import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { AvForm } from "availity-reactstrap-validation";
import { Table } from "reactstrap";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";

import {
    designStore,
    setFooterTicketNumberMessage,
    setFooterFinalMessage,
    setFooterFinalMessageItems,
    computedStore,
    setFooterTicketMessageItems,
} from "@store";

import { TicketNumberMessageRow } from "@pages/GeneralConfig/Design/Content/FinalMessage/Design/Footer/TicketNumberMessageRow";
import { FinalMessageRow } from "@pages/GeneralConfig/Design/Content/FinalMessage/Design/Footer/FinalMessageRow";

export function TableContent(): JSX.Element {
    const { t } = useTranslation();

    const {
        selectedLanguage,
        footerTicketNumberMessage,
        activePageName,
        isFooterFinalMessageActive,
        isFooterTicketNumberMessageActive,
        footerFinalMessage,
        activeSlideIndex,
    } = useSnapshot(designStore);

    const { elements } = useSnapshot(computedStore);

    function handleResetTranslationOnClickEvent() {
        const localFooterTicketNumberMessageItems: HeaderLanguageItemsType = {
            ...footerTicketNumberMessage,
            [selectedLanguage]: {
                ...footerTicketNumberMessage[selectedLanguage],
                content:
                    footerTicketNumberMessage[selectedLanguage].defaultContent,
            },
        };

        setFooterTicketNumberMessage(localFooterTicketNumberMessageItems);
        setFooterTicketMessageItems(
            localFooterTicketNumberMessageItems,
            elements[activeSlideIndex].name
        );

        const localFooterFinalMessage: HeaderLanguageItemsType = {
            ...footerFinalMessage,
            [selectedLanguage]: {
                ...footerFinalMessage[selectedLanguage],
                content: footerFinalMessage[selectedLanguage].defaultContent,
            },
        };
        setFooterFinalMessage(localFooterFinalMessage);
        setFooterFinalMessageItems(
            localFooterFinalMessage,
            elements[activeSlideIndex].name
        );
    }

    return (
        <div className="mt-3 mx-4">
            <AvForm>
                <Table bordered className="languages-table__clz">
                    <thead>
                        <tr>
                            <th
                                style={{
                                    width: "35%",
                                    verticalAlign: "middle",
                                }}
                            >
                                <div className="ml-2 d-flex align-items-center cursor__clz">
                                    {t("Default text")}
                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="ml-2 cursor__clz">
                                        {t("Custom")}
                                    </div>

                                    <StyledIconButton
                                        title={t("Reset")}
                                        icon="RefreshIcon"
                                        style={{ margin: "0px" }}
                                        onClick={() => {
                                            handleResetTranslationOnClickEvent();
                                        }}
                                    >
                                        <RefreshIcon height={15} width={15} />
                                    </StyledIconButton>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {!["authenticationModes", "connection"].includes(
                            activePageName
                        ) ? (
                            isFooterTicketNumberMessageActive === true ? (
                                <TicketNumberMessageRow />
                            ) : null
                        ) : null}
                        {isFooterFinalMessageActive ? (
                            <FinalMessageRow />
                        ) : null}
                    </tbody>
                </Table>
            </AvForm>
        </div>
    );
}
