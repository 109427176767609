import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledButton,
    StyledLabel,
    StyledDangerButton,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { AlertForm } from "./AlertForm";
import { capitalize } from "@helpers/general";

import "./index.css";

type AlertMeansType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    week: any;
    keyDay: number;
    setWeek: Function;
};

export function AlertMeans({
    isModalOpened,
    setIsModalOpened,
    week,
    keyDay,
    setWeek,
}: AlertMeansType) {
    const { t } = useTranslation();
    const alertForm =
        week[keyDay] === undefined ? "XLS" : week[keyDay].alertForm;

    const [alertMeans, setAlertMeans] = React.useState<any>(
        week[keyDay] === undefined ? [] : [...week[keyDay].alertMeans]
    );
    const [isEmailInclude, setIsEmailInclude] = React.useState<boolean>(
        alertMeans.includes("E-mail")
    );
    const [form, setForm] = React.useState<string>("");
    const setAlertForm = (form: string) => {
        week[keyDay].alertForm = form;
    };

    const handleAlertMeansChange = () => {
        week[keyDay].alertMeans = alertMeans;
        let newWeek = [...week] as any;
        newWeek[keyDay].alertMeans = week[keyDay].alertMeans;
        setWeek(newWeek);
        setAlertForm(form);
    };

    React.useEffect(() => {
        setAlertMeans(
            week[keyDay] === undefined ? [] : [...week[keyDay].alertMeans]
        );
    }, [isModalOpened, week, keyDay]);

    return (
        <StyledModal
            toggle={true}
            isOpen={isModalOpened!}
            centered
            size="lg"
            className="modal-content__clz"
            style={{ fontSize: "17px" }}
        >
            <ModalHeader toggle={() => setIsModalOpened(!isModalOpened)}>
                <StyledH2>
                    {capitalize(t("Alert means and format choice"))}
                </StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="m-0" style={{ fontSize: "17px" }}>
                    {t("Please choose your alert means and format")}
                </StyledLabel>
                <div className="align-items-center  mt-3">
                    <div>
                        <input
                            type="checkbox"
                            id="example"
                            defaultChecked={week[keyDay]?.alertMeans.includes(
                                t("SMS")
                            )}
                            value="SMS"
                            onChange={(event) => {
                                if (event.target.checked) {
                                    setAlertMeans([...alertMeans, "SMS"]);
                                } else {
                                    setAlertMeans([
                                        ...alertMeans.filter(
                                            (alertMean: string) =>
                                                alertMean !== "SMS"
                                        ),
                                    ]);
                                }
                            }}
                        />
                        <label className="ml-3">{t("SMS")}</label>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <div style={{ marginTop: "7px" }}>
                            <input
                                type="checkbox"
                                id="example"
                                value="E-mail"
                                defaultChecked={week[
                                    keyDay
                                ]?.alertMeans.includes(t("E-mail"))}
                                onChange={(event) => {
                                    if (event.target.checked) {
                                        setAlertMeans([
                                            ...alertMeans,
                                            "E-mail",
                                        ]);
                                        setIsEmailInclude(true);
                                    } else {
                                        setAlertMeans([
                                            ...alertMeans.filter(
                                                (alertMean: string) =>
                                                    alertMean !== "E-mail"
                                            ),
                                        ]);
                                        setIsEmailInclude(false);
                                    }
                                }}
                            />
                            <label className="ml-3">{t("E-mail")}</label>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div style={{ color: "black" }}>
                                {t("Alert format")}
                            </div>
                            <AlertForm
                                isEmailInclude={isEmailInclude}
                                alertForm={alertForm}
                                setForm={setForm}
                                alertMeans={alertMeans}
                                setIsEmailInclude={setIsEmailInclude}
                            />
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <StyledDangerButton
                    outline
                    variant="danger"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </StyledDangerButton>
                <StyledButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                        handleAlertMeansChange();
                    }}
                >
                    {t("Validate")}
                </StyledButton>
            </ModalFooter>
        </StyledModal>
    );
}
