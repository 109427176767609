import React from "react";
import { useSnapshot } from "valtio";
import { BiCaretUp } from "react-icons/bi";
import {
    designStore,
    generalConfigStore,
    setActiveOrderTakingSummaryConfigOptions,
} from "@store";
import { getImageContentById } from "@helpers/general";

export function ConfigOptionsWrapper(): JSX.Element {
    const { activeOrderTakingSummaryConfigOptions } = useSnapshot(designStore);
    const {
        project: { template, files: images },
    } = useSnapshot(generalConfigStore);
    const {
        selectedLanguage,
        isSalesMethodChangeInSummaryActive,
    } = useSnapshot(designStore);

    const salesMethodItems = (template.content[
        "salesMethods"
    ] as ProjectContentItemType).items;

    const activeItems = salesMethodItems.filter(
        (item) =>
            (item.name === "To take" &&
                isSalesMethodChangeInSummaryActive === true) ||
            item.name === "On the spot"
    );

    const globalColor = (template.content.generalDesign as GeneralDesignType)
        .colors[0].content;

    const styles = {
        root: {
            height: "27px",
            width: "27px",
            boxShadow: "0px 2px 6px -2px rgba(0,0,0,0.6)",
            transition: ".2s transform linear, .2s box-shadow linear",
            ":hover": {
                boxShadow: "0px 8px 14px -4px rgba(0,0,0,0.2)",
                transform: "translate(0px, -3px)",
            },
            borderRadius: "5px",
        },
    };

    const imageContent = getImageContentById(
        activeItems[0].id,
        images,
        selectedLanguage
    );
    const imageSalesMethodSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    React.useEffect(() => {
        setActiveOrderTakingSummaryConfigOptions(
            (template.pages.ways[
                "orderTaking"
            ] as OrderTakingPageType).summary.options.items.filter(
                (item) => item.active === true && item.title !== "Usual"
            )
        );
    }, [template.pages.ways]);

    return (
        <div
            className="d-flex justify-content-end align-items-center px-3"
            style={{ gap: "10px" }}
        >
            {isSalesMethodChangeInSummaryActive === true ? (
                <div
                    className="d-flex flex-row"
                    style={{
                        borderBottom: `${globalColor} 4px solid`,
                        borderRadius: "5px",
                        boxShadow: "0px 2px 6px -4px rgba(0,0,0,0.6)",
                        transition:
                            ".2s transform linear, .2s box-shadow linear",
                    }}
                >
                    <img
                        key={activeItems[0].id}
                        alt="salesMethos"
                        className="sales-method-image"
                        src={imageSalesMethodSrc}
                        style={{
                            height: "27px",
                            width: "27px",
                        }}
                    />
                    <div
                        style={{
                            height: "27px",
                            width: "15px",
                        }}
                    >
                        <BiCaretUp />
                    </div>
                </div>
            ) : null}
            {activeOrderTakingSummaryConfigOptions.map(
                (option: OptionsItemType, index: number) => {
                    const imageContent =
                        option.title === "PRM/Child"
                            ? typeof option["content"] !== "string"
                                ? getImageContentById(
                                      option.id,
                                      images,
                                      selectedLanguage
                                  )
                                : option.content
                            : getImageContentById(
                                  option.id,
                                  images,
                                  selectedLanguage
                              );

                    const imageSrc =
                        imageContent?.includes("http") ||
                        imageContent?.includes("https")
                            ? imageContent
                            : `./images/${imageContent}`;

                    return (
                        <img
                            key={index}
                            src={imageSrc}
                            alt={option.title}
                            style={styles.root}
                        />
                    );
                }
            )}
        </div>
    );
}
