import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { CheckboxIcon } from "@aureskonnect/react-ui";
import { mutate } from "swr";
import { toast } from "react-toastify";
import { uuid } from "uuidv4";

import { setGlobalDataIsUpdated, setIsModalOpened, store } from "../store";
import { store as useStore } from "@components/VerticalLayout/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import "./index.css";

type PropsType = {
    children: React.ReactNode;
    title: string;
    selectedRows: any;
};

export function ModalWrapper({
    children,
    title = "",
    selectedRows,
}: PropsType): JSX.Element {
    const { t } = useTranslation();
    const { userID, franchiseID } = useSnapshot(useStore);
    const {
        isModalOpened,
        itemActivation,
        ItemSalesMethods,
        ItemPayementChoice,
        loyaltyAccount,
        options,
        orders,
        hourOfUse,
        apkId,
    } = useSnapshot(store);

    const toggle = () => {
        setIsModalOpened(!isModalOpened);
        setGlobalDataIsUpdated(true);
    };
    async function ccSetup() {
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/C&C/c&cSetup`;

        let ApkId =
            apkId.id !== undefined &&
            selectedRows["Nom commercial"] === apkId.boutique
                ? apkId.id
                : uuid();
        let savedData = {
            userId: userID,
            shopId: selectedRows["Nom commercial"],
            franchiseId: franchiseID,
            ApkId: ApkId,

            data: {
                [selectedRows["Nom commercial"]]: {
                    apk_designation: "APK test",
                    id_apk: ApkId,
                    itemActivation,
                    ItemSalesMethods,
                    ItemPayementChoice,
                    loyaltyAccount,
                    options,
                    orders,
                    hourOfUse,
                },
            },
        };

        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(savedData),
                }).then((result: any) => {
                    if (result.error === true) {
                        throw Error(result.message);
                    }
                    toast.success(`${t("''C&C'' setup successfully")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    });
                    setIsModalOpened(!isModalOpened);
                    setGlobalDataIsUpdated(true);
                    setTimeout(function () {
                        window.location.reload();
                    }, 100);
                })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <React.Fragment>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                toggle={toggle}
                isOpen={isModalOpened}
                className="modal-wrapper__clz"
            >
                <ModalHeader toggle={toggle} className="text-uppercase">
                    {title}
                </ModalHeader>
                <ModalBody className="p-0">{children}</ModalBody>
                <ModalFooter>
                    <CustomMainColorButton
                        className="w-20"
                        rounded
                        variant="primary"
                        onClick={() => {
                            ccSetup();
                        }}
                    >
                        {t("Save")}
                    </CustomMainColorButton>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
