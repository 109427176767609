import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";

import { store } from "../../../../components/VerticalLayout/store";
import {
    store as storeSaleSupport,
    setSaleSupportData,
    setFranchiseDataIsUpdated,
    setNumberActive,
    setNumberArchived,
    setListFranchiseArchived,
    setArchivedData,
    setIsHistoryModalOpened,
    setIsInlineErrorCleared,
    setIsActionsClicked,
    setIsEdited,
    setActions,
    setValidationAction,
} from "../store";

import { CustomSideFilter } from "./CustomSideFilter";
import { CustomIcons } from "./CustomIcons";
import SaleSupportForm from "./SaleSupportForm";
import HistoryModal from "../HistoryModal";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import { ConfirmationActiveSaleSupport } from "./ConfirmationActiveSaleSupport";
import { ConfirmationFavorite } from "./ConfirmationFavorite";

import { useCallbackPrompt } from "@hooks/useCallbackPrompt";
import { ConfirmationUpdatingDataMessageModal } from "@components/Common/ConfirmationUpdatingDataMessageModal";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type saleSupportType = {
    isArchivedSaleSupportClicked: boolean;
    setIsArchivedSaleSupportClicked: Function;
    setLimit: Function;
};

export default function SaleSupportFranchise({
    isArchivedSaleSupportClicked,
    setIsArchivedSaleSupportClicked,
    setLimit,
}: saleSupportType) {
    const { t } = useTranslation();
    const { oneShop, userID, franchiseID, shopID, operatorID } = useSnapshot(
        store
    );
    const {
        franchiseDataIsUpdated,
        isHistoryModalOpened,
        isActionsClicked,
        isEdited,
        actions,
        validationAction,
    } = useSnapshot(storeSaleSupport);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        isEdited
    );
    const shopId: string = oneShop ? shopID : "0";
    const [isAddSaleSupportClicked, setIsAddSaleSupportClicked] = useState(
        false
    );
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

    const [
        isAddNewSaleSupportButtonClicked,
        setIsAddNewSaleSupportButtonClicked,
    ] = useState(false);
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [saleSupport, setSaleSupport] = useState<any>({});
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [data, setData] = React.useState<any>({});
    const [
        isModalConfirmationOpened,
        setIsModalConfirmationOpened,
    ] = React.useState<boolean>(false);
    const [isFavoriteModified, setIsFavoriteModified] = React.useState<boolean>(
        false
    );
    const [rowData, setRowData] = useState<any>({});
    const urlArchive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=archived`;
    const urlActive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=activated`;
    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };

    React.useEffect(() => {
        let localShop: string[] = [];

        selectedRows.forEach((el: any) => {
            localShop.push(el[t("Shop")]);
        });
        setNumberActive(
            data.othersData !== undefined ? data.othersData.activated : 0
        );
        setNumberArchived(
            data.othersData !== undefined ? data.othersData.archived : 0
        );

        setListFranchiseArchived(
            data.othersData !== undefined &&
                data.othersData.dataArchive !== undefined &&
                isArchivedSaleSupportClicked
                ? data?.othersData?.dataArchive.data
                : []
        );

        setArchivedData(
            data.othersData !== undefined &&
                data.othersData.archivedData !== undefined
                ? data.othersData.archivedData.data
                : []
        );
        setSaleSupportData(data.data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isArchivedSaleSupportClicked]);

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 4,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    setLimit={setLimit}
                    selectedRowData={e}
                    userData={{
                        userId: userID,
                        shopId: shopId,
                        franchiseId: franchiseID,
                        operatorId: operatorID,
                    }}
                    setSaleSupport={setSaleSupport}
                    setIsAddSaleSupportClicked={setIsAddSaleSupportClicked}
                    setIsModalConfirmationOpened={setIsModalConfirmationOpened}
                    isModalConfirmationOpened={isModalConfirmationOpened}
                    setSelectedRows={setSelectedRows}
                    isArchivedSaleSupportClicked={isArchivedSaleSupportClicked}
                    isFavoriteModified={isFavoriteModified}
                    setIsFavoriteModified={setIsFavoriteModified}
                    setRowData={setRowData}
                />
            ),
        },
    ];

    React.useEffect(() => {
        if (filterActive) {
            setIsAddSaleSupportClicked(false);
        }
    }, [filterActive]);

    React.useEffect(() => {
        setActions(confirmNavigation);
        setIsActionsClicked(showPrompt as boolean);
        setValidationAction(cancelNavigation);
    }, [showPrompt, confirmNavigation, cancelNavigation]);

    return (
        <React.Fragment>
            <div
                style={{
                    display: "grid",
                    minHeight: oneShop ? "650px" : "600px",
                    gridTemplateColumns: isAddSaleSupportClicked
                        ? "2fr 1fr "
                        : "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    url={isArchivedSaleSupportClicked ? urlArchive : urlActive}
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    canExpand={false}
                    canSelect
                    canResize
                    setData={setData}
                    customSelect
                    canMovedCheckboxLeftOnExpand
                    setSelectedRows={setSelectedRows}
                    customJsxSideFilterButton={
                        <CustomSideFilter
                            setLimit={setLimit}
                            selectedRows={selectedRows}
                            saleSupport={saleSupport}
                            isEdited={isEdited}
                            setIsInInitializationModalOpened={
                                setIsInInitializationModalOpened
                            }
                            setDataIsUpdated={setFranchiseDataIsUpdated}
                            setLocalFilterActive={setLocalFilterActive}
                            setIsAddSaleSupportClicked={
                                setIsAddSaleSupportClicked
                            }
                            isAddSaleSupportClicked={isAddSaleSupportClicked}
                            setIsModalConfirmationOpened={
                                setIsModalConfirmationOpened
                            }
                            isModalConfirmationOpened={
                                isModalConfirmationOpened
                            }
                            isArchivedSaleSupportClicked={
                                isArchivedSaleSupportClicked
                            }
                            setIsArchivedSaleSupportClicked={
                                setIsArchivedSaleSupportClicked
                            }
                        />
                    }
                    showGlobalFilter
                    showFilter
                    actionColumn={ActionColumn}
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={
                        setFranchiseDataIsUpdated as
                            | React.Dispatch<
                                  React.SetStateAction<number | boolean>
                              >
                            | undefined
                    }
                    dataIsUpdated={franchiseDataIsUpdated}
                    elevationTable={1}
                    minHeight={oneShop ? "500px" : "450px"}
                    maxHeight={oneShop ? "500px" : "450px"}
                    name="saleSupport"
                />
                {isAddSaleSupportClicked ? (
                    <SaleSupportForm
                        setLimit={setLimit}
                        allDataShops={
                            data?.othersData !== undefined &&
                            !isArchivedSaleSupportClicked
                                ? data?.othersData?.allData?.data
                                : []
                        }
                        isAddSaleSupportClicked={isAddSaleSupportClicked}
                        setIsAddSaleSupportClicked={setIsAddSaleSupportClicked!}
                        saleSupport={saleSupport}
                        isAddNewSaleSupportButtonClicked={
                            isAddNewSaleSupportButtonClicked
                        }
                        setSaleSupportData={setSaleSupport}
                        userData={{
                            userId: userID,
                            shopId: shopId,
                            franchiseId: franchiseID,
                        }}
                        setLocalFilterActive={setLocalFilterActive}
                    />
                ) : null}
            </div>
            {isInInitializationModalOpened === true ? (
                <ConfirmationInitializationMessage
                    isModalOpened={isInInitializationModalOpened}
                    setIsModalOpened={setIsInInitializationModalOpened}
                    setIsClicked={setIsAddNewSaleSupportButtonClicked}
                    isClicked={isAddNewSaleSupportButtonClicked}
                    setIsEdited={setIsEdited}
                    setIsInlineErrorCleared={setIsInlineErrorCleared}
                />
            ) : null}
            {isHistoryModalOpened ? (
                <HistoryModal
                    ids={selectedRows}
                    setIsModalOpened={setIsHistoryModalOpened}
                    isModalOpened={isHistoryModalOpened}
                />
            ) : null}
            {isModalConfirmationOpened === true ? (
                <ConfirmationActiveSaleSupport
                    setIsModalConfirmationOpened={setIsModalConfirmationOpened}
                    isModalConfirmationOpened={isModalConfirmationOpened}
                    setDataIsUpdated={setFranchiseDataIsUpdated}
                    selectedRows={selectedRows}
                />
            ) : null}
            {isFavoriteModified ? (
                <ConfirmationFavorite
                    isModalOpened={isFavoriteModified}
                    setIsModalOpened={setIsFavoriteModified}
                    selectedRowData={rowData}
                />
            ) : null}

            {isActionsClicked === true ? (
                <ConfirmationUpdatingDataMessageModal
                    isModalOpened={isActionsClicked}
                    setIsModalOpened={setIsActionsClicked}
                    validationAction={validationAction}
                    actions={actions}
                    setIsEdited={setIsEdited}
                />
            ) : null}
        </React.Fragment>
    );
}
