import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { AvForm } from "availity-reactstrap-validation";
import { Table } from "reactstrap";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";

import {
    computedStore,
    designStore,
    setInformationMessageItems,
    setUserAccountMessageItems,
    setPageInformationMessageItems,
    setPageUserAccountItems,
} from "@store";

import { UserAccountRow } from "./UserAccountRow";
import { InformationMessageRow } from "./InformationMessageRow";

export function TableContent(): JSX.Element {
    const { t } = useTranslation();

    const {
        selectedLanguage,
        informationMessageItems,
        userAccountMessageItems,
        activeSlideIndex,
        isUserAccountActive,
        isInformationMessageActive,
        activePageName,
    } = useSnapshot(designStore);

    const { elements } = useSnapshot(computedStore);

    function handleResetTranslationOnClickEvent() {
        const localInformationMessageItems: HeaderLanguageItemsType = {
            ...informationMessageItems,
            [selectedLanguage]: {
                ...informationMessageItems[selectedLanguage],
                content:
                    informationMessageItems[selectedLanguage].defaultContent,
            },
        };

        setInformationMessageItems(localInformationMessageItems);
        setPageInformationMessageItems(
            localInformationMessageItems,
            elements[activeSlideIndex].name
        );
        if (
            !["authenticationModes", "connection"].includes(
                elements[activeSlideIndex].name
            )
        ) {
            const localUserAccountItems: HeaderLanguageItemsType = {
                ...userAccountMessageItems,
                [selectedLanguage]: {
                    ...userAccountMessageItems[selectedLanguage],
                    content:
                        userAccountMessageItems[selectedLanguage]
                            .defaultContent,
                },
            };
            setUserAccountMessageItems(localUserAccountItems);

            setPageUserAccountItems(
                localUserAccountItems,
                elements[activeSlideIndex].name
            );
        }
    }

    return (
        <div className="mt-3 mx-4">
            <AvForm>
                <Table bordered className="languages-table__clz">
                    <thead>
                        <tr>
                            <th
                                style={{
                                    width: "30%",
                                    verticalAlign: "middle",
                                }}
                            >
                                <div className="ml-2 d-flex align-items-center cursor__clz">
                                    {t("Default text")}
                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="ml-2 cursor__clz">
                                        {t("Custom")}
                                    </div>

                                    <StyledIconButton
                                        title={t("Reset")}
                                        icon="RefreshIcon"
                                        style={{ margin: "0px" }}
                                        onClick={() => {
                                            handleResetTranslationOnClickEvent();
                                        }}
                                    >
                                        <RefreshIcon height={15} width={15} />
                                    </StyledIconButton>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {![
                            "authenticationModes",
                            "connection",
                            "finalMessage",
                        ].includes(activePageName) ? (
                            isUserAccountActive === true ? (
                                <UserAccountRow />
                            ) : null
                        ) : null}
                        {isInformationMessageActive ? (
                            <InformationMessageRow />
                        ) : null}
                    </tbody>
                </Table>
            </AvForm>
        </div>
    );
}
