import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { uid } from "react-uid";
import { useSnapshot } from "valtio";

import {
    designStore,
    generalConfigStore,
    setImageKey,
    setIsCategorySubCategoryTitleActive,
    setIsOrderTakingCaloryActive,
    setIsOrderTakingProductInformationActive,
    setIsOrderTakingSelectedCategoryTitleActive,
    setIsOrderTakingTagsActive,
    setIsPageProductAccessIconActive,
    setIsPageSelectedCategoryTitleActive,
    setIsPageTagsActive,
    setIsProductDescriptionActive,
    setIsProductTitleActive,
    setPageOrderTaking,
    setProjectFiles,
} from "@store";

import { Uploader } from "@components/Uploader";

export function ProductView(): JSX.Element {
    const { t } = useTranslation();

    const {
        imageKey,
        isOrderTakingTagsActive,
        project: { template, files: images },
        isOrderTakingSelectedCategoryTitleActive,
        isOrderTakingCaloryActive,
        isOrderTakingProductInformationActive,
    } = useSnapshot(generalConfigStore);
    const {
        isProductTitleActive,
        isProductDescriptionActive,
        productAccessIcon,
        isCategorySubCategoryTitleActive,
        calorieIcon,
    } = useSnapshot(designStore);

    function handleResetImagesOnClickEvent(id: string) {
        const imagesArray = [...images];
        const image = imagesArray.find((element) => element.id === id);
        if (image !== undefined) {
            const imageIndex = imagesArray.indexOf(image);
            imagesArray[imageIndex] = {
                ...image,
                content: image.defaultImage as string,
            };
        }

        setProjectFiles(imagesArray);
        setImageKey(uid(imagesArray));
    }
    return (
        <div
            className="mt-4 rounded border"
            style={{ width: "93%", height: "96%", borderColor: "#CECECE" }}
        >
            <div
                className="text-left px-4 d-flex align-items-center cursor__clz"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                {t("General")}
            </div>
            <div
                className="p-4 d-flex flex-column justify-content-between"
                style={{ gap: "20px" }}
            >
                <div
                    className="align-items-center"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                    }}
                >
                    <label>{t("Designation of the selected category")}</label>
                    <div className="d-flex justify-content-end">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isOrderTakingSelectedCategoryTitleActive}
                            onChange={() => {
                                setIsOrderTakingSelectedCategoryTitleActive(
                                    !isOrderTakingSelectedCategoryTitleActive
                                );
                                setIsPageSelectedCategoryTitleActive(
                                    !isOrderTakingSelectedCategoryTitleActive,
                                    "orderTaking"
                                );
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                </div>
                <div
                    className="align-items-center"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                    }}
                >
                    <label>{t("Filter(tag)")}</label>
                    <div className="d-flex justify-content-end">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isOrderTakingTagsActive}
                            onChange={() => {
                                setIsOrderTakingTagsActive(
                                    !isOrderTakingTagsActive
                                );
                                setIsPageTagsActive(
                                    !isOrderTakingTagsActive,
                                    "orderTaking"
                                );
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                </div>
                <div
                    className="align-items-center"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                    }}
                >
                    <label>{t("Product information access icon")}</label>
                    <div className="d-flex flex-row align-items-center justify-content-start">
                        <StyledIconButton
                            title={t("Reset")}
                            className="m-0"
                            icon="RefreshIcon"
                            onClick={() => {
                                handleResetImagesOnClickEvent(
                                    productAccessIcon.id
                                );
                            }}
                            disabled={
                                isOrderTakingProductInformationActive === true
                                    ? false
                                    : true
                            }
                        >
                            <RefreshIcon height={15} width={15} />
                        </StyledIconButton>
                        <Uploader
                            key={imageKey}
                            uploadedFileTitle={
                                productAccessIcon.content as string
                            }
                            id={productAccessIcon.id}
                            disabled={
                                isOrderTakingProductInformationActive === true
                                    ? false
                                    : true
                            }
                        />
                    </div>
                    <div className="d-flex justify-content-end">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isOrderTakingProductInformationActive}
                            onChange={() => {
                                setIsOrderTakingProductInformationActive(
                                    !isOrderTakingProductInformationActive
                                );
                                setIsPageProductAccessIconActive(
                                    !isOrderTakingProductInformationActive,
                                    "orderTaking"
                                );
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                </div>
                <div
                    className="align-items-center"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                    }}
                >
                    <label>{t("Number of calories on the product")}</label>
                    <div className="d-flex flex-row align-items-center justify-content-start">
                        <StyledIconButton
                            title={t("Reset")}
                            className="m-0"
                            icon="RefreshIcon"
                            onClick={() => {
                                handleResetImagesOnClickEvent(calorieIcon.id);
                            }}
                            disabled={
                                isOrderTakingCaloryActive === true
                                    ? false
                                    : true
                            }
                        >
                            <RefreshIcon height={15} width={15} />
                        </StyledIconButton>
                        <Uploader
                            key={imageKey}
                            uploadedFileTitle={calorieIcon.content as string}
                            id={calorieIcon.id}
                            disabled={
                                isOrderTakingCaloryActive === true
                                    ? false
                                    : true
                            }
                        />
                    </div>
                    <div className="d-flex justify-content-end">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            disabled
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isOrderTakingCaloryActive}
                            onChange={() => {
                                setIsOrderTakingCaloryActive(
                                    !isOrderTakingCaloryActive
                                );
                                setPageOrderTaking({
                                    ...(template.pages.ways[
                                        "orderTaking"
                                    ] as OrderTakingPageType)
                                        .orderTakingProduct,
                                    calorieIcon: {
                                        ...(template.pages.ways[
                                            "orderTaking"
                                        ] as OrderTakingPageType)
                                            .orderTakingProduct.calorieIcon,
                                        active: !isOrderTakingCaloryActive,
                                    },
                                });
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                </div>
                <div
                    className="align-items-center"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                    }}
                >
                    <label>{t("Product title")}</label>
                    <div className="d-flex justify-content-end">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isProductTitleActive}
                            onChange={() => {
                                setIsProductTitleActive(!isProductTitleActive);
                                setPageOrderTaking({
                                    ...(template.pages.ways[
                                        "orderTaking"
                                    ] as OrderTakingPageType)
                                        .orderTakingProduct,
                                    isProductTitleActive: !isProductTitleActive,
                                });
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                </div>
                <div
                    className="align-items-center"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                    }}
                >
                    <label>{t("Category/subCategory title")}</label>
                    <div className="d-flex justify-content-end">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isCategorySubCategoryTitleActive}
                            onChange={() => {
                                setIsCategorySubCategoryTitleActive(
                                    !isCategorySubCategoryTitleActive
                                );
                                setPageOrderTaking({
                                    ...(template.pages.ways[
                                        "orderTaking"
                                    ] as OrderTakingPageType)
                                        .orderTakingProduct,
                                    categorySubCategoryTitle: !isCategorySubCategoryTitleActive,
                                });
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                </div>
                <div
                    className="align-items-center"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                    }}
                >
                    <label>{t("Product Description")}</label>
                    <div className="d-flex justify-content-end">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isProductDescriptionActive}
                            onChange={() => {
                                setIsProductDescriptionActive(
                                    !isProductDescriptionActive
                                );
                                setPageOrderTaking({
                                    ...(template.pages.ways[
                                        "orderTaking"
                                    ] as OrderTakingPageType)
                                        .orderTakingProduct,
                                    isProductDescriptionActive: !isProductDescriptionActive,
                                });
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
