import classNames from "classnames";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSnapshot } from "valtio";

import { generalConfigStore } from "@store";

import { BaconIcon } from "../Icons/BaconIcon";
import { BioIcon } from "../Icons/BioIcon";
import { BonDealIcon } from "../Icons/BonDealIcon";
import { ChickenSvgIcon } from "../Icons/ChickenSvgIcon";
import { CowSvgIcon } from "../Icons/CowSvgIcon";
import { NewIcon } from "../Icons/NewIcon";
import { PoissonIcon } from "../Icons/PoissonIcon";
import { VeganIcon } from "../Icons/VeganIcon";
import "./tags.css";

import "swiper/swiper.min.css";

export function TagsSwiperWrapper(): JSX.Element {
    const {
        project: {
            template: {
                content: { generalDesign },
            },
        },
        secondColor,
    } = useSnapshot(generalConfigStore);

    document.documentElement.style.setProperty(
        "--primary-color",
        (generalDesign as GeneralDesignType).colors[0].content
    );

    const [tags, setTags] = React.useState([
        {
            id: 0,
            title: "Poulet",
            icon: "chicken",
            active: true,
            isVisible: true,
        },
        {
            id: 1,
            title: "Boeuf",
            icon: "cow",
            active: false,
            isVisible: true,
        },
        {
            id: 2,
            title: "Bacon",
            icon: "bacon",
            active: false,
            isVisible: true,
        },
        {
            id: 3,
            title: "Poisson",
            icon: "poisson",
            active: false,
            isVisible: true,
        },
        {
            id: 4,
            title: "Bio",
            icon: "bio",
            active: false,
            isVisible: true,
        },
        {
            id: 5,
            title: "Vegan",
            icon: "vegan",
            active: false,
            isVisible: true,
        },
        {
            id: 6,
            title: "Bon Deal",
            icon: "bonDeal",
            active: false,
            isVisible: true,
        },
        { id: 7, title: "New", icon: "new", active: false, isVisible: true },
    ]);

    function handleActiveTagOnClickEvent(id: number) {
        setTags((prevState) => {
            let newState = [...prevState];
            newState = newState.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        active: !item.active,
                    };
                }
                return { ...item };
            });

            return newState;
        });
    }

    return (
        <Swiper
            className="tags-swiper__clz"
            spaceBetween={5}
            slidesPerView={5}
            slidesPerGroup={5}
            style={{ height: "40px" }}
        >
            {tags.map((tag, itemIndex) => {
                const iconStyle = {
                    display: "flex",
                    justifyContent: "center",
                    fill: tag.active === true ? secondColor : "black",
                };
                return (
                    <SwiperSlide
                        className={classNames({
                            "active-tags-swiper-width__clz":
                                tag.active === true,
                            "tags-swiper-width__clz": tag.active === false,
                        })}
                        key={itemIndex}
                        onClick={() => {
                            handleActiveTagOnClickEvent(tag.id);
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{
                                gap: "8px",
                            }}
                        >
                            {tag.icon === "cow" ? (
                                <CowSvgIcon style={iconStyle} />
                            ) : tag.icon === "chicken" ? (
                                <ChickenSvgIcon style={iconStyle} />
                            ) : tag.icon === "poisson" ? (
                                <PoissonIcon style={iconStyle} />
                            ) : tag.icon === "bio" ? (
                                <BioIcon style={iconStyle} />
                            ) : tag.icon === "vegan" ? (
                                <VeganIcon style={iconStyle} />
                            ) : tag.icon === "bonDeal" ? (
                                <BonDealIcon style={iconStyle} />
                            ) : tag.icon === "bacon" ? (
                                <BaconIcon style={iconStyle} />
                            ) : tag.icon === "new" ? (
                                <NewIcon style={iconStyle} />
                            ) : null}
                            <div
                                style={{
                                    color:
                                        tag.active === true
                                            ? secondColor
                                            : "black",
                                    fontWeight: "bold",
                                    fontSize: "10px",
                                    fontFamily: "Segoe UI",
                                    textAlign: "center",
                                }}
                            >
                                {tag.title}
                            </div>
                        </div>
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
}
