import React from "react";
import { useSnapshot } from "valtio";
import { t } from "i18next";
import { CSSTransition } from "react-transition-group";

import { generalConfigStore } from "@store";

import { TransitionCardWrapper } from "@pages/GeneralConfig/Various/GeneralTransition/TransitionCardWrapper";
import { CustomErrorBoundary } from "@components/CustomErrorBoundary";

export function GeneralTransition(): JSX.Element {
    const { transitions, generalTransition, isAnimationIn } = useSnapshot(
        generalConfigStore
    );

    return (
        <CustomErrorBoundary>
            <div className="d-flex justify-content-between cursor__clz">
                <div
                    className="d-grid__clz"
                    style={{
                        gridTemplateRows: "0.1fr 0.9fr",
                        gap: "30px",
                    }}
                >
                    <div className="d-flex align-items-center py-3">
                        <span
                            className="text-nowrap"
                            style={{
                                font: "normal normal 600 35px/61px Nunito Sans",
                            }}
                        >
                            {t("General transition")}
                        </span>
                    </div>
                    <div className="d-flex flex-wrap" style={{ gap: "30px" }}>
                        {Object.keys(transitions).map(
                            (transition: string, index: number) => {
                                return (
                                    <TransitionCardWrapper
                                        key={index}
                                        transition={transition}
                                    />
                                );
                            }
                        )}
                    </div>
                </div>

                <div
                    className="d-flex justify-content-center align-items-center mt-1"
                    style={{
                        backgroundColor: "#363636",
                        borderRadius: "10px",
                        height: "700px",
                        width: "448px",
                    }}
                >
                    <CSSTransition
                        in={isAnimationIn}
                        appear={true}
                        timeout={generalTransition.timeout}
                        classNames={generalTransition.className}
                    >
                        <div
                            style={{
                                backgroundColor: "#FFFFFF",
                                height: "680px",
                                width: "430px",
                                borderRadius: "10px",
                            }}
                        >
                            <img
                                alt="transitionImage"
                                src={"./images/salesMethods.png"}
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    borderRadius: "6px",
                                }}
                            />
                        </div>
                    </CSSTransition>
                </div>
            </div>
        </CustomErrorBoundary>
    );
}
