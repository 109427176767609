import React from "react";
import { StyledH2, StyledH1, RefreshIcon } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { Card, CardBody } from "reactstrap";

import { setModel } from "../../store";
import { ColorPickerWrapper } from "./ColorPickerWrapper";
import { Model1 } from "./Model1";
import { Model2 } from "./Model2";
import { Model3 } from "./Model3";
import { Model4 } from "./Model4";
import { Model5 } from "./Model5";
import { Model6 } from "./Model6";
import { Model7 } from "./Model7";

export default function Model({ rowData }: any) {
    const { t } = useTranslation();
    const [colors, setColors] = React.useState<any>(
        Object.values(rowData.template).length === 0
            ? [
                  {
                      id: 1,
                      title: "Primary color",
                      content: "#000",
                      defaultContent: "#000",
                  },
                  {
                      id: 2,
                      title: "Secondary color",
                      content: "#E6E6E6",
                      defaultContent: "#E6E6E6",
                  },
              ]
            : rowData.template.model.colors
    );

    const [modelVisibility, setModelVisibility] = React.useState<any>(
        Object.values(rowData.template).length === 0
            ? [
                  {
                      name: t("Model 1"),
                      state: true,
                  },
                  {
                      name: t("Model 2"),
                      state: false,
                  },
                  {
                      name: t("Model 3"),
                      state: false,
                  },
                  {
                      name: t("Model 4"),
                      state: false,
                  },
                  {
                      name: t("Model 5"),
                      state: false,
                  },
                  {
                      name: t("Model 6"),
                      state: false,
                  },
                  {
                      name: t("Model 7"),
                      state: false,
                  },
              ]
            : rowData.template.model.modelVisibility
    );
    async function handleResetOnClickEvent() {
        const initialColors = colors.map((item: ColorsContentType) => {
            return {
                ...item,

                content: item.defaultContent,
            };
        });

        setColors(initialColors);
    }

    let index = modelVisibility.findIndex(
        (element: any) => element.state === true
    );

    React.useEffect(() => {
        setModel(
            JSON.parse(
                JSON.stringify({
                    colors: colors,
                    modelVisibility: modelVisibility,
                })
            )
        );
        // eslint-disable-next-line
    }, [colors, modelVisibility]);

    return (
        <React.Fragment>
            <StyledH1 className="m-3">{t("Model")}</StyledH1>
            <div className="border-footer__clz" />
            <div className="d-flex  align-items-center justify-content-between mt-2">
                <StyledH2 className="ml-3 mt-2">{t("Design")}</StyledH2>
                <div className="float-right mr-5">
                    <RefreshIcon
                        height={25}
                        width={25}
                        className="m-1"
                        onClick={() => {
                            handleResetOnClickEvent();
                        }}
                        style={{ cursor: "pointer" }}
                    />
                </div>
            </div>

            <div className="d-flex  align-items-center justify-content-between mt-2 mb-1">
                <div className="float-right mr-5">
                    {colors.map((color: ColorsContentType, index: number) => (
                        <div
                            key={index}
                            className="d-flex  align-items-center justify-content-between mt-2"
                            style={{ gap: "10px" }}
                        >
                            <div className="d-flex justify-content-center align-items-center ml-4">
                                <span
                                    style={{
                                        font:
                                            "normal normal normal 15px/20px Nunito Sans",
                                        color: "black",
                                    }}
                                >
                                    {t(color.title)}
                                </span>
                            </div>
                            <div
                                className="d-flex flex-column justify-content-center mx-4"
                                style={{ marginLeft: "10%" }}
                            >
                                <ColorPickerWrapper
                                    item={color}
                                    setColor={setColors}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="border-footer__clz" />
            <StyledH2 className="ml-3 mt-1">{t("Model")}</StyledH2>
            <div className="ml-5" style={{ fontWeight: "bold" }}>
                {t("Choose the model of the ORB screen")}
            </div>
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                }}
            >
                <div style={{ width: "90%", marginLeft: "4%" }}>
                    <Card className="mb-1">
                        <CardBody>
                            <div className="d-flex  align-items-center justify-content-between mt-2">
                                <div
                                    style={{
                                        marginLeft: "3%",
                                        font:
                                            "normal normal 600 14px/19px Segoe UI",
                                        marginBottom: "2%",
                                    }}
                                >
                                    {t("Model")}
                                </div>

                                <div
                                    style={{
                                        marginRight: "10%",
                                        font:
                                            "normal normal 600 14px/19px Segoe UI",
                                        marginBottom: "2%",
                                    }}
                                >
                                    {t("Action")}
                                </div>
                            </div>
                            <div className="border-footer__clz" />
                            {modelVisibility.map(
                                (model: any, key: any): JSX.Element => (
                                    <div className="d-flex  align-items-center justify-content-between mt-3">
                                        <div
                                            className="d-flex col-4"
                                            style={{ color: "#212529 " }}
                                        >
                                            <div className="px-1">
                                                <h6 style={{ width: "76px" }}>
                                                    {model.name}
                                                </h6>
                                            </div>
                                            <div style={{ marginLeft: "230%" }}>
                                                <ReactSwitch
                                                    disabled={
                                                        modelVisibility[key]
                                                            .state
                                                            ? true
                                                            : false
                                                    }
                                                    uncheckedIcon={false}
                                                    checkedIcon={false}
                                                    handleDiameter={26}
                                                    offColor="#f7b4b8"
                                                    offHandleColor="#E30613"
                                                    checked={model.state}
                                                    onChange={(e: any) => {
                                                        if (
                                                            modelVisibility[key]
                                                                .state === false
                                                        ) {
                                                            [
                                                                ...modelVisibility,
                                                            ].forEach(
                                                                (
                                                                    element: any
                                                                ) => {
                                                                    if (
                                                                        element.name !==
                                                                        modelVisibility[
                                                                            key
                                                                        ].name
                                                                    )
                                                                        element.state = false;
                                                                }
                                                            );
                                                        }
                                                        let newModelVisibility = [
                                                            ...modelVisibility,
                                                        ];
                                                        newModelVisibility[
                                                            key
                                                        ].state = !newModelVisibility[
                                                            key
                                                        ].state;
                                                        setModelVisibility(
                                                            newModelVisibility
                                                        );
                                                    }}
                                                    onColor="#c2eddd"
                                                    onHandleColor="#34C38F"
                                                    width={55}
                                                    height={20}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </CardBody>
                    </Card>
                </div>
                <div style={{ width: "90%" }}>
                    <Card
                        style={{
                            height: "99%",
                            backgroundColor: "#000",
                        }}
                    >
                        <CardBody>
                            {index === 0 ? (
                                <Model1 />
                            ) : index === 1 ? (
                                <Model2 />
                            ) : index === 2 ? (
                                <Model3 />
                            ) : index === 3 ? (
                                <Model4 />
                            ) : index === 4 ? (
                                <Model5 />
                            ) : index === 5 ? (
                                <Model6 />
                            ) : (
                                <Model7 />
                            )}
                        </CardBody>
                    </Card>
                </div>
            </div>
        </React.Fragment>
    );
}
