import React, { useRef } from "react";
import {
    StyledH2,
    StyledH1,
    StyledSelectInput,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { AvForm, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import ReactSwitch from "react-switch";
import { Tooltip } from "@mui/material";

import { setOptions } from "../../store";

import BellSvgIcon from "@components/Common/SvgIcons/BellSvgIcon";

export default function Options({ rowData }: any) {
    const { t } = useTranslation();
    const audioRef = useRef<any>(null);
    const bellRingtone1 = "/BellRingTone/Bipeur1.wav";
    const bellRingtone2 = "/BellRingTone/Bipeur2.wav";
    const bellRingtone3 = "/BellRingTone/Bipeur3.wav";
    const bellRingtone4 = "/BellRingTone/Bipeur3.wav";

    const [isCallRingtoneActive, setIsCallRingtoneActive] = React.useState(
        Object.values(rowData.template).length === 0
            ? false
            : rowData.template.options.callingReadyOrders.isCallRingtoneActive
    );
    const [
        inputPersonalizedMessage,
        setInputPersonalizedMessage,
    ] = React.useState<string>(
        Object.values(rowData.template).length === 0
            ? ""
            : rowData.template.options.customerMessage.inputPersonalizedMessage
    );
    const [bellName, setBellName] = React.useState<string>(
        Object.values(rowData.template).length === 0
            ? ""
            : rowData.template.options.callingReadyOrders.bellName
    );
    const [numberDigits, setNumberDigits] = React.useState<string>(
        Object.values(rowData.template).length === 0
            ? ""
            : rowData.template.options.numberDigits
    );
    const [callRingtone, setCallRingtone] = React.useState<string>(
        Object.values(rowData.template).length === 0
            ? ""
            : rowData.template.options.callingReadyOrders.callRingtone
    );
    const [isEnableManualCall, setIsEnableManualCall] = React.useState(
        Object.values(rowData.template).length === 0
            ? false
            : rowData.template.options.callingReadyOrders.isEnableManualCall
    );
    const [isActivateVoice, setIsActivateVoice] = React.useState(
        Object.values(rowData.template).length === 0
            ? false
            : rowData.template.options.callingReadyOrders.isActivateVoice
    );
    const [customerMessage, setCustomerMessage] = React.useState<any>(
        Object.values(rowData.template).length === 0
            ? t("Default message")
            : rowData.template.options.customerMessage.customerMessage
    );
    const [
        animationCustomerMessage,
        setAnimationCustomerMessage,
    ] = React.useState<any>(
        Object.values(rowData.template).length === 0
            ? t("Fixed display")
            : rowData.template.options.animationCustomerMessage
    );
    const [
        animationDisplayedCommands,
        setAnimationDisplayedCommands,
    ] = React.useState<any>(
        Object.values(rowData.template).length === 0
            ? t("Fixed list")
            : rowData.template.options.animationDisplayedCommands
    );
    React.useEffect(() => {
        setOptions(
            JSON.parse(
                JSON.stringify({
                    callingReadyOrders: {
                        isCallRingtoneActive: isCallRingtoneActive,
                        callRingtone: callRingtone,
                        isEnableManualCall: isEnableManualCall,
                        isActivateVoice: isActivateVoice,
                        bellName: bellName,
                    },
                    customerMessage: {
                        customerMessage: customerMessage,
                        inputPersonalizedMessage: inputPersonalizedMessage,
                    },
                    animationCustomerMessage: animationCustomerMessage,
                    animationDisplayedCommands: animationDisplayedCommands,
                    numberDigits: numberDigits,
                })
            )
        );
        // eslint-disable-next-line
    }, [
        animationCustomerMessage,
        animationDisplayedCommands,
        callRingtone,
        customerMessage,
        inputPersonalizedMessage,
        isCallRingtoneActive,
        isEnableManualCall,
        numberDigits,
        isActivateVoice,
    ]);

    const playRingtone = () => {
        if (audioRef.current && audioRef.current !== "") {
            audioRef.current.play();
        }
    };

    return (
        <React.Fragment>
            <StyledH1 className="m-3">{t("Options")}</StyledH1>
            <StyledH2 className="ml-3 mt-2">
                {t("Calling ready orders")}
            </StyledH2>

            <div className="d-flex  align-items-center justify-content-between mt-2">
                <div className="ml-5">{t("Call ringtone")}</div>
                <div
                    style={{
                        width: "172px",
                        position: "absolute",
                        right: "54%",
                    }}
                >
                    <StyledSelectInput
                        isClearable
                        value={
                            bellName === ""
                                ? null
                                : {
                                      label: bellName,
                                      value: bellName,
                                  }
                        }
                        onChange={(e: any) => {
                            if (e === null ? "" : e.label === t("Sonnaire 1")) {
                                setCallRingtone(bellRingtone1);
                            } else if (
                                e === null ? "" : e.label === t("Sonnaire 2")
                            ) {
                                setCallRingtone(bellRingtone2);
                            } else if (
                                e === null ? "" : e.label === t("Sonnaire 3")
                            ) {
                                setCallRingtone(bellRingtone3);
                            } else if (
                                e === null ? "" : e.label === t("Sonnaire 4")
                            ) {
                                setCallRingtone(bellRingtone4);
                            }
                            setBellName(e === null ? "" : e.label);
                        }}
                        placeholder={t("Select")}
                        options={[
                            {
                                label: t("Sonnaire 1"),
                                value: t("Sonnaire 1"),
                            },
                            {
                                label: t("Sonnaire 2"),
                                value: t("Sonnaire 2"),
                            },
                            {
                                label: t("Sonnaire 3"),
                                value: t("Sonnaire 3"),
                            },
                            {
                                label: t("Sonnaire 4"),
                                value: t("Sonnaire 4"),
                            },
                        ]}
                        name="mark"
                        noOptionsMessage={() => t("No options")}
                        maxMenuHeight="70px"
                    />
                </div>
                <Tooltip title={t("listen")}>
                    <div
                        style={{
                            position: "absolute",
                            right: "48%",
                            cursor: "pointer",
                        }}
                        className={`${classnames({
                            "not-allowed-icon__clz": bellName === "",
                        })}`}
                    >
                        <BellSvgIcon onClick={playRingtone} />

                        <audio ref={audioRef} src={callRingtone} />
                    </div>
                </Tooltip>
                <div className="float-right mr-5">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isCallRingtoneActive}
                        onChange={() => {
                            setIsCallRingtoneActive(!isCallRingtoneActive);
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                    />
                </div>
            </div>
            <div className="d-flex  align-items-center justify-content-between mt-2">
                <div className="ml-5">
                    {t("Activate voice call ready commands")}
                </div>
                <Tooltip title={t("listen")}>
                    <div
                        style={{
                            position: "absolute",
                            right: "48%",
                        }}
                    >
                        <BellSvgIcon />
                    </div>
                </Tooltip>
                <div className="float-right mr-5">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isActivateVoice}
                        onChange={() => {
                            setIsActivateVoice(!isActivateVoice);
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                    />
                </div>
            </div>
            <div className="d-flex  align-items-center justify-content-between mt-2">
                <div className="ml-5">
                    {t("Enable manual call of ready orders")}
                </div>
                <div className="float-right mr-5">
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isEnableManualCall}
                        onChange={() => {
                            setIsEnableManualCall(!isEnableManualCall);
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={55}
                        height={20}
                    />
                </div>
            </div>

            <div className="border-footer__clz" />
            <StyledH2 className="ml-3 mt-2">{t("Customer message")}</StyledH2>
            <div className="d-flex  align-items-center justify-content-between mt-2">
                <div className="ml-5">{t("Default message")}</div>

                <div className="float-right mr-5">
                    <AvForm
                        model={{
                            form: customerMessage,
                            checkItOut: true,
                        }}
                    >
                        <AvRadioGroup
                            name="form"
                            required
                            inline
                            errorMessage="Pick one!"
                        >
                            <AvRadio
                                id={t("Default message")}
                                label=""
                                name="radio"
                                onChange={() => {
                                    setCustomerMessage(t("Default message"));
                                }}
                                value={t("Default message")}
                                disabled={false}
                            />
                            <div style={{ position: "absolute", top: "42%" }}>
                                <AvRadio
                                    id={t("Personalized message")}
                                    label=""
                                    name="radio"
                                    onChange={() => {
                                        setCustomerMessage(
                                            t("Personalized message")
                                        );
                                    }}
                                    value={t("Personalized message")}
                                    disabled={false}
                                />
                            </div>
                        </AvRadioGroup>
                    </AvForm>
                </div>
            </div>
            <div
                style={{
                    font: " normal normal normal 15px/20px Nunito Sans",
                    marginLeft: "47px",
                    color: "rgb(166, 163, 163)",
                }}
            >
                {t("Please present your ticket at the cash desk")}
            </div>
            <div className="d-flex  align-items-center justify-content-between mt-2">
                <div className="ml-5">{t("Personalized message")}</div>
            </div>
            <AvForm
                style={{
                    width: "33%",
                    marginLeft: "3%",
                    marginTop: "1%",
                    marginBottom: "1%",
                }}
            >
                <StyledTextInput
                    autocomplete="off"
                    name="designation"
                    onChange={(e: any) => {
                        setInputPersonalizedMessage(e.target.value);
                    }}
                    value={inputPersonalizedMessage}
                    placeholder={t("Write")}
                    type="text"
                    disabled={customerMessage === t("Default message")}
                />
            </AvForm>
            <div className="border-footer__clz" />
            <StyledH2 className="ml-3 mt-2">
                {t("Animation of the customer message")}
            </StyledH2>

            <div className="d-flex  align-items-center justify-content-between mt-2">
                <div className="ml-5">{t("Fixed display")}</div>
                <div className="float-right mr-5">
                    <AvForm
                        model={{
                            form: animationCustomerMessage,
                            checkItOut: true,
                        }}
                    >
                        <AvRadioGroup
                            name="form"
                            required
                            inline
                            errorMessage="Pick one!"
                        >
                            <AvRadio
                                id={t("Fixed display")}
                                label=""
                                name="radio"
                                onChange={() => {
                                    setAnimationCustomerMessage(
                                        t("Fixed display")
                                    );
                                }}
                                value={t("Fixed display")}
                                disabled={false}
                            />
                            <div style={{ position: "absolute", top: "65%" }}>
                                <AvRadio
                                    id={t("Rotating display")}
                                    label=""
                                    name="radio"
                                    onChange={() => {
                                        setAnimationCustomerMessage(
                                            t("Rotating display")
                                        );
                                    }}
                                    value={t("Rotating display")}
                                    disabled={false}
                                />
                            </div>
                        </AvRadioGroup>
                    </AvForm>
                </div>
            </div>
            <div className="d-flex  align-items-center justify-content-between mt-3 mb-1">
                <div className="ml-5">{t("Rotating display")}</div>
            </div>

            <div className="border-footer__clz" />
            <StyledH2 className="ml-3 mt-2">
                {t("Animation of orders displayed (Orders ready)")}
            </StyledH2>

            <div className="d-flex  align-items-center justify-content-between mt-1">
                <div className="ml-5">{t("Fixed list")}</div>

                <div className="float-right mr-5">
                    <AvForm
                        model={{
                            form: animationDisplayedCommands,
                            checkItOut: true,
                        }}
                    >
                        <AvRadioGroup
                            name="form"
                            required
                            inline
                            errorMessage="Pick one!"
                        >
                            <AvRadio
                                id={t("Fixed list")}
                                label=""
                                name="example"
                                onChange={() => {
                                    setAnimationDisplayedCommands(
                                        t("Fixed list")
                                    );
                                }}
                                value={t("Fixed list")}
                                disabled={false}
                            />
                            <div style={{ position: "absolute", top: "80%" }}>
                                <AvRadio
                                    id={t("Dynamic list")}
                                    label=""
                                    name="example"
                                    onChange={() => {
                                        setAnimationDisplayedCommands(
                                            t("Dynamic list")
                                        );
                                    }}
                                    value={t("Dynamic list")}
                                    disabled={false}
                                />
                            </div>
                        </AvRadioGroup>
                    </AvForm>
                </div>
            </div>
            <div className="d-flex  align-items-center justify-content-between mt-3 mb-1">
                <div className="ml-5">{t("Dynamic list")}</div>
                <div className="float-right mr-5"></div>
            </div>

            <div className="border-footer__clz" />
            <StyledH2 className="ml-3 mt-1">{t("Order number")}</StyledH2>

            <div className="d-flex  align-items-center justify-content-between mt-2">
                <div className="ml-5">{t("Number of digits")}</div>
                <div style={{ width: "172px", marginRight: "63%" }}>
                    <StyledSelectInput
                        isClearable
                        value={
                            numberDigits === ""
                                ? null
                                : {
                                      label: numberDigits,
                                      value: numberDigits,
                                  }
                        }
                        onChange={(e: any) => {
                            setNumberDigits(e !== null ? e.label : "");
                        }}
                        placeholder={t("Select")}
                        options={[
                            {
                                label: t("By default"),
                                value: t("By default"),
                            },
                            {
                                label: "2",
                                value: "2",
                            },
                            {
                                label: "3",
                                value: "3",
                            },
                            {
                                label: "4",
                                value: "4",
                            },
                        ]}
                        name="mark"
                        noOptionsMessage={() => t("No options")}
                        maxMenuHeight="40px"
                    />
                </div>
            </div>
        </React.Fragment>
    );
}
