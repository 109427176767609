import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import { Dropdown, DropdownButton } from "react-bootstrap";
import classnames from "classnames";
import { useSnapshot } from "valtio";

import store from "./store";

import { capitalize } from "@helpers/general";

import "react-toastify/dist/ReactToastify.css";
import "./index.css";

type DropdownStatusType = {
    state: string;
    typeDisplay: string;
};

export function DropdownStatus({ state, typeDisplay }: DropdownStatusType) {
    const { t } = useTranslation();
    const [lastState, setLastState] = useState<string>("In line");
    const [StatusValue, setStatusValue] = React.useState<string>("In line");
    const { isAddAppClicked } = useSnapshot(store);

    useEffect(() => {
        setStatusValue(state === "" ? t("In Line") : t(capitalize(state)));
    }, [state, t]);
    useEffect(() => {
        if (StatusValue !== undefined && StatusValue !== "")
            setLastState(StatusValue);
    }, [StatusValue, t, state]);
    return (
        <div
            className={`${classnames("d-flex justify-content-around w-50", {
                "not-allowed-icon__clz":
                    isAddAppClicked || typeDisplay === "archived",
            })}`}
        >
            <DropdownButton
                key="Success"
                id="dropdown-split-variants-Success"
                variant={lastState === t("In line") ? "success" : "danger"}
                title={t(lastState)}
                size="sm"
                onClick={(e: any) => setStatusValue(e.target.text)}
            >
                <Dropdown.Item eventKey="1">{t("In line")}</Dropdown.Item>
                <Dropdown.Item eventKey="2">{t("Offline")}</Dropdown.Item>
                <Dropdown.Item eventKey="3">{t("Unknown")}</Dropdown.Item>
            </DropdownButton>
        </div>
    );
}
