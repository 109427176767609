import React from "react";
import { AvForm } from "availity-reactstrap-validation";

import { ColorResult, SketchPicker } from "react-color";

import { InputGroupWrapper } from "./InputGroupWrapper";

export function ColorPickerWrapper({
    item,
    setColor,
}: {
    item: ColorsContentType;
    setColor: React.Dispatch<React.SetStateAction<ColorsContentType[]>>;
}): JSX.Element {
    const color = item.content;

    const [isColorPickerShown, setIsColorPickerShown] = React.useState<boolean>(
        false
    );

    function handleColorPickerOnChangeEvent(color: ColorResult, index: number) {
        setColor((prevState: ColorsContentType[]) => {
            let newState = [...prevState];
            newState = newState.map((item: ColorsContentType) => {
                if (item.id === index) {
                    return {
                        ...item,
                        content: color.hex as ColorType,
                    };
                }
                return { ...item };
            });
            return newState;
        });
    }

    function handleShowColorPickerOnClickEvent() {
        setIsColorPickerShown(!isColorPickerShown);
    }

    function handleColorPickerCloseEvent() {
        setIsColorPickerShown(false);
    }

    return (
        <AvForm
            className="btn-group"
            style={{
                display: "grid",
                gridColumnGap: "2px",
            }}
        >
            <InputGroupWrapper
                color={color}
                handleShowColorPickerOnClickEvent={
                    handleShowColorPickerOnClickEvent
                }
            />

            {isColorPickerShown ? (
                <div
                    style={{
                        position: "absolute",
                        zIndex: 2,
                        marginLeft: "-71px",
                        marginRight: "91px",
                        marginTop: "60px",
                    }}
                >
                    <div
                        style={{
                            position: "fixed",
                            top: "0px",
                            right: "0px",
                            bottom: "0px",
                            left: "0px",
                        }}
                        onClick={handleColorPickerCloseEvent}
                    />

                    <SketchPicker
                        onChangeComplete={(colorResult) =>
                            handleColorPickerOnChangeEvent(colorResult, item.id)
                        }
                        color={color}
                    />
                </div>
            ) : null}
        </AvForm>
    );
}
