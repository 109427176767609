import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledLabel,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";
import { store } from "@components/VerticalLayout/store";
import ErrorToast from "@components/Common/ErrorTost";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationArchiveType = {
    setIsModalOpened: Function;
    isModalOpened: boolean;
    e?: any;
    setDataIsUpdated: Function;
    selectedRows: any;
    isShop?: boolean;
    setLimit?: Function | undefined;
};

export function ConfirmationArchive({
    setIsModalOpened,
    isModalOpened,
    setDataIsUpdated,
    e,
    selectedRows,
    isShop,
    setLimit = () => {},
}: ConfirmationArchiveType) {
    const { t } = useTranslation();
    const { franchiseID, userID, oneShop, shopID, operatorID } = useSnapshot(
        store
    );
    const [error, setError] = React.useState(false);
    const [listSaleMode, setListSaleMode] = React.useState<any>([]);

    const shopId = oneShop ? shopID : 0;

    const getArrayOfRows = () => {
        let arrayOfRows: any[] = [];
        let ids: any[] = [];

        selectedRows.forEach(async (el: any) => {
            if (
                el[t("Shop")] === undefined &&
                ids.indexOf(`${el.shopId}${el.Id}`) === -1
            ) {
                arrayOfRows.push(el);
                ids.push(`${el.shopId}${el.Id}`);
            }
        });
        arrayOfRows = [...new Set(arrayOfRows)];
        return arrayOfRows;
    };

    const dataFetch = React.useCallback(async () => {
        const data =
            isShop !== true
                ? await (
                      await fetch(
                          `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`
                      )
                  ).json()
                : await (
                      await fetch(
                          `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information?userId=${userID}&franchiseId=${franchiseID}`
                      )
                  ).json();
        let listSaleMode: any[] = [];
        if (data.data !== undefined) {
            data.data.forEach((element: any) => {
                if (element["Mode de vente"] !== undefined)
                    listSaleMode.push(
                        `${element["Mode de vente"]} ${element.shopId}`
                    );
                else {
                    element.subRows.forEach((el: any) => {
                        listSaleMode.push(
                            `${el["Mode de vente"]} ${el.shopId}`
                        );
                    });
                }
            });
        }
        setListSaleMode(listSaleMode);
    }, [franchiseID, isShop, shopId, userID]);

    async function archiveModeOfSale() {
        toast.dismiss();
        let objectData: any = [];
        let isInclude: boolean = false;
        let isNotInclude: boolean = false;

        if (selectedRows.length !== 0) {
            let arrayOfRows = getArrayOfRows();
            arrayOfRows.forEach(async (el: any) => {
                if (
                    !listSaleMode
                        .flat(1)
                        .includes(`${el["Mode of sale"]} ${el.shopId}`)
                ) {
                    isNotInclude = true;
                    let dataModeOfSale = {
                        userId: userID,
                        shopId: [el.shopId],
                        franchiseId: franchiseID,
                        operatorId: operatorID,
                        data: {
                            [el.Id]: {
                                designation: el["Mode of sale"],
                                tag: el.tag,
                                display_name: el.display_name,
                                support_vente: el.support_vente,
                                isArchived: true,
                                state: false,
                                isFavorite: false,
                                shopId: el.shopId,
                            },
                        },
                    };

                    objectData.push(dataModeOfSale);
                } else {
                    isInclude = true;
                }
            });

            try {
                mutate(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`,
                    await fetch(
                        `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`,
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(objectData),
                        }
                    ).then((result) => console.log(result))
                );
            } catch (e) {
                toast.error(`${t("There's an error")}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setError(true);
            }

            if (error === false && isNotInclude) {
                toast.success(
                    `${t("The sales method has been successfully archived")}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
                setDataIsUpdated!(true);
            }
            if (isInclude) {
                ErrorToast(t("Attention, you cannot archive a used element."));
                setDataIsUpdated!(true);
            }
            setLimit(2);
        } else {
            let dataModeOfSale = [
                {
                    userId: userID,
                    shopId: [e.selectedRow.shopId],
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    isShop: false,
                    data: {
                        [e.selectedRow.Id]: {
                            designation: e.selectedRow["Mode of sale"],
                            tag: e.selectedRow.tag,
                            display_name: e.selectedRow.display_name,
                            support_vente: e.selectedRow.support_vente,
                            isArchived: true,
                            isFavorite: false,
                            state: false,
                            shopId: e.selectedRow.shopId,
                        },
                    },
                },
            ];
            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`;
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(dataModeOfSale),
            };
            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            toast.success(
                                `${t(
                                    "The sales method has been successfully archived"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setDataIsUpdated!(true);
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    React.useEffect(() => {
        dataFetch();
    }, [dataFetch]);

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                }}
            >
                <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        "Attention, note that this sales method(s) can be used by other modules.\n Do you want to confirm the archive of the selected sales method(s)?"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        archiveModeOfSale();
                        setIsModalOpened(!isModalOpened);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
