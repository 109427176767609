import React from "react";

export default function BellConcierge(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="35"
            viewBox="0 0 64.809 70.009"
            {...props}
        >
            <g
                id="Groupe_5705"
                data-name="Groupe 5705"
                transform="translate(0 0)"
            >
                <g
                    id="Groupe_4376"
                    data-name="Groupe 4376"
                    transform="translate(0 0)"
                >
                    <g
                        id="Groupe_102"
                        data-name="Groupe 102"
                        transform="translate(0 0)"
                    >
                        <g id="Groupe_575" data-name="Groupe 575">
                            <g id="Groupe_91" data-name="Groupe 91">
                                <g id="Tracé_32" data-name="Tracé 32">
                                    <path
                                        id="Tracé_1339"
                                        data-name="Tracé 1339"
                                        d="M8741.168,3347.173c17.893,0,32.4,15.671,32.4,35s-14.507,35-32.4,35-32.406-15.671-32.406-35,14.51-35,32.406-35Z"
                                        transform="translate(-8708.762 -3347.173)"
                                        fill="none"
                                    />
                                    <path
                                        id="Tracé_1340"
                                        data-name="Tracé 1340"
                                        d="M8741.167,3352.045c-15.377,0-27.892,13.517-27.892,30.132s12.515,30.132,27.892,30.132,27.9-13.518,27.9-30.132-12.516-30.132-27.9-30.132m0-4.872c17.9,0,32.4,15.673,32.4,35s-14.5,35-32.4,35-32.408-15.673-32.408-35S8723.27,3347.173,8741.167,3347.173Z"
                                        transform="translate(-8708.759 -3347.173)"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <g
                    id="sur_placve3-2"
                    data-name="sur placve3-2"
                    transform="translate(10.821 8.921)"
                >
                    <path
                        id="Tracé_104"
                        data-name="Tracé 104"
                        d="M8225.525,3451.927v-1.784a2.465,2.465,0,1,0-4.916,0v1.825A18.3,18.3,0,0,1,8225.525,3451.927Z"
                        transform="translate(-8199.474 -3447.486)"
                    />
                    <path
                        id="Tracé_105-2"
                        data-name="Tracé 105-2"
                        d="M8131.481,3687.364l-.018.012-6.384,4.183a6.156,6.156,0,0,1,.2,1.19.819.819,0,0,1-.714.895l-9.892.817h-.062a.843.843,0,0,1-.057-1.665l9.05-.745c1.332-.995-2.1-3.016-3.854-2.874l-6.016.5a7.448,7.448,0,0,1-3.513-.569l-.641-.274a8.7,8.7,0,0,0-9.309,1.61l.662,9.359.914-.668a4.282,4.282,0,0,1,3.338-.779l10.483,1.945a8.952,8.952,0,0,0,5.733-1.354l13.124-11.412c.926-1.86-2.1-.824-3.045-.167Z"
                        transform="translate(-8093.476 -3655.724)"
                    />
                    <path
                        id="Tracé_106"
                        data-name="Tracé 106"
                        d="M8082.344,3633.445a4.352,4.352,0,0,0,3.944,2.6h27.657a4.341,4.341,0,0,0,3.941-2.6l1.508-3.443H8080.84Z"
                        transform="translate(-8076.356 -3606.518)"
                    />
                    <path
                        id="Tracé_107-2"
                        data-name="Tracé 107-2"
                        d="M8043.181,3697.3l5.063-.418.988,13.988-5.061.417Z"
                        transform="translate(-8043.181 -3664.794)"
                    />
                    <path
                        id="Tracé_108"
                        data-name="Tracé 108"
                        d="M8131.717,3498.724a16.643,16.643,0,0,0-23.547,0,16.433,16.433,0,0,0-4.822,10.971h33.191a16.442,16.442,0,0,0-4.822-10.971Z"
                        transform="translate(-8096.183 -3487.88)"
                    />
                </g>
            </g>
        </svg>
    );
}
