import { proxy } from "valtio";

type StoreType = {
    isAddAppClicked: boolean;
    isAddPeripheralClicked: boolean;
    isDeletePeripheralModalOpened: boolean;
    globalDataIsUpdated: boolean | number;
    isMultiDeletePeripheralModalOpened: boolean;
    isActionsClicked: boolean;
    isEdited: boolean;
    actions: Function;
    modify: boolean;
    validationAction: any;
};

export const store = proxy<StoreType>({
    isAddAppClicked: false,
    isAddPeripheralClicked: false,
    isDeletePeripheralModalOpened: false,
    globalDataIsUpdated: false,
    isMultiDeletePeripheralModalOpened: false,
    isActionsClicked: false,
    isEdited: false,
    actions: () => {},
    validationAction: {},
    modify: false,
});
export function setModify(modify: boolean): void {
    store.modify = modify;
}
export function setIsAddAppClicked(isAddAppClicked: boolean): void {
    store.isAddAppClicked = isAddAppClicked;
}
export function setIsAddPeripheralClicked(
    isAddPeripheralClicked: boolean
): void {
    store.isAddPeripheralClicked = isAddPeripheralClicked;
}
export function setIsMultiDeletePeripheralModalOpened(
    isMultiDeletePeripheralModalOpened: boolean
): void {
    store.isMultiDeletePeripheralModalOpened = isMultiDeletePeripheralModalOpened;
}
export function setIsDeletePeripheralModalOpened(
    isDeletePeripheralModalOpened: boolean
): void {
    store.isDeletePeripheralModalOpened = isDeletePeripheralModalOpened;
}
export function setGlobalDataIsUpdated(dataIsUpdated: boolean | number): void {
    store.globalDataIsUpdated = dataIsUpdated;
}

export function setIsEdited(isEdited: boolean): any {
    store.isEdited = isEdited;
}

export function setIsActionsClicked(isActionsClicked: boolean): any {
    store.isActionsClicked = isActionsClicked;
}

export function setValidationAction(validationAction: any): any {
    store.validationAction = validationAction;
}

export function setActions(actions: any): any {
    store.actions = actions;
}
export default store;
