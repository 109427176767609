import React from "react";
import classnames from "classnames";
import ReactSwitch from "react-switch";
import { HeartIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { Tooltip } from "@mui/material";

import {
    setActiveLanguages,
    setFavoredLanguages,
    store as generalSettingStore,
} from "../store";
import { useTranslation } from "react-i18next";

type CustomIconsAssociateModalType = {
    e: any;
    isArchivedLanguagesClicked: boolean;
};

export function CustomIconsAssociateModal({
    e,
    isArchivedLanguagesClicked,
}: CustomIconsAssociateModalType) {
    const { t } = useTranslation();
    const { localShop, associatedLanguages } = useSnapshot(generalSettingStore);

    const [languageState, setLanguageState] = React.useState<boolean>(
        localShop.length === 1 &&
            associatedLanguages.findIndex(
                (el: any) => el.Id === e.selectedRow.Id
            ) !== -1
            ? associatedLanguages[
                  associatedLanguages.findIndex(
                      (el: any) => el.Id === e.selectedRow.Id
                  )
              ].isActive
            : true
    );
    const [isLanguageFavored, setIsLanguageFavored] = React.useState<boolean>(
        localShop.length === 1 &&
            associatedLanguages.findIndex(
                (el: any) => el.Id === e.selectedRow.Id
            ) !== -1
            ? associatedLanguages[
                  associatedLanguages.findIndex(
                      (el: any) => el.Id === e.selectedRow.Id
                  )
              ].isFavorite
            : false
    );
    React.useEffect(() => {
        setActiveLanguages({
            Id: e.selectedRow.Id,
            state: languageState,
        });

        // eslint-disable-next-line
    }, [languageState]);

    React.useEffect(() => {
        setFavoredLanguages({
            Id: e.selectedRow.Id,
            isFavored: isLanguageFavored,
        });
        // eslint-disable-next-line
    }, [isLanguageFavored]);

    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    <Tooltip
                        title={
                            isLanguageFavored === false
                                ? t("Enable favorite state")
                                : t("Disable favorite state")
                        }
                    >
                        <div>
                            <HeartIcon
                                onClick={() => {
                                    setIsLanguageFavored(!isLanguageFavored);
                                }}
                                height={25}
                                width={25}
                                name="heart"
                                fill={isLanguageFavored ? "red" : "black"}
                                className={`${classnames(
                                    "pointer__clz lan_icn_AFlike",
                                    {
                                        "not-allowed-icon__clz": isArchivedLanguagesClicked,
                                    }
                                )}`}
                            />
                        </div>
                    </Tooltip>
                    <Tooltip
                        title={
                            languageState === false
                                ? t("Activate")
                                : t("Unactivate")
                        }
                    >
                        <div>
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={languageState}
                                onChange={async () => {
                                    setLanguageState(!languageState);
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={50}
                                height={20}
                                className={`${classnames(
                                    "pointer__clz lan_icn_AFenableDisable ml-3",
                                    {
                                        "not-allowed-icon__clz": isArchivedLanguagesClicked,
                                    }
                                )}`}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
        </React.Fragment>
    );
}
