

export const DateService: DateServiceProps[] = [

    {
        id: 1,
        Icon: "L",
        title: "lun",
        visible: false

    }, {
        id: 2,
        Icon: "M",
        title: "mar",
        visible: false
    }, {
        id: 3,
        Icon: "M",
        title: "mer",
        visible: false
    }

    , {
        id: 4,
        Icon: "J",
        title: "jeu",
        visible: false
    }
    , {
        id: 5,
        Icon: "V",
        title: "ven",
        visible: false
    }
    , {
        id: 6,
        Icon: "S",
        title: "sam",
        visible: false
    }
    , {
        id: 7,
        Icon: "D",
        title: "dim",
        visible: false
    }




]