import {
    ConfigurationTemplateIcon,
    DeviceSettingsIcon,
} from "@components/Common/SvgIcons";

export type CardMenuType = {
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    description?: string;
    title: string;
    number: number;
    path: string;
};

export const CARD_MENU_LIST: CardMenuType[] = [
    {
        title: "Template Setup",
        number: 0,
        Icon: ConfigurationTemplateIcon,
        description:
            "Description to add. Description to add. Description to add. Description to add",
        path: "/sco-project-setting",
    },
    {
        title: "Peripheral setting",
        number: 0,
        Icon: DeviceSettingsIcon,
        description:
            "Description to add. Description to add. Description to add. Description to add",
        path: "/sco-peripheral-setting",
    },
];
