import React, { useEffect, useState } from "react";
import {
    CheckboxIcon,
    CrossIcon,
    StyledLabel,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { mutate } from "swr";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";
import classnames from "classnames";

import {
    store as appStore,
    setIsEdited,
    setActions,
    setGlobalDataIsUpdated,
    setIsActionsClicked,
} from "../store";
import { store as userStore } from "@components/VerticalLayout/store";
import { store } from "../ApplicationAssociateModal/store";
import { checkIpIfExist } from "@helpers/general";

import { ipMatchWord } from "@constants/index";

import useResource from "@hooks/useResource";
import {
    CustomCardHeader,
    CustomCardBody,
    CustomCardFooter,
} from "@components/Common/CustomCardStyled";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import "../index.css";

type applicationConfigurationType = {
    setIsAddAppClicked: Function;
    setDataIsUpdated: Function;
    isAddAppClicked: boolean;
    isAddNewAppButtonClicked: boolean;
    selectedRows: any;
    isComingFromModify?: boolean;
    inputDesignation: boolean;
    setInputDesignation: Function;
    setIsConfirmationEditModalOpened: Function;
    data: any;
    isConsult: boolean;
    setIsConsult: Function;
};

export function ApplicationConfigure({
    setIsAddAppClicked,
    isAddAppClicked,
    setDataIsUpdated,
    isAddNewAppButtonClicked,
    selectedRows,
    isComingFromModify,
    inputDesignation,
    setInputDesignation,
    setIsConfirmationEditModalOpened,
    data,
    isConsult,
    setIsConsult,
}: applicationConfigurationType) {
    const { t } = useTranslation();
    const {
        oneShop,
        userID,
        franchiseID,
        shopID,
        operatorID,
        resourceIp,
    } = useSnapshot(userStore);
    const { applicationListData } = useSnapshot(store);
    const { isEdited, isActionsClicked } = useSnapshot(appStore);

    const [designation, setDesignation] = useState<string>("");
    const [application, setApplication] = useState<string>("KIOSK");
    const [remark, setRemark] = useState<string>("");
    const [ip, setIp] = useState<string>("");
    const [isApplicationListExist, setIsApplicationListExist] = React.useState<
        boolean
    >(false);

    const [exist, setExist] = useState<any>({
        isNameExist: false,
        isIpExist: false,
    });

    const [localNamesApplications, setLocalNamesApplications] = useState<
        string[]
    >([]);
    const [ips, setIps] = useState<string[]>([]);
    const matchWordDesignation = new RegExp(
        new RegExp(/^[\w]+([-_\s]{1}[A-Za-z0-9]+)*$/i)
    );
    const resourceDataProject = useResource(
        `${process.env.REACT_APP_API_V2_URL}/settings/projects?userId=${userID}&franchiseId=${franchiseID}`
    );
    function listAssociatesShops() {
        let projects: any[] = [];
        if (resourceDataProject.resourceData.data.length > 0) {
            resourceDataProject.resourceData.data?.forEach((element: any) => {
                Object.entries(element?.associates).forEach((item: any) => {
                    if (item[1].id_boutique === selectedRows?.shopId) {
                        projects.push(element.projectId);
                    }
                });
            });
        }
        return projects;
    }

    const checkIfApplicationExist = React.useCallback(() => {
        let isApplicationListExist = false;
        let allData: any[] = JSON.parse(JSON.stringify(applicationListData));

        allData.forEach((el: any) => {
            if (
                el[t("Designation")].toUpperCase() === designation.toUpperCase()
            ) {
                isApplicationListExist = true;
                return;
            }
        });

        return isApplicationListExist;
    }, [applicationListData, designation, t]);

    async function handleValidateButtonClickEvent() {
        let idProject: any = listAssociatesShops();
        let existData: any = {
            designation: designation,
            ip: ip,
            note: remark,
        };
        let NewData: any = {
            designation: selectedRows
                ? selectedRows[t("Designation")] !== undefined
                    ? selectedRows[t("Designation")]
                    : selectedRows[t("Application")]
                : designation,
            ip: selectedRows ? selectedRows[t("IP adress")] : ip,
            note: selectedRows ? selectedRows[t("Remark")] : remark,
        };
        toast.dismiss();
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application`;
        if (isComingFromModify !== true) {
            let userId: number;
            let shopId: string;
            let franchiseId: string;
            if (oneShop) {
                userId = userID;
                shopId = shopID.toString();
                franchiseId = franchiseID;
            } else {
                userId = userID;
                shopId = selectedRows.shopId;
                franchiseId = franchiseID;
            }
            let savedData = {
                userId: userId,
                shopId: shopId,
                franchiseId: franchiseId,
                operatorId: operatorID,
                data: {
                    [application]: {
                        id_application: application,

                        idProject: selectedRows?.idProject
                            ? selectedRows.idProject
                            : idProject,
                        mark: "",
                        note: "",
                        status: "",
                        state: "",
                        designation: "",
                        template: {},
                        data_app: {
                            [designation]: {
                                id_application_children: designation,
                                designation: designation,
                                printer: {},
                                monetic: {},
                                cash_management: {},
                                template: {},
                                ip: ip,
                                note: remark,
                                status: "Offline",
                                state: "To configure",
                                isArchived: false,
                            },
                        },
                    },
                },
            };
            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bareer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify(savedData),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error while saving selection!");
                            }
                            toast.success(
                                `${t(
                                    "The application was successfully added"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setLocalNamesApplications([
                                ...localNamesApplications,
                                designation,
                            ]);
                            setIps([...ips, ip]);
                            setDesignation("");
                            setRemark("");
                            setDataIsUpdated(true);
                            setIp("");
                            setInputDesignation(false);
                            setGlobalDataIsUpdated(true);
                        })
                );
            } catch (e: any) {
                toast.error(`${t("There's an error")}!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        } else {
            if (JSON.stringify(existData) === JSON.stringify(NewData)) {
                setIsConfirmationEditModalOpened(true);
            } else {
                let userId: number;
                let shopId: string;
                let franchiseId: string;
                if (oneShop) {
                    userId = userID;
                    shopId = shopID.toString();
                    franchiseId = franchiseID;
                } else {
                    userId = userID;
                    shopId = selectedRows.shopId;
                    franchiseId = franchiseID;
                }
                let savedData = {
                    userId: userId,
                    shopId: shopId,
                    franchiseId: franchiseId,
                    operatorId: operatorID,
                    data: {
                        KIOSK: {
                            id_application:
                                selectedRows["key_application"] === undefined
                                    ? selectedRows[t("Application")]
                                    : selectedRows["key_application"],
                            note: "",
                            status: "",
                            state: "",
                            designation: "",
                            data_app: {
                                [selectedRows["appId"]]: {
                                    id_application_children:
                                        selectedRows["appId"],
                                    designation: designation,
                                    state: "To configure",
                                    status: "Offline",
                                    ip: ip,
                                    note: remark,
                                    printer: {},
                                    monetic: {},
                                    cash_management: {},
                                    template: selectedRows.templateApp,
                                },
                            },
                        },
                    },
                };
                try {
                    mutate(
                        apiUrl,
                        await fetch(apiUrl, {
                            headers: {
                                "Content-Type": "application/json",
                                authorization: `Bareer ${localStorage.getItem(
                                    "jwt"
                                )}`,
                            },
                            body: JSON.stringify(savedData),
                            method: "PUT",
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                if (data.error) {
                                    throw Error("Error!");
                                }
                                toast.success(
                                    `${t(
                                        "The application was successfully changed"
                                    )!}`,
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 2000,
                                        theme: "colored",
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        icon: (
                                            <CheckboxIcon
                                                height={25}
                                                width={25}
                                                fill="white"
                                            />
                                        ),
                                    }
                                );
                                setDataIsUpdated!(true);
                                setIsAddAppClicked!(!isAddAppClicked);
                                setGlobalDataIsUpdated(true);
                            })
                    );
                } catch (e: any) {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        }
    }

    const activateCheckbox = () => {
        let checks = document.getElementsByClassName("PrivateSwitchBase-input");
        for (let i = 0; i < checks.length; i++) {
            checks[i].classList.remove("not-allowed-icon-check__clz");
        }
    };
    useEffect(() => {
        setDesignation("");
        setApplication("KIOSK");
        setRemark("");
        setIp("");
        setInputDesignation(false);
        setExist(false);
        // eslint-disable-next-line
    }, [isAddNewAppButtonClicked]);
    useEffect(() => {
        if (isComingFromModify === true && selectedRows !== undefined) {
            setDesignation(
                selectedRows[t("Designation")] !== undefined
                    ? selectedRows[t("Designation")]
                    : selectedRows[t("Application")]
            );
            setApplication("KIOSK");
            setRemark(selectedRows[t("Remark")]);
            setIp(selectedRows[t("IP adress")]);
        } else {
            setDesignation("");
            setApplication("KIOSK");
            setRemark("");
            setIp("");
            setInputDesignation(false);
            setExist(false);
        }
        listAssociatesShops();
        checkIfApplicationExist();

        // eslint-disable-next-line
    }, [t, isComingFromModify, selectedRows]);

    useEffect(() => {
        let isApplicationListExist = checkIfApplicationExist();
        if (
            isApplicationListExist &&
            (isComingFromModify !== true ||
                (isComingFromModify === true &&
                    selectedRows[t("Designation")] !== designation))
        ) {
            setIsApplicationListExist(true);
        } else {
            setIsApplicationListExist(false);
        }

        const localNamesApplications: string[] = [];

        if (oneShop === false && selectedRows !== undefined) {
            if (selectedRows.subRows !== undefined) {
                if (selectedRows.subRows.length > 0) {
                    selectedRows.subRows.forEach((datum: any) => {
                        localNamesApplications.push(datum[t("Application")]);
                    });
                }
            }
        } else {
            if (data?.data !== undefined && data.data?.length > 0) {
                data.data.forEach((datum: any) => {
                    localNamesApplications.push(datum[t("Application")]);
                });
            }
        }
        let shopId: string = oneShop
            ? shopID
            : selectedRows?.shopId !== undefined
            ? selectedRows.shopId
            : "";
        setLocalNamesApplications(localNamesApplications);
        setIps(
            resourceIp
                // eslint-disable-next-line
                .filter((x: any) => {
                    if (x.shopId === shopId) {
                        return x.ip;
                    }
                })
                .map((x: any) => {
                    return x.ip;
                })
        );

        let check: any = checkIpIfExist(
            resourceIp,
            ip,
            selectedRows?.appId !== undefined ? selectedRows?.appId : "",
            shopId
        );

        setExist((prevState: any) => ({
            ...prevState,
            isIpExist: check,
        }));
        // eslint-disable-next-line
    }, [oneShop, selectedRows, data, isComingFromModify, designation, ip]);

    React.useEffect(() => {
        let shopId: string = oneShop
            ? shopID
            : selectedRows?.shopId !== undefined
            ? selectedRows.shopId
            : "";
        // eslint-disable-next-line
        let resCheckName: any = resourceIp.filter((x: any) => {
            return selectedRows?.appId !== undefined
                ? x.type === "application-KIOSK" &&
                      x.shopId === shopId &&
                      x.designation !== selectedRows?.appId
                : x.type === "application-KIOSK" && x.shopId === shopId;
        });

        // eslint-disable-next-line
        setIsApplicationListExist(
            isComingFromModify
                ? resCheckName.filter((x: any) => {
                      return (
                          x.designation.toUpperCase() ===
                              designation.toUpperCase() &&
                          x.designation.toUpperCase() !==
                              selectedRows[t("Application")].toUpperCase()
                      );
                  }).length > 0
                : resCheckName.filter((x: any) => {
                      return (
                          x.designation.toUpperCase() ===
                          designation.toUpperCase()
                      );
                  }).length > 0
        );

        // eslint-disable-next-line
    }, [designation, selectedRows]);

    return (
        <AvForm className="card">
            <CustomCardHeader className="d-flex align-items-center justify-content-between">
                <h5 className="pt-3">
                    {isConsult
                        ? t("View an application")
                        : isComingFromModify === false
                        ? t("Add an application")
                        : t("Modify an application")}
                </h5>
                <CrossIcon
                    style={{ cursor: "pointer" }}
                    height={13}
                    width={13}
                    onClick={() => {
                        if (isEdited === true) {
                            setIsActionsClicked(!isActionsClicked);
                            setActions(() => {
                                activateCheckbox();
                                setIsAddAppClicked!(!isAddAppClicked);
                                setIsEdited(false);
                                setIsConsult(false);
                            });
                        } else {
                            activateCheckbox();
                            setIsConsult(false);
                            setIsAddAppClicked!(!isAddAppClicked);
                        }
                    }}
                />
            </CustomCardHeader>
            <CustomCardBody className="pl-3 pr-5">
                <AvGroup>
                    <StyledLabel htmlFor="name" className="required__clz mt-3">
                        {t("Designation")} (KIOSK1,KIOSK2...)
                    </StyledLabel>
                    <StyledTextInput
                        autocomplete="off"
                        className={classnames("afa_inp_designation", {
                            input__clz:
                                inputDesignation ||
                                isApplicationListExist ||
                                (designation.trim() === "" &&
                                    designation !== ""),
                            readOnly__clz: isConsult,
                        })}
                        id="designation"
                        name="designation"
                        placeholder={t("Enter")}
                        type="text"
                        onChange={(e: any) => {
                            setDesignation(e.target.value);
                            if (setIsEdited !== undefined) setIsEdited!(true);
                            setInputDesignation(false);
                        }}
                        value={designation}
                        validate={{
                            pattern: {
                                value: matchWordDesignation,
                                errorMessage: t("Prohibited characters"),
                            },
                        }}
                        autoFocus={isConsult === false}
                    />
                    {inputDesignation ||
                    (designation.trim() === "" && designation !== "") ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("Please enter a designation")}
                        </div>
                    ) : null}
                    {isApplicationListExist ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("The name is already exists")!}
                        </div>
                    ) : null}
                </AvGroup>
                <AvGroup>
                    <StyledLabel htmlFor="example-input" className="mt-3">
                        {t("IP address")}
                    </StyledLabel>
                    <StyledTextInput
                        id="ip"
                        name="ip"
                        autocomplete="off"
                        placeholder={t("Enter")}
                        type="text"
                        onChange={(e: any) => {
                            setIp(e.target.value);
                            if (setIsEdited !== undefined) setIsEdited!(true);

                            let shopId: string = oneShop
                                ? shopID
                                : selectedRows?.shopId !== undefined
                                ? selectedRows.shopId
                                : "";

                            let check: any = checkIpIfExist(
                                resourceIp,
                                e.target.value,
                                selectedRows?.appId !== undefined
                                    ? selectedRows?.appId
                                    : "",
                                shopId
                            );

                            setExist((prevState: any) => ({
                                ...prevState,
                                isIpExist: check,
                            }));
                        }}
                        className={classnames("afa_inp_ipAddress", {
                            input__clz:
                                (!ip.match(ipMatchWord) && ip !== "") ||
                                (exist.isIpExist && ip !== ""),
                            readOnly__clz: isConsult,
                        })}
                        value={ip}
                    />

                    {exist.isIpExist && ip !== "" ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("The ip address is already exists")!}
                        </div>
                    ) : !ip.match(ipMatchWord) && ip !== "" ? (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("IP address is invalid")!}
                        </div>
                    ) : null}
                </AvGroup>
                <AvGroup>
                    <StyledLabel className="mt-3" htmlFor="remark">
                        {t("Remark")} (
                        {`${t("Left Kiosk")},${t("Right kiosk")}`})
                    </StyledLabel>
                    <StyledTextInput
                        autocomplete="off"
                        id="remark"
                        name="remark"
                        placeholder={t("Enter")}
                        type="text"
                        onChange={(e: any) => {
                            setRemark(e.target.value);
                            if (setIsEdited !== undefined) setIsEdited!(true);
                        }}
                        value={remark}
                        className={classnames("afa_inp_remark", {
                            readOnly__clz: isConsult,
                        })}
                    />
                </AvGroup>
            </CustomCardBody>
            <CustomCardFooter className="pt-1 pb-2">
                <CustomSecondaryColorButton
                    outline
                    rounded
                    variant="light"
                    className="mr-2 afa_btn_FRcancel"
                    onClick={() => {
                        if (isEdited === true) {
                            setIsActionsClicked(!isActionsClicked);
                            setActions(() => {
                                activateCheckbox();
                                setIsConsult(false);
                                setIsAddAppClicked!(!isAddAppClicked);
                                if (setIsEdited !== undefined) {
                                    setIsEdited!(true);
                                }
                                setIsEdited(false);
                            });
                        } else {
                            setIsConsult(false);
                            activateCheckbox();
                            setIsAddAppClicked!(!isAddAppClicked);
                            if (setIsEdited !== undefined) {
                                setIsEdited!(true);
                            }
                        }
                    }}
                >
                    <span> {t("Cancel")}</span>
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    variant="primary"
                    onClick={() => {
                        if (designation === "") {
                            setInputDesignation(true);
                        }
                        if (
                            (ip.trim() !== "" ? ip.match(ipMatchWord) : true) &&
                            designation.length !== 0 &&
                            !isApplicationListExist &&
                            designation.trim() !== "" &&
                            designation.match(matchWordDesignation) &&
                            (ip.trim() !== "" ? !exist.isIpExist : true)
                        )
                            handleValidateButtonClickEvent();
                        activateCheckbox();
                    }}
                    className={classnames("afa_btn_FRvalidate", {
                        readOnly__clz: isConsult,
                        "not-allowed-icon__clz": isConsult,
                    })}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </CustomCardFooter>
        </AvForm>
    );
}
