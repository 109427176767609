import React from "react";
import { NProgress } from "@tanem/react-nprogress";

import Bar from "./Bar";
import Container from "./Container";
import Spinner from "./Spinner";

import "./index.css";

type NProgressWrapperPropsType = { isLoading: boolean };

export default function NProgressWrapper({
    isLoading,
}: NProgressWrapperPropsType) {
    return (
        <React.Fragment>
            <NProgress isAnimating={isLoading}>
                {({ isFinished, progress, animationDuration }) => (
                    <Container
                        isFinished={isFinished}
                        animationDuration={animationDuration}
                    >
                        <Bar
                            progress={progress}
                            animationDuration={animationDuration}
                        />
                        {isLoading && <Spinner />}
                    </Container>
                )}
            </NProgress>
        </React.Fragment>
    );
}
