import { CheckboxIcon } from "@aureskonnect/react-ui";
import React from "react";
import { ModalFooter } from "reactstrap";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import moment from "moment";
import { toast } from "react-toastify";

import { store as userStore } from "@components/VerticalLayout/store";
import {
    setIsModesModalPublishOpened,
    setIsLoading,
    store,
} from "../../../store/project";
import ModalAssociateStore from "./ModalAssociateStore";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import "@assets/swal.css";
import "@assets/theme.css";

type CardFooterModalPublishType = {
    rowData: any;
    setIsDataUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
};

export default function CardFooterModalPublish({
    rowData,
    setIsDataUpdated,
}: CardFooterModalPublishType) {
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isValidatedClicked, setIsValidatedClicked] = React.useState<boolean>(
        false
    );

    const { isModesModalPublishOpened } = useSnapshot(store);
    const {
        userID,
        franchiseID,
        oneShop,
        shopID,
        shopName,
        operatorID,
    } = useSnapshot(userStore);
    const { t } = useTranslation();
    const { projectData } = useSnapshot(store);

    function AssociateProject() {
        let idShops: any = [];
        if (rowData !== undefined) {
            Object.values(rowData.shopKey) !== undefined &&
                Object.values(rowData.shopKey).forEach((el: any) => {
                    idShops.push(el);
                });
        }
        idShops = [...new Set(idShops), "0"];
        return idShops;
    }
    function isAssociate() {
        let projectId: any = [];
        let associateskeys: any = [];
        let isAssociateShops: boolean = false;
        if (projectData !== undefined) {
            projectData.forEach((el: any) => {
                Object.values(el?.associates).forEach((elment: any) => {
                    if (rowData?.associates !== undefined) {
                        Object.values(rowData?.associates).forEach(
                            (item: any) => {
                                if (
                                    elment?.id_boutique === item?.id_boutique &&
                                    el?.Status === "Publié"
                                ) {
                                    associateskeys.push(el?.shopKey);
                                    projectId.push(el?.projectId);
                                }
                            }
                        );
                    }
                });
            });
        }
        if (Object.keys(associateskeys).length > 0) {
            isAssociateShops = true;
        }
        return isAssociateShops;
    }

    let isAssociateShops: boolean = isAssociate();

    async function handleSaveButtonOnClickEvent() {
        let localData: any = [];

        let data: any = {};
        data[shopID] = {
            designation: shopName,
            id_boutique: shopID,
            hoursOfUse:
                rowData.hoursOfUse !== undefined ? rowData.hoursOfUse : "",
        };
        let dataAssociate: any[] = AssociateProject();
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/status`;
        let savedData = {
            userId: userID,
            franchiseId: franchiseID,
            operatorId: operatorID,
            shopId: oneShop ? [shopID, "0"] : dataAssociate,
            projectId: rowData.projectId,
            status: "Publié",
            modified_at: `le ${moment().format("DD/MM/YY à HH:mm a")}`,
        };
        localData.push(
            fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(savedData),
            }).then((response) => response.json())
        );

        try {
            mutate(
                apiUrl,
                await Promise.all(localData).then((result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                        toast.success(
                            `${t("The project is published successfully")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsDataUpdated(true);
                        setIsModesModalPublishOpened(
                            !isModesModalPublishOpened
                        );
                    }
                })
            );
        } catch (e: any) {
            console.log(`${t("There's an error")}!`);
        }
    }
    async function handleSaveButtonOnClickEventDissociationShops() {
        let localData: any = [];

        let dataAssociate: any[] = AssociateProject();
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/status`;
        let savedData = {
            userId: userID,
            franchiseId: franchiseID,
            shopId: dataAssociate,
            projectId: rowData.projectId,
            operatorId: operatorID,
            status: "Publié",
            modified_at: `le ${moment().format("DD/MM/YY à HH:mm a")}`,
        };

        localData.push(
            fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(savedData),
            }).then((response) => response.json())
        );

        try {
            mutate(
                apiUrl,
                await Promise.all(localData).then(async (result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                        toast.success(
                            `${t("The project is published successfully")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        dissociationShops();

                        setIsModalOpened(false);
                    } else {
                        throw Error(result.message);
                    }
                })
            );
        } catch (e: any) {
            console.log(`${t("There's an error")}!`);
        }
        setIsDataUpdated(true);
    }

    function handleOnClickEvent() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/projects/delete`;
        let localData: any = [];
        let projectId: string[] = [];
        let associateskeys: any = [];
        let associateShops: any = [];
        let projectIdProject: any = [];
        projectData.forEach((el: any) => {
            Object.values(el?.associates).forEach((elment: any) => {
                Object.values(rowData.associates).forEach((item: any) => {
                    if (
                        elment?.id_boutique === item?.id_boutique &&
                        el?.Status === "Publié"
                    ) {
                        associateskeys.push(item?.id_boutique);
                        associateShops.push(...el.shopKey);
                        projectId.push(el?.projectId);
                        projectIdProject.push(el);
                    }
                });
            });
        });

        let savedData = {
            userId: userID,
            franchiseId: franchiseID,
            shopId: associateskeys.join(","),
            projectId: "PROJECT",
            operatorId: operatorID,
            project_id: projectId.join(","),
        };

        localData.push(
            fetch(apiUrl, {
                method: "delete",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(savedData),
            }).then((response) => response.json())
        );
        try {
            mutate(
                apiUrl,
                Promise.all(localData).then((result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                        setIsDataUpdated(true);
                    } else {
                        throw Error(result.message);
                    }
                })
            );
        } catch (e: any) {
            console.log(`${t("There's an error")}!`);
        }
    }
    function dissociationShops() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/projects/dissociation/shops`;
        let apiProject = `${process.env.REACT_APP_API_V2_URL}/settings/project`;
        let projectId: string = "";
        let associateskeys: any = [];
        let associateShops: any = [];
        let projectIdProject: any = [];
        projectData.forEach((el: any) => {
            Object.values(el?.associates).forEach((elment: any) => {
                Object.values(rowData.associates).forEach((item: any) => {
                    if (
                        elment?.id_boutique === item?.id_boutique &&
                        el?.Status === "Publié"
                    ) {
                        associateskeys.push(item?.id_boutique);
                        associateShops.push(...el.shopKey);
                        projectId = el?.projectId;
                        projectIdProject.push(el);
                    }
                });
            });
        });
        associateskeys = [...new Set(associateskeys)];
        associateShops = [...new Set(associateShops)];
        setIsLoading(true);
        if (associateShops.length === associateskeys.length) {
            for (let element of projectIdProject) {
                let savedDataProject = {
                    userId: userID,
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    shopId: "0",
                    data: {
                        PROJECT: {
                            id_project: "PROJECT",
                            data_project: {
                                [element.projectId]: {
                                    projectId: element.projectId,
                                    designation: element.Désignation,
                                    associates: {},
                                    isArchived: true,
                                    template: element.template,
                                    files: element.files,
                                    note: element.Remarque,
                                    nombre: element.Nombre,
                                    status: "en attente",
                                    modified_at: `le ${moment().format(
                                        "DD/MM/YY à HH:mm a"
                                    )}`,
                                    hour: `${moment().format(
                                        "DD/MM/YY - HH:mm a"
                                    )}`,
                                },
                            },
                        },
                    },
                };

                try {
                    mutate(
                        apiProject,
                        fetch(apiProject, {
                            headers: {
                                "Content-Type": "application/json",
                                authorization: `Bareer ${localStorage.getItem(
                                    "jwt"
                                )}`,
                            },
                            body: JSON.stringify(savedDataProject),
                            method: "POST",
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                if (data.error) {
                                    throw Error(
                                        "Error while saving selection!"
                                    );
                                } else {
                                    setIsDataUpdated(true);
                                }
                            })
                    );
                } catch (e: any) {
                    console.log(`${t("There's an error")}!`);
                }
            }
        }
        if (Object.keys(associateskeys).length !== 0) {
            handleOnClickEvent();
            let savedData = {
                userId: userID,
                franchiseId: franchiseID,
                project_id: "PROJECT",
                projectId: projectId,
                operatorId: operatorID,
                shopId: "0",
                shopsKey: associateskeys.join(","),
            };

            try {
                mutate(
                    apiUrl,
                    fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bareer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify(savedData),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then(async (data) => {
                            if (data.error) {
                                throw Error("Error while saving selection!");
                            }
                        })
                );
                setIsLoading(false);
            } catch (e: any) {
                console.log(`${t("There's an error")}!`);
            }
        }
    }

    React.useEffect(() => {
        if (isValidatedClicked) {
            handleSaveButtonOnClickEventDissociationShops();
        }
        // eslint-disable-next-line
    }, [isValidatedClicked]);

    return (
        <React.Fragment>
            <ModalFooter className="border-top-0">
                <CustomMainColorButton
                    outline
                    variant="primary"
                    onClick={() => {
                        setIsModesModalPublishOpened!(
                            !isModesModalPublishOpened
                        );
                        setIsDataUpdated(true);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomMainColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        if (!isAssociateShops || oneShop) {
                            handleSaveButtonOnClickEvent();
                        } else {
                            setIsModalOpened(true);
                        }
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
            <ModalAssociateStore
                setIsModalOpened={setIsModalOpened}
                isModalOpened={isModalOpened}
                setIsValidatedClicked={setIsValidatedClicked}
                rowData={rowData}
            />
        </React.Fragment>
    );
}
