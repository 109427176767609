import React from "react";

export function ModifyIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 83 83"
        >
            <defs>
                <filter
                    id="Rectangle_11391"
                    width="83"
                    height="83"
                    x="0"
                    y="0"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="3"></feOffset>
                    <feGaussianBlur
                        result="blur"
                        stdDeviation="3"
                    ></feGaussianBlur>
                    <feFlood floodOpacity="0.302"></feFlood>
                    <feComposite in2="blur" operator="in"></feComposite>
                    <feComposite in="SourceGraphic"></feComposite>
                </filter>
            </defs>
            <g data-name="Groupe 30533" transform="translate(9 6)">
                <g data-name="Groupe 30532">
                    <g
                        filter="url(#Rectangle_11391)"
                        transform="translate(-9 -6)"
                    >
                        <rect
                            width="65"
                            height="65"
                            fill="#f8f8f6"
                            data-name="Rectangle 11391"
                            rx="15"
                            transform="translate(9 6)"
                        ></rect>
                    </g>
                    <path
                        d="M33.706 18.347a.9.9 0 111.806 0v12.639A4.514 4.514 0 0131 35.5H7.5a4.514 4.514 0 01-4.514-4.514V7.514A4.514 4.514 0 017.5 3h12.664a.9.9 0 110 1.806H7.5a2.708 2.708 0 00-2.708 2.708v23.472A2.708 2.708 0 007.5 33.694H31a2.708 2.708 0 002.708-2.708zm-2.07-15.083l3.611 3.611a.9.9 0 010 1.277L20.8 22.6a.9.9 0 01-.638.264h-3.609a.9.9 0 01-.9-.9v-3.617a.9.9 0 01.264-.638L30.359 3.264a.9.9 0 011.277 0zM31 5.179L17.456 18.721v2.334h2.334L33.332 7.514z"
                        transform="translate(13.251 13.25)"
                    ></path>
                </g>
            </g>
        </svg>
    );
}
