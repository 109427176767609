import { CategoriesIcon } from "@components/Common/SvgIcons/CategoriesIcon";

export type CardMenuType = {
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    description?: string;
    title: string;
    number?: string;
    path: string;
};

export const CARD_MENU_LIST: CardMenuType[] = [
    {
        title: "KIOSK",
        number: '0',
        Icon: CategoriesIcon,
        description:
            "Description à ajouter.Description à ajouter.Description à ajouter.Description à ajouter.",
        path: "/kiosk",
    },
    // {
    //     title: "C&C",
    //     number: '0',
    //     Icon: CategoriesIcon,
    //     description:
    //         "Description à ajouter.Description à ajouter.Description à ajouter.Description à ajouter.",
    //     path: "/c&c",
    // },
    // {
    //     title: "KDS",
    //     number: '0',
    //     Icon: CategoriesIcon,
    //     description:
    //         "Description à ajouter.Description à ajouter.Description à ajouter.Description à ajouter.",
    //     path: "/kds",
    // },
    // {
    //     title: "SCO",
    //     number: '0',
    //     Icon: CategoriesIcon,
    //     description:
    //         "Description à ajouter.Description à ajouter.Description à ajouter.Description à ajouter.",
    //     path: "/sco",
    // },

];
