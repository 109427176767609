import React from "react";
import { useSnapshot } from "valtio";

import { computedStore, designStore } from "@store";

import "@pages/GeneralConfig/Design/index.css";

export function Model(): JSX.Element {
    const { activeSlideIndex } = useSnapshot(designStore);
    const { elements } = useSnapshot(computedStore);

    return (
        <div>
            <div className="d-flex justify-content-center align-items-center p-2 mx-2 cursor__clz">
                <span
                    style={{
                        fontSize: "14px",
                        color: "#AFAFAF",
                        marginLeft: "10px",
                        whiteSpace: "nowrap",
                    }}
                >
                    Modele par defaut template ETK
                </span>
                <hr
                    style={{
                        height: "1px",
                        width: "79%",
                        backgroundColor: "#AFAFAF",
                        border: "0px",
                    }}
                ></hr>
            </div>
            <div className="model-items__clz p-2 mx-4">
                <div
                    className="active-model-item__clz"
                    style={{
                        border: "1px solid",
                        borderRadius: "6px",
                        height: "230px",
                        width: "150px",
                        backgroundColor: "#DEDEDE",
                        position: "relative",
                    }}
                >
                    <img
                        alt=""
                        src={`./images/${elements[activeSlideIndex].content}`}
                        style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "fill",
                            position: "absolute",
                            borderRadius: "6px",
                        }}
                    />
                </div>
                <span
                    className="cursor__clz"
                    style={{
                        fontSize: "17px",
                        textAlign: "center",
                        width: "150px",
                    }}
                >
                    Modele 1
                </span>
            </div>
            <div className="d-flex justify-content-center align-items-center p-2 mx-2 cursor__clz">
                <span
                    style={{
                        fontSize: "14px",
                        color: "#AFAFAF",
                        marginLeft: "10px",
                        whiteSpace: "nowrap",
                    }}
                >
                    Autres Modeles
                </span>
                <hr
                    style={{
                        height: "1px",
                        width: "89%",
                        backgroundColor: "#AFAFAF",
                        border: "0px",
                        marginLeft: "10px",
                    }}
                ></hr>
            </div>
        </div>
    );
}
