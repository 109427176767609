import React from "react";
import { useTranslation } from "react-i18next";
import { StyledH2, StyledModal } from "@aureskonnect/react-ui";
import { ModalBody, ModalHeader } from "reactstrap";
import { setDataModal } from "./store";

import CostumerForm from "./CostumerForm";
import { useSnapshot } from "valtio";

import { store } from "@components/VerticalLayout/store";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

export default function ModalInfo({
    isAddCustomerAccountModalClicked,
    setIsAddCustomerAccountModalClicked,
    cosutmerName,
    uid,
    setDataIsUpdated,
}: any) {
    const { t } = useTranslation();
    const { franchiseID } = useSnapshot(store);

    return (
        <StyledModal
            toggle={true}
            isOpen={isAddCustomerAccountModalClicked}
            centered
            className="modal-wrapper__clz"
            backdrop="static"
            fade={false}
            scrollable
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsAddCustomerAccountModalClicked(
                        !isAddCustomerAccountModalClicked
                    );
                    setDataModal({});
                    setDataIsUpdated(true);
                }}
            >
                <StyledH2 className="text-uppercase">
                    {t("Customer account") + " : " + cosutmerName}
                </StyledH2>
            </ModalHeader>
            <ModalBody>
                <CostumerForm franchise={franchiseID} />
            </ModalBody>
        </StyledModal>
    );
}
