import React from "react";
import { StyledH2 } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";

import { store } from "../../../store";

import BellConcierge from "@components/Common/SvgIcons/BellConcierge";
import ModelNumber from "@components/Common/ModelNumber";

export  function Model5() {
    const { t } = useTranslation();
     const { model } = useSnapshot(store);
     const modalTemplate: any = JSON.parse(
         JSON.stringify(Object.values(model))
     )[0];

    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr",
            }}
        >
            <div
                style={{
                    background:
                        "rgb(255, 255, 255) none no-repeat scroll 0% 0% padding-box",
                    border: "1px solid rgb(230, 230, 230)",
                    color: "#212529 ",
                    gap: "1px",
                    height: "120%",
                    
                }}
            >
                <StyledH2>
                    <span className="d-flex ml-3 mb">
                        {t("Please present your ticket at the cash desk")}
                    </span>
                </StyledH2>
            </div>
            <div
                style={{
                    backgroundColor:
                        modalTemplate === undefined
                            ? "#000"
                            : modalTemplate[0]["content"],
                    height: "120%",
                }}
            >
                {[1, 2, 3, 4, 5].reverse().map((elt: any) => {
                    return (
                        <div className="d-flex mt-3" style={{ gap: "2%" }}>
                            <BellConcierge
                                fill={
                                    modalTemplate === undefined
                                        ? "rgb(230, 230, 230)"
                                        : modalTemplate[1]["content"]
                                }
                            />
                            <ModelNumber
                                text={`N° 00${elt}`}
                                colorPrimary={
                                    modalTemplate === undefined
                                        ? "#000"
                                        : modalTemplate[0]["content"]
                                }
                                colorSecondary={
                                    modalTemplate === undefined
                                        ? "#E6E6E6"
                                        : modalTemplate[1]["content"]
                                }
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
