import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledLabel, CheckboxIcon } from "@aureskonnect/react-ui";
import { mutate } from "swr";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";

import { capitalize } from "@helpers/general";
import { store } from "@components/VerticalLayout/store";
import {
    store as storeLogo,
    setIsDataUpdated as setIsDataUpdatedShops,
    setIsValidateClicked,
    setLogoData,
    setNumberActive,
} from "../store";
import { resetAllPeripherals } from "@pages/Applications/DeviceAssociateModal/store";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationAssociationModalType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    designation: string;
    setDataIsUpdated: Function;
    setIsClickedBouttonValider: Function;
    setDesignation: Function;
    setSalesSupport: Function;
    setUploadedImage: Function;
    setLocalFilterActive: Function;
    salesSupport: any;
};

export function ConfirmationAssociationBoutiqueModal({
    isModalOpened,
    setIsModalOpened,
    designation,
    setDataIsUpdated,
    setIsClickedBouttonValider,
    setDesignation,
    setSalesSupport,
    setUploadedImage,
    setLocalFilterActive,
    salesSupport,
}: ConfirmationAssociationModalType) {
    const { t } = useTranslation();
    const { dataFranchiseLogo, numberActive } = useSnapshot(storeLogo);
    const { franchiseID, userID, shopID, operatorID } = useSnapshot(store);

    async function AssociateLogo() {
        setIsClickedBouttonValider(true);
        setIsValidateClicked(false);
        toast.dismiss();
        let objectData =
            dataFranchiseLogo[
                dataFranchiseLogo.findIndex(
                    (el: any) => el[t("Designation")] === designation
                )
            ];

        let dataLogo = {
            affectation: true,
            userId: userID,
            shopId: [shopID],
            franchiseId: franchiseID,
            operatorId: operatorID,
            data: {
                [objectData.Id]: {
                    designation: objectData[t("Designation")],
                    tag: objectData.tag,
                    isArchived: false,
                    support_de_vente: salesSupport.join(","),
                    shopId: shopID,
                    urlImage: objectData.Image,
                    isActive: objectData.isActive,
                    img: objectData.img,
                },
            },
        };

        const data = new FormData();
        data.append("dataLogo", JSON.stringify(dataLogo));
        data.append("image", "");
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/logo`;
        const requestOptions = {
            method: "POST",
            body: data,
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(`${t("logo is successfully added")!}`, {
                            position: toast.POSITION.TOP_CENTER,
                            theme: "colored",
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        });
                        setDataIsUpdated(true);
                        setIsDataUpdatedShops(true);
                        setIsValidateClicked(true);
                        setLogoData({});
                        resetAllPeripherals();
                        setDesignation("");
                        setSalesSupport([]);
                        setUploadedImage("");
                        setNumberActive(numberActive + 1);
                        setLocalFilterActive(false);
                        clearData();
                        clearMultiCustomSelectionData();
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => setIsModalOpened(!isModalOpened)}
            >
                <span>{capitalize(t("Alert"))}</span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {t(
                        "The logo already exists in the franchise. do you want to associate it with this shop?"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                        setIsClickedBouttonValider(true);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>

                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        AssociateLogo();
                        setIsModalOpened(!isModalOpened);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
