import { StyledH2 } from "@aureskonnect/react-ui";
import { numberMatchWord } from "@constants/index";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NumericInput from "react-numeric-input";
import Switch from "react-switch";
import { Button } from "reactstrap";
import { mutate } from "swr";
import { useSnapshot } from "valtio";
import {
    setDuration,
    setIsModalOpenedOrders,
    setOrders,
    setPattern,
    store,
} from "../store";
import { store as useStore } from "@components/VerticalLayout/store";

import ModalOrders from "./ModalOrders";

export default function Orders() {
    const { t } = useTranslation();
    const {
        isModalOpenedOrders,
        duration,
        pattern,
        dataSetupApk,
        apkId,
    } = useSnapshot(store);
    const { userID, franchiseID } = useSnapshot(useStore);
    const [isMinimumPrice, setIsMinimumPrice] = React.useState<boolean>(
        dataSetupApk[0] !== undefined && dataSetupApk[0].orders.isMinimumPrice
            ? dataSetupApk[0].orders.isMinimumPrice
            : false
    );
    const [isDeferredOrders, setIsDeferredOrders] = React.useState<boolean>(
        dataSetupApk[0] !== undefined && dataSetupApk[0].orders.isDeferredOrders
            ? dataSetupApk[0].orders.isDeferredOrders
            : false
    );
    const [isValidationnumDelivrey, setIsValidationnumDelivrey] = useState<
        boolean
    >(false);
    const [isValidationnumTheSpot, setIsValidationnumTheSpot] = useState<
        boolean
    >(false);
    const [isValidationnumToTake, setIsValidationnumToTake] = useState<boolean>(
        false
    );

    const [isPauseOrders, setIsPauseOrders] = useState<boolean>(
        dataSetupApk[0] !== undefined && dataSetupApk[0].orders.isPauseOrders
            ? dataSetupApk[0].orders.isPauseOrders
            : false
    );
    const [delivery, setDelivery] = React.useState<number>(
        dataSetupApk[0] !== undefined && dataSetupApk[0].orders.delivery
            ? dataSetupApk[0].orders.delivery
            : 30
    );
    const [onTheSpot, setOnTheSpot] = React.useState<number>(
        dataSetupApk[0] !== undefined && dataSetupApk[0].orders.onTheSpot
            ? dataSetupApk[0].orders.onTheSpot
            : 15
    );
    const [toTake, setToTake] = React.useState<number>(
        dataSetupApk[0] !== undefined && dataSetupApk[0].orders.toTake
            ? dataSetupApk[0].orders.toTake
            : 15
    );
    const [localDuration, setLocalDuration] = React.useState<any>(
        dataSetupApk[0] !== undefined && dataSetupApk[0].orders.duration
            ? dataSetupApk[0].orders.duration
            : duration
    );
    const [localPattern, setLocalPattern] = React.useState<any>(
        dataSetupApk[0] !== undefined && dataSetupApk[0].orders.pattern
            ? dataSetupApk[0].orders.pattern
            : pattern
    );
    const [numberToTake, setNumberToTake] = useState<number>(
        dataSetupApk[0] !== undefined && dataSetupApk[0].orders.numberToTake
            ? dataSetupApk[0].orders.numberToTake
            : 0
    );
    const [numberOnTheSpot, setNumberOnTheSpot] = useState<number>(
        dataSetupApk[0] !== undefined && dataSetupApk[0].orders.numberOnTheSpot
            ? dataSetupApk[0].orders.numberOnTheSpot
            : 0
    );
    const [maximumPeriod, setMaximumPeriod] = useState<number>(
        dataSetupApk[0] !== undefined && dataSetupApk[0].orders.maximumPeriod
            ? dataSetupApk[0].orders.maximumPeriod
            : 0
    );
    useEffect(() => {
        if (
            dataSetupApk[0] !== undefined &&
            dataSetupApk[0].orders.isPauseOrders
        ) {
            setIsModalOpenedOrders(false);
        } else if (isPauseOrders) {
            setIsModalOpenedOrders(true);
        }
    }, [isPauseOrders, dataSetupApk]);

    useEffect(() => {
        if (duration !== "" && duration.label !== t("Undetermined")) {
            setTimeout(() => {
                setLocalDuration("");
                setDuration("");
                setPattern("");
                setIsMinimumPrice(false);
                ccSetupOrders();
            }, duration.label * 60000);
        }
        // eslint-disable-next-line
    }, [duration, t]);
    useEffect(() => {
        setOrders({
            duration,
            pattern,
            isMinimumPrice,
            isDeferredOrders,
            isPauseOrders,
            delivery,
            onTheSpot,
            toTake,
            numberToTake,
            numberOnTheSpot,
            maximumPeriod,
        });
    }, [
        onTheSpot,
        duration,
        pattern,
        isMinimumPrice,
        isDeferredOrders,
        isPauseOrders,
        delivery,
        toTake,
        numberToTake,
        numberOnTheSpot,
        maximumPeriod,
        dataSetupApk,
    ]);
    async function ccSetupOrders() {
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/C&C/c&cSetup`;
        let savedData = {
            userId: userID,
            shopId: apkId.boutique,
            franchiseId: franchiseID,
            ApkId: apkId.id,

            duration: "",
            isMinimumPrice: false,
            pattern:"",
        };

        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(savedData),
                }).then((result: any) => {
                    if (result.error === true) {
                        throw Error(result.message);
                    }else{
                        
                    }
                })
            );
        } catch (e: any) {}
    }
    return (
        <React.Fragment>
            <StyledH2
                style={{
                    font: "normal normal 600 45px/61px Nunito Sans",
                    color: "#000000",
                    opacity: "1",
                }}
                className="ml-3 pt-5"
            >
                {t("Configuring commands")}
            </StyledH2>
            <div className="border-footer__clz" />
            <StyledH2
                style={{
                    font: "normal normal 600 20px/27px Nunito Sans",
                    color: "#000000",
                    opacity: "1",
                }}
                className="ml-5 px-1 pt-2"
            >
                {t("Preparation time required for an order (in minutes)")}
            </StyledH2>
            <div className="ml-5  p-2">
                <span className="px-4">{t("Delivery")}</span>
                <input
                    type="number"
                    defaultValue={delivery}
                    onChange={(num: any) => {
                        if (num.target.value.match(numberMatchWord)) {
                            setDelivery(num.target.value);
                            setIsValidationnumDelivrey(false);
                        } else {
                            setIsValidationnumDelivrey(true);
                        }
                    }}
                />
                {isValidationnumDelivrey ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                            marginBottom: "0.25rem",
                            marginLeft: "6.25rem",
                        }}
                    >
                        {t("Please insert a number")}
                    </div>
                ) : null}
            </div>
            <div className="ml-5 p-2">
                <span className="px-4">{t("On the spot")}</span>
                <input
                    type="number"
                    defaultValue={onTheSpot}
                    onChange={(num: any) => {
                        if (num.target.value.match(numberMatchWord)) {
                            setOnTheSpot(num.target.value);
                            setIsValidationnumTheSpot(false);
                        } else {
                            setIsValidationnumTheSpot(true);
                        }
                    }}
                />
                {isValidationnumTheSpot ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                            marginBottom: "0.25rem",
                            marginLeft: "6.25rem",
                        }}
                    >
                        {t("Please insert a number")}
                    </div>
                ) : null}
            </div>
            <div className="ml-5 p-2">
                <span className="px-3">{t("To take")}</span>
                <input
                    type="number"
                    defaultValue={toTake}
                    onChange={(num: any) => {
                        if (num.target.value.match(numberMatchWord)) {
                            setToTake(num.target.value);
                            setIsValidationnumToTake(false);
                        } else {
                            setIsValidationnumToTake(true);
                        }
                    }}
                />
                {isValidationnumToTake ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                            marginBottom: "0.25rem",
                            marginLeft: "6.25rem",
                        }}
                    >
                        {t("Please insert a number")}
                    </div>
                ) : null}
            </div>
            <div className="border-footer__clz" />
            <div className="ml-5 d-flex justify-content-between p-2">
                <StyledH2
                    style={{
                        font: "normal normal 600 20px/27px Nunito Sans",
                        color: "#000000",
                        opacity: "1",
                    }}
                    className="ml-2 px-1 pt-2"
                >
                    {t("Minimum price to place an order")}
                </StyledH2>
                <Switch
                    offColor="#faafb4"
                    offHandleColor="#E30613"
                    onColor="#c2eec4"
                    onHandleColor="#34C38F"
                    height={19}
                    handleDiameter={24}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    checked={isMinimumPrice}
                    onChange={(e: any) => {
                        setIsMinimumPrice(!isMinimumPrice);
                    }}
                />
            </div>
            <div className="ml-5 p-2">
                <span className="px-3">{t("To take")}</span>
                <NumericInput
                    defaultValue={numberToTake}
                    min={0}
                    onChange={(num: number | null) => {
                        setNumberToTake(num !== null ? (num > 0 ? num : 0) : 0);
                    }}
                />
            </div>
            <div className="ml-5 p-2">
                <span className="px-4">{t("On the spot")}</span>
                <NumericInput
                    min={0}
                    onChange={(num: number | null) => {
                        setNumberOnTheSpot(
                            num !== null ? (num > 0 ? num : 0) : 0
                        );
                    }}
                    defaultValue={numberOnTheSpot}
                />
            </div>
            <div className="border-footer__clz" />
            <div className="ml-5 d-flex justify-content-between p-2">
                <StyledH2
                    style={{
                        font: "normal normal 600 20px/27px Nunito Sans",
                        color: "#000000",
                        opacity: "1",
                    }}
                    className="ml-2 px-1 pt-2"
                >
                    {t("Pause orders")}
                </StyledH2>
                <Switch
                    offColor="#faafb4"
                    offHandleColor="#E30613"
                    onColor="#c2eec4"
                    onHandleColor="#34C38F"
                    height={19}
                    handleDiameter={24}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    checked={isPauseOrders}
                    onChange={(e: any) => {
                        setIsPauseOrders(!isPauseOrders);
                    }}
                />
            </div>
            <div className="d-flex justify-content-between p-2 px-4 ml-5">
                <span
                    style={{ font: "normal normal normal 16px/21px Segoe UI" }}
                >
                    {isPauseOrders &&
                    localDuration.label !== undefined &&
                    localDuration.label !== t("Undetermined")
                        ? `${t("Orders are suspended until")} ${
                              localDuration.label
                          } ${t("min")}`
                        : localDuration.label === t("Undetermined")
                        ? `${t("Orders are suspended until")}  ${
                              localDuration.label
                          }`
                        : `${t("No interrupt commands are enabled")}`}
                </span>
                <Button
                    className={classnames("px-2 ml-3 style-button-order__clz", {
                        "not-allowed-icon__clz": !isPauseOrders,
                    })}
                    onClick={() => {
                        setIsModalOpenedOrders(true);
                    }}
                >
                    <span
                        style={{
                            font: "normal normal normal 16px/21px Segoe UI",
                            color: "rgb(0, 112, 255)",
                            textDecoration: "underline",
                        }}
                    >
                        {t("Edit")}
                    </span>
                </Button>
            </div>
            <div className="border-footer__clz" />
            <div className="ml-5 d-flex justify-content-between p-2">
                <StyledH2
                    style={{
                        font: "normal normal 600 20px/27px Nunito Sans",
                        color: "#000000",
                        opacity: "1",
                    }}
                    className="ml-2 px-1 pt-2"
                >
                    {t("Deferred orders")}
                </StyledH2>
                <Switch
                    offColor="#faafb4"
                    offHandleColor="#E30613"
                    onColor="#c2eec4"
                    onHandleColor="#34C38F"
                    height={19}
                    handleDiameter={24}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    checked={isDeferredOrders}
                    onChange={(e: any) => {
                        setIsDeferredOrders(!isDeferredOrders);
                    }}
                />
            </div>
            <div className="ml-5  p-2">
                <span className="px-3">
                    {t("Maximum withdrawal period to respect (in days)")}
                </span>
                <input
                    type="number"
                    defaultValue={maximumPeriod}
                    onChange={(num: any) => {
                        setMaximumPeriod(num.target.value);
                    }}
                />
            </div>
            <span
                className="not-allowed-icon__clz ml-5 px-4"
                style={{ left: "5px" }}
            >
                {t(
                    "If the value entered is 5 days, then the end customer has the possibility of ordering up to D+5 from the date of order taking."
                )}
            </span>
            {isModalOpenedOrders ? (
                <ModalOrders
                    setLocalDuration={setLocalDuration}
                    localDuration={localDuration}
                    setLocalPattern={setLocalPattern}
                    localPattern={localPattern}
                />
            ) : null}
        </React.Fragment>
    );
}
