import { proxy } from "valtio";

type StoreType = {
    isModesSimple: boolean;
    isCreateProjectClicked: boolean;
    isModesModalOpened: boolean;
    isModalAssociateStore: boolean;
    isModesModalPublishOpened: boolean;
    isCreatedCoping: boolean;
    isModalOpenedSimpleProject: boolean;
    isModalOpenedPlaning: boolean;
    hourlyInterval: boolean;
    definedTime: boolean;
    isArchivedTemplateClicked: boolean;
    isModalArchiveOpened: boolean;
    isModalAssociatesStores: boolean;
    isModalConfirmationConfigurationKiosk: boolean;
    projectData: any[];
    dataProjectFranchise: any[];
    numberActive: number;
    numberArchived: number;
    isConsult: boolean;
    isModesModalDisassociate: boolean;
    isLoading: boolean;
    projectFranchise: any;
    projectFiles: any;
    isModalDissociateOpened: boolean;
    isConfirmationPublishProject: boolean;
    publishProject: any;
    kioskProject: any;
    selectedProject: any[];
    isPlusIconClicked: boolean;
    designation: string;
    note: string;
    modify: boolean;
};

export const store = proxy<StoreType>({
    isModesSimple: false,
    isCreateProjectClicked: false,
    isModesModalOpened: false,
    isModalAssociateStore: false,
    isModesModalPublishOpened: false,
    isCreatedCoping: false,
    isModalOpenedSimpleProject: false,
    isModalOpenedPlaning: false,
    hourlyInterval: false,
    definedTime: true,
    isArchivedTemplateClicked: false,
    isModalArchiveOpened: false,
    isModalAssociatesStores: false,
    isModalConfirmationConfigurationKiosk: false,
    projectData: [],
    dataProjectFranchise: [],
    numberActive: 0,
    numberArchived: 0,
    isConsult: false,
    isModesModalDisassociate: false,
    isLoading: false,
    projectFranchise: {},
    projectFiles: {},
    isModalDissociateOpened: false,
    isConfirmationPublishProject: false,
    publishProject: {},
    kioskProject: {},
    selectedProject: [],
    isPlusIconClicked: false,
    designation: "",
    note: "",
    modify: false,
});
export function setModify(x: boolean) {
    store.modify = x;
}
export function setIsModalAssociateStore(isModalAssociateStore: boolean): void {
    store.isModalAssociateStore = isModalAssociateStore;
}
export function setIsModalAssociatesStores(
    isModalAssociatesStores: boolean
): void {
    store.isModalAssociatesStores = isModalAssociatesStores;
}
export function setIsModesSimple(isModesSimple: boolean): void {
    store.isModesSimple = isModesSimple;
}
export function setHourlyInterval(hourlyInterval: boolean): void {
    store.hourlyInterval = hourlyInterval;
}
export function setDefinedTime(definedTime: boolean): void {
    store.definedTime = definedTime;
}
export function setIsModalOpenedPlaning(isModalOpenedPlaning: boolean): void {
    store.isModalOpenedPlaning = isModalOpenedPlaning;
}

export function setIsModesModalPublishOpened(
    isModesModalPublishOpened: boolean
): void {
    store.isModesModalPublishOpened = isModesModalPublishOpened;
}
export function setIsModesModalDisassociate(
    isModesModalDisassociate: boolean
): void {
    store.isModesModalDisassociate = isModesModalDisassociate;
}
export function setIsCreatedCoping(isCreatedCoping: boolean): void {
    store.isCreatedCoping = isCreatedCoping;
}
export function setIsModalOpenedSimpleProject(
    isModalOpenedSimpleProject: boolean
): void {
    store.isModalOpenedSimpleProject = isModalOpenedSimpleProject;
}
export function setIsArchivedTemplateClicked(
    isArchivedTemplateClicked: boolean
): void {
    store.isArchivedTemplateClicked = isArchivedTemplateClicked;
}
export function setIsModalArchiveOpened(isModalArchiveOpened: boolean): void {
    store.isModalArchiveOpened = isModalArchiveOpened;
}
export function setIsModalDissociateOpened(
    isModalDissociateOpened: boolean
): void {
    store.isModalDissociateOpened = isModalDissociateOpened;
}
export function setIsModalConfirmationConfigurationKiosk(
    isModalConfirmationConfigurationKiosk: boolean
): void {
    store.isModalConfirmationConfigurationKiosk = isModalConfirmationConfigurationKiosk;
}
export function setDataProject(data: any[]): void {
    store.projectData = data;
}
export function setNumberArchived(numberArchived: number): void {
    store.numberArchived = numberArchived;
}
export function setNumberActive(numberActive: number): void {
    store.numberActive = numberActive;
}
export function setIsConsult(isConsult: boolean): void {
    store.isConsult = isConsult;
}
export function setIsLoading(isLoading: boolean): void {
    store.isLoading = isLoading;
}
export function setDesignation(designation: string): void {
    store.designation = designation;
}
export function setNote(note: string): void {
    store.note = note;
}
export function setIsConfirmationPublishProject(
    isConfirmationPublishProject: boolean
): void {
    store.isConfirmationPublishProject = isConfirmationPublishProject;
}
export function setPublishProject(publishProject: boolean): void {
    store.publishProject = publishProject;
}
export function setDataProjectFranchise(dataProjectFranchise: any): void {
    store.dataProjectFranchise = dataProjectFranchise;
}
export function setKioskProject(kioskProject: any): void {
    store.kioskProject = kioskProject;
}
export function setSelectedProject(selectedProject: any[]): any {
    store.selectedProject = selectedProject;
}
export function setIsPlusIconClicked(isPlusIconClicked: boolean): void {
    store.isPlusIconClicked = isPlusIconClicked;
}
