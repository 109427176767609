import React, { useState } from "react";

import { CrossIcon, StyledLabel, CheckboxIcon } from "@aureskonnect/react-ui";
import { Input } from "reactstrap";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { mutate } from "swr";
import { toast } from "react-toastify";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { resetAllPeripherals } from "@pages/Applications/DeviceAssociateModal/store";
import {
    CustomCardHeader,
    CustomCardBody,
    CustomCardFooter,
} from "@components/Common/CustomCardStyled";

import storeVatRate, {
    setConfirmationModificationClick,
    setDataIsUpdatedFranchise,
    setDataIsUpdatedShops,
    setConfirmationArchivedExistMessageClick,
    setIsFormShopUpdatedPays,
    setIsActionsClicked,
    setActions,
    setIsEdited,
} from "../store";

import { ModalNoEditModification } from "./ModalNoEditModification";
import { arrayDiff, searchPosInArrayObject } from "@helpers/general";
import { ConfirmationModification } from "./ConfirmationModification";

import MultiCustomCreatableSelection from "@components/Common/MultiCustomCreatableSelection";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";
import MultiCustomSelect from "@components/Common/MultiCustomSelect";
import ErrorToast from "@components/Common/ErrorTost";
import { AlertIsExistArchivedModal } from "./AlertIsExistArchivedModal";
import { ConfirmationAssociation } from "./ConfirmationAssociation";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";
import { store } from "../../../../components/VerticalLayout/store";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";


import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type vatRateFormType = {
    dataVatRate: any;
    setDatavatRate: Function;
    dataM: any;

    editedData: any;
    setEditedData: Function;
    selectedRows: any;
    isAddVatRateClicked: boolean;
    setIsAddVatRateClicked: Function;
    dataTable: any;
    pays: any;
    dataState: any;
    isAddNewVatRateButtonClicked: boolean;
};

function makeId(length: any) {
    var result = "";
    var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
}

export default function VatRateForm({
    dataVatRate,
    setEditedData,
    isAddVatRateClicked,
    setIsAddVatRateClicked,
    dataTable,
    pays,
    dataState,
    isAddNewVatRateButtonClicked,
}: vatRateFormType) {
    const { t } = useTranslation();
    const { oneShop, shopPays, operatorID } = useSnapshot(store);
    const {
        dataFranchise,
        paysVateRate,
        dataShops,
        confirmationModificationClick,
        confirmationArchivedExistMessageClick,
        isActionsClicked,
        isEdited,
    } = useSnapshot(storeVatRate);
    const [ListPaysDiff, setListPaysDiff] = useState<any>([]);
    const [franchiseData, setFranchiseData] = useState<any>([]);
    React.useEffect(() => {
        refreshTags(true);

        if (typeof dataVatRate?.selectedRow === "object") {
            let paysCheck: any = fetchShopsPays(dataVatRate?.selectedRow.Id);
            setListPaysDiff(paysCheck);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataVatRate]);

    React.useEffect(() => {
        if (typeof dataVatRate?.selectedRow === "object") {
            setInputCodeVateRate(false);
            setInputVateRate(false);
            setInputPaysVateRate(false);
            setInputVateRate(false);
            setInputCodeVateRateExist(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAddVatRateClicked, dataVatRate]);
    React.useEffect(() => {
        cleanForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAddNewVatRateButtonClicked]);
    const [canValidate, setCanValidate] = React.useState<boolean>(
        typeof dataVatRate?.selectedRow === "object" ? false : true
    );
    const [isSendingTag, setIsSendingTag] = React.useState<boolean>(false);

    const [
        confirmationAssociationClick,
        setConfirmationAssociationClick,
    ] = React.useState<boolean>(false);
    const [objAssoc, setObjAssoc] = React.useState<any>({});
    const [
        confirmationEditMessageClick,
        setConfirmationEditMessageClick,
    ] = React.useState<boolean>(false);

    const [codeVateRate, setCodeVateRate] = useState<string>("");
    React.useEffect(() => {
        if (dataVatRate?.selectedRow?.Id !== undefined) {
            clearData();
            clearMultiCustomSelectionData();
            setCodeVateRate(dataVatRate?.selectedRow.code);
            setVateRate(dataVatRate?.selectedRow.value);
            setTagVateRateItem(dataVatRate?.selectedRow.tag);
            setPaysVateRateData(dataVatRate?.selectedRow[t("Country")].labels);
            setType(oneShop ? "updateShop" : "update");
            setCanValidate(false);
        } else {
            if (
                confirmationAssociationClick === false &&
                confirmationArchivedExistMessageClick === false
            ) {
                cleanForm();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataVatRate]);
    async function associate(obj: any) {
        mutate(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/vatRate/associate`,
            await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/vatRate/associate`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(obj),
                }
            )
                .then((response1) => response1.json())
                .then(() => {
                    setDataIsUpdatedShops!(true);
                    setDataIsUpdatedFranchise!(true);

                    setIsSendingTag(true);
                })
        );
    }
    const [inputCodeVateRate, setInputCodeVateRate] = useState<boolean>(false);

    const [, setDataPaysVateRateDiff] = useState<any>([]);

    const [inputCodeVateRateExist, setInputCodeVateRateExist] = useState<
        boolean
    >(false);
    const [vateRate, setVateRate] = useState<string>("");
    const [inputVateRate, setInputVateRate] = useState<boolean>(false);
    const [type, setType] = useState<string>("add");

    const [canReplace, setCanReplace] = useState<boolean>(false);

    const [inputPaysVateRate, setInputPaysVateRate] = useState<boolean>(false);
    const [tagsVateRate, setTagsVateRate] = useState<any>([]);
    const [inputTagsVateRate] = useState<boolean>(false);
    const [paysVateRateItem, setPaysVateRateItem] = useState<any>([]);
    const [paysVateRateData, setPaysVateRateData] = useState<any>([]);

    const [tagVateRateItem, setTagVateRateItem] = useState<any>([]);
    const { userID, franchiseID } = useSnapshot(store);
    const { shopID } = useSnapshot(store);

    React.useEffect(() => {
        let paysOfVatRateItems: any = [];
        if (paysVateRateData.length > 0) {
            paysVateRateData.forEach((el: any) => {
                if (searchPosInArrayObject(paysVateRate, "label", el) !== -1) {
                    paysOfVatRateItems.push(
                        paysVateRate[
                            searchPosInArrayObject(paysVateRate, "label", el)
                        ]
                    );
                } else {
                }
            });
        }

        setPaysVateRateItem(
            paysVateRateData.length > 0
                ? pays.filter(
                      (x: any) => paysVateRateData.indexOf(x.label) !== -1
                  )
                : []
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paysVateRateData, dataVatRate]);
    React.useEffect(() => {
        if (dataVatRate?.selectedRow?.Id) {
            let paysCheck: any = fetchShopsPays(dataVatRate?.selectedRow.Id);
            if (paysDiff(paysCheck, paysVateRateData).length > 0) {
                setDataPaysVateRateDiff(paysDiff(paysCheck, paysVateRateData));
            } else if (paysDiff(paysCheck, paysVateRateData).length === 0) {
                setDataPaysVateRateDiff([]);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paysVateRateData]);

    function searchPosInArrayObjectVatRateUppercase(array: any, req: any) {
        let pos = -1;
        if (array.length > 0) {
            array.forEach((el: any, i: number) => {
                if (
                    el.code.toUpperCase() === req.code.toUpperCase() &&
                    el.value.toUpperCase() === req.value.toUpperCase()
                ) {
                    pos = i;
                }
            });
        }

        return pos;
    }
    async function refreshTags(init: boolean) {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/tag?userId=${userID}&shopId=0&franchiseId=${franchiseID}&deviceType=tag`;

        clearMultiCustomSelectionData();

        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },

                    method: "GET",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        let listOfTag: any = [];

                        data.data.forEach((element: any) => {
                            listOfTag.push(element.Designation);
                        });

                        setTagsVateRate(listOfTag);

                        if (Object.keys(tagVateRateItem).length === 0) {
                        }

                        if (data.error) {
                            throw Error("Error!");
                        }
                    })
            );
        } catch (e: any) {}
    }
    function fetchShopsPays(id: string) {
        let res: any = [];
        dataShops.forEach((el: any) => {
            // eslint-disable-next-line array-callback-return
            if (
                // eslint-disable-next-line array-callback-return
                el.subRows.filter(function (x: any) {
                    if (x.Id === id) {
                        return x;
                    }
                }).length > 0 &&
                res.indexOf(el[t("Country")]) === -1
            ) {
                res.push(el[t("Country")]);
            }
        });
        return res;
    }
    async function handleValidate(object: any, type: string, notify?: any) {
        let refreshAction: boolean = true;
        if (canValidate === false) {
            setCanValidate(true);

            let apiUrl = ``;
            if (type === "add") {
                apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/vatRate`;
            } else if (type === "update" || type === "updateShop") {
                apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/editVatRate`;
            }

            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(object),
            };

            if (oneShop === true && type === "updateShop") {
                try {
                    mutate(
                        apiUrl,
                        await fetch(apiUrl, requestOptions)
                            .then((response) => response.json())
                            .then(async (data) => {
                                if (data.error) {
                                    throw Error("error");
                                }
                                setIsSendingTag(true);
                                setDataIsUpdatedFranchise!(true);
                                setIsEdited(false);
                                resetAllPeripherals();
                                setIsAddVatRateClicked(false);

                                refreshTags(false);
                            })
                    );
                } catch (e) {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            } else {
                try {
                    mutate(
                        apiUrl,
                        await fetch(apiUrl, requestOptions)
                            .then((response) => response.json())
                            .then(async (data) => {
                                if (data.error) {
                                    throw Error("error");
                                }
                                setIsSendingTag(true);

                                if (type === "add") {
                                    let assocObj: any = {};
                                    if (oneShop) {
                                        if (data?.exist?.id !== undefined) {
                                            assocObj = {
                                                userId: userID,
                                                operatorId: operatorID,
                                                franchiseId: franchiseID,
                                                canReplace: true,
                                                shopId: shopID,
                                                data: [data?.exist],
                                            };
                                        } else {
                                            assocObj = {
                                                userId: userID,
                                                operatorId: operatorID,
                                                franchiseId: franchiseID,
                                                canReplace: true,
                                                shopId: shopID,
                                                data: [object.data[0]],
                                            };
                                        }

                                        if (data?.exist?.id !== undefined) {
                                            setFranchiseData(data?.exist);

                                            let paysOfVatRateAssociatedLabel: any = [];
                                            if (data?.exist.pays.length > 0) {
                                                paysOfVatRateAssociatedLabel = pays
                                                    .filter(
                                                        (x: any) =>
                                                            data?.exist.pays.indexOf(
                                                                x.value
                                                            ) !== -1
                                                    )
                                                    .map((y: any) => y.label);
                                            }

                                            if (
                                                paysOfVatRateAssociatedLabel.indexOf(
                                                    shopPays
                                                ) !== -1 &&
                                                assocObj!.data[0].archived ===
                                                    false
                                            ) {
                                                assocObj!.data[0].status = false;

                                                notify = false;

                                                if (oneShop && type === "add") {
                                                    assocObj.data[0].tag = tagVateRateItem;
                                                    setConfirmationAssociationClick(
                                                        true
                                                    );
                                                    refreshAction = false;
                                                }
                                                setObjAssoc(assocObj);
                                            } else if (
                                                assocObj!.data[0].archived ===
                                                true
                                            ) {
                                                setIsFormShopUpdatedPays(false);
                                                setConfirmationArchivedExistMessageClick(
                                                    true
                                                );
                                                refreshAction = false;
                                                notify = false;
                                            } else if (
                                                paysOfVatRateAssociatedLabel.indexOf(
                                                    shopPays
                                                ) === -1 &&
                                                assocObj!.data[0].archived ===
                                                    false
                                            ) {
                                                let pay = searchPosInArrayObject(
                                                    pays,
                                                    "label",
                                                    shopPays
                                                );

                                                let paysAdded = pays[pay].value;

                                                let updatesPays = [
                                                    ...data?.exist.pays,
                                                    paysAdded,
                                                ];
                                                data.exist.pays = updatesPays;

                                                let Obj: any = {
                                                    userId: userID,
                                                    operatorId: operatorID,
                                                    franchiseId: franchiseID,
                                                    canReplace: true,
                                                    shopId: 0,
                                                    data: [data?.exist],
                                                };

                                                setIsFormShopUpdatedPays(true);

                                                Obj = {
                                                    userId: userID,
                                                    operatorId: operatorID,
                                                    franchiseId: franchiseID,
                                                    canReplace: true,
                                                    shopId: shopID,
                                                    data: [data?.exist],
                                                    baseCountry:
                                                        data?.exist.pays,
                                                };
                                                notify = false;
                                                if (oneShop && type === "add") {
                                                    assocObj.data[0].tag = tagVateRateItem;

                                                    setConfirmationAssociationClick!(
                                                        true
                                                    );
                                                    refreshAction = false;
                                                }
                                                setObjAssoc(Obj);
                                            } else {
                                                setIsFormShopUpdatedPays(true);
                                            }
                                        } else {
                                            assocObj!.data[0].status = false;
                                            await associate(assocObj);
                                        }
                                    }
                                }
                            })
                    );
                } catch (e) {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
            if (type === "update" || type === "updateShop") {
                if (
                    notify === undefined ||
                    notify === true ||
                    notify === "shopsUpdate"
                ) {
                    if (refreshAction === true) {
                        toast.success(
                            `${t("The vat rate is changed successfully")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                    }
                }
            } else if (type === "add") {
                if (notify === undefined || notify === true) {
                    toast.success(
                        `${t("The vat rate is successfully added")!}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            theme: "colored",
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                }
            }

            setIsSendingTag(true);
            if (oneShop && type === "add") {
                setDataIsUpdatedFranchise!(true);
            }
            if (refreshAction === true) {
                setDataIsUpdatedFranchise!(true);
                setIsEdited(false);
                resetAllPeripherals();
                cleanForm();
            }
        }
    }
    function paysDiff(arr1: any, arr2: any) {
        let res: any = [];
        arr1.forEach((element: any) => {
            if (arr2.indexOf(element) === -1) {
                res.push(element);
            }
        });
        return res;
    }
    function cleanForm() {
        setCanValidate(true);
        setVateRate("");
        setCodeVateRate("");
        setTagVateRateItem([]);
        setPaysVateRateItem([]);
        setPaysVateRateData([]);
        setIsEdited(false);
        clearData();
        clearMultiCustomSelectionData();
        setInputCodeVateRate(false);
        setInputVateRate(false);
        setInputPaysVateRate(false);
        setInputVateRate(false);

        setDataPaysVateRateDiff([]);
    }

    const formatCreateLabel = (inputValue: any) => (
        <span>
            {inputValue} ({t("New tag")})
        </span>
    );

    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader className="d-flex align-items-center justify-content-between">
                    <h5
                        className={`${classnames(
                            "pt-3 tva_txt_FRtitleFormVatRate",
                            {
                                tva_txt_FRmodifyVatRate:
                                    dataVatRate.length !== 0,
                            }
                        )}`}
                    >
                        {dataVatRate.length === 0
                            ? t("Add VAT rate")
                            : t("Modify VAT rate")}
                    </h5>
                    <CrossIcon
                        style={{ cursor: "pointer" }}
                        className="tva_icn_FRcloseForm"
                        height={13}
                        width={13}
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsEdited(false);
                                    resetAllPeripherals();
                                    setEditedData([]);
                                    setIsAddVatRateClicked!(
                                        !isAddVatRateClicked
                                    );
                                    setIsEdited(false);
                                    cleanForm();
                                });
                            } else {
                                resetAllPeripherals();
                                setEditedData([]);
                                setIsAddVatRateClicked!(!isAddVatRateClicked);
                                setIsEdited(false);
                                cleanForm();
                            }
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className="pl-3 pr-5"
                    style={{ overflowY: "scroll", maxHeight: "539px" }}
                >
                    <AvGroup>
                        <StyledLabel
                            htmlFor="path"
                            className="required__clz mt-3 "
                        >
                            {t("VAT code")}
                        </StyledLabel>
                        <Input
                            name="code"
                            className={classnames("tva_inp_FRvatCode ", {
                                input__clz:
                                    inputCodeVateRate || inputCodeVateRateExist,
                                "not-allowed-input-vr__clz":
                                    oneShop &&
                                    typeof dataVatRate?.selectedRow ===
                                        "object",
                            })}
                            placeholder={t("Enter")}
                            type="text"
                            autocomplete="off"
                            value={codeVateRate}
                            onChange={(e: any) => {
                                let dataFran: any = oneShop
                                    ? // eslint-disable-next-line array-callback-return
                                      dataShops.find((x: any) => {
                                          if (x.shopId === shopID) {
                                              return x;
                                          }
                                      }).subRows
                                    : dataFranchise;
                                if (dataFran.length > 0) {
                                    let pos = searchPosInArrayObjectVatRateUppercase(
                                        dataFran,

                                        {
                                            code: e.target.value.trim(),
                                            value: vateRate.trim(),
                                        }
                                    );

                                    setIsEdited(true);

                                    if (
                                        (pos !== -1 &&
                                            typeof dataVatRate?.selectedRow !==
                                                "object") ||
                                        (pos !== -1 &&
                                            typeof dataVatRate?.selectedRow ===
                                                "object" &&
                                            e.target.value.trim() !==
                                                dataVatRate?.selectedRow[
                                                    t("VAT code")
                                                ].trim())
                                    ) {
                                        setInputCodeVateRateExist(true);
                                    } else {
                                        setInputCodeVateRateExist(false);
                                    }
                                }
                                setCodeVateRate(e.target.value);
                                setCanValidate(false);

                                setInputCodeVateRate(false);
                            }}
                            autoFocus={
                                oneShop &&
                                typeof dataVatRate?.selectedRow === "object"
                                    ? false
                                    : true
                            }
                        />
                        {inputCodeVateRateExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t(
                                    "The combination VAT code, VAT rate already exists"
                                )}
                            </div>
                        ) : null}
                        {inputCodeVateRate ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a vat code")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="path"
                            className="required__clz mt-3 "
                        >
                            {t("VAT rate")}
                        </StyledLabel>
                        <Input
                            name="value"
                            className={classnames("tva_inp_FRvatRate ", {
                                input__clz:
                                    inputVateRate || inputCodeVateRateExist,
                                "not-allowed-input-vr__clz":
                                    oneShop &&
                                    typeof dataVatRate?.selectedRow ===
                                        "object",
                            })}
                            placeholder={t("Enter")}
                            type="number"
                            step="0.00"
                            onKeyPress={(e) => {}}
                            value={vateRate}
                            autocomplete="off"
                            onChange={(e: any) => {
                                setVateRate(
                                    e.target.value !== ""
                                        ? Number(e.target.value).toString()
                                        : ""
                                );
                                setCanValidate(false);
                                setIsEdited(true);
                                setInputVateRate(false);
                                let dataFran: any = oneShop
                                    ? // eslint-disable-next-line array-callback-return
                                      dataShops.find((x: any) => {
                                          if (x.shopId === shopID) {
                                              return x;
                                          }
                                      }).subRows
                                    : dataFranchise;
                                if (dataFran.length > 0) {
                                    let pos = searchPosInArrayObjectVatRateUppercase(
                                        dataFran,

                                        {
                                            code: codeVateRate.trim(),
                                            value: Number(e.target.value)
                                                .toString()
                                                .trim(),
                                        }
                                    );

                                    if (
                                        (pos !== -1 &&
                                            typeof dataVatRate?.selectedRow !==
                                                "object") ||
                                        (pos !== -1 &&
                                            typeof dataVatRate?.selectedRow ===
                                                "object" &&
                                            e.target.value.trim() !==
                                                dataVatRate?.selectedRow[
                                                    t("VAT rate")
                                                ].trim())
                                    ) {
                                        setInputCodeVateRateExist(true);
                                    } else {
                                        setInputCodeVateRateExist(false);
                                    }
                                }
                            }}
                        />
                        {inputVateRate ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a vat rate")}
                            </div>
                        ) : null}
                        {inputCodeVateRateExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t(
                                    "The combination VAT code, VAT rate already exists"
                                )}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="path"
                            className="required__clz mt-3 "
                        >
                            {t("Country")}
                        </StyledLabel>
                        <MultiCustomSelect
                            onDelete={() => {
                                setIsEdited(true);
                                setCanValidate(false);
                            }}
                            blocked={
                                oneShop &&
                                typeof dataVatRate?.selectedRow === "object"
                            }
                            result={paysVateRateData}
                            setResult={setPaysVateRateData}
                            data={
                                oneShop
                                    ? [shopPays]
                                    : pays.map((a: any) => a.label)
                            }
                            onChange={(e: any) => {
                                setIsEdited(true);
                                setCanValidate(false);
                                setInputPaysVateRate(false);
                            }}
                            className={classnames("tva_inp_FRcountry ", {
                                invalid__clz: inputPaysVateRate,
                                "not-allowed-input-vr__clz":
                                    oneShop &&
                                    typeof dataVatRate?.selectedRow ===
                                        "object",
                            })}
                            placeholder={`${t("Select")}…`}
                            value={t("Choose")}
                            name="country"
                            blockedList={ListPaysDiff}
                        />
                        {inputPaysVateRate ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter thee country of vat rate")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel htmlFor="path" className="mt-3 ">
                            {t("Tag")}
                        </StyledLabel>
                        <MultiCustomCreatableSelection
                            isSendingTag={isSendingTag}
                            onDelete={() => {
                                setIsEdited(true);
                                setCanValidate(false);
                            }}
                            onCreateOption={async (e: any) => {
                                setIsEdited(true);
                            }}
                            className="tva_inp_FRtags"
                            setResult={setTagVateRateItem}
                            result={tagVateRateItem}
                            data={tagsVateRate}
                            onChange={(e: any) => {
                                setIsEdited(true);
                                setCanValidate(false);
                                setTagVateRateItem([]);
                            }}
                            placeholder={`${t("Select")}…`}
                            value={""}
                            name="tag"
                            noOptionsMessage={() => t("No options")}
                            formatCreateLabel={formatCreateLabel}
                        />
                        {inputTagsVateRate ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a vat rate tag")}
                            </div>
                        ) : null}
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        variant="light"
                        className="mr-2 tva_btn_FRcancel"
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsEdited(false);
                                    setEditedData([]);
                                    setIsAddVatRateClicked!(
                                        !isAddVatRateClicked
                                    );
                                    cleanForm();
                                });
                            } else {
                                setEditedData([]);
                                setIsAddVatRateClicked!(!isAddVatRateClicked);
                                cleanForm();
                            }
                        }}
                    >
                        <span>{t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        className={`${classnames("tva_btn_FRvalidate", {})}`}
                        variant="primary"
                        onClick={async () => {
                            let verify = true;
                            let dataPaysShops: any = [];
                            let tagDiff: any[] = [];
                            let PaysDiff: any[] = [];
                            if (dataVatRate?.selectedRow?.Id) {
                                dataPaysShops = fetchShopsPays(
                                    dataVatRate?.selectedRow?.Id
                                );
                                let sameVatRate: any =
                                    dataTable.data[
                                        searchPosInArrayObject(
                                            dataTable.data,
                                            "Id",
                                            dataVatRate?.selectedRow?.Id
                                        )
                                    ];

                                tagDiff = arrayDiff(
                                    sameVatRate.tag,
                                    tagVateRateItem
                                );
                                PaysDiff = arrayDiff(
                                    sameVatRate[t("Country")].labels,
                                    paysVateRateData
                                );
                            }
                            if (codeVateRate === "") {
                                setInputCodeVateRate(true);
                                verify = false;
                            }
                            if (inputCodeVateRateExist) {
                                verify = false;
                            }
                            if (
                                (Number(vateRate) >= 0 &&
                                    Number(vateRate) <= 100 &&
                                    vateRate !== "") === false
                            ) {
                                setInputVateRate(true);
                                verify = false;
                            }
                            if (Object.keys(paysVateRateItem).length === 0) {
                                setInputPaysVateRate(true);
                                verify = false;
                            }

                            if (
                                isEdited === false &&
                                typeof dataVatRate?.selectedRow === "object" &&
                                PaysDiff.length === 0 &&
                                tagDiff.length === 0
                            ) {
                                setConfirmationEditMessageClick(true);
                            } else if (verify) {
                                let ident: string;
                                if (
                                    typeof dataVatRate?.selectedRow ===
                                        "object" &&
                                    paysDiff(dataPaysShops, paysVateRateData)
                                        .length > 0
                                ) {
                                    ErrorToast(
                                        `${t(
                                            "Attention ! You cannot remove the country of a VAT assigned to the store(s)"
                                        )} `
                                    );
                                    //  ${dataPaysVateRateDiff.join(',')}
                                } else if (
                                    typeof dataVatRate?.selectedRow ===
                                        "object" &&
                                    oneShop
                                ) {
                                    setType("updateShop");
                                    handleValidate(
                                        {
                                            userId: userID,
                                            operatorId: operatorID,
                                            shopId: shopID,
                                            franchiseId: franchiseID,
                                            canReplace: false,
                                            data: [
                                                {
                                                    id:
                                                        dataVatRate?.selectedRow
                                                            .Id,
                                                    code: codeVateRate.trim(),
                                                    value: vateRate,
                                                    tag: tagVateRateItem,
                                                    status:
                                                        typeof dataVatRate?.selectedRow ===
                                                        "object"
                                                            ? Object.keys(
                                                                  dataState
                                                              ).length === 0
                                                                ? dataVatRate
                                                                      ?.selectedRow
                                                                      .status
                                                                : dataState.state
                                                            : true,
                                                    pays: paysVateRateItem.map(
                                                        (a: any) => a.value
                                                    ),
                                                    archived:
                                                        typeof dataVatRate?.selectedRow ===
                                                        "object"
                                                            ? dataVatRate
                                                                  ?.selectedRow
                                                                  .archived
                                                            : false,
                                                },
                                            ],
                                        },
                                        type
                                    );
                                } else if (
                                    typeof dataVatRate?.selectedRow ===
                                        "object" &&
                                    paysDiff(dataPaysShops, paysVateRateData)
                                        .length === 0
                                ) {
                                    setType("update");
                                    ident = dataVatRate?.selectedRow.Id;
                                    let posX = 0;
                                    for (
                                        let index = 0;
                                        index < dataShops.length;
                                        index++
                                    ) {
                                        let el = dataShops[index];
                                        if (
                                            searchPosInArrayObject(
                                                el.subRows,
                                                "Id",
                                                dataVatRate?.selectedRow.Id
                                            ) !== -1
                                        ) {
                                            posX++;
                                        }
                                    }

                                    if (posX > 0) {
                                        setConfirmationModificationClick(
                                            !confirmationModificationClick
                                        );
                                    } else {
                                        setCanReplace(true);
                                        handleValidate(
                                            {
                                                userId: userID,
                                                operatorId: operatorID,
                                                shopId: 0,
                                                franchiseId: franchiseID,
                                                canReplace: true,
                                                data: [
                                                    {
                                                        id: ident,
                                                        code: codeVateRate.trim(),
                                                        value: vateRate,
                                                        tag: tagVateRateItem,
                                                        status:
                                                            typeof dataVatRate?.selectedRow ===
                                                            "object"
                                                                ? dataVatRate
                                                                      ?.selectedRow
                                                                      .status
                                                                : true,
                                                        pays: paysVateRateItem.map(
                                                            (a: any) => a.value
                                                        ),
                                                        archived:
                                                            typeof dataVatRate?.selectedRow ===
                                                            "object"
                                                                ? dataVatRate
                                                                      ?.selectedRow
                                                                      .archived
                                                                : false,
                                                    },
                                                ],
                                            },
                                            "update"
                                        );
                                    }
                                } else {
                                    setType("add");
                                    ident = makeId(10);

                                    handleValidate(
                                        {
                                            userId: userID,
                                            operatorId: operatorID,
                                            shopId: 0,
                                            franchiseId: franchiseID,
                                            canReplace: false,
                                            data: [
                                                {
                                                    id: ident,
                                                    code: codeVateRate.trim(),
                                                    value: vateRate,
                                                    tag: tagVateRateItem,
                                                    status:
                                                        typeof dataVatRate?.selectedRow ===
                                                        "object"
                                                            ? dataVatRate
                                                                  ?.selectedRow
                                                                  .status
                                                            : true,
                                                    pays: paysVateRateItem.map(
                                                        (a: any) => a.value
                                                    ),
                                                    archived:
                                                        typeof dataVatRate?.selectedRow ===
                                                        "object"
                                                            ? dataVatRate
                                                                  ?.selectedRow
                                                                  .archived
                                                            : false,
                                                },
                                            ],
                                        },
                                        type
                                    );
                                }
                            }
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
            <ConfirmationModification
                handleValidate={handleValidate}
                obj={{
                    userId: userID,
                    operatorId: operatorID,
                    shopId: 0,
                    franchiseId: franchiseID,
                    canReplace: true,
                    data: [
                        {
                            id:
                                dataVatRate?.selectedRow !== undefined
                                    ? dataVatRate?.selectedRow.Id
                                    : 0,
                            code: codeVateRate.trim(),
                            value: vateRate,
                            tag: tagVateRateItem,

                            status:
                                typeof dataVatRate?.selectedRow === "object"
                                    ? dataVatRate?.selectedRow.status
                                    : true,
                            pays:
                                paysVateRateItem[0]?.value !== undefined
                                    ? paysVateRateItem.map((a: any) => a.value)
                                    : [],
                            archived:
                                typeof dataVatRate?.selectedRow === "object"
                                    ? dataVatRate?.selectedRow.archived
                                    : false,
                        },
                    ],
                }}
                type={type}
                canReplace={canReplace}
                setIsAddVatRateClicked={setIsAddVatRateClicked}
            />
            <AlertIsExistArchivedModal
                isModalOpened={confirmationArchivedExistMessageClick}
                setIsModalOpened={setConfirmationArchivedExistMessageClick}
            />
            <ModalNoEditModification
                isModalOpened={confirmationEditMessageClick}
                setIsModalOpened={setConfirmationEditMessageClick}
                isAddVatRateClicked={isAddVatRateClicked}
                setIsAddVatRateClicked={setIsAddVatRateClicked}
            />
            <ConfirmationAssociation
                isModalOpened={confirmationAssociationClick}
                setIsModalOpened={setConfirmationAssociationClick}
                Fn={associate}
                obj={objAssoc}
                handleValidate={handleValidate}
                franchiseData={franchiseData}
                setIsSendingTag={setIsSendingTag}
                setCanValidate={setCanValidate}
            />
        </React.Fragment>
    );
}
