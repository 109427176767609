import React from "react";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { CheckboxIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { useTranslation } from "react-i18next/";

import ErrorToast from "@components/Common/ErrorTost";

import {
    store,
    setIsAddAppClicked,
    setIsMultiDeletePeripheralModalOpened,
    setIsActionsClicked,
    setIsEdited,
    setActions,
} from "./store";
import { verifyIfApplicationAssociate } from "@helpers/peripheralsHelpers";
import {
    setMessage,
    setFN,
} from "@components/Common/ConfirmationMultiDeleteMessage/store";
type actionType = {
    typeDisplay: string;
    setTypeDisplay: Function;
    selectedIds: any;
    ListNumberOrigin: any;
    setDataIsUpdated: Function;
    setIsHistoryModalOpened: Function;
    displayMode: string;
};
export default function DropdownAction({
    typeDisplay,
    setTypeDisplay,
    selectedIds,
    ListNumberOrigin,
    setDataIsUpdated,
    displayMode,
    setIsHistoryModalOpened,
}: actionType) {
    const { t } = useTranslation();
    const { isActionsClicked, isEdited, modify } = useSnapshot(store);
    const appId = "KIOSK";
    let isAppAssociate = false;

    return (
        <React.Fragment>
            <div>
                <div
                    className={` cmn_drp_FRmenu ${classnames("dropdown", {})}`}
                    onClick={() => {}}
                >
                    <VerticalDotsIcon
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        style={{ cursor: "pointer" }}
                        height={25}
                        width={25}
                        fill="black"
                    />
                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <div
                            className={` dropdown-item pl-1  ${classnames("", {
                                "not-allowed-icon__clz":
                                    displayMode === t("View by device"),
                            })}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);
                                        setTypeDisplay(
                                            typeDisplay === "notArchived"
                                                ? "archived"
                                                : "notArchived"
                                        );
                                        if (typeDisplay === "notArchived") {
                                            setIsAddAppClicked(false);
                                        }
                                    });
                                } else {
                                    setTypeDisplay(
                                        typeDisplay === "notArchived"
                                            ? "archived"
                                            : "notArchived"
                                    );
                                    if (typeDisplay === "notArchived") {
                                        setIsAddAppClicked(false);
                                    }
                                }
                            }}
                        >
                            <span className="ml-2 cmn_btn_FRlistArchived">
                                {displayMode === t("View by device")
                                    ? t(
                                          typeDisplay === "notArchived"
                                              ? "Archived(s) device(s)"
                                              : "Activated(s) device(s)"
                                      )
                                    : t(
                                          typeDisplay === "notArchived"
                                              ? "Archived(s) application(s)"
                                              : "Activated(s) application(s)"
                                      )}
                                (
                                {typeDisplay === "notArchived"
                                    ? ListNumberOrigin?.nbrArchived
                                    : ListNumberOrigin?.nbrNotArchived}
                                )
                            </span>
                        </div>
                        {modify === true ? (
                            <>
                                <div
                                    style={{ cursor: "pointer" }}
                                    className={`cmn_btn_FRarchived cmn_btn_FRunarchive cmn_btn_FRarchived ${classnames(
                                        " dropdown-item pl-1 pointer__clz",
                                        {
                                            "not-allowed-icon__clz":
                                                (selectedIds !== undefined &&
                                                    selectedIds.length === 0) ||
                                                displayMode ===
                                                    t("View by device"),
                                        }
                                    )}`}
                                    onClick={async () => {
                                        let error: boolean = false;
                                        if (selectedIds.length === 1) {
                                            ErrorToast(
                                                `${t(
                                                    "Attention, it is a multiple action. Please select at least two items"
                                                )!}`
                                            );
                                        } else {
                                            let canRun: boolean = true;
                                            try {
                                                for (
                                                    let index = 0;
                                                    index < selectedIds.length;
                                                    index++
                                                ) {
                                                    let item =
                                                        selectedIds[index];
                                                    isAppAssociate = await verifyIfApplicationAssociate(
                                                        item.shopID,
                                                        item.franchiseID,
                                                        item.userID,
                                                        appId,
                                                        item.Id
                                                    );
                                                    if (isAppAssociate) {
                                                        canRun = false;
                                                    }
                                                }
                                                if (
                                                    (canRun &&
                                                        typeDisplay ===
                                                            "notArchived") ||
                                                    typeDisplay === "archived"
                                                ) {
                                                    setFN(async () => {
                                                        for (
                                                            let index = 0;
                                                            index <
                                                            selectedIds.length;
                                                            index++
                                                        ) {
                                                            let item =
                                                                selectedIds[
                                                                    index
                                                                ];

                                                            const link = `${process.env.REACT_APP_API_V2_URL}/settings/application`;
                                                            let savedData = {
                                                                userId:
                                                                    item.userID,
                                                                shopId:
                                                                    item.shopId,
                                                                operatorId:
                                                                    item.operatorId,
                                                                franchiseId:
                                                                    item.franchiseID,
                                                                appId: appId,
                                                                appId_children: [
                                                                    item.Id,
                                                                ],
                                                                isArchived:
                                                                    item.isArchived,
                                                            };
                                                            mutate(
                                                                link,
                                                                await fetch(
                                                                    link,
                                                                    {
                                                                        headers: {
                                                                            "Content-Type":
                                                                                "application/json",
                                                                            authorization: `Bareer ${localStorage.getItem(
                                                                                "jwt"
                                                                            )}`,
                                                                        },

                                                                        method:
                                                                            "Delete",
                                                                        body: JSON.stringify(
                                                                            savedData
                                                                        ),
                                                                    }
                                                                )
                                                                    .then(
                                                                        (
                                                                            response
                                                                        ) =>
                                                                            response.json()
                                                                    )
                                                                    // eslint-disable-next-line no-loop-func
                                                                    .then(
                                                                        // eslint-disable-next-line no-loop-func
                                                                        (
                                                                            data
                                                                        ) => {
                                                                            if (
                                                                                data.error
                                                                            ) {
                                                                                error = true;
                                                                                throw Error(
                                                                                    "Error!"
                                                                                );
                                                                            }

                                                                            if (
                                                                                index ===
                                                                                selectedIds.length -
                                                                                    1
                                                                            ) {
                                                                                if (
                                                                                    error ===
                                                                                        false &&
                                                                                    selectedIds.length >
                                                                                        1
                                                                                ) {
                                                                                    setDataIsUpdated(
                                                                                        true
                                                                                    );

                                                                                    toast.success(
                                                                                        `${t(
                                                                                            displayMode !==
                                                                                                t(
                                                                                                    "View by device"
                                                                                                )
                                                                                                ? typeDisplay ===
                                                                                                  "notArchived"
                                                                                                    ? "This application was successfully archived"
                                                                                                    : "This application was successfully unarchived"
                                                                                                : typeDisplay ===
                                                                                                  "notArchived"
                                                                                                ? "The device was successfully archived"
                                                                                                : "The device was successfully unarchived"
                                                                                        )!}`,
                                                                                        {
                                                                                            position:
                                                                                                toast
                                                                                                    .POSITION
                                                                                                    .TOP_CENTER,
                                                                                            autoClose: 2000,
                                                                                            theme:
                                                                                                "colored",
                                                                                            closeOnClick: true,
                                                                                            pauseOnHover: true,
                                                                                            draggable: true,
                                                                                            icon: (
                                                                                                <CheckboxIcon
                                                                                                    height={
                                                                                                        25
                                                                                                    }
                                                                                                    width={
                                                                                                        25
                                                                                                    }
                                                                                                    fill="white"
                                                                                                />
                                                                                            ),
                                                                                        }
                                                                                    );
                                                                                }
                                                                            }
                                                                        }
                                                                    )
                                                            );
                                                        }
                                                    });
                                                    setMessage(
                                                        t(
                                                            typeDisplay ===
                                                                "notArchived"
                                                                ? "Do you want to confirm the archiving of the selected application?"
                                                                : "Do you want to confirm the unarchiving of the selected application?"
                                                        )
                                                    );
                                                    setIsMultiDeletePeripheralModalOpened(
                                                        true
                                                    );
                                                } else {
                                                    ErrorToast(
                                                        t(
                                                            "Please note that you cannot archive a device associated with an application."
                                                        )
                                                    );
                                                }
                                            } catch (e: any) {
                                                error = true;
                                                toast.error(
                                                    `${t("There's an error")!}`,
                                                    {
                                                        position:
                                                            toast.POSITION
                                                                .TOP_CENTER,
                                                        autoClose: 2000,
                                                        theme: "colored",
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                    }
                                                );
                                            }
                                        }
                                    }}
                                >
                                    <span className="ml-2">
                                        {typeDisplay === "notArchived"
                                            ? t("Archive")
                                            : t("Dearchive")}
                                    </span>
                                </div>
                            </>
                        ) : null}
                        <div
                            className={` dropdown-item pl-1 ${classnames(
                                "cmn_btn_FRhistory",
                                {
                                    "not-allowed-icon__clz":
                                        selectedIds.length < 2,
                                }
                            )}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setIsHistoryModalOpened(true);
                            }}
                        >
                            <span className="ml-2 cmn_btn_FRhistory">
                                {t("Historical")}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
