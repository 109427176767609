import React from "react";
import classnames from "classnames";
import ReactSwitch from "react-switch";
import { toast } from "react-toastify";
import {
    CheckboxIcon,
    EyeIcon,
    PencilIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { Tooltip } from "@mui/material";

import { store as ModeOfInformation } from "../../../../components/VerticalLayout/store";
import store, {
    setFnDelete,
    setNumberActive,
    setNumberArchived,
    setIsDeleteCheckClick,
    setIsActionsClicked,
    setIsEdited,
    setActions,
} from "../store";

import ErrorToast from "@components/Common/ErrorTost";
import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";

type CustomIconsType = {
    e: any;
    setShopId: Function;
    setRowData: Function;
    setInformation: Function;
    setIsModalOpened: Function;
    isModalOpened: boolean;
    setDataIsUpdated: Function;
    setIsHistoryModalOpened: Function;
    isHistoryModalOpened: boolean;
    setSelectedRows: Function;
    isModeOfInformationArchived: boolean;
    setIsAddModeOfInformationClicked: Function;
    setLocalFilterActive: Function;
};
export function CustomIcons({
    e,
    setShopId,
    setRowData,
    setInformation,
    setIsModalOpened,
    isModalOpened,
    setDataIsUpdated,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    setSelectedRows,
    isModeOfInformationArchived,
    setIsAddModeOfInformationClicked,
    setLocalFilterActive,
}: CustomIconsType) {
    const { t } = useTranslation();
    const {
        numberActive,
        numberArchived,
        shopsModeInformationData,
        archivedModeInformationFranchise,
        isActionsClicked,
        isEdited,
        modify,
    } = useSnapshot(store);
    const { franchiseID, oneShop, userID, operatorID } = useSnapshot(
        ModeOfInformation
    );
    const [modeOfInformationState, setModeOfInformationState] = React.useState<
        boolean
    >(e.selectedRow.state);

    let dataAssociate: any[] = [];
    let dataArchive: any[] = [];

    if (!oneShop) {
        dataAssociate = AssociateModeOfInformation();
        dataArchive = ArchiveModeOfInformation();
    } else {
        dataArchive = ArchiveModeOfInformationFranchise();
    }

    function AssociateModeOfInformation() {
        let idsAssociated: any = [];
        if (shopsModeInformationData.data !== undefined) {
            shopsModeInformationData.data.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((elt: any) => {
                        idsAssociated.push(elt.Id);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function ArchiveModeOfInformation() {
        let idsAssociated: any = [];
        if (shopsModeInformationData.data !== undefined) {
            shopsModeInformationData.data.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((el: any) => {
                        idsAssociated.push(el.Id);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function ArchiveModeOfInformationFranchise() {
        let idsAssociated: any = [];

        if (archivedModeInformationFranchise !== undefined) {
            archivedModeInformationFranchise.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }

        idsAssociated = [...new Set(idsAssociated)];

        return idsAssociated;
    }
    async function handleStateChange() {
        let id = e.selectedRow.Id;
        if (id !== undefined) {
            let obj = [
                {
                    userId: userID,
                    operatorId: operatorID,
                    shopId: [e.selectedRow.shopId],
                    franchiseId: franchiseID,
                    isShop: false,
                    data: {
                        [id]: {
                            designation:
                                e.selectedRow[t("Mode of information")],
                            tag: e.selectedRow.tag,
                            supportOfSale: e.selectedRow[t("Sales support")],
                            salesSupportData: [
                                e.selectedRow[t("Sales support")],
                            ],
                            displayName: e.selectedRow[t("Display name")],
                            modeOfSale: e.selectedRow[t("Sales method")],
                            isArchived: false,
                            state: !modeOfInformationState,
                            shopId: e.selectedRow.shopId,
                            stateShop: !modeOfInformationState,
                        },
                    },
                },
            ];

            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`;
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(obj),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.error) {
                            throw Error(result.message);
                        }

                        toast.success(
                            modeOfInformationState === true
                                ? `${t(
                                      "The Mode of information has been successfully deactivated"
                                  )}`
                                : `${t(
                                      "The Mode of information has been successfully activated"
                                  )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated!(true);
                    })
                    .catch((error: any) => {
                        toast.error(`${t("There's an error")!}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    })
            );
        }
    }

    async function activeModeOfInformation() {
        toast.dismiss();
        if (e.selectedRow.Id !== undefined) {
            let dataModeOfInformation = [
                {
                    userId: userID,
                    operatorId: operatorID,
                    shopId: [e.selectedRow.shopId],
                    franchiseId: franchiseID,
                    isShop: false,
                    data: {
                        [e.selectedRow.Id]: {
                            designation:
                                e.selectedRow[t("Mode of information")],
                            tag: e.selectedRow.tag,
                            supportOfSale: e.selectedRow[t("Sales support")],
                            salesSupportData: [
                                e.selectedRow[t("Sales support")],
                            ],
                            displayName: e.selectedRow[t("Display name")],
                            modeOfSale: e.selectedRow[t("Sales method")],
                            isArchived: false,
                            state: e.selectedRow.state,
                            shopId: e.selectedRow.shopId,
                            stateShop: true,
                        },
                    },
                },
            ];

            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`;
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(dataModeOfInformation),
            };
            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            toast.success(
                                `${t(
                                    "Information mode has been successfully unarchived"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setDataIsUpdated!(true);
                        })
                );
            } catch (e: any) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    async function archiveModeOfInformation() {
        toast.dismiss();
        if (e.selectedRow.Id !== undefined) {
            let dataModeOfInformation = [
                {
                    userId: userID,
                    operatorId: operatorID,
                    shopId: [e.selectedRow.shopId],
                    franchiseId: franchiseID,
                    isShop: false,
                    data: {
                        [e.selectedRow.Id]: {
                            designation:
                                e.selectedRow[t("Mode of information")],
                            tag: e.selectedRow.tag,
                            supportOfSale: e.selectedRow[t("Sales support")],
                            salesSupportData: [
                                e.selectedRow[t("Sales support")],
                            ],
                            displayName: e.selectedRow[t("Display name")],
                            modeOfSale: e.selectedRow[t("Sales method")],
                            isArchived: true,
                            state: false,
                            shopId: e.selectedRow.shopId,
                            stateShop: true,
                        },
                    },
                },
            ];

            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`;
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(dataModeOfInformation),
            };
            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            toast.success(
                                `${t(
                                    "The Information mode has been successfully archived"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setDataIsUpdated!(true);
                            setNumberActive(numberActive - 1);
                            setNumberArchived(numberArchived + 1);
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <div
                    className="d-flex justify-content-around"
                    style={{ zIndex: 100 }}
                >
                    {modify === true ? (
                        <>
                            <Tooltip
                                title={
                                    isModeOfInformationArchived ? "" : t("Edit")
                                }
                            >
                                <div>
                                    <PencilIcon
                                        onClick={() => {
                                            if (isEdited === true) {
                                                setIsActionsClicked(
                                                    !isActionsClicked
                                                );
                                                setActions(() => {
                                                    setIsEdited(false);
                                                    setIsAddModeOfInformationClicked(
                                                        true
                                                    );
                                                    setRowData(e.selectedRow);
                                                    setShopId(
                                                        e.selectedRow.shopId
                                                    );
                                                    setInformation(
                                                        e.selectedRow
                                                    );
                                                    setLocalFilterActive(false);
                                                });
                                            } else {
                                                setIsAddModeOfInformationClicked(
                                                    true
                                                );
                                                setRowData(e.selectedRow);
                                                setShopId(e.selectedRow.shopId);
                                                setInformation(e.selectedRow);
                                                setLocalFilterActive(false);
                                            }
                                        }}
                                        height={25}
                                        width={25}
                                        style={{ cursor: "pointer" }}
                                        className={`${classnames(
                                            "pointer__clz cmn_icn_FRedit",
                                            {
                                                "not-allowed-icon__clz": isModeOfInformationArchived,
                                            },
                                            oneShop ? "mr-2" : "ml-3"
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                            {oneShop && (
                                <Tooltip
                                    title={
                                        isModeOfInformationArchived
                                            ? ""
                                            : modeOfInformationState === false
                                            ? t("Activate")
                                            : t("Unactivate")
                                    }
                                >
                                    <div>
                                        <ReactSwitch
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            handleDiameter={26}
                                            offColor="#f7b4b8"
                                            offHandleColor="#E30613"
                                            checked={modeOfInformationState}
                                            onChange={async () => {
                                                setModeOfInformationState(
                                                    !modeOfInformationState
                                                );
                                                handleStateChange();
                                            }}
                                            onColor="#c2eddd"
                                            onHandleColor="#34C38F"
                                            width={50}
                                            height={20}
                                            className={`${classnames(
                                                " cmn_icn_BTenableDisable pointer__clz",
                                                {
                                                    "not-allowed-icon__clz": isModeOfInformationArchived,
                                                },
                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}

                            {!isModeOfInformationArchived ? (
                                <Tooltip title={t("Archive")}>
                                    <div>
                                        <TrashIcon
                                            onClick={() => {
                                                if (isEdited === true) {
                                                    setIsActionsClicked(
                                                        !isActionsClicked
                                                    );
                                                    setActions(() => {
                                                        setIsEdited(false);
                                                        setIsAddModeOfInformationClicked(
                                                            false
                                                        );
                                                        if (
                                                            dataAssociate.includes(
                                                                e.selectedRow.Id
                                                            )
                                                        ) {
                                                            ErrorToast(
                                                                `${t(
                                                                    "Please note that you cannot archive an item assigned to stores."
                                                                )!}`
                                                            );
                                                        } else {
                                                            setIsDeleteCheckClick(
                                                                true
                                                            );
                                                            setFnDelete(
                                                                async () => {
                                                                    archiveModeOfInformation();
                                                                }
                                                            );
                                                        }
                                                    });
                                                } else {
                                                    if (
                                                        dataAssociate.includes(
                                                            e.selectedRow.Id
                                                        )
                                                    ) {
                                                        ErrorToast(
                                                            `${t(
                                                                "Please note that you cannot archive an item assigned to stores."
                                                            )!}`
                                                        );
                                                    } else {
                                                        setIsDeleteCheckClick(
                                                            true
                                                        );
                                                        setFnDelete(
                                                            async () => {
                                                                archiveModeOfInformation();
                                                            }
                                                        );
                                                    }
                                                }
                                            }}
                                            height={25}
                                            width={25}
                                            fill="red"
                                            style={{ cursor: "pointer" }}
                                            className={`${classnames(
                                                "pointer__clz",
                                                {
                                                    cmn_icn_FRarchived: !oneShop,
                                                    cmn_icn_BTarchived: oneShop,
                                                },
                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip title={t("Unarchive")}>
                                    <div>
                                        <EyeIcon
                                            height={25}
                                            width={25}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                if (
                                                    dataArchive.includes(
                                                        e.selectedRow.Id
                                                    ) &&
                                                    !oneShop
                                                ) {
                                                    setIsModalOpened(
                                                        !isModalOpened
                                                    );
                                                } else {
                                                    activeModeOfInformation();
                                                    setNumberActive(
                                                        numberActive + 1
                                                    );
                                                    setNumberArchived(
                                                        numberArchived - 1
                                                    );
                                                }
                                                setInformation(e.selectedRow);
                                                setSelectedRows([
                                                    e.selectedRow,
                                                ]);
                                            }}
                                            className={`${classnames(
                                                "pointer__clz cmn_icn_FRunarchive",
                                                {
                                                    "not-allowed-icon__clz":
                                                        dataArchive.includes(
                                                            e.selectedRow.Id
                                                        ) && oneShop,
                                                },
                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : null}
                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(() => {
                                            setIsEdited(false);
                                            setIsAddModeOfInformationClicked(
                                                false
                                            );
                                            setIsHistoryModalOpened(
                                                !isHistoryModalOpened
                                            );
                                            setRowData(e.selectedRow);
                                            setSelectedRows([e.selectedRow]);
                                        });
                                    } else {
                                        setIsAddModeOfInformationClicked(false);
                                        setIsHistoryModalOpened(
                                            !isHistoryModalOpened
                                        );
                                        setRowData(e.selectedRow);
                                        setSelectedRows([e.selectedRow]);
                                    }
                                }}
                                className={`${classnames(
                                    "pointer__clz cmn_icn_FRhistory",
                                    oneShop ? "mr-2" : "ml-3"
                                )}`}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
        </React.Fragment>
    );
}
