import React from "react";
import { StyledSelectInput } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import {
    designStore,
    generalConfigStore,
    setSelectedOptionsLanguage,
} from "@store";

export function LanguagesSelectWrapper(): JSX.Element {
    const { t } = useTranslation();

    const {
        project: {
            template: {
                content: { languages },
            },
        },
        defaultLanguage,
    } = useSnapshot(generalConfigStore);
    const { selectedLanguage } = useSnapshot(designStore);

    const [languagesOptions] = React.useState<LocalOptionsType[]>(
        (languages as LanguagesType).items
            .filter((language) => {
                return language.active === true;
            })
            .map((option) => ({
                label: option.title,
                value: option.name,
            }))
    );

    const [selectedLanguageOption, setSelectedLanguageOption] = React.useState<
        LocalOptionsType
    >({
        label: defaultLanguage.title,
        value: selectedLanguage,
    });

    function handleSelectOnChangeEvent(selectedValue: LocalOptionsType) {
        setSelectedOptionsLanguage(selectedValue.value as string);
        setSelectedLanguageOption(selectedValue);
    }

    return (
        <StyledSelectInput
            id="languages-select"
            name="languages-select"
            options={languagesOptions}
            value={
                selectedLanguageOption !== undefined
                    ? selectedLanguageOption
                    : languagesOptions[0]
            }
            onChange={handleSelectOnChangeEvent}
            noOptionsMessage={() => t("There's no more choice")}
            className="w-25"
        />
    );
}
