import React, { HTMLProps } from "react";
import { Table } from "reactstrap";
import {
    CellContext,
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    HeaderContext,
    Row,
    useReactTable,
} from "@tanstack/react-table";

import "./index.css";
type CustomReactTablePropsType<Type> = {
    tableColumns: ColumnDef<Type>[];
    data: Type[];
    isRowSelectionEnabled?: boolean;
    globalFilter?: string;
    customRowSelection?: Record<string, boolean>;
    setGlobalFilter?: React.Dispatch<React.SetStateAction<string>>;
    setSelectedRows?: React.Dispatch<React.SetStateAction<Row<Type>[]>>;
    setRowSelection?: React.Dispatch<
        React.SetStateAction<Record<string, boolean>>
    >;
};
export default function CustomReactTable<Type extends any>({
    tableColumns,
    data,
    globalFilter = "",
    isRowSelectionEnabled = false,
    customRowSelection = {},
    setGlobalFilter = () => {},
    setSelectedRows = () => {},
    setRowSelection = () => {},
}: CustomReactTablePropsType<Type>) {
    const rowSelectionColumn = {
        id: "select",
        header: SelectAllRowsCheckBox,
        cell: RowSelectionCheckBox,
    };
    const columns: ColumnDef<Type>[] = [...tableColumns];
    if (isRowSelectionEnabled) {
        columns.unshift(rowSelectionColumn);
    }
    const table = useReactTable({
        data,
        columns,
        state: { globalFilter, rowSelection: customRowSelection },
        globalFilterFn: "includesString",
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onGlobalFilterChange: setGlobalFilter,
        getFilteredRowModel: getFilteredRowModel(),
        getCoreRowModel: getCoreRowModel(),
    });
    const selectedRows = table.getSelectedRowModel().flatRows;
    React.useEffect(() => {
        if (isRowSelectionEnabled) {
            setSelectedRows(selectedRows);
        }
    }, [isRowSelectionEnabled, selectedRows, setSelectedRows]);
    return (
        <Table responsive className="border">
            <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                            <th key={header.id}>
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                          header.column.columnDef.header,
                                          header.getContext()
                                      )}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody>
                {table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                            <td key={cell.id}>
                                {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                )}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}
function IndeterminateCheckbox({
    indeterminate,
    className = "",
    ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
    const ref = React.useRef<HTMLInputElement>(null!);

    React.useEffect(() => {
        if (typeof indeterminate === "boolean") {
            ref.current.indeterminate = !rest.checked && indeterminate;
        }
    }, [ref, indeterminate, rest.checked]);
    return (
        <input type="checkbox" ref={ref} {...rest} className="checkbox__clz" />
    );
}
function SelectAllRowsCheckBox<Type extends any>({
    table,
}: HeaderContext<Type, unknown>) {
    return (
        <IndeterminateCheckbox
            {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
            }}
        />
    );
}
function RowSelectionCheckBox<Type extends any>({
    row,
}: CellContext<Type, unknown>) {
    return (
        <IndeterminateCheckbox
            {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
            }}
        />
    );
}
