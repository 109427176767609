import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { CardBody } from "reactstrap";
import { PlusIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";

import { store, setIsAddPeripheralClicked, setSelectedRowData } from "../store";
import { store as userStore } from "@components/VerticalLayout/store";

import { CashManagementConfigure } from "@pages/Peripherals/CashManagement/CashManagementConfigure";
import { ConfirmationDeleteMessage } from "@components/Common/ConfirmationDeleteMessage";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import DropDownAction from "../DropDownAction";
import PrepheralCardFooter from "../PrepheralCardFooter";
import CashManagementActionColumn from "./CashManagementActionColumn";
import { AlertDeletePeripheralMessage } from "@components/Common/AlertDeletePeripheralMessage";
import { ConfirmationMessageEditPeripheral } from "@components/Common/ConfirmationMessageEditPeripheral";
import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";
import { CustomMainColorButtonWithIcon } from "@components/Common/CustomMainColorButtonWithIcon";

import "../../index.css";
import { getAuthorizationStates } from "@components/VerticalLayout/StyledSidebarMenu";

type CustomColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
};
type DeviceAssociateModalType = {
    setIsDeviceAssociateModalOpened: Function;
    isDeviceAssociateModalOpened: boolean;
    rowData: any;
    dataIsUpdated: boolean | number;
    setDataIsUpdated: Function;
};
export default function CashManagement({
    setIsDeviceAssociateModalOpened,
    isDeviceAssociateModalOpened,
    rowData,
    dataIsUpdated,
    setDataIsUpdated,
}: DeviceAssociateModalType) {
    const { t } = useTranslation();
    const { oneShop, userID, franchiseID, shopID } = useSnapshot(userStore);
    const { isAddPeripheralClicked } = useSnapshot(store);
    const modify = getAuthorizationStates(
        "5590fe0f-69fc-9ff9-699d-d9a9defd12ad"
    ).Modifie;
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [
        isDataCashManagementUpdated,
        setIsDataCashManagementUpdated,
    ] = React.useState<boolean | number>(false);
    const [
        isConfirmationEditRunning,
        setIsConfirmationEditRunning,
    ] = React.useState<boolean>(false);
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [
        isAddNewCashManagementButtonClicked,
        setIsAddNewCashManagementButtonClicked,
    ] = React.useState(false);
    const [isEdited, setIsEdited] = React.useState<boolean>(false);
    const [shopId, setShopId] = React.useState<number>(
        oneShop ? shopID : rowData.shopId
    );
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const [cashManagementId, setCashManagementId] = React.useState<string>("");
    const [editedData, setEditedData] = React.useState<any>([]);
    const [data, setData] = React.useState<any>([]);
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [
        isModalDeleteAssociatePeripheralOpened,
        setIsModalDeleteAssociatePeripheralOpened,
    ] = React.useState<boolean>(false);
    const [inputDesignation, setInputDesignation] = React.useState<boolean>(
        false
    );

    const [inputMarque, setInputMarque] = React.useState<boolean>(false);
    const [inputIp, setInputIp] = React.useState<boolean>(false);
    const [isUpdateOperation, setIsUpdateOperation] = React.useState<boolean>(
        false
    );

    let arrayOfCustomColumns: CustomColumnProps[] = [];

    arrayOfCustomColumns.push({
        indexOFColumn: 999,
        columnName: t("Actions"),
        customJsx: (e: any) => (
            <CashManagementActionColumn
                rowData={rowData}
                element={e.selectedRow}
                isDataCashManagementUpdated={isDataCashManagementUpdated}
            />
        ),
    });
    arrayOfCustomColumns.push({
        indexOFColumn: 0,
        columnName: t("Designation"),
        customJsx: (e: any) => {
            return <div>{e.selectedRow[t("Designation")]}</div>;
        },
    });

    function customJsxComponent(e: any) {
        return (
            (e.selectedRow.original["Application"] !== "" ||
                e.selectedRow.original["Périphérique"] !== "") && (
                <div className="w-100 d-flex justify-content-center">
                    <DropDownAction
                        element={e}
                        setPeripheralId={setCashManagementId}
                        setEditedData={setEditedData}
                        setIsModalOpened={setIsModalOpened}
                        isModalOpened={isModalOpened}
                        setIsModalDeleteAssociatePeripheralOpened={
                            setIsModalDeleteAssociatePeripheralOpened
                        }
                        isModalDeleteAssociatePeripheralOpened={
                            isModalDeleteAssociatePeripheralOpened
                        }
                        setInputIp={setInputIp}
                        setIsConfirmationEditRunning={
                            setIsConfirmationEditRunning
                        }
                        isUpdateOperation={isUpdateOperation}
                    />
                </div>
            )
        );
    }

    useEffect(() => {
        setSelectedRowData(rowData);
        setShopId(oneShop ? shopID : rowData.shopId);
    }, [oneShop, shopID, rowData]);

    return (
        <React.Fragment>
            <CardBody
                className="p-1"
                style={{
                    maxHeight: isAddPeripheralClicked ? "initial" : "720px",
                }}
            >
                <div
                    style={{
                        display: "grid",
                        minHeight: "650px",
                        gridTemplateColumns: isAddPeripheralClicked
                            ? "2fr 1fr "
                            : "auto",
                        gridColumnGap: "4px",
                        marginBottom: isAddPeripheralClicked ? "70px" : "0px",
                        border: 0,
                    }}
                >
                    <DynamicTable
                        requestHeader={{ "Accept-Language": i18nextLng }}
                        url={`${process.env.REACT_APP_API_V2_URL}/settings/cash_management?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&typeDisplay=notArchived`}
                        customJsxSideFilterButton={
                            isAddPeripheralClicked !== true &&
                            modify === true ? (
                                <div className="d-flex justify-content-start align-items-center">
                                    <span
                                        onClick={() => {
                                            setEditedData([]);
                                            setIsAddPeripheralClicked(
                                                !isAddPeripheralClicked
                                            );
                                        }}
                                    >
                                        <CustomMainColorButtonWithIcon
                                            icon="PlusIcon"
                                            iconPosition="left"
                                            className="w-100"
                                            style={{ height: "0px" }}
                                            rounded
                                            variant="primary"
                                        >
                                            {t("Add device")}
                                        </CustomMainColorButtonWithIcon>
                                    </span>
                                </div>
                            ) : modify === true ? (
                                <div className="d-flex justify-content-start">
                                    <CustomMainColorIconButton
                                        icon="PlusIcon"
                                        rounded
                                        onClick={() => {
                                            if (isEdited === true)
                                                setIsInInitializationModalOpened(
                                                    true
                                                );
                                            else {
                                                setInputDesignation(false);
                                                setInputMarque(false);
                                            }
                                        }}
                                        disabled={
                                            editedData.length === 0
                                                ? false
                                                : true
                                        }
                                    >
                                        <PlusIcon
                                            height={20}
                                            width={20}
                                            fill="white"
                                        />
                                    </CustomMainColorIconButton>
                                </div>
                            ) : null
                        }
                        setDataIsUpdated={setIsDataCashManagementUpdated}
                        dataIsUpdated={isDataCashManagementUpdated}
                        showGlobalFilter
                        setData={setData}
                        actionColumn={customJsxComponent}
                        filterActive={filterActive}
                        setLocalFilterActive={setLocalFilterActive}
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        minHeight="520px"
                        maxHeight="520px"
                    />
                    {isAddPeripheralClicked ? (
                        <CashManagementConfigure
                            setIsAddCashManagementClicked={
                                setIsAddPeripheralClicked!
                            }
                            isAddCashManagementClicked={isAddPeripheralClicked}
                            setDataIsUpdated={setIsDataCashManagementUpdated}
                            editedData={editedData}
                            setEditedData={setEditedData}
                            isAddNewCashManagementButtonClicked={
                                isAddNewCashManagementButtonClicked
                            }
                            shopId={shopId}
                            isEdited={isEdited}
                            setIsEdited={setIsEdited}
                            localShopId={oneShop ? [shopID] : [rowData.shopId]}
                            setInputDesignation={setInputDesignation}
                            inputDesignation={inputDesignation}
                            setInputMarque={setInputMarque}
                            inputMarque={inputMarque}
                            setInputIp={setInputIp}
                            inputIp={inputIp}
                            dataTable={data}
                            setIsUpdateOperation={setIsUpdateOperation}
                            isConsult={false}
                            setIsConsult={() => {}}
                        />
                    ) : null}
                </div>
            </CardBody>
            {!isAddPeripheralClicked ? (
                <PrepheralCardFooter
                    dataIsUpdated={dataIsUpdated}
                    setIsEdited={setIsEdited}
                    setIsDeviceAssociateModalOpened={
                        setIsDeviceAssociateModalOpened
                    }
                    isDeviceAssociateModalOpened={isDeviceAssociateModalOpened}
                    rowData={rowData}
                    setDataIsUpdated={setDataIsUpdated}
                />
            ) : null}
            {isModalDeleteAssociatePeripheralOpened === false ? (
                <ConfirmationDeleteMessage
                    isModalOpened={isModalOpened}
                    setIsModalOpened={setIsModalOpened}
                    setDataIsUpdated={setIsDataCashManagementUpdated}
                    id={cashManagementId}
                    text={t("Please confirm archivation of this device")}
                    message={t("The device was successfully archived")}
                    apiUrl={`${
                        process.env.REACT_APP_API_V2_URL
                    }/settings/cashManagement?userId=${userID}&shopId=${
                        oneShop ? shopID : rowData.shopId
                    }&franchiseId=${franchiseID}&id=${cashManagementId}&isArchived=true`}
                    setGlobalDataIsUpdated={setDataIsUpdated}
                />
            ) : (
                <AlertDeletePeripheralMessage
                    text={t(
                        "This device is associated with one or more applications! Unable to perform delete action"
                    )}
                    isModalDeleteAssociatePeripheralOpened={
                        isModalDeleteAssociatePeripheralOpened
                    }
                    setIsModalDeleteAssociatePeripheralOpened={
                        setIsModalDeleteAssociatePeripheralOpened
                    }
                />
            )}
            {isInInitializationModalOpened === true ? (
                <ConfirmationInitializationMessage
                    isModalOpened={isInInitializationModalOpened}
                    setIsModalOpened={setIsInInitializationModalOpened}
                    setIsClicked={setIsAddNewCashManagementButtonClicked}
                    isClicked={isAddNewCashManagementButtonClicked}
                    setIsEdited={setIsEdited}
                />
            ) : null}
            {isConfirmationEditRunning === true ? (
                <ConfirmationMessageEditPeripheral
                    isModalOpened={isConfirmationEditRunning}
                    setIsModalOpened={setIsConfirmationEditRunning}
                    setCancel={setIsAddPeripheralClicked}
                    archive={false}
                />
            ) : null}
        </React.Fragment>
    );
}
