import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { PlusIcon } from "@aureskonnect/react-ui";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";

import { store } from "../../../../components/VerticalLayout/store";
import {
    store as settingStore,
    setNumberArchived,
    setNumberActive,
    setLocalShop,
    setIdsLocalShop,
    setModeInformationData,
    setArchivedModeInformationFranchise,
    setModeInformationFranchise,
    setModeOfSaleFranchiseArchive,
    setDataArchive,
    setDataFranchiseArchive,
    setIsDeleteCheckClick,
    setIsActionsClicked,
    setIsEdited,
    setActions,
    setValidationAction,
} from "../store";
import { store as InformationStore } from "../store";

import { CustomIcons } from "./CustomIcons";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import InformationForm from "./InformationForm";
import { ActionColumnModeInformation } from "./ActionColumnModeInformation";
import { ConfirmationActiveModeOfInformation } from "../ConfirmationActiveModeOfInformation";
import HistoryModal from "../HistoryModal";
import { ConfirmationArchiveDesarchiveComponent } from "../ConfirmationArchiveDesarchiveComponent";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";
import { useCallbackPrompt } from "@hooks/useCallbackPrompt";
import { ConfirmationUpdatingDataMessageModal } from "@components/Common/ConfirmationUpdatingDataMessageModal";
import { CustomMainColorButtonWithIcon } from "@components/Common/CustomMainColorButtonWithIcon";
import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";

import "./index.css";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type ModeOfInformationsType = {
    isModeOfInformationArchived: boolean;
    setIsModeOfInformationArchived: Function;
    setLimit: Function;
};
export default function ModeOfInformations({
    isModeOfInformationArchived,
    setIsModeOfInformationArchived,
    setLimit,
}: ModeOfInformationsType) {
    const { t } = useTranslation();
    const { oneShop, userID, franchiseID, shopID } = useSnapshot(store);
    const { globalDataIsUpdated, isDeleteCheckClick } = useSnapshot(
        InformationStore
    );
    const {
        isActionsClicked,
        isEdited,
        actions,
        modify,
        validationAction,
    } = useSnapshot(settingStore);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        isEdited
    );
    const [
        isAddNewModeOfInformationButtonClicked,
        setIsAddNewModeOfInformationButtonClicked,
    ] = useState(false);
    const [rowData, setRowData] = useState<any>({});
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [
        isAddModeOfInformationClicked,
        setIsAddModeOfInformationClicked,
    ] = React.useState<boolean>(false);

    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isHistoryModalOpened, setIsHistoryModalOpened] = React.useState<
        boolean
    >(false);
    const [Information, setInformation] = useState<any[]>([]);
    const [shopId, setShopId] = React.useState<string | number>(
        oneShop ? shopID : 0
    );
    const [selectedItems, setSelectedItems] = React.useState<any>([]);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [dataTable, setDataTable] = React.useState<any>({});
    const [inputInformationMode, setInputInformationMode] = React.useState<
        boolean
    >(false);
    React.useEffect(() => {
        if (selectedRows.length > 0) {
            if (selectedRows.length === 1) {
                if (selectedRows[0]?.selectedRows !== undefined) {
                    setSelectedItems(selectedRows[0]?.selectedRows);
                }
            } else {
                setSelectedItems(selectedRows);
            }
        } else {
            setSelectedItems([]);
        }
    }, [selectedRows]);
    React.useEffect(() => {
        setDataIsUpdated(true);
    }, [globalDataIsUpdated]);
    const urlArchive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information_archived?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`;

    const urlActive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`;

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 1,
            columnName: t("Information mode"),
            customJsx: (e: any) => <NumberModeOfInformation e={e} />,
            filterName: t("Information mode"),
            canFilter: true,
        },
        {
            indexOFColumn: 3,
            columnName: t("Sales method"),
            customJsx: (e: any) => (
                <CustomColumnDisplay
                    text={
                        Array.isArray(e.selectedRow[t("Sales method")])
                            ? e.selectedRow[t("Sales method")].join(",")
                            : e.selectedRow[t("Sales method")]
                    }
                />
            ),
            filterName: t("Sales method"),
            canFilter: true,
        },
        {
            indexOFColumn: 2,
            columnName: t("Support of sale"),
            customJsx: (e: any) => (
                <CustomColumnDisplay
                    text={
                        e.selectedRow[t("Sales support")] !== undefined &&
                        Object.keys(e.selectedRow[t("Sales support")]).length >
                            0
                            ? e.selectedRow[t("Sales support")]?.label
                            : ""
                    }
                />
            ),
            filterName: t("Support of sale"),
            canFilter: true,
        },
        {
            indexOFColumn: 4,
            columnName: t("Tag"),
            customJsx: (e: any) => (
                <CustomColumnDisplay
                    text={
                        Array.isArray(e.selectedRow.tag)
                            ? e.selectedRow.tag.join(",")
                            : ""
                    }
                />
            ),
            filterName: t("Tag"),
            canFilter: true,
        },
        {
            indexOFColumn: oneShop ? 6 : 7,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    e={e}
                    setShopId={setShopId}
                    setRowData={setRowData}
                    setInformation={setInformation}
                    setIsModalOpened={setIsModalOpened}
                    isModalOpened={isModalOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    setIsHistoryModalOpened={setIsHistoryModalOpened}
                    isHistoryModalOpened={isHistoryModalOpened}
                    setSelectedRows={setSelectedRows}
                    isAddModeOfInformationClicked={
                        isAddModeOfInformationClicked
                    }
                    setIsAddModeOfInformationClicked={
                        setIsAddModeOfInformationClicked
                    }
                    isModeOfInformationArchived={isModeOfInformationArchived}
                    setIsModeOfInformationArchived={
                        setIsModeOfInformationArchived
                    }
                    setLocalFilterActive={setLocalFilterActive}
                />
            ),
            disableFilter: false,
        },
    ];

    function NumberActiveAndArchive() {
        if (dataTable.othersData !== undefined) {
            setNumberActive(dataTable.othersData.numberActive);
            setNumberArchived(dataTable.othersData.numberArchive);
        }
    }

    function NumberModeOfInformation(e: any) {
        return (
            <React.Fragment>
                {e.e.selectedRow?.subRows !== undefined ? (
                    <h6 className="badge__clz">
                        {e.e.selectedRow[t("Shop")] !== ""
                            ? e.e.selectedRow.subRows?.length
                            : 0}
                    </h6>
                ) : (
                    e.e.selectedRow[t("Mode of information")]
                )}
            </React.Fragment>
        );
    }

    React.useEffect(() => {
        if (filterActive) {
            setIsAddModeOfInformationClicked(false);
        }
    }, [filterActive]);

    React.useEffect(() => {
        let localShop: string[] = [];
        let idsLocalShop: string[] = [];
        selectedItems.forEach((el: any) => {
            if (el[t("Shop")] !== "" && el[t("Shop")] !== undefined)
                localShop.push(el.shopId);

            idsLocalShop.push(el.Id);
        });
        setIdsLocalShop(idsLocalShop);
        setLocalShop(localShop);
        setModeOfSaleFranchiseArchive(dataTable);
        setModeInformationData(dataTable);
        setDataArchive(
            dataTable.othersData !== undefined
                ? dataTable.othersData.dataArchive
                : []
        );

        setDataFranchiseArchive(
            dataTable.othersData !== undefined
                ? dataTable.othersData.modeInformationFranchiseArchive
                : []
        );
        setArchivedModeInformationFranchise(
            dataTable.othersData !== undefined
                ? dataTable.othersData.modeInformationFranchiseArchive
                : []
        );
        setModeInformationFranchise(
            dataTable.othersData !== undefined
                ? dataTable.othersData.modeInformationFranchise
                : []
        );
    }, [selectedItems, dataTable, t]);

    React.useEffect(() => {
        NumberActiveAndArchive();
        // eslint-disable-next-line
    }, [dataTable]);

    React.useEffect(() => {
        setActions(confirmNavigation);
        setIsActionsClicked(showPrompt as boolean);
        setValidationAction(cancelNavigation);
    }, [showPrompt, confirmNavigation, cancelNavigation]);
    React.useEffect(() => {
        setDataIsUpdated(true);
    }, [i18nextLng]);

    return (
        <React.Fragment>
            <div
                style={{
                    display: "grid",
                    minHeight: oneShop ? "650px" : "600px",
                    gridTemplateColumns: isAddModeOfInformationClicked
                        ? "2fr 1fr "
                        : "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    url={isModeOfInformationArchived ? urlArchive : urlActive}
                    canExpand={false}
                    canMovedCheckboxLeftOnExpand
                    canSelect
                    customSelect
                    setData={setDataTable}
                    setSelectedRows={setSelectedRows}
                    customJsxSideFilterButton={
                        <React.Fragment>
                            {modify === true ? (
                                <>
                                    {isAddModeOfInformationClicked !== true ? (
                                        <div className="d-flex justify-content-start align-items-center ml-2">
                                            <span
                                                onClick={() => {
                                                    setInformation([]);

                                                    setIsAddModeOfInformationClicked(
                                                        !isAddModeOfInformationClicked
                                                    );

                                                    setLocalFilterActive(false);
                                                }}
                                                className={`${classnames(
                                                    "cmn_btn_openForm",
                                                    {
                                                        "not-allowed-icon__clz": isModeOfInformationArchived,
                                                    }
                                                )}`}
                                            >
                                                <CustomMainColorButtonWithIcon
                                                    icon="PlusIcon"
                                                    iconPosition="left"
                                                    className={`w-100 ml-3 mdi_btn_FRaddModeOfInformation ${classnames(
                                                        "",
                                                        {}
                                                    )}`}
                                                    rounded
                                                    variant="primary"
                                                >
                                                    {t(
                                                        "Add a Mode of information"
                                                    )}
                                                </CustomMainColorButtonWithIcon>
                                            </span>
                                            <div></div>
                                        </div>
                                    ) : (
                                        <div className="d-flex justify-content-start">
                                            <CustomMainColorIconButton
                                                icon="PlusIcon"
                                                className="mdi_btn_FRreset"
                                                rounded
                                                disabled={
                                                    Information.length === 0
                                                        ? false
                                                        : true
                                                }
                                                onClick={() => {
                                                    if (isEdited === true)
                                                        setIsInInitializationModalOpened(
                                                            true
                                                        );
                                                    else {
                                                        setInputInformationMode(
                                                            false
                                                        );
                                                    }
                                                }}
                                            >
                                                <PlusIcon
                                                    height={20}
                                                    width={20}
                                                    fill="white"
                                                />
                                            </CustomMainColorIconButton>
                                            <div className="mt-3"></div>
                                        </div>
                                    )}
                                </>
                            ) : null}
                            <ActionColumnModeInformation
                                setIsHistoryModalOpened={
                                    setIsHistoryModalOpened
                                }
                                isHistoryModalOpened={isHistoryModalOpened}
                                selectedRows={selectedItems}
                                setDataIsUpdated={setDataIsUpdated}
                                setIsModalOpened={setIsModalOpened}
                                isModalOpened={isModalOpened}
                                setIsModeOfInformationArchived={
                                    setIsModeOfInformationArchived
                                }
                                isModeOfInformationArchived={
                                    isModeOfInformationArchived
                                }
                                setIsAddModeOfInformationClicked={
                                    setIsAddModeOfInformationClicked
                                }
                            />
                        </React.Fragment>
                    }
                    canResize
                    showGlobalFilter
                    showFilter
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    actionColumn={() => <></>}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    elevationTable={1}
                    minHeight={oneShop ? "500px" : "450px"}
                    maxHeight={oneShop ? "500px" : "450px"}
                    name="ModeOfInformation"
                />
                {isAddModeOfInformationClicked ? (
                    <InformationForm
                        isAddModeOfInformationClicked={
                            isAddModeOfInformationClicked
                        }
                        setIsAddModeOfInformationClicked={
                            setIsAddModeOfInformationClicked!
                        }
                        setDataIsUpdated={setDataIsUpdated}
                        dataInformation={Information}
                        isAddNewModeOfInformationButtonClicked={
                            isAddNewModeOfInformationButtonClicked
                        }
                        setDataInformation={setInformation}
                        setLocalFilterActive={setLocalFilterActive}
                        inputInformationMode={inputInformationMode}
                        setInputInformationMode={setInputInformationMode}
                        dataIsUpdated={dataIsUpdated}
                        dataTable={dataTable}
                        setLimit={setLimit}
                    />
                ) : null}
            </div>

            <ConfirmationActiveModeOfInformation
                e={Information}
                setIsModalOpened={setIsModalOpened}
                isModalOpened={isModalOpened}
                setDataIsUpdated={setDataIsUpdated}
                selectedRows={selectedRows}
                isOneShop={true}
            />
            {isInInitializationModalOpened === true ? (
                <ConfirmationInitializationMessage
                    isModalOpened={isInInitializationModalOpened}
                    setIsModalOpened={setIsInInitializationModalOpened}
                    setIsClicked={setIsAddNewModeOfInformationButtonClicked}
                    isClicked={isAddNewModeOfInformationButtonClicked}
                    setIsEdited={setIsEdited}
                />
            ) : null}
            {isHistoryModalOpened ? (
                <HistoryModal
                    ids={selectedRows}
                    setIsModalOpened={setIsHistoryModalOpened}
                    isModalOpened={isHistoryModalOpened}
                    rowData={rowData}
                    isOneShop={true}
                    setData={setDataIsUpdated}
                />
            ) : null}
            {isDeleteCheckClick && (
                <ConfirmationArchiveDesarchiveComponent
                    setIsModalOpened={setIsDeleteCheckClick}
                    isModalOpened={isDeleteCheckClick}
                />
            )}
            {isActionsClicked === true ? (
                <ConfirmationUpdatingDataMessageModal
                    isModalOpened={isActionsClicked}
                    setIsModalOpened={setIsActionsClicked}
                    validationAction={validationAction}
                    actions={actions}
                    setIsEdited={setIsEdited}
                />
            ) : null}
        </React.Fragment>
    );
}
