import React, { useEffect, useState } from "react";
import {
    CheckboxIcon,
    CrossIcon,
    StyledLabel,
    StyledSelectInput,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { mutate } from "swr";
import { toast } from "react-toastify";
import classnames from "classnames";
import { useSnapshot } from "valtio";

import { store } from "../../../components/VerticalLayout/store";
import { resetAllPeripherals } from "@pages/Applications/DeviceAssociateModal/store";
import { store as userStore } from "@components/VerticalLayout/store";
import {
    store as cashManagementStore,
    setIsActionsClicked,
    setActions,
} from "./store";

import { getUniqueId } from "@helpers/general";
import CashManagementConfiguration from "../../../pages/Applications/DeviceAssociateModal/CashManagementApplicationForm/CashManagementConfiguration";
import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import {
    CustomCardHeader,
    CustomCardBody,
    CustomCardFooter,
} from "@components/Common/CustomCardStyled";
import { ipMatchWord, numberMatchWord } from "@constants/index";
import ErrorToast from "@components/Common/ErrorTost";
import { verifyIfPeripheralAssociate } from "../../../helpers/peripheralsHelpers";

import "../CashManagement/ModalConfigurations/index.css";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type CashManagementType = {
    setIsAddCashManagementClicked: Function;
    setDataIsUpdated: Function;
    isAddCashManagementClicked: boolean;
    editedData: any;
    setEditedData: Function;
    isAddNewCashManagementButtonClicked: boolean;
    shopId: number | string;
    isEdited: boolean;
    setIsEdited: Function;
    setInputDesignation: Function;
    inputDesignation: boolean;
    setInputMarque: Function;
    inputMarque: boolean;
    inputIp: boolean;
    setInputIp: Function;
    dataTable?: any;
    localShopId?: any;
    isConsult: boolean;
    setIsConsult: Function;
};

export function CashManagementConfigure({
    setIsAddCashManagementClicked,
    isAddCashManagementClicked,
    setDataIsUpdated,
    editedData,
    setEditedData,
    isAddNewCashManagementButtonClicked,
    shopId,
    setIsEdited,
    inputDesignation,
    setInputDesignation,
    inputMarque,
    setInputMarque,
    inputIp,
    setInputIp,
    dataTable,
    localShopId,
    isConsult,
    setIsConsult,
}: CashManagementType) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const { oneShop, shopID } = useSnapshot(userStore);
    const { isActionsClicked, isEdited } = useSnapshot(cashManagementStore);

    const [designation, setDesignation] = useState<string>("");
    const [mark, setMark] = useState<string>("");
    const [remark, setRemark] = useState<string>("");
    const [reference, setReference] = useState<string>("");
    const [ipAddress, setIpAddress] = useState<string>("");
    const [portNumber, setPortNumber] = useState<string>("");
    const [user, setUser] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [posId, setPosId] = useState<string>("");
    const [logDirectory, setLogDirectory] = useState<string>(".\\logMonnayeur");
    const [isNameExist, setIsNameExist] = React.useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [selectMark, setSelectMark] = React.useState<boolean>(false);
    const [inputPort, setInputPort] = React.useState<boolean>(false);
    const [inputUser, setInputUser] = React.useState<boolean>(false);
    const [inputPassword, setInputPassword] = React.useState<boolean>(false);
    const [inputPosId, setInputPosId] = React.useState<boolean>(false);
    const [ipExist, setIpExist] = React.useState<boolean>(false);
    const [emptyIp, setEmptyIp] = React.useState<boolean>(false);

    const appId = "KIOSK";
    let ifPeripheralAssociateObject: {
        isPeripheralAssociate: boolean;
        appId_children: any[];
    };
    const data = {
        mark,
        ipAddress,
        portNumber,
        user,
        password,
        posId,
        logDirectory,
        setIpAddress,
        setPortNumber,
        setUser,
        setPassword,
        setPosId,
        setLogDirectory,
        inputIp,
        setInputIp,
        inputPort,
        setInputPort,
        inputUser,
        setInputUser,
        inputPassword,
        setInputPassword,
        inputPosId,
        setInputPosId,
        ipExist,
        setIpExist,
        emptyIp,
        setEmptyIp,
    };
    const pathname = window.location.pathname.toString();
    const [canValidate, setCanValidate] = React.useState<boolean>(true);
    const notStartWithSpaceRegExp = new RegExp(/^\S/);
    const matchWordDesignation = new RegExp(
        new RegExp(/^[\w]+([-_\s]{1}[A-Za-z0-9]+)*$/i)
    );
    const CashdroReferencesArray: {
        label: string;
        value: string;
    }[] = ["EXEMPLE 1", "EXEMPLE 2", "EXEMPLE 3", "EXEMPLE 4"].map(
        (element: string) => {
            return {
                label: element,
                value: element,
            };
        }
    );
    const GloryReferencesArray: {
        label: string;
        value: string;
    }[] = ["Monnayeur CI-5", "Monnayeur CI-10"].map((element: string) => {
        return {
            label: element,
            value: element,
        };
    });

    const activateCheckbox = () => {
        let checks = document.getElementsByClassName("PrivateSwitchBase-input");
        for (let i = 0; i < checks.length; i++) {
            checks[i].classList.remove("not-allowed-icon-check__clz");
        }
    };

    function uniqueDesignation(designation: string) {
        setIsNameExist(false);

        let names: any[] = [];

        if (localShopId.length === 1) {
            if (dataTable.data.length > 0) {
                if (dataTable.data[0][t("Shop")] === undefined) {
                    dataTable.data.forEach((element: any) => {
                        names.push({
                            shopId: element.shopId,
                            name: element[t("Designation")],
                            id: element.Id,
                        });
                    });
                } else {
                    let fetchData: any = dataTable.data.find(
                        (x: any) => x.shopId === localShopId[0]
                    );
                    if (fetchData?.subRows.length > 0) {
                        fetchData?.subRows.forEach((element: any) => {
                            names.push({
                                shopId: element.shopId,
                                name: element[t("Designation")],
                                id: element.Id,
                            });
                        });
                    }
                }
            }
        }

        if (localShopId.length === 1) {
            if (
                names.findIndex(
                    (elt: any) =>
                        elt.name.toUpperCase() === designation.toUpperCase()
                ) !== -1 &&
                editedData.Id === undefined
            ) {
                setIsNameExist(true);
            } else if (
                editedData.Id !== undefined &&
                names.findIndex(
                    (elt: any) =>
                        elt.name.toUpperCase() === designation.toUpperCase() &&
                        designation.toUpperCase() !==
                            editedData[t("Designation")].toUpperCase()
                ) !== -1
            ) {
                setIsNameExist(true);
            }
        }
    }
    function IsExistPeripheral(data: any, des: string) {
        let res: boolean = true;
        for (let index = 0; index < data.length; index++) {
            const element = data[index][t("Designation")]?.toString();
            if (element.toUpperCase() === des.toUpperCase()) {
                res = false;
            }
        }
        return res;
    }
    async function handleValidateButtonClickEvent() {
        toast.dismiss();

        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/cash_management`;
        let userId: number = userID;
        let franchiseId: string = franchiseID;

        let listShopId = localShopId;

        if (localShopId.length === 0) {
            ErrorToast(`${t("Attention ! you must select a store.")!}`);
        } else {
            if (canValidate) {
                setCanValidate(false);
                try {
                    let action: boolean = false;

                    for (let index = 0; index < listShopId.length; index++) {
                        const element = listShopId[index];

                        let savedData = {
                            userId: userId,
                            shopId: element,
                            franchiseId: franchiseId,
                            operatorId: operatorID,
                            data: [
                                {
                                    id: getUniqueId(10),
                                    designation: designation?.trim(),
                                    mark: mark,
                                    note: remark,
                                    reference: reference,
                                    ip: ipAddress,
                                    port_number: portNumber,
                                    user: user,
                                    password: password,
                                    server_directory: logDirectory,
                                    pos_id: posId,
                                    shopId: element,
                                    edit_date: new Date(),
                                    add_date: new Date(),
                                    isArchived: false,
                                },
                            ],
                        };

                        let dataSearch =
                            oneShop ||
                            (dataTable.data.length > 0 &&
                                dataTable.data[0][t("Shop")] === undefined) ||
                            dataTable.data.length === 0
                                ? dataTable.data
                                : dataTable.data.find((x: any) => {
                                      return x.shopId === element;
                                  }).subRows;

                        if (
                            IsExistPeripheral(dataSearch, designation?.trim())
                        ) {
                            action = true;
                            mutate(
                                apiUrl,
                                await fetch(apiUrl, {
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: `Bareer ${localStorage.getItem(
                                            "jwt"
                                        )}`,
                                    },
                                    body: JSON.stringify(savedData),
                                    method: "POST",
                                })
                                    .then((response) => response.json())
                                    .then((data) => {
                                        if (data.error) {
                                            throw Error(
                                                "Error while saving selection!"
                                            );
                                        }
                                        setCanValidate(true);
                                    })
                            );
                        }
                    }
                    if (action) {
                        setDataIsUpdated(true);
                        toast.success(
                            `${t("The device is successfully added")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                    }
                    resetAllPeripherals();
                    setDataIsUpdated(true);
                    setEditedData([]);
                    setIsEdited(false);
                    activateCheckbox();

                    // setIsAddCashManagementClicked(false);
                } catch (e: any) {
                    setCanValidate(true);
                    toast.error(`${t("There's an error")}!`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        }
    }

    async function handleEditButtonClickEvent() {
        toast.dismiss();
        let userId: number;
        let localShopId: string | number;
        let franchiseId: string;
        if (oneShop) {
            userId = userID;
            localShopId = shopID;
            franchiseId = franchiseID;
        } else {
            userId = userID;
            localShopId = shopId;
            franchiseId = franchiseID;
        }
        ifPeripheralAssociateObject = await verifyIfPeripheralAssociate(
            editedData.Id,
            localShopId,
            franchiseID,
            userID,
            appId
        );
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/cash_management?isPeripheralAssociate=${ifPeripheralAssociateObject.isPeripheralAssociate}`;
        let savedData = {
            userId: userId,
            shopId: localShopId,
            franchiseId: franchiseId,
            appId: appId,
            operatorId: operatorID,
            appId_children: ifPeripheralAssociateObject.appId_children,
            peripheralId: editedData.Id,
            data: [
                {
                    id: editedData.Id,
                    designation: designation?.trim(),
                    mark: mark,
                    reference: reference,
                    note: remark,
                    ip: ipAddress,
                    shopId: shopId,
                    port_number: portNumber,
                    user: user,
                    password: password,
                    server_directory: logDirectory,
                    pos_id: posId,
                    edit_date: new Date(),
                    add_date: "",
                    isArchived: false,
                },
            ],
        };
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(savedData),
                    method: "PUT",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error!");
                        }
                        toast.success(
                            `${t("The device is successfully changed")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        resetAllPeripherals();
                        setIsAddCashManagementClicked!(
                            !isAddCashManagementClicked
                        );
                        setDataIsUpdated(true);
                        setEditedData([]);
                        setIsEdited(false);
                        activateCheckbox();
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }
    useEffect(() => {
        if (editedData.length !== 0) {
            setDesignation(editedData[t("Designation")]);
            setMark(editedData[t("Mark")]);
            setReference(editedData[t("Reference")]);
            setRemark(editedData[t("Remark")]);
            setIpAddress(editedData.ip);
            setPortNumber(editedData.port_number);
            setUser(editedData.user);
            setPassword(editedData.password);
            setPosId(editedData.pos_id);
            setLogDirectory(editedData.server_directory);
            setEmptyIp(false);
        } else {
            setDesignation("");
            setMark("");
            setReference("");
            setRemark("");
            setEmptyIp(false);
        }
        setInputDesignation(false);
        setInputMarque(false);
        setInputIp(false);
        setInputPort(false);
        setInputUser(false);
        setInputPosId(false);
        setInputPassword(false);
        setEmptyIp(false);
        // eslint-disable-next-line
    }, [editedData, isAddNewCashManagementButtonClicked, oneShop]);

    React.useEffect(() => {
        uniqueDesignation(designation);
        setIsNameExist(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataTable, localShopId, editedData.Id]);
    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader className="d-flex align-items-center justify-content-between">
                    <h5 className="pt-3 mon_txt_addCoinDevice">
                        {isConsult === true
                            ? t("View peripheral: coiners")
                            : editedData.length === 0
                            ? t("Add device: coiners")
                            : t("Modify a device: coiners")}
                    </h5>
                    <CrossIcon
                        style={{ cursor: "pointer" }}
                        className="mon_icn_closeForm"
                        height={13}
                        width={13}
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    resetAllPeripherals();
                                    setEditedData([]);
                                    setIsAddCashManagementClicked!(
                                        !isAddCashManagementClicked
                                    );
                                    setIsEdited(false);
                                    activateCheckbox();
                                    setIsConsult(false);
                                });
                            } else {
                                resetAllPeripherals();
                                setEditedData([]);
                                setIsAddCashManagementClicked!(
                                    !isAddCashManagementClicked
                                );
                                setIsEdited(false);
                                activateCheckbox();
                                setIsConsult(false);
                            }
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className="pl-3 pr-5"
                    style={{
                        maxHeight:
                            pathname === "/peripherals/cash-management"
                                ? "550px"
                                : pathname === "/kiosk-peripheral-setting"
                                ? "610px"
                                : "600px",
                        overflowY: " scroll",
                    }}
                >
                    <AvGroup>
                        <StyledLabel
                            className="required__clz mt-3"
                            htmlFor="name"
                        >
                            {t("Designation")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames("mon_inp_name", {
                                input__clz:
                                    inputDesignation ||
                                    isNameExist ||
                                    (designation?.trim() === "" &&
                                        designation !== ""),
                                readOnly__clz: isConsult,
                            })}
                            autocomplete="off"
                            id="designation"
                            name="designation"
                            placeholder={t("Enter")}
                            type="text"
                            onChange={(e: any) => {
                                setDesignation(e.target.value);
                                setIsEdited(true);
                                setInputDesignation(false);
                                uniqueDesignation(e.target.value);
                            }}
                            value={designation}
                            validate={{
                                pattern: {
                                    value: matchWordDesignation,
                                    errorMessage: t("Prohibited characters"),
                                },
                            }}
                            autoFocus={isConsult === true ? false : true}
                        />
                        {inputDesignation ||
                        (designation?.trim() === "" && designation !== "") ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a designation")}
                            </div>
                        ) : null}
                        {isNameExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("The name is already exists")!}
                            </div>
                        ) : null}
                    </AvGroup>

                    <AvGroup>
                        <StyledLabel
                            className="required__clz mt-3"
                            htmlFor="mark"
                        >
                            {t("Mark")}
                        </StyledLabel>
                        {mark !== "" ? (
                            <StyledSelectInput
                                value={{
                                    label: mark,
                                    value: mark,
                                }}
                                className={classnames("mon_inp_mark", {
                                    invalid__clz: selectMark,
                                    readOnly__clz: isConsult,
                                    "not-allowed-input__clz":
                                        editedData.Id !== undefined,
                                })}
                                onChange={(e: any) => {
                                    setMark(e.value);
                                    setIsEdited(true);
                                    setRemark("");
                                    setIpAddress("");
                                    setPortNumber("");
                                    setUser("");
                                    setPassword("");
                                    setPosId("");
                                    e.value === "Cashdro"
                                        ? setReference("EXAMPLE 1")
                                        : setReference("Monnayeur CI-5");
                                }}
                                options={[
                                    {
                                        label: "Glory",
                                        value: "Glory",
                                    },
                                    {
                                        label: "Cashdro",
                                        value: "Cashdro",
                                    },
                                ]}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: `${t(
                                            "Please select a mark"
                                        )!}`,
                                    },
                                }}
                                name="mark"
                                noOptionsMessage={() => t("No options")}
                            />
                        ) : (
                            <React.Fragment>
                                <StyledSelectInput
                                    className={classnames("mon_inp_mark", {
                                        invalid__clz: inputMarque,
                                        readOnly__clz: isConsult,
                                    })}
                                    placeholder={t("Select")}
                                    onChange={(e: any) => {
                                        setSelectMark(false);
                                        setMark(e.value);
                                        setIsEdited(true);
                                        setRemark("");
                                        setIpAddress("");
                                        setPortNumber("");
                                        setUser("");
                                        setPassword("");
                                        setPosId("");
                                        setIsEdited(true);

                                        e.value === "Cashdro"
                                            ? setReference("EXAMPLE 1")
                                            : setReference("Monnayeur CI-5");
                                    }}
                                    options={[
                                        {
                                            label: "Glory",
                                            value: "Glory",
                                        },
                                        {
                                            label: "Cashdro",
                                            value: "Cashdro",
                                        },
                                    ]}
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: `${t(
                                                "Please select a mark"
                                            )!}`,
                                        },
                                    }}
                                    name="mark"
                                    noOptionsMessage={() => t("No options")}
                                    isClearable={true}
                                />
                                {inputMarque ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t("Please select a mark")}
                                    </div>
                                ) : null}
                            </React.Fragment>
                        )}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            className="required__clz mt-3"
                            htmlFor="reference"
                        >
                            {t("Reference")}
                        </StyledLabel>
                        {reference ? (
                            <StyledSelectInput
                                isDisabled={mark === ""}
                                onChange={(e: any) => {
                                    setReference(e.value);
                                    setIsEdited(true);
                                }}
                                value={{
                                    label: reference,
                                    value: reference,
                                }}
                                options={
                                    mark === "Cashdro"
                                        ? CashdroReferencesArray
                                        : GloryReferencesArray
                                }
                                name="reference"
                                className={classnames("mon_inp_reference", {
                                    readOnly__clz: isConsult,
                                })}
                                noOptionsMessage={() => t("No options")}
                            />
                        ) : (
                            <React.Fragment>
                                <StyledSelectInput
                                    isDisabled={mark === ""}
                                    placeholder={t("Select")}
                                    className={classnames("mon_inp_reference", {
                                        readOnly__clz: isConsult,
                                    })}
                                    onChange={(e: any) => {
                                        setReference(e.value);
                                        setIsEdited(true);
                                    }}
                                    options={
                                        mark === "Cashdro"
                                            ? CashdroReferencesArray
                                            : GloryReferencesArray
                                    }
                                    name="reference"
                                    noOptionsMessage={() => t("No options")}
                                />
                            </React.Fragment>
                        )}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel className="mt-3" htmlFor="remark">
                            {t("Remark")}
                        </StyledLabel>
                        <StyledTextInput
                            autocomplete="off"
                            className={classnames("mon_inp_remark", {
                                readOnly__clz: isConsult,
                            })}
                            id="remark"
                            name="remark"
                            placeholder={t("Enter")}
                            type="text"
                            onChange={(e: any) => {
                                setRemark(e.target.value);
                                setIsEdited(true);
                            }}
                            value={remark}
                        />
                    </AvGroup>

                    <CashManagementConfiguration
                        data={data}
                        setDataIsUpdated={setIsEdited}
                        isEdited={isEdited}
                        setIsEdited={setIsEdited}
                        editedData={editedData}
                        localShopId={localShopId}
                        isConsult={isConsult}
                    />
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="mr-2 mon_btn_cancel"
                        variant="light"
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setEditedData([]);
                                    setIsAddCashManagementClicked!(
                                        !isAddCashManagementClicked
                                    );
                                    setIsEdited(false);
                                    setIsConsult(false);
                                    activateCheckbox();
                                });
                            } else {
                                setEditedData([]);
                                setIsAddCashManagementClicked!(
                                    !isAddCashManagementClicked
                                );
                                setIsEdited(false);
                                activateCheckbox();
                                setIsConsult(false);
                            }
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        className={classnames("mon_btn_validate", {
                            "not-allowed-icon__clz": isConsult,
                        })}
                        variant="primary"
                        onClick={() => {
                            if (designation === "") {
                                setInputDesignation(true);
                            }
                            if (mark === "") {
                                setInputMarque(true);
                            }
                            if (mark !== "") {
                                if (portNumber === "") {
                                    setInputPort(true);
                                }
                                if (user === "") {
                                    setInputUser(true);
                                }
                                if (password === "") {
                                    setInputPassword(true);
                                }
                                if (posId === "") {
                                    setInputPosId(true);
                                }
                                if (ipAddress === "") {
                                    setEmptyIp(true);
                                }
                            }
                            if (
                                editedData.length === 0 &&
                                mark.length !== 0 &&
                                designation.length !== 0 &&
                                reference.length !== 0 &&
                                ipAddress.length !== 0 &&
                                portNumber.length !== 0 &&
                                !isNameExist &&
                                designation?.trim() !== "" &&
                                designation.match(notStartWithSpaceRegExp) &&
                                designation.match(matchWordDesignation) &&
                                ipAddress.match(ipMatchWord) &&
                                portNumber.match(numberMatchWord) &&
                                data.ipExist === false
                            ) {
                                if (
                                    mark === "Cashdro" &&
                                    posId.length !== 0 &&
                                    user.length !== 0 &&
                                    password.length !== 0 &&
                                    ipAddress.match(ipMatchWord)
                                ) {
                                    handleValidateButtonClickEvent();
                                } else if (mark === "Glory") {
                                    handleValidateButtonClickEvent();
                                }
                            } else if (
                                editedData.length !== 0 &&
                                mark.length !== 0 &&
                                designation.length !== 0 &&
                                reference.length !== 0 &&
                                ipAddress.length !== 0 &&
                                portNumber.length !== 0 &&
                                !isNameExist &&
                                designation?.trim() !== "" &&
                                designation.match(notStartWithSpaceRegExp) &&
                                designation.match(matchWordDesignation) &&
                                ipAddress.match(ipMatchWord) &&
                                data.ipExist === false
                            ) {
                                if (
                                    mark === "Cashdro" &&
                                    posId.length !== 0 &&
                                    user.length !== 0 &&
                                    password.length !== 0 &&
                                    ipAddress.match(ipMatchWord)
                                ) {
                                    if (isEdited === false) {
                                        setIsModalOpened(!isModalOpened);
                                    } else handleEditButtonClickEvent();
                                } else if (mark === "Glory") {
                                    if (isEdited === false) {
                                        setIsModalOpened(!isModalOpened);
                                    } else handleEditButtonClickEvent();
                                }
                            }
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
            <ConfirmationEditMessage
                setDataIsUpdated={setDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddCashManagementClicked}
                setIsAddClicked={setIsAddCashManagementClicked}
                setEditedData={setEditedData}
            />
        </React.Fragment>
    );
}
