import React from "react";
import { useTranslation } from "react-i18next";
import { ModalFooter } from "reactstrap";
import { CheckboxIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { v4 as uuid } from "uuid";

import { setIsProjectModesModalOpened } from "../../../store";
import { store, setIsCreatedCoping } from "../../../store/project/index";
import { mutate } from "swr";
import { toast } from "react-toastify";
import moment from "moment";

import { store as userStore } from "@components/VerticalLayout/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

type CardFooterCreatedCopingType = {
    rowData: any;
    setIsDataUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    shopId: string;
    dataProject: any;
};

export default function CardFooterCreatedCoping({
    rowData,
    setIsDataUpdated,
    dataProject,
}: CardFooterCreatedCopingType) {
    const { t } = useTranslation();
    const { isCreatedCoping } = useSnapshot(store);
    const {
        userID,
        franchiseID,
        oneShop,
        shopID,
        shopName,
        operatorID,
    } = useSnapshot(userStore);
    function AssociateProject() {
        let idShops: any = [];
        if (rowData !== undefined) {
            Object.values(rowData.shopKey).forEach((el: any) => {
                idShops.push(el);
            });
        }
        idShops = [...new Set(idShops)];
        return idShops;
    }
    function project() {
        let project: any = [];
        if (dataProject !== undefined) {
            dataProject?.dataProjectFranchise?.data.forEach((el: any) => {
                if (el.projectId === rowData.projectId) {
                    project.push(el);
                }
            });
        }
        project = [...new Set(project)];
        return project;
    }
    console.log(project()[0]);
    async function handleSaveProjectsButtonOnClickEvent() {
        let kioskApiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/kiosk/template`;
        let projectId = uuid();
        let kioskLocalData: any[] = [];
        if (!oneShop) {
            rowData.subRows.forEach((element: any) => {
                element.subRows.forEach((elt: any) => {
                    if (elt.template !== "") {
                        let savedData = {
                            shopId: elt.shopId,
                            userId: userID,
                            franchiseId: franchiseID,
                            appId: "KIOSK",
                            appId_children: elt.appId,
                            operatorId: operatorID,
                            etat: "Dupplication",
                            data: {
                                [projectId]: {
                                    projectId: projectId,
                                    template: elt.template,
                                    files: elt.files,
                                    hoursOfUse: elt.hoursOfUse,
                                },
                            },
                        };
                        console.log("aa", savedData);
                        kioskLocalData.push(
                            fetch(kioskApiUrl, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify(savedData),
                            }).then((response) => response.json())
                        );
                    }
                });
            });
        } else {
            rowData.subRows.forEach((elt: any) => {
                if (elt.template !== "") {
                    let savedData = {
                        shopId: elt.shopId,
                        userId: userID,
                        franchiseId: franchiseID,
                        appId: "KIOSK",
                        appId_children: elt.appId,
                        etat: "Dupplication",
                        operatorId: operatorID,
                        data: {
                            [projectId]: {
                                projectId: projectId,
                                template: elt.template,
                                files: elt.files,
                                hoursOfUse: elt.hoursOfUse,
                            },
                        },
                    };
                    console.log("cc", savedData);
                    kioskLocalData.push(
                        fetch(kioskApiUrl, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(savedData),
                        }).then((response) => response.json())
                    );
                }
            });
        }
        if (Object.keys(kioskLocalData).length !== 0) {
            mutate(
                kioskApiUrl,
                await Promise.all(kioskLocalData).then((result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (errors.includes(true)) {
                        throw Error(result.message);
                    }
                })
            );
        }

        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project`;
        let dataAssociate: any = AssociateProject();
        let localData: any = [];
        let associates: any = {};
        let savedData;
        let projects: any = project();
        let data: any = {};
        data[shopID] = {
            designation: shopName,
            id_boutique: shopID,
            hoursOfUse:
                projects[0].associates[shopID] !== undefined
                    ? projects[0].associates[shopID].hoursOfUse
                    : "",
        };

        oneShop ? (associates = data) : (associates = rowData.associates);
        savedData = {
            userId: userID,
            shopId: "0",
            franchiseId: franchiseID,
            etat: "Dupplication",
            operatorId: operatorID,
            itemProject: projects[0].projectId,
            designationProject: projects[0].Désignation,
            data: {
                PROJECT: {
                    project_id: "PROJECT",
                    data_project: {
                        [projectId]: {
                            projectId: projectId,
                            designation: `${projects[0].Désignation}_${t(
                                "Copy"
                            )}_${moment().format("DD/MM/YY - HH:mm:ss")}`,
                            associates: associates,
                            template: projects[0].template,
                            files: projects[0].files,
                            isArchived: false,
                            note: projects[0].Remarque,
                            nombre: projects[0].Nombre,
                            status: "en attente",
                            "Modifié le": `${moment().format(
                                "DD/MM/YY - HH:mm a"
                            )}`,
                            hour: `${moment().format("DD/MM/YY - HH:mm a")}`,
                        },
                    },
                },
            },
        };
        console.log("bb", savedData);
        localData.push(
            fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(savedData),
            }).then((response) => response.json())
        );
        if (!oneShop) {
            if (dataAssociate.length > 0) {
                rowData.subRows.forEach((el: any) => {
                    savedData = {
                        userId: userID,
                        shopId: el.id_boutique,
                        franchiseId: franchiseID,
                        operatorId: operatorID,
                        itemProject: projects[0].projectId,
                        designationProject: projects[0].Désignation,
                        etat: "Dupplication",
                        data: {
                            PROJECT: {
                                project_id: "PROJECT",
                                data_project: {
                                    [projectId]: {
                                        projectId: projectId,
                                        designation: `${el.Project}_${t(
                                            "Copy"
                                        )}_${moment().format(
                                            "DD/MM/YY - HH:mm:ss"
                                        )}`,
                                        template: el.template,
                                        files: el.files,
                                        isArchived: false,
                                        note: el.Remarque,
                                        status: "en attente",
                                        "Modifié le": `${moment().format(
                                            "DD/MM/YY - HH:mm a"
                                        )}`,
                                        hour: `${moment().format(
                                            "DD/MM/YY - HH:mm a"
                                        )}`,
                                    },
                                },
                            },
                        },
                    };
                    console.log("1", savedData);
                    localData.push(
                        fetch(apiUrl, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(savedData),
                        }).then((response) => response.json())
                    );
                });
            }
        } else {
            savedData = {
                userId: userID,
                shopId: shopID,
                franchiseId: franchiseID,
                etat: "Dupplication",
                operatorId: operatorID,
                itemProject: projects[0].projectId,
                designationProject: projects[0].Désignation,
                data: {
                    PROJECT: {
                        project_id: "PROJECT",
                        data_project: {
                            [projectId]: {
                                projectId: projectId,
                                designation: `${rowData.Désignation}_${t(
                                    "Copy"
                                )}_${moment().format("DD/MM/YY - HH:mm:ss")}`,
                                template: rowData.template,
                                files: rowData.files,
                                isArchived: false,
                                note: rowData.Remarque,
                                nombre: rowData.Nombre,
                                status: "en attente",
                                "Modifié le": `${moment().format(
                                    "DD/MM/YY - HH:mm a"
                                )}`,
                                hour: `${moment().format(
                                    "DD/MM/YY - HH:mm a"
                                )}`,
                            },
                        },
                    },
                },
            };
            console.log("88", savedData);
            localData.push(
                fetch(apiUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(savedData),
                }).then((response) => response.json())
            );
        }

        try {
            mutate(
                apiUrl,
                await Promise.all(localData).then((result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                        toast.success(
                            `${t("The project is copied successfully")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsDataUpdated(true);
                    } else {
                        throw Error(result.message);
                    }
                })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <ModalFooter className="border-top-0">
            <CustomMainColorButton
                outline
                variant="primary"
                onClick={() => {
                    setIsCreatedCoping!(!{ isCreatedCoping });
                }}
                rounded
            >
                {t("Cancel")}
            </CustomMainColorButton>
            <CustomMainColorButton
                rounded
                className="mr-2"
                variant="primary"
                onClick={() => {
                    handleSaveProjectsButtonOnClickEvent();

                    setIsProjectModesModalOpened(true);
                    setIsCreatedCoping(false);
                }}
            >
                {t("Validate")}
            </CustomMainColorButton>
        </ModalFooter>
    );
}
