import React from "react";
import { useTranslation } from "react-i18next";
import { Nav, NavItem, TabContent, TabPane, NavLink } from "reactstrap";
import classNames from "classnames";
import { useSnapshot } from "valtio";

import {
    setActiveTabIndex,
    setIsPaymentImageActive,
    setIsPaymentMessageActive,
    setIsMainContentChangeMachine,
    setInformationMessageItem,
    setAmountToBePaidItem,
    setTotalAmountItem,
    setPaymentInstructionsItem,
    setActionsButtonsItems,
    setIsValinaOptionActive,
    setPaymentTPAInformationMessageItems,
    setPaymentTPAActionsButtonsItems,
    setMainContentItemTPA,
    generalConfigStore,
    designStore,
    setIsLogoActive,
    setIsActionButtonsActive,
    setIsInformationMessageActive,
    setIsTopBannerActive,
    setInformationMessageItems,
    setIsHeaderActive,
    setHasSubSteps,
    setIsBackgroundActive,
    computedStore,
} from "@store";

import { Model } from "./Model";
import { Transition } from "../Transition";

import "@pages/GeneralConfig/Design/index.css";
import { Design } from "./Design";

export function PaymentContent(): JSX.Element {
    const { t } = useTranslation();

    const {
        project: { template },
    } = useSnapshot(generalConfigStore);
    const {
        selectedLanguage,
        activeSlideIndex,
        paymentSelectedRegulationModeValue,
    } = useSnapshot(designStore);
    const { elements } = useSnapshot(computedStore);
    const tabs: TabsType[] = [
        {
            title: t("Model"),
            content: <Model />,
        },
        {
            title: t("Design"),
            content: <Design />,
        },
        {
            title: t("Transition"),
            content: <Transition />,
        },
    ];

    const [activeTab, setActiveTab] = React.useState<number>(0);

    function toggleNav(tab: number) {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    React.useEffect(() => {
        if (activeTab === 1) {
            setActiveTabIndex(0);
        } else {
            setActiveTabIndex(-1);
        }
    }, [activeTab]);

    React.useEffect(() => {
        if (elements.length > 0 && elements[activeSlideIndex] !== undefined) {
            setIsValinaOptionActive(
                ((template.content
                    .payment as PaymentContentItemType).items.filter(
                    (regulationMode) => {
                        return regulationMode.shortName === "creditCard";
                    }
                )[0].languages[selectedLanguage].paymentInstructions
                    .TPAValina as TPAValinaType).active as boolean
            );

            setPaymentTPAInformationMessageItems(
                (template.content.TPAValina as TPAValinaPageType).header
            );

            setMainContentItemTPA(
                ((template.content
                    .payment as PaymentContentItemType).items.filter(
                    (regulationMode) => {
                        return regulationMode.shortName === "creditCard";
                    }
                )[0].languages[selectedLanguage].paymentInstructions
                    .TPAValina as TPAValinaType).items
            );
            setIsBackgroundActive(
                (template.pages.ways["payment"] as PaymentPageType).background
                    .active
            );

            setIsHeaderActive(
                (template.pages.ways["payment"] as PaymentPageType)
                    .regulationMode[paymentSelectedRegulationModeValue].header
                    .active
            );

            setIsLogoActive(
                (template.pages.ways["payment"] as PaymentPageType).topBanner
                    .logo.active
            );

            setIsTopBannerActive(
                (template.pages.ways["payment"] as PaymentPageType).topBanner
                    .active
            );

            setIsInformationMessageActive(
                (template.pages.ways["payment"] as PaymentPageType)
                    .regulationMode[paymentSelectedRegulationModeValue].header
                    .informationMessage.active
            );

            setInformationMessageItems(
                (template.pages.ways["payment"] as PaymentPageType)
                    .regulationMode[paymentSelectedRegulationModeValue].header
                    .informationMessage.languages
            );

            setHasSubSteps(
                (template.pages.ways["payment"] as PaymentPageType)
                    .hasSubSteps as boolean
            );

            setIsPaymentImageActive(
                (template.content
                    .payment as PaymentContentItemType).items.filter(
                    (regulationMode) => {
                        return (
                            regulationMode.shortName ===
                            paymentSelectedRegulationModeValue
                        );
                    }
                )[0].languages[selectedLanguage].paymentInstructions
                    .isImageVisible as boolean
            );

            setIsPaymentMessageActive(
                (template.content
                    .payment as PaymentContentItemType).items.filter(
                    (regulationMode) => {
                        return (
                            regulationMode.shortName ===
                            paymentSelectedRegulationModeValue
                        );
                    }
                )[0].languages[selectedLanguage].paymentInstructions
                    .isTextVisible
            );

            setIsMainContentChangeMachine(
                (template.content
                    .payment as PaymentContentItemType).items.filter(
                    (regulationMode) => {
                        return (
                            regulationMode.shortName ===
                            paymentSelectedRegulationModeValue
                        );
                    }
                )[0].languages[selectedLanguage].paymentInstructions.active
            );

            setInformationMessageItem(
                (template.content
                    .payment as PaymentContentItemType).items.filter(
                    (regulationMode) => {
                        return (
                            regulationMode.shortName ===
                            paymentSelectedRegulationModeValue
                        );
                    }
                )[0].languages[selectedLanguage].amount.informationMessage
            );

            setAmountToBePaidItem(
                (template.content
                    .payment as PaymentContentItemType).items.filter(
                    (regulationMode) => {
                        return (
                            regulationMode.shortName ===
                            paymentSelectedRegulationModeValue
                        );
                    }
                )[0].languages[selectedLanguage].amount.amountToBePaid
            );

            setTotalAmountItem(
                (template.content
                    .payment as PaymentContentItemType).items.filter(
                    (regulationMode) => {
                        return (
                            regulationMode.shortName ===
                            paymentSelectedRegulationModeValue
                        );
                    }
                )[0].languages[selectedLanguage].amount.totalAmount
            );

            setPaymentInstructionsItem(
                (template.content
                    .payment as PaymentContentItemType).items.filter(
                    (regulationMode) => {
                        return (
                            regulationMode.shortName ===
                            paymentSelectedRegulationModeValue
                        );
                    }
                )[0].languages[selectedLanguage].paymentInstructions
            );

            setIsActionButtonsActive(
                (template.pages.ways["payment"] as PaymentPageType)
                    .regulationMode[paymentSelectedRegulationModeValue]
                    .actionsButtons.active
            );

            setActionsButtonsItems(
                (template.pages.ways["payment"] as PaymentPageType)
                    .regulationMode[paymentSelectedRegulationModeValue]
                    .actionsButtons.items
            );

            setPaymentTPAActionsButtonsItems(
                (template.content.TPAValina as TPAValinaPageType).actionsButtons
                    .items
            );
        }
    }, [
        activeSlideIndex,
        elements,
        paymentSelectedRegulationModeValue,
        selectedLanguage,
        template,
    ]);
    return (
        <div style={{ height: "634px" }}>
            <div className="cursor__clz pl-3 py-2" style={{ fontSize: "22px" }}>
                {t("Step: Payment")}
            </div>

            <div className="mb-2" style={{ height: "100%" }}>
                <div style={{ width: "576px" }}>
                    <Nav tabs className="bg-white">
                        {tabs.map((element, index) => {
                            return (
                                <NavItem
                                    key={index}
                                    className={classNames({
                                        "advanced-nav-item-active__clz":
                                            activeTab === index,
                                    })}
                                    style={{ border: "1px solid #E5E5E5" }}
                                >
                                    <NavLink
                                        className={classNames(
                                            "border-0 sales-methods-nav-link__clz",
                                            {
                                                "active nav-tabs-active-link__clz":
                                                    activeTab === index,
                                            }
                                        )}
                                        onClick={() => {
                                            toggleNav(index);
                                        }}
                                    >
                                        <span className="px-2 cursor__clz">
                                            {element.title}
                                        </span>
                                    </NavLink>
                                </NavItem>
                            );
                        })}
                    </Nav>
                </div>
                <TabContent
                    activeTab={activeTab}
                    style={{
                        width: "100%",
                        height: "93%",
                    }}
                    className="bg-white border-0 rounded"
                >
                    {tabs.map((element, index) => {
                        return (
                            <TabPane
                                tabId={index}
                                style={{ height: "100%", width: "100%" }}
                                key={index}
                            >
                                {element.content}
                            </TabPane>
                        );
                    })}
                </TabContent>
            </div>
        </div>
    );
}
