import { StyledH1 } from "@aureskonnect/react-ui";
import CustomReactTable from "@components/Common/CustomReactTable";
import { ColumnDef } from "@tanstack/react-table";
import classnames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import {
    BsFillArrowRightCircleFill,
    BsFillArrowLeftCircleFill,
} from "react-icons/bs";

import { setCategoryAssignment } from "../../store";

export default function CategoryAssignment({ idStore, rowData }: any) {
    const { t } = useTranslation();
    const [data, setData] = React.useState<any>([]);

    const [newCategoryAssignment, setNewCategoryAssignment] = React.useState<
        any
    >(
        Object.values(rowData.template).length === 0
            ? []
            : rowData.template.categoryAssignment.newCategoryAssignment
    );
    const schemaFranchise = localStorage.getItem("schemaFranchise");
    const [globalFilter, setGlobalFilter] = React.useState<string>("");
    const [rowSelection, setRowSelection] = React.useState<
        Record<string, boolean>
    >({});
    const [rowSelection1, setRowSelection1] = React.useState<
        Record<string, boolean>
    >({});

    const Columns: ColumnDef<any>[] = React.useMemo(() => {
        return [
            {
                accessorKey: "title",
                header: () => <span>{t("Category designation")}</span>,
            },
        ];
    }, [t]);
    const dataFetch = React.useCallback(async () => {
        let listCategory: any = [];
        const data: any = await (
            await fetch(
                `https://dev-catalogue-api.aureskonnect.com/api_etk_article_bd/v1/cards/${schemaFranchise}/categories/${idStore}`
            )
        ).json();

        data.forEach((element: any) => {
            listCategory.push(element.category);
        });
        setData(listCategory);
    }, [idStore, schemaFranchise]);

    React.useEffect(() => {
        dataFetch();
    }, [dataFetch]);

    React.useEffect(() => {
        setCategoryAssignment(
            JSON.parse(
                JSON.stringify({
                    newCategoryAssignment: newCategoryAssignment,
                })
            )
        );
        // eslint-disable-next-line
    }, [newCategoryAssignment]);

    return (
        <React.Fragment>
            <StyledH1 className="m-3">{t("Category assignment")}</StyledH1>
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "3fr 1fr 3fr",
                }}
            >
                <div
                    style={{
                        overflowY: "scroll",
                        marginLeft: "2%",
                        height: "58%",
                        width: "90%",
                    }}
                >
                    <CustomReactTable
                        tableColumns={Columns}
                        data={data}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        customRowSelection={rowSelection}
                        setRowSelection={setRowSelection}
                        isRowSelectionEnabled
                    />
                </div>
                <div
                    className="d-flex flex-column"
                    style={{ marginTop: "70%", gap: "2%" }}
                >
                    <BsFillArrowRightCircleFill
                        style={{
                            height: "45px",
                            width: "100px",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            let newData = [
                                ...newCategoryAssignment,
                                data.filter((elt: any) =>
                                    Object.keys(rowSelection).includes(
                                        data.indexOf(elt).toString()
                                    )
                                ),
                            ];

                            setNewCategoryAssignment(newData.flat());
                        }}
                        className={`${classnames({
                            "not-allowed-icon__clz":
                                Object.values(rowSelection).length === 0,
                        })}`}
                    />
                    <BsFillArrowLeftCircleFill
                        style={{
                            height: "45px",
                            width: "100px",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            let newData: any = structuredClone(
                                newCategoryAssignment
                            );

                            Object.keys(rowSelection1).forEach((elt: any) => {
                                newData.splice(Number(elt), 1);
                            });
                            setNewCategoryAssignment(newData);
                            setRowSelection1({});
                        }}
                        className={`${classnames({
                            "not-allowed-icon__clz":
                                Object.values(rowSelection1).length === 0,
                        })}`}
                    />
                </div>
                <div
                    style={{
                        overflowY: "scroll",
                        marginLeft: "2%",
                        height: "58%",
                        width: "90%",
                    }}
                >
                    <CustomReactTable
                        tableColumns={Columns}
                        data={newCategoryAssignment}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        customRowSelection={rowSelection1}
                        setRowSelection={setRowSelection1}
                        isRowSelectionEnabled
                    />
                </div>
            </div>
        </React.Fragment>
    );
}
