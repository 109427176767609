import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    CheckboxIcon,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { mutate } from "swr";

import { setIsDeletePeripheralModalOpened } from "@pages/Applications/store";
import { setIsClicked } from "@pages/Applications/DeviceAssociateModal/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationDeleteMessageType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    apiUrl: string;
    setDataIsUpdated?: Function;
    data?: any;
    message: string;
    text: any;

    setGlobalDataIsUpdated?: Function;
};

export function ConfirmationDeleteMessage({
    isModalOpened,
    setIsModalOpened,
    setDataIsUpdated,
    apiUrl,
    data,
    message,
    text,
    setGlobalDataIsUpdated,
}: ConfirmationDeleteMessageType) {
    const { t } = useTranslation();

    async function handleRemoveButtonOnClickEvent() {
        toast.dismiss();
        if (setGlobalDataIsUpdated !== undefined) setGlobalDataIsUpdated!(true);
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${localStorage.getItem("jwt")}`,
                    },
                    method: "DELETE",
                    body: JSON.stringify(data),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while delete selection!");
                        }

                        toast.success(message, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        });
                        setDataIsUpdated!(true);
                        if (setGlobalDataIsUpdated !== undefined)
                            setGlobalDataIsUpdated!(true);
                        setIsDeletePeripheralModalOpened(false);
                        setIsClicked(false);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                }}
            >
                <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">{text}</StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    className="afa_btn_cancelAlert"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2 afa_btn_confirmAlert"
                    variant="primary"
                    onClick={() => {
                        handleRemoveButtonOnClickEvent();
                        setIsModalOpened(!isModalOpened);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
