import React from "react";
import { useTranslation } from "react-i18next";
import { StyledH1 } from "@aureskonnect/react-ui";

import { setLanguage } from "../../store";
import { CardWrapperSpecific } from "../../CardWrapperSpecific";

export default function Language({ rowData }: any) {
    const { t } = useTranslation();

    const [items, setItems] = React.useState<any>(
        Object.values(rowData.template).length === 0
            ? [
                  {
                      name: t("French"),
                      active: true,
                      image: "https://flagpedia.net/data/flags/h80/fr.webp",
                  },
                  {
                      name: t("English"),
                      active: false,
                      image: "https://flagpedia.net/data/flags/h80/gb.webp",
                  },
              ]
            : rowData.template.language.items
    );
    React.useEffect(() => {
        setLanguage(
            JSON.parse(
                JSON.stringify({
                    items: items,
                })
            )
        );
        // eslint-disable-next-line
    }, [items]);

    return (
        <React.Fragment>
            <StyledH1 className="m-3">{t("Language")}</StyledH1>
            <div
                className="d-flex flex-row justify-content-center align-items-center mt-5"
                style={{ gap: "100px" }}
            >
                {items.map((item: any, index: number) => (
                    <CardWrapperSpecific
                        items={items}
                        index={index}
                        setItems={setItems}
                    />
                ))}
            </div>
        </React.Fragment>
    );
}
