import React from "react";
import { useTranslation } from "react-i18next";
import { StyledLabel, StyledTextInput } from "@aureskonnect/react-ui";
import { AvGroup } from "availity-reactstrap-validation";
import classnames from "classnames";
import { Input } from "antd";

import HidePasswordSvgIcon from "@components/Common/SvgIcons/HidePasswordSvgIcon";
import ShowPasswordSvgIcon from "@components/Common/SvgIcons/ShowPasswordSvgIcon";

import "./index.css";

type FidelityZerosixType = {
    password: string;
    setPassword: Function;
    user: string;
    setUser: Function;
    inputUser: boolean;
    setInputUser: Function;
    inputPassword: boolean;
    setInputPassword: Function;
    setIsEdited: Function;
    setDashboardLink: Function;
    dashboardLink: string;
    apiKey: string;
    setApiKey: Function;
    inputApiKey: boolean;
    inputDashboardLink: boolean;
    setInputApiKey: Function;
    setInputDashboardLink: Function
};
export default function FidelityZerosix({
    password,
    setPassword,
    user,
    setUser,
    inputUser,
    setInputUser,
    inputPassword,
    setInputPassword,
    setIsEdited,
    setDashboardLink,
    dashboardLink,
    apiKey,
    setApiKey,
    inputDashboardLink,
    inputApiKey,
    setInputApiKey,
    setInputDashboardLink,
}: FidelityZerosixType) {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <AvGroup>
                <StyledLabel className="required__clz mt-3" htmlFor="name">
                    {t("User")}
                </StyledLabel>
                <StyledTextInput
                    autocomplete="off"
                    id="User"
                    name="User"
                    placeholder={t("Enter")}
                    type="text"
                    onChange={(e: any) => {
                        
                            setUser(e.target.value);
                            setInputUser(false);
                            setIsEdited(true);
                        
                    }}
                    className={classnames({
                        input__clz: inputUser,
                        
                    })}
                    value={user}
                />
                {inputUser ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please enter a link to the dashboard")}
                    </div>
                ) : null}
            </AvGroup>
            <AvGroup>
                <StyledLabel className="required__clz mt-3" htmlFor="name">
                    {t("Password")}
                </StyledLabel>
                <Input.Password
                    placeholder={t("Enter")}
                    iconRender={(visible) =>
                        visible ? (
                            <ShowPasswordSvgIcon />
                        ) : (
                            <HidePasswordSvgIcon />
                        )
                    }
                    onChange={(e: any) => {
                        
                            setPassword(e.target.value);
                            setInputPassword(false);
                            setIsEdited(true);
                        
                    }}
                    value={password}
                    className={classnames({
                        input__clz: inputPassword,
                        
                    })}
                    style={{
                        height: "38px",
                        borderRadius: "6px",
                        color: "#2b2828",
                        fontSize: "16px",
                        fontFamily: "Segoe UI",
                    }}
                />
                {inputPassword ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please enter an password")}
                    </div>
                ) : null}
            </AvGroup>
            <AvGroup>
                <StyledLabel className="required__clz mt-3" htmlFor="name">
                    {t("Dashboard Link")}
                </StyledLabel>
                <StyledTextInput
                    autocomplete="off"
                    id="Dashboard Link"
                    name="Dashboard Link"
                    placeholder={t("Enter")}
                    type="text"
                    onChange={(e: any) => {
                       
                            setDashboardLink(e.target.value);
                            setInputDashboardLink(false);
                            setIsEdited(true);
                        
                    }}
                    className={classnames({
                        input__clz: inputDashboardLink,
                        
                    })}
                    value={dashboardLink}
                />
                {inputDashboardLink ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please enter a link to the dashboard")}
                    </div>
                ) : null}
            </AvGroup>
            <AvGroup>
                <StyledLabel className="required__clz mt-3" htmlFor="name">
                    {t("API key")}
                </StyledLabel>
                <StyledTextInput
                    autocomplete="off"
                    id="API key"
                    name="API key"
                    placeholder={t("Enter")}
                    type="text"
                    onChange={(e: any) => {
                      
                            setApiKey(e.target.value);
                            setInputApiKey(false);
                            setIsEdited(true);
                        
                    }}
                    className={classnames({
                        input__clz: inputApiKey,
                        
                    })}
                    value={apiKey}
                />
                {inputApiKey ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please enter an API Key")}
                    </div>
                ) : null}
            </AvGroup>
        </React.Fragment>
    );
}
