import React from "react";
import { motion } from "framer-motion/dist/framer-motion";
import { useTranslation } from "react-i18next/";
import PageTitle from "@components/Common/PageTitle";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import { CARD_MENU_LIST } from "./CardMenuList";
import CardMenu from "@helpers/CardMenus";


export function Kds() {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <PageTitle title={"Applications"} />
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1 }}
                className="page-content__clz"
            >
                <StyledBreadcrumb
                    items={[
                        {
                            item: "ETK Settings".toUpperCase(),
                            link: "https://dev-portail.aureskonnect.com",
                        },
                        {
                            item:
                                localStorage
                                    .getItem("selectedAccount")
                                    ?.toUpperCase() || "",
                            link: "/compte",
                        },
                        {
                            item: t("Application").toUpperCase(),
                            link: "applications",
                        },
                        {
                            item: t("KDS").toUpperCase(),
                            link: "#",
                        },
                    ]}
                />
                <div className="row p-4">
                    {CARD_MENU_LIST.map((cardMenu: any, index: any) => {
                        return (
                            <CardMenu
                                key={index}
                                title={t(cardMenu.title).toUpperCase()}
                                number={cardMenu.number}
                                Icon={cardMenu.Icon}
                                description={t(cardMenu.description)}
                                path={cardMenu.path}
                                numberKiosk="0"
                            />
                        );
                    })}
                </div>
            </motion.div>
        </React.Fragment>
    );
}
