import React from "react";
import { Nav, NavItem, TabContent, TabPane, NavLink } from "reactstrap";
import { t } from "i18next";

import { setActiveAdvertisingScreenTab } from "@store";

import { StandbyStateScreen } from "./StandbyStateScreen";
import { ActiveStateScreen } from "./ActiveStateScreen";

export function General(): JSX.Element {
    const tabs: TabsType[] = [
        {
            title: t("Active state"),
            content: <ActiveStateScreen />,
        },
        {
            title: t("Standby state"),
            content: <StandbyStateScreen />,
        },
    ];
    const [activeTab, setActiveTab] = React.useState<number>(0);

    function toggle(tab: number): void {
        if (activeTab !== tab) {
            setActiveTab(tab);
            setActiveAdvertisingScreenTab(tab);
        }
    }

    return (
        <div
            className="d-flex flex-column"
            style={{ height: "100%", gap: "10px" }}
        >
            <Nav
                tabs
                style={{
                    backgroundColor: "white",
                    width: "25%",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gridTemplateRows: "1fr",
                    border: "unset",
                    gap: "10px",
                }}
            >
                {tabs.map((element, index) => {
                    return (
                        <NavItem
                            key={index}
                            className="d-flex align-items-center text-nowrap"
                            style={{
                                cursor: "pointer",
                                background:
                                    activeTab === index ? "#323333" : "#E9E9E9",
                                borderRadius: "5px",
                                border:
                                    activeTab === index
                                        ? "unset"
                                        : "1px solid rgb(233, 233, 233)",
                            }}
                        >
                            <NavLink
                                className="w-100 nav-link-hover__clz"
                                onClick={() => {
                                    toggle(index);
                                }}
                                style={{
                                    color:
                                        activeTab === index
                                            ? "#FFFFFF"
                                            : "#000000",
                                }}
                            >
                                <span className="px-2">{element.title}</span>
                            </NavLink>
                        </NavItem>
                    );
                })}
            </Nav>

            <TabContent
                activeTab={activeTab}
                style={{
                    width: "100%",
                    height: "93%",
                }}
                className="bg-white border-0 rounded"
            >
                {tabs.map((element, index) => {
                    return (
                        <TabPane
                            tabId={index}
                            style={{ height: "100%", width: "100%" }}
                            key={index}
                        >
                            {element.content}
                        </TabPane>
                    );
                })}
            </TabContent>
        </div>
    );
}
