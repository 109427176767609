import React from "react";
import { t } from "i18next";
import { AvForm } from "availity-reactstrap-validation";
import { StyledTextInput } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import ReactSwitch from "react-switch";

import {
    generalConfigStore,
    setInitialStepTimeout,
    setTimeOutOptions,
    setKeyboard,
    setIsAutoLaunchActivated,
} from "@store";

import { CustomErrorBoundary } from "@components/CustomErrorBoundary";

export function OtherTabs(): JSX.Element {
    const {
        project: {
            template: { timeout, initialStepTimeout },
        },
    } = useSnapshot(generalConfigStore);

    const [isKeyboardChecked, setIsKeyboardChecked] = React.useState<boolean>(
        false
    );
    const [isAutoLaunchChecked, setIsAutoLaunchChecked] = React.useState<
        boolean
    >(true);
    return (
        <CustomErrorBoundary>
            <div
                className="d-flex flex-column p-3 cursor__clz"
                style={{ gap: "30px" }}
            >
                <div className="d-flex align-items-center">
                    <span
                        style={{
                            font: "normal normal  Nunito Sans",
                            fontSize: "30px",
                            marginLeft: "25px",
                        }}
                    >
                        {t("Automatic Order Abandonment Alert")}
                    </span>
                </div>
                <div
                    className="d-flex flex-column justify-content-start ml-5"
                    style={{ gap: "30px" }}
                >
                    <div
                        className="d-flex flex-column p-2"
                        style={{ gap: "30px" }}
                    >
                        <div
                            style={{
                                fontSize: "25px",
                            }}
                        >
                            {t("Auto abort popup display timeout")}:
                        </div>

                        <div
                            className="d-flex flex-row align-item-center"
                            style={{ gap: "30px" }}
                        >
                            <AvForm
                                style={{
                                    width: "80px",
                                    height: "30px",
                                }}
                            >
                                <StyledTextInput
                                    id="input-timeout-show-popup"
                                    name="input-timeout-show-popup"
                                    placeholder={t("Put")}
                                    type="text"
                                    value={timeout}
                                    onChange={(
                                        event: React.ChangeEvent<
                                            HTMLInputElement
                                        >
                                    ) =>
                                        setTimeOutOptions(
                                            Number(event.target.value)
                                        )
                                    }
                                />
                            </AvForm>
                            <span
                                className="d-flex align-items-end"
                                style={{
                                    fontSize: "20px",
                                }}
                            >
                                {t("Second(s)")}
                            </span>
                        </div>
                    </div>
                    <div
                        className="d-flex flex-column p-2"
                        style={{ gap: "30px" }}
                    >
                        <div
                            style={{
                                fontSize: "25px",
                            }}
                        >
                            {t(
                                "Waiting time for the return to the initial stage"
                            )}
                            :
                        </div>
                        <div
                            className="d-flex flex-row"
                            style={{ gap: "30px" }}
                        >
                            <AvForm
                                style={{
                                    width: "80px",
                                    height: "30px",
                                }}
                            >
                                <StyledTextInput
                                    id="waiting-timeout"
                                    name="waiting-timeout"
                                    placeholder="10"
                                    type="text"
                                    value={initialStepTimeout}
                                    defaultValue={initialStepTimeout}
                                    onChange={(
                                        event: React.ChangeEvent<
                                            HTMLInputElement
                                        >
                                    ) => {
                                        setInitialStepTimeout(
                                            Number(event.target.value)
                                        );
                                    }}
                                />
                            </AvForm>
                            <span
                                className="d-flex align-items-end"
                                style={{
                                    fontSize: "20px",
                                }}
                            >
                                {t("Second(s)")}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="d-flex align-items-center">
                    <span
                        style={{
                            font: "normal normal  Nunito Sans",
                            fontSize: "30px",
                            marginLeft: "25px",
                        }}
                    >
                        {t("Typing keyboard")}
                    </span>
                </div>
                <div
                    className="d-flex justify-content-start ml-5"
                    style={{ gap: "30px" }}
                >
                    <div
                        className="d-flex pl-2"
                        style={{
                            fontSize: "20px",
                        }}
                    >
                        AZERTY
                    </div>
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#d6d6d6"
                        offHandleColor="#34C38F"
                        onChange={() => {
                            setIsKeyboardChecked(!isKeyboardChecked);
                            setKeyboard(
                                isKeyboardChecked === true ? "azerty" : "qwerty"
                            );
                        }}
                        checked={isKeyboardChecked}
                        onColor="#d6d6d6"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                    <div
                        style={{
                            fontSize: "20px",
                        }}
                    >
                        QWERTY
                    </div>
                </div>
                <div
                    className="d-flex justify-content-start  align-items-center"
                    style={{ gap: "30px" }}
                >
                    <div
                        className="d-flex pl-2"
                        style={{
                            font: "normal normal  Nunito Sans",
                            fontSize: "30px",
                            marginLeft: "25px",
                        }}
                    >
                        {t("Auto Launch")}
                    </div>
                    <ReactSwitch
                        uncheckedIcon={false}
                        disabled={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#d6d6d6"
                        offHandleColor="#E30613"
                        onChange={() => {
                            setIsAutoLaunchActivated(!isAutoLaunchChecked);
                            setIsAutoLaunchChecked(!isAutoLaunchChecked);
                        }}
                        checked={isAutoLaunchChecked}
                        onColor="#d6d6d6"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
            </div>
        </CustomErrorBoundary>
    );
}
