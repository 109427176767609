import React from "react";
import classnames from "classnames";
import {
    GridIcon,
    PlusIcon,
    StyledButtonWithIcon,
    StyledIconButton,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";

import { store as useStore } from "@components/VerticalLayout/store";
import { store, setIsPlusIconClicked } from "./store";

import ErrorToast from "@components/Common/ErrorTost";

export function CustomSideFilter({
    selectedRows,
    setDataIsUpdated,
    setLocalFilterActive,
    isAddApplication,
    setIsAddApplication,
    isApplicationArchived,
    setIsApplicationArchived,
    setIsInInitializationModalOpened,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    setIsConfirmationModalArchived,
    setIsConfirmationModalActive,
    setSelectedRows,
}: any) {
    const { t } = useTranslation();
    const { oneShop } = useSnapshot(useStore);
    const { numberArchived, numberActive, isEdited } = useSnapshot(store);

    const isExist = React.useCallback(() => {
        let isExist: boolean = false;
        let existShops: any[] = [];
        selectedRows !== undefined &&
            Object.values(selectedRows).length !== 0 &&
            Object.values(selectedRows).forEach((row: any) => {
                if (row[t("Shop")] !== "") {
                    existShops.push(row[t("Shop")]);
                }
            });
        if (existShops.length > 1 || existShops.length === 0) {
            isExist = true;
        }
        return isExist;
    }, [selectedRows, t]);

    let isExistShops = isExist();
    const isExistDevice = React.useCallback(() => {
        let isExist: boolean = false;
        let existDevice: any[] = [];
        selectedRows !== undefined &&
            Object.values(selectedRows).length !== 0 &&
            Object.values(selectedRows).forEach((row: any) => {
                if (row[t("Shop")] === "") {
                    if (row.subRows !== undefined && row.subRows.length > 0) {
                        existDevice.push(row);
                    }
                }
            });
        if (existDevice.length > 0) {
            isExist = true;
        }
        return isExist;
    }, [selectedRows, t]);
    let IsExistPeripheral: boolean = isExistDevice();
    function isDisable() {
        let isExist: boolean = false;
        selectedRows !== undefined &&
            Object.values(selectedRows).length !== 0 &&
            Object.values(selectedRows).forEach((row: any) => {
                if (row[t("Application")] !== "") {
                    isExist = true;
                }
            });

        return isExist;
    }
    return (
        <div className="d-flex justify-content-start align-items-center ml-2">
            <div>
                <StyledIconButton
                    style={{
                        backgroundColor: "#323333",

                        borderRadius: "7px",
                    }}
                    className={` ${classnames("not-allowed-icon__clz", {
                        // "not-allowed-icon__clz":
                        //     typeDisplay === "archived",
                    })}`}
                    icon="GridIcon"
                    onClick={() => {
                        // if (isEdited === true) {
                        //     setIsActionsClicked(!isActionsClicked);
                        //     setActions(() => {
                        //         setIsDisplayModeChanged(
                        //             !isDisplayModeChanged
                        //         );
                        //         setIsAddAppClicked(false);
                        //     });
                        // } else {
                        //     setIsDisplayModeChanged(!isDisplayModeChanged);
                        //     setIsAddAppClicked(false);
                        // }
                    }}
                >
                    <GridIcon height={25} width={25} fill={"white"} />
                </StyledIconButton>
            </div>
            {isAddApplication !== true ? (
                <span
                    onClick={() => {
                        setIsAddApplication(!isAddApplication);
                        oneShop && setSelectedRows([]);
                        setLocalFilterActive(false);
                    }}
                    className={
                        isApplicationArchived || (!oneShop && isExistShops)
                            ? "w-100 ml-2 not-allowed-icon__clz"
                            : "w-100 ml-2"
                    }
                >
                    <StyledButtonWithIcon
                        icon="PlusIcon"
                        iconPosition="left"
                        rounded
                        variant="primary"
                    >
                        {t("Add an application")}
                    </StyledButtonWithIcon>
                </span>
            ) : (
                <StyledIconButton
                    icon="PlusIcon"
                    className="bg-dark"
                    rounded
                    disabled={selectedRows.length === 0 ? false : true}
                    onClick={() => {
                        if (isEdited === true)
                            setIsInInitializationModalOpened(true);
                        else {
                            setIsPlusIconClicked(true);
                        }
                    }}
                >
                    <PlusIcon height={20} width={20} fill="white" />
                </StyledIconButton>
            )}

            <VerticalDotsIcon
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={25}
                fill="black"
                className="cmn_drp_FRmenu"
            />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames("dropdown-item pl-1")}
                    onClick={() => {
                        setIsApplicationArchived(false);
                        setDataIsUpdated(true);
                    }}
                >
                    {t("Activated(s) application(s)")} ({numberActive})
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames("dropdown-item pl-1")}
                    onClick={() => {
                        setIsApplicationArchived(true);
                        setDataIsUpdated(true);
                    }}
                >
                    {t("Archived(s) application(s)")} ({numberArchived})
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={`${classnames("dropdown-item pl-1", {
                        "not-allowed-icon__clz":
                            selectedRows.length === 0 || !isDisable(),
                    })}`}
                    onClick={() => {
                        setIsHistoryModalOpened(!isHistoryModalOpened);
                    }}
                >
                    {t("Historical")}
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={`${classnames("dropdown-item pl-1", {
                        "not-allowed-icon__clz":
                            selectedRows.length === 0 || !isDisable(),
                    })}`}
                    onClick={() => {
                        if (isApplicationArchived) {
                            setIsConfirmationModalActive(true);
                        } else {
                            if (IsExistPeripheral) {
                                ErrorToast(
                                    t(
                                        "Please note that you cannot archive a application associated with an device."
                                    )
                                );
                            } else {
                                setIsConfirmationModalArchived(true);
                            }
                        }
                    }}
                >
                    {isApplicationArchived
                        ? `${t("Dearchive")}`
                        : `${t("Archive")}`}
                </div>
            </div>
        </div>
    );
}
