import React from "react";
import { useSnapshot } from "valtio";
import SimpleBar from "simplebar-react";

import { designStore, generalConfigStore } from "@store";

import { ImageContent } from "@components/Common/Design/Preview/ImageContent";
import { LoyaltyConsommationCard } from "@components/Common/Design/Preview/LoyaltyWrapper/LoyaltyConsommationCard";

export function MainContentWrapper(): JSX.Element {
    const {
        mainContentItems,
        isInformationModesPersonalized,
        selectedInformationModes,
        activePageName,
        selectedLanguage,
    } = useSnapshot(designStore);

    const { selectedSaleModeValue } = useSnapshot(generalConfigStore);

    const activeMainContentItems =
        activePageName === "informationModes"
            ? isInformationModesPersonalized === true
                ? mainContentItems.filter((item) => {
                      return (
                          item.id ===
                          (selectedInformationModes as ProjectMainContentItemType)
                              .id
                      );
                  })
                : mainContentItems.filter((item) => {
                      return (
                          (item.active as ItemContentType)[
                              selectedSaleModeValue
                          ] === true
                      );
                  })
            : mainContentItems.filter((item) => {
                  return (
                      item.active === true &&
                      item.languages[selectedLanguage].content !== ""
                  );
              });

    const activeMainContentItemsLength = activeMainContentItems.length;

    return (
        <React.Fragment>
            {activeMainContentItemsLength <= 2 ? (
                <div
                    className="d-flex flex-column justify-content-center align-items-center p-2"
                    style={{
                        gap: "50px",
                        width: "450px",
                    }}
                >
                    <div className="mb-5" style={{}}>
                        {activePageName === "loyaltyConsommation" ? (
                            <LoyaltyConsommationCard />
                        ) : null}
                    </div>

                    <div
                        className="d-flex justify-content-center align-items-center p-2"
                        style={{
                            gap: "20px",
                            width: "450px",
                        }}
                    >
                        {activeMainContentItems.map(
                            (
                                item: ProjectMainContentItemType,
                                index: number
                            ) => (
                                <ImageContent
                                    item={item}
                                    key={index}
                                    activeMainContentItemsLength={
                                        activeMainContentItemsLength
                                    }
                                />
                            )
                        )}
                    </div>
                </div>
            ) : activeMainContentItemsLength > 2 &&
              activeMainContentItemsLength <= 4 ? (
                <div>
                    <div
                        className="d-flex justify-content-center p-2"
                        style={{
                            gap: "20px",
                            height: "155px",
                            width: "427px",
                        }}
                    >
                        {activeMainContentItems
                            .slice(0, 2)
                            .map(
                                (
                                    item: ProjectMainContentItemType,
                                    index: number
                                ) => (
                                    <ImageContent
                                        item={item}
                                        activeMainContentItemsLength={
                                            activeMainContentItemsLength
                                        }
                                        key={index}
                                    />
                                )
                            )}
                    </div>
                    <div
                        className="d-flex justify-content-center p-2"
                        style={{
                            gap: "20px",
                            height: "155px",
                            width: "427px",
                        }}
                    >
                        {activeMainContentItems
                            .slice(2)
                            .map(
                                (
                                    item: ProjectMainContentItemType,
                                    index: number
                                ) => (
                                    <ImageContent
                                        item={item}
                                        activeMainContentItemsLength={
                                            activeMainContentItemsLength
                                        }
                                        key={index}
                                    />
                                )
                            )}
                    </div>
                </div>
            ) : activeMainContentItemsLength > 4 &&
              activeMainContentItemsLength <= 6 ? (
                <div style={{ width: "425px" }}>
                    <div
                        className="d-flex p-2 justify-content-center"
                        style={{ gap: "10px" }}
                    >
                        {activeMainContentItems
                            .slice(0, 3)
                            .map(
                                (
                                    item: ProjectMainContentItemType,
                                    index: number
                                ) => (
                                    <ImageContent
                                        item={item}
                                        activeMainContentItemsLength={
                                            activeMainContentItemsLength
                                        }
                                        key={index}
                                    />
                                )
                            )}
                    </div>
                    <div
                        className="d-flex p-2 justify-content-center"
                        style={{ gap: "10px" }}
                    >
                        {activeMainContentItems
                            .slice(3)
                            .map(
                                (
                                    item: ProjectMainContentItemType,
                                    index: number
                                ) => (
                                    <ImageContent
                                        item={item}
                                        activeMainContentItemsLength={
                                            activeMainContentItemsLength
                                        }
                                        key={index}
                                    />
                                )
                            )}
                    </div>
                </div>
            ) : (
                <SimpleBar
                    autoHide={true}
                    style={{ maxHeight: 315, width: "100%" }}
                >
                    <div
                        className="d-grid__clz"
                        style={{
                            gridTemplateColumns: "repeat(3,1fr)",
                            gridTemplateRows: `repeat(${Math.ceil(
                                activeMainContentItems.length / 3
                            )},1fr)`,
                            rowGap: "20px",
                            columnGap: "20px",
                            padding: "25px 13px 35px",
                        }}
                    >
                        {activeMainContentItems.map(
                            (item: ProjectMainContentItemType) => (
                                <ImageContent
                                    item={item}
                                    activeMainContentItemsLength={
                                        activeMainContentItemsLength
                                    }
                                    key={item.id}
                                />
                            )
                        )}
                    </div>
                </SimpleBar>
            )}
        </React.Fragment>
    );
}
