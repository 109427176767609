import React from "react";
import { useSnapshot } from "valtio";

import { generalConfigStore } from "@store";

import { getTopBannerImagePath } from "@helpers/general";

export function TopBannerWrapper(): JSX.Element {
    const {
        project: { files: images },
    } = useSnapshot(generalConfigStore);

    const topBannerBackgroundImageSrc = getTopBannerImagePath(
        "orderTaking",
        images
    );
    const possibleExtensions = ["mp4", "mov", "avi", "flv", "wmv"];

    const extension = topBannerBackgroundImageSrc.split(".")[
        topBannerBackgroundImageSrc.split(".").length - 1
    ];

    return (
        <React.Fragment>
            {possibleExtensions.includes(extension) === true ? (
                <video
                    autoPlay={true}
                    muted={true}
                    loop={true}
                    style={{
                        height: "80px",
                        width: "430px",
                        objectFit: "cover",
                        borderRadius: "2px",
                        position: "fixed",
                        zIndex: 0,
                    }}
                    src={topBannerBackgroundImageSrc}
                />
            ) : (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                        backgroundImage: `url(${topBannerBackgroundImageSrc})`,
                        width: "430px",
                        backgroundSize: "cover",
                        height: "80px",
                        borderRadius: "2px",
                    }}
                />
            )}
        </React.Fragment>
    );
}
