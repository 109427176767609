import React from "react";
import {
    PencilIcon,
    SettingsIcon,
    DisassociateIcon,
    ShieldExclamationIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { Tooltip, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import {
    setProjectId,
    store as userStore,
} from "@components/VerticalLayout/store";
import { store } from "../../store/project/index";
import { setIsKioskSetting } from "../GeneralConfig/store";
import {
    store as appStore,
    setChosenTemplate,
    setDefaultLanguage,
    setIsConfigActive,
    setIsCustomerAccountActive,
    setIsModalOpened,
    setItems,
    setProject,
    setSelectedLanguage,
    setSelectedSaleModeValue,
    projectStore,
    setInitItemsFromGeneralSetting,
    setIsActionsClicked,
    setIsEdited,
    setActions,
    setConfigOptionsCustomerAccount,
    setIsStateChanged,
} from "@store";
import {
    setIsModesModalDisassociate,
    setSelectedProject,
} from "../../../src/store/project";

import { FlexboxGrid } from "@components/Common/FlexboxGrid";
import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

export function CustomIcons({
    e,
    setRowData,
    setIsCreatedProject,
    isCreatedProject,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
}: any) {
    const { t } = useTranslation();
    const { projectData, dataProjectFranchise, modify } = useSnapshot(store);

    const { isActionsClicked, isEdited } = useSnapshot(appStore);
    const { isArchivedTemplateClicked, isModesModalDisassociate } = useSnapshot(
        projectStore
    );
    const { oneShop, shopID } = useSnapshot(userStore);

    const [isAlertModalOpened, setIsAlertModalOpened] = React.useState<boolean>(
        false
    );

    function alertToggle() {
        setIsAlertModalOpened(!isAlertModalOpened);
    }

    const getProject = () => {
        let project: any;
        if (oneShop) {
            project = dataProjectFranchise.filter((element) => {
                return element.projectId === e?.selectedRow?.id_project;
            });
        } else {
            project = projectData.filter((element) => {
                return element.projectId === e?.selectedRow?.id_project;
            });
        }
        return project[0];
    };

    const handleSettingIconOnClickEvent = () => {
        let template =
            e.selectedRow.template !== ""
                ? e.selectedRow.template
                : e.selectedRow.shop_template !== ""
                ? e.selectedRow.shop_template
                : e.selectedRow.project_template;

        if (
            (template.content
                .salesMethods as ProjectContentItemType).items.filter(
                (item: any) => item.active === true
            ).length === 0
        ) {
            setIsKioskSetting(false);
            setIsModalOpened(false);
            setIsAlertModalOpened(!isAlertModalOpened);
        } else {
            setProjectId(e?.selectedRow?.id_project);
            setProject(
                e?.selectedRow?.template !== ""
                    ? e.selectedRow.template
                    : getProject()
            );
            setRowData(e?.selectedRow);
            setIsKioskSetting(true);
            setIsModalOpened(true);
            setIsConfigActive(true);
            let hoursOfUse =
                e.selectedRow.hoursOfUse !== ""
                    ? e.selectedRow.hoursOfUse
                    : e.selectedRow.shop_hoursOfUse;
            localStorage.setItem("hoursOfUse", JSON.stringify(hoursOfUse));

            let files =
                e.selectedRow.files !== ""
                    ? e.selectedRow.files
                    : e.selectedRow.shop_files !== ""
                    ? e.selectedRow.shop_files
                    : e.selectedRow.project_files;
            setChosenTemplate(template, files);
            setDefaultLanguage(
                (template.content.languages as LanguagesType).items.filter(
                    (language) => language.isDefault === true
                )[0]
            );
            setSelectedLanguage(
                (template.content.languages as LanguagesType).items.filter(
                    (language) => language.isDefault === true
                )[0].name
            );
            const selectedSaleMethodDeliveryExist = (template.content
                .salesMethods as ProjectContentItemType).items.filter(
                (sale: any) => sale.name === "Delivery"
            )[0];
            if (
                selectedSaleMethodDeliveryExist !== undefined &&
                selectedSaleMethodDeliveryExist.active === true
            ) {
                setIsCustomerAccountActive(true);

                setIsStateChanged(true);
                setConfigOptionsCustomerAccount(true);
            } else {
                setIsCustomerAccountActive(
                    (template.content.options as OptionsType).items.filter(
                        (item) => item.title === "Customer account"
                    )[0].active
                );
            }
            setSelectedSaleModeValue(
                (template.content
                    .salesMethods as ProjectContentItemType).items.filter(
                    (saleMethod) => {
                        return saleMethod.name !== "Retrait C&C";
                    }
                )[0].name
            );

            setInitItemsFromGeneralSetting({
                informationModes: template.content.informationModes.items,
                saleMethods: template.content.salesMethods.items,
                reglements: template.content.meansOfPayment.items,
                languages: template.content.languages.items,
                logo: template.content.generalDesign.logo,
            });
            setItems(template.pages);
        }
    };

    return (
        <React.Fragment>
            <Modal
                isOpen={isAlertModalOpened}
                toggle={alertToggle}
                backdrop={false}
                fade={false}
                centered
            >
                <ModalHeader toggle={alertToggle}>
                    <span
                        style={{
                            textAlign: "left",
                            font: " normal normal 600 25px Segoe UI",
                            color: "#000000",
                        }}
                    >
                        {" "}
                        {t("Alert")}
                    </span>
                </ModalHeader>
                <ModalBody
                    className="d-flex flex-column"
                    style={{ gap: "10px" }}
                >
                    <div
                        style={{
                            textAlign: "center",
                            font: " normal normal 600 16px/10px Segoe UI",
                            color: "#2B2828",
                        }}
                    >
                        {t("Warning! Missing setting.")}
                    </div>
                    <div
                        style={{
                            font: " normal normal 600 16px/20px Segoe UI",
                            textAlign: "left",
                            color: "#2B2828",
                            padding: "14px",
                        }}
                    >
                        {t(
                            "To configure your KIOSK, it is essential to configure the elements in the general configuration section."
                        )}
                    </div>
                    <FlexboxGrid
                        className="pt-4 justify-content-end"
                        gap="10px"
                    >
                        <CustomMainColorButton
                            style={{
                                height: "37px !important",
                                width: "8px",
                                minWidth: "115px",
                            }}
                            rounded={true}
                            variant="primary"
                            outline
                            onClick={() => setIsAlertModalOpened(false)}
                        >
                            {t("Close")}
                        </CustomMainColorButton>
                    </FlexboxGrid>
                </ModalBody>
            </Modal>

            <React.Fragment>
                {e.selectedRow.key_project !== undefined ? (
                    <div className="d-flex justify-content-around">
                        {modify === true && (
                            <>
                                <Tooltip title={t("Modify project")}>
                                    <IconButton>
                                        <PencilIcon
                                            onClick={() => {
                                                if (isEdited === true) {
                                                    setIsActionsClicked(
                                                        !isActionsClicked
                                                    );
                                                    setActions(() => {
                                                        setIsEdited(false);
                                                        if (!isCreatedProject) {
                                                            setIsCreatedProject(
                                                                !isCreatedProject
                                                            );
                                                        }
                                                        setRowData(
                                                            e.selectedRow
                                                        );
                                                    });
                                                } else {
                                                    if (!isCreatedProject) {
                                                        setIsCreatedProject(
                                                            !isCreatedProject
                                                        );
                                                    }
                                                    setRowData(e.selectedRow);
                                                }
                                            }}
                                            height={25}
                                            width={25}
                                            className={`${classnames(
                                                "pointer__clz ml-3 cmn_icn_FRedit",
                                                {
                                                    "not-allowed-icon__clz": isArchivedTemplateClicked,
                                                }
                                            )}`}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                        <Tooltip title={t("Historical")}>
                            <IconButton>
                                <TimePastSvgIcon
                                    onClick={() => {
                                        if (isEdited === true) {
                                            setIsActionsClicked(
                                                !isActionsClicked
                                            );
                                            setActions(() => {
                                                setIsEdited(false);
                                                setIsCreatedProject(
                                                    !isCreatedProject
                                                );
                                                setIsHistoryModalOpened(
                                                    !isHistoryModalOpened
                                                );
                                                setSelectedProject([
                                                    {
                                                        [shopID]:
                                                            e.selectedRow
                                                                .projectId,
                                                    },
                                                ]);
                                                setRowData([e.selectedRow]);
                                            });
                                        } else {
                                            setIsCreatedProject(
                                                !isCreatedProject
                                            );
                                            setIsHistoryModalOpened(
                                                !isHistoryModalOpened
                                            );
                                            setSelectedProject([
                                                {
                                                    [shopID]:
                                                        e.selectedRow.projectId,
                                                },
                                            ]);
                                            setRowData([e.selectedRow]);
                                        }
                                    }}
                                    className="ml-2"
                                />
                            </IconButton>
                        </Tooltip>
                    </div>
                ) : e.selectedRow.id_boutique !== undefined &&
                  modify === true ? (
                    <>
                        <Tooltip
                            title={t("Unlink the store from the Template")}
                        >
                            <IconButton>
                                <DisassociateIcon
                                    height={40}
                                    width={35}
                                    fill="white"
                                    className={`${classnames(
                                        "pointer__clz cgt_icn_unlinkShop",
                                        {
                                            "not-allowed-icon__clz": isArchivedTemplateClicked,
                                        }
                                    )}`}
                                    onClick={() => {
                                        if (e.selectedRow.Status === "Publié") {
                                            toast.error(
                                                `${t(
                                                    "Unable to unlink a store from a published Template."
                                                )!}`,
                                                {
                                                    position:
                                                        toast.POSITION
                                                            .TOP_CENTER,
                                                    autoClose: 2000,
                                                    theme: "colored",
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    icon: (
                                                        <ShieldExclamationIcon
                                                            width={25}
                                                            height={25}
                                                            fill="white"
                                                        />
                                                    ),
                                                }
                                            );
                                        } else {
                                            setIsModesModalDisassociate(
                                                !isModesModalDisassociate
                                            );
                                        }

                                        setRowData(e.selectedRow);
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </>
                ) : e?.selectedRow?.subRows === undefined && modify === true ? (
                    <Tooltip title={t("configure")}>
                        <IconButton>
                            <SettingsIcon
                                className={`${classnames("pointer__clz ml-3", {
                                    "not-allowed-icon__clz":
                                        getProject()?.template?.id === "" ||
                                        isArchivedTemplateClicked,
                                })}`}
                                onClick={handleSettingIconOnClickEvent}
                                height={22}
                                width={22}
                            />
                        </IconButton>
                    </Tooltip>
                ) : null}
            </React.Fragment>
        </React.Fragment>
    );
}
