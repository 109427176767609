import React from "react";
import { CSSTransition } from "react-transition-group";
import { useSnapshot } from "valtio";

import { generalConfigStore } from "@store";

import { Preview } from "@components/Common/Design/Preview";

export function AuthenticationModesPreview(): JSX.Element {
    const { chosenTransition, isAnimationIn } = useSnapshot(generalConfigStore);

    return (
        <div
            className="d-flex justify-content-center align-items-center p-1 m-2"
            style={{
                backgroundColor: "#363636",
                borderRadius: "10px",
                height: "740px",
                width: "448px",
            }}
        >
            <CSSTransition
                in={isAnimationIn}
                appear={true}
                timeout={chosenTransition.timeout}
                classNames={chosenTransition.className}
            >
                <div
                    style={{
                        backgroundColor: "#FFFFFF",
                        height: "725px",
                        width: "430px",
                        borderRadius: "10px",
                    }}
                >
                    <Preview />
                </div>
            </CSSTransition>
        </div>
    );
}
