import React, { useEffect } from "react";
import Switch from "react-switch";
import { useTranslation } from "react-i18next";
import { StyledH2 } from "@aureskonnect/react-ui";
import classNames from "classnames";
import { AvForm, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import { setOptions, store } from "../store";
import { useSnapshot } from "valtio";
import ReactSwitch from "react-switch";

import "./index.css";

export default function Options() {
    const { t } = useTranslation();
    const { dataSetupApk } = useSnapshot(store);
    const [activateButton, setActiveButton] = React.useState<boolean>(
        dataSetupApk[0] !== undefined &&
            dataSetupApk[0].options.activateButton !== undefined
            ? dataSetupApk[0].options.activateButton
            : false
    );
    const [mandatoryEmail, setMandatoryEmail] = React.useState<boolean>(
        dataSetupApk[0] !== undefined &&
            dataSetupApk[0].options.mandatoryEmail !== undefined
            ? dataSetupApk[0].options.mandatoryEmail
            : false
    );
    const [validatingOrder, setValidatingOrder] = React.useState<boolean>(
        dataSetupApk[0] !== undefined &&
            dataSetupApk[0].options.validatingOrder !== undefined
            ? dataSetupApk[0].options.validatingOrder
            : false
    );
    const [compositeProducts, setCompositeProducts] = React.useState<string>(
        dataSetupApk[0] !== undefined &&
            dataSetupApk[0].options.compositeProducts !== undefined
            ? dataSetupApk[0].options.compositeProducts
            : `${t("The Next In The List")}`
    );

    useEffect(() => {
        const dataOptions = {
            activateButton,
            mandatoryEmail,
            validatingOrder,
            compositeProducts,
        };

        setOptions(dataOptions);
    }, [
        activateButton,
        mandatoryEmail,
        validatingOrder,
        compositeProducts,
        dataSetupApk,
    ]);

    return (
        <React.Fragment>
            <StyledH2
                style={{
                    font: "normal normal 600 45px/61px Nunito Sans",
                    color: "#000000",
                    opacity: "1",
                }}
                className="ml-3 pt-5"
            >
                {t("Options")}
            </StyledH2>
            <div className="border-footer__clz" />
            <StyledH2
                style={{
                    font: "normal normal 600 20px/27px Nunito Sans",
                    color: "#000000",
                    opacity: "1",
                }}
                className="ml-5 px-1 pt-2"
            >
                {t("Customer login")}
            </StyledH2>
            <div className="ml-5 d-flex justify-content-between p-2">
                <span
                    style={{ font: "normal normal normal 16px/21px Segoe UI" }}
                >
                    {t("Activate the ''Quick connect'' button")}
                </span>

                <ReactSwitch
                    offColor="#faafb4"
                    offHandleColor="#E30613"
                    onColor="#c2eec4"
                    onHandleColor="#34C38F"
                    height={19}
                    handleDiameter={24}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    checked={activateButton}
                    onChange={(e: any) => {
                        setActiveButton(!activateButton);
                    }}
                />
            </div>
            <span className="not-allowed-icon__clz ml-5 px-1 pt-2 p-2">
                {t("Allow order taking without customer authentication")}
            </span>
            <div className="d-flex justify-content-between p-2 ml-5">
                <span
                    className={classNames("", {
                        "not-allowed-icon__clz": activateButton === false,
                    })}
                    style={{ font: "normal normal normal 16px/21px Segoe UI" }}
                >
                    {t(
                        "Mandatory email for the case of a ''Quick connection''"
                    )}
                </span>
                <Switch
                    offColor="#faafb4"
                    offHandleColor="#E30613"
                    onColor="#c2eec4"
                    onHandleColor="#34C38F"
                    height={19}
                    disabled={!activateButton}
                    handleDiameter={24}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    checked={mandatoryEmail}
                    onChange={(e: any) => {
                        setMandatoryEmail(!mandatoryEmail);
                    }}
                />
            </div>
            <div className="border-footer__clz" />
            <StyledH2
                style={{
                    font: "normal normal 600 20px/27px Nunito Sans",
                    color: "#000000",
                    opacity: "1",
                }}
                className="ml-5 px-1 pt-2"
            >
                {t("Contact shop")}
            </StyledH2>
            <div className="d-flex justify-content-between p-2 ml-5">
                <span
                    style={{ font: "normal normal normal 16px/21px Segoe UI" }}
                >
                    {t(
                        "Activate the ''Call the shop'' icon when validating the order"
                    )}
                </span>

                <Switch
                    offColor="#faafb4"
                    offHandleColor="#E30613"
                    onColor="#c2eec4"
                    onHandleColor="#34C38F"
                    height={19}
                    handleDiameter={24}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    checked={validatingOrder}
                    onChange={(e: any) => {
                        setValidatingOrder(!validatingOrder);
                    }}
                />
            </div>
            <span className="not-allowed-icon__clz ml-5 px-1 pt-2 p-2">
                {t("This option is available for the app only")}
            </span>
            <div className="border-footer__clz" />
            <StyledH2
                style={{
                    font: "normal normal 600 20px/27px Nunito Sans",
                    color: "#000000",
                    opacity: "1",
                }}
                className="ml-5 px-1 pt-2"
            >
                {t("Free rules for composite products")}
            </StyledH2>

            <AvForm model={{ form: compositeProducts, checkItOut: true }}>
                <AvRadioGroup
                    name="form"
                    required
                    inline
                    errorMessage="Pick one!"
                >
                    <div className="p-2 ml-5">
                        <AvRadio
                            id="oneRadio"
                            label={`${t("The Next In The List")}`}
                            value={t("The Next In The List")}
                            disabled={false}
                            onChange={() => {
                                setCompositeProducts(
                                    `${t("The Next In The List")}`
                                );
                            }}
                        />
                    </div>

                    <div className="p-2 ml-5">
                        <AvRadio
                            id="twoRadio"
                            label={`${t("The cheapest")}`}
                            value={t("The cheapest")}
                            disabled={false}
                            onChange={() => {
                                setCompositeProducts(`${t("The cheapest")}`);
                            }}
                        />
                    </div>
                    <div className="p-2 ml-5">
                        <AvRadio
                            id="threeRadio"
                            label={`${t("The most expensive")}`}
                            value={t("The most expensive")}
                            disabled={false}
                            onChange={() => {
                                setCompositeProducts(
                                    `${t("The most expensive")}`
                                );
                            }}
                        />
                    </div>
                </AvRadioGroup>
            </AvForm>
        </React.Fragment>
    );
}
