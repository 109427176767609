import React from "react";
import { StyledButton, StyledH2 } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { capitalize } from "@helpers/general";

type PrintersPathsModalType = {
    setIsDeviceAssociateModalOpened: Function;
    isDeviceAssociateModalOpened: boolean;
    rowData: any;
};

type CustomColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
};

export default function Modalresult({
    setIsDeviceAssociateModalOpened,
    isDeviceAssociateModalOpened,
    rowData,
}: PrintersPathsModalType) {
    const { t } = useTranslation();
    const [loading] = React.useState<boolean>(true);
    let arrayOfCustomColumns: CustomColumnProps[] = [];

    arrayOfCustomColumns.push({
        indexOFColumn: 999,
        columnName: t("Actions"),
        customJsx: () => (
            <StyledButton rounded variant="light">
                <span className="text-dark">{t("Select")}</span>
            </StyledButton>
        ),
    });

    return (
        <React.Fragment>
            <Modal
                size="xl"
                centered
                isOpen={isDeviceAssociateModalOpened}
                style={{
                    marginTop: "4%",
                    marginLeft: "30%",
                    width: "942px",
                }}
            >
                <ModalHeader
                    toggle={() =>
                        setIsDeviceAssociateModalOpened!(
                            !isDeviceAssociateModalOpened
                        )
                    }
                >
                    <StyledH2>
                        {capitalize(t("To restart"))}
                        {rowData.Application === ""
                            ? rowData.Périphérique + " - " + rowData.appId
                            : ` ${
                                  rowData.peripheral === undefined
                                      ? ""
                                      : rowData.peripheral
                              } / ${rowData.Application}`}
                    </StyledH2>
                </ModalHeader>

                <ModalBody style={{ height: "500px" }}>
                    <div>
                        {loading === true ? (
                            <div>
                                <img
                                    alt=""
                                    width="200"
                                    style={{
                                        marginLeft: "350px",
                                        marginTop: "72px",
                                    }}
                                    src="https://thumbs.gfycat.com/CautiousImpassionedBoubou-size_restricted.gif"
                                />
                                <br></br>
                                <br></br>
                                <StyledH2 style={{ marginLeft: "200px" }}>
                                    {t("Coin mechanism being restarted")}{" "}
                                    {t("Please wait...")}
                                </StyledH2>
                            </div>
                        ) : null}
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}
