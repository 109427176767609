import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { CheckboxIcon, StyledButton } from "@aureskonnect/react-ui";
import { toast } from "react-toastify";
import { mutate } from "swr";

import { store } from "@components/VerticalLayout/store";
import { store as orbStore } from "../store";

import "./index.css";

type PropsType = {
    children: React.ReactNode;
    setIsConfigurationModalOpened: Function;
    isConfigurationModalOpened: boolean;
    rowData: any;
    setIsDataUpdated: Function;
};

export function ModalWrapper({
    children,
    setIsConfigurationModalOpened,
    isConfigurationModalOpened,
    rowData,
    setIsDataUpdated,
}: PropsType): JSX.Element {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const {
        view,
        language,
        model,
        options,
        categoryAssignment,
        advertisingAnimation,
    } = useSnapshot(orbStore);

    async function handleUpdateShopButtonOnClickEvent() {
        let apiUrl: any;
        apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/configuration/orb`;
        mutate(
            apiUrl,
            await fetch(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    userId: userID,
                    franchiseId: franchiseID,
                    project_id: "PROJECT",
                    projectId: rowData.projectId,
                    shopId: rowData.shopId,
                    operatorId: operatorID,
                    data: {
                        template: {
                            view: view,
                            language: language,
                            model: model,
                            options: options,
                            categoryAssignment: categoryAssignment,
                            advertisingAnimation: advertisingAnimation,
                        },
                    },
                }),
                method: "POST",
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }
                    toast.success(`${t("successfully")}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    });

                    setIsConfigurationModalOpened(!isConfigurationModalOpened);
                    setIsDataUpdated(true);
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }
    return (
        <React.Fragment>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                isOpen={isConfigurationModalOpened}
                className="modal-wrapper__clz"
            >
                <ModalHeader
                    toggle={() => {
                        setIsConfigurationModalOpened(
                            !isConfigurationModalOpened
                        );
                        setIsDataUpdated(true);
                    }}
                    className="text-uppercase"
                >
                    {t("ORB Setup")}
                </ModalHeader>
                <ModalBody className="p-0">{children}</ModalBody>
                <ModalFooter>
                    <StyledButton
                        className="w-20"
                        rounded
                        variant="primary"
                        onClick={() => {
                            handleUpdateShopButtonOnClickEvent();
                        }}
                    >
                        {t("Save")}
                    </StyledButton>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
