import React from "react";
import { useTranslation } from "react-i18next";
import { Nav, NavItem, TabContent, TabPane, NavLink } from "reactstrap";
import classNames from "classnames";

import { setActiveTabIndex, setIsInformationModesPersonalized } from "@store";

import { Modes } from "./Modes";
import { Header } from "./Header";
import { TopBanner } from "./TopBanner";
import { ActionsButtons } from "./ActionsButtons";

export function Design(): JSX.Element {
    const { t } = useTranslation();

    const tabs: TabsType[] = [
        {
            title: t("General"),
            content: <TopBanner />,
        },
        {
            title: t("Header"),
            content: <Header />,
        },
        {
            title: t("Information modes"),
            content: <Modes />,
        },
        {
            title: t("Actions Buttons"),
            content: <ActionsButtons />,
        },
    ];

    const [activeTab, setActiveTab] = React.useState<number>(0);

    function toggleNav(tab: number) {
        if (activeTab !== tab) setActiveTab(tab);
        setActiveTabIndex(tab);
    }

    React.useEffect(() => {
        if (activeTab !== 2) {
            setIsInformationModesPersonalized(false);
        }
    }, [activeTab]);

    return (
        <div className="design-container-items__clz">
            <div
                style={{
                    width: "85%",
                    height: "98%",
                    backgroundColor: "#EDEDED",
                }}
                className="m-2 border-0 rounded"
            >
                <div
                    className="p-2 mt-1 text-center font-weight-bold"
                    style={{ cursor: "default" }}
                >
                    {t("Setup options")}
                </div>
                <Nav tabs className="mt-2">
                    {tabs.map((element, index) => {
                        return (
                            <NavItem key={index} style={{ width: "100%" }}>
                                <NavLink
                                    className={classNames("nav-link__clz", {
                                        "active design-tab-nav-tabs-active-link__clz":
                                            activeTab === index,
                                    })}
                                    onClick={() => {
                                        toggleNav(index);
                                        setActiveTabIndex(index);
                                    }}
                                >
                                    <span className="mx-2">
                                        {element.title}
                                    </span>
                                </NavLink>
                            </NavItem>
                        );
                    })}
                </Nav>
            </div>
            <div>
                <TabContent
                    activeTab={activeTab}
                    style={{ width: "100%", height: "95%" }}
                >
                    {tabs.map((element, index) => {
                        return (
                            <TabPane
                                tabId={index}
                                style={{ height: "100%", width: "100%" }}
                                key={index}
                            >
                                {element.content}
                            </TabPane>
                        );
                    })}
                </TabContent>{" "}
            </div>
        </div>
    );
}
