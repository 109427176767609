import React from "react";
import { useNavigate } from "react-router-dom";
import { StyledButton } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import {
    setMultiShopSwitch,
    setOneShopSwitch,
} from "@components/VerticalLayout/store";

import "./index.css";

export function CustomIconsComponent({ e }: any) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    function setShop(
        franchiseId: string,
        shopId: string,
        pays: string,
        shopName: string
    ) {
        setOneShopSwitch(franchiseId, shopId, pays, shopName);

        navigate("/applications");
    }
    function setFranchise(franchiseId: string, shopName: string) {
        setMultiShopSwitch(franchiseId, shopName);
        navigate("/applications");
    }

    return (
        <React.Fragment>
            {e.selectedRow.Nature === "franchise" ||
            e.selectedRow.Nature === "boutique" ||
            e.selectedRow.Nature === "Franchise" ||
            e.selectedRow.Nature === "Boutique" ? (
                <StyledButton
                    outline
                    rounded
                    variant={"rgb(35, 35, 35)"}
                    onClick={() => {
                        if (
                            e.selectedRow.Nature === "boutique" ||
                            e.selectedRow.Nature === "Boutique"
                        ) {
                            localStorage.setItem(
                                "selectedAccount",
                                e.selectedRow["Nom commercial"]
                            );
                            setShop(
                                e.selectedRow.uuidFranchise,
                                e.selectedRow.uid,
                                e.selectedRow.Pays,
                                e.selectedRow["Nom commercial"]
                                // e.selectedRow["Société"]LOCAL,
                            );
                        } else {
                            localStorage.setItem(
                                "selectedAccount",
                                e.selectedRow["Nom commercial"]
                            );
                            setFranchise(
                                e.selectedRow.uid,
                                e.selectedRow["Nom commercial"]
                            );
                        }
                    }}
                >
                    {t("To access")}
                </StyledButton>
            ) : (
                <div></div>
            )}
        </React.Fragment>
    );
}
