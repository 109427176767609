import { DeviceSettingsIcon } from "@components/Common/SvgIcons";

export type CardMenuType = {
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    description?: string;
    title: string;
    path: string;
};

export const CARD_MENU_LIST: CardMenuType[] = [
    {
        title: "Configuration",
        Icon: DeviceSettingsIcon,
        description:
            "Description to add. Description to add. Description to add. Description to add",
        path: "/Configuration",
    },
];
