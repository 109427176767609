import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledLabel, StyledH2 } from "@aureskonnect/react-ui";
import moment from "moment";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";

import { store } from "@components/VerticalLayout/store";

type ExceptionalHoursInfoModalType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    rowData: any;
    infoHours: any;
};

export function ExceptionalHoursInfoModal({
    isModalOpened,
    setIsModalOpened,
    rowData,
    infoHours,
}: ExceptionalHoursInfoModalType) {
    const { t } = useTranslation();
    const { oneShop, userID, franchiseID } = useSnapshot(store);

    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);

    return (
        <StyledModal toggle={true} isOpen={isModalOpened} centered size="xl">
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                }}
            >
                <StyledH2 className="hex_txt_FRlistOfExceptional">
                    {`${t("List of exceptions")}: ${
                        rowData[t("Designation")]
                    }`.toUpperCase()}
                </StyledH2>
            </ModalHeader>
            <ModalBody>
                <div className="d-flex justify-content-between pt-2">
                    <StyledLabel htmlFor="text" className="hex_txt_FRstate">
                        {t("State").toUpperCase()}:
                    </StyledLabel>
                    <StyledLabel htmlFor="text">
                        {t(rowData.state).toUpperCase()}
                    </StyledLabel>
                </div>
                <div className="d-flex justify-content-between pt-2">
                    <StyledLabel htmlFor="text" className="hex_txt_FRhourly">
                        {t("Hours").toUpperCase()}:
                    </StyledLabel>
                    <StyledLabel htmlFor="text">{infoHours}</StyledLabel>
                </div>
                <div className="d-flex justify-content-between pt-2">
                    <StyledLabel
                        htmlFor="text"
                        className="hex_txt_FRrecurrenceEndDate"
                    >
                        {t("Recurrence end date").toUpperCase()}:
                    </StyledLabel>
                    <StyledLabel htmlFor="text">
                        {moment(
                            rowData.endDate_recurrence,
                            "YYYY-MM-DD"
                        ).format("DD/MM/YYYY")}
                    </StyledLabel>
                </div>
                <div
                    style={{
                        display: "grid",
                        minHeight: oneShop ? "650px" : "600px",
                        gridColumnGap: "4px",
                        border: 0,
                    }}
                >
                    <DynamicTable
                        url={`${process.env.REACT_APP_API_V2_URL}/settings/general/exceptionalHoursInfo?userId=${userID}&shopId=${rowData.shopId}&franchiseId=${franchiseID}&idException=${rowData.Id}`}
                        canResize
                        showGlobalFilter
                        showFilter
                        setLocalFilterActive={setLocalFilterActive}
                        filterActive={filterActive}
                        actionColumn={() => <></>}
                        elevationTable={1}
                        minHeight="450px"
                        maxHeight="450px"
                        name="language"
                    />
                </div>
            </ModalBody>
            <ModalFooter className="border-top-0 "></ModalFooter>
        </StyledModal>
    );
}
