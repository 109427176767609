import React from "react";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { StyledLabel, StyledSelectInput } from "@aureskonnect/react-ui";
import { Card, Input } from "reactstrap";

import { generalConfigStore, setGeneralDesignFonts } from "@store";

import { UsualModeWrapper } from "./UsualModeWrapper";

type OptionsType = { label: string; value: string };

export function SelectWrapper(): JSX.Element {
    const { t } = useTranslation();

    const {
        project: { template },
    } = useSnapshot(generalConfigStore);

    const options: OptionsType[] = [
        {
            label: "Tahoma",
            value: "Tahoma",
        },
        {
            label: "Times",
            value: "Times",
        },
        {
            label: "Times New Roman",
            value: "Times New Roman",
        },
    ];

    const [fonts, setFonts] = React.useState<GeneralDesignFontType[]>([]);

    function handleFontsSelectOnChangeEvent(
        selectedValue: OptionsType,
        index: number
    ) {
        setFonts(
            (prevState: GeneralDesignFontType[]): GeneralDesignFontType[] => {
                let newState = [...prevState];

                newState = newState.map(
                    (item: GeneralDesignFontType, itemIndex: number) => {
                        if (itemIndex === index) {
                            return {
                                ...item,
                                font: selectedValue.value,
                            };
                        }

                        return { ...item };
                    }
                );
                setGeneralDesignFonts(newState);
                return newState;
            }
        );
    }

    function handleSizeSelectOnChangeEvent(size: number, index: number) {
        setFonts(
            (prevState: GeneralDesignFontType[]): GeneralDesignFontType[] => {
                let newState = [...prevState];

                newState = newState.map(
                    (item: GeneralDesignFontType, itemIndex: number) => {
                        if (itemIndex === index) {
                            return {
                                ...item,
                                size: size,
                            };
                        }

                        return { ...item };
                    }
                );
                setGeneralDesignFonts(newState);
                return newState;
            }
        );
    }

    React.useEffect(() => {
        setFonts((template.content.generalDesign as GeneralDesignType).fonts);
    }, [template]);

    return (
        <div
            className="d-flex flex-row justify-content-center"
            style={{ gap: "80px" }}
        >
            {fonts.map((item: GeneralDesignFontType, index: number) => (
                <div
                    key={index}
                    className="d-flex flex-column"
                    style={{ gap: "10px" }}
                >
                    <Card
                        style={{
                            width: "270px",
                            height: "230px",
                            borderRadius: "20px",
                            display: "grid",
                            gridTemplateRows: "1fr 1fr",
                        }}
                        className="justify-content-center"
                    >
                        <div className="d-flex flex-column justify-content-center">
                            <StyledLabel>{t("Font")}</StyledLabel>
                            <StyledSelectInput
                                options={options}
                                value={{
                                    label: item.font,
                                    value: item.font,
                                }}
                                name="fonts-select"
                                onChange={(selectedValue: OptionsType) => {
                                    handleFontsSelectOnChangeEvent(
                                        selectedValue,
                                        index
                                    );
                                }}
                                noOptionsMessage={() =>
                                    t("There's no more choice")
                                }
                            />
                        </div>

                        <div className="d-flex flex-column justify-content-center">
                            <StyledLabel>{t("Size")}</StyledLabel>
                            <Input
                                name="size-select"
                                type="number"
                                value={item.size}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    handleSizeSelectOnChangeEvent(
                                        Number(event.target.value),
                                        index
                                    );
                                }}
                            />
                        </div>
                    </Card>
                    <div className="d-flex justify-content-center align-items-center">
                        <span
                            style={{
                                font:
                                    "normal normal normal 15px/20px Nunito Sans",
                                color: "black",
                            }}
                        >
                            {t(item.title)}
                        </span>
                    </div>
                </div>
            ))}
            <UsualModeWrapper />
        </div>
    );
}
