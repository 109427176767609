import React from "react";
import classnames from "classnames";
import {
    CheckboxIcon,
    PlusIcon,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { mutate } from "swr";

import {
    setActions,
    setIsActionsClicked,
    setIsEdited,
    setIsHistoryModalOpened,
    setIsPlusIconClicked,
    setIsResetAllLogos,
    store,
} from "../store";
import { store as useStore } from "@components/VerticalLayout/store";

import ErrorToast from "../../../../components/Common/ErrorTost";

import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";
import { CustomMainColorButtonWithIcon } from "@components/Common/CustomMainColorButtonWithIcon";

export function CustomSideFilter({
    setLogo,
    setShopId,
    selectedRows,
    logo,
    setDataIsUpdated,
    setLocalFilterActive,
    setIsModalConfirmationOpened,
    isModalConfirmationOpened,
    isAddLogoClicked,
    setIsAddLogoClicked,
    isLogoArchived,
    setIsLogoArchived,
    setIsInInitializationModalOpened,
}: any) {
    const { t } = useTranslation();
    const { shopID, oneShop, userID, franchiseID, operatorID } = useSnapshot(
        useStore
    );
    const {
        numberArchived,
        numberActive,
        isHistoryModalOpened,
        shopsLogoData,
        isEdited,
        isActionsClicked,
    } = useSnapshot(store);

    const [error, setError] = React.useState(false);
    function logoExistInShops() {
        let isIdExist;
        let idsAssociated: any = [];
        shopsLogoData.forEach((el: any) => {
            el.subRows.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        });

        selectedRows.forEach((el: any) => {
            if (idsAssociated.includes(el.Id)) {
                isIdExist = true;
            }
        });
        if (isIdExist === true) {
            ErrorToast(
                `${t(
                    "Please note that you cannot archive an item assigned to stores."
                )!}`
            );
        } else {
            handleArchiveLogo();
        }
    }
    function logoExistInShopsActived() {
        let isIdExist;
        let idsAssociated: any = [];
        shopsLogoData.forEach((el: any) => {
            el.subRows.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        });

        selectedRows.forEach((el: any) => {
            if (idsAssociated.includes(el.Id)) {
                isIdExist = true;
            }
        });
        if (isIdExist === true) {
            setIsModalConfirmationOpened(!isModalConfirmationOpened);
        } else {
            handleActiveLogo();
        }
    }

    async function handleArchiveLogo() {
        toast.dismiss();
        let localData: any = [];

        selectedRows.forEach(async (el: any) => {
            const id = el.Id;
            let dataLogo = {
                userId: userID,
                shopId: [selectedRows[0].shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                data: {
                    [id]: {
                        designation: el[t("Designation")],
                        support_de_vente: el[t("Sales support")],
                        urlImage: el[t("Image")],
                        shopId: el.shopId,
                        tag: el.tag,
                        isActive: false,
                        img: el.img,
                        isArchived: true,
                    },
                },
            };
            const data = new FormData();
            data.append("dataLogo", JSON.stringify(dataLogo));
            data.append("image", "");
            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/logo`;
            localData.push(
                fetch(apiUrlAdd, {
                    method: "POST",
                    body: data,
                }).then((response) => response.json())
            );
        });
        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/logo`,
                await Promise.all(localData).then((result) => {})
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setError(true);
        }

        if (error === false) {
            toast.success(`${t("The logo is successfully archived")!}`, {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",
                icon: <CheckboxIcon height={25} width={25} fill="white" />,
            });
            setDataIsUpdated!(true);
        }
    }
    async function handleActiveLogo() {
        toast.dismiss();
        let localData: any = [];
        selectedRows.forEach(async (el: any) => {
            const id = el.Id;
            let dataLogo = {
                userId: userID,
                shopId: [selectedRows[0].shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                data: {
                    [id]: {
                        designation: el[t("Designation")],
                        support_de_vente: el[t("Sales support")],
                        urlImage: el[t("Image")],
                        shopId: el.shopId,
                        tag: el.tag,
                        img: el.img,
                        isActive: false,
                        isArchived: false,
                    },
                },
            };
            const data = new FormData();
            data.append("dataLogo", JSON.stringify(dataLogo));
            data.append("image", "");
            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/logo`;
            localData.push(
                fetch(apiUrlAdd, {
                    method: "POST",
                    body: data,
                }).then((response) => response.json())
            );
        });
        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/logo`,
                await Promise.all(localData).then((result) => {})
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setError(true);
        }

        if (error === false) {
            toast.success(`${t("logo has been successfully activated")!}`, {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",
                icon: <CheckboxIcon height={25} width={25} fill="white" />,
            });
            setDataIsUpdated!(true);
        }
    }

    return (
        <div className="d-flex justify-content-start align-items-center ml-2">
            {isAddLogoClicked !== true ? (
                <span
                    onClick={() => {
                        setLogo([]);
                        setIsAddLogoClicked(!isAddLogoClicked);
                        setShopId(oneShop ? shopID : 0);
                        setLocalFilterActive(false);
                        setIsResetAllLogos(false);
                    }}
                    className={
                        isLogoArchived
                            ? "w-100 ml-2 not-allowed-icon__clz cmn_btn_openForm"
                            : "w-100 ml-2 cmn_btn_openForm"
                    }
                >
                    <CustomMainColorButtonWithIcon
                        icon="PlusIcon"
                        iconPosition="left"
                        rounded
                        variant="primary"
                    >
                        {t("Add a logo")}
                    </CustomMainColorButtonWithIcon>
                </span>
            ) : (
                <CustomMainColorIconButton
                    icon="PlusIcon"
                    className="lgo_btn_FRreset"
                    rounded
                    disabled={logo.length === 0 ? false : true}
                    onClick={() => {
                        if (isEdited === true)
                            setIsInInitializationModalOpened(true);
                        else {
                            setIsPlusIconClicked(true);
                        }
                    }}
                >
                    <PlusIcon height={20} width={20} fill="white" />
                </CustomMainColorIconButton>
            )}

            <VerticalDotsIcon
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={25}
                fill="black"
                className="cmn_drp_FRmenu"
            />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames(
                        "dropdown-item pl-1 cmn_btn_FRlistActivated"
                    )}
                    onClick={() => {
                        setIsLogoArchived(false);
                        setDataIsUpdated(true);
                    }}
                >
                    {t("Enabled logo(s)")} ({numberActive})
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames(
                        "dropdown-item pl-1 cmn_btn_FRlistArchived"
                    )}
                    onClick={() => {
                        if (isEdited === true) {
                            setIsActionsClicked(!isActionsClicked);
                            setActions(() => {
                                setIsEdited(false);
                                setIsLogoArchived(true);
                                setDataIsUpdated(true);
                            });
                        } else {
                            setIsLogoArchived(true);
                            setDataIsUpdated(true);
                        }
                    }}
                >
                    {t("Archived logo(s)")} ({numberArchived})
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={`${classnames(
                        "dropdown-item pl-1 cmn_btn_FRhistory",
                        {
                            "not-allowed-icon__clz": selectedRows.length === 0,
                        }
                    )}`}
                    onClick={() => {
                        if (isEdited === true) {
                            setIsActionsClicked(!isActionsClicked);
                            setActions(() => {
                                setIsEdited(false);
                                if (selectedRows.length === 1) {
                                    ErrorToast(
                                        `${t(
                                            "Attention, it is a multiple action. Please select at least two items"
                                        )!}`
                                    );
                                } else {
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );
                                }
                            });
                        } else {
                            if (selectedRows.length === 1) {
                                ErrorToast(
                                    `${t(
                                        "Attention, it is a multiple action. Please select at least two items"
                                    )!}`
                                );
                            } else {
                                setIsHistoryModalOpened(!isHistoryModalOpened);
                            }
                        }
                    }}
                >
                    {t("Historical")}
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={`${classnames(
                        "dropdown-item pl-1 cmn_btn_FRunarchive cmn_btn_FRarchived",
                        {
                            "not-allowed-icon__clz": selectedRows.length === 0,
                        }
                    )}`}
                    onClick={() => {
                        if (isEdited === true) {
                            setIsActionsClicked(!isActionsClicked);
                            setActions(() => {
                                setIsEdited(false);
                                if (selectedRows.length === 1) {
                                    ErrorToast(
                                        `${t(
                                            "Attention, it is a multiple action. Please select at least two items"
                                        )!}`
                                    );
                                } else if (!isLogoArchived) {
                                    logoExistInShops();
                                } else {
                                    logoExistInShopsActived();
                                }
                            });
                        } else {
                            if (selectedRows.length === 1) {
                                ErrorToast(
                                    `${t(
                                        "Attention, it is a multiple action. Please select at least two items"
                                    )!}`
                                );
                            } else if (!isLogoArchived) {
                                logoExistInShops();
                            } else {
                                logoExistInShopsActived();
                            }
                        }
                    }}
                >
                    {isLogoArchived ? `${t("Dearchive")}` : `${t("Archive")}`}
                </div>
            </div>
        </div>
    );
}
