import React from "react";
import { useTranslation } from "react-i18next";
import { StyledH2, StyledLabel } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useSnapshot } from "valtio";

import { store, setIsModesModalDisassociate } from "../../../store/project";

import CardFooterModalPublichProject from "./CardFooterModalPublichProject";
import { capitalize } from "@helpers/general";

type ModalPublishType = {
    rowData: any;
    setIsDataUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    shopId: string;
};

export default function ModalPublishProject({
    rowData,
    setIsDataUpdated,
}: ModalPublishType) {
    const { t } = useTranslation();

    const {  isModesModalDisassociate } = useSnapshot(
        store
    );

    return (
        <React.Fragment>
            <Modal centered isOpen={isModesModalDisassociate}>
                <ModalHeader
                    toggle={() => {
                        setIsModesModalDisassociate!(
                            !{ isModesModalDisassociate }
                        );
                    }}
                >
                    <StyledH2>{capitalize(t("Unsociate shop(s)"))}</StyledH2>
                </ModalHeader>
                <ModalBody>
                    <StyledLabel className="pl-3 m-0">
                        {`${t(
                            "Do you want to confirm the dissociation of"
                        )} ''${rowData[t("Designation")]}'' ${t("from the Template")}
                              ''${rowData?.Project}''?`}
                    </StyledLabel>
                </ModalBody>
                <CardFooterModalPublichProject
                    rowData={rowData}
                    setIsDataUpdated={setIsDataUpdated}
                />
            </Modal>
        </React.Fragment>
    );
}
