import React from "react";
import { useTranslation } from "react-i18next/";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";

import { setMultiShopSwitch, store } from "@components/VerticalLayout/store";

import { CustomIconsComponent } from "./CustomIcons";
import PageTitle from "@components/Common/PageTitle";

type CustomColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
};

export function TestAuto() {
    const { t } = useTranslation();
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const { oneShop, franchiseID } = useSnapshot(store);

    React.useEffect(() => {}, [selectedRows]);
    const uidEntity = localStorage.getItem("Uid_Entitie_User");
    const urlApi =
        "https://dev-consoleadmin-api.aureskonnect.com/apiAccess/v1/entity/" +
        uidEntity +
        "";

    const dataFetch = React.useCallback(async () => {
        const data = await(await fetch(urlApi)).json();
        setMultiShopSwitch(franchiseID, data.data[0]["Nom commercial"]);
        // eslint-disable-next-line
    }, [urlApi, franchiseID]);
    React.useEffect(() => {
        dataFetch();
    }, [dataFetch]);
    let arrayOfCustomColumns: CustomColumnProps[] = [
        {
            indexOFColumn: oneShop ? 9 : 10,
            columnName: t("Actions"),
            customJsx: (e: any) => {
                return <CustomIconsComponent e={e} />;
            },
        },
    ];
    return (
        <React.Fragment>
            <PageTitle title={t("Peripherals")} />

            <div
                className=""
                style={{
                    padding: "15px",
                }}
            >
                <h1>Choisir un compte</h1>
                <DynamicTable
                    url={urlApi}
                    canExpand={true}
                    canSelect={true}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    canResize
                    showGlobalFilter
                    elevationTable={1}
                    setSelectedRows={setSelectedRows}
                    name="Choice"
                    minHeight={"600px"}
                    maxHeight={"400px"}
                />
            </div>
        </React.Fragment>
    );
}
