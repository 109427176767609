export const data = {
    categories: [
        {
            id: 1,
            name: "COTE BISTROT",
            image: "../images_order/FAMND7.png",
            content: "../images_order/Bistrot.mp4",
            color: "",
            active: true,
        },
        {
            id: "2",
            name: "BURGERS HOMEMADE",
            image: "../images_order/FAMND31.png",
            content: "../images_order/burger.mp4",
            active: false,
        },
        {
            id: "3",
            name: "PÂTES",
            image: "../images_order/FAMND10.png",
            content: "../images_order/pate.mp4",
            active: false,
        },
        {
            id: "4",
            name: "SALADES",
            image: "../images_order/FAMND11.png",
            content: "../images_order/salade.mp4",
            active: false,
        },
        {
            id: "5",
            name: "PIZZAS",
            image: "../images_order/FAMND64.png",
            content: "../images_order/pizza.mp4",
            active: false,
        },
        {
            id: "6",
            name: "SANDWICHS",
            image: "../images_order/FAMND66.png",
            content: "../images_order/sandwich.mp4",
            active: false,
        },
        {
            id: "7",
            name: "DESSERTS",
            image: "../images_order/FAMND74.png",
            content: "../images_order/dessert.mp4",
            active: false,
        },
        {
            id: "8",
            name: "BOISSONS FRAICHES",
            image: "../images_order/FAMND21.png",
            content: "../images_order/Boisson.mp4",
            active: false,
        },
    ],
    products: [
        {
            id_category: 1,
            maxQuantity: 10,
            category: "COTE BISTROT",
            name: { isVisible: true, content: "POULET CRISPY THAI" },
            price: { isVisible: true, content: 11.9 },
            calorie: { isVisible: false, content: "12" },
            description: {
                isVisible: true,
                content:
                    "A media query string ready to be used with most styling solutions, which matches screen widths",
            },
            image: "../images_order/PRFND339.png",
            basic_composition: [1, 2, 3, 4, 5],
            available: true,
            stock: 20,
            allergens: [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
            ],
        },
        {
            id_category: 1,
            maxQuantity: 10,
            category: "COTE BISTROT",
            name: { isVisible: true, content: "BROCHETTES DE POULET" },
            price: { isVisible: true, content: 11.9 },
            calorie: { isVisible: false, content: "12" },
            description: {
                isVisible: true,
                content:
                    "A media query string ready to be used with most styling solutions, which matches screen widths",
            },
            image: "../images_order/PRFND341.png",

            basic_composition: ["Composant 1 ", "Composant 2 ", "Composant 3 "],
            available: true,
            stock: 20,
            allergens: ["1", "2", "3", "4", "5"],
        },
        {
            id_category: 1,
            maxQuantity: 10,
            category: "COTE BISTROT",
            name: { isVisible: true, content: "ENTRECOTE" },
            price: { isVisible: true, content: 14.9 },
            calorie: { isVisible: false, content: "12" },
            description: {
                isVisible: true,
                content:
                    "A media query string ready to be used with most styling solutions, which matches screen widths",
            },
            image: "../images_order/PRFND340.png",

            basic_composition: ["Composant 1 ", "Composant 2 ", "Composant 3 "],
            available: true,
            stock: 20,
        },
        {
            id_category: 1,
            maxQuantity: 10,
            category: "BURGERS HOMEMADE",
            name: { isVisible: true, content: "LE VEGETARIEN" },
            price: { isVisible: true, content: 10.5 },
            calorie: { isVisible: false, content: "12" },
            description: {
                isVisible: true,
                content:
                    "A media query string ready to be used with most styling solutions, which matches screen widths",
            },
            image: "../images_order/PRFND361.png",

            basic_composition: [1, 2, 3],
            available: true,
            stock: 20,
            size: [
                {
                    name: "Small",
                    //s   price: 0.5,
                    image: "../images_order/small-burger.png",
                },
                {
                    name: "Medium",
                    price: 0.5,
                    image: "../images_order/medium-burger.png",
                },
                {
                    name: "Big",
                    price: 0.99,
                    image: "../images_order/big-burger.png",
                },
                {
                    name: "XBig",
                    price: 9.9,
                    image: "../images_order/big-burger.png",
                },
                {
                    name: "XXBig",
                    price: 9.9,
                    image: "../images_order/big-burger.png",
                },
                // {
                //     name: "XXXBig",
                //     price: 9.9,
                //     image: "../images_order/big-burger.png",
                // },
            ],
        },
        {
            id_category: 1,
            maxQuantity: 10,
            category: "BURGERS HOMEMADE",
            name: { isVisible: true, content: "LE SPICY CHICKEN" },
            price: { isVisible: true, content: 10.5 },
            calorie: { isVisible: false, content: "12" },
            description: {
                isVisible: true,
                content:
                    "A media query string ready to be used with most styling solutions, which matches screen widths",
            },
            image: "../images_order/PRFND624.png",

            basic_composition: ["Composant 1 ", "Composant 2 ", "Composant 3 "],
            available: true,
            stock: 20,
        },

        {
            id_category: 1,
            maxQuantity: 10,
            category: "BURGERS HOMEMADE",
            name: { isVisible: true, content: "L'AMÉRICAIN" },
            price: { isVisible: true, content: 10 },
            calorie: { isVisible: false, content: "12" },
            description: {
                isVisible: true,
                content:
                    "A media query string ready to be used with most styling solutions, which matches screen widths",
            },
            image: "../images_order/PRFND355.png",

            basic_composition: ["Composant 1 ", "Composant 2 ", "Composant 3 "],
            available: true,
            stock: 20,
        },
        {
            id_category: 1,
            maxQuantity: 10,
            category: "BURGERS HOMEMADE",
            name: { isVisible: true, content: "LE DAJAJ" },
            price: { isVisible: true, content: 11.9 },
            calorie: { isVisible: false, content: "12" },
            description: {
                isVisible: true,
                content:
                    "A media query string ready to be used with most styling solutions, which matches screen widths",
            },
            image: "../images_order/PRFND358.png",

            basic_composition: ["Composant 1 ", "Composant 2 ", "Composant 3 "],
            available: true,
            stock: 20,
        },

        {
            id_category: 1,
            maxQuantity: 10,
            category: "BURGERS HOMEMADE",
            name: { isVisible: true, content: "LE CHEVRE" },
            price: { isVisible: true, content: 11.9 },
            calorie: { isVisible: false, content: "12" },
            description: {
                isVisible: true,
                content:
                    "A media query string ready to be used with most styling solutions, which matches screen widths",
            },
            image: "../images_order/PRFND359.png",

            basic_composition: ["Composant 1 ", "Composant 2 ", "Composant 3 "],
            available: true,
            stock: 20,
        },
        {
            id_category: 1,
            maxQuantity: 10,
            category: "BURGERS HOMEMADE",
            name: { isVisible: true, content: "LE FARMER" },
            price: { isVisible: true, content: 11 },
            calorie: { isVisible: false, content: "12" },
            description: {
                isVisible: true,
                content:
                    "A media query string ready to be used with most styling solutions, which matches screen widths",
            },
            image: "../images_order/PRFND357.png",

            basic_composition: ["Composant 1 ", "Composant 2 ", "Composant 3 "],
            available: true,
            stock: 20,
        },
        {
            id_category: 1,
            maxQuantity: 10,
            category: "BURGERS HOMEMADE",
            name: { isVisible: true, content: "L'AVOCADO" },
            price: { isVisible: true, content: 11.5 },
            calorie: { isVisible: false, content: "12" },
            description: {
                isVisible: true,
                content:
                    "A media query string ready to be used with most styling solutions, which matches screen widths",
            },
            image: "../images_order/PRFND360.png",

            basic_composition: ["Composant 1 ", "Composant 2 ", "Composant 3 "],
            available: true,
            stock: 20,
        },
        {
            id_category: 1,
            maxQuantity: 10,
            category: "BURGERS HOMEMADE",
            name: { isVisible: true, content: "LE BACON GRILL" },
            price: { isVisible: true, content: 11.5 },
            calorie: { isVisible: false, content: "12" },
            description: {
                isVisible: true,
                content:
                    "A media query string ready to be used with most styling solutions, which matches screen widths",
            },
            image: "../images_order/PRFND356.png",

            basic_composition: ["Composant 1 ", "Composant 2 ", "Composant 3 "],
            available: true,
            stock: 20,
        },
        /*/** PATES*/
        {
            id_category: 1,
            maxQuantity: 10,
            category: "PÂTES",
            name: { isVisible: true, content: "PATES PESTO" },
            price: { isVisible: true, content: 9 },
            calorie: { isVisible: false, content: "12" },
            description: {
                isVisible: true,
                content:
                    "A media query string ready to be used with most styling solutions, which matches screen widths",
            },
            image: "../images_order/PRFND626.png",

            basic_composition: ["Composant 1 ", "Composant 2 ", "Composant 3 "],
            available: true,
            stock: 20,
        },

        /** SALADES */
        {
            id_category: 1,
            maxQuantity: 10,
            category: "SALADES",
            name: { isVisible: true, content: "SALADE FRAÎCHEUR" },
            price: { isVisible: true, content: 9.5 },
            calorie: { isVisible: false, content: "12" },
            description: {
                isVisible: true,
                content:
                    "A media query string ready to be used with most styling solutions, which matches screen widths",
            },
            image: "../images_order/PRFND370.png",

            basic_composition: ["Composant 1 ", "Composant 2 ", "Composant 3 "],
            available: true,
            stock: 20,
        },

        /** Pizza */
        {
            id_category: 1,
            maxQuantity: 10,
            category: "PIZZAS",
            name: { isVisible: true, content: "PIZZA KEBAB" },
            price: { isVisible: true, content: 9.9 },
            calorie: { isVisible: false, content: "12" },
            description: {
                isVisible: true,
                content:
                    "A media query string ready to be used with most styling solutions, which matches screen widths",
            },
            image: "../images_order/PRFND720.png",

            basic_composition: ["Composant 1 ", "Composant 2 ", "Composant 3 "],
            available: true,
            stock: 20,
        },

        /** Sandwichs */
        {
            id_category: 1,
            maxQuantity: 10,
            category: "SANDWICHS",
            name: { isVisible: true, content: "MENU KEBAB" },
            price: { isVisible: true, content: 7 },
            calorie: { isVisible: false, content: "12" },
            description: {
                isVisible: true,
                content:
                    "A media query string ready to be used with most styling solutions, which matches screen widths",
            },
            image: "../images_order/PRFND375.png",

            basic_composition: ["Composant 1 ", "Composant 2 ", "Composant 3 "],
            available: true,
            stock: 20,
        },

        /** DESSERTS  */

        {
            id_category: 1,
            maxQuantity: 10,
            category: "DESSERTS",
            name: { isVisible: true, content: "TIRAMISU CHOCO NUT" },
            price: { isVisible: true, content: 2.5 },
            calorie: { isVisible: false, content: "12" },
            description: {
                isVisible: true,
                content:
                    "A media query string ready to be used with most styling solutions, which matches screen widths",
            },
            image: "../images_order/PRFND412.png",

            basic_composition: ["Composant 1 ", "Composant 2 ", "Composant 3 "],
            available: true,
            stock: 20,
        },

        /* BOISS FRAICHES*/
        {
            id_category: 1,
            maxQuantity: 10,
            category: "BOISSONS FRAICHES",
            name: { isVisible: true, content: "COCA-COLA CLASSIQUE 33CL" },
            price: { isVisible: true, content: 1.2 },
            calorie: { isVisible: false, content: "12" },
            description: {
                isVisible: true,
                content:
                    "A media query string ready to be used with most styling solutions, which matches screen widths",
            },
            image: "../images_order/PRFND430.png",

            basic_composition: ["Composant 1 ", "Composant 2 ", "Composant 3 "],
            available: true,
            stock: 20,
        },

        /* BOISSONS CHAUDES*/
        {
            id_category: 1,
            maxQuantity: 10,
            category: "BOISSONS CHAUDES",
            name: { isVisible: true, content: "ESPRESSO" },
            price: { isVisible: true, content: 1.6 },
            calorie: { isVisible: false, content: "12" },
            description: {
                isVisible: true,
                content:
                    "A media query string ready to be used with most styling solutions, which matches screen widths",
            },
            image: "../images_order/PRFND442.png",

            basic_composition: ["Composant 1 ", "Composant 2 ", "Composant 3 "],
            available: true,
            stock: 20,
        },
    ],
    allergens: {
        Divers: [
            { id: "1", name: "Oeufs", active: true, icon: "EggIcon" },
            { id: "2", name: "Poisson", active: false, icon: "FishIcon" },
            { id: "3", name: "Arachides", active: false, icon: "PenautIcon" },
            { id: "4", name: "Soja", active: false, icon: "SoyIcon" },
            { id: "5", name: "Lait", active: false, icon: "MilkIcon" },
            { id: "6", name: "Célerie", active: false, icon: "CeleryIcon" },
            { id: "7", name: "Lupin", active: false, icon: "LupinIcon" },
            {
                id: "8",
                name: "Graines de sésame",
                active: false,
                icon: "SesameSeedIcon",
            },
            {
                id: "9",
                name: "Moutarde",
                active: false,
                icon: "MustardIcon",
            },
            {
                id: "10",
                name: "Mollusques",
                active: false,
                icon: "MolluscsIcon",
            },
            {
                id: "11",
                name: "Crustacés",
                active: false,
                icon: "ShellfishIcon",
            },
            {
                id: "12",
                name: "Sulfites",
                active: false,
                icon: "SulphitesIcon",
            },
        ],
        "Fruits à coques": [
            { id: "13", name: "Amandes", active: true, icon: "NutsIcon" },
            { id: "14", name: "Noisettes", active: true, icon: "NutsIcon" },
            {
                id: "15",
                name: "Noix de Brésil",
                active: false,
                icon: "NutsIcon",
            },
        ],
        Gluten: [
            { id: "16", name: "Blé", active: false, icon: "GlutenIcon" },
            { id: "17", name: "Seigle", active: false, icon: "GlutenIcon" },
            { id: "18", name: "Orge", active: false, icon: "GlutenIcon" },
            { id: "19", name: "Avoine", active: true, icon: "GlutenIcon" },
        ],
    },
    basic_composition: {
        1: {
            key: "Tomato",
            name: "Tomate",
            image: "../images_order/tomato.png",
            isRequired: true,
        },
        2: {
            key: "Cheese",
            name: "Fromage",
            image: "../images_order/cheese.png",
            isRequired: true,
        },
        3: {
            key: "Onions",
            name: "Oignons",
            image: "../images_order/onions.png",
            isRequired: false,
        },
        4: {
            key: "Lettuce",
            name: "Laitue",
            image: "../images_order/lettuce.png",
            isRequired: false,
        },
        5: {
            key: "Pickles",
            name: "Cornichons",
            image: "../images_order/pickles.png",
            isRequired: false,
        },
    },
    workFlow: {
        title: "Plat du jour",
        step: [
            {
                id: 1,
                title: "Plat",
                image: "../images_order/PRFND339.png",
                isActive: true,
                isCompleted: false,
            },
            {
                id: 1,
                title: "Drinks",
                image: "../images_order/PRFND430.png",
                isActive: false,
                isCompleted: false,
            },
            {
                id: 1,
                title: "Desserts",
                image: "../images_order/PRFND412.png",
                isActive: false,
                isCompleted: false,
            },
        ],
        product: [
            {
                id_category: 1,
                maxQuantity: 10,
                category: "Plat",
                name: { isVisible: true, content: "POULET CRISPY THAI" },
                price: { isVisible: true, content: 11.9 },
                calorie: { isVisible: false, content: "12" },
                description: {
                    isVisible: true,
                    content:
                        "A media query string ready to be used with most styling solutions, which matches screen widths",
                },
                image: "../images_order/PRFND339.png",
                basic_composition: [1, 2, 3, 4, 5],
                available: true,
                stock: 20,
                allergens: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                ],
            },
            {
                id_category: 1,
                maxQuantity: 10,
                category: "Desserts",
                name: { isVisible: true, content: "TIRAMISU CHOCO NUT" },
                price: { isVisible: true, content: 2.5 },
                calorie: { isVisible: false, content: "12" },
                description: {
                    isVisible: true,
                    content:
                        "A media query string ready to be used with most styling solutions, which matches screen widths",
                },
                image: "../images_order/PRFND412.png",

                basic_composition: [
                    "Composant 1 ",
                    "Composant 2 ",
                    "Composant 3 ",
                ],
                available: true,
                stock: 20,
            },
            {
                id_category: 1,
                maxQuantity: 10,
                category: "Drinks",
                name: { isVisible: true, content: "COCA-COLA CLASSIQUE 33CL" },
                price: { isVisible: true, content: 1.2 },
                calorie: { isVisible: false, content: "12" },
                description: {
                    isVisible: true,
                    content:
                        "A media query string ready to be used with most styling solutions, which matches screen widths",
                },
                image: "../images_order/PRFND430.png",

                basic_composition: [
                    "Composant 1 ",
                    "Composant 2 ",
                    "Composant 3 ",
                ],
                available: true,
                stock: 20,
            },
        ],
    },
};
