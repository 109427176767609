import React from "react";
import { useSnapshot } from "valtio";
import classNames from "classnames";

import { designStore } from "@store";

import { truncateString } from "@helpers/general";

import { UsualNumericKeyboardWrapper } from "@components/Common/UsualNumericKeyboardWrapper";

export function MainContentPaymentPreviewRestoflash(): JSX.Element {
    const { isPaymentMessageActive, paymentInstructionsItem, activeTabIndex } =
        useSnapshot(designStore);

    return (
        <div
            className={classNames(
                "d-flex flex-column justify-content-center align-items-center",
                {
                    "border-wrapper__clz": activeTabIndex === 3,
                }
            )}
            style={{ width: "400px" }}
        >
            {isPaymentMessageActive === true ? (
                <div
                    className="d-flex justify-content-center align-items-center cursor__clz"
                    style={{
                        maxWidth: "400px",
                        height: "80px",
                        textAlign: "center",
                    }}
                >
                    {truncateString(paymentInstructionsItem.message, 50)}
                </div>
            ) : null}
            <div
                style={{
                    width: "237px",
                    height: "267px",
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                }}
            >
                <UsualNumericKeyboardWrapper />
            </div>
        </div>
    );
}
