import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { useTranslation } from "react-i18next/";
import { useSnapshot } from "valtio";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";

import { store } from "./Kiosk/store";
import { store as userStore } from "@components/VerticalLayout/store";
import {
    store as appStore,
    setIsAddAppClicked,
    setIsDeletePeripheralModalOpened,
    setGlobalDataIsUpdated,
    setIsMultiDeletePeripheralModalOpened,
    setIsActionsClicked,
    setActions,
    setValidationAction,
    setIsEdited,
} from "./store";
import { store as settingPrinter } from "../ApplicationSetting/store";
import { setDataTable } from "./PeripheralConfigure/MoneticPeripheralConfigure/store";
import { setSelectedMonnyeur } from "@pages/Peripherals/CashManagement/store";

import { UpdateResourceIp } from "@helpers/general";
import { AlertDeletePeripheralMessage } from "@components/Common/AlertDeletePeripheralMessage";
import PageTitle from "@components/Common/PageTitle";
import MessageInfo from "@components/Common/MessageInfo";
import ApplicationSetting from "@pages/ApplicationSetting";
import CashManagementSetting from "@pages/ApplicationSetting/CashManagementSetting";
import { ConfirmationDeleteMessage } from "@components/Common/ConfirmationDeleteMessage";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import { ConfirmationMultiDeleteMessage } from "@components/Common/ConfirmationMultiDeleteMessage";
import { useCallbackPrompt } from "@hooks/useCallbackPrompt";
import { ConfirmationUpdatingDataMessageModal } from "@components/Common/ConfirmationUpdatingDataMessageModal";
import { getPathAuthorization } from "@components/VerticalLayout";
import { useLocation } from "react-router-dom";
import { setModify } from "./store";

import { ApplicationConfigure } from "./ApplicationConfigure";
import { PeripheralConfiguration } from "./PeripheralConfigure";
import { DropdownConfigure } from "./DropdownConfigure";
import DeviceAssociateModal from "./DeviceAssociateModal";
import { MoneticValinaSetting } from "./ApplicationSettingMonetic/MoneticValinaSetting";
import AddApplication from "./AddApplication";
import ApplicationAssociateModal from "./ApplicationAssociateModal";
import { CustomIcons } from "./CustomIcons";
import { DropdownStatus } from "./DropdownStatus";
import { ApplicationHistory } from "./History/ApplicationHistory";
import { MoneticsHistory } from "@pages/Peripherals/Monetics/MoneticsHistory";
import HistoryModal from "@pages/Peripherals/CashManagement/HistoryModal";
import BadgeNumber from "@components/Common/BadgeNumber";
import OriginHistory from "@pages/Peripherals/Printers/OriginHistory";
import { ActionColumn } from "./ActionColumn";

import "react-toastify/dist/ReactToastify.css";
import "./index.css";

type CustomColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
};

export default function Applications() {
    const { t } = useTranslation();
    const location = useLocation();
    setModify(getPathAuthorization(location.pathname).Modifie);
    const { oneShop, userID, franchiseID, shopID, operatorID } = useSnapshot(
        userStore
    );
    const {
        isDeletePeripheralModalOpened,
        isAddAppClicked,
        isAddPeripheralClicked,
        globalDataIsUpdated,
        isMultiDeletePeripheralModalOpened,
        isEdited,
        actions,
        validationAction,
        isActionsClicked,
        modify,
    } = useSnapshot(appStore);
    const { displayMode } = useSnapshot(store);
    const { isValidated } = useSnapshot(settingPrinter);
    const [isConsult, setIsConsult] = React.useState<boolean>(false);
    const appId = "KIOSK";

    const [isHistoryModalOpened, setIsHistoryModalOpened] = useState(false);
    const [
        isHistoryModalCashMangmentOpened,
        setIsHistoryModalCashMangmentOpened,
    ] = useState(false);
    const [
        isHistoryMoneticsModalOpened,
        setIsHistoryMoneticsModalOpened,
    ] = useState(false);
    const [isAddNewAppButtonClicked, setIsAddNewAppButtonClicked] = useState(
        false
    );
    const [urlHistoryPrinter, setUrlHistoryPrinter] = useState<string>(``);

    const [
        isAddPrinterOriginHistoryClicked,
        setIsAddPrinterOriginHistoryClicked,
    ] = React.useState<boolean>(false);
    const [
        isInitializationModalOpened,
        setIsInitializationModalOpened,
    ] = useState(false);
    const [isModalOpened, setIsModalOpened] = useState(false);
    const [
        isMoneticsPathsModalOpened,
        setIsMoneticsPathsModalOpened,
    ] = useState(false);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        isEdited
    );
    const [applicationChildrenId, setApplicationChildrenId] = React.useState<
        string
    >("");
    const [selectedIds, setSelectedIds] = React.useState<any>([]);
    const [ListNumberOrigin, setListNumberOrigin] = React.useState<any>({
        nbrNotArchived: 0,
        nbrArchived: 0,
    });
    const [typeDisplay, setTypeDisplay] = React.useState<string>("notArchived");
    const [shopId, setShopId] = React.useState<number>();
    const [peripheralId, setPeripheralId] = React.useState<string>("");
    const [data, setData] = React.useState<any>({});
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [isAssociateModalOpened, setIsAssociateModalOpened] = React.useState<
        boolean
    >(false);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const [
        isConfirmationEditModalOpened,
        setIsConfirmationEditModalOpened,
    ] = React.useState<boolean>(false);

    const { isConfigurationCashManagementClicked } = useSnapshot(store);
    const [rowData, setRowData] = useState<any>({});
    const [apiUrl, setApiUrl] = useState<any>("");
    const [textModal, setTextModal] = useState<any>("");
    const [peripheralUrl, setPeripheralUrl] = useState<string>(
        oneShop
            ? `/settings/applications/list?userId=${userID}&franchiseId=${franchiseID}&appId=${appId}&shopId=${shopID}&typeDisplay=${typeDisplay}`
            : `/settings/applications?userId=${userID}&franchiseId=${franchiseID}&appId=${appId}&typeDisplay=${typeDisplay}`
    );
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [editedData, setEditedData] = React.useState<any>([]);
    const [isComingFromModify, setIsComingFromModify] = React.useState<boolean>(
        false
    );
    const [inputDesignation, setInputDesignation] = React.useState<boolean>(
        false
    );
    const [
        isModalDeleteAssociatePeripheralOpened,
        setIsModalDeleteAssociatePeripheralOpened,
    ] = React.useState<boolean>(false);

    let arrayOfCustomColumns: CustomColumnProps[] = [
        {
            indexOFColumn: oneShop ? 1 : 2,
            columnName:
                displayMode === t("View by device")
                    ? t("Peripheral")
                    : t("Application"),
            customJsx: (e: any) => {
                return displayMode === t("View by device") ? (
                    oneShop ? (
                        e.selectedRow[t("Peripheral")]
                    ) : e.selectedRow[t("Shop")] !== "" ? (
                        <BadgeNumber value={e.selectedRow.subRows?.length} />
                    ) : (
                        e.selectedRow[t("Peripheral")]
                    )
                ) : oneShop ? (
                    e.selectedRow[t("Application")]
                ) : e.selectedRow[t("Shop")] !== "" ? (
                    <BadgeNumber value={e.selectedRow.subRows?.length} />
                ) : (
                    e.selectedRow[t("Application")]
                );
            },
        },
        {
            indexOFColumn: oneShop ? 2 : 3,
            columnName:
                displayMode !== t("View by device")
                    ? t("Peripheral")
                    : t("Application"),
            customJsx: (e: any) => {
                return displayMode !== t("View by device")
                    ? e.selectedRow[t("Peripheral")] !== undefined
                        ? e.selectedRow[t("Peripheral")]
                        : ""
                    : e.selectedRow[t("Application")];
            },
        },
        {
            indexOFColumn: 997,
            columnName: t("Status"),
            customJsx: (e: any) => {
                return e.selectedRow[t("Application")] !== "" ? (
                    <DropdownStatus state="In line" typeDisplay={typeDisplay} />
                ) : e.selectedRow[t("Peripheral")] !== "" ? (
                    <DropdownStatus state="In line" typeDisplay={typeDisplay} />
                ) : null;
            },
        },
        {
            indexOFColumn: 998,
            columnName: t("Setup status"),
            customJsx: (e: any) => {
                return e.selectedRow[t("Application")] !== "" ? (
                    <DropdownConfigure
                        state={e.selectedRow[t("State")]}
                        typeDisplay={typeDisplay}
                    />
                ) : e.selectedRow[t("Peripheral")] !== "" ? (
                    <DropdownConfigure
                        state={e.selectedRow[t("State")]}
                        typeDisplay={typeDisplay}
                    />
                ) : null;
            },
        },
        {
            indexOFColumn: 999,
            columnName: t("Actions"),
            customJsx: (e: any) => {
                return (
                    <CustomIcons
                        e={e}
                        setShopId={setShopId}
                        setRowData={setRowData}
                        setPeripheralId={setPeripheralId}
                        setIsModalOpened={setIsModalOpened}
                        isModalOpened={isModalOpened}
                        setIsMoneticsPathsModalOpened={
                            setIsMoneticsPathsModalOpened
                        }
                        isMoneticsPathsModalOpened={isMoneticsPathsModalOpened}
                        setIsAssociateModalOpened={setIsAssociateModalOpened}
                        isAssociateModalOpened={isAssociateModalOpened}
                        setIsDeletePeripheralModalOpened={
                            setIsDeletePeripheralModalOpened
                        }
                        setApplicationChildrenId={setApplicationChildrenId}
                        setApiUrl={setApiUrl}
                        isConfigurationCashManagementClicked={
                            isConfigurationCashManagementClicked
                        }
                        setIsModalDeleteAssociatePeripheralOpened={
                            setIsModalDeleteAssociatePeripheralOpened
                        }
                        setTextModal={setTextModal}
                        typeDisplay={typeDisplay}
                        setSelectedRows={setSelectedRows}
                        setIsHistoryModalOpened={setIsHistoryModalOpened}
                        setIsConsult={setIsConsult}
                        setEditedData={setEditedData}
                        setIsComingFromModify={setIsComingFromModify}
                        setIsHistoryMoneticsModalOpened={
                            setIsHistoryMoneticsModalOpened
                        }
                        setIsHistoryModalCashMangmentOpened={
                            setIsHistoryModalCashMangmentOpened
                        }
                        setUrlHistoryPrinter={setUrlHistoryPrinter}
                        setIsAddPrinterOriginHistoryClicked={
                            setIsAddPrinterOriginHistoryClicked
                        }
                    />
                );
            },
        },
    ];

    React.useEffect(() => {
        if (oneShop) {
            if (displayMode === t("View by device")) {
                setPeripheralUrl(
                    `/settings/applications/oneShop/devices?userId=${userID}&franchiseId=${franchiseID}&appId=${appId}&shopId=${shopID}&typeDisplay=${typeDisplay}`
                );
            } else {
                setPeripheralUrl(
                    `/settings/applications/list?userId=${userID}&franchiseId=${franchiseID}&appId=${appId}&shopId=${shopID}&typeDisplay=${typeDisplay}`
                );
            }
        } else {
            if (displayMode === t("View by device")) {
                setPeripheralUrl(
                    `/settings/applications/devices?userId=${userID}&franchiseId=${franchiseID}&appId=${appId}&typeDisplay=${typeDisplay}`
                );
            } else {
                setPeripheralUrl(
                    `/settings/applications?userId=${userID}&franchiseId=${franchiseID}&appId=${appId}&typeDisplay=${typeDisplay}`
                );
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        displayMode,
        oneShop,
        franchiseID,
        shopID,
        t,
        userID,
        isValidated,
        typeDisplay,
    ]);
    React.useEffect(() => {
        setGlobalDataIsUpdated(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18nextLng]);
    React.useEffect(() => {
        setDataTable(data.data);
        setListNumberOrigin(data?.othersData?.ListNumberOrigin);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    React.useEffect(() => {
        UpdateResourceIp(userID, franchiseID);
        // eslint-disable-next-line
    }, [globalDataIsUpdated]);

    React.useEffect(() => {
        let ids: any[] = [];
        selectedRows.forEach((el: any) => {
            if (
                displayMode === t("View by device") &&
                el.subRows === undefined
            ) {
                ids.push({
                    Id: el[t("Application")],
                    isArchived:
                        typeDisplay === "notArchived" ? "true" : "false",
                    shopId: el.shopId,
                    userID: userID,
                    franchiseID: franchiseID,
                });
            } else if (
                displayMode !== t("View by device") &&
                (el[t("Shop")] === "" || el[t("Shop")] === undefined) &&
                (!oneShop || (oneShop && el.subRows !== undefined))
            ) {
                ids.push({
                    Id: el[t("Application")],
                    isArchived:
                        typeDisplay === "notArchived" ? "true" : "false",
                    shopId: el.shopId,
                    userID: userID,
                    franchiseID: franchiseID,
                    operatorId: operatorID,
                });
            } else if (
                oneShop &&
                el.subRows === undefined &&
                displayMode !== t("View by device") &&
                el[t("Application")] === ""
            ) {
                ids.push({
                    Id: el[t("Application")],
                    isArchived:
                        typeDisplay === "notArchived" ? "true" : "false",
                    shopId: shopID,
                    userID: userID,
                    franchiseID: franchiseID,
                    operatorId: operatorID,
                });
            } else if (
                oneShop &&
                el.subRows === undefined &&
                displayMode !== t("View by device") &&
                el[t("Peripheral")] === ""
            ) {
                ids.push({
                    Id: el[t("Peripheral")],
                    isArchived:
                        typeDisplay === "notArchived" ? "true" : "false",
                    shopId: shopID,
                    userID: userID,
                    franchiseID: franchiseID,
                    operatorId: operatorID,
                });
            }
        });
        let array: any[] = [];
        setSelectedIds(ids);

        selectedRows.forEach((element: any) => {
            array.push({ [element.shopId]: element.peripheralId });
        });
        setSelectedMonnyeur(array);
        // eslint-disable-next-line
    }, [selectedRows]);

    React.useEffect(() => {
        setActions(confirmNavigation);
        setIsActionsClicked(showPrompt as boolean);
        setValidationAction(cancelNavigation);
    }, [showPrompt, confirmNavigation, cancelNavigation]);

    return (
        <React.Fragment>
            <PageTitle title={t("Applications")} />
            <div className="page-content__clz">
                <StyledBreadcrumb
                    items={[
                        {
                            item: "ETK Settings".toUpperCase(),
                            link: "https://dev-portail.aureskonnect.com",
                        },
                        {
                            item:
                                localStorage
                                    .getItem("selectedAccount")
                                    ?.toUpperCase() || "",
                            link: "/compte",
                        },
                        {
                            item: t("Application").toUpperCase(),
                            link: "applications",
                        },
                        {
                            item: t("KIOSK").toUpperCase(),
                            link: "kiosk",
                        },
                        {
                            item: t(
                                typeDisplay === "notArchived"
                                    ? "Peripheral setting"
                                    : "Archived peripheral setting"
                            ).toUpperCase(),
                            link: "#",
                        },
                    ]}
                />
                <MessageInfo message={t("Please set your application")} />

                <div
                    style={{
                        display: "grid",
                        minHeight: "650px",
                        gridTemplateColumns: isAddAppClicked
                            ? "2fr 1fr "
                            : "auto",
                        gridColumnGap: "4px",
                        border: 0,
                    }}
                >
                    <DynamicTable
                        url={`${process.env.REACT_APP_API_V2_URL}${peripheralUrl}`}
                        name={displayMode}
                        requestHeader={{ "Accept-Language": i18nextLng }}
                        customJsxSideFilterButton={
                            <AddApplication
                                setIsComingFromModify={setIsComingFromModify}
                                isComingFromModify={isComingFromModify}
                                isAddAppClicked={isAddAppClicked}
                                setIsAddAppClicked={setIsAddAppClicked}
                                selectedRows={selectedRows}
                                isEdited={isEdited}
                                setIsInitializationModalOpened={
                                    setIsInitializationModalOpened
                                }
                                isAddPeripheralClicked={isAddPeripheralClicked}
                                oneShop={oneShop}
                                setDataIsUpdated={setGlobalDataIsUpdated}
                                setIsAddNewAppButtonClicked={
                                    setIsAddNewAppButtonClicked
                                }
                                setInputDesignation={setInputDesignation}
                                typeDisplay={typeDisplay}
                                setTypeDisplay={setTypeDisplay}
                                selectedIds={selectedIds}
                                ListNumberOrigin={ListNumberOrigin}
                                setIsHistoryModalOpened={
                                    setIsHistoryModalOpened
                                }
                            />
                        }
                        setDataIsUpdated={
                            setGlobalDataIsUpdated as
                                | React.Dispatch<
                                      React.SetStateAction<number | boolean>
                                  >
                                | undefined
                        }
                        dataIsUpdated={globalDataIsUpdated}
                        canResize
                        actionColumn={(e: any) =>
                            modify === true ? (
                                <>
                                    <ActionColumn
                                        setIsComingFromModify={
                                            setIsComingFromModify
                                        }
                                        setEditedData={setEditedData}
                                        e={e}
                                        displayMode={displayMode}
                                        typeDisplay={typeDisplay}
                                    />
                                </>
                            ) : null
                        }
                        setData={setData}
                        showGlobalFilter
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        filterActive={filterActive}
                        setLocalFilterActive={setLocalFilterActive}
                        elevationTable={1}
                        minHeight="500px"
                        maxHeight="500px"
                        canExpand={true}
                        canSelect={true}
                        customSelect
                        setSelectedRows={setSelectedRows}
                        canMovedCheckboxLeftOnExpand
                    />

                    {displayMode !== t("View by device") && isAddAppClicked ? (
                        <ApplicationConfigure
                            setIsAddAppClicked={setIsAddAppClicked!}
                            isAddAppClicked={isAddAppClicked}
                            setDataIsUpdated={setGlobalDataIsUpdated}
                            isAddNewAppButtonClicked={isAddNewAppButtonClicked}
                            selectedRows={
                                !isComingFromModify
                                    ? selectedRows[0]
                                    : editedData
                            }
                            isComingFromModify={isComingFromModify}
                            inputDesignation={inputDesignation}
                            setInputDesignation={setInputDesignation}
                            setIsConfirmationEditModalOpened={
                                setIsConfirmationEditModalOpened
                            }
                            data={data}
                            isConsult={isConsult}
                            setIsConsult={setIsConsult}
                        />
                    ) : displayMode === t("View by device") &&
                      isAddAppClicked ? (
                        <PeripheralConfiguration
                            setIsAddPeripheralClicked={setIsAddAppClicked!}
                            isAddPeripheralClicked={isAddAppClicked}
                            setDataIsUpdated={setGlobalDataIsUpdated}
                            isAddNewPeripheralButtonClicked={
                                isAddNewAppButtonClicked
                            }
                            selectedRows={
                                !isComingFromModify
                                    ? selectedRows[0]
                                    : editedData
                            }
                            isComingFromModify={isComingFromModify}
                            setEditedData={setEditedData}
                            setIsEdited={setIsEdited}
                            isEdited={isEdited}
                            editedData={editedData}
                        />
                    ) : null}

                    <ToastContainer limit={1} />
                    <ApplicationSetting rowData={rowData} oneShop={oneShop} />

                    <CashManagementSetting
                        rowData={rowData}
                        setRowData={setRowData}
                        oneShop={oneShop}
                        setDataIsUpdated={setGlobalDataIsUpdated}
                    />
                </div>
            </div>
            {displayMode !== t("View by device") ? (
                <DeviceAssociateModal
                    setIsDeviceAssociateModalOpened={setIsAssociateModalOpened}
                    isDeviceAssociateModalOpened={isAssociateModalOpened}
                    rowData={rowData}
                    dataIsUpdated={globalDataIsUpdated}
                    setDataIsUpdated={setGlobalDataIsUpdated}
                    oneShop={oneShop}
                />
            ) : (
                <ApplicationAssociateModal
                    setIsDeviceAssociateModalOpened={setIsAssociateModalOpened}
                    isDeviceAssociateModalOpened={isAssociateModalOpened}
                    rowData={rowData}
                    dataIsUpdated={globalDataIsUpdated}
                    setDataIsUpdated={setGlobalDataIsUpdated}
                />
            )}
            {isMultiDeletePeripheralModalOpened === true && (
                <ConfirmationMultiDeleteMessage
                    isModalOpened={isMultiDeletePeripheralModalOpened}
                    setIsModalOpened={setIsMultiDeletePeripheralModalOpened}
                />
            )}
            {isModalDeleteAssociatePeripheralOpened === true ? (
                <AlertDeletePeripheralMessage
                    text={textModal}
                    isModalDeleteAssociatePeripheralOpened={
                        isModalDeleteAssociatePeripheralOpened
                    }
                    setIsModalDeleteAssociatePeripheralOpened={
                        setIsModalDeleteAssociatePeripheralOpened
                    }
                />
            ) : oneShop === true ? (
                <ConfirmationDeleteMessage
                    isModalOpened={isModalOpened}
                    setIsModalOpened={setIsModalOpened}
                    setDataIsUpdated={setGlobalDataIsUpdated}
                    apiUrl={apiUrl}
                    text={
                        isDeletePeripheralModalOpened === true ? (
                            displayMode !== t("View by device") ? (
                                t("Please confirm unpairing this device")
                            ) : rowData.subRows !== undefined &&
                              rowData.subRows.length > 0 ? (
                                <div className="d-flex flex-column">
                                    <span>
                                        {t(
                                            "This device contains associated applications"
                                        )}
                                        !
                                    </span>
                                    <span>
                                        {t(
                                            "Please confirm archiving of this device"
                                        )}
                                    </span>
                                </div>
                            ) : (
                                t("Please confirm archiving of this device")
                            )
                        ) : typeDisplay === "notArchived" ? (
                            t(
                                "Do you want to confirm the archiving of the selected application?"
                            )
                        ) : (
                            t(
                                "Do you want to confirm the unarchiving of the selected application?"
                            )
                        )
                    }
                    message={
                        typeDisplay === "notArchived"
                            ? displayMode === t("View by device")
                                ? t("The device was successfully archived")
                                : t(
                                      "This application was successfully archived"
                                  )
                            : displayMode === t("View by device")
                            ? t("The device was successfully unarchived")
                            : t("This application was successfully unarchived")
                    }
                    data={{
                        userId: userID,
                        shopId: shopId,
                        franchiseId: franchiseID,
                        appId: appId,
                        appId_children: [applicationChildrenId],
                        peripheralId: peripheralId,
                        peripheralName:
                            displayMode === t("View by device")
                                ? rowData.peripheral
                                : rowData[t("Device")],
                        operatorId: operatorID,
                        isArchived:
                            typeDisplay === "notArchived" ? true : false,
                    }}
                />
            ) : (
                <ConfirmationDeleteMessage
                    isModalOpened={isModalOpened}
                    setIsModalOpened={setIsModalOpened}
                    setDataIsUpdated={setGlobalDataIsUpdated}
                    apiUrl={apiUrl}
                    message={
                        isDeletePeripheralModalOpened === true
                            ? typeDisplay === "notArchived"
                                ? t("This device was successfully archived")
                                : t("This device was successfully unarchived")
                            : typeDisplay === "notArchived"
                            ? t("This application was successfully archived")
                            : t("This application was successfully unarchived")
                    }
                    text={
                        isDeletePeripheralModalOpened === true ? (
                            displayMode !== t("View by device") ? (
                                t("Please confirm unpairing this device")
                            ) : rowData.subRows !== undefined &&
                              rowData.subRows.length > 0 ? (
                                <div className="d-flex flex-column">
                                    <span>
                                        {t(
                                            "This device contains associated applications"
                                        )}
                                        !
                                    </span>
                                    <span>
                                        {t(
                                            "Please confirm deletion of this device"
                                        )}
                                    </span>
                                </div>
                            ) : (
                                t("Please confirm deletion of this device")
                            )
                        ) : rowData.subRows !== undefined &&
                          rowData.subRows.length > 0 ? (
                            <div className="d-flex flex-column">
                                <span>
                                    {t(
                                        "This application contains associated devices!"
                                    )}
                                </span>
                                <span>
                                    {t(
                                        typeDisplay === "notArchived"
                                            ? "Do you want to confirm the archiving of the selected application?"
                                            : "Do you want to confirm the unarchiving of the selected application?"
                                    )}
                                </span>
                            </div>
                        ) : displayMode !== t("View by device") ? (
                            t(
                                typeDisplay === "notArchived"
                                    ? "Do you want to confirm the archiving of the selected application?"
                                    : "Do you want to confirm the unarchiving of the selected application?"
                            )
                        ) : (
                            t("Please confirm unpairing of the application")
                        )
                    }
                    data={{
                        userId: userID,
                        shopId: shopId,
                        franchiseId: franchiseID,
                        appId: appId,
                        appId_children:
                            applicationChildrenId !== ""
                                ? typeof applicationChildrenId === "string"
                                    ? [applicationChildrenId]
                                    : [...applicationChildrenId]
                                : [],
                        peripheralId: peripheralId,
                        peripheralName:
                            displayMode === t("View by device")
                                ? rowData.peripheral
                                : rowData[t("Device")],
                        operatorId: operatorID,
                        isArchived:
                            typeDisplay === "notArchived" ? true : false,
                    }}
                />
            )}

            <MoneticValinaSetting
                data={rowData}
                infoData={{
                    userId: userID,
                    shopId: shopId,
                    franchiseId: franchiseID,
                    appId: appId,
                    appId_children: applicationChildrenId,
                }}
                setIsMoneticsPathsModalOpened={setIsMoneticsPathsModalOpened}
                isMoneticsPathsModalOpened={isMoneticsPathsModalOpened}
                setDataIsUpdated={setGlobalDataIsUpdated}
            />
            {isInitializationModalOpened === true ? (
                <ConfirmationInitializationMessage
                    isModalOpened={isInitializationModalOpened}
                    setIsModalOpened={setIsInitializationModalOpened}
                    setIsClicked={setIsAddNewAppButtonClicked}
                    isClicked={isAddNewAppButtonClicked}
                    setIsEdited={setIsEdited}
                />
            ) : null}
            {isConfirmationEditModalOpened ? (
                <ConfirmationEditMessage
                    setDataIsUpdated={setGlobalDataIsUpdated}
                    isModalOpened={isConfirmationEditModalOpened}
                    setIsModalOpened={setIsConfirmationEditModalOpened}
                    isAddClicked={isAddAppClicked}
                    setIsAddClicked={setIsAddAppClicked}
                />
            ) : null}
            {isHistoryModalOpened ? (
                <ApplicationHistory
                    selectedRows={selectedRows}
                    isHistoryModalOpened={isHistoryModalOpened}
                    setIsHistoryModalOpened={setIsHistoryModalOpened}
                    setDataShopIsUpdated={setGlobalDataIsUpdated}
                    displayMode={displayMode}
                />
            ) : null}
            {isHistoryMoneticsModalOpened ? (
                <MoneticsHistory
                    selectedRows={selectedRows}
                    isHistoryModalOpened={isHistoryMoneticsModalOpened}
                    setIsHistoryModalOpened={setIsHistoryMoneticsModalOpened}
                    setGlobalDataUpdated={setGlobalDataIsUpdated}
                    displayMode={displayMode}
                />
            ) : null}
            {isHistoryModalCashMangmentOpened ? (
                <HistoryModal
                    isModalOpened={isHistoryModalCashMangmentOpened}
                    setIsModalOpened={setIsHistoryModalCashMangmentOpened}
                    setGlobalDataUpdated={setGlobalDataIsUpdated}
                />
            ) : null}
            {isAddPrinterOriginHistoryClicked && (
                <OriginHistory
                    url={urlHistoryPrinter}
                    setIsModalOpened={setIsAddPrinterOriginHistoryClicked}
                    isModalOpened={isAddPrinterOriginHistoryClicked}
                />
            )}
            {isActionsClicked === true ? (
                <ConfirmationUpdatingDataMessageModal
                    isModalOpened={isActionsClicked}
                    setIsModalOpened={setIsActionsClicked}
                    validationAction={validationAction}
                    actions={actions}
                    setIsEdited={setIsEdited}
                />
            ) : null}
        </React.Fragment>
    );
}
