import React from "react";
import { setAdvertisingAnimation } from "../../store";

import AdvertisingAnimationTable from "./ActiveStateScreenTable";
import { ActivateStateScreenPreview } from "./ActivateStateScreenPreview";

export function AdvertisingAnimation({ rowData }: any) {
    const [activeStateScreenItems, setActiveStateScreenItems] = React.useState<
        any
    >(
        Object.values(rowData.template).length === 0
            ? []
            : rowData.template.advertisingAnimation.activeStateScreenItems
    );

    React.useEffect(() => {
        setAdvertisingAnimation(
            JSON.parse(
                JSON.stringify({
                    activeStateScreenItems: activeStateScreenItems,
                })
            )
        );
        // eslint-disable-next-line
    }, [activeStateScreenItems]);
    return (
        <div
            className="d-grid__clz"
            style={{
                gridTemplateColumns: "1.6fr 0.6fr",
                gap: "10px",
            }}
        >
            <AdvertisingAnimationTable
                activeStateScreenItems={activeStateScreenItems}
                setActiveStateScreenItems={setActiveStateScreenItems}
            />
            <ActivateStateScreenPreview
                activeStateScreenItems={activeStateScreenItems}
            />
        </div>
    );
}
