import React from "react";
import { useTranslation } from "react-i18next";
import { CheckboxIcon } from "@aureskonnect/react-ui";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";
import moment from "moment";

import { Modal, ModalHeader, ModalBody } from "reactstrap";

import { store as userStore } from "@components/VerticalLayout/store";
import {
    generalConfigStore,
    setChosenTemplate,
    setInitItemsFromGeneralSetting,
    setIsInformationModesEmpty,
    setProject,
} from "@store";
import { setIsLoading } from "../../../store/project";

import { FlexboxGrid } from "@components/Common/FlexboxGrid";
import { setDataIsUpdated } from "@pages/SettingGeneral/VatRate/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type CardFooterListShopType = {
    dataListShops: any;
    rowData: any;
    setIsModalAssociateStore: Function;
    isModalAssociateStore: boolean;
    setIsDataUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    setLimit: Function;
};

export default function CardFooterListShop({
    dataListShops,
    rowData,
    setIsModalAssociateStore,
    isModalAssociateStore,
    setIsDataUpdated,
}: CardFooterListShopType) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID } = useSnapshot(userStore);
    const { project } = useSnapshot(generalConfigStore);
    const [isAlertModalOpened, setIsAlertModalOpened] = React.useState<boolean>(
        false
    );

    function alertToggle() {
        setIsAlertModalOpened(!isAlertModalOpened);
    }
    let newProject = { ...project };

    async function handleValidateButtonClickEvent(data: any) {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/projects/associate/shops`;
        let succes: boolean = false;
        let error: boolean = false;
        let savedData = {
            userId: userID,
            franchiseId: franchiseID,
            project_id: "PROJECT",
            projectId: rowData.projectId,
            operatorId: operatorID,
            data: data,
        };

        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(savedData),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then(async (data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                        for (let el of dataListShops) {
                            const getSalesMethodUrl: Response = await fetch(
                                `${process.env.REACT_APP_API_V2_URL}/settings/consomation/sale?franchiseId=${franchiseID}&shopId=${el.id_boutique}`
                            );
                            const saleMethods = await getSalesMethodUrl.json();

                            if (
                                saleMethods.filter(
                                    (item: any) => item.active === true
                                ).length === 0
                            ) {
                                error = true;
                            } else {
                                succes = true;
                            }
                        }
                        if (error === true) {
                            toast.warning(
                                `${t(
                                    `Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings.`
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                        }
                        if (succes === true) {
                            toast.success(
                                `${t("Store assignment was successful")!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                        }
                        setIsDataUpdated(true);
                        setIsLoading(false);
                    })
            );
        } catch (e: any) {
            toast.error(`${t("Mistake ! Please try again")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function handleSaveProjectsButtonOnClickEvent(
        localData: any,
        apiUrl: any
    ) {
        try {
            mutate(
                apiUrl,
                await Promise.all(localData).then(async (result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                    }
                })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function addTemplateOfApplication() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/template`;
        let shopId: any;
        let savedData: any = {
            userId: userID,
            franchiseId: franchiseID,
            appId: "KIOSK",
            shopId: [],
            data: [rowData.projectId],
            operatorId: operatorID,
        };
        dataListShops.forEach(async (el: any) => {
            shopId = el.id_boutique;
            savedData.shopId.push(shopId);
        });
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(savedData),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                    })
            );
        } catch (e: any) {
            toast.error(`${t("Mistake ! Please try again")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function getDataFromGeneralSetting(shopId: string) {
        let template: any;
        const getLanguagesUrl: Response = await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/consomation/langue?franchiseId=${franchiseID}&shopId=${shopId}`
        );
        const languages = await getLanguagesUrl.json();
        const getReglementUrl: Response = await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/consomation/reglement?franchiseId=${franchiseID}&shopId=${shopId}`
        );
        const reglements = await getReglementUrl.json();

        const getInformationMethodUrl: Response = await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/consomation/information?franchiseId=${franchiseID}&shopId=${shopId}`
        );

        const getSalesMethodUrl: Response = await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/consomation/sale?franchiseId=${franchiseID}&shopId=${shopId}`
        );

        const getLogoUrl: Response = await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/consomation/logo?franchiseId=${franchiseID}&shopId=${shopId}`
        );

        const informationModes = await getInformationMethodUrl.json();
        const saleMethods = await getSalesMethodUrl.json();
        const logo = await getLogoUrl.json();

        JSON.parse(
            JSON.stringify(rowData.template.content.salesMethods)
        ).items.forEach((element: any) => {
            saleMethods.forEach((el: any) => {
                if (el.id === element.id) {
                    el.active = element.active;
                    el.languages = element.languages;
                }
            });
        });
        JSON.parse(
            JSON.stringify(rowData.template.content.languages)
        ).items.forEach((element: any) => {
            languages.forEach((el: any) => {
                if (el.id === element.id) {
                    el.active = element.active;
                }
            });
        });

        JSON.parse(
            JSON.stringify(rowData.template.content.informationModes)
        ).items.forEach((element: any) => {
            informationModes.forEach((el: any) => {
                if (el.id === element.id) {
                    el.active = element.active;
                    el.languages = element.languages;
                }
            });
        });
        JSON.parse(
            JSON.stringify(rowData.template.content.meansOfPayment)
        ).items.forEach((element: any) => {
            reglements.forEach((el: any) => {
                if (el.id === element.id) {
                    el.active = element.active;
                    el.languages = element.languages;
                }
            });
        });
        if (
            informationModes.length === 0 ||
            informationModes.filter((item: ProjectMainContentItemType) => {
                return saleMethods.filter((saleMethod: any) => {
                    return (
                        Object.keys(item.active).includes(saleMethod.name) &&
                        saleMethod.active === true
                    );
                });
            }).length === 0
        ) {
            setIsInformationModesEmpty(true);
        } else {
            setIsInformationModesEmpty(false);
        }
        if (
            saleMethods.filter((item: any) => item.active === true).length === 0
        ) {
            setIsAlertModalOpened(!isAlertModalOpened);
            return;
        } else {
            template = {
                ...project.template,
                content: {
                    ...project.template.content,
                    languages: {
                        ...project.template.content.languages,
                        items: languages,
                    },
                    salesMethods: {
                        items: saleMethods,
                    },
                    meansOfPayment: {
                        items: reglements,
                    },
                    informationModes: {
                        items: informationModes,
                    },
                    generalDesign: {
                        ...project.template.content.generalDesign,
                        logo,
                    },
                },
            };

            let files = rowData.files;
            setProject({ ...rowData, template, files });
            setInitItemsFromGeneralSetting({
                informationModes,
                saleMethods,
                reglements,
                languages,
                logo,
            });

            setChosenTemplate(template, files);
            let project1 = { ...rowData, template };
            return project1;
        }
    }

    async function handleValidate() {
        setIsLoading(true);
        if (newProject.template.id !== "") {
            let data: any = {};
            const dataItems: Record<string, any> = {};
            let localData: any = [];
            dataItems[rowData.projectId] = {
                ...newProject,
                projectId: rowData.projectId,
                note: rowData[t("Note")],
                designation: rowData[t("Designation")],
            };
            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project`;

            for (let el of dataListShops) {
                let shopProject: any = await getDataFromGeneralSetting(
                    el.id_boutique
                );
                if (shopProject !== undefined && isAlertModalOpened === false) {
                    data[el.id_boutique] = {
                        designation: el[t("Shop")],
                        id_boutique: el.id_boutique,
                        template: shopProject.template,
                        files: shopProject.files,
                        hoursOfUse: "",
                    };
                    let savedData: any = {
                        userId: userID,
                        shopId: el.id_boutique,
                        franchiseId: franchiseID,
                        type: "Add",
                        operatorId: operatorID,
                        data: {
                            PROJECT: {
                                id_project: "PROJECT",
                                data_project: {
                                    [rowData.projectId]: {
                                        ...shopProject,
                                        projectId: rowData.projectId,
                                        note: rowData[t("Note")],
                                        designation: rowData[t("Designation")],
                                        status: rowData["Status"],
                                    },
                                },
                            },
                        },
                    };
                    localData.push(
                        fetch(apiUrl, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                authorization: `Bareer ${localStorage.getItem(
                                    "jwt"
                                )}`,
                            },
                            body: JSON.stringify(savedData),
                        }).then((response) => response.json())
                    );
                }
            }
            handleValidateButtonClickEvent(data);

            handleSaveProjectsButtonOnClickEvent(localData, apiUrl);
            addTemplateOfApplication();
            setIsModalAssociateStore(!isModalAssociateStore);

            setDataIsUpdated(true);
        }
        if (newProject.template.id === "") {
            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project`;
            let data: any = {};
            let listShops: string[] = [];
            const dataItems: Record<string, any> = {};
            let localData: any = [];
            dataListShops.forEach((element: any) => {
                listShops.push(element.id_boutique);
            });
            dataItems[rowData.projectId] = {
                ...newProject,
                projectId: rowData.projectId,
                note: rowData[t("Note")],
                designation: rowData[t("Designation")],
            };
            let isEmpty = false;
            for (let el of dataListShops) {
                const getSalesMethodUrl: Response = await fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/consomation/sale?franchiseId=${franchiseID}&shopId=${el.id_boutique}`
                );
                const saleMethods = await getSalesMethodUrl.json();
                if (saleMethods.length !== 0) {
                    data[el.id_boutique] = {
                        designation: el.Boutique,
                        id_boutique: el.id_boutique,
                    };
                } else {
                    isEmpty = true;
                }

                let savedData: any = {
                    userId: userID,
                    shopId: el.id_boutique,
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    type: "Add",
                    data: {
                        PROJECT: {
                            id_project: "PROJECT",
                            data_project: {
                                [rowData.projectId]: {
                                    ...newProject,
                                    projectId: rowData.projectId,
                                    note: rowData[t("Note")],
                                    designation: rowData[t("Designation")],
                                    status: rowData["Status"],
                                    modified_at: `le ${moment().format(
                                        "DD/MM/YY à HH:mm a"
                                    )}`,
                                },
                            },
                        },
                    },
                };
                localData.push(
                    fetch(apiUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bareer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify(savedData),
                    }).then((response) => response.json())
                );
            }
            if (Object.keys(data).length > 0) {
                handleValidateButtonClickEvent(data);
                handleSaveProjectsButtonOnClickEvent(localData, apiUrl);
                addTemplateOfApplication();
            } else if (isEmpty === true) {
                toast.warning(
                    `${t(
                        `Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings.`
                    )!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
            }
            setIsModalAssociateStore(!isModalAssociateStore);
            setIsDataUpdated(true);
            setIsLoading(false);
        }
    }

    return (
        <div>
            <div className="justify-content-between d-flex align-items-right  float-right p-3 mt-3">
                <CustomSecondaryColorButton
                    outline
                    rounded
                    className="mr-2 cmn_icn_closeAlert"
                    variant="light"
                    onClick={() => {
                        setIsModalAssociateStore(!isModalAssociateStore);
                    }}
                >
                    <span> {t("Cancel")}</span>
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    variant="primary"
                    className="cmn_btn_validateAlert"
                    onClick={handleValidate}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </div>
            <Modal
                isOpen={isAlertModalOpened}
                toggle={alertToggle}
                backdrop={false}
                fade={false}
                centered
            >
                <ModalHeader toggle={alertToggle}>
                    <span
                        style={{
                            textAlign: "left",
                            font: " normal normal 600 25px Segoe UI",
                            color: "#000000",
                        }}
                    >
                        {" "}
                        {t("Alert")}
                    </span>
                </ModalHeader>
                <ModalBody
                    className="d-flex flex-column"
                    style={{ gap: "10px" }}
                >
                    <div
                        style={{
                            textAlign: "center",
                            font: " normal normal 600 16px/10px Segoe UI",
                            color: "#2B2828",
                        }}
                    >
                        {t("Warning! Missing setting.")}
                    </div>
                    <div
                        style={{
                            font: " normal normal 600 16px/20px Segoe UI",
                            textAlign: "left",
                            color: "#2B2828",
                            padding: "14px",
                        }}
                    >
                        {t(
                            "To configure your KIOSK, it is essential to configure the elements in the general configuration section."
                        )}
                    </div>
                    <FlexboxGrid
                        className="pt-4 justify-content-end"
                        gap="10px"
                    >
                        <CustomMainColorButton
                            style={{
                                height: "37px !important",
                                width: "8px",
                                minWidth: "115px",
                            }}
                            rounded={true}
                            variant="primary"
                            outline
                            onClick={() => setIsAlertModalOpened(false)}
                        >
                            {t("Close")}
                        </CustomMainColorButton>
                    </FlexboxGrid>
                </ModalBody>
            </Modal>
        </div>
    );
}
