import React from "react";
import { useSnapshot } from "valtio";

import { generalConfigStore } from "@store";

export function FireIcon(): JSX.Element {
    const {
        project: {
            template: {
                content: { generalDesign },
            },
        },
    } = useSnapshot(generalConfigStore);

    return (
        <svg
            id="fire"
            xmlns="http://www.w3.org/2000/svg"
            width="13.485"
            height="19.882"
            viewBox="0 0 7.485 9.882"
        >
            <path
                id="Tracé_19823"
                data-name="Tracé 19823"
                d="M15.1,11.886a3.379,3.379,0,0,1-1.132-.191h0a4.076,4.076,0,0,1-2.579-2.16,4.389,4.389,0,0,1,.16-3.328.147.147,0,0,1,.144-.08.145.145,0,0,1,.128.1,1.861,1.861,0,0,0,.6.824,4.661,4.661,0,0,1,1.861-2.646c.943-.731.173-2.183.165-2.2a.138.138,0,0,1,.037-.175.15.15,0,0,1,.184,0c2.6,2.04,2.448,4.095,2.239,4.939a1.348,1.348,0,0,0,.529-1.267.141.141,0,0,1,.092-.151.149.149,0,0,1,.173.053,6.312,6.312,0,0,1,.682,1.359,3.76,3.76,0,0,1-.685,3.723l-.031.037A3.357,3.357,0,0,1,15.1,11.886Zm-1.032-.456a3.13,3.13,0,0,0,3.372-.883l.029-.035A3.487,3.487,0,0,0,18.1,7.06a6.3,6.3,0,0,0-.392-.868A1.919,1.919,0,0,1,16.7,7.441a.15.15,0,0,1-.166-.028.138.138,0,0,1-.029-.16c.046-.1,1.05-2.273-1.593-4.637a1.784,1.784,0,0,1-.463,2.017,4.357,4.357,0,0,0-1.785,2.7.142.142,0,0,1-.085.111.15.15,0,0,1-.142-.012,2.656,2.656,0,0,1-.754-.787,3.986,3.986,0,0,0-.027,2.787,3.8,3.8,0,0,0,2.409,2Z"
                transform="translate(-11.115 -2.003)"
                fill={(generalDesign as GeneralDesignType).colors[0].content}
            />
            <path
                id="Tracé_19824"
                data-name="Tracé 19824"
                d="M27.755,37.282a.149.149,0,0,1-.094-.033,2.571,2.571,0,0,1-.536-3.475.148.148,0,0,1,.159-.058.142.142,0,0,1,.108.127.929.929,0,0,0,.228.592,2.833,2.833,0,0,1,1.286-2.774.15.15,0,0,1,.174.02.138.138,0,0,1,.029.167.955.955,0,0,0-.118.478,1.014,1.014,0,0,0,.183.542l.058.086a8.888,8.888,0,0,0,.627.8,2.472,2.472,0,0,1,.414.682,2.285,2.285,0,0,1-.537,2.62.149.149,0,0,1-.206-.007.138.138,0,0,1,.008-.2,1.971,1.971,0,0,0,.47-2.3L30,34.537a2.205,2.205,0,0,0-.366-.6,9.147,9.147,0,0,1-.647-.825l-.058-.086a1.29,1.29,0,0,1-.231-.69,1.229,1.229,0,0,1,.008-.165,2.552,2.552,0,0,0-.746,2.541.138.138,0,0,1-.044.133.149.149,0,0,1-.141.031.978.978,0,0,1-.592-.605,2.189,2.189,0,0,0,.665,2.766.138.138,0,0,1,.043.156.146.146,0,0,1-.137.093Z"
                transform="translate(-24.396 -27.458)"
                fill={(generalDesign as GeneralDesignType).colors[0].content}
            />
        </svg>
    );
}
