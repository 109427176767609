import { DynamicTable } from "@maherunlocker/custom-react-table";
import React from "react";
import { useTranslation } from "react-i18next/";
import { useSnapshot } from "valtio";
import { AvForm } from "availity-reactstrap-validation";
import ReactSwitch from "react-switch";
import { ModalBody } from "reactstrap";

import { store } from "../../../../../../components/VerticalLayout/store";
import {
    setIsConfigurationCashManagementClicked,
    store as userStore,
} from "../../../../../Applications/Kiosk/store";
import { setAcceptedDenomination } from "../store";

import { Footer } from "../Footer";

type AcceptedDenominationType = {
    rowData: any;
    oneShop: boolean;
    setDataIsUpdated: Function;
};
export function AcceptedDenomination({
    rowData,
    oneShop,
    setDataIsUpdated,
}: AcceptedDenominationType) {
    const { t } = useTranslation();
    const { userID, shopID, franchiseID } = useSnapshot(store);
    const { isConfigurationCashManagementClicked } = useSnapshot(userStore);
    const appId = "SCO";
    const shopId = oneShop ? shopID : rowData.shopId;
    const url = `${process.env.REACT_APP_API_V2_URL}/settings/application/sco/cashManagement/accepted_denomination?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&applicationId=${appId}&applicationChildrenId=${rowData.appId}&peripheralId=${rowData.peripheralId}`;
    let data: any = {
        "5.00 €": rowData.accepted_denomination["5.00 €"],
        "10.00 €": rowData.accepted_denomination["10.00 €"],
        "20.00 €": rowData.accepted_denomination["20.00 €"],
        "50.00 €": rowData.accepted_denomination["50.00 €"],
        "100.00 €": rowData.accepted_denomination["100.00 €"],
        "200.00 €": rowData.accepted_denomination["200.00 €"],
        "500.00 €": rowData.accepted_denomination["500.00 €"],
    };

    const [
        isDataDenominationUpdated,
        setIsdataDenominationUpdated,
    ] = React.useState<boolean | number>(false);

    const setDataState = (selectedRow: any) => {
        data[selectedRow.Billets] = !data[selectedRow.Billets];
        setAcceptedDenomination(JSON.parse(JSON.stringify(data)));
    };

    function ReactSwitch_({ selectedRow }: any) {
        const [state, setState] = React.useState<boolean>(selectedRow.state);
        return (
            <ReactSwitch
                uncheckedIcon={false}
                checkedIcon={false}
                handleDiameter={26}
                offColor="#f7b4b8"
                offHandleColor="#E30613"
                checked={state}
                onChange={() => {
                    setDataState(selectedRow);
                    setState(!state);
                }}
                onColor="#c2eddd"
                onHandleColor="#34C38F"
                width={55}
                height={20}
            />
        );
    }

    let arrayOfCustomColumns = [
        {
            indexOFColumn: oneShop ? 6 : 7,
            columnName: t("Actions"),
            customJsx: (e: any) =>
                e.selectedRow?.subRows === undefined ? ReactSwitch_(e) : null,
        },
    ];

    return (
        <React.Fragment>
            <ModalBody style={{ height: "89.7%" }}>
                <AvForm>
                    <div>
                        <h3 className="p-4" style={{ color: "black" }}>
                            {t("Accepted denomination")}
                        </h3>
                        <div>
                            <DynamicTable
                                url={url}
                                canExpand={!oneShop}
                                canSelect={!oneShop}
                                canResize
                                actionColumn={() => <></>}
                                showGlobalFilter
                                arrayOfCustomColumns={arrayOfCustomColumns}
                                elevationTable={1}
                                minHeight="450px"
                                maxHeight="450px"
                                name="patterns"
                                dataIsUpdated={isDataDenominationUpdated}
                                setDataIsUpdated={setIsdataDenominationUpdated}
                            />
                        </div>
                    </div>
                </AvForm>
            </ModalBody>
            <Footer
                isConfigurationCashManagementClicked={
                    isConfigurationCashManagementClicked
                }
                setIsConfigurationCashManagementClicked={
                    setIsConfigurationCashManagementClicked
                }
                setDataIsUpdated={setDataIsUpdated}
                rowData={rowData}
            />
        </React.Fragment>
    );
}
