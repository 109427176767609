import { CheckIcon, StyledBreadcrumb } from "@aureskonnect/react-ui";
import PageTitle from "@components/Common/PageTitle";
import MessageInfo from "@components/Common/MessageInfo";
import {
    customColumnProps,
    DynamicTable,
} from "@maherunlocker/custom-react-table";
import React from "react";
import { useTranslation } from "react-i18next/";
import { useSnapshot } from "valtio";
import { ToastContainer } from "react-toastify";

import { store as useStore } from "@components/VerticalLayout/store";
import {
    setApkId,
    setDataSetupApk,
    setDataShop,
    store,
    setDataSite,
    setGlobalDataIsUpdated,
    setModify,
} from "./store";

import CustomIcons from "./CustomIcons";
import CustomSideFilter from "./CustomSideFilter";
import { ModalContentWrapper } from "./ModalContentWrapper";
import { ModalWrapper } from "./ModalWrapper";
import useResource from "@hooks/useResource";
import { useLocation } from "react-router-dom";
import { getPathAuthorization } from "@components/VerticalLayout";

export default function CCSetup() {
    const { t } = useTranslation();
    const location = useLocation();
    setModify(getPathAuthorization(location.pathname).Modifie);
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [selectedRows, setSelectedRows] = React.useState<any>({});
    const [isAddApk] = React.useState<boolean>(false);
    const {
        isModalOpened,
        dataShops,
        dataSite,
        globalDataIsUpdated,
    } = useSnapshot(store);
    const { userID, franchiseID } = useSnapshot(useStore);
    const url = `${process.env.REACT_APP_API_V2_URL}/settings/configuration/c&c?userId=${userID}&franchiseId=${franchiseID}`;
    const { resourceData } = useResource(url);
    React.useEffect(() => {
        let dataShop: any[] = [];
        let dataShopSite: any = [];
        if (Object.keys(resourceData).length > 0) {
            Object.values(resourceData.APK).forEach((value: any) => {
                if (
                    value.shopId !== undefined &&
                    value.shopId.indexOf("1") !== -1 &&
                    value[value.shopId].itemActivation[1].name ===
                        t("C&C app") &&
                    value[value.shopId].itemActivation[1].active === true
                ) {
                    dataShop.push("Boutique 1");
                }
                if (
                    value.shopId !== undefined &&
                    value.shopId.indexOf("2") !== -1 &&
                    value[value.shopId].itemActivation[1].name ===
                        t("C&C app") &&
                    value[value.shopId].itemActivation[1].active === true
                ) {
                    dataShop.push("Boutique 2");
                }
                if (
                    value.shopId !== undefined &&
                    value.shopId.indexOf("1") !== -1 &&
                    value[value.shopId].itemActivation[0].name ===
                        t("C&C website") &&
                    value[value.shopId].itemActivation[0].active === true
                ) {
                    dataShopSite.push("Boutique 1");
                }
                if (
                    value.shopId !== undefined &&
                    value.shopId.indexOf("2") !== -1 &&
                    value[value.shopId].itemActivation[0].name ===
                        t("C&C website") &&
                    value[value.shopId].itemActivation[0].active === true
                ) {
                    dataShopSite.push("Boutique 2");
                }
            });
        }
        dataShop = [...new Set(dataShop)];
        dataShopSite = [...new Set(dataShopSite)];
        setDataShop(dataShop);
        setDataSite(dataShopSite);
    }, [userID, franchiseID, resourceData, t]);

    React.useEffect(() => {
        let data: any[] = [];
        if (Object.keys(resourceData).length > 0) {
            Object.values(resourceData.APK).forEach((value: any) => {
                if (selectedRows["Nom commercial"] !== undefined) {
                    if (
                        value.shopId !== undefined &&
                        value.shopId.indexOf("1") !== -1 &&
                        selectedRows["Nom commercial"].indexOf("1") !== -1
                    ) {
                        data.push(value[value.shopId]);
                        let id: string = (value[value.shopId] as any).id_apk;
                        setApkId({ id, boutique: "Boutique 1" });
                    } else if (
                        value.shopId !== undefined &&
                        value.shopId.indexOf("2") !== -1 &&
                        selectedRows["Nom commercial"].indexOf("2") !== -1
                    ) {
                        data.push(value[value.shopId]);
                        let id: string = (value[value.shopId] as any).id_apk;
                        setApkId({ id, boutique: "Boutique 2" });
                    }
                }
            });
        }
        setDataSetupApk(data);
    }, [userID, franchiseID, selectedRows, resourceData]);

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 999,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons e={e} setSelectedRows={setSelectedRows} />
            ),
            disableFilter: false,
        },
        {
            indexOFColumn: 4,
            columnName: t("Application"),
            customJsx: (e: any) => {
                return e.selectedRow !== undefined &&
                    e.selectedRow?.subRows === undefined ? (
                    e.selectedRow["Nom commercial"] === "Boutique 1" &&
                    [...dataShops].length !== 0 &&
                    [...dataShops].join(",").indexOf("Boutique 1") !== -1 ? (
                        <CheckIcon
                            className="mt-1 ml-1 cmn_chk_selectButton"
                            height={45}
                            width={45}
                        />
                    ) : e.selectedRow["Nom commercial"] === "Boutique 2" &&
                      [...dataShops].length !== 0 &&
                      [...dataShops].join(",").indexOf("Boutique 2") !== -1 ? (
                        <CheckIcon
                            className="mt-1 ml-1 cmn_chk_selectButton"
                            height={45}
                            width={45}
                        />
                    ) : null
                ) : null;
            },
        },
        {
            indexOFColumn: 5,
            columnName: t("Site web"),
            customJsx: (e: any) => {
                return e.selectedRow !== undefined &&
                    e.selectedRow?.subRows === undefined ? (
                    e.selectedRow["Nom commercial"] === "Boutique 1" &&
                    [...dataSite].join(",").indexOf("Boutique 1") !== -1 ? (
                        <CheckIcon
                            className="mt-1 ml-1 cmn_chk_selectButton"
                            height={45}
                            width={45}
                        />
                    ) : e.selectedRow["Nom commercial"] === "Boutique 2" &&
                      [...dataSite].join(",").indexOf("Boutique 2") !== -1 ? (
                        <CheckIcon
                            className="mt-1 ml-1 cmn_chk_selectButton"
                            height={45}
                            width={45}
                        />
                    ) : null
                ) : null;
            },
        },
    ];

    return (
        <React.Fragment>
            <PageTitle title={t("Applications")} />

            <div className="page-content__clz">
                <StyledBreadcrumb
                    items={[
                        {
                            item: "ETK Settings".toUpperCase(),
                            link: "https://dev-portail.aureskonnect.com",
                        },
                        {
                            item:
                                localStorage
                                    .getItem("selectedAccount")
                                    ?.toUpperCase() || "",
                            link: "/compte",
                        },
                        {
                            item: t("Application").toUpperCase(),
                            link: "applications",
                        },
                        {
                            item: t("C&C").toUpperCase(),
                            link: "c&c",
                        },
                        {
                            item: t("Configuration").toUpperCase(),
                            link: "Configuration",
                        },
                    ]}
                />
                <MessageInfo
                    message={t("Please set your application and C&C...")}
                />

                <div
                    style={{
                        display: "grid",
                        minHeight: "650px",
                        gridTemplateColumns: "auto",
                        gridColumnGap: "4px",
                        border: 0,
                    }}
                >
                    <DynamicTable
                        url="./apk.json"
                        showFilter
                        canSelect={true}
                        setLocalFilterActive={setLocalFilterActive}
                        filterActive={filterActive}
                        actionColumn={() => <></>}
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        customJsxSideFilterButton={
                            <CustomSideFilter
                                // setIsAddApk={setIsAddApk}
                                isAddApk={isAddApk}
                            />
                        }
                        showGlobalFilter
                        canExpand
                        elevationTable={1}
                        minHeight="500px"
                        maxHeight="500px"
                        setDataIsUpdated={
                            setGlobalDataIsUpdated as
                                | React.Dispatch<
                                      React.SetStateAction<number | boolean>
                                  >
                                | undefined
                        }
                        dataIsUpdated={globalDataIsUpdated}
                        setSelectedRows={setSelectedRows}
                        name="Choice"
                    />
                    {isModalOpened ? (
                        <ModalWrapper
                            title={t("C&C Setup")}
                            selectedRows={selectedRows}
                        >
                            <ModalContentWrapper />
                        </ModalWrapper>
                    ) : null}
                    <ToastContainer limit={1} />
                </div>
            </div>
        </React.Fragment>
    );
}
