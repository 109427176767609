import React from "react";

type ModelNumberType = {
    text: string;
    colorPrimary: any;
    colorSecondary: any;
    width?: string;
};

export default function ModelNumber({
    text,
    colorPrimary,
    colorSecondary,
    width,
}: ModelNumberType) {
    return (
        <h6
            className="d-flex align-items-center justify-content-center"
            style={{
                width: width !== undefined ? width : "105px",
                height: "35px",
                backgroundColor: colorSecondary,
            }}
        >
            <span style={{ color: colorPrimary, fontSize: "175%" }}>
                {text}
            </span>
        </h6>
    );
}
