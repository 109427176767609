import React from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { useSnapshot } from "valtio";

import {
    generalConfigStore,
    setActionsButtonsItems,
    setIsActionButtonsActive,
    setIsPageActionsButtonsActive,
    setPageActionsButtonsItems,
} from "@store";

export function ActionsButtons(): JSX.Element {
    const { t } = useTranslation();

    const {
        project: { template },
    } = useSnapshot(generalConfigStore);

    const [actionButtonItems, setActionButtonItems] = React.useState(
        (
            template.pages.ways["orderTaking"] as OrderTakingPageType
        ).actionsButtons.items.filter(
            (actionButton) => actionButton.name === "Cancel order"
        )
    );

    function handleActivateSwitchOnClickEvent(name: string) {
        const copOfData = [...actionButtonItems];

        const findIndex = copOfData.find((element) => element.name === name);

        if (findIndex) {
            const actionButtonItem = copOfData.indexOf(findIndex);

            copOfData[actionButtonItem] = {
                ...findIndex,
                active: !copOfData[actionButtonItem].active,
            };
            setActionButtonItems(copOfData);
            setActionsButtonsItems(copOfData);
            setPageActionsButtonsItems(copOfData, "orderTaking");
        }

        if (
            copOfData.filter((actionButtonItem) => {
                return actionButtonItem.active === true;
            }).length === 0
        ) {
            setIsActionButtonsActive(false);
            setIsPageActionsButtonsActive(false, "orderTaking");
        }
    }

    return (
        <div>
            <div
                className="mt-4 rounded border"
                style={{
                    width: "93%",
                    height: "96%",
                    borderColor: "#CECECE",
                }}
            >
                <div
                    className="text-left px-4 d-flex align-items-center cursor__clz"
                    style={{
                        backgroundColor: "#EDEDED",
                        height: "40px",
                        fontSize: "20px",
                    }}
                >
                    {t("Actions Buttons")}
                </div>
                <div className="p-4">
                    {actionButtonItems.map(
                        (item: ActionsButtonsDesignItemType, index: number) => {
                            return (
                                <div
                                    className="py-2 d-flex flex-row justify-content-between align-items-center cursor__clz"
                                    key={index}
                                >
                                    <span>{t(item?.name)}</span>
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        onChange={() => {
                                            handleActivateSwitchOnClickEvent(
                                                actionButtonItems[index].name
                                            );
                                        }}
                                        checked={
                                            actionButtonItems[index].active
                                        }
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                    />
                                </div>
                            );
                        }
                    )}
                </div>
            </div>
        </div>
    );
}
