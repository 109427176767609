import React from "react";
import { useSnapshot } from "valtio";

import { truncateString } from "@helpers/general";
import { designStore, generalConfigStore } from "@store";

type PropsType = {
    imageId: string | undefined;
    screenItemId?: string;
    screenItems?: StandbyScreenItemType[];
};

export function TruncatedTextWrapper({
    imageId,
    screenItemId,
    screenItems,
}: PropsType): JSX.Element {
    const {
        project: { files: images },
    } = useSnapshot(generalConfigStore);

    const { selectedLanguage } = useSnapshot(designStore);

    // eslint-disable-next-line
    let image: any = {};

    if (screenItemId !== undefined) {
        // eslint-disable-next-line
        image = screenItems?.find((element) => element.id === screenItemId)!;
    } else {
        // eslint-disable-next-line
        image = images.find((image: ImageItemType) => image.id === imageId)!;
    }
    const filename =
        typeof image?.content === "string"
            ? image?.content.split("/")[image?.content.split("/").length - 1]
            : image?.content[selectedLanguage].path.split("/")[
                  image?.content[selectedLanguage].path.split("/").length - 1
              ];

    return (
        <div
            className="text-truncate"
            style={{
                width: filename !== "" ? "150px" : "unset",
                whiteSpace: "nowrap",
                overflow: "hidden",
            }}
        >
            {truncateString(filename as string, 10)}
        </div>
    );
}
