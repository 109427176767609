import React from "react";
import { useSnapshot } from "valtio";

import { designStore } from "@store";

import "@components/Common/Design/index.css";

import { MainContent } from "./MainContent";
import { Translation } from "./Translation";

export function Header(): JSX.Element {
    const { isUserAccountOrderTakingActive } = useSnapshot(designStore);

    return (
        <React.Fragment>
            <MainContent />
            {isUserAccountOrderTakingActive === true ? <Translation /> : null}
        </React.Fragment>
    );
}
