import React from "react";
import { useSnapshot } from "valtio";

import { designStore, generalConfigStore } from "@store";

import { getImageContentById } from "@helpers/general";

export function ActionsButtonsWrapper(): JSX.Element {
    const {
        project: { template, files: images },
    } = useSnapshot(generalConfigStore);
    const { selectedLanguage } = useSnapshot(designStore);

    const actionsButtonsOrderTakingItems = (template.pages.ways[
        "orderTaking"
    ] as OrderTakingPageType).actionsButtons.items.filter(
        (actionButton) =>
            actionButton.name === "Abandon article" ||
            actionButton.name === "Next in step" ||
            actionButton.name === "Previous in step"
    );

    const activeActionsButtons = actionsButtonsOrderTakingItems.filter(
        (item) => {
            return item.active === true;
        }
    );

    const mainActiveActionsButtons = activeActionsButtons.sort((a, b) => {
        return a.role < b.role ? 1 : -1;
    });

    return (
        <div
            className="d-flex flex-row align-items-center"
            style={{
                justifyContent: "space-between",
                width: "100%",
            }}
        >
            <div
                className="d-flex flex-row align-items-center"
                style={{
                    gap: "5px",
                }}
            >
                {mainActiveActionsButtons.map((item, index) => {
                    const imageContent = getImageContentById(
                        item.id,
                        images,
                        selectedLanguage
                    );

                    const imageSrc =
                        imageContent?.includes("http") ||
                        imageContent?.includes("https")
                            ? imageContent
                            : `./images/${imageContent}`;

                    return (
                        <div key={index}>
                            <img
                                alt={item.name}
                                src={imageSrc}
                                style={{
                                    width:
                                        mainActiveActionsButtons.length < 3
                                            ? "150px"
                                            : "100px",
                                    height: "25px",
                                }}
                            />
                        </div>
                    );
                })}
            </div>
            <div
                className="badge badge-secondary d-flex justify-content-center align-items-center text-dark"
                style={{
                    height: "20px",
                    background: "#ffffff 0% 0% no-repeat padding-box ",
                    borderRadius: "4px",
                }}
            >
                {"Total 147.000 €"}
            </div>
        </div>
    );
}
