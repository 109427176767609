import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { DynamicTable } from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";
import {
    setIsAddCustomerAccountModalClicked,
    storeCustomerAccount,
} from "../store";

import { store } from "../../../../components/VerticalLayout/store";
import ModalInfo from "../ModalInfo";
import { CustomIcons } from "./CustomIcons";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

export default function CustomerAccounts(franchise: any) {
    const { t } = useTranslation();
    const { oneShop, franchiseID, userID } = useSnapshot(store);
    // const [url] = useState<string>("/customerAccount.json");
    const url = `${process.env.REACT_APP_API_V2_URL}/settings/general/get_list_shops?userId=${userID}&franchiseId=${franchiseID}`;

    const [, setSelectedRows] = useState<any>([]);
    const [cosutmerName, setCosutmerName] = useState<string>("");
    const [uid, setUid] = useState<string>("");

    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const { isAddCustomerAccountModalClicked } = useSnapshot(
        storeCustomerAccount
    );
    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    type CustomColumnProps = {
        indexOFColumn: number;
        columnName: string;
        customJsx: React.ReactNode;
    };
    let arrayOfCustomColumns: CustomColumnProps[] = [
        {
            indexOFColumn: 999,
            columnName: t("Action"),

            customJsx: (e: any) => (
                <CustomIcons
                    e={e}
                    setIsAddCustomerAccountModalClicked={
                        setIsAddCustomerAccountModalClicked
                    }
                    isAddCustomerAccountModalClicked={
                        isAddCustomerAccountModalClicked
                    }
                    setCosutmerName={setCosutmerName}
                    setUid={setUid}
                />
            ),
        },
    ];

    return (
        <React.Fragment>
            <div
                style={{
                    display: "grid",
                    minHeight: oneShop ? "650px" : "600px",
                    gridTemplateColumns: "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    url={url}
                    canExpand={true}
                    canSelect
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    customSelect
                    setSelectedRows={setSelectedRows}
                    customJsxSideFilterButton={
                        <React.Fragment></React.Fragment>
                    }
                    canResize
                    actionColumn={ActionColumn}
                    showGlobalFilter
                    showFilter
                    dataIsUpdated={dataIsUpdated}
                    setDataIsUpdated={setDataIsUpdated}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    elevationTable={1}
                    minHeight={oneShop ? "500px" : "450px"}
                    maxHeight={oneShop ? "500px" : "450px"}
                    name="CustomerAccount"
                />
                {isAddCustomerAccountModalClicked && (
                    <ModalInfo
                        isAddCustomerAccountModalClicked={
                            isAddCustomerAccountModalClicked
                        }
                        setIsAddCustomerAccountModalClicked={
                            setIsAddCustomerAccountModalClicked
                        }
                        cosutmerName={cosutmerName}
                        uid={uid}
                        setDataIsUpdated={setDataIsUpdated}
                    />
                )}
            </div>
        </React.Fragment>
    );
}
