import React from "react";
import { ListManager } from "react-beautiful-dnd-grid";

import { CardWrapper } from "@components/Common/CardWrapper";
import { ScrollContainer } from "@components/ScrollContainer";

import "./index.css";

type DragDropWrapperPropsType = {
    items: ProjectMainContentItemType[];
    setItems: React.Dispatch<
        React.SetStateAction<ProjectMainContentItemType[]>
    >;
    setConfig: (items: ProjectMainContentItemType[]) => void;
};
export function DragDropWrapper({
    items,
    setItems,
    setConfig,
}: DragDropWrapperPropsType): JSX.Element {
    function handleOnDragEndEvent(
        sourceIndex: number,
        destinationIndex: number
    ) {
        if (destinationIndex === sourceIndex) {
            return;
        }

        const newItems = [...items];
        const [reorderedItem] = newItems.splice(sourceIndex, 1);
        newItems.splice(destinationIndex, 0, reorderedItem);
        setItems(newItems);
        setConfig(newItems);
    }

    return (
        <React.Fragment>
            {items.length > 8 ? (
                <ScrollContainer>
                    <div
                        className="drag-drop-wrapper__clz d-flex flex-column justify-content-center"
                        style={{
                            gap: "15px",
                        }}
                    >
                        <ListManager
                            items={items}
                            direction="horizontal"
                            maxItems={4}
                            render={(item) => {
                                const index = items.findIndex((object) => {
                                    return object.name === item.name;
                                });

                                return (
                                    <CardWrapper
                                        item={item}
                                        index={index}
                                        setConfig={setConfig}
                                        setItems={setItems}
                                        hasTooltip={true}
                                    />
                                );
                            }}
                            onDragEnd={handleOnDragEndEvent}
                        />
                    </div>
                </ScrollContainer>
            ) : (
                <div
                    className="drag-drop-wrapper__clz d-flex flex-column justify-content-center"
                    style={{ gap: "15px" }}
                >
                    <ListManager
                        items={items}
                        direction="horizontal"
                        maxItems={items.length > 4 && items.length <= 6 ? 3 : 4}
                        render={(item) => {
                            const index = items.findIndex((object) => {
                                return object.name === item.name;
                            });

                            return (
                                <CardWrapper
                                    item={item}
                                    index={index}
                                    setConfig={setConfig}
                                    setItems={setItems}
                                    hasTooltip={true}
                                />
                            );
                        }}
                        onDragEnd={handleOnDragEndEvent}
                    />
                </div>
            )}
        </React.Fragment>
    );
}
