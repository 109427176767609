import React from "react";
import classNames from "classnames";
import { useSnapshot } from "valtio";

import { designStore } from "@store";

import { LogoWrapper } from "./LogoWrapper";
export function HeaderWrapper(): JSX.Element {
    const {
        isSummaryHeaderLogoActive,
        isSummaryHeaderAccumulatedLoyaltyPointsActive,
        summaryHeaderAccumulatedLoyaltyPointsItems,
        selectedLanguage,
        summaryHeaderItems,
        activeTabIndex,
    } = useSnapshot(designStore);

    return (
        <div
            className={classNames({
                "border-wrapper__clz": activeTabIndex === 1,
            })}
            style={{ width: "429px" }}
        >
            <div
                className="headerSummary"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    boxShadow: "0px 3px 6px #00000080",
                    borderRadius: "19px",
                    width: "380px",
                    marginTop: "11px",
                    marginLeft: "23px",
                    background: "white",
                }}
            >
                {isSummaryHeaderLogoActive === true ? (
                    <div className="centered-items">
                        <LogoWrapper width={70} height={70} />
                    </div>
                ) : null}
                <div
                    className="d-flex flex-column justify-content-center"
                    style={{ height: "100%" }}
                >
                    <>
                        {isSummaryHeaderLogoActive === true ? (
                            <hr
                                style={{
                                    height: "1px",
                                    width: "96%",
                                    backgroundColor: "#cccccc",
                                    margin: "unset 2px unset 2px",
                                    marginTop: "unset",
                                    marginBottom: "unset",
                                    border: " 0px none",
                                }}
                            />
                        ) : null}
                        {isSummaryHeaderAccumulatedLoyaltyPointsActive ===
                        true ? (
                            <div
                                className={classNames("header-text pl-4", {
                                    "d-flex justify-content-center align-items-center":
                                        isSummaryHeaderLogoActive === false,
                                })}
                                style={{ height: "47px" }}
                            >
                                {summaryHeaderAccumulatedLoyaltyPointsItems.map(
                                    (
                                        item: {
                                            name: string;
                                            languages: {
                                                [key: string]: {
                                                    content: string;
                                                    defaultContent: string;
                                                };
                                            };
                                        },
                                        index: number
                                    ) => {
                                        return (
                                            <div
                                                className="d-flex flex-row align-items-center"
                                                style={{ gap: "5px" }}
                                            >
                                                <span
                                                    style={{
                                                        font:
                                                            index === 0
                                                                ? "normal normal bold 20px/24px Nunito"
                                                                : "normal normal 400 10px/20px Segoe UI",
                                                    }}
                                                >
                                                    {
                                                        item.languages[
                                                            selectedLanguage
                                                        ].content
                                                    }
                                                </span>
                                                {index === 1 ? (
                                                    <div>
                                                        <span
                                                            style={{
                                                                font:
                                                                    "normal normal 600 10px/20px Segoe UI",
                                                                color: "gray",
                                                            }}
                                                        >
                                                            {"X "}
                                                        </span>
                                                        <span
                                                            style={{
                                                                font:
                                                                    "normal normal 400 10px/20px Segoe UI",
                                                            }}
                                                        >
                                                            {"points"}
                                                        </span>
                                                    </div>
                                                ) : null}
                                            </div>
                                        );
                                    }
                                )}

                                <br />
                            </div>
                        ) : null}
                    </>
                    {isSummaryHeaderAccumulatedLoyaltyPointsActive === true ? (
                        <hr
                            style={{
                                height: "1px",
                                width: "96%",
                                backgroundColor: "#cccccc",
                                margin: "unset 2px unset 2px",
                                marginTop: "unset",
                                marginBottom: "unset",
                                border: " 0px none",
                            }}
                        />
                    ) : null}
                    <div
                        className="mt-4 d-flex justify-content-center align-items-center"
                        style={{
                            font: "normal normal 600 15px/10px Nunito Sans",
                        }}
                    >
                        {summaryHeaderItems[selectedLanguage].content !==
                        undefined
                            ? summaryHeaderItems[selectedLanguage].content
                            : ""}
                    </div>
                </div>
            </div>
        </div>
    );
}
