import React from "react";
import { useSnapshot } from "valtio";

import { computedStore, designStore, generalConfigStore } from "@store";

import { getImageContentById } from "@helpers/general";

type PropsType = {
    children: React.ReactNode;
    selectedSubStep?: ProjectMainContentItemType;
};

export function PreviewBackgroundWrapper({
    children,
    selectedSubStep,
}: PropsType): JSX.Element {
    const {
        project: { files: images },
    } = useSnapshot(generalConfigStore);
    const { activeSlideIndex, isBackgroundActive } = useSnapshot(designStore);
    const { elements } = useSnapshot(computedStore);

    const [backgroundImageSrc, setBackgroundImageSrc] = React.useState<string>(
        ""
    );
    const [backgroundImage, setBackgroundImage] = React.useState<
        ImageItemType
    >();

    React.useEffect(() => {
        if (elements.length > 0) {
            const localBackgroundImage = images.find((image) => {
                return (
                    image.key === "background" &&
                    (selectedSubStep === undefined
                        ? image.name === elements[activeSlideIndex]?.name
                        : image.subName ===
                          // eslint-disable-next-line
                          (selectedSubStep.shortName as string))
                );
            });
            setBackgroundImage(localBackgroundImage);
            const backgroundImageContent = getImageContentById(
                (localBackgroundImage as ImageItemType).id as string,
                images
            );
            setBackgroundImageSrc(
                backgroundImageContent?.includes("http") ||
                    backgroundImageContent?.includes("https")
                    ? backgroundImageContent
                    : `./images/${backgroundImageContent}`
            );
        }
    }, [selectedSubStep, images, activeSlideIndex, elements]);

    return (
        <React.Fragment>
            {isBackgroundActive === true ? (
                backgroundImage?.type !== undefined &&
                backgroundImage?.type.includes("video") ? (
                    <React.Fragment>
                        <video
                            autoPlay={true}
                            muted={true}
                            loop={true}
                            style={{
                                height: "725px",
                                width: "430px",
                                objectFit: "cover",
                                position: "fixed",
                            }}
                            src={backgroundImageSrc}
                        />
                        {children}
                    </React.Fragment>
                ) : (
                    <div
                        style={{
                            backgroundImage: `url(${backgroundImageSrc})`,
                            height: "725px",
                            backgroundSize: "cover",
                        }}
                    >
                        {children}
                    </div>
                )
            ) : (
                <div
                    style={{
                        backgroundImage: `url(${backgroundImageSrc})`,
                        height: "725px",
                        backgroundSize: "cover",
                    }}
                >
                    {children}
                </div>
            )}
        </React.Fragment>
    );
}
