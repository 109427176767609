import React from "react";
import ReactSwitch from "react-switch";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { uid } from "react-uid";

import {
    designStore,
    setProjectFiles,
    setImageKey,
    setTPAValinaModalContent,
    generalConfigStore,
} from "@store";

import { truncateString } from "@helpers/general";

import { Uploader } from "@components/Uploader";

import { Translation } from "./Translation";

export function MainContent(): JSX.Element {
    const { t } = useTranslation();

    const {
        selectedLanguage,
        paymentTPAInformationMessageItems,
        TPAValinaModalContent,
    } = useSnapshot(designStore);
    const {
        project: { files: images },
        imageKey,
    } = useSnapshot(generalConfigStore);

    const [isPaymentTPAIconActive, setIsPaymentTPAIconActive] =
        React.useState<boolean>();
    const [
        isPaymentTPAInformationMessageActive,
        setIsPaymentTPAInformationMessageActive,
    ] = React.useState<boolean>();
    const [
        isPaymentTPAActionButtonsActive,
        setIsPaymentTPAActionButtonsActive,
    ] = React.useState<boolean>();

    function handleResetImagesOnClickEvent() {
        const imagesArray = [...images];

        const image = imagesArray.find(
            (element) =>
                element.id ===
                (TPAValinaModalContent.header.icon as IconType).id
        );
        if (image !== undefined) {
            const imageIndex = imagesArray.indexOf(image);
            imagesArray[imageIndex] = {
                ...image,
                content: image.defaultImage as string,
            };
        }

        setProjectFiles(imagesArray);
        setImageKey(uid(imagesArray));
    }

    function handleIsPaymentTPAIconSwitchOnClickEvent() {
        setIsPaymentTPAIconActive(!isPaymentTPAIconActive);
        setTPAValinaModalContent({
            ...TPAValinaModalContent,
            header: {
                ...TPAValinaModalContent.header,
                icon: {
                    ...(TPAValinaModalContent.header.icon as IconType),
                    active: !isPaymentTPAIconActive,
                },
            },
        });
    }

    function handleIsPaymentTPAInformationMessageSwitchOnClickEvent() {
        setIsPaymentTPAInformationMessageActive(
            !isPaymentTPAInformationMessageActive
        );
        setTPAValinaModalContent({
            ...TPAValinaModalContent,
            header: {
                ...TPAValinaModalContent.header,
                informationMessage: {
                    ...TPAValinaModalContent.header.informationMessage,
                    active: !isPaymentTPAInformationMessageActive,
                },
            },
        });
    }

    function handleIsPaymentTPAActionButtonsSwitchOnClickEvent() {
        setIsPaymentTPAActionButtonsActive(!isPaymentTPAActionButtonsActive);
        setTPAValinaModalContent({
            ...TPAValinaModalContent,
            actionsButtons: {
                ...TPAValinaModalContent.actionsButtons,
                items: [
                    {
                        ...TPAValinaModalContent.actionsButtons.items[0],
                        active: !isPaymentTPAActionButtonsActive,
                    },
                ],
            },
        });
    }

    React.useEffect(() => {
        setIsPaymentTPAIconActive(
            (TPAValinaModalContent.header.icon as IconType).active
        );
        setIsPaymentTPAInformationMessageActive(
            TPAValinaModalContent.header.informationMessage.active
        );
        setIsPaymentTPAActionButtonsActive(
            TPAValinaModalContent.actionsButtons.items[0].active
        );
    }, [TPAValinaModalContent]);

    return (
        <React.Fragment>
            <div
                className="rounded border p-2 m-2"
                style={{
                    width: "820px",
                    height: "690px",
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    borderRadius: "9px",
                    opacity: 1,
                }}
            >
                <div
                    style={{
                        height: "300px",
                    }}
                >
                    <div
                        className="text-left d-flex justify-content-between align-items-center"
                        style={{
                            backgroundColor: "#EDEDED",
                            height: "50px",
                            opacity: 1,
                            fontSize: "20px",
                        }}
                    >
                        <span className="mx-4 cursor__clz">
                            {t("Choice of payment (with or without contact)")}
                        </span>
                    </div>

                    <div
                        className="mx-4"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "280px",
                        }}
                    >
                        <div className="p-3" style={{ marginTop: "10px" }}>
                            <span className="font-weight-bold cursor__clz">
                                {t("Header")} :
                            </span>
                            <div
                                className="d-grid__clz"
                                style={{
                                    gridTemplateColumns: "300px 200px auto",
                                    gap: "10px",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    className="mx-5 cursor__clz"
                                    style={{
                                        width: "484px",
                                        height: "20px",
                                        textAlign: "left",
                                        font: "normal normal 600 15px/20px Segoe UI",
                                        color: "#000000",
                                    }}
                                >
                                    {t("Icon")}
                                </div>
                                <div className="d-flex align-items-center">
                                    <StyledIconButton
                                        title={t("Reset")}
                                        className="m-0"
                                        icon="RefreshIcon"
                                        onClick={() => {
                                            handleResetImagesOnClickEvent();
                                        }}
                                        disabled={
                                            isPaymentTPAIconActive === true
                                                ? false
                                                : true
                                        }
                                    >
                                        <RefreshIcon height={15} width={15} />
                                    </StyledIconButton>
                                    <Uploader
                                        key={imageKey}
                                        uploadedFileTitle={
                                            (
                                                paymentTPAInformationMessageItems.icon as IconType
                                            ).content
                                        }
                                        id={
                                            (
                                                paymentTPAInformationMessageItems.icon as IconType
                                            ).id
                                        }
                                        disabled={
                                            isPaymentTPAIconActive === true
                                                ? false
                                                : true
                                        }
                                    />
                                </div>
                                <div className="d-flex align-items-center justify-content-end">
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        // eslint-disable-next-line
                                        checked={isPaymentTPAIconActive!}
                                        onChange={
                                            handleIsPaymentTPAIconSwitchOnClickEvent
                                        }
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                    />
                                </div>
                            </div>
                            <div
                                className="d-grid__clz"
                                style={{
                                    gridTemplateColumns: "300px auto",
                                    gap: "10px",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        top: "531px",
                                        left: "569px",
                                        width: "484px",
                                        height: "20px",
                                        textAlign: "left",
                                        font: "normal normal 600 15px/20px Segoe UI",
                                        color: "#000000",
                                        opacity: 1,
                                    }}
                                >
                                    <div className="mx-5 cursor__clz">
                                        {t("Information message")}

                                        <div
                                            className="cursor__clz"
                                            style={{
                                                color: "#B7B7B7",
                                                fontSize: "13px",
                                            }}
                                        >
                                            {truncateString(
                                                TPAValinaModalContent.header
                                                    .informationMessage
                                                    .languages[selectedLanguage]
                                                    .content,
                                                35
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex align-items-center justify-content-end">
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={
                                            // eslint-disable-next-line
                                            isPaymentTPAInformationMessageActive!
                                        }
                                        onChange={
                                            handleIsPaymentTPAInformationMessageSwitchOnClickEvent
                                        }
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className="font-weight-bold p-3 cursor__clz"
                            style={{ marginTop: "10px" }}
                        >
                            {t("Action buttons")} :
                            <div
                                className="d-grid__clz p-2"
                                style={{
                                    gridTemplateColumns: "300px 405px",
                                    gap: "10px",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        width: "484px",
                                        height: "20px",
                                        textAlign: "left",
                                        font: "normal normal 600 15px/20px Segoe UI",
                                        color: "#000000",
                                        marginLeft: "40px",
                                    }}
                                >
                                    <span className="cursor__clz">
                                        {" "}
                                        {t("Back")}
                                    </span>
                                </div>

                                <div className="d-flex align-items-center justify-content-end">
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={
                                            // eslint-disable-next-line
                                            isPaymentTPAActionButtonsActive!
                                        }
                                        onChange={
                                            handleIsPaymentTPAActionButtonsSwitchOnClickEvent
                                        }
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Translation />
            </div>
        </React.Fragment>
    );
}
