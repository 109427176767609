import React from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import {
    CrossIcon,
    StyledLabel,
    StyledTextInput,
    CheckboxIcon,
    PlusIcon,
    TrashIcon,
    StyledSelectInput,
} from "@aureskonnect/react-ui";
import locale from "antd/es/locale/fr_FR";
import { ConfigProvider, Input, TimePicker } from "antd";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import classnames from "classnames";
import { useSnapshot } from "valtio";
import { v4 as uuid } from "uuid";
import { mutate } from "swr";
import moment, * as Moment from "moment";
import { extendMoment } from "moment-range";

import { store } from "@components/VerticalLayout/store";
import {
    store as generalSettingStore,
    setIsArchived,
    setIsAssociatedExceptionalHours,
    setLocalShop,
    setMessage,
    setSelectedRow,
    setIsActionsClicked,
    setActions,
    setIsEdited,
} from "../store";

import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import {
    CustomCardBody,
    CustomCardFooter,
    CustomCardHeader,
} from "@components/Common/CustomCardStyled";
import { PersonalizedModal } from "./PersonalizedModal";
import { OverLappedMessage } from "./OverLappedMessage";
import { ConfirmationModificationModal } from "./ConfirmationModificationModal";
import { DiscardHoursModal } from "./DiscardHours";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.css";
import "./index.css";

type ExceptionalHoursFormType = {
    setIsAddExceptionalHoursClicked: Function;
    setDataIsUpdated: Function;
    setRowData: Function;
    setIsEdited: Function;
    setLocalFilterActive: Function;
    isAddExceptionalHoursClicked: boolean;
    dataIsUpdated: boolean | number;
    isAddNewExceptionalHoursButtonClicked: boolean;
    rowData: any;
    isModification: boolean;
};

export function ExceptionalHoursForm({
    setIsAddExceptionalHoursClicked,
    setDataIsUpdated,
    setRowData,
    setLocalFilterActive,
    isAddExceptionalHoursClicked,
    dataIsUpdated,
    isAddNewExceptionalHoursButtonClicked,
    rowData,
    isModification,
}: ExceptionalHoursFormType) {
    const { t } = useTranslation();
    const { userID, franchiseID, shopID, oneShop, operatorID } = useSnapshot(
        store
    );
    const {
        dataArchive,
        exceptionalHoursData,
        shopsExceptionalHoursData,
        archivedExceptionalHoursOfFranchise,
        exceptionalHoursFranchise,
        isActionsClicked,
        isEdited,
    } = useSnapshot(generalSettingStore);
    const momentRange = extendMoment(Moment);

    const recurrenceOptions: {
        label: string;
        value: string;
    }[] = [
        t("Never"),
        t("Every week"),
        t("Every month"),
        t("Every year"),
        t("Personalized"),
    ].map((element: string) => {
        return {
            label: element,
            value: element,
        };
    });

    const [idsHour, setIdsHours] = React.useState<any>([]);
    const [dataShop, setDataShop] = React.useState<any>([]);
    const [designation, setDesignation] = React.useState<string>("");
    const [recurrence, setRecurrence] = React.useState<string>(
        recurrenceOptions[0].label
    );
    const [isOpened, setIsOpened] = React.useState<boolean>(false);
    const [
        isDiscardHoursModalOpened,
        setIsDiscardHoursModalOpened,
    ] = React.useState<boolean>(false);
    const [title, setTitle] = React.useState<string>("");
    const [hours, setHours] = React.useState([
        {
            startTime: "",
            endTime: "",
            overLappedStart: false,
            overLappedEnd: false,
            isBothOverlapped: false,
            inputEndTime: false,
            inputStartTime: false,
            isTimeInvalid: false,
        },
    ]);
    const [
        isPersonalizedModalOpened,
        setIsPersonalizedModalOpened,
    ] = React.useState<boolean>(false);
    const [inputDesignation, setInputDesignation] = React.useState<boolean>(
        false
    );
    const [isDateOverlapped, setIsDateOverlapped] = React.useState<boolean>(
        false
    );
    const [isDesignationExist, setIsDesignationExist] = React.useState<boolean>(
        false
    );
    const [
        isValidRecurrenceEndDate,
        setIsValidRecurrenceEndDate,
    ] = React.useState<boolean>(false);
    const [inputEndDate, setInputEndDate] = React.useState<boolean>(false);
    const [inputRecurrenceEndDate, setInputRecurrenceEndDate] = React.useState<
        boolean
    >(false);
    const [state, setState] = React.useState<boolean>(true);
    const [dataEdited, setDataEdited] = React.useState<any>();
    const [associated, setAssociated] = React.useState<any[]>([]);

    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [
        isOverLappedModalOpened,
        setIsOverLappedModalOpened,
    ] = React.useState<boolean>(false);

    const [isOneDay, setIsOneDay] = React.useState<boolean>(false);
    const [isStartDateInvalid, setIsStartDateInvalid] = React.useState<boolean>(
        false
    );
    const [isEndDateInvalid, setIsEndDateInvalid] = React.useState<boolean>(
        false
    );
    const [inputStartDate, setInputStartDate] = React.useState<boolean>(false);
    const [startDate, setStartDate] = React.useState<string>("");
    const [endDate, setEndDate] = React.useState<string>("");
    const [recurrenceEndDate, setRecurrenceEndDate] = React.useState<string>(
        ""
    );
    const [recurrenceDays, setRecurrenceDays] = React.useState<number>(1);
    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState<boolean>(false);
    const shopId = oneShop ? shopID : "0";

    const addHour = () => {
        setHours([
            ...hours,
            {
                startTime: "",
                endTime: "",
                overLappedStart: false,
                overLappedEnd: false,
                isBothOverlapped: false,
                inputEndTime: false,
                inputStartTime: false,
                isTimeInvalid: false,
            },
        ]);
    };

    const deleteHour = (key: number) => {
        let newHours = [...hours];
        newHours.splice(key, 1);
        newHours.map((element: any, key: number) => {
            return (
                checkIfHoursOverLapped(key, "startTime", newHours),
                checkIfHoursOverLapped(key, "endTime", newHours)
            );
        });
        setHours(newHours);
    };

    const handleHoursChange = (changedItem: string, key: number, item: any) => {
        let newHours = [...hours];
        if (changedItem === "startTime") {
            newHours[key].startTime = item;
            if (
                moment().format("YYYY-MM-DD") === startDate &&
                item < moment().format("HH:mm")
            ) {
                newHours[key].isTimeInvalid = true;
            } else {
                newHours[key].isTimeInvalid = false;
            }
        } else if (changedItem === "endTime") {
            newHours[key].endTime = item;
        }
        setHours(newHours);
    };

    const checkIfExceptionalHoursFranchiseExist = () => {
        let isExceptionalHoursExist = false;
        exceptionalHoursFranchise.forEach((element: any) => {
            if (
                element[t("Designation")] === designation &&
                element.startDate === startDate &&
                element.endDate === endDate &&
                element[t("Recurrence")] === recurrence &&
                ((element.state === "Opened" && state === true) ||
                    (element.state === "Closed" && state === false)) &&
                element.recurrenceDays === recurrenceDays &&
                ((state === true &&
                    element.subRows.length === hours.length &&
                    element.subRows.every(
                        (el: any, key: number) =>
                            el[t("Start hour")] === hours[key].startTime &&
                            el[t("End hour")] === hours[key].endTime
                    )) ||
                    state === false)
            ) {
                setLocalShop([shopID]);
                setSelectedRow([element]);
                isExceptionalHoursExist = true;
                return;
            }
        });
        return isExceptionalHoursExist;
    };

    const checkIfExceptionalHoursFranchiseExistAndArchived = () => {
        let isExceptionalHoursExist = false;
        archivedExceptionalHoursOfFranchise.forEach((element: any) => {
            if (element[t("Designation")] === designation) {
                setLocalShop([shopID]);
                setSelectedRow([element]);
                isExceptionalHoursExist = true;
                return;
            }
        });
        return isExceptionalHoursExist;
    };

    const handleValidateButtonOnClickEvent = () => {
        let isExceptionalHoursFranchiseExist = checkIfExceptionalHoursFranchiseExist();
        let isExceptionalHoursFranchiseExistAndArchived = checkIfExceptionalHoursFranchiseExistAndArchived();
        if (designation === "") setInputDesignation(true);
        if (startDate === "") setInputStartDate(true);
        if (endDate === "") setInputEndDate(true);
        if (recurrence !== t("Never") && recurrenceEndDate === "")
            setInputRecurrenceEndDate(true);
        let newHours = [...hours];
        if (state === true) {
            newHours.forEach((element: any) => {
                if (element.startTime === "") {
                    element.inputStartTime = true;
                }
                if (element.endTime === "") {
                    element.inputEndTime = true;
                }
            });
            setHours(newHours);
        }

        if (
            rowData.Id !== undefined &&
            designation === rowData[t("Designation")] &&
            (state === (rowData.state === "Opened") ? true : false) &&
            startDate === rowData.startDate &&
            endDate === rowData.endDate &&
            recurrenceEndDate === rowData.endDate_recurrence &&
            recurrence === rowData[t("Recurrence")] &&
            ((rowData.subRows.length === hours.length &&
                rowData.subRows.every(
                    (element: any, key: number) =>
                        element[t("Start hour")] === hours[key].startTime &&
                        element[t("End hour")] === hours[key].endTime
                )) ||
                (rowData.subRows.length === 0 &&
                    hours.length === 1 &&
                    hours[0].startTime === "" &&
                    hours[0].endTime === ""))
        ) {
            setIsModalOpened(true);
        } else if (
            designation !== "" &&
            startDate !== "" &&
            endDate !== "" &&
            isStartDateInvalid === false &&
            isEndDateInvalid === false &&
            inputRecurrenceEndDate === false &&
            isValidRecurrenceEndDate === false &&
            (recurrenceEndDate !== "" || recurrence === t("Never")) &&
            (hours.every(
                (element: any) =>
                    element.startTime !== "" &&
                    element.endTime !== "" &&
                    element.overLappedStart === false &&
                    element.overLappedEnd === false &&
                    element.isBothOverlapped === false &&
                    element.isTimeInvalid === false
            ) ||
                state === false)
        ) {
            if (
                oneShop &&
                isExceptionalHoursFranchiseExist &&
                isDesignationExist === false
            ) {
                setMessage(
                    t(
                        "The exceptional hours already exists in the franchise. do you want to associate it with this shop?"
                    )
                );
                setIsAssociatedExceptionalHours(true);
            } else if (
                oneShop &&
                isExceptionalHoursFranchiseExistAndArchived &&
                isDesignationExist === false
            ) {
                setMessage(
                    t(
                        "Attention! the exceptional hours is already archived at the franchise level"
                    )
                );
                setIsArchived(true);
                setIsAssociatedExceptionalHours(true);
            } else if (
                rowData.Id !== undefined &&
                isModification &&
                !isDesignationExist
            ) {
                editExceptionalHour();
            } else if (!isDesignationExist) addExceptionalHour();
        }
    };

    const checkIfHoursOverLapped = (
        key: number,
        changedItem: string,
        arrayOfHOurs?: any[]
    ) => {
        let newHours =
            arrayOfHOurs !== undefined ? [...arrayOfHOurs] : [...hours];
        newHours[key].isBothOverlapped = false;
        if (changedItem === "startTime") newHours[key].overLappedStart = false;
        if (changedItem === "endTime") newHours[key].overLappedEnd = false;
        newHours.map((element: any, key1) => {
            if (changedItem === "startTime" && key1 !== key) {
                if (
                    (rowData.Id === undefined || rowData.Id !== element.Id) &&
                    element.startTime !== "" &&
                    element.endTime !== "" &&
                    (element.startTime < element.endTime
                        ? newHours[key].startTime > element.startTime &&
                          newHours[key].startTime < element.endTime
                        : (newHours[key].startTime > element.startTime &&
                              newHours[key].startTime > element.endTime) ||
                          (newHours[key].startTime < element.startTime &&
                              newHours[key].startTime < element.endTime))
                ) {
                    newHours[key].overLappedStart = true;
                }
            } else if (changedItem === "endTime" && key1 !== key) {
                if (
                    (rowData.Id === undefined || rowData.Id !== element.Id) &&
                    element.startTime !== "" &&
                    element.endTime !== "" &&
                    (element.startTime < element.endTime
                        ? newHours[key].endTime > element.startTime &&
                          newHours[key].endTime < element.endTime
                        : (newHours[key].endTime > element.startTime &&
                              newHours[key].endTime > element.endTime) ||
                          (newHours[key].endTime < element.startTime &&
                              newHours[key].endTime < element.endTime))
                ) {
                    newHours[key].overLappedEnd = true;
                }
            }
            if (
                ((newHours[key].startTime < element.startTime &&
                    newHours[key].endTime < element.startTime &&
                    newHours[key].startTime > element.endTime &&
                    newHours[key].endTime > element.endTime) ||
                    (newHours[key].startTime < element.startTime &&
                        newHours[key].endTime > element.startTime &&
                        newHours[key].startTime < element.endTime &&
                        newHours[key].endTime > element.endTime) ||
                    newHours[key].startTime === element.startTime ||
                    newHours[key].endTime === element.endTime) &&
                key1 !== key &&
                newHours[key].startTime !== "" &&
                newHours[key].endTime !== "" &&
                element.startTime !== "" &&
                element.endTime !== ""
            ) {
                newHours[key].isBothOverlapped = true;
            }
            return true;
        });
        setHours(newHours);
    };

    const getListOfRecurrence = (
        startDate: any,
        endDate: any,
        recurrence: any,
        endDateRecurrence: any
    ) => {
        let data = [];
        let lastDateStart: any = "";
        let lastDateEnd: any = "";
        let startRecurrence: any;
        let endRecurrence: any;
        if (lastDateStart === "")
            lastDateStart = moment(startDate, "YYYY-MM-DD");
        if (lastDateEnd === "") lastDateEnd = moment(endDate, "YYYY-MM-DD");
        while (
            lastDateStart.diff(moment(endDateRecurrence, "YYYY-MM-DD")) <= 0
        ) {
            let dayEnd =
                lastDateEnd.diff(moment(endDateRecurrence, "YYYY-MM-DD")) > 0
                    ? moment(endDateRecurrence, "YYYY-MM-DD").format(
                          "DD/MM/YYYY"
                      )
                    : lastDateEnd.format("DD/MM/YYYY");
            data.push({
                [t("Start date")]: lastDateStart.format("DD/MM/YYYY"),
                [t("End date")]: dayEnd,
            });
            if (recurrence === "Toutes les semaines") {
                startRecurrence = lastDateStart.add(7, "days");
                endRecurrence = lastDateEnd.add(7, "days");
                lastDateStart = startRecurrence;
                lastDateEnd = endRecurrence;
            } else if (recurrence === "Tous les mois") {
                startRecurrence = lastDateStart.add(1, "months");
                endRecurrence = lastDateEnd.add(1, "months");
                lastDateStart = startRecurrence;
                lastDateEnd = endRecurrence;
            } else if (recurrence === "Tous les ans") {
                startRecurrence = lastDateStart.add(1, "years");
                endRecurrence = lastDateEnd.add(1, "years");
                lastDateStart = startRecurrence;
                lastDateEnd = endRecurrence;
            } else if (recurrence === "Personnalisée") {
                startRecurrence = lastDateStart.add(
                    Number(recurrenceDays),
                    "days"
                );
                endRecurrence = lastDateEnd.add(Number(recurrenceDays), "days");
                lastDateStart = startRecurrence;
                lastDateEnd = endRecurrence;
            }
        }
        return data;
    };

    const handleCheckIfDatesOverLapped = () => {
        let isOverLapped = false;
        let recurrences: any = getListOfRecurrence(
            startDate,
            endDate,
            recurrence,
            recurrenceEndDate
        );

        if (recurrences.length === 0) {
            recurrences.push({
                [t("Start date")]: moment(startDate, "YYYY-MM-DD"),
                [t("End date")]: moment(endDate, "YYYY-MM-DD"),
            });
        }
        recurrences.forEach((el: any) => {
            let localDate = momentRange.range(
                moment(el[t("Start date")], "YYYY-MM-DD"),
                moment(el[t("End date")], "YYYY-MM-DD")
            );
            exceptionalHoursData.forEach((element: any) => {
                element.recurrenceList.forEach((elt: any) => {
                    let recurrenceDate = momentRange.range(
                        moment(
                            moment(elt[t("Start date")], "DD/MM/YYYY").format(
                                "YYYY-MM-DD"
                            )
                        ),
                        moment(
                            moment(elt[t("End date")], "DD/MM/YYYY").format(
                                "YYYY-MM-DD"
                            )
                        )
                    );
                    if (
                        recurrenceDate.overlaps(localDate) ||
                        recurrenceDate.adjacent(localDate) ||
                        moment(el[t("Start date")], "YYYY-MM-DD").isBetween(
                            moment(
                                moment(
                                    elt[t("Start date")],
                                    "DD/MM/YYYY"
                                ).format("YYYY-MM-DD"),
                                "YYYY-MM-DD"
                            ),
                            moment(
                                moment(elt[t("End date")], "DD/MM/YYYY").format(
                                    "YYYY-MM-DD"
                                ),
                                "YYYY-MM-DD"
                            )
                        )
                    ) {
                        isOverLapped = true;
                    }
                });
            });
            setIsDateOverlapped(isOverLapped);
        });
    };

    const checkIfExceptionalHoursExist = () => {
        let isExceptionalHoursExist = false;
        let allExceptionalHours = [...exceptionalHoursData, ...dataArchive];
        allExceptionalHours.forEach((element: any) => {
            if (element[t("Designation")] === designation) {
                setLocalShop([shopID]);
                setSelectedRow([element]);
                isExceptionalHoursExist = true;
                return;
            }
        });
        return isExceptionalHoursExist;
    };

    const resetFields = () => {
        setState(true);
        setDesignation("");
        setStartDate("");
        setEndDate("");
        setHours([
            {
                startTime: "",
                endTime: "",
                overLappedStart: false,
                overLappedEnd: false,
                isBothOverlapped: false,
                inputEndTime: false,
                inputStartTime: false,
                isTimeInvalid: false,
            },
        ]);
        setRecurrence(recurrenceOptions[0].label);
        setRecurrenceEndDate("");
        setRecurrenceDays(1);
    };

    async function addExceptionalHour() {
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/exceptionalHours`;
        let id = uuid();
        let data = {
            userId: userID,
            shopId: oneShop ? [shopId, 0] : [shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            adding: true,
            data: {
                [id]: {
                    id: id,
                    designation: designation,
                    state: state === true ? "Opened" : "Closed",
                    startDate: startDate,
                    endDate: endDate,
                    hours: state === true ? hours : [],
                    isArchived: false,
                    shopId: shopId,
                    recurrence: recurrence,
                    endDate_recurrence: recurrenceEndDate,
                    recurrenceDays: recurrenceDays,
                },
            },
        };
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                "The exceptional schedule was successfully added"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsEdited(false);
                        setLocalFilterActive(false);
                        resetFields();
                        setDataIsUpdated(!dataIsUpdated);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function editExceptionalHour() {
        let dataAssociate: any[] = [];
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/exceptionalHours`;
        let id = rowData.Id;
        let data = {
            userId: userID,
            shopId: oneShop ? [shopId, 0] : [shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            data: {
                [id]: {
                    id: id,
                    designation: designation,
                    state: state === true ? "Opened" : "Closed",
                    startDate: startDate,
                    endDate: endDate,
                    hours: state === true ? hours : [],
                    isArchived: false,
                    isActive: false,
                    shopId: shopId,
                    recurrence: recurrence,
                    endDate_recurrence: recurrenceEndDate,
                    recurrenceDays: recurrenceDays,
                },
            },
        };
        setDataEdited(data.data);
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        if (!oneShop) {
            dataAssociate = AssociateExceptionalHours();
        }
        if (dataAssociate.length > 1) {
            let data = dataOverlaps(id);
            setDataShop(data);
            setIsOpened(true);
        } else {
            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            toast.success(
                                `${t(
                                    "The exceptional schedule was successfully changed"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setIsEdited(false);
                            setLocalFilterActive(false);
                            resetFields();
                            setDataIsUpdated(!dataIsUpdated);
                            setIsAddExceptionalHoursClicked(false);
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    function AssociateExceptionalHours() {
        let idsAssociated = [0];
        let associated: any[] = [rowData];
        let data: any[] = [];
        if (shopsExceptionalHoursData !== undefined) {
            shopsExceptionalHoursData.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    if (el.Id === rowData.Id) {
                        idsAssociated.push(el.shopId);
                        associated.push(el);
                    }
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        if (shopsExceptionalHoursData !== undefined) {
            shopsExceptionalHoursData.forEach((element: any) => {
                if (idsAssociated.includes(element.shopId)) {
                    element.subRows.forEach((el: any) => {
                        if (data.every((elt: any) => elt.Id !== el.Id))
                            data.push(el);
                    });
                }
            });
        }
        setIdsHours(idsAssociated);
        return associated;
    }

    function dataOverlaps(idHours: string) {
        AssociateExceptionalHours();
        let data: any[] = [];
        if (shopsExceptionalHoursData !== undefined) {
            shopsExceptionalHoursData.forEach((element: any) => {
                if (idsHour.includes(element.shopId)) {
                    element.subRows.forEach((el: any) => {
                        if (
                            data.every(
                                (elt: any) =>
                                    elt.Id !== el.Id && el.Id !== idHours
                            )
                        )
                            data.push(el);
                    });
                }
            });
        }
        return data;
    }

    React.useEffect(() => {
        if (rowData.Id !== undefined && isModification) {
            setTitle(t("Modify an exceptional schedule"));
            setDesignation(rowData[t("Designation")]);
            setStartDate(rowData.startDate);
            setEndDate(rowData.endDate);
            setRecurrence(rowData[t("Recurrence")]);
            setRecurrenceEndDate(rowData.endDate_recurrence);
            setState(rowData.state === "Opened" ? true : false);
            setInputDesignation(false);
            setInputStartDate(false);
            setInputEndDate(false);
            if (rowData.subRows.length === 0) {
                setHours([
                    {
                        startTime: "",
                        endTime: "",
                        overLappedStart: false,
                        overLappedEnd: false,
                        isBothOverlapped: false,
                        inputEndTime: false,
                        inputStartTime: false,
                        isTimeInvalid: false,
                    },
                ]);
            } else {
                let newHours: any[] = [];
                rowData.subRows.forEach((element: any) => {
                    newHours.push({
                        startTime: element[t("Start hour")],
                        endTime: element[t("End hour")],
                        overLappedStart: false,
                        overLappedEnd: false,
                        inputEndTime: false,
                        inputStartTime: false,
                        isTimeInvalid: false,
                        isBothOverlapped: false,
                    });
                });
                setHours(newHours);
            }
            setRecurrenceDays(rowData.recurrenceDays);
        } else {
            setTitle(t("Add an exceptional schedule"));
            resetFields();
        }
        setAssociated(AssociateExceptionalHours);
        // eslint-disable-next-line
    }, [rowData, t, isAddNewExceptionalHoursButtonClicked]);

    React.useEffect(() => {
        let isExceptionalHoursExist = checkIfExceptionalHoursExist();
        setIsStartDateInvalid(false);
        setIsEndDateInvalid(false);
        setIsValidRecurrenceEndDate(false);
        if (
            isExceptionalHoursExist &&
            (rowData.Id === undefined ||
                (rowData.Id !== undefined &&
                    rowData[t("Designation")] !== designation))
        ) {
            setIsDesignationExist(true);
        } else {
            setIsDesignationExist(false);
        }
        if (
            moment(endDate, "YYYY-MM--DD").isBefore(
                moment(startDate, "YYYY-MM--DD")
            )
        ) {
            setIsDateOverlapped(false);
            setIsStartDateInvalid(true);
            setIsEndDateInvalid(true);
        }
        if (
            moment(startDate, "YYYY-MM--DD").isBefore(
                moment().subtract(1, "days")
            )
        ) {
            setIsDateOverlapped(false);
            setIsStartDateInvalid(true);
        }
        if (
            moment(endDate, "YYYY-MM--DD").isBefore(
                moment().subtract(1, "days")
            )
        ) {
            setIsDateOverlapped(false);
            setIsEndDateInvalid(true);
        }
        if (
            moment(recurrenceEndDate, "YYYY-MM--DD").isBefore(
                moment(endDate, "YYYY-MM--DD")
            ) &&
            recurrenceEndDate !== "" &&
            endDate !== ""
        ) {
            setIsValidRecurrenceEndDate(true);
        }
        // eslint-disable-next-line
    }, [
        designation,
        t,
        exceptionalHoursData,
        rowData,
        endDate,
        startDate,
        recurrenceEndDate,
    ]);

    React.useEffect(() => {
        if (
            hours.some(
                (element: any) =>
                    element.startTime === element.endTime &&
                    element.startTime !== "" &&
                    element.endTime !== ""
            )
        ) {
            setIsOneDay(true);
        } else {
            setIsOneDay(false);
        }
    }, [hours]);

    React.useEffect(() => {
        if (oneShop) handleCheckIfDatesOverLapped();
        // eslint-disable-next-line
    }, [startDate, endDate, recurrence, recurrenceEndDate]);
    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader className="d-flex align-items-center justify-content-between">
                    <h5 className="hex_txt_FRaddAnExceptionalSchedule pt-3">
                        {title}
                    </h5>
                    <CrossIcon
                        className="icon-close-notification__clz hex_icn_FRcloseForm"
                        height={13}
                        width={13}
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsEdited(false);
                                    setIsAddExceptionalHoursClicked(
                                        !isAddExceptionalHoursClicked
                                    );
                                });
                            } else {
                                setIsAddExceptionalHoursClicked(
                                    !isAddExceptionalHoursClicked
                                );
                                setIsEdited(false);
                            }
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className="pl-3 pr-5"
                    style={{
                        maxHeight: "450px",
                        overflowY: " scroll",
                    }}
                >
                    <AvGroup className="d-flex justify-content-between">
                        <StyledLabel htmlFor="text" className="mt-3">
                            {t("State")}
                        </StyledLabel>
                        <span className="d-flex justify-content-between">
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={state}
                                onChange={() => {
                                    setIsEdited(true);
                                    if (
                                        hours.some(
                                            (el: any) => el.startTime !== ""
                                        ) &&
                                        state === true
                                    ) {
                                        setIsDiscardHoursModalOpened(true);
                                    } else {
                                        setState(!state);
                                    }
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={50}
                                height={20}
                                className="hex_icn_FRenableDisable mt-3 mr-2"
                            />
                            <StyledLabel htmlFor="text" className="mt-3">
                                {state === true ? t("Opened") : t("Closed")}
                            </StyledLabel>
                        </span>
                    </AvGroup>
                    <AvGroup style={{ width: "94%" }}>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Designation")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames("hex_inp_FRname", {
                                input__clz:
                                    inputDesignation || isDesignationExist,
                            })}
                            autoComplete="off"
                            name="designation"
                            onChange={(e: any) => {
                                setDesignation(e.target.value.trim());
                                setIsEdited(true);
                                setInputDesignation(false);
                            }}
                            value={designation}
                            placeholder={t("Write")}
                            type="text"
                            autoFocus
                            style={{ height: "40px" }}
                        />
                        {inputDesignation ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a designation")}
                            </div>
                        ) : null}
                        {isDesignationExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("The name is already exists")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <span className="d-flex align-item-between p-2">
                            <div className="mr-5" style={{ width: "50%" }}>
                                <StyledLabel
                                    htmlFor="example-input"
                                    className="required__clz mt-3"
                                >
                                    {t("Start date")}
                                </StyledLabel>
                                <Input
                                    style={{
                                        padding: "0px !important",
                                        width: "90%",
                                        height: "40px",
                                    }}
                                    id="example-input"
                                    name="example-input"
                                    placeholder="Insert text"
                                    type="date"
                                    onChange={(e: any) => {
                                        setIsEdited(true);
                                        let newHours = [...hours];
                                        setStartDate(e.target.value);
                                        if (
                                            moment().format("YYYY-MM-DD") ===
                                            e.target.value
                                        ) {
                                            newHours.forEach((element: any) => {
                                                if (
                                                    element.startTime <
                                                        moment().format(
                                                            "HH:mm"
                                                        ) &&
                                                    element.startTime !== ""
                                                ) {
                                                    element.isTimeInvalid = true;
                                                }
                                            });
                                        } else {
                                            newHours.forEach((element: any) => {
                                                element.isTimeInvalid = false;
                                            });
                                        }
                                        setHours(newHours);
                                        setInputStartDate(false);
                                    }}
                                    value={startDate}
                                    className={classnames(
                                        "hex_drp_FRstartDate",
                                        {
                                            input__clz:
                                                inputStartDate ||
                                                isStartDateInvalid ||
                                                isDateOverlapped,
                                        }
                                    )}
                                />
                                {inputStartDate ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t("Please choose a date")}
                                    </div>
                                ) : null}
                                {isDateOverlapped ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t("Overlap detected")}
                                    </div>
                                ) : null}
                                {isStartDateInvalid ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t("The date is incorrect")}
                                    </div>
                                ) : null}
                            </div>
                            <div style={{ width: "50%" }}>
                                <StyledLabel
                                    htmlFor="example-input"
                                    className="required__clz mt-3"
                                >
                                    {t("End date")}
                                </StyledLabel>
                                <Input
                                    style={{
                                        padding: "0px !important",
                                        width: "90%",
                                        height: "40px",
                                    }}
                                    id="example-input"
                                    name="example-input"
                                    placeholder="Insert text"
                                    type="date"
                                    onChange={(e: any) => {
                                        setEndDate(e.target.value);
                                        setIsEdited(true);
                                        setInputEndDate(false);
                                    }}
                                    value={endDate}
                                    className={classnames("hex_drp_FRendDate", {
                                        input__clz:
                                            inputEndDate ||
                                            isEndDateInvalid ||
                                            isDateOverlapped,
                                    })}
                                />
                                {isDateOverlapped && !inputEndDate ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t("Overlap detected")}
                                    </div>
                                ) : null}
                                {inputEndDate ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t("Please choose a date")}
                                    </div>
                                ) : null}
                                {isEndDateInvalid ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t("The date is incorrect")}
                                    </div>
                                ) : null}
                            </div>
                        </span>
                    </AvGroup>
                    {hours.map((element: any, key: number) => (
                        <AvGroup>
                            <span className="d-flex align-item-between p-2">
                                <div
                                    style={{ width: "50%" }}
                                    className={classnames("mr-5", {
                                        "not-allowed-icon__clz":
                                            state === false,
                                    })}
                                >
                                    <StyledLabel
                                        htmlFor="example-input"
                                        className={classnames("mt-3", {
                                            required__clz: state,
                                        })}
                                    >
                                        {t("Start time")}
                                    </StyledLabel>
                                    <ConfigProvider locale={locale}>
                                        <TimePicker
                                            onChange={(
                                                time: Moment.Moment | null,
                                                e: string
                                            ) => {
                                                let newHours = [...hours];
                                                newHours[
                                                    key
                                                ].inputStartTime = false;
                                                setHours(newHours);
                                                handleHoursChange(
                                                    "startTime",
                                                    key,
                                                    e
                                                );
                                                newHours.map(
                                                    (el: any, key1: number) => {
                                                        if (el.endTime !== "")
                                                            checkIfHoursOverLapped(
                                                                key1,
                                                                "endTime"
                                                            );
                                                        if (el.startTime !== "")
                                                            checkIfHoursOverLapped(
                                                                key1,
                                                                "startTime"
                                                            );
                                                        return true;
                                                    }
                                                );
                                                setIsEdited(true);
                                            }}
                                            value={
                                                element.startTime !== ""
                                                    ? (moment(
                                                          element.startTime,
                                                          "HH:mm"
                                                      ) as
                                                          | Moment.Moment
                                                          | null
                                                          | undefined)
                                                    : null
                                            }
                                            format="HH:mm"
                                            style={{
                                                width: "90%",
                                                height: "40px",
                                            }}
                                            className={classnames(
                                                "hex_drp_FRopeningTime",
                                                {
                                                    input__clz:
                                                        element.inputStartTime ||
                                                        element.overLappedStart ||
                                                        element.isBothOverlapped ||
                                                        element.isTimeInvalid,
                                                }
                                            )}
                                        />
                                    </ConfigProvider>

                                    {element.inputStartTime ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t("Please choose a time")}
                                        </div>
                                    ) : null}
                                    {element.isTimeInvalid ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t("The time is invalid")}
                                        </div>
                                    ) : null}
                                    {element.overLappedStart ||
                                    element.isBothOverlapped ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t("Overlap detected")}
                                        </div>
                                    ) : null}
                                </div>
                                <div
                                    style={{ width: "50%" }}
                                    className={classnames({
                                        "not-allowed-icon__clz":
                                            state === false,
                                    })}
                                >
                                    <StyledLabel
                                        htmlFor="example-input"
                                        className={classnames("mt-3", {
                                            required__clz: state,
                                        })}
                                    >
                                        {t("End time")}
                                    </StyledLabel>
                                    <ConfigProvider locale={locale}>
                                        <TimePicker
                                            value={
                                                element.endTime !== ""
                                                    ? (moment(
                                                          element.endTime,
                                                          "HH:mm"
                                                      ) as
                                                          | Moment.Moment
                                                          | null
                                                          | undefined)
                                                    : null
                                            }
                                            onChange={(
                                                time: Moment.Moment | null,
                                                e: string
                                            ) => {
                                                let newHours = [...hours];
                                                newHours[
                                                    key
                                                ].inputEndTime = false;
                                                setHours(newHours);
                                                handleHoursChange(
                                                    "endTime",
                                                    key,
                                                    e
                                                );
                                                newHours.map(
                                                    (el: any, key1: number) => {
                                                        if (el.endTime !== "")
                                                            checkIfHoursOverLapped(
                                                                key1,
                                                                "endTime"
                                                            );
                                                        if (el.startTime !== "")
                                                            checkIfHoursOverLapped(
                                                                key1,
                                                                "startTime"
                                                            );
                                                        return true;
                                                    }
                                                );
                                                setIsEdited(true);
                                            }}
                                            format="HH:mm"
                                            style={{
                                                width: "90%",
                                                height: "40px",
                                            }}
                                            className={classnames(
                                                "hex_drp_FRclosingTime",
                                                {
                                                    input__clz:
                                                        element.inputEndTime ||
                                                        element.overLappedEnd ||
                                                        element.isBothOverlapped,
                                                }
                                            )}
                                        />
                                    </ConfigProvider>
                                    {element.inputEndTime ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t("Please choose a time")}
                                        </div>
                                    ) : null}
                                    {element.overLappedEnd ||
                                    element.isBothOverlapped ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                marginTop: "0.25rem",
                                                fontSize: "80%",
                                                color: "#f46a6a",
                                            }}
                                        >
                                            {t("Overlap detected")}
                                        </div>
                                    ) : null}
                                </div>
                                <div
                                    style={{
                                        height: "10px",
                                        position: "relative",
                                        top: "35px",
                                        marginLeft: "-55px",
                                        left: "50px",
                                    }}
                                >
                                    <CustomMainColorIconButton
                                        rounded
                                        onClick={() => {
                                            key === 0
                                                ? addHour()
                                                : deleteHour(key);
                                        }}
                                        className={classnames({
                                            "not-allowed-icon__clz":
                                                state === false ||
                                                (isOneDay && key === 0),
                                        })}
                                    >
                                        {key === 0 ? (
                                            <PlusIcon height={15} width={15} />
                                        ) : (
                                            <TrashIcon
                                                height={22}
                                                width={22}
                                                fill="red"
                                            />
                                        )}
                                    </CustomMainColorIconButton>
                                </div>
                            </span>
                        </AvGroup>
                    ))}
                    <AvGroup>
                        <span className="d-flex align-item-between p-2">
                            <div style={{ width: "50%" }} className="mr-5">
                                <StyledLabel
                                    htmlFor="example-input"
                                    className="required__clz mt-3"
                                >
                                    {t("Define recurrences")}
                                </StyledLabel>
                                <StyledSelectInput
                                    value={{
                                        label: recurrence,
                                        value: recurrence,
                                    }}
                                    name="recurrence"
                                    id="recurrence"
                                    options={recurrenceOptions}
                                    onChange={(e: any) => {
                                        setRecurrence(e.label);
                                        if (e.label === t("Personalized")) {
                                            setIsPersonalizedModalOpened(true);
                                        } else if (e.label === t("Never")) {
                                            setRecurrenceEndDate("");
                                            setInputRecurrenceEndDate(false);
                                        }
                                        setIsEdited(true);
                                    }}
                                    noOptionsMessage={() => t("No options")}
                                    maxMenuHeight="90px"
                                    className="hex_drp_FRdefineRecurrences"
                                />
                            </div>
                            <div
                                className={`${classnames({
                                    "not-allowed-icon__clz":
                                        recurrence === t("Never"),
                                })}`}
                                style={{ width: "50%" }}
                            >
                                <StyledLabel
                                    htmlFor="example-input"
                                    className={
                                        recurrence !== t("Never")
                                            ? "required__clz mt-3"
                                            : "mt-3"
                                    }
                                >
                                    {t("End date")}
                                </StyledLabel>
                                <Input
                                    style={{
                                        padding: "0px !important",
                                        height: "40px",
                                        width: "90%",
                                    }}
                                    className={classnames(
                                        "hex_drp_FRendDateRecurrences",
                                        {
                                            input__clz:
                                                inputRecurrenceEndDate ||
                                                isValidRecurrenceEndDate,
                                        }
                                    )}
                                    id="example-input"
                                    name="example-input"
                                    placeholder="Insert text"
                                    type="date"
                                    onChange={(e: any) => {
                                        if (e.target.value)
                                            setRecurrenceEndDate(
                                                e.target.value
                                            );
                                        setIsEdited(true);
                                        setInputRecurrenceEndDate(false);
                                    }}
                                    value={recurrenceEndDate}
                                />
                                {inputRecurrenceEndDate ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t("Please choose a date")}
                                    </div>
                                ) : null}
                                {isValidRecurrenceEndDate ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            marginTop: "0.25rem",
                                            fontSize: "80%",
                                            color: "#f46a6a",
                                        }}
                                    >
                                        {t("The date is incorrect")}
                                    </div>
                                ) : null}
                            </div>
                        </span>
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="mr-2 hex_btn_FRcancel"
                        variant="light"
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsEdited(false);
                                    setIsAddExceptionalHoursClicked(
                                        !isAddExceptionalHoursClicked
                                    );
                                });
                            } else {
                                setIsAddExceptionalHoursClicked(
                                    !isAddExceptionalHoursClicked
                                );
                            }
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        disabled={isValidateButtonDisabled}
                        variant="primary"
                        onClick={() => {
                            setIsValidateButtonDisabled(true);
                            handleValidateButtonOnClickEvent();
                            setTimeout(() => {
                                setIsValidateButtonDisabled(false);
                            }, 1000);
                        }}
                        className="hex_btn_FRvalidate"
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
            {isOpened ? (
                <ConfirmationModificationModal
                    isModalOpened={isOpened}
                    setIsModalOpened={setIsOpened}
                    editedExceptionalHours={dataEdited}
                    setIsAddExceptionalHoursClicked={
                        setIsAddExceptionalHoursClicked
                    }
                    associated={associated}
                    isAddExceptionalHoursClicked={isAddExceptionalHoursClicked}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    dataShop={dataShop}
                />
            ) : null}
            {isModalOpened ? (
                <ConfirmationEditMessage
                    setDataIsUpdated={setDataIsUpdated}
                    isModalOpened={isModalOpened}
                    setIsModalOpened={setIsModalOpened}
                    isAddClicked={isAddExceptionalHoursClicked}
                    setIsAddClicked={setIsAddExceptionalHoursClicked}
                    setEditedData={setRowData}
                />
            ) : null}
            {isPersonalizedModalOpened ? (
                <PersonalizedModal
                    isModalOpened={isPersonalizedModalOpened}
                    setIsModalOpened={setIsPersonalizedModalOpened}
                    endDate={recurrenceEndDate}
                    recurrenceDays={recurrenceDays}
                    setRecurrenceDays={setRecurrenceDays}
                    setEndDate={setRecurrenceEndDate}
                />
            ) : null}
            {isOverLappedModalOpened ? (
                <OverLappedMessage
                    isModalOpened={isOverLappedModalOpened}
                    setIsModalOpened={setIsOverLappedModalOpened}
                />
            ) : null}
            {isDiscardHoursModalOpened ? (
                <DiscardHoursModal
                    isModalOpened={isDiscardHoursModalOpened}
                    setIsModalOpened={setIsDiscardHoursModalOpened}
                    setHours={setHours}
                    setState={setState}
                />
            ) : null}
        </React.Fragment>
    );
}
