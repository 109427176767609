import { StyledH2 } from '@aureskonnect/react-ui';
import React from 'react'
import { useTranslation } from "react-i18next";
import { Card, Input, Label } from 'reactstrap';

export default function Delivery() {
  const { t } = useTranslation();
  return (
      <React.Fragment>
          <StyledH2
              style={{
                  font: "normal normal 600 20px/27px Nunito Sans",
                  color: "#000000",
                  opacity: "1",
              }}
              className="ml-5 px-1 pt-2"
          >
              {t("delivery management")}
          </StyledH2>
          <Card className="ml-3" style={{ width: "1500px" }}>
              <div className="d-flex flex-column p-2 ml-4">
                  <div className="d-flex justify-content-between p-2 mt-2">
                      <span
                          className="ml-1"
                          style={{
                              fontSize: "15px",
                          }}
                      >
                          {t("Delivery management by city")}
                      </span>
                      <Label>
                          <Input
                              type="radio"
                              name="radio1"
                              defaultChecked={true}
                              onClick={(e: any) => {}}
                              onCheckColor={"#6F763F"}
                          />
                      </Label>
                  </div>
                  <div className="d-flex justify-content-between p-2 mt-2">
                      <span
                          className="ml-1"
                          style={{
                              fontSize: "15px",
                          }}
                      >
                          {t("Delivery management by zone (in kilometers)")}
                      </span>
                      <Label>
                          <Input
                              type="radio"
                              name="radio1"
                              defaultChecked={true}
                              className="Cgt_chk_definiteTime"
                              onClick={(e: any) => {}}
                              onCheckColor={"#6F763F"}
                          />
                      </Label>
                  </div>
              </div>
          </Card>
      </React.Fragment>
  );
}

 