import { proxy } from "valtio";
type StoreKitchenTicketType = {
    noteKitchenTicketPrintNote: boolean;
    customerAddressModeSale: any[],
    ticketKitchenModeSale: any[],
    customerInformationModeSaleName: any[];
    customerInformationModeSaleNumTel: any[];
    customerInformationModeSaleAdress: any[];
    customerInformationModeSaleCity: any[];
    customerInformationModeSaleCode: any[];
    customerInformationModeSaleCompany: any[]
};

export const storeKitchenTicketType = proxy<StoreKitchenTicketType>({
    noteKitchenTicketPrintNote: false,
    customerAddressModeSale: [],
    ticketKitchenModeSale: [],
    customerInformationModeSaleName: [],
    customerInformationModeSaleNumTel: [],
    customerInformationModeSaleAdress: [],
    customerInformationModeSaleCity: [],
    customerInformationModeSaleCode: [],
    customerInformationModeSaleCompany: []

});


export function setNoteKitchenTicket(
    noteKitchenTicketPrintNote: boolean
): void {
    storeKitchenTicketType.noteKitchenTicketPrintNote = noteKitchenTicketPrintNote;
}
export function setCustomerAddressModeSale(
    customerAddressModeSale: any[]
): void {
    storeKitchenTicketType.customerAddressModeSale = customerAddressModeSale;
}
export function setTicketKitchenModeSale
    (
        ticketKitchenModeSale: any[]
    ): void {
    storeKitchenTicketType.ticketKitchenModeSale = ticketKitchenModeSale;
}
export function setCustomerInformationModeSaleName
    (
        customerInformationModeSaleName: any[]
    ): void {
    storeKitchenTicketType.customerInformationModeSaleName = customerInformationModeSaleName;
}
export function setCustomerInformationModeSaleNumTel
    (
        customerInformationModeSaleNumTel: any[]
    ): void {
    storeKitchenTicketType.customerInformationModeSaleNumTel = customerInformationModeSaleNumTel;
}
export function setCustomerInformationModeSaleCity
    (
        customerInformationModeSaleCity: any[]
    ): void {
    storeKitchenTicketType.customerInformationModeSaleCity = customerInformationModeSaleCity;
}
export function setCustomerInformationModeSaleAdress
    (
        customerInformationModeSaleAdress: any[]
    ): void {
    storeKitchenTicketType.customerInformationModeSaleAdress = customerInformationModeSaleAdress;
}
// export function setCustomerInformationModeSaleCode
//     (
//         customerInformationModeSaleCode: any[]
//     ): void {
//     storeKitchenTicketType.customerInformationModeSaleCode = customerInformationModeSaleCode;
// }
export function setCustomerInformationModeSaleCode
    (
        customerInformationModeSaleCode: any[]
    ): void {
    storeKitchenTicketType.customerInformationModeSaleCode = customerInformationModeSaleCode;
}
export function setCustomerInformationModeSaleCompany
    (
        customerInformationModeSaleCompany: any[]
    ): void {
    storeKitchenTicketType.customerInformationModeSaleCompany = customerInformationModeSaleCompany;
}



export function resetStore() {
    storeKitchenTicketType.noteKitchenTicketPrintNote = false;
    storeKitchenTicketType.customerAddressModeSale = [];
    storeKitchenTicketType.ticketKitchenModeSale = [];
    storeKitchenTicketType.customerInformationModeSaleName = [];
    storeKitchenTicketType.customerInformationModeSaleNumTel = [];
    storeKitchenTicketType.customerInformationModeSaleAdress = [];
    storeKitchenTicketType.customerInformationModeSaleCity = [];
    storeKitchenTicketType.customerInformationModeSaleCode = [];
    storeKitchenTicketType.customerInformationModeSaleCompany = [];

}

export default storeKitchenTicketType;
