import React from "react";
import { useSnapshot } from "valtio";
import SwiperCore, { Autoplay } from "swiper";

import "swiper/swiper.min.css";
import { store } from "../../store";

SwiperCore.use([Autoplay]);

export function ActivateStateScreenPreview({ activeStateScreenItems }:any): JSX.Element {
   

    const activeScreens: StandbyScreenItemType[] = activeStateScreenItems.filter(
        (item: StandbyScreenItemType) => item.active
    );

    return (
        <div
            className="d-flex justify-content-center align-items-center"
            style={{
                width: "470px",
                height: "700px",
            }}
        >
            {activeScreens.map((item: StandbyScreenItemType) =>
                item.type.includes("video") ? (
                    <video
                        autoPlay
                        muted
                        loop
                        className="h-100 w-100"
                        style={{
                            objectFit: "fill",
                        }}
                        src={
                            item.content.includes("http") ||
                            item.content.includes("https")
                                ? item.content
                                : `./images/${item.content}`
                        }
                    ></video>
                ) : (
                    <img
                        alt="img"
                        className="h-100 w-100"
                        src={
                            item.content.includes("http") ||
                            item.content.includes("https")
                                ? item.content
                                : `./images/${item.content}`
                        }
                    />
                )
            )}
        </div>
    );
}
