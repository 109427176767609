import React from "react";
import { Nav, NavItem, TabContent, TabPane, NavLink } from "reactstrap";
import classNames from "classnames";
import { t } from "i18next";

import { setActiveTabIndex } from "@store";

import { Model } from "@pages/GeneralConfig/Design/Content/StandbyScreen/Model";
import { Design } from "@pages/GeneralConfig/Design/Content/StandbyScreen/Design";

import "@pages/GeneralConfig/Design/index.css";

export function StandbyScreenContent(): JSX.Element {
    const tabs: TabsType[] = [
        {
            title: t("Model"),
            content: <Model />,
        },
        {
            title: t("Design"),
            content: <Design />,
        },
    ];

    const [activeTab, setActiveTab] = React.useState<number>(0);

    function toggleNav(tab: number) {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    React.useEffect(() => {
        setActiveTabIndex(activeTab === 1 ? 0 : -1);
    }, [activeTab]);

    return (
        <div>
            <div className="cursor__clz pl-3 py-2" style={{ fontSize: "22px" }}>
                {t("Step: Home")}
            </div>
            <div>
                <Nav
                    tabs
                    className="bg-white"
                    style={{
                        width: "40%",
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                    }}
                >
                    {tabs.map((element, index) => {
                        return (
                            <NavItem
                                key={index}
                                className={classNames("border", {
                                    "advanced-nav-item-active__clz":
                                        activeTab === index,
                                })}
                            >
                                <NavLink
                                    className={classNames(
                                        "border-0 sales-methods-nav-link__clz w-100",
                                        {
                                            "active nav-tabs-active-link__clz":
                                                activeTab === index,
                                        }
                                    )}
                                    onClick={() => {
                                        toggleNav(index);
                                    }}
                                >
                                    <span className="px-2">
                                        {element.title}
                                    </span>
                                </NavLink>
                            </NavItem>
                        );
                    })}
                </Nav>

                <TabContent
                    activeTab={activeTab}
                    style={{
                        height: "93%",
                    }}
                    className="w-100 bg-white border-0 rounded"
                >
                    {tabs.map((element, index) => {
                        return (
                            <TabPane
                                tabId={index}
                                className="h-100 w-100"
                                key={index}
                            >
                                {element.content}
                            </TabPane>
                        );
                    })}
                </TabContent>
            </div>
        </div>
    );
}
