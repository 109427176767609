import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { CheckboxIcon, StyledH2, StyledModal } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { mutate } from "swr";
import { toast } from "react-toastify";

import ErrorToast from "@components/Common/ErrorTost";

import { store } from "@components/VerticalLayout/store";
import { store as dataStore } from "../store";

import { CustomIconsAssociateModal } from "./CustomIconsAssociateModal";
import { ConfirmationMessage } from "./ConfirmationMessage";
import { ConfirmationAssociatedMessage } from "./ConfirmationAssociatedMessage";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type ServicesType = {
    isAssociateServiceOpened: boolean;
    setIsAssociateServiceOpened: Function;
    idShops: string[];
    selectedShopsRows: any;
    setIsUpdatedModification: Function;
    setGlobalDataUpdated: Function;
};

export function ServiceAssociateModal({
    setIsAssociateServiceOpened,
    isAssociateServiceOpened,
    idShops,
    selectedShopsRows,
    setIsUpdatedModification,
    setGlobalDataUpdated,
}: ServicesType) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const { activeServices, serviceStoreArchived } = useSnapshot(dataStore);
    const shopId = 0;
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isConfirmationModal, setIsConfiramationModal] = React.useState<
        boolean
    >(false);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [selectedServices, setSelectedServices] = React.useState<any[]>([]);
    const [isValidateClickedModal, setIsValidateClickedModal] = React.useState<
        boolean | number
    >(false);
    const [isConfirmation, setIsConfiramation] = React.useState<boolean>(false);
    const [isStateChanged, setIsStateChanged] = React.useState<boolean>(false);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);

    const url =
        selectedShopsRows.length === 1
            ? `${process.env.REACT_APP_API_V2_URL}/settings/general/services/shops?userId=${userID}&shopId=${selectedShopsRows[0].shopId}&franchiseId=${franchiseID}`
            : `${process.env.REACT_APP_API_V2_URL}/settings/general/services?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=activated`;

    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 4,
            columnName: t("Tag"),
            customJsx: (e: any) => {
                return <span>{`${e.selectedRow?.tag}`}</span>;
            },
            filterName: "tag",
            canFilter: true,
        },
        {
            indexOFColumn: 5,
            columnName: t("Action"),
            customJsx: (e: any) => (
                <CustomIconsAssociateModal
                    element={e}
                    isOneShopClicked={
                        selectedShopsRows.filter(
                            (el: any) => el.subRows !== undefined
                        ).length < 2
                    }
                    selectedServices={selectedServices}
                />
            ),
        },
    ];
    React.useEffect(() => {
        selectedRows.forEach(async (el: any) => {
            let serviceArchived: any = [...serviceStoreArchived];
            Object.values(serviceArchived).forEach((element: any) => {
                if (Object.keys(element).includes(el.Id)) {
                    setIsConfiramation(true);
                }
            });
        });
    }, [serviceStoreArchived, selectedRows]);

    React.useEffect(() => {
        let localLogo: any = [];
        selectedShopsRows.forEach((el: any) => {
            if (el?.subRows !== undefined)
                el?.subRows.forEach((element: any) => {
                    localLogo.push(element);
                });
        });
        setSelectedServices(localLogo);
        let notChanged: any = [];
        localLogo.forEach((el: any) => {
            selectedRows?.forEach((element: any) => {
                if (element.Id === el.Id) {
                    let test: any = activeServices.find(
                        (element: any) => element.id === el.Id
                    );
                    notChanged.push(test.state === el.isActive);
                }
            });
        });

        setIsStateChanged(notChanged.includes(false));
    }, [selectedShopsRows, selectedRows, activeServices]);

    async function handleAssociateServicesButtonClick() {
        let objectData: any = {};
        let localData: any = [];
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/services`;
        for (let element of idShops) {
            if (selectedRows.length !== 0) {
                selectedRows.forEach(async (el: any) => {
                    Object.assign(objectData, {
                        [el.Id]: {
                            designation: el[t("Designation")],
                            heure_début: el[t("Start hour")],
                            heure_fin: el[t("End hour")],
                            stateShop: el.state,
                            activeDays: el?.activeDays,
                            jour_de_la_semaine: el[t("Day of the week")],
                            shopId: element,

                            tag: el.tag,
                            isActive: activeServices.find(
                                (element: any) => element.id === el.Id
                            ).state,
                            isArchived: el.isArchived,
                        },
                    });
                });
                let dataService = {
                    affectation: !isStateChanged ? true : false,
                    userId: userID,
                    shopId: [element],
                    franchiseId: franchiseID,
                    data: objectData,
                    operatorId: operatorID,
                };
                localData.push(
                    fetch(apiUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(dataService),
                    }).then((response) => response.json())
                );
            }
        }

        try {
            mutate(
                apiUrl,
                await Promise.all(localData).then((result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                        toast.success(
                            !isStateChanged
                                ? `${t("The assignment was successful")}`
                                : `${t("The reassignment was successful")}`,

                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsAssociateServiceOpened(!isAssociateServiceOpened);
                        setDataIsUpdated!(true);
                        setSelectedRows([]);
                        setGlobalDataUpdated!(true);
                        setIsValidateClickedModal(false);
                        setIsUpdatedModification(false);
                    } else {
                        throw Error(result.message);
                    }
                })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    React.useEffect(() => {
        if (isValidateClickedModal === true)
            handleAssociateServicesButtonClick();

        // eslint-disable-next-line
    }, [isValidateClickedModal]);
    return (
        <React.Fragment>
            <StyledModal
                toggle={true}
                isOpen={isAssociateServiceOpened!}
                centered
                className="modal-wrapper__clz"
                backdrop="static"
                fade={false}
                scrollable
            >
                <ModalHeader
                    className="text-capitalize srv_icn_AFclose"
                    toggle={() => {
                        setIsAssociateServiceOpened(!isAssociateServiceOpened);
                    }}
                >
                    <StyledH2 className="text-uppercase srv_txt_AFserviceList">
                        {t("List of services")}
                    </StyledH2>
                </ModalHeader>
                <ModalBody>
                    <DynamicTable
                        url={url}
                        canSelect
                        customSelect
                        canResize
                        setSelectedRows={setSelectedRows}
                        canMovedCheckboxLeftOnExpand
                        requestHeader={{ "Accept-Language": i18nextLng }}
                        actionColumn={ActionColumn}
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        showGlobalFilter
                        setDataIsUpdated={setDataIsUpdated}
                        dataIsUpdated={dataIsUpdated}
                        elevationTable={1}
                        minHeight="500px"
                        maxHeight="500px"
                        name="associateModeOfSale"
                    />
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <CustomSecondaryColorButton
                        outline
                        variant="light"
                        className="srv_btn_AFcancel"
                        onClick={() => {
                            setIsAssociateServiceOpened(
                                !isAssociateServiceOpened
                            );
                        }}
                        rounded
                    >
                        {t("Cancel")}
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        className="srv_btn_AFvalidate"
                        onClick={() => {
                            if (selectedRows.length === 0) {
                                ErrorToast(
                                    t(
                                        "Attention ! You must select at least one service"
                                    )
                                );
                            } else if (isStateChanged) {
                                setIsModalOpened(true);
                            } else if (isConfirmation) {
                                setIsConfiramationModal(true);
                            } else {
                                handleAssociateServicesButtonClick();
                            }
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </ModalFooter>
            </StyledModal>
            {isModalOpened ? (
                <ConfirmationMessage
                    msg={t(
                        'Do you want to apply these changes for all stores? \n Attention ! If you click on "Validate", the specific settings of the shops will be modified.'
                    )}
                    setIsModalOpened={setIsModalOpened}
                    isModalOpened={isModalOpened}
                    setIsValidateClickedModal={setIsValidateClickedModal}
                />
            ) : null}
            {isConfirmationModal ? (
                <ConfirmationAssociatedMessage
                    isConfirmationModal={isConfirmationModal}
                    selectedRows={selectedRows}
                    setIsConfiramationModal={setIsConfiramationModal}
                    idShops={idShops}
                    setIsUpdatedModification={setIsUpdatedModification}
                    setGlobalDataUpdated={setGlobalDataUpdated}
                    setIsAssociateServiceOpened={setIsAssociateServiceOpened}
                    setIsValidateClickedModal={setIsValidateClickedModal}
                    setSelectedRows={setSelectedRows}
                />
            ) : null}
        </React.Fragment>
    );
}
