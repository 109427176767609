import React from "react";
import classnames from "classnames";
import ReactSwitch from "react-switch";
import { toast } from "react-toastify";

import { CheckboxIcon, EyeIcon, TrashIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { Tooltip } from "@mui/material";
import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import { store as service } from "../../../../components/VerticalLayout/store";
import store, {
    setIsHistoryShopsModalOpened,
    setNumberActiveMultiShop,
    setNumberArchiveMultiShop,
    setSelectedServices,
} from "../store";
import { ConfirmationArchive } from "../ConfirmationArchive";

export function CustomIcons({
    selectedRowData,
    setDataIsUpdated,
    isServicesArchived,
    selectedRows,
}: any) {
    const { t } = useTranslation();
    const {
        numberActiveMultiShop,
        numberArchiveMultiShop,
        isHistoryShopsModalOpened,
        servicesData,
        modify,
    } = useSnapshot(store);
    const { franchiseID, userID, operatorID } = useSnapshot(service);
    const [isArchiveModalOpened, setIsArchiveModalOpened] = React.useState<
        boolean
    >(false);

    const [choiceState, setServiceState] = React.useState<boolean>(
        selectedRowData.selectedRow?.isActive
            ? selectedRowData.selectedRow?.isActive
            : false
    );
    function ArchivedService() {
        let idsAssociated: any[] = [];
        if (servicesData !== undefined) {
            servicesData.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    let dataArchive = ArchivedService();

    async function handleStateChange(data: boolean) {
        let id = selectedRowData?.selectedRow.Id;
        let obj = {
            userId: userID,
            shopId: [selectedRowData?.selectedRow.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            data: {
                [id]: {
                    designation: selectedRowData?.selectedRow[t("Designation")],
                    tag: selectedRowData?.selectedRow.tag,
                    shopId: selectedRowData?.selectedRow.shopId,
                    isArchived: selectedRowData?.selectedRow.isArchived,
                    isActive: data,
                    heure_début: selectedRowData?.selectedRow[t("Start hour")],
                    heure_fin: selectedRowData?.selectedRow[t("End hour")],
                    activeDays: selectedRowData?.selectedRow?.activeDays,
                    jour_de_la_semaine:
                        selectedRowData.selectedRow[t("Day of the week")],
                },
            },
        };

        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/services`;
        mutate(
            apiUrl,
            await fetch(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(obj),
                method: "POST",
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }

                    toast.success(
                        !choiceState
                            ? `${t("service has been successfully activated")}`
                            : `${t(
                                  "service has been successfully deactivated"
                              )}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setDataIsUpdated!(true);
                })
                .catch((error: any) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }

    async function handleActiveServices(selectedRowData: any) {
        toast.dismiss();
        let dataService = {
            userId: userID,
            shopId: [selectedRowData.selectedRow.shopId],
            franchiseId: franchiseID,
            isShop: true,
            operatorId: operatorID,
            data: {
                [selectedRowData.selectedRow.Id]: {
                    designation: selectedRowData.selectedRow[t("Designation")],
                    heure_début: selectedRowData.selectedRow[t("Start hour")],
                    heure_fin: selectedRowData.selectedRow[t("End hour")],
                    jour_de_la_semaine:
                        selectedRowData.selectedRow[t("Day of the week")],
                    shopId: selectedRowData.selectedRow.shopId,
                    tag: selectedRowData.selectedRow.tag,
                    isActive: selectedRowData.selectedRow.isActive,
                    activeDays: selectedRowData.selectedRow.activeDays,
                    isArchived: false,
                },
            },
        };

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/services`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataService),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t("The service was successfully unarchived")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated!(true);
                        setNumberActiveMultiShop(numberActiveMultiShop + 1);
                        setNumberArchiveMultiShop(numberArchiveMultiShop - 1);
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <React.Fragment>
            {selectedRowData.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    {modify === true ? (
                        <>
                            <Tooltip
                                title={
                                    isServicesArchived
                                        ? ""
                                        : choiceState === false
                                        ? t("Activate")
                                        : t("Unactivate")
                                }
                            >
                                <div>
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={choiceState}
                                        onChange={async (e: boolean) => {
                                            setServiceState(!choiceState);

                                            handleStateChange(e);
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                        className={`${classnames(
                                            "pointer__clz ml-2 cmn_icn_BTenableDisable",
                                            {
                                                "not-allowed-icon__clz": isServicesArchived,
                                            }
                                        )}`}
                                    />
                                </div>
                            </Tooltip>

                            {!isServicesArchived ? (
                                <Tooltip title={t("Archive")}>
                                    <div>
                                        <TrashIcon
                                            onClick={() => {
                                                setIsArchiveModalOpened(
                                                    !isArchiveModalOpened
                                                );
                                            }}
                                            height={25}
                                            width={25}
                                            fill="red"
                                            style={{ cursor: "pointer" }}
                                            className="ml-2 cmn_icn_BTarchived"
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={
                                        dataArchive.includes(
                                            selectedRowData.selectedRow.Id
                                        )
                                            ? ""
                                            : t("Unarchive")
                                    }
                                >
                                    <div>
                                        <EyeIcon
                                            height={25}
                                            width={25}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                handleActiveServices(
                                                    selectedRowData
                                                );
                                            }}
                                            className={`${classnames(
                                                "pointer__clz ml-2 cmn_icn_BTunarchive",
                                                {
                                                    "not-allowed-icon__clz": dataArchive.includes(
                                                        selectedRowData
                                                            .selectedRow.Id
                                                    ),
                                                }
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : null}
                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                className="pointer__clz ml-2 cmn_icn_BThistory"
                                onClick={() => {
                                    setIsHistoryShopsModalOpened(
                                        !isHistoryShopsModalOpened
                                    );

                                    setSelectedServices([
                                        {
                                            [selectedRowData.selectedRow
                                                .shopId]:
                                                selectedRowData.selectedRow.Id,
                                        },
                                    ]);
                                }}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
            {isArchiveModalOpened ? (
                <ConfirmationArchive
                    isModalOpened={isArchiveModalOpened}
                    setIsModalOpened={setIsArchiveModalOpened}
                    e={selectedRowData}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                />
            ) : null}
        </React.Fragment>
    );
}
