import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import ReactSwitch from "react-switch";
import { IoIosArrowBack } from "react-icons/io";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { uid } from "react-uid";
import SimpleBar from "simplebar-react";

import {
    designStore,
    generalConfigStore,
    setIsInformationModesPersonalized,
    setIsIconActive,
    setIsSubStepLogoActive,
    setIsSubStepTopBannerActive,
    setSubStepActionsButtonsItems,
    setIsPageIconActive,
    setIsSubStepInformationMessageActive,
    setIsSubStepPageLogoActive,
    setIsSubStepPageTopBannerActive,
    setSubStepPageActionsButtonsItems,
    setIsSubStepPageInformationMessageActive,
    setIsSubStepHeaderActive,
    setIsSubStepPageHeaderActive,
    setProjectFiles,
    setSubStepInformationMessageItems,
    setIsSubStepActionButtonsActive,
} from "@store";

import { Uploader } from "@components/Uploader";

import "./index.css";

export function PersonalizedInformationModes(): JSX.Element {
    const { t } = useTranslation();

    const {
        project: { template, files: images },
    } = useSnapshot(generalConfigStore);
    const {
        isSubStepInformationMessageActive,
        isInformationModesPersonalized,
        selectedInformationModes,
        isIconActive,
        isSubStepLogoActive,
        isSubStepTopBannerActive,
        subStepActionsButtonsItems,
        selectedLanguage,
        isSubStepHeaderActive,
    } = useSnapshot(designStore);

    const [uploaderImageKey, setUploaderImageKey] = React.useState<string>();
    const [
        uploaderBackgroundImageKey,
        setUploaderBackgroundImageKey,
    ] = React.useState<string>();

    function handleSwitchOnChangeEvent(id: string) {
        let newState = [...subStepActionsButtonsItems];
        newState = newState.map((item: ActionsButtonsDesignItemType) => {
            if (item.id === id) {
                return {
                    ...item,
                    active: !item.active,
                };
            }
            return { ...item };
        });

        setSubStepActionsButtonsItems(newState);
        setSubStepPageActionsButtonsItems(
            newState,
            (selectedInformationModes as ProjectMainContentItemType)
                .shortName as string
        );
        return newState;
    }

    function handleResetImagesOnClickEvent(id: string) {
        const imagesArray = [...images];

        const image = imagesArray.find((element) => element.id === id);

        if (image !== undefined) {
            const imageIndex = imagesArray.indexOf(image);

            const localImageContent = image.content as ImageItemLanguagesType;
            if (typeof imagesArray[imageIndex].content !== "string") {
                imagesArray[imageIndex] = {
                    ...image,
                    content: {
                        ...localImageContent,
                        [selectedLanguage as string]: {
                            ...localImageContent[selectedLanguage as string],
                            path:
                                localImageContent[selectedLanguage as string]
                                    .defaultImage,
                        },
                    } as ImageItemLanguagesType,
                };
            } else {
                imagesArray[imageIndex] = {
                    ...image,
                    content: image.defaultImage as string,
                };
            }

            setProjectFiles(imagesArray);
            setUploaderImageKey(uid(imagesArray));
        }
    }

    function handleInformationMessageSwitchOnClickEvent() {
        if (isSubStepHeaderActive === false) {
            setIsSubStepHeaderActive(true);
            setIsSubStepPageHeaderActive(
                true,
                (selectedInformationModes as ProjectMainContentItemType)
                    .shortName as string
            );
        }

        setIsSubStepInformationMessageActive(
            !isSubStepInformationMessageActive
        );
        setIsSubStepPageInformationMessageActive(
            !isSubStepInformationMessageActive,
            (selectedInformationModes as ProjectMainContentItemType)
                .shortName as string
        );

        if (isSubStepInformationMessageActive === true) {
            setIsSubStepHeaderActive(false);
            setIsSubStepPageHeaderActive(
                false,
                (selectedInformationModes as ProjectMainContentItemType)
                    .shortName as string
            );
        }
    }

    function handleResetBackgroundImageOnClickEvent(id: string) {
        const imagesArray = [...images];

        const image = imagesArray.find((element) => element.id === id);

        if (image !== undefined) {
            const imageIndex = imagesArray.indexOf(image);

            imagesArray[imageIndex] = {
                ...image,
                content: image.defaultImage as string,
            };
        }
        setProjectFiles(imagesArray);
        setUploaderBackgroundImageKey(uid(imagesArray));
    }

    React.useEffect(() => {
        setIsSubStepLogoActive(
            (template.pages.subSteps[
                (selectedInformationModes as ProjectMainContentItemType)
                    .shortName as string
            ] as PageType)?.topBanner.logo.active
        );
        setIsSubStepTopBannerActive(
            (template.pages.subSteps[
                (selectedInformationModes as ProjectMainContentItemType)
                    .shortName as string
            ] as PageType).topBanner.active
        );
        setIsSubStepActionButtonsActive(
            (template.pages.subSteps[
                (selectedInformationModes as ProjectMainContentItemType)
                    .shortName as string
            ] as PageType).actionsButtons.active
        );
        setSubStepActionsButtonsItems(
            (template.pages.subSteps[
                (selectedInformationModes as ProjectMainContentItemType)
                    .shortName as string
            ] as PageType).actionsButtons.items.filter(
                (item: ActionsButtonsDesignItemType) => {
                    return (
                        item.role === 1 || item.role === 2 || item.role === 3
                    );
                }
            )
        );
        setIsSubStepInformationMessageActive(
            (template.pages.subSteps[
                (selectedInformationModes as ProjectMainContentItemType)
                    .shortName as string
            ] as PageType).header.informationMessage.active
        );
        setIsIconActive(
            (template.pages.subSteps[
                (selectedInformationModes as ProjectMainContentItemType)
                    .shortName as string
            ] as PageType).header.icon?.active as boolean
        );
        setIsSubStepHeaderActive(
            (template.pages.subSteps[
                (selectedInformationModes as ProjectMainContentItemType)
                    .shortName as string
            ] as PageType).header.active
        );
        setSubStepInformationMessageItems(
            (template.pages.subSteps[
                (selectedInformationModes as ProjectMainContentItemType)
                    .shortName as string
            ] as PageType).header.informationMessage.languages
        );
        // eslint-disable-next-line
    }, [template]);

    return (
        <div
            className="mt-4 rounded border"
            style={{
                width: "93%",
                height: "96%",
                borderColor: "#CECECE",
            }}
        >
            <div
                className="d-flex align-items-center"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                    gap: "30px",
                }}
            >
                <div
                    className="d-flex align-items-center"
                    style={{
                        backgroundColor: "#8a8787",
                        gap: "8px",
                        cursor: "pointer",
                        borderTopLeftRadius: "0.25rem",
                        height: "40px",
                        width: "280px",
                    }}
                    onClick={() => {
                        setIsInformationModesPersonalized(
                            !isInformationModesPersonalized
                        );
                    }}
                >
                    <IoIosArrowBack
                        style={{
                            fill: "white",
                            marginLeft: "10px",
                        }}
                    />
                    <div className="text-white">{t("Information modes")}</div>
                </div>
                <div className="cursor__clz">
                    {t(
                        (selectedInformationModes as ProjectMainContentItemType)
                            ?.name
                    )}
                </div>
            </div>
            <SimpleBar autoHide={true} style={{ maxHeight: 184 }}>
                <div className="px-4 py-2">
                    <div className="d-flex justify-content-between mb-3">
                        <label>{t("Background")}</label>
                        <div className="d-flex flex-row align-items-center ">
                            <StyledIconButton
                                title={t("Reset")}
                                className="m-0"
                                icon="RefreshIcon"
                                onClick={() => {
                                    handleResetBackgroundImageOnClickEvent(
                                        (template.pages.subSteps[
                                            (selectedInformationModes as ProjectMainContentItemType)
                                                .shortName as string
                                        ] as PageType).background.id as string
                                    );
                                }}
                            >
                                <RefreshIcon height={15} width={15} />
                            </StyledIconButton>
                            <div>
                                <Uploader
                                    key={uploaderBackgroundImageKey}
                                    uploadedFileTitle={
                                        (template.pages.subSteps[
                                            (selectedInformationModes as ProjectMainContentItemType)
                                                .shortName as string
                                        ] as PageType).background !== undefined
                                            ? ((template.pages.subSteps[
                                                  (selectedInformationModes as ProjectMainContentItemType)
                                                      .shortName as string
                                              ] as PageType).background
                                                  .content as string)
                                            : undefined
                                    }
                                    id={
                                        (template.pages.subSteps[
                                            (selectedInformationModes as ProjectMainContentItemType)
                                                .shortName as string
                                        ] as PageType).background.id as string
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <label>{t("Logo")}</label>
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isSubStepLogoActive}
                            onChange={() => {
                                setIsSubStepLogoActive(!isSubStepLogoActive);
                                setIsSubStepPageLogoActive(
                                    !isSubStepLogoActive,
                                    (selectedInformationModes as ProjectMainContentItemType)
                                        .shortName as string
                                );

                                setIsSubStepTopBannerActive(
                                    !isSubStepTopBannerActive
                                );
                                setIsSubStepPageTopBannerActive(
                                    !isSubStepTopBannerActive,
                                    (selectedInformationModes as ProjectMainContentItemType)
                                        .shortName as string
                                );
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <label>{t("Icon")}</label>
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isIconActive}
                            onChange={() => {
                                setIsIconActive(!isIconActive);
                                setIsPageIconActive(
                                    !isIconActive,
                                    (selectedInformationModes as ProjectMainContentItemType)
                                        .shortName as string
                                );
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                    {isIconActive ? (
                        <div className="d-flex justify-content-between  mb-3">
                            <label>{t("File")}</label>
                            <div className="d-flex flex-row align-items-center">
                                <StyledIconButton
                                    title={t("Reset")}
                                    icon="RefreshIcon"
                                    style={{ margin: "0px" }}
                                    onClick={() => {
                                        handleResetImagesOnClickEvent(
                                            ((template.pages.subSteps[
                                                (selectedInformationModes as ProjectMainContentItemType)
                                                    .shortName as string
                                            ] as PageType).header
                                                .icon as IconType).id
                                        );
                                    }}
                                >
                                    <RefreshIcon height={15} width={15} />
                                </StyledIconButton>
                                <div>
                                    <Uploader
                                        key={uploaderImageKey}
                                        uploadedFileTitle={
                                            ((template.pages.subSteps[
                                                (selectedInformationModes as ProjectMainContentItemType)
                                                    .shortName as string
                                            ] as PageType).header
                                                .icon as IconType) !== undefined
                                                ? ((template.pages.subSteps[
                                                      (selectedInformationModes as ProjectMainContentItemType)
                                                          .shortName as string
                                                  ] as PageType).header
                                                      .icon as IconType).content
                                                : undefined
                                        }
                                        id={
                                            (template.pages.subSteps[
                                                (selectedInformationModes as ProjectMainContentItemType)
                                                    .shortName as string
                                            ] as PageType).header.icon?.id
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    ) : null}
                    <div className="d-flex justify-content-between  mb-3">
                        <label>{t("Text")}</label>
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isSubStepInformationMessageActive}
                            onChange={() => {
                                handleInformationMessageSwitchOnClickEvent();
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                    {subStepActionsButtonsItems
                        .filter((item: ActionsButtonsDesignItemType) => {
                            return item.name.toLowerCase() !== "validate";
                        })
                        .map((item: ActionsButtonsDesignItemType) => {
                            return (
                                <div
                                    key={item.id}
                                    className="d-flex justify-content-between mb-3"
                                >
                                    <label>{t(item.name)}</label>
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={item.active}
                                        onChange={() =>
                                            handleSwitchOnChangeEvent(item.id)
                                        }
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                    />
                                </div>
                            );
                        })}
                </div>
            </SimpleBar>
        </div>
    );
}
