import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledLabel,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { mutate } from "swr";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";

import { store } from "@components/VerticalLayout/store";
import {
    store as saleSupportStore,
    setFranchiseDataIsUpdated,
    setShopDataIsUpdated,
} from "../store";

import { capitalize } from "@helpers/general";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationFavoriteType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    selectedRowData: any;
};

export function ConfirmationFavorite({
    isModalOpened,
    setIsModalOpened,
    selectedRowData,
}: ConfirmationFavoriteType) {
    const { t } = useTranslation();
    const { franchiseID, userID, operatorID } = useSnapshot(store);
    const { saleSupportData, shopsSaleSupportData } = useSnapshot(
        saleSupportStore
    );

    async function favoriteSaleSupportShop(isFavorite: boolean) {
        toast.dismiss();
        let dataSS: any = [];
        saleSupportData.forEach((elt: any) => {
            shopsSaleSupportData.forEach((el: any) => {
                let favoriteSaleSupportData;
                el.subRows.forEach((saleSupport: any) => {
                    if (selectedRowData.Id === saleSupport.Id) {
                        favoriteSaleSupportData = {
                            userId: userID,
                            shopId: [el.shopId],
                            franchiseId: franchiseID,
                            operatorId: operatorID,
                            isShop: false,
                            data: {
                                [saleSupport.Id]: {
                                    id: saleSupport.Id,
                                    displayName: saleSupport[t("Display name")],
                                    tag: saleSupport.Tag,
                                    support_de_vente:
                                        saleSupport[t("Sale support")],
                                    shopId: saleSupport.shopId,
                                    isActive: saleSupport.isActive,
                                    isArchived: saleSupport.isArchived,
                                    isFavorite: isFavorite,
                                },
                            },
                        };
                        dataSS.push(favoriteSaleSupportData);
                    } else if (
                        elt.Id === saleSupport.Id &&
                        el.subRows.findIndex(
                            (elt: any) => elt.Id === selectedRowData.Id
                        ) !== -1
                    ) {
                        favoriteSaleSupportData = {
                            userId: userID,
                            shopId: [el.shopId],
                            franchiseId: franchiseID,
                            operatorId: operatorID,
                            isShop: false,
                            data: {
                                [saleSupport.Id]: {
                                    id: saleSupport.Id,
                                    displayName: saleSupport[t("Display name")],
                                    tag: saleSupport.Tag,
                                    support_de_vente:
                                        saleSupport[t("Sale support")],
                                    shopId: saleSupport.shopId,
                                    isActive: saleSupport.isActive,
                                    isArchived: saleSupport.isArchived,
                                    isFavorite: false,
                                },
                            },
                        };
                        dataSS.push(favoriteSaleSupportData);
                    }
                });
            });
        });

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataSS),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${
                                isFavorite
                                    ? `${t(
                                          "Shop favorite state has been successfully activated"
                                      )}`
                                    : `${t(
                                          "Shop favorite state has been successfully deactivated"
                                      )}`
                            }`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setFranchiseDataIsUpdated!(true);
                        setShopDataIsUpdated(true);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                }}
            >
                <StyledH2>{capitalize(t("Alert"))}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        'Do you want to apply these changes for all stores? \n Attention ! If you click on "Validate", the specific settings of the shops will be modified.'
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        favoriteSaleSupportShop(!selectedRowData.isFavorite);
                        setIsModalOpened(!isModalOpened);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
