import React, { useState } from "react";
import {
    CrossIcon,
    StyledLabel,
    StyledSelectInput,
} from "@aureskonnect/react-ui";
import { AvForm } from "availity-reactstrap-validation";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import {
    CustomCardHeader,
    CustomCardBody,
    CustomCardFooter,
} from "../../../components/Common/CustomCardStyled";

import { resetAllPeripherals } from "../../../pages/Applications/DeviceAssociateModal/store";
import { store, setActions, setIsActionsClicked } from "../store";

import { CashManagementPeripheralConfigure } from "./CashManagementPeripheralConfigure";
import { MoneticPeripheralConfigure } from "./MoneticPeripheralConfigure";
import { PrinterPeripheralConfigure } from "./PrinterPeripheralConfigure";

import { CardFooter as CardCashManagementFooter } from "./CashManagementPeripheralConfigure/CardFooter";
import { CardFooter as CardMoneticFooter } from "./MoneticPeripheralConfigure/CardFooter";
import { CardFooter as CardPrinterFooter } from "./PrinterPeripheralConfigure/CardFooter";
import { resetPrinterData } from "./PrinterPeripheralConfigure/store";
import { resetCashManagementData } from "./CashManagementPeripheralConfigure/store";
import { resetMoneticData } from "./MoneticPeripheralConfigure/store";
import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";

import "../index.css";

type peripheralConfigurationType = {
    setIsAddPeripheralClicked: Function;
    setDataIsUpdated: Function;
    isAddPeripheralClicked: boolean;
    isAddNewPeripheralButtonClicked: boolean;
    selectedRows: any;
    isComingFromModify?: boolean;
    setEditedData: Function;
    editedData: any;
    setIsEdited: Function;
    isEdited: boolean;
};

export function PeripheralConfiguration({
    setIsAddPeripheralClicked,
    isAddPeripheralClicked,
    setDataIsUpdated,
    isAddNewPeripheralButtonClicked,
    selectedRows,
    isComingFromModify,
    setEditedData,
    editedData,
    setIsEdited,
    isEdited,
}: peripheralConfigurationType) {
    const { t } = useTranslation();

    const { isActionsClicked } = useSnapshot(store);

    const [peripheralType, setPeripheralType] = useState<string>(
        editedData.peripheralType === "printer"
            ? "Imprimantes"
            : editedData.peripheralType === "monetic"
            ? "Monétiques"
            : editedData.peripheralType === "cash_management"
            ? "Monnayeurs"
            : ""
    );
    const [
        isConfirmationEditModalOpened,
        setIsConfirmationEditModalOpened,
    ] = React.useState<boolean>(false);
    const pathname = window.location.pathname.toString();

    const activateCheckbox = () => {
        let checks = document.getElementsByClassName("PrivateSwitchBase-input");
        for (let i = 0; i < checks.length; i++) {
            checks[i].classList.remove("not-allowed-icon-check__clz");
        }
    };
    React.useEffect(() => {
        if (editedData?.length === 0) {
            setPeripheralType("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAddNewPeripheralButtonClicked]);
    return (
        <AvForm className="card">
            <CustomCardHeader className="d-flex align-items-center justify-content-between">
                <h5 className="pt-3">
                    {isComingFromModify === false
                        ? t("Add device")
                        : t("Modify a device")}
                </h5>
                <CrossIcon
                    style={{ cursor: "pointer" }}
                    height={13}
                    width={13}
                    onClick={() => {
                        if (isEdited === true) {
                            setIsActionsClicked(!isActionsClicked);
                            setActions(() => {
                                setIsAddPeripheralClicked!(
                                    !isAddPeripheralClicked
                                );
                                resetAllPeripherals();
                                resetPrinterData();
                                resetCashManagementData();
                                resetMoneticData();
                                setEditedData([]);
                                setIsEdited(false);
                                activateCheckbox();
                            });
                        } else {
                            setIsAddPeripheralClicked!(!isAddPeripheralClicked);
                            resetAllPeripherals();
                            resetPrinterData();
                            resetCashManagementData();
                            resetMoneticData();
                            setEditedData([]);
                            setIsEdited(false);
                            activateCheckbox();
                        }
                    }}
                    className="afp_icn_FRcloseForm"
                />
            </CustomCardHeader>
            <CustomCardBody
                className="pl-3 pr-5"
                style={{
                    maxHeight:
                        pathname === "/peripherals/cash-management"
                            ? "530px"
                            : "512px",
                    overflowY: " scroll",
                }}
            >
                <StyledLabel style={{ marginTop: "10px" }}>
                    {t("Choice of peripherals")}
                </StyledLabel>
                <StyledSelectInput
                    value={
                        peripheralType === ""
                            ? null
                            : {
                                  label: peripheralType,
                                  value: peripheralType,
                              }
                    }
                    onChange={(e: any) => {
                        setPeripheralType(e.value);
                    }}
                    placeholder={t("Select")}
                    options={[
                        {
                            label: t("Monnayeurs"),
                            value: t("Monnayeurs"),
                        },
                        {
                            label: "Monétiques",
                            value: "Monétiques",
                        },
                        {
                            label: "Imprimantes",
                            value: "Imprimantes",
                        },
                    ]}
                    validate={{
                        required: {
                            value: true,
                            errorMessage: `${t("Please select a mark")!}`,
                        },
                    }}
                    name="mark"
                    noOptionsMessage={() => t("No options")}
                    isDisabled={isComingFromModify ? true : false}
                    className="afp_drp_peripheralChoice"
                />
                {peripheralType === "Monnayeurs" ? (
                    <CashManagementPeripheralConfigure
                        editedData={editedData}
                        isEdited={isEdited}
                        setIsEdited={setIsEdited}
                        selectedRows={selectedRows}
                    />
                ) : null}
                {peripheralType === "Monétiques" ? (
                    <MoneticPeripheralConfigure
                        editedData={editedData}
                        isAddNewCashManagementButtonClicked={
                            isAddNewPeripheralButtonClicked
                        }
                        isEdited={isEdited}
                        setIsEdited={setIsEdited}
                        selectedRows={selectedRows}
                    />
                ) : null}
                {peripheralType === "Imprimantes" ? (
                    <PrinterPeripheralConfigure
                        editedData={editedData}
                        setIsEdited={setIsEdited}
                        selectedRows={selectedRows}
                    />
                ) : null}
            </CustomCardBody>
            <CustomCardFooter className="pt-1 pb-2">
                {peripheralType === "Monétiques" ? (
                    <CardMoneticFooter
                        isEdited={isEdited}
                        setEditedData={setEditedData}
                        setDataIsUpdated={setDataIsUpdated}
                        editedData={editedData}
                        selectedRows={selectedRows}
                        setIsAddPeripheralClicked={setIsAddPeripheralClicked}
                        isAddPeripheralClicked={isAddPeripheralClicked}
                        setIsEdited={setIsEdited}
                        setIsConfirmationEditModalOpened={
                            setIsConfirmationEditModalOpened
                        }
                    />
                ) : null}
                {peripheralType === "Monnayeurs" ? (
                    <CardCashManagementFooter
                        setEditedData={setEditedData}
                        isEdited={isEdited}
                        peripheralType={peripheralType}
                        setDataIsUpdated={setDataIsUpdated}
                        editedData={editedData}
                        selectedRows={selectedRows}
                        setIsAddPeripheralClicked={setIsAddPeripheralClicked}
                        isAddPeripheralClicked={isAddPeripheralClicked}
                        setIsEdited={setIsEdited}
                        setIsConfirmationEditModalOpened={
                            setIsConfirmationEditModalOpened
                        }
                    />
                ) : null}
                {peripheralType === "Imprimantes" ? (
                    <CardPrinterFooter
                        setEditedData={setEditedData}
                        isEdited={isEdited}
                        setDataIsUpdated={setDataIsUpdated}
                        editedData={editedData}
                        selectedRows={selectedRows}
                        setIsAddPeripheralClicked={setIsAddPeripheralClicked}
                        isAddPeripheralClicked={isAddPeripheralClicked}
                        setIsEdited={setIsEdited}
                        setIsConfirmationEditModalOpened={
                            setIsConfirmationEditModalOpened
                        }
                    />
                ) : null}
            </CustomCardFooter>

            {isConfirmationEditModalOpened ? (
                <ConfirmationEditMessage
                    setDataIsUpdated={setDataIsUpdated}
                    isModalOpened={isConfirmationEditModalOpened}
                    setIsModalOpened={setIsConfirmationEditModalOpened}
                    isAddClicked={isAddPeripheralClicked}
                    setIsAddClicked={setIsAddPeripheralClicked}
                />
            ) : null}
        </AvForm>
    );
}
