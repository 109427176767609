import React from "react";
import { useSnapshot } from "valtio";

import {
    computedStore,
    designStore,
    setPageUserAccountItems,
    setUserAccountMessageItems,
} from "@store";

import { CustomInput as Input } from "@components/Common/CustomInput";

export function UserAccountRow(): JSX.Element {
    const {
        selectedLanguage,
        userAccountMessageItems,
        activeSlideIndex,
    } = useSnapshot(designStore);

    const { elements } = useSnapshot(computedStore);

    function handleUserAccountMessageItemInputOnChangeEvent(content: string) {
        const localItems: HeaderLanguageItemsType = {
            ...userAccountMessageItems,
            [selectedLanguage]: {
                defaultContent:
                    userAccountMessageItems[selectedLanguage].defaultContent,
                content: content,
            },
        };

        setUserAccountMessageItems(localItems);
        setPageUserAccountItems(localItems, elements[activeSlideIndex].name);
    }

    return (
        <tr>
            <td className="cursor__clz" style={{ width: "30%" }}>
                {userAccountMessageItems[selectedLanguage].defaultContent !==
                undefined
                    ? userAccountMessageItems[selectedLanguage].defaultContent
                    : null}
            </td>
            <td>
                <Input
                    value={
                        userAccountMessageItems[selectedLanguage].content !==
                        undefined
                            ? userAccountMessageItems[selectedLanguage].content
                            : undefined
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        handleUserAccountMessageItemInputOnChangeEvent(
                            event.target.value
                        );
                    }}
                />
            </td>
        </tr>
    );
}
