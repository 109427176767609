import React from "react";

import { OptionItem } from "@components/OptionItem";
import { FlexboxGrid } from "@components/Common/FlexboxGrid";

export type OptionsMatrixPropsType = {
    options: OptionsItemType[];
    setOptions: React.Dispatch<React.SetStateAction<OptionsItemType[]>>;
};

export function OptionsNumberBetweenFourAndSix({
    options,
    setOptions,
}: OptionsMatrixPropsType): JSX.Element {
    return (
        <FlexboxGrid
            className="flex-column"
            gap="50px"
            justifyContentCentered={true}
        >
            <FlexboxGrid justifyContentCentered={true} gap="50px">
                {options
                    .slice(0, 3)
                    .map((option: OptionsItemType, index: number) => (
                        <OptionItem
                            key={index}
                            option={option}
                            index={index}
                            setOptions={setOptions}
                        />
                    ))}
            </FlexboxGrid>
            <FlexboxGrid justifyContentCentered={true} gap="50px">
                {options
                    .slice(3)
                    .map((option: OptionsItemType, index: number) => (
                        <OptionItem
                            key={index + 3}
                            option={option}
                            index={index + 3}
                            setOptions={setOptions}
                        />
                    ))}
            </FlexboxGrid>
        </FlexboxGrid>
    );
}
