import React from "react";
import { useSnapshot } from "valtio";

import { computedStore, designStore, setActionsButtonsItems } from "@store";

import { ActionsButtonsWrapper } from "@components/Common/Design/ActionsButtonsWrapper";

export function ActionsButtons(): JSX.Element {
    const { activeSlideIndex } = useSnapshot(designStore);
    const { elements } = useSnapshot(computedStore);

    const [localActionButtons, setLocalActionButtons] = React.useState<
        ActionsButtonsDesignItemType[]
    >((elements[activeSlideIndex] as PageType).actionsButtons.items);

    React.useEffect(() => {
        setActionsButtonsItems(localActionButtons);
        // eslint-disable-next-line
    }, []);

    return (
        <ActionsButtonsWrapper
            localActionsButtonsItems={localActionButtons}
            setLocalActionsButtonsItems={setLocalActionButtons}
        />
    );
}
