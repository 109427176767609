import { CardWrapperSpecific } from "./CardWrapperSpecific";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { StyledH2 } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";

import { setItemSalesMethods, store } from "../store";

export default function SalesMethods() {
    const { t } = useTranslation();
    const { dataSetupApk, ItemSalesMethods } = useSnapshot(store);
    const [items] = React.useState<any>([
        {
            name: t("On the spot"),
            active: false,
            image: "./images/atTheCounter.png",
        },
        {
            name: t("To take"),
            active: false,
            image: "./images/toTakeAway.png",
        },
        {
            name: t("Delivery"),
            active: false,
            image: "./images/delivery.png",
        },
    ]);

    useEffect(() => {
        if (
            dataSetupApk[0] !== undefined &&
            dataSetupApk[0].ItemSalesMethods !== undefined
        ) {
            setItemSalesMethods(dataSetupApk[0].ItemSalesMethods);
        } else {
            setItemSalesMethods(items);
        }
    }, [items, dataSetupApk]);
    return (
        <React.Fragment>
            <StyledH2
                style={{
                    font: "normal normal 600 45px/61px Nunito Sans",
                    color: "#000000",
                    opacity: "1",
                }}
                className="ml-5 pt-5"
            >
                {t("Sales methods choice")}
            </StyledH2>
            <div
                className="d-flex flex-row justify-content-center align-items-center mt-5"
                style={{ gap: "100px" }}
            >
                {ItemSalesMethods.map((item: any, index: number) => (
                    <CardWrapperSpecific
                        items={ItemSalesMethods}
                        index={index}
                        setItems={setItemSalesMethods}
                    />
                ))}
            </div>
        </React.Fragment>
    );
}
