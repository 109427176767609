import React from "react";
import { Navigate, useRoutes } from "react-router-dom";

import Layout from "@components/VerticalLayout";
import Monetics from "@pages/Peripherals/Monetics";

import Printers from "@pages/Peripherals/Printers";
import Beepers from "@pages/Peripherals/Beeper";
import CashManagement from "@pages/Peripherals/CashManagement";
import Applications from "@pages/Applications";
import ApplicationHome from "@pages/Applications/Home";
import { Kiosk } from "@pages/Applications/Kiosk";
import { Uber } from "@pages/Applications/Uber";
import { SiteCC } from "@pages/Applications/C&C";
import { Orb } from "@pages/Applications/Orb";
import { Hubrise } from "@pages/Applications/Hubrise";
import { Kds } from "@pages/Applications/Kds";
import { Sco } from "@pages/Applications/Sco";
import { Dds } from "@pages/Applications/Dds";
import { Rfid } from "@pages/Applications/Rfid";
import TestSocket from "@pages/testSocket";

import { FullscreenPreview } from "@components/FullscreenPreview";
import { Kyra } from "@pages/Applications/Kyra";
import { Dashboard } from "@pages/Dashbord";
import { TabsLanguage } from "@pages/SettingGeneral/Language";
import { PaymentMethod } from "@pages/SettingGeneral/PaymentMethod";
import { Services } from "@pages/SettingGeneral/Services";
import VatRate from "@pages/SettingGeneral/VatRate";
import { ModeOfSale } from "@pages/SettingGeneral/ModeOfSale";
import { Compte } from "@pages/Compte";
import { TestAuto } from "@pages/Compte/TestAuto";

import { ModeOfInformation } from "@pages/SettingGeneral/ModeOfInformation";
import CustomerAccount from "@pages/SettingGeneral/CustomerAccount";
import { Logo } from "@pages/SettingGeneral/Logo";
import { SaleSupport } from "@pages/SettingGeneral/SaleSupport";
import { FiscalYear } from "@pages/SettingGeneral/FiscalYear";
import { GeneralConfig } from "@pages/GeneralConfig";
import { Project } from "@pages/Project";
import { Devise } from "@pages/SettingGeneral/Devise";
import { HoursShops } from "@pages/SettingGeneral/Hours";
import { Fidelity } from "@pages/SettingGeneral/Fidelity";
import Login from "@pages/Authentication/Login";
import { Callback } from "@pages/Authentication/Callback";
import AuthRouter from "./middleware/AuthRoute";
import { useLocation } from "react-router-dom";
import UpdatePassword from "@pages/Authentication/UpdatePassword";
import CCSetup from "@pages/Applications/C&C/C&CSetup";
import KdsDeviceSetup from "@pages/Applications/Kds/KdsDeviceSetup";
import KdsProjectSetup from "@pages/Applications/Kds/KdsProjectSetup";
import ScoDeviceSetup from "@pages/Applications/Sco/ScoDeviceSetup";
import ScoProjectSetup from "@pages/Applications/Sco/ScoProjectSetup";
import ProjectSetup from "@pages/Applications/Orb/ProjectSetup";
import Setup from "@pages/Applications/Orb/Setup";

export default function Router() {
    const location = useLocation();

    let matchTestAutoRoute = "/test-auto" === location.pathname;

    if (matchTestAutoRoute) {
        localStorage.setItem(
            "Uid_Entitie_User",
            "8ecfe5ee-926b-423e-8050-c911dd81c150-test-auto"
        );
        localStorage.setItem("firstName", "Test");
        localStorage.setItem("lastName", "auto");
        localStorage.setItem("email", "testauto@aureskonnect.com");
        localStorage.setItem("secondaryColor", "#292929");
        localStorage.setItem("mainColor", "#292929");
        const urlApi =
            "https://consoleadmin-api.etk360.com/apiAccess/v1/getAutorisationProfileEntity?uuidEntity=f1d6b159-2e0d-4dbd-bc8a-5876f9b4503b";
        fetch(urlApi, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((result) => {
                localStorage.setItem(
                    "authorizationSetting",
                    JSON.stringify(result)
                );
            });
    }
    return useRoutes([
        {
            element: (
                <AuthRouter>
                    <Layout />
                </AuthRouter>
            ),
            children: [
                {
                    path: "applications",
                    element: (
                        <React.Suspense fallback={null}>
                            <ApplicationHome />
                        </React.Suspense>
                    ),
                },
                {
                    path: "test",
                    element: <GeneralConfig />,
                },
                {
                    path: "compte",
                    element: (
                        <React.Suspense fallback={null}>
                            <Compte />
                        </React.Suspense>
                    ),
                },

                {
                    path: "test-auto",
                    element: (
                        <React.Suspense fallback={null}>
                            <TestAuto />
                        </React.Suspense>
                    ),
                },

                {
                    path: "dashboard",
                    element: <Dashboard />,
                },

                {
                    path: "kiosk",

                    element: (
                        <React.Suspense fallback={null}>
                            <Kiosk />
                        </React.Suspense>
                    ),
                },
                {
                    path: "uber",
                    element: <Uber />,
                },
                {
                    path: "c&c",
                    element: <SiteCC />,
                },
                {
                    path: "kds",
                    element: <Kds />,
                },
                {
                    path: "sco",
                    element: <Sco />,
                },
                {
                    path: "dds",
                    element: <Dds />,
                },
                {
                    path: "rfid",
                    element: <Rfid />,
                },
                {
                    path: "orb",
                    element: <Orb />,
                },
                {
                    path: "kyra",
                    element: <Kyra />,
                },
                {
                    path: "hubrise",
                    element: <Hubrise />,
                },
                {
                    path: "kiosk-template-setup",
                    element: <Applications />,
                },
                {
                    path: "kiosk-template-setup",
                    element: <Applications />,
                },
                {
                    path: "kiosk-peripheral-setting",
                    element: (
                        <React.Suspense fallback={null}>
                            <Applications />
                        </React.Suspense>
                    ),
                },
                {
                    path: "/project",
                    element: (
                        <React.Suspense fallback={null}>
                            <Project />
                        </React.Suspense>
                    ),
                },
                {
                    path: "/fullscreen-preview",
                    element: <FullscreenPreview />,
                },

                {
                    path: "peripherals",
                    children: [
                        {
                            path: "monetics",
                            element: <Monetics />,
                        },
                        {
                            path: "printers",
                            element: (
                                <React.Suspense fallback={null}>
                                    <Printers />
                                </React.Suspense>
                            ),
                        },
                        {
                            path: "cash-management",
                            element: (
                                <React.Suspense fallback={null}>
                                    <CashManagement />
                                </React.Suspense>
                            ),
                        },
                        {
                            path: "beepers",
                            element: (
                                <React.Suspense fallback={null}>
                                    <Beepers />
                                </React.Suspense>
                            ),
                        },
                    ],
                },
                { path: "testsocket", element: <TestSocket /> },
                { path: "/setting/fiscal-year", element: <FiscalYear /> },
                {
                    path: "/setting/payment-method",
                    element: <PaymentMethod />,
                },

                {
                    path: "setting/customer-account",
                    element: (
                        <React.Suspense fallback={null}>
                            <CustomerAccount />
                        </React.Suspense>
                    ),
                },
                {
                    path: "setting/language",
                    element: (
                        <React.Suspense fallback={null}>
                            <TabsLanguage />
                        </React.Suspense>
                    ),
                },
                {
                    path: "/setting/sale",
                    element: <ModeOfSale />,
                },
                {
                    path: "/setting/information",
                    element: (
                        <React.Suspense fallback={null}>
                            <ModeOfInformation />
                        </React.Suspense>
                    ),
                },
                {
                    path: "/setting/vat-rate",
                    element: (
                        <React.Suspense fallback={null}>
                            <VatRate />
                        </React.Suspense>
                    ),
                },
                {
                    path: "/setting/service",
                    element: <Services />,
                },
                {
                    path: "/setting/logo",
                    element: <Logo />,
                },
                {
                    path: "/setting/saleSupport",
                    element: <SaleSupport />,
                },
                {
                    path: "/setting/devise",
                    element: <Devise />,
                },
                {
                    path: "/setting/hoursShops",
                    element: <HoursShops />,
                },
                {
                    path: "/setting/fidelity",
                    element: <Fidelity />,
                },

                {
                    path: "/",
                    element: (
                        <React.Fragment>
                            <Navigate to="compte" />
                        </React.Fragment>
                    ),
                },
                {
                    path: "Configuration",
                    element: (
                        <React.Suspense fallback={null}>
                            <CCSetup />
                        </React.Suspense>
                    ),
                },
                {
                    path: "kds-peripheral-setting",
                    element: <KdsDeviceSetup />,
                },
                {
                    path: "kds-project-setting",
                    element: <KdsProjectSetup />,
                },
                {
                    path: "sco-project-setting",
                    element: <ScoProjectSetup />,
                },
                {
                    path: "sco-peripheral-setting",
                    element: <ScoDeviceSetup />,
                },

                {
                    path: "orb-project-setup",
                    element: <ProjectSetup />,
                },
                {
                    path: "orb-setup",
                    element: <Setup />,
                },
            ],
        },
        {
            element: <Login />,
            path: "login",
        },
        {
            element: <UpdatePassword />,
            path: "update",
        },
        {
            element: <Callback />,
            path: "/Callback",
        },
    ]);
}
