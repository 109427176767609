import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledLabel,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";
import moment, * as Moment from "moment";
import { extendMoment } from "moment-range";

import { setGlobalDataIsUpdated } from "../store";
import { store } from "@components/VerticalLayout/store";
import { store as ExceptionalHoursStore } from "../store";

import { capitalize } from "@helpers/general";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationModificationMessageType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    setIsAddExceptionalHoursClicked: Function;
    associated: any[];
    isAddExceptionalHoursClicked: boolean;
    setDataIsUpdated: Function;
    editedExceptionalHours: any;
    dataIsUpdated: number | boolean;
    dataShop: any[];
};

export function ConfirmationModificationModal({
    isModalOpened,
    setIsModalOpened,
    setIsAddExceptionalHoursClicked,
    associated,
    editedExceptionalHours,
    isAddExceptionalHoursClicked,
    setDataIsUpdated,
    dataIsUpdated,
    dataShop,
}: ConfirmationModificationMessageType) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const { globalDataIsUpdated } = useSnapshot(ExceptionalHoursStore);
    const momentRange = extendMoment(Moment);

    const [error, setError] = React.useState<boolean>(false);

    const checkIfSelectedRowsOverLapped = (exceptionalHours: any) => {
        let isOverLapped = false;
        dataShop.forEach((element: any) => {
            if (
                element.subRows !== undefined &&
                element.Id !== exceptionalHours.id
            ) {
                let localDate = momentRange.range(
                    moment(moment(exceptionalHours.startDate)),
                    moment(moment(exceptionalHours.endDate))
                );
                element.recurrenceList.forEach((elt: any) => {
                    let recurrenceDate = momentRange.range(
                        moment(
                            moment(elt[t("Start date")], "DD/MM/YYYY").format(
                                "YYYY-MM-DD"
                            )
                        ),
                        moment(
                            moment(elt[t("End date")], "DD/MM/YYYY").format(
                                "YYYY-MM-DD"
                            )
                        )
                    );
                    if (
                        localDate.overlaps(recurrenceDate) ||
                        localDate.adjacent(recurrenceDate)
                    ) {
                        isOverLapped = true;
                    }
                });
            }
        });

        return isOverLapped;
    };

    async function editExceptionalHours() {
        toast.dismiss();
        let objectData = {};
        let localData: any[] = [];
        let isOverLapped = false;
        associated.forEach(async (el: any) => {
            const id: any = el.Id;
            let isOverLap = checkIfSelectedRowsOverLapped(
                editedExceptionalHours[id]
            );
            if (isOverLap === true) {
                isOverLapped = true;
            }
            Object.assign(objectData, {
                [id]: {
                    id: id,
                    designation: editedExceptionalHours[id].designation,
                    state: editedExceptionalHours[id].state,
                    startDate: editedExceptionalHours[id].startDate,
                    endDate: editedExceptionalHours[id].endDate,
                    hours: editedExceptionalHours[id].hours,
                    isArchived: el.isArchived,
                    isActive: el.isActive,
                    shopId: el.shopId,
                    recurrence: editedExceptionalHours[id].recurrence,
                    endDate_recurrence:
                        editedExceptionalHours[id].endDate_recurrence,
                    recurrenceDays: editedExceptionalHours[id].recurrenceDays,
                },
            });
            let dataExceptionalHours = {
                userId: userID,
                shopId: [el.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                data: objectData,
            };
            localData.push(
                fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/exceptionalHours`,
                    {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(dataExceptionalHours),
                    }
                ).then((response) => response.json())
            );
        });
        if (isOverLapped === false) {
            try {
                mutate(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/exceptionalHours`,
                    await Promise.all(localData).then((result) => {
                        result.forEach((element: any) => {
                            if (element.error === true) setError(true);
                        });
                    })
                );
            } catch (e) {
                toast.error(`${t("There's an error")}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
            if (error === false) {
                toast.success(
                    `${t("The exceptional schedule was successfully changed")}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
                setDataIsUpdated(!dataIsUpdated);
                setIsAddExceptionalHoursClicked(!isAddExceptionalHoursClicked);
                setGlobalDataIsUpdated(!globalDataIsUpdated);
            }
        } else {
            toast.warn(
                t(
                    "Please note that the modification could not be made at one or more stores.Please check the data settings of the store(s)"
                ),
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                }
            );
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize cmn_icn_closeAlert"
                toggle={() => setIsModalOpened(!isModalOpened)}
            >
                <StyledH2 className="cmn_txt_titleAlert">
                    {capitalize(t("Alert"))}
                </StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="mn_txt_descriptionAlert pl-3 m-0 cmn_txt_descriptionAlert"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        'Do you want to apply these changes for all stores? \n Attention ! If you click on "Validate", the specific settings of the shops will be modified.'
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                    }}
                    rounded
                    className="cmn_btn_cancelAlert"
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="cmn_btn_validateAlert mr-2"
                    variant="primary"
                    onClick={() => {
                        editExceptionalHours();
                        setIsModalOpened(!isModalOpened);
                        setIsAddExceptionalHoursClicked(false);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
