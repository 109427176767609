import React from "react";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSnapshot } from "valtio";

import { generalConfigStore } from "@store";

import "swiper/swiper.min.css";

SwiperCore.use([Autoplay]);

export function StandbyScreenPreview(): JSX.Element {
    const {
        activeAdvertisingScreenTab,
        standbyScreenFile,
        activeStandbyScreenFile,
        imageKey,
    } = useSnapshot(generalConfigStore);

    const activeScreens: StandbyScreenItemType[] = (activeAdvertisingScreenTab ===
    0
        ? activeStandbyScreenFile
        : standbyScreenFile
    ).filter((item: StandbyScreenItemType) => item.active === true);

    return (
        <div className="mt-2">
            {activeScreens.length > 1 ? (
                <Swiper
                    loop={true}
                    autoplay={{
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                    }}
                >
                    {activeScreens.map((item: StandbyScreenItemType) => {
                        const imageContent = item.content;
                        return (
                            <SwiperSlide
                                key={imageKey}
                                data-swiper-autoplay={item.timeout * 1000}
                            >
                                {item.type.includes("video") ? (
                                    <video
                                        autoPlay
                                        muted
                                        loop
                                        style={{
                                            width: "95%",
                                            height: "725px",
                                            objectFit: "fill",
                                        }}
                                    >
                                        <source
                                            src={
                                                imageContent?.includes(
                                                    "http"
                                                ) ||
                                                imageContent?.includes("https")
                                                    ? imageContent
                                                    : `./images/${imageContent}`
                                            }
                                            type="video/mp4"
                                        />
                                    </video>
                                ) : (
                                    <img
                                        key={imageKey}
                                        style={{
                                            width: "95%",
                                            height: "725px",
                                        }}
                                        src={
                                            imageContent?.includes("http") ||
                                            imageContent?.includes("https")
                                                ? imageContent
                                                : `./images/${imageContent}`
                                        }
                                        alt={item.id}
                                    />
                                )}
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            ) : (
                <React.Fragment>
                    {activeScreens.map((item: StandbyScreenItemType) =>
                        item.type.includes("video") ? (
                            <video
                                key={imageKey}
                                autoPlay
                                muted
                                loop
                                style={{
                                    width: "95%",
                                    height: "725px",
                                    objectFit: "fill",
                                }}
                            >
                                <source
                                    src={
                                        item.content?.includes("http") ||
                                        item.content?.includes("https")
                                            ? item.content
                                            : `./images/${item.content}`
                                    }
                                    type="video/mp4"
                                />
                            </video>
                        ) : (
                            <img
                                alt="img"
                                key={imageKey}
                                style={{
                                    width: "95%",
                                    height: "725px",
                                }}
                                src={
                                    item.content?.includes("http") ||
                                    item.content?.includes("https")
                                        ? item.content
                                        : `./images/${item.content}`
                                }
                            />
                        )
                    )}
                </React.Fragment>
            )}
        </div>
    );
}
