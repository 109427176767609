import React from "react";
import { useSnapshot } from "valtio";

import { designStore, generalConfigStore } from "@store";

import { getImageContentById } from "@helpers/general";

export function ConfigOptionsWrapper(): JSX.Element {
    const { activeConfigOptions } = useSnapshot(designStore);
    const {
        defaultLanguage,
        project: { files: images },
    } = useSnapshot(generalConfigStore);

    return (
        <div
            className="px-3 d-flex justify-content-end align-items-center"
            style={{ gap: "10px" }}
        >
            {activeConfigOptions.map(
                (option: OptionsItemType, index: number) => {
                    const imageContent = getImageContentById(
                        option.id,
                        images,
                        defaultLanguage.name
                    );

                    const imageSrc =
                        imageContent?.includes("http") ||
                        imageContent?.includes("https")
                            ? imageContent
                            : `./images/${imageContent}`;

                    return (
                        <img
                            alt="img"
                            key={index}
                            src={imageSrc}
                            style={{
                                height: "40px",
                                width: "40px",
                            }}
                        />
                    );
                }
            )}
        </div>
    );
}
