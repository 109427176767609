import React from "react";
import classnames from "classnames";
import ReactSwitch from "react-switch";
import { toast } from "react-toastify";
import {
    CheckboxIcon,
    EyeIcon,
    PencilIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { Tooltip } from "@mui/material";

import { store as device } from "../../../../components/VerticalLayout/store";
import store, {
    setSelectedDevise,
    setDevise,
    setIsActionsClicked,
    setIsEdited,
    setActions,
} from "../store";

import ErrorToast from "@components/Common/ErrorTost";
import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import { ConfirmationArchive } from "../ConfirmationArchive";

export function CustomIcons({
    e,
    setShopId,
    setDevises,
    setDataIsUpdated,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    setSelectedRows,
    setIsAddDeviceClicked,
    isAddDeviceClicked,
    setIsModalConfirmationOpened,
    isModalConfirmationOpened,
    isDeviseArchived,
    dataArchived,
    selectedRows,
    setLocalFilterActive,
}: any) {
    const { t } = useTranslation();
    const {
        shopsDeviceData,
        deviceDataData,
        isActionsClicked,
        isEdited,modify
    } = useSnapshot(store);
    const { franchiseID, oneShop, userID, operatorID } = useSnapshot(device);

    const [choiceState, setDeviseState] = React.useState<boolean>(
        e.selectedRow.isActive
    );
    const [isArchiveModalOpened, setIsArchiveModalOpened] = React.useState<
        boolean
    >(false);
    let dataArchive: any[] = [];

    if (!oneShop) {
        dataArchive = archiveDevice();
    } else {
        dataArchive = archiveDeviceFranchise();
    }

    function archiveDevice() {
        let idsAssociated: any = [];
        if (shopsDeviceData !== undefined) {
            shopsDeviceData.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((el: any) => {
                        idsAssociated.push(el.id);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function archiveDeviceFranchise() {
        let idsAssociated: any = [];
        if (dataArchived?.data !== undefined) {
            dataArchived?.data.forEach((element: any) => {
                idsAssociated.push(element.id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    function handleTrashButtonClicked(selectedRow: any) {
        let idsAssociated: any = [];
        shopsDeviceData.forEach((el: any) => {
            el.subRows.forEach((element: any) => {
                idsAssociated.push(element.id);
            });
        });

        if (idsAssociated.includes(selectedRow.selectedRow.id) && !oneShop) {
            ErrorToast(
                `${t(
                    "Please note that you cannot archive an item assigned to stores."
                )!}`
            );
        } else {
            setIsArchiveModalOpened!(!isArchiveModalOpened);
        }
    }
    async function handleStateChange(dataState: boolean) {
        let dataDevises = {
            userId: userID,
            shopId: [e?.selectedRow.idShop],
            franchiseId: franchiseID,
            operatorId: operatorID,
            data: {
                [e.selectedRow.id]: {
                    Nom_d_Affichage: e.selectedRow[t("Display name")],
                    tag: e.selectedRow[t("Tag")],
                    symbole: e.selectedRow[t("Symbol")],
                    Nbr_décimale: e.selectedRow[t("Nbr decimal")],
                    designation_décimale:
                        e.selectedRow[t("Decimal designation")],
                    devise: e.selectedRow[t("Currency")],
                    pays: e.selectedRow[t("Country")],
                    shopId: e.selectedRow.shopId,
                    isArchived: false,
                    isActive: dataState,
                },
            },
        };

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/devise`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataDevises),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            !choiceState
                                ? `${t(
                                      "Currency has been successfully activated"
                                  )}`
                                : `${t(
                                      "Currency has been successfully deactivated"
                                  )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated!(true);
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function activeDevise(e: any) {
        toast.dismiss();
        let dataModeOfSale = {
            userId: userID,
            shopId: [e.selectedRow.idShop],
            franchiseId: franchiseID,
            isShop: false,
            operatorId: operatorID,
            data: {
                [e.selectedRow.id]: {
                    Nom_d_Affichage: e.selectedRow[t("Display name")],
                    tag: e.selectedRow[t("Tag")],
                    symbole: e.selectedRow[t("Symbol")],
                    Nbr_décimale: e.selectedRow[t("Nbr decimal")],
                    designation_décimale:
                        e.selectedRow[t("Decimal designation")],
                    devise: e.selectedRow[t("Currency")],
                    pays: e.selectedRow[t("Country")],
                    shopId: e.selectedRow.shopId,
                    isArchived: false,
                    isActive: e.selectedRow.isArchived,
                },
            },
        };

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/devise`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataModeOfSale),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t("The Currency was successfully unarchived")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated!(true);
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    {modify === true ? (
                        <>
                            <Tooltip title={isDeviseArchived ? "" : t("Edit")}>
                                <div>
                                    <PencilIcon
                                        onClick={() => {
                                            if (isEdited === true) {
                                                setIsActionsClicked(
                                                    !isActionsClicked
                                                );
                                                setActions(() => {
                                                    setIsEdited(false);
                                                    setDevises(e.selectedRow);
                                                    setShopId(
                                                        e.selectedRow.idShop
                                                    );
                                                    setLocalFilterActive(false);
                                                    if (!isAddDeviceClicked) {
                                                        setIsAddDeviceClicked(
                                                            !isAddDeviceClicked
                                                        );
                                                    }
                                                });
                                            } else {
                                                setDevises(e.selectedRow);
                                                setShopId(e.selectedRow.idShop);
                                                setLocalFilterActive(false);
                                                if (!isAddDeviceClicked) {
                                                    setIsAddDeviceClicked(
                                                        !isAddDeviceClicked
                                                    );
                                                }
                                            }
                                        }}
                                        height={25}
                                        width={25}
                                        style={{ cursor: "pointer" }}
                                        className={`${classnames(
                                            "pointer__clz cmn_icn_FRedit",
                                            {
                                                "not-allowed-icon__clz": isDeviseArchived,
                                            },
                                            oneShop ? "mr-1" : "ml-3"
                                        )}`}
                                    />
                                </div>
                            </Tooltip>

                            {oneShop && (
                                <Tooltip
                                    title={
                                        isDeviseArchived
                                            ? ""
                                            : choiceState === false
                                            ? t("Activate")
                                            : t("Unactivate")
                                    }
                                >
                                    <div>
                                        <ReactSwitch
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            handleDiameter={26}
                                            offColor="#f7b4b8"
                                            offHandleColor="#E30613"
                                            checked={choiceState}
                                            onChange={(e: boolean) => {
                                                setDeviseState(!choiceState);
                                                handleStateChange(e);
                                            }}
                                            onColor="#c2eddd"
                                            onHandleColor="#34C38F"
                                            width={50}
                                            height={20}
                                            className={`${classnames(
                                                "pointer__clz",
                                                {
                                                    "not-allowed-icon__clz": isDeviseArchived,
                                                },
                                                oneShop ? "mr-1" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}

                            {!isDeviseArchived ? (
                                <Tooltip title={t("Archive")}>
                                    <div>
                                        <TrashIcon
                                            onClick={() => {
                                                if (isEdited === true) {
                                                    setIsActionsClicked(
                                                        !isActionsClicked
                                                    );
                                                    setActions(() => {
                                                        setIsEdited(false);
                                                        setIsAddDeviceClicked(
                                                            false
                                                        );
                                                        setSelectedRows(e);
                                                        setDevises(
                                                            e.selectedRow
                                                        );
                                                        handleTrashButtonClicked(
                                                            e
                                                        );
                                                    });
                                                } else {
                                                    setSelectedRows(e);
                                                    setDevises(e.selectedRow);
                                                    handleTrashButtonClicked(e);
                                                }
                                            }}
                                            height={25}
                                            width={25}
                                            fill="red"
                                            style={{ cursor: "pointer" }}
                                            className={`${classnames(
                                                "pointer__clz cmn_icn_FRarchived",
                                                oneShop ? "mr-1" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={
                                        dataArchive.includes(
                                            e.selectedRow.id
                                        ) && oneShop
                                            ? ""
                                            : t("Unarchive")
                                    }
                                >
                                    <div>
                                        <EyeIcon
                                            height={25}
                                            width={25}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                if (
                                                    dataArchive.includes(
                                                        e.selectedRow.id
                                                    ) &&
                                                    !oneShop
                                                ) {
                                                    activeDevise(e);
                                                    setIsModalConfirmationOpened(
                                                        !isModalConfirmationOpened
                                                    );
                                                } else if (
                                                    deviceDataData.othersData?.allDevise?.data.filter(
                                                        (element: any) => {
                                                            if (
                                                                element.idShop ===
                                                                e.selectedRow
                                                                    .idShop
                                                            ) {
                                                                return element;
                                                            }
                                                            return true;
                                                        }
                                                    )[0] !== undefined &&
                                                    deviceDataData.othersData?.allDevise?.data.filter(
                                                        (element: any) => {
                                                            if (
                                                                element.idShop ===
                                                                e.selectedRow
                                                                    .idShop
                                                            ) {
                                                                return element;
                                                            }
                                                            return true;
                                                        }
                                                    )[0].subRows.length !== 0 &&
                                                    oneShop
                                                ) {
                                                    ErrorToast(
                                                        t(
                                                            "Attention,a shop cannot have more than one currency"
                                                        )
                                                    );
                                                } else {
                                                    activeDevise(e);
                                                }

                                                setDevise(e.selectedRow);
                                                setSelectedRows([
                                                    e.selectedRow,
                                                ]);
                                            }}
                                            className={`${classnames(
                                                "pointer__clz cmn_icn_FRunarchive ",
                                                {
                                                    "not-allowed-icon__clz":
                                                        dataArchive.includes(
                                                            e.selectedRow.id
                                                        ) && oneShop,
                                                },
                                                oneShop ? "mr-1" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : null}
                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(() => {
                                            setIsEdited(false);
                                            setIsAddDeviceClicked(
                                                !isAddDeviceClicked
                                            );
                                            setIsHistoryModalOpened(
                                                !isHistoryModalOpened
                                            );
                                            setSelectedDevise([
                                                {
                                                    [e.selectedRow.idShop]:
                                                        e.selectedRow.id,
                                                },
                                            ]);
                                            setSelectedRows([e.selectedRow]);
                                        });
                                    } else {
                                        setIsAddDeviceClicked(
                                            !isAddDeviceClicked
                                        );
                                        setIsHistoryModalOpened(
                                            !isHistoryModalOpened
                                        );
                                        setSelectedDevise([
                                            {
                                                [e.selectedRow.idShop]:
                                                    e.selectedRow.id,
                                            },
                                        ]);
                                        setSelectedRows([e.selectedRow]);
                                    }
                                }}
                                className={`${classnames(
                                    "pointer__clz cmn_icn_FRhistory",
                                    oneShop ? "mr-1" : "ml-3"
                                )}`}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
            {isArchiveModalOpened ? (
                <ConfirmationArchive
                    isModalOpened={isArchiveModalOpened}
                    setIsModalOpened={setIsArchiveModalOpened}
                    e={e}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                />
            ) : null}
        </React.Fragment>
    );
}
