import { DeviceSettingsIcon } from "@components/Common/SvgIcons";

export type CardMenuType = {
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    description?: string;
    title: string;
    number: number;
    path: string;
};

export const CARD_MENU_LIST: CardMenuType[] = [
    {
        title: "Configuration projet",
        number: 0,
        Icon: DeviceSettingsIcon,
        description:
            "Description to add. Description to add. Description to add. Description to add",
        path: "/orb-project-setup",
    },
    {
        title: "Configuration ORB",
        number: 0,
        Icon: DeviceSettingsIcon,
        description:
            "Description to add. Description to add. Description to add. Description to add",
        path: "/orb-setup",
    },
];
