import React from "react";
import { useTranslation } from "react-i18next";

import { SwiperLanguagesWrapper } from "@components/Common/Design/SwiperLanguagesWrapper";

import { TableContent } from "@pages/GeneralConfig/Design/Content/FinalMessage/Design/Footer/TableContent";

export function Translation(): JSX.Element {
    const { t } = useTranslation();

    return (
        <div
            className="mt-3 rounded border mx-2"
            style={{
                borderColor: "#CECECE",
            }}
        >
            <div
                className="text-left px-4 d-flex align-items-center cursor__clz"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                {t("Translation")}
            </div>
            <div className="p-2" style={{ width: "798px" }}>
                <SwiperLanguagesWrapper />
                <TableContent />
            </div>
        </div>
    );
}
