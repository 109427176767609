import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { CheckboxIcon, StyledH2, StyledModal } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { mutate } from "swr";
import { toast } from "react-toastify";

import { store } from "@components/VerticalLayout/store";
import {
    setFranchiseDataIsUpdated,
    setIds,
    setSelectedRow,
    setShopDataIsUpdated,
    store as dataStore,
} from "../store";

import ErrorToast from "@components/Common/ErrorTost";

import { CustomIconsAssociateModal } from "./CustomIconsAssociateModal";
import { ConfirmationMessage } from "./ConfirmationMessage";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type SaleSupportAssociateModalType = {
    isAssociateModalOpened: boolean;
    setIsAssociateModalOpened: Function;
    selectedShopsRows: any;
};

export function SaleSupportAssociateModal({
    setIsAssociateModalOpened,
    isAssociateModalOpened,
    selectedShopsRows,
}: SaleSupportAssociateModalType) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const {
        idsLocalShop,
        activeSaleSupports,
        shopsSelectedRow,
        localShop,
        associatedSaleSupport,
        shopDataIsUpdated,
        salesSupportFavored,
    } = useSnapshot(dataStore);

    const shopId = 0;
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

    const [isValidateClicked, setIsValidateClicked] = React.useState<
        boolean | number
    >(false);
    const [isSalesSupportFavored, setIsSalesSupportFavored] = React.useState<
        boolean
    >(false);

    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isModalArchivedOpened, setIsModalArchivedOpened] = React.useState<
        boolean
    >(false);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [selectedSaleSupport, setSelectedSaleSupport] = React.useState<any[]>(
        []
    );
    const url =
        localShop.length === 1
            ? `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupportToBeAssociate?userId=${userID}&shopId=${localShop[0].id}&franchiseId=${franchiseID}`
            : `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=activated`;

    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 6,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIconsAssociateModal
                    element={e}
                    isOneShopClicked={
                        selectedShopsRows.filter(
                            (el: any) => el.subRows !== undefined
                        ).length < 2
                    }
                    isSalesSupportFavored={isSalesSupportFavored}
                    setIsSalesSupportFavored={setIsSalesSupportFavored}
                    selectedSaleSupport={selectedSaleSupport}
                />
            ),
        },
    ];

    async function handleAssociateSaleSupportButtonClick() {
        let localData: any = [];
        let localShopsSelectedRow: any = [];
        let archived: any = {};
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport`;

        if (shopsSelectedRow.length > 0) {
            shopsSelectedRow.forEach((element: any) => {
                element?.archivedSaleSupport?.forEach((el: any) => {
                    Object.assign(archived, {
                        [el.shopId]: el.Id,
                    });
                });
                localShopsSelectedRow.push(element.subRows);
            });
        }

        let isReassignment: boolean[] = [];
        let favoriteSelectedSaleSupport: any = [];
        let shopSelectedSaleSupport: any = [];
        for (let element of selectedShopsRows) {
            if (selectedRows.length !== 0) {
                selectedRows.forEach(() => {
                    favoriteSelectedSaleSupport.push(
                        salesSupportFavored.find(
                            (element: any) => element.status === true
                        )
                    );
                });
                if (favoriteSelectedSaleSupport !== undefined) {
                    let shopFavoriteSaleSupport: any =
                        element.subRows &&
                        element.subRows.find(
                            (el: any) => el.isFavorite === true
                        );

                    if (shopFavoriteSaleSupport !== undefined) {
                        let shopFavoriteSaleSupport: any = element.subRows.find(
                            (el: any) => el.isFavorite === true
                        );
                        shopFavoriteSaleSupport.isFavorite = false;
                        if (
                            selectedRows.find(
                                (el: any) =>
                                    el.Id === shopFavoriteSaleSupport.Id
                            ) === undefined
                        ) {
                            selectedRows.push(shopFavoriteSaleSupport);
                            shopSelectedSaleSupport.push(
                                shopFavoriteSaleSupport
                            );
                        }
                    }
                }
                let indexInAssociated: number = -1;
                selectedRows.forEach(async (el: any) => {
                    indexInAssociated =
                        element.subRows !== undefined &&
                        element.subRows.findIndex(
                            (item: any) => item.Id === el.Id
                        );
                    let isLocalAssociated: boolean =
                        (element.subRows !== undefined &&
                            element.subRows[indexInAssociated] !== undefined &&
                            element.subRows[indexInAssociated].isActive !==
                                activeSaleSupports.find((element: any) => {
                                    return element.Id === el.Id;
                                }).state) ||
                        (element.subRows !== undefined &&
                            element.subRows[indexInAssociated] !== undefined &&
                            element.subRows[indexInAssociated].isFavorite !==
                                activeSaleSupports.find((element: any) => {
                                    return element.Id === el.Id;
                                }).isFavorite);

                    isReassignment.push(isLocalAssociated);
                    let objectData: any =
                        shopSelectedSaleSupport[0] !== undefined &&
                        shopSelectedSaleSupport[0].Id === el.Id
                            ? {
                                  userId: userID,
                                  shopId: [element.shopId],
                                  franchiseId: franchiseID,
                                  operatorId: operatorID,
                                  data: {
                                      [el.Id]: {
                                          id: el.Id,
                                          displayName: el[t("Display name")],
                                          shopId: element.shopId,
                                          support_de_vente:
                                              el[t("Sale support")],
                                          tag: el.Tag,
                                          isActive: activeSaleSupports.find(
                                              (element: any) =>
                                                  element.Id === el.Id
                                          ).state,
                                          isArchived: el.isArchived,
                                          isFavorite:
                                              salesSupportFavored.find(
                                                  (element: any) =>
                                                      element.id === el.Id
                                              ) !== undefined
                                                  ? salesSupportFavored.find(
                                                        (element: any) =>
                                                            element.id === el.Id
                                                    ).status
                                                  : el.isFavorite,
                                      },
                                  },
                              }
                            : {
                                  affectation:
                                      isLocalAssociated !== true
                                          ? true
                                          : "reassignment",
                                  userId: userID,
                                  shopId: [element.shopId],
                                  franchiseId: franchiseID,
                                  operatorId: operatorID,
                                  data: {
                                      [el.Id]: {
                                          id: el.Id,
                                          displayName: el[t("Display name")],
                                          shopId: element.shopId,
                                          support_de_vente:
                                              el[t("Sale support")],
                                          tag: el.Tag,
                                          isActive: activeSaleSupports.find(
                                              (element: any) =>
                                                  element.Id === el.Id
                                          ).state,
                                          isArchived: el.isArchived,
                                          isFavorite:
                                              salesSupportFavored.find(
                                                  (element: any) =>
                                                      element.id === el.Id
                                              ) !== undefined
                                                  ? salesSupportFavored.find(
                                                        (element: any) =>
                                                            element.id === el.Id
                                                    ).status
                                                  : el.isFavorite,
                                      },
                                  },
                              };

                    localData.push(objectData);
                });
            }
        }
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(localData),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            !isReassignment.includes(true)
                                ? `${t("The assignment was successful")}`
                                : `${t("The reassignment was successful")}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsValidateClicked(false);
                        setIsAssociateModalOpened(!isAssociateModalOpened);
                        setFranchiseDataIsUpdated(true);
                        setShopDataIsUpdated(true);
                        setSelectedRows([]);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    function CountIds() {
        let number = 0;
        idsLocalShop.forEach((el: any) => {
            selectedRows.forEach((v: any) => {
                if (v.Id === el) {
                    number = number + 1;
                }
            });
        });
        setIds(number);
    }

    function checkIfSaleSupportExistAndArchived() {
        let archived: any[] = [];
        if (shopsSelectedRow.length > 0) {
            shopsSelectedRow.forEach((element: any) => {
                element?.archivedSaleSupports?.forEach((el: any) => {
                    archived.push(el.Id);
                });
            });
        }
        return archived;
    }

    React.useEffect(() => {
        let localSelectedSaleSupport: any = [];
        selectedShopsRows.forEach((el: any) => {
            if (el.subRows !== undefined)
                el.subRows.forEach((element: any) => {
                    localSelectedSaleSupport.push(element);
                });
        });
        setSelectedSaleSupport(localSelectedSaleSupport);
    }, [selectedShopsRows, selectedRows, activeSaleSupports]);

    React.useEffect(() => {
        if (isValidateClicked === true) handleAssociateSaleSupportButtonClick();
        // eslint-disable-next-line
    }, [isValidateClicked]);

    return (
        <React.Fragment>
            <StyledModal
                toggle={true}
                isOpen={isAssociateModalOpened!}
                centered
                className="modal-wrapper__clz"
                backdrop="static"
                fade={false}
                scrollable
            >
                <ModalHeader
                    className="text-capitalize sdv_icn_AFclose"
                    toggle={() => {
                        setIsAssociateModalOpened(!isAssociateModalOpened);
                    }}
                >
                    <StyledH2 className="text-uppercase sdv_txt_AFlistOfSalesSupports">
                        {t("List of sales support")}
                    </StyledH2>
                </ModalHeader>
                <ModalBody>
                    <DynamicTable
                        url={url}
                        requestHeader={{ "Accept-Language": i18nextLng }}
                        canSelect
                        customSelect
                        canResize
                        setSelectedRows={setSelectedRows}
                        actionColumn={ActionColumn}
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        showGlobalFilter
                        canMovedCheckboxLeftOnExpand
                        setDataIsUpdated={
                            setShopDataIsUpdated as
                                | React.Dispatch<
                                      React.SetStateAction<number | boolean>
                                  >
                                | undefined
                        }
                        dataIsUpdated={shopDataIsUpdated}
                        elevationTable={1}
                        minHeight="500px"
                        maxHeight="500px"
                        name="associateModeOfSale"
                    />
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <CustomSecondaryColorButton
                        outline
                        variant="light"
                        onClick={() => {
                            setIsAssociateModalOpened(!isAssociateModalOpened);
                        }}
                        rounded
                        className="sdv_btn_AFcancel"
                    >
                        {t("Cancel")}
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        onClick={() => {
                            let archived = checkIfSaleSupportExistAndArchived();
                            if (selectedRows.length === 0) {
                                ErrorToast(
                                    `${t(
                                        "Attention! You must select at least one sale support"
                                    )!}`
                                );
                            } else {
                                CountIds();
                                try {
                                    setSelectedRow(selectedRows);
                                    selectedRows.forEach((el: any) => {
                                        associatedSaleSupport.forEach(
                                            (element: any) => {
                                                if (
                                                    activeSaleSupports.find(
                                                        (elt: any) =>
                                                            elt.Id === el.Id
                                                    ) !== undefined &&
                                                    element.Id === el.Id &&
                                                    element.isActive !==
                                                        activeSaleSupports.find(
                                                            (elt: any) =>
                                                                elt.Id === el.Id
                                                        ).state
                                                ) {
                                                    setIsModalOpened(
                                                        !isModalOpened
                                                    );
                                                    // eslint-disable-next-line
                                                    throw "Break";
                                                }
                                            }
                                        );
                                    });
                                    selectedRows.forEach((el: any) => {
                                        if (archived.includes(el.Id)) {
                                            setIsModalArchivedOpened(
                                                !isModalArchivedOpened
                                            );
                                            // eslint-disable-next-line
                                            throw "Break";
                                        }
                                    });
                                    handleAssociateSaleSupportButtonClick();
                                } catch (e) {
                                    if (e !== "Break") throw e;
                                }
                            }
                        }}
                        className="sdv_btn_AFvalidate"
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </ModalFooter>
            </StyledModal>
            {isModalOpened ? (
                <ConfirmationMessage
                    msg={t(
                        'Do you want to apply these changes for all stores? \n Attention ! If you click on "Validate", the specific settings of the shops will be modified.'
                    )}
                    setIsModalOpened={setIsModalOpened}
                    isModalOpened={isModalOpened}
                    setIsValidateClicked={setIsValidateClicked}
                />
            ) : null}
            {isModalArchivedOpened ? (
                <ConfirmationMessage
                    msg={t(
                        "Attention! One or more selected languages are already assigned and archived at the store level.Would you unarchive them?"
                    )}
                    setIsModalOpened={setIsModalArchivedOpened}
                    isModalOpened={isModalArchivedOpened}
                    setIsValidateClicked={setIsValidateClicked}
                />
            ) : null}
        </React.Fragment>
    );
}
