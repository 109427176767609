import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    CheckboxIcon,
    StyledLabel,
} from "@aureskonnect/react-ui";

import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";

import { setIsLoading } from "../../store/project";
import { store as userStore } from "../../components/VerticalLayout/store";
import moment from "moment";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationArchiveType = {
    setIsDataUpdated: Function;
    setIsConfirmationPublishProject: Function;
    isConfirmationPublishProject: boolean;
    dataTable: any;
    selectedRows: any;
    dataProject: any;
};

export function ConfigurationProjectPublish({
    setIsDataUpdated,
    setIsConfirmationPublishProject,
    isConfirmationPublishProject,
    dataTable,
    selectedRows,
    dataProject,
}: ConfirmationArchiveType): JSX.Element {
    const { t } = useTranslation();
    const { userID, franchiseID, shopID, operatorID } = useSnapshot(userStore);
    const isExistShops = React.useCallback(() => {
        let isExist: boolean = false;
        dataProject?.dataProjectFranchise?.data.forEach((el: any) => {
            if (el.projectId === dataTable.projectId) {
                if (el.shopKey.length > 1) {
                    isExist = true;
                }
            }
        });
        return isExist;
    }, [dataTable, dataProject]);
    let isExist = isExistShops();
    function handlePublishProject() {
        let localData: any = [];

        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/status`;
        let savedData = {
            userId: userID,
            franchiseId: franchiseID,
            shopId: [shopID, "0"],
            projectId: selectedRows.projectId,
            operatorId: operatorID,
            status: "Publié",
            modified_at: `le ${moment().format("DD/MM/YY à HH:mm a")}`,
        };

        setIsLoading(true);
        localData.push(
            fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(savedData),
            }).then((response) => response.json())
        );

        try {
            mutate(
                apiUrl,
                Promise.all(localData).then((result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                        toast.success(
                            `${t("The project is published successfully")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        handleSaveButtonOnClickEvent();
                        setIsDataUpdated(true);
                        setIsLoading(false);
                    }
                })
            );
        } catch (e: any) {
            console.log(`${t("There's an error")}!`);
        }
    }

    function handleSaveButtonOnClickEvent() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/projects/delete`;
        let localData: any = [];
        let savedData = {
            userId: userID,
            franchiseId: franchiseID,
            shopId: shopID,
            projectId: "PROJECT",
            operatorId: operatorID,
            project_id: dataTable.projectId,
        };

        localData.push(
            fetch(apiUrl, {
                method: "delete",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(savedData),
            }).then((response) => response.json())
        );

        try {
            mutate(
                apiUrl,
                Promise.all(localData).then((result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                        dissociationShops();
                    } else {
                        throw Error(result.message);
                    }
                })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                icon: true,
            });
        }
    }
    function handleArchivage() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project`;
        let savedDataProject = {
            userId: userID,
            franchiseId: franchiseID,
            operatorId: operatorID,
            shopId: "0",
            data: {
                PROJECT: {
                    id_project: "PROJECT",
                    data_project: {
                        [dataTable.projectId]: {
                            projectId: dataTable.projectId,
                            designation: dataTable.Désignation,
                            associates: {},
                            isArchived: true,
                            template: dataTable.template,
                            files: dataTable.files,
                            note: dataTable.Remarque,
                            nombre: dataTable.Nombre,
                            status: "en attente",
                            modified_at: `le ${moment().format(
                                "DD/MM/YY à HH:mm a"
                            )}`,
                            hour: `${moment().format("DD/MM/YY - HH:mm a")}`,
                        },
                    },
                },
            },
        };

        try {
            mutate(
                apiUrl,
                fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(savedDataProject),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                        setIsDataUpdated(true);
                    })
            );
        } catch (e: any) {
            console.log(`${t("There's an error")}!`);
        }
    }
    function dissociationShops() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/projects/dissociation/shops`;
        let localData: any = [];

        let savedData = {
            userId: userID,
            franchiseId: franchiseID,
            project_id: "PROJECT",
            projectId: dataTable.projectId,
            operatorId: operatorID,
            shopsKey: shopID,
        };

        localData.push(
            fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(savedData),
            }).then((response) => response.json())
        );

        try {
            mutate(
                apiUrl,
                Promise.all(localData).then((result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                    } else {
                        throw Error(result.message);
                    }
                })
            );
        } catch (e: any) {
            console.log(`${t("There's an error")}!`);
        }
    }

    return (
        <StyledModal
            toggle={true}
            isOpen={isConfirmationPublishProject}
            centered
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() =>
                    setIsConfirmationPublishProject(
                        !isConfirmationPublishProject
                    )
                }
            >
                <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {`${t(
                        "Attention, a Template is already published.The publication of the"
                    )}''${selectedRows.Désignation}''${t(
                        "Template generates the dissociation of the shops of the Template published.\n Do you want to publish the selected Template?"
                    )}`}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() =>
                        setIsConfirmationPublishProject(
                            !isConfirmationPublishProject
                        )
                    }
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        handlePublishProject();
                        if (!isExist) {
                            handleArchivage();
                        }
                        setIsConfirmationPublishProject(
                            !isConfirmationPublishProject
                        );
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
