import React from "react";
import Switch from "react-switch";
import { Card } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import ThreedImageModel from "../../../../../../image/ThreedImageModel.png";

import {
    setThreedModelFollowKitchenTicket,
    setThreedModelDispatchAutomaticallyFollow,
    setThreedModelFontSmall,
    setThreedModelActivateManualDispatchFollow,
    setThreedModelOrderKitchen,
    setThreedModelSendChangesKitchen,
    setThreedModelTicketAdditionsCancellations,
    resetStore,
    storeModelTicket,
} from "./store";
import { store } from "../store";

import { PrinterFooter } from "../PrinterFooter";

import "./index.css";

type ThreedModelType = {
    rowData: any;
    oneShop: boolean;
    setCheckboxIconThreedModel: Function;
    setDataIsUpdated:Function;
    isDataUpdated:number|boolean
};

export default function ThreedModel({
    rowData,
    setCheckboxIconThreedModel,
    setDataIsUpdated,
    isDataUpdated,
}: ThreedModelType) {
    const { t } = useTranslation();
    const {
        threedModelFollowKitchenTicket,
        threedModelFontSmall,
        threedModelDispatchAutomaticallyFollow,
        threedModelActivateManualDispatchFollow,
        threedModelOrderKitchen,
        threedModelSendChangesKitchen,
        threedModelTicketAdditionsCancellations,
    } = useSnapshot(storeModelTicket);

    const { checkboxIconThreedModel } = useSnapshot(store);

    return (
        <React.Fragment>
            <div className="card_modale__clz">
                <div
                    className="card card-model-second__clz"
                    style={{ fontSize: "17px" }}
                >
                    <div className="border__clz">
                        <div className="p-3">
                            <span className="style-span__clz">
                                {t("Title to follow")}
                            </span>
                        </div>
                        <div className="p-2 d-flex justify-content-between">
                            <span>
                                {`${t(
                                    "Print the title to follow on the kitchen ticket"
                                )} *`}
                            </span>
                            <Switch
                                offColor="#faafb4"
                                offHandleColor="#E30613"
                                onColor="#c2eec4"
                                onHandleColor="#34C38F"
                                height={20}
                                handleDiameter={24}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                checked={threedModelFollowKitchenTicket}
                                onChange={(e: any) => {
                                    setThreedModelFollowKitchenTicket(
                                        !threedModelFollowKitchenTicket
                                    );
                                }}
                            />
                        </div>
                        <div className="p-2 d-flex justify-content-between">
                            <span>
                                {`${t(
                                    "Print components with a plus font small"
                                )}`}
                            </span>
                            <Switch
                                offColor="#faafb4"
                                offHandleColor="#E30613"
                                onColor="#c2eec4"
                                onHandleColor="#34C38F"
                                height={20}
                                handleDiameter={24}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                checked={threedModelFontSmall}
                                onChange={(e: any) => {
                                    setThreedModelFontSmall(
                                        !threedModelFontSmall
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div className="border__clz">
                        <div className="p-3">
                            <span className="style-span__clz">
                                {`${t("Dispatch to follow")}`}
                            </span>
                        </div>
                        <div className="p-2 d-flex justify-content-between">
                            <span>{`${t(
                                "Enable dispatch automatically from to follow"
                            )}`}</span>
                            <Switch
                                offColor="#faafb4"
                                offHandleColor="#E30613"
                                onColor="#c2eec4"
                                onHandleColor="#34C38F"
                                height={20}
                                handleDiameter={24}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                checked={threedModelDispatchAutomaticallyFollow}
                                onChange={(e: any) => {
                                    setThreedModelDispatchAutomaticallyFollow(
                                        !threedModelDispatchAutomaticallyFollow
                                    );
                                }}
                            />
                        </div>
                        <div className="p-2 d-flex justify-content-between">
                            <span>
                                {`${t(
                                    "Activate the manual dispatch of to follow"
                                )}`}
                            </span>

                            <Switch
                                offColor="#faafb4"
                                offHandleColor="#E30613"
                                onColor="#c2eec4"
                                onHandleColor="#34C38F"
                                height={20}
                                handleDiameter={24}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                checked={
                                    threedModelActivateManualDispatchFollow
                                }
                                onChange={(e: any) => {
                                    setThreedModelActivateManualDispatchFollow(
                                        !threedModelActivateManualDispatchFollow
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div className="p-3">
                        <span className="style-span__clz">
                            {`${t("Printing a modified order")}`}
                        </span>
                    </div>
                    <div className="p-2 d-flex justify-content-between">
                        <span>
                            {`${t(
                                "Send the final status of the order to the kitchen"
                            )}`}
                        </span>
                        <Switch
                            offColor="#faafb4"
                            offHandleColor="#E30613"
                            onColor="#c2eec4"
                            onHandleColor="#34C38F"
                            height={20}
                            handleDiameter={24}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            checked={threedModelOrderKitchen}
                            onChange={(e: any) => {
                                setThreedModelOrderKitchen(
                                    !threedModelOrderKitchen
                                );
                            }}
                        />
                    </div>
                    <div className="p-2 d-flex justify-content-between">
                        <span>{`${t(
                            "Send changes only to the kitchen"
                        )}`}</span>

                        <Switch
                            offColor="#faafb4"
                            offHandleColor="#E30613"
                            onColor="#c2eec4"
                            onHandleColor="#34C38F"
                            height={20}
                            handleDiameter={24}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            checked={threedModelSendChangesKitchen}
                            onChange={(e: any) => {
                                setThreedModelSendChangesKitchen(
                                    !threedModelSendChangesKitchen
                                );
                            }}
                        />
                    </div>
                    <div className="p-2 d-flex justify-content-between">
                        <span>
                            {`${t(
                                "Print a kitchen ticket for additions and a other for cancellations"
                            )}`}
                        </span>
                        <Switch
                            offColor="#faafb4"
                            offHandleColor="#E30613"
                            onColor="#c2eec4"
                            onHandleColor="#34C38F"
                            height={20}
                            handleDiameter={24}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            checked={threedModelTicketAdditionsCancellations}
                            onChange={(e: any) => {
                                setThreedModelTicketAdditionsCancellations(
                                    !threedModelTicketAdditionsCancellations
                                );
                            }}
                        />
                    </div>
                </div>
                <div className="card-model-first__clz">
                    <div>
                        <input
                            className="check-style__clz"
                            style={{
                                marginBottom: "530px",
                                marginRight: "122px",
                            }}
                            type="checkbox"
                            checked={checkboxIconThreedModel}
                            onChange={() => {
                                setCheckboxIconThreedModel(
                                    !checkboxIconThreedModel
                                );
                            }}
                        />
                    </div>
                    <Card>
                        <img
                            src={ThreedImageModel}
                            alt="Logo"
                            className="style-logo__clz"
                        />
                    </Card>
                </div>
            </div>
            <PrinterFooter
                rowData={rowData}
                resetStore={resetStore}
                setDataIsUpdated={setDataIsUpdated}
                isDataUpdated={isDataUpdated}
            />
        </React.Fragment>
    );
}
