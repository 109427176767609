import React from "react";
import classnames from "classnames";
import {
    DisassociateIcon,
    EyeIcon,
    PencilIcon,
    SettingsIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next/";
import { useSnapshot } from "valtio";

import { store as device } from "../../../../components/VerticalLayout/store";
import store, {
    setIsConfigurationPrinterClicked,
    setSelectedApplicationKds,
} from "./store";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import ErrorToast from "@components/Common/ErrorTost";

export function CustomIcons({
    e,
    setSelectedRows,
    isAddApplication,
    setIsAddApplication,
    isApplicationArchived,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    setIsConfirmationModalArchived,
    setIsConfirmationModalActive,
    setUrlHistoryPrinter,
    setIsAddPrinterOriginHistoryClicked,
}: any) {
    const { t } = useTranslation();
    const { userID, franchiseID } = useSnapshot(device);
    const { isConfigurationPrinterClicked } = useSnapshot(store);
    return (
        <React.Fragment>
            {e.selectedRow?.subRows !== undefined &&
            e.selectedRow["Application"] !== "" ? (
                <div className="d-flex justify-content-between mt-2">
                    <div>
                        <PencilIcon
                            onClick={() => {
                                setSelectedRows([e.selectedRow]);
                                if (!isAddApplication) {
                                    setIsAddApplication(!isAddApplication);
                                }
                            }}
                            height={25}
                            width={25}
                            style={{ cursor: "pointer" }}
                            className={`${classnames("pointer__clz", {
                                "not-allowed-icon__clz": isApplicationArchived,
                            })}`}
                        />
                    </div>

                    <div>
                        {!isApplicationArchived ? (
                            <TrashIcon
                                onClick={() => {
                                    if (e.selectedRow.subRows.length > 0) {
                                        ErrorToast(
                                            t(
                                                "Please note that you cannot archive a application associated with an device."
                                            )
                                        );
                                    } else {
                                        setIsConfirmationModalArchived(true);
                                    }

                                    setSelectedRows([e.selectedRow]);
                                }}
                                height={25}
                                width={25}
                                fill="red"
                                style={{ cursor: "pointer" }}
                                className={`${classnames("pointer__clz ml-1")}`}
                            />
                        ) : (
                            <EyeIcon
                                height={25}
                                width={25}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setIsConfirmationModalActive(true);
                                    setSelectedRows([e.selectedRow]);
                                }}
                                className={`${classnames(
                                    "pointer__clz ml-1",
                                    {}
                                )}`}
                            />
                        )}
                    </div>
                    <div>
                        <TimePastSvgIcon
                            onClick={() => {
                                setSelectedApplicationKds([
                                    {
                                        [e.selectedRow.shopId]:
                                            e.selectedRow.appId,
                                    },
                                ]);
                                setIsHistoryModalOpened(!isHistoryModalOpened);

                                setSelectedRows([e.selectedRow]);
                            }}
                            className={`${classnames("pointer__clz", "ml-2")}`}
                        />
                    </div>
                </div>
            ) : e.selectedRow[t("Peripheral")] !== "" ? (
                <div className="d-flex justify-content-between mt-3">
                    <SettingsIcon
                        className="pointer__clz ml-4"
                        onClick={() => {
                            if (e.selectedRow.peripheralType === "printer") {
                                setSelectedRows(e.selectedRow);
                                setIsConfigurationPrinterClicked(
                                    !isConfigurationPrinterClicked
                                );
                            }
                        }}
                        height={22}
                        width={22}
                    />
                    <DisassociateIcon
                        height={40}
                        width={35}
                        fill="white"
                        style={{ position: "relative", bottom: "10px" }}
                        className={`${classnames("pointer__clz ml-2", {
                            "not-allowed-icon__clz": isApplicationArchived,
                        })}`}
                        onClick={() => {
                            setSelectedRows([e.selectedRow]);
                        }}
                    />
                    <TimePastSvgIcon
                        onClick={() => {
                            setUrlHistoryPrinter(
                                `${process.env.REACT_APP_API_V2_URL}/settings/printers/printer/history?userId=${userID}&shopId=${e.selectedRow.shopId}&franchiseId=${franchiseID}&id=${e.selectedRow.peripheralId}`
                            );
                            setIsAddPrinterOriginHistoryClicked(true);
                        }}
                        className={`${classnames("pointer__clz", "ml-3")}`}
                    />
                </div>
            ) : null}
        </React.Fragment>
    );
}
