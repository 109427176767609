import { store } from ".";

export function setActiveSlideIndex(activeSlideIndex: number): void {
    store.activeSlideIndex = activeSlideIndex;
}

export function setSaleMethodsNumber(saleMethodsNumber: number): void {
    store.saleMethodsNumber = saleMethodsNumber;
}

export function setIsTopBannerActive(isTopBannerActive: boolean): void {
    store.isTopBannerActive = isTopBannerActive;
}

export function setIsInformationMessageActive(
    isInformationMessageActive: boolean
): void {
    store.isInformationMessageActive = isInformationMessageActive;
}

export function setIsUserAccountActive(isUserAccountActive: boolean): void {
    store.isUserAccountActive = isUserAccountActive;
}

export function setIsLanguagesActive(isLanguagesActive: boolean): void {
    store.isLanguagesActive = isLanguagesActive;
}

export function setActiveLanguages(activeLanguages: LanguagesItemType[]): void {
    store.activeLanguages = activeLanguages;
}

export function setIsConfigOptionsActive(isConfigOptionsActive: boolean): void {
    store.isConfigOptionsActive = isConfigOptionsActive;
}

export function setActiveConfigOptions(
    activeConfigOptions: OptionsItemType[]
): void {
    store.activeConfigOptions = activeConfigOptions;
}

export function setIsLogoActive(isLogoActive: boolean): void {
    store.isLogoActive = isLogoActive;
}

export function setIsActionButtonsActive(isActionButtonsActive: boolean): void {
    store.isActionButtonsActive = isActionButtonsActive;
}

export function setIsHeaderActive(isHeaderActive: boolean): void {
    store.isHeaderActive = isHeaderActive;
}

export function setActionsButtonsItems(
    actionsButtonsItems: ActionsButtonsDesignItemType[]
): void {
    store.actionsButtonsItems = actionsButtonsItems;
}

export function setActiveMainContentItems(
    activeMainContentItems: ProjectMainContentItemType[]
): void {
    store.activeMainContentItems = activeMainContentItems;
}

export function setMainContentItems(
    mainContentItems: ProjectMainContentItemType[]
): void {
    store.mainContentItems = mainContentItems;
}

export function setInformationMessageItems(
    informationMessageItems: HeaderLanguageItemsType
): void {
    store.informationMessageItems = informationMessageItems;
}

export function setUserAccountMessageItems(
    userAccountMessageItems: HeaderLanguageItemsType
): void {
    store.userAccountMessageItems = userAccountMessageItems;
}

export function setActiveTabIndex(activeTabIndex: number): void {
    store.activeTabIndex = activeTabIndex;
}

export function setSelectedLanguage(selectedLanguage: string): void {
    store.selectedLanguage = selectedLanguage;
}

export function setActivePageName(activePageName: string): void {
    store.activePageName = activePageName;
}

export function setIsInformationModesPersonalized(
    isInformationModesPersonalized: boolean
): void {
    store.isInformationModesPersonalized = isInformationModesPersonalized;
}

export function setSelectedInformationModes(
    selectedInformationModes: ProjectMainContentItemType
): void {
    store.selectedInformationModes = selectedInformationModes;
}

export function setIsIconActive(isIconActive: boolean): void {
    store.isIconActive = isIconActive;
}

export function setIsSubStepLogoActive(isSubStepLogoActive: boolean): void {
    store.isSubStepLogoActive = isSubStepLogoActive;
}

export function setIsSubStepTopBannerActive(
    isSubStepTopBannerActive: boolean
): void {
    store.isSubStepTopBannerActive = isSubStepTopBannerActive;
}

export function setIsSubStepActionButtonsActive(
    isSubStepActionButtonsActive: boolean
): void {
    store.isSubStepActionButtonsActive = isSubStepActionButtonsActive;
}

export function setSubStepActionsButtonsItems(
    subStepActionsButtonsItems: ActionsButtonsDesignItemType[]
): void {
    store.subStepActionsButtonsItems = subStepActionsButtonsItems;
}

export function setIsSubStepInformationMessageActive(
    isSubStepInformationMessageActive: boolean
): void {
    store.isSubStepInformationMessageActive = isSubStepInformationMessageActive;
}

export function setIsSubStepHeaderActive(isSubStepHeaderActive: boolean): void {
    store.isSubStepHeaderActive = isSubStepHeaderActive;
}

export function setSubStepInformationMessageItems(
    subStepInformationMessageItems: HeaderLanguageItemsType
): void {
    store.subStepInformationMessageItems = subStepInformationMessageItems;
}
export function setHasSubSteps(hasSubSteps: boolean): void {
    store.hasSubSteps = hasSubSteps;
}

export function setIsSubStepActivated(isSubStepActivated: boolean): void {
    store.isSubStepActivated = isSubStepActivated;
}

export function setSelectedSubStep(
    selectedSubStep: ProjectMainContentItemType
): void {
    store.selectedSubStep = selectedSubStep;
}

export function setSelectedRegulationModeValue(regulationsMode: string): void {
    store.selectedRegulationModeValue = regulationsMode;
}

export function setSelectedInformationModeValue(informationMode: string): void {
    store.selectedInformationModeValue = informationMode;
}

export function setFinalMessage(finalMessage: string): void {
    store.finalMessage = finalMessage;
}

export function setFooterTicketNumberMessage(
    ticketNumberMessage: HeaderLanguageItemsType
): void {
    store.footerTicketNumberMessage = ticketNumberMessage;
}

export function setIsFooterTicketNumberMessageActive(
    ticketNumberMessageActive: boolean
): void {
    store.isFooterTicketNumberMessageActive = ticketNumberMessageActive;
}

export function setFooterFinalMessage(
    footerFinalMessage: HeaderLanguageItemsType
): void {
    store.footerFinalMessage = footerFinalMessage;
}

export function setIsFooterFinalMessageActive(
    FooterFinalMessageActive: boolean
): void {
    store.isFooterFinalMessageActive = FooterFinalMessageActive;
}

export function setIsBackgroundActive(isBackgroundActive: boolean): void {
    store.isBackgroundActive = isBackgroundActive;
}

export function setPaymentMainContent(
    paymentMainContent: PaymentMainContentItemType[]
): void {
    store.paymentMainContent = paymentMainContent;
}

export function setPaymentSelectedRegulationModeValue(
    paymentSelectedRegulationModeValue: string
): void {
    store.paymentSelectedRegulationModeValue =
        paymentSelectedRegulationModeValue;
}

export function setIsPaymentImageActive(isPaymentImageActive: boolean): void {
    store.isPaymentImageActive = isPaymentImageActive;
}

export function setIsPaymentMessageActive(
    isPaymentMessageActive: boolean
): void {
    store.isPaymentMessageActive = isPaymentMessageActive;
}

export function setIsMainContentChangeMachine(
    isMainContentChangeMachine: boolean
): void {
    store.isMainContentChangeMachine = isMainContentChangeMachine;
}

export function setInformationMessageItem(informationMessageItem: {
    name: string;
    defaultName: string;
    isTextVisible: boolean;
    isDefaultTextVisible: boolean;
}): void {
    store.informationMessageItem = informationMessageItem;
}

export function setAmountToBePaidItem(
    amountToBePaidItem: AmountItemType
): void {
    store.amountToBePaidItem = amountToBePaidItem;
}

export function setTotalAmountItem(totalAmountItem: AmountItemType): void {
    store.totalAmountItem = totalAmountItem;
}

export function setPaymentInstructionsItem(
    paymentInstructionsItem: PaymentInstructionsType
): void {
    store.paymentInstructionsItem = paymentInstructionsItem;
}

export function setIsValinaOptionActive(isValinaOptionActive: boolean): void {
    store.isValinaOptionActive = isValinaOptionActive;
}

export function setIsOptionModalOpen(isOptionModalOpen: boolean): void {
    store.isOptionModalOpen = isOptionModalOpen;
}

export function setPaymentTPAInformationMessageItems(
    paymentTPAInformationMessageItems: NewHeaderType
): void {
    store.paymentTPAInformationMessageItems = paymentTPAInformationMessageItems;
}

export function setPaymentTPAActionsButtonsItems(
    paymentTPAActionsButtonsItems: ActionsButtonsDesignItemType[]
): void {
    store.paymentTPAActionsButtonsItems = paymentTPAActionsButtonsItems;
}
export function setMainContentItemTPA(
    mainContentItemTPA: TPAValinaItemType[]
): void {
    store.mainContentItemTPA = mainContentItemTPA;
}

export function setFinalMessageItems(
    finalMessageItems: FinalMessageItemType[]
): void {
    store.finalMessageItems = finalMessageItems;
}

export function setTPAValinaModalContent(
    TPAValinaModalContent: TPAValinaPageType
): void {
    store.TPAValinaModalContent = TPAValinaModalContent;
}

export function setPaymentCreditCardContent(
    paymentCreditCardContent: PaymentMainContentItemType
): void {
    store.paymentCreditCardContent = paymentCreditCardContent;
}

export function setIsHeaderOrderTakingActive(
    isHeaderOrderTakingActive: boolean
): void {
    store.isHeaderOrderTakingActive = isHeaderOrderTakingActive;
}

export function setUserAccountOrderTakingMessageItems(
    userAccountOrderTakingMessageItems: HeaderLanguageItemsType
): void {
    store.userAccountOrderTakingMessageItems =
        userAccountOrderTakingMessageItems;
}

export function setSelectedCategory(selectedCategory: {
    id: number | string;
    name: string;
    image: string;
    content: string;
    color?: string;
    active: boolean;
}): void {
    store.selectedCategory = selectedCategory;
}

export function setIsSelectedCategoryTitleActive(
    isSelectedCategoryTitleActive: boolean
): void {
    store.isSelectedCategoryTitleActive = isSelectedCategoryTitleActive;
}

export function setIsProductTitleActive(isProductTitleActive: boolean): void {
    store.isProductTitleActive = isProductTitleActive;
}

export function setIsProductDescriptionActive(
    isProductDescriptionActive: boolean
): void {
    store.isProductDescriptionActive = isProductDescriptionActive;
}

export function setProductAccessIcon(
    productAccessIcon: ProductAccessIconType
): void {
    store.productAccessIcon = productAccessIcon;
}

export function setCalorieIcon(
    calorieIcon: ProductAccessIconType
): void {
    store.calorieIcon = calorieIcon;
}

export function setIsOrderTakingConfigOptionsActive(
    isOrderTakingConfigOptionsActive: boolean
): void {
    store.isOrderTakingConfigOptionsActive = isOrderTakingConfigOptionsActive;
}

export function setActiveOrderTakingConfigOptions(
    activeOrderTakingConfigOptions: OptionsItemType[]
): void {
    store.activeOrderTakingConfigOptions = activeOrderTakingConfigOptions;
}
export function setActiveOrderTakingSummaryConfigOptions(
    activeOrderTakingSummaryConfigOptions: OptionsItemType[]
): void {
    store.activeOrderTakingSummaryConfigOptions = activeOrderTakingSummaryConfigOptions;
}

export function setIsClosingButtonActive(isClosingButtonActive: boolean): void {
    store.isClosingButtonActive = isClosingButtonActive
}

export function setActiveOrderTakingAllergensAccount(
    activeOrderTakingAllergensAccount: OptionsItemType[]
): void {
    store.activeOrderTakingAllergensAccount = activeOrderTakingAllergensAccount;
}

export function setIsCategorySubCategoryTitleActive(
    isCategorySubCategoryTitleActive: boolean
): void {
    store.isCategorySubCategoryTitleActive = isCategorySubCategoryTitleActive;
}

export function setShopCardMessage(
    shopCardMessage: ShopCardMessageLanguageType
): void {
    store.shopCardMessage = shopCardMessage;
}

export function setCompositeProductStepShopCardMessage(
    compositeProductStepShopCardMessage: ShopCardMessageLanguageType
): void {
    store.compositeProductStepShopCardMessage = compositeProductStepShopCardMessage;
}

export function setProgressColor(progressColor: ColorsContentType): void {
    store.progressColor = progressColor
}

export function setClosingButton(closingButton: ProductAccessIconType): void {
    store.closingButton = closingButton
}

export function setInformationMessageCompositeProduct(informationMessageCompositeProduct:
    boolean,

): void {
    store.informationMessageCompositeProduct = informationMessageCompositeProduct
}
export function setBasket(basket: boolean): void {
    store.basket = basket
}

export function setBreadcrumbTrail(breadcrumbTrail: boolean): void {
    store.breadcrumbTrail = breadcrumbTrail
}

export function setIsBackgroundColorActive(isBackgroundColorActive: boolean): void {
    store.isBackgroundColorActive = isBackgroundColorActive
}

export function setIsNextOrPreviousButtonsActive(isNextOrPreviousButtonsActive: boolean): void {
    store.isNextOrPreviousButtonsActive = isNextOrPreviousButtonsActive
}
export function setProductAccessIconCompositeProduct(
    productAccessIconCompositeProduct: ProductAccessIconType
): void {
    store.productAccessIconCompositeProduct = productAccessIconCompositeProduct;
}

export function setIsUserAccountOrderTakingActive(isUserAccountOrderTakingActive: boolean): void {
    store.isUserAccountOrderTakingActive = isUserAccountOrderTakingActive
}

export function setIsSummaryHeaderLogoActive(isSummaryHeaderLogoActive: boolean): void {
    store.isSummaryHeaderLogoActive = isSummaryHeaderLogoActive
}

export function setIsSummaryHeaderAccumulatedLoyaltyPointsActive(isSummaryHeaderAccumulatedLoyaltyPointsActive: boolean): void {
    store.isSummaryHeaderAccumulatedLoyaltyPointsActive = isSummaryHeaderAccumulatedLoyaltyPointsActive
}

export function setSummaryHeaderAccumulatedLoyaltyPointsItems(summaryHeaderAccumulatedLoyaltyPointsItems:
    [
        {
            name: string;
            languages: {
                [key: string]: {
                    content: string;
                    defaultContent: string;
                };

            }
        },
    ]
): void {
    store.summaryHeaderAccumulatedLoyaltyPointsItems = summaryHeaderAccumulatedLoyaltyPointsItems
}

export function setSummaryHeaderItems(summaryHeaderItems: {

    [key: string]: {
        content: string;
        defaultContent: string;
    };


}): void {
    store.summaryHeaderItems = summaryHeaderItems
}

export function setIsSalesMethodChangeActive(isSalesMethodChangeActive: boolean): void {
    store.isSalesMethodChangeActive = isSalesMethodChangeActive
}

export function setIsSalesMethodChangeInSummaryActive(isSalesMethodChangeActive: boolean): void {
    store.isSalesMethodChangeInSummaryActive = isSalesMethodChangeActive
}

export function setIsSummaryOrderTakingConfigOptionsActive(isSummaryOrderTakingConfigOptionsActive: boolean): void {
    store.isSummaryOrderTakingConfigOptionsActive = isSummaryOrderTakingConfigOptionsActive
}
export function setInitDesignStoreVariables() {

    store.activeSlideIndex = 0;
    store.isInformationMessageActive = false;
    store.isLanguagesActive = false;
    store.activeLanguages = [];
    store.isConfigOptionsActive = false;
    store.activeConfigOptions = [];
    store.isLogoActive = false;
    store.isActionButtonsActive = false;
    store.isUserAccountActive = false;
    store.actionsButtonsItems = [];
    store.mainContentItems = [];
    store.activeSalesMethodsItems = [];
    store.activeMainContentItems = [];
    store.saleMethodsNumber = 0;
    store.informationMessageItems = {};
    store.isTopBannerActive = false;
    store.isHeaderActive = false;
    store.userAccountMessageItems = {};
    store.activeTabIndex = -1;
    store.selectedLanguage = "";
    store.activePageName = "";
    store.isInformationModesPersonalized = false;
    store.selectedInformationModes = null;
    store.isIconActive = false;
    store.isSubStepLogoActive = false;
    store.isSubStepTopBannerActive = false;
    store.isSubStepActionButtonsActive = false;
    store.subStepActionsButtonsItems = [];
    store.isSubStepInformationMessageActive = false;
    store.isSubStepHeaderActive = false;
    store.subStepInformationMessageItems = {};
    store.hasSubSteps = false;
    store.selectedSubStep = {
        id: "",
        name: "",
        shortName: "",
        active: true,
        languages: {},
    };
    store.isSubStepActivated = false;
    store.selectedInformationModeValue = "bridge";
    store.selectedRegulationModeValue = "By credit card";
    store.finalMessage = "";
    store.footerTicketNumberMessage = {};
    store.isFooterTicketNumberMessageActive = false;
    store.footerFinalMessage = {};
    store.isFooterFinalMessageActive = false;
    store.isBackgroundActive = false;
    store.paymentMainContent = [];
    store.paymentSelectedRegulationModeValue = "creditCard";
    store.isPaymentImageActive = false;
    store.isPaymentMessageActive = false;
    store.isMainContentChangeMachine = false;
    store.informationMessageItem = {
        name: "",
        defaultName: "",
        isTextVisible: false,
        isDefaultTextVisible: false,
    };
    store.amountToBePaidItem = {
        name: "",
        defaultName: "",
    };
    store.totalAmountItem = {
        name: "",
        defaultName: "",
    };
    store.paymentInstructionsItem = {
        active: false,
        id: "",
        message: "",
        image: "",
        isImageVisible: false,
        defaultMessage: "",
        isTextVisible: false,
        TPAValina: {
            active: true,
            items: [
                {
                    id: "",
                    name: "",
                    defaultName: "",
                    shortName: "",
                    image: "",
                    defaultImage: "",
                    isNameVisible: true,
                    isDefaultNameVisible: true,
                },
            ],
        },
    };
    store.isValinaOptionActive = false;
    store.isTPAValina = true;
    store.isOptionModalOpen = false;
    store.paymentTPAInformationMessageItems = {
        active: false,
        informationMessage: {
            active: false,
            languages: {},
        },
        icon: {
            id: "",
            active: false,
            content: "",
        },
    };
    store.paymentTPAActionsButtonsItems = [];
    store.mainContentItemTPA = [];
    store.finalMessageItems = [];
    store.TPAValinaModalContent = {
        name: "",
        header: {
            active: false,
            informationMessage: {
                active: false,
                languages: {},
            },
            icon: {
                id: "",
                active: false,
                content: "",
            },
        },
        actionsButtons: {
            active: false,
            items: [],
        },
        title: "",
        prefix: "",
        content: "",
        animation: "",
    };
    store.paymentCreditCardContent = {
        id: "",
        name: "",
        shortName: "",
        languages: {},
    };
    store.isHeaderOrderTakingActive = false;
    store.userAccountOrderTakingMessageItems = {};
    store.selectedCategory = {
        id: 0,
        name: "",
        image: "",
        content: "",
        active: false,
    };
    store.isSelectedCategoryTitleActive = false;
    store.isProductTitleActive = false;
    store.isProductDescriptionActive = false;
    store.productAccessIcon = {
        id: "",
        active: false,
        content: "",
        defaultContent: "",
    };
    store.isOrderTakingConfigOptionsActive = false;
    store.activeOrderTakingConfigOptions = [];
    store.isCategorySubCategoryTitleActive = false;
    store.shopCardMessage = {};
    store.progressColor = {
        id: 0,
        title: "",
        content: `rgb(${0}, ${0}, ${0})`,
        defaultContent: `rgb(${0}, ${0}, ${0})`
    };
    store.closingButton = {
        id: "",
        active: false,
        content: "",
        defaultContent: "",
    };
    store.productAccessIconCompositeProduct = {
        id: "",
        active: false,
        content: "",
        defaultContent: "",
    };
    store.informationMessageCompositeProduct = false
    store.basket = false;
    store.breadcrumbTrail = false;
    store.isBackgroundColorActive = false;
    store.isNextOrPreviousButtonsActive = false;
    store.isUserAccountOrderTakingActive = false
    store.isSummaryHeaderLogoActive = false;
    store.isSummaryHeaderAccumulatedLoyaltyPointsActive = false;
    store.isSalesMethodChangeActive = false;
    store.isSummaryOrderTakingConfigOptionsActive = false;
    store.summaryHeaderAccumulatedLoyaltyPointsItems =

        [
            {
                name: "",
                languages: {
                    fr: {
                        content: "",
                        defaultContent: "",
                    },

                }
            },

        ];
    store.summaryHeaderItems = {

        fr: {
            content: "",
            defaultContent: ""
        }


    };

}