import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { AvForm } from "availity-reactstrap-validation";
import { Table } from "reactstrap";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { CustomInput as Input } from "@components/Common/CustomInput";
import {
    designStore,
    setPageUserAccountItems,
    setUserAccountOrderTakingMessageItems,
} from "@store";

export function TableContent(): JSX.Element {
    const { t } = useTranslation();

    const {
        selectedLanguage,
        userAccountOrderTakingMessageItems,
        isUserAccountOrderTakingActive,
    } = useSnapshot(designStore);

    function handleResetTranslationOnClickEvent() {
        const localUserAccountItems: HeaderLanguageItemsType = {
            ...userAccountOrderTakingMessageItems,
            [selectedLanguage]: {
                ...userAccountOrderTakingMessageItems[selectedLanguage],
                content:
                    userAccountOrderTakingMessageItems[selectedLanguage]
                        .defaultContent,
            },
        };
        setUserAccountOrderTakingMessageItems(localUserAccountItems);

        setPageUserAccountItems(localUserAccountItems, "orderTaking");
    }

    function handleUserAccountMessageItemInputOnChangeEvent(content: string) {
        const localItems: HeaderLanguageItemsType = {
            ...userAccountOrderTakingMessageItems,
            [selectedLanguage]: {
                ...userAccountOrderTakingMessageItems[selectedLanguage],
                content: content,
            },
        };

        setUserAccountOrderTakingMessageItems(localItems);
        setPageUserAccountItems(localItems, "orderTaking");
    }

    return (
        <div className="mt-3 mx-4">
            <AvForm>
                <Table bordered className="loyalty-languages-table__clz">
                    <thead>
                        <tr>
                            <th
                                style={{
                                    width: "30%",
                                    verticalAlign: "middle",
                                }}
                            >
                                <div className="ml-2 d-flex align-items-center">
                                    {t("Default text")}
                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="ml-2">{t("Custom")}</div>

                                    <StyledIconButton
                                        title={t("Reset")}
                                        icon="RefreshIcon"
                                        style={{ margin: "0px" }}
                                        onClick={() => {
                                            handleResetTranslationOnClickEvent();
                                        }}
                                    >
                                        <RefreshIcon height={15} width={15} />
                                    </StyledIconButton>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    {isUserAccountOrderTakingActive === true ? (
                        <tbody>
                            <tr>
                                <td
                                    className="cursor__clz"
                                    style={{ width: "30%" }}
                                >
                                    {userAccountOrderTakingMessageItems[
                                        selectedLanguage
                                    ].defaultContent !== undefined
                                        ? userAccountOrderTakingMessageItems[
                                              selectedLanguage
                                          ].defaultContent
                                        : ""}
                                </td>
                                <td>
                                    <Input
                                        value={
                                            userAccountOrderTakingMessageItems[
                                                selectedLanguage
                                            ].content !== undefined
                                                ? userAccountOrderTakingMessageItems[
                                                      selectedLanguage
                                                  ].content
                                                : ""
                                        }
                                        onChange={(
                                            event: React.ChangeEvent<
                                                HTMLInputElement
                                            >
                                        ) => {
                                            handleUserAccountMessageItemInputOnChangeEvent(
                                                event.target.value
                                            );
                                        }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    ) : null}
                </Table>
            </AvForm>
        </div>
    );
}
