import React from "react";
import { Card } from "reactstrap";
import { useSnapshot } from "valtio";
import classNames from "classnames";

import { designStore, generalConfigStore } from "@store";

import "@assets/css/global.css";
import { truncateString } from "@helpers/general";

export function PaymentConsummationCard(): JSX.Element {
    const {
        activeTabIndex,
        informationMessageItem,
        amountToBePaidItem,
        totalAmountItem,
        paymentSelectedRegulationModeValue,
    } = useSnapshot(designStore);
    const {
        project: {
            template: {
                content: { generalDesign },
            },
        },
    } = useSnapshot(generalConfigStore);

    return (
        <div
            className={classNames(
                "d-flex flex-column justify-content-center align-items-center",
                {
                    "border-wrapper__clz": activeTabIndex === 2,
                }
            )}
            style={{ width: "400px" }}
        >
            <Card
                style={{
                    width: "370px",
                    height: "122px",
                    borderRadius: "20px",
                    borderBottom: `6px solid ${
                        (generalDesign as GeneralDesignType).colors[0].content
                    }`,
                    boxShadow: "0px 3px 6px #00000029",
                    font: "Segoe UI",
                }}
            >
                <div
                    className={classNames("d-grid__clz cursor__clz", {
                        "p-2 d-flex flex-column justify-content-center":
                            informationMessageItem.isTextVisible === false,
                    })}
                    style={{
                        gridTemplateRows:
                            informationMessageItem.isTextVisible === true
                                ? "1fr 0.1fr 0.8fr 0.8fr"
                                : "1fr 1fr",
                        height: "100%",
                        gap:
                            paymentSelectedRegulationModeValue === "restoflash"
                                ? "10px"
                                : "unset",
                    }}
                >
                    {informationMessageItem.isTextVisible === true ? (
                        <div className="mt-2 d-flex flex-column align-items-center">
                            <div
                                className="d-flex justify-content-center "
                                style={{
                                    fontSize:
                                        "normal normal normal 20px/51px Open Sans",
                                    color: "#323333",
                                }}
                            >
                                {truncateString(
                                    informationMessageItem.name,
                                    20
                                )}
                            </div>

                            <hr
                                className="d-flex justify-content-between"
                                style={{
                                    height: "2px",
                                    width: "80%",
                                    backgroundColor: "#AFAFAF",
                                    border: "0px",
                                    margin: "8px !important",
                                }}
                            />
                        </div>
                    ) : null}
                    <div
                        className="d-flex justify-content-between align-items-center mx-1 px-2 font-weight-bold cursor__clz"
                        style={{
                            fontSize: "10px Segoe UI",
                        }}
                    >
                        {truncateString(amountToBePaidItem.name, 16)}
                        <div>5.00 €</div>
                    </div>
                    <div
                        className="d-flex justify-content-between align-items-center mx-1 px-2 cursor__clz"
                        style={{
                            fontSize: "10px",
                            color: "#7C7C7C",
                        }}
                    >
                        {truncateString(totalAmountItem.name, 16)}
                        <div>20.00 €</div>
                    </div>
                </div>
            </Card>
        </div>
    );
}
