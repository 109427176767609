import React from "react";
import { useTranslation } from "react-i18next/";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";
import { setMultiShopSwitch, store } from "@components/VerticalLayout/store";

import { CustomIconsComponent } from "./CustomIcons";
import PageTitle from "@components/Common/PageTitle";

type CustomColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
};

export function Compte() {
    const { t } = useTranslation();
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);

    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const { franchiseID } = useSnapshot(store);

    React.useEffect(() => {}, [selectedRows]);

    const uidEntity = localStorage.getItem("Uid_Entitie_User");
    //  const uidEntity = "8ecfe5ee-926b-423e-8050-c911dd81c147";

    const urlApi =
        "https://demonstration-consoleadmin-api.aureskonnect.com/apiAccess/v1/entity/" +
        uidEntity +
        "";

    const dataFetch = React.useCallback(async () => {
        const data = await (await fetch(urlApi)).json();
        localStorage.setItem("schemaFranchise", data.data[0].franchise_schema);
        setMultiShopSwitch(franchiseID, data.data[0]["Nom commercial"]);
    }, [urlApi, franchiseID]);
    React.useEffect(() => {
        dataFetch();
    }, [dataFetch]);
    let arrayOfCustomColumns: CustomColumnProps[] = [
        {
            indexOFColumn: 99,
            columnName: t("Actions"),
            customJsx: (e: any) => {
                return <CustomIconsComponent e={e} />;
            },
        },
    ];

    return (
        <React.Fragment>
            <PageTitle title={t("Home")} />

            <div className="page-content__clz">
                <h1> {t("Choose an account")}</h1>
                <DynamicTable
                    url={urlApi}
                    canExpand={true}
                    showFilter
                    canSelect={true}
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    actionColumn={() => <></>}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    canResize
                    showGlobalFilter
                    elevationTable={1}
                    setSelectedRows={setSelectedRows}
                    name="Choice"
                    minHeight={"500px"}
                    maxHeight={"500px"}
                />
            </div>
        </React.Fragment>
    );
}
