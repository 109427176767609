import { proxy } from "valtio";

type StoreType = {
    localShop: string[];
    idsLocalShop: string[];
    selectedRows: any[];
    isAssociatedModalClosed: boolean;
    ids: number;
    isModified: boolean;
    isActivation: boolean;
    isAssociatedClosed: boolean;
    shopsHoursSettingData: any[];
    selectedHours: any[];
    isHistoryShopsModalOpened: boolean;
    isModalOpened: boolean;
    shopsSelectedRow: any;
    isArchiveModalOpened: boolean;
    archivedData: any[];
    shopDataIsUpdated: boolean | number;
    franchiseDataIsUpdated: boolean;
    listFranchiseArchived: any[];
    isInputUpdated: boolean;
    isInlineErrorCleared: boolean;
    isAssociateModalOpened: boolean;
    activeHours: any[];
    shopRowData: any[];
    associatedHours: any[];
    selectedRowsAssociate: any[];
    isHistoryModalOpened: boolean;
    associatedDays: any[];
    modify: boolean;
};

export const store = proxy<StoreType>({
    localShop: [],
    idsLocalShop: [],
    selectedRows: [],
    isAssociatedModalClosed: false,
    ids: 0,
    isModified: false,
    isActivation: false,
    isAssociatedClosed: false,
    shopsHoursSettingData: [],
    selectedHours: [],
    isHistoryShopsModalOpened: false,
    isModalOpened: false,
    shopsSelectedRow: [],
    isArchiveModalOpened: false,
    archivedData: [],
    shopDataIsUpdated: false,
    franchiseDataIsUpdated: false,
    listFranchiseArchived: [],
    isInputUpdated: false,
    isInlineErrorCleared: false,
    isAssociateModalOpened: false,
    activeHours: [],
    shopRowData: [],
    associatedHours: [],
    selectedRowsAssociate: [],
    isHistoryModalOpened: false,
    associatedDays: [],
    modify: false,
});
export function setModify(value: boolean): void {
    store.modify = value;
}
export function setIsModalOpened(isModalOpened: boolean): any {
    store.isModalOpened = isModalOpened;
}
export function setIsHistoryShopsModalOpened(
    isHistoryShopsModalOpened: boolean
): void {
    store.isHistoryShopsModalOpened = isHistoryShopsModalOpened;
}

export function setShopsSelectedRow(shopSelectedRow: any): any {
    store.shopsSelectedRow = shopSelectedRow;
}

export function setShopsHoursSettingData(shopsHoursSettingData: any[]): void {
    store.shopsHoursSettingData = shopsHoursSettingData;
}

export function setLocalShop(localShop: string[]): void {
    store.localShop = localShop;
}
export function setIsAssociateModalClosed(
    isAssociatedModalClosed: boolean
): void {
    store.isAssociatedModalClosed = isAssociatedModalClosed;
}

export function setIdsLocalShop(idsLocalShop: string[]): void {
    store.idsLocalShop = idsLocalShop;
}

export function setSelectedRow(selectedRows: any[]): void {
    store.selectedRows = selectedRows;
}

export function setIsAssociatedClosed(isAssociatedModalClosed: boolean): void {
    store.isAssociatedModalClosed = isAssociatedModalClosed;
}

export function setIds(id: number): void {
    store.ids = id;
}

export function setIsModified(isModified: boolean): void {
    store.isModified = isModified;
}
export function setIsActivation(isActivation: boolean): void {
    store.isActivation = isActivation;
}
export function setSelectedHours(selectedHours: any[]): any {
    store.selectedHours = selectedHours;
}

export function setArchivedData(archivedData: any[]): any {
    store.archivedData = archivedData;
}
export function setIsArchiveModalOpened(isArchiveModalOpened: boolean): any {
    store.isArchiveModalOpened = isArchiveModalOpened;
}

export function setShopDataIsUpdated(shopDataIsUpdated: boolean): void {
    store.shopDataIsUpdated = shopDataIsUpdated;
}
export function setFranchiseDataIsUpdated(
    franchiseDataIsUpdated: boolean
): void {
    store.franchiseDataIsUpdated = franchiseDataIsUpdated;
}

export function setListFranchiseArchived(listFranchiseArchived: any[]): void {
    store.listFranchiseArchived = listFranchiseArchived;
}
export function setIsInputUpdated(isInputUpdated: boolean): any {
    store.isInputUpdated = isInputUpdated;
}

export function setIsInlineErrorCleared(isInlineErrorCleared: any): any {
    store.isInlineErrorCleared = isInlineErrorCleared;
}
export function setIsAssociateModalOpened(
    isAssociateModalOpened: boolean
): any {
    store.isAssociateModalOpened = isAssociateModalOpened;
}
export function setActiveHours(activeHour: any): void {
    let index = store.activeHours.findIndex(
        (element: any) => element.Id === activeHour.Id
    );
    index !== -1
        ? (store.activeHours[index] = activeHour)
        : store.activeHours.push(activeHour);
}
export function setAssociatedHours(associatedHours: any[]): void {
    store.associatedHours = associatedHours;
}
export function setShopRowData(shopRowData: any[]): void {
    store.shopRowData = shopRowData;
}
export function setSelectedRowsAssociate(selectedRowsAssociate: any[]): void {
    store.selectedRowsAssociate = selectedRowsAssociate;
}
export function setIsHistoryModalOpened(isHistoryModalOpened: boolean): void {
    store.isHistoryModalOpened = isHistoryModalOpened;
}
export function setAssociatedDays(associatedDays: any[]): void {
    store.associatedDays = associatedDays;
}

export default store;
