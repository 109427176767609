import { proxy, subscribe } from "valtio";

type StoreType = {
    isValidateCashFundForum: boolean
};

let initialStateStore = {
    isValidateCashFundForum: true
}

export const store = proxy<StoreType>(initialStateStore);
subscribe(store, () => {
    window.localStorage.setItem("store", JSON.stringify(store));
});
export function setIsValidateCashFundForum(value: boolean): void {
    store.isValidateCashFundForum = value;
}

