import React from "react";
import { StyledButton, StyledH2, StyledLabel } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { capitalize } from "@helpers/general";

type PrintersPathsModalType = {
    setIsDeviceAssociateModalOpened: Function;
    isDeviceAssociateModalOpened: boolean;
    rowData: any;
};

type CustomColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
};

export default function Modalresult({
    setIsDeviceAssociateModalOpened,
    isDeviceAssociateModalOpened,
    rowData,
}: PrintersPathsModalType) {
    const { t } = useTranslation();
    let arrayOfCustomColumns: CustomColumnProps[] = [];

    arrayOfCustomColumns.push({
        indexOFColumn: 999,
        columnName: t("Actions"),
        customJsx: () => (
            <StyledButton rounded variant="light">
                <span className="text-dark">{t("Select")}</span>
            </StyledButton>
        ),
    });

    return (
        <React.Fragment>
            <Modal
                size="xl"
                isOpen={isDeviceAssociateModalOpened}
                style={{
                    marginTop: "12%",
                    marginLeft: "30%",
                    width: "942px",
                    height: "517px",
                }}
            >
                <ModalHeader
                    toggle={() =>
                        setIsDeviceAssociateModalOpened!(
                            !isDeviceAssociateModalOpened
                        )
                    }
                >
                    <StyledH2>
                        {capitalize(t("Close cover"))}{" "}
                        {rowData.Application === ""
                            ? rowData.Périphérique + " - " + rowData.appId
                            : ` ${
                                  rowData.peripheral === undefined
                                      ? ""
                                      : rowData.peripheral
                              } / ${rowData.Application}`}
                    </StyledH2>
                </ModalHeader>

                <ModalBody style={{ height: "500px" }}>
                    <StyledLabel
                        style={{
                            marginTop: "200px",

                            marginLeft: "250px",
                            font: "normal normal 600 25px/33px Segoe UI",
                        }}
                    >
                        {t("Operation completed successfully")}!
                    </StyledLabel>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}
