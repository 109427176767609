import React from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { useSnapshot } from "valtio";

import {
    designStore,
    generalConfigStore,
    setIsHeaderOrderTakingActive,
    setIsOrderTakingPromoCodeActive,
    setIsOrderTakingProductInformationActive,
    setIsOrderTakingSelectedCategoryTitleActive,
    setIsOrderTakingTagsActive,
    setIsOrderTakingTopBannerActive,
    setIsPageHeaderActive,
    setIsPageProductAccessIconActive,
    setIsPagePromoCodeActive,
    setIsPageSelectedCategoryTitleActive,
    setIsPageTagsActive,
    setIsPageTopBannerActive,
    setIsPageUserAccountActive,
    setIsUserAccountOrderTakingActive,
} from "@store";

export function Functionalities(): JSX.Element {
    const { t } = useTranslation();
    const {
        isCustomerAccountActive,
        isOrderTakingTopBannerActive,
        isOrderTakingTagsActive,
        isOrderTakingProductInformationActive,
        isOrderTakingSelectedCategoryTitleActive,
        isCustomerAccountOrderTakingActive,
        isAllergenOrderTakingActive,
        isOrderTakingPromoCodeActive,
    } = useSnapshot(generalConfigStore);
    const { isUserAccountOrderTakingActive } = useSnapshot(designStore);

    function handleIsClientActiveSwitchOnClickEvent() {
        setIsUserAccountOrderTakingActive(!isUserAccountOrderTakingActive);
        setIsPageUserAccountActive(
            !isUserAccountOrderTakingActive,
            "orderTaking"
        );
        if (
            [
                !isUserAccountOrderTakingActive,
                isCustomerAccountOrderTakingActive,
                isAllergenOrderTakingActive,
            ].every((currentValue) => currentValue === false) === true
        ) {
            setIsHeaderOrderTakingActive(false);
            setIsPageHeaderActive(false, "orderTaking");
        } else {
            setIsHeaderOrderTakingActive(true);
            setIsPageHeaderActive(true, "orderTaking");
        }
    }
    function handleIsTopBannerActiveSwitchOnClickEvent() {
        setIsOrderTakingTopBannerActive(!isOrderTakingTopBannerActive);
        setIsPageTopBannerActive(!isOrderTakingTopBannerActive, "orderTaking");
    }

    return (
        <div
            style={{
                display: "grid",
                gridTemplateRows: "0.4fr 1.6fr",
                gap: "40px",
            }}
        >
            <div className="d-flex align-items-center mt-3">
                <span
                    style={{
                        font: "normal normal 600 35px/61px Nunito Sans",
                    }}
                >
                    {t("Functionalities")}
                </span>
            </div>

            <div className="d-flex flex-column" style={{ gap: "30px" }}>
                <div className="d-flex flex-row justify-content-between align-items-center">
                    {t("Banner")}

                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isOrderTakingTopBannerActive}
                        onChange={() => {
                            handleIsTopBannerActiveSwitchOnClickEvent();
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
                <div className=" d-flex flex-row justify-content-between align-items-center">
                    {t("Filter(tag)")}

                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isOrderTakingTagsActive}
                        onChange={() => {
                            setIsOrderTakingTagsActive(
                                !isOrderTakingTagsActive
                            );
                            setIsPageTagsActive(
                                !isOrderTakingTagsActive,
                                "orderTaking"
                            );
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
                <div className=" d-flex flex-row justify-content-between align-items-center">
                    {t("Title of selected category")}

                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isOrderTakingSelectedCategoryTitleActive}
                        onChange={() => {
                            setIsOrderTakingSelectedCategoryTitleActive(
                                !isOrderTakingSelectedCategoryTitleActive
                            );
                            setIsPageSelectedCategoryTitleActive(
                                !isOrderTakingSelectedCategoryTitleActive,
                                "orderTaking"
                            );
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
                <div className=" d-flex flex-row justify-content-between align-items-center">
                    {t("Client name")}

                    <ReactSwitch
                        disabled={
                            isCustomerAccountActive === false ? true : false
                        }
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isUserAccountOrderTakingActive}
                        onChange={handleIsClientActiveSwitchOnClickEvent}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
                <div className=" d-flex flex-row justify-content-between align-items-center">
                    {t("Product information access icon")}

                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isOrderTakingProductInformationActive}
                        onChange={() => {
                            setIsOrderTakingProductInformationActive(
                                !isOrderTakingProductInformationActive
                            );
                            setIsPageProductAccessIconActive(
                                !isOrderTakingProductInformationActive,
                                "orderTaking"
                            );
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
                <div className=" d-flex flex-row justify-content-between align-items-center">
                    {t("Module code promo")}

                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isOrderTakingPromoCodeActive}
                        onChange={() => {
                            setIsOrderTakingPromoCodeActive(
                                !isOrderTakingPromoCodeActive
                            );
                            setIsPagePromoCodeActive(
                                !isOrderTakingPromoCodeActive
                            );
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
            </div>
        </div>
    );
}
