import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { StyledLabel, StyledTextInput } from "@aureskonnect/react-ui";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { store } from "../../../components/VerticalLayout/store";
import storeCustomerAccount from "./store";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

export default function CostumerForm({ franchise }: any) {
    const { t } = useTranslation();
    const { oneShop, shopID } = useSnapshot(store);
    const { dataModal } = useSnapshot(storeCustomerAccount);
    const [dataInfo, setInfoData] = React.useState<any>([]);

    const apiUrlInfo = `https://demo-consoleadmin-api.aureskonnect.com/apiAccess/v1/entity/${franchise}`;

    React.useEffect(() => {
        let selectedAccount = localStorage.getItem("selectedAccount");
        async function getData() {
            const response = await fetch(apiUrlInfo);
            let actualData = await response.json();
            if (actualData.data.length > 0) {
                if (
                    actualData.data[0][t("Commercial name")] ===
                        selectedAccount &&
                    dataModal[t("Shop")] === undefined
                ) {
                    setInfoData(actualData.data[0]);
                } else if (dataModal[t("Shop")] === undefined) {
                    actualData.data[0].subRows.forEach((element: any) => {
                        if (element[t("Commercial name")] === selectedAccount)
                            setInfoData(element);
                    });
                } else {
                    setInfoData(JSON.parse(JSON.stringify(dataModal)));
                }
            }
        }

        getData();
    }, [apiUrlInfo, dataModal, t]);

    return (
        <React.Fragment>
            <div
                style={{
                    border: 0,
                    backgroundColor: "white",
                    padding: "20px",
                }}
            >
                <fieldset className="border p-2">
                    <legend className="w-auto px-2">
                        <StyledLabel htmlFor="text">
                            {t("Customer account information")}
                        </StyledLabel>
                    </legend>
                    <AvForm>
                        <div style={{ marginRight: "38px" }}>
                            <div className="d-flex justify-content-between">
                                <div className="p-1">
                                    <AvGroup>
                                        <StyledLabel htmlFor="path">
                                            {t("Customer ID")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            name="nn"
                                            disabled
                                            placeholder={t("")}
                                            type="text"
                                            value={oneShop ? shopID : franchise}
                                            onChange={(e: any) => {}}
                                            style={{
                                                width: "628px",
                                                background: "#E6E6E6",
                                            }}
                                            className="icc_inp_FRidClient"
                                        />
                                    </AvGroup>
                                </div>
                                <div className="p-1"></div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="p-1">
                                    <AvGroup style={{ width: "628px" }}>
                                        <StyledLabel htmlFor="path">
                                            {t("Trade name")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            name="nn"
                                            disabled
                                            placeholder={t("")}
                                            type="text"
                                            value={dataInfo["Commercial name"]}
                                            onChange={(e: any) => {}}
                                            style={{
                                                width: "628px",
                                                background: "#E6E6E6",
                                            }}
                                            className="icc_inp_FRtradeName"
                                        />
                                    </AvGroup>
                                </div>
                                <div className="p-1">
                                    <AvGroup style={{ width: "628px" }}>
                                        <StyledLabel htmlFor="path">
                                            {t("Company name")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            name="nn"
                                            disabled
                                            placeholder={t("")}
                                            type="text"
                                            value={
                                                dataInfo["RaisonSociale"] ||
                                                dataInfo["Socialreason"] ||
                                                dataInfo["Company name"]
                                            }
                                            onChange={(e: any) => {}}
                                            style={{
                                                width: "628px",
                                                background: "#E6E6E6",
                                            }}
                                            className="icc_inp_FRsocialReason"
                                        />
                                    </AvGroup>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="p-1">
                                    <AvGroup style={{ width: "628px" }}>
                                        <StyledLabel htmlFor="path">
                                            {t("Address")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            name="nn"
                                            disabled
                                            placeholder={t("")}
                                            type="text"
                                            value={dataInfo["Address"]}
                                            onChange={(e: any) => {}}
                                            style={{
                                                width: "628px",
                                                background: "#E6E6E6",
                                            }}
                                            className="icc_inp_FRadress"
                                        />
                                    </AvGroup>
                                </div>
                                <div className="p-1">
                                    <AvGroup style={{ width: "628px" }}>
                                        <StyledLabel htmlFor="path">
                                            {t("Postal code")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            name="nn"
                                            disabled
                                            placeholder={t("")}
                                            type="text"
                                            value={
                                                dataInfo["Code postal"] ||
                                                dataInfo["Code Postal"] ||
                                                dataInfo["Zip code"]
                                            }
                                            onChange={(e: any) => {}}
                                            style={{
                                                width: "628px",
                                                background: "#E6E6E6",
                                            }}
                                            className="icc_inp_FRcodePostal"
                                        />
                                    </AvGroup>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="p-1">
                                    <AvGroup style={{ width: "628px" }}>
                                        <StyledLabel htmlFor="path">
                                            {t("City")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            name="nn"
                                            disabled
                                            placeholder={t("")}
                                            type="text"
                                            value={
                                                dataInfo["ville"] ||
                                                dataInfo["City"]
                                            }
                                            onChange={(e: any) => {}}
                                            style={{
                                                width: "628px",
                                                background: "#E6E6E6",
                                            }}
                                            className="icc_inp_FRcity"
                                        />
                                    </AvGroup>
                                </div>
                                <div className="p-1">
                                    <AvGroup style={{ width: "628px" }}>
                                        <StyledLabel htmlFor="path">
                                            {t("Country")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            name="nn"
                                            disabled
                                            placeholder={t("")}
                                            type="text"
                                            value={dataInfo["Pays"]}
                                            onChange={(e: any) => {}}
                                            style={{
                                                width: "628px",
                                                background: "#E6E6E6",
                                            }}
                                            className="icc_inp_FRcountry"
                                        />
                                    </AvGroup>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="p-1">
                                    <AvGroup style={{ width: "628px" }}>
                                        <StyledLabel htmlFor="path">
                                            {t("Telephone 1")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            name="nn"
                                            disabled
                                            placeholder={t("")}
                                            type="text"
                                            value={
                                                dataInfo["Téléphone1"] ||
                                                dataInfo["portable1"] ||
                                                dataInfo["Phone 1"]
                                            }
                                            onChange={(e: any) => {}}
                                            style={{
                                                width: "628px",
                                                background: "#E6E6E6",
                                            }}
                                            className="icc_inp_FRphone1"
                                        />
                                    </AvGroup>
                                </div>
                                <div className="p-1">
                                    <AvGroup style={{ width: "628px" }}>
                                        <StyledLabel htmlFor="path">
                                            {t("Telephone 2")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            name="nn"
                                            disabled
                                            placeholder={t("")}
                                            type="text"
                                            value={
                                                dataInfo["Téléphone2"] ||
                                                dataInfo["porable2"] ||
                                                dataInfo["Phone 2"]
                                            }
                                            onChange={(e: any) => {}}
                                            style={{
                                                width: "628px",
                                                background: "#E6E6E6",
                                            }}
                                            className="icc_inp_FRphone2"
                                        />
                                    </AvGroup>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="p-1">
                                    <AvGroup style={{ width: "628px" }}>
                                        <StyledLabel htmlFor="path">
                                            {t("Siret number")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            name="nn"
                                            disabled
                                            placeholder={t("")}
                                            type="text"
                                            value={dataInfo["siret"]}
                                            onChange={(e: any) => {}}
                                            style={{
                                                width: "628px",
                                                background: "#E6E6E6",
                                            }}
                                            className="icc_inp_FRnSiret"
                                        />
                                    </AvGroup>
                                </div>
                                <div className="p-1">
                                    <AvGroup style={{ width: "628px" }}>
                                        <StyledLabel htmlFor="path">
                                            {t("VAT number")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            name="nn"
                                            disabled
                                            placeholder={t("")}
                                            type="text"
                                            value={dataInfo["numTva"]}
                                            onChange={(e: any) => {}}
                                            style={{
                                                width: "628px",
                                                background: "#E6E6E6",
                                            }}
                                            className="icc_inp_FRnTva"
                                        />
                                    </AvGroup>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="p-1">
                                    <AvGroup style={{ width: "628px" }}>
                                        <StyledLabel htmlFor="path">
                                            {t("APE/NAF code")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            name="nn"
                                            disabled
                                            placeholder={t("")}
                                            type="text"
                                            value={
                                                dataInfo["codeApe"] ||
                                                dataInfo["codeape"]
                                            }
                                            onChange={(e: any) => {}}
                                            style={{
                                                width: "628px",
                                                background: "#E6E6E6",
                                            }}
                                            className="icc_inp_FRcodeApeNaf"
                                        />
                                    </AvGroup>
                                </div>
                                <div className="p-1">
                                    <AvGroup style={{ width: "628px" }}>
                                        <StyledLabel htmlFor="path">
                                            {t("Profile")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            name="nn"
                                            disabled
                                            placeholder={t("")}
                                            type="text"
                                            value={dataInfo["Profile"]}
                                            onChange={(e: any) => {}}
                                            style={{
                                                width: "628px",
                                                background: "#E6E6E6",
                                            }}
                                            className="icc_inp_FRprofile"
                                        />
                                    </AvGroup>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="p-1">
                                    <AvGroup style={{ width: "628px" }}>
                                        <StyledLabel htmlFor="path">
                                            {t("E-mail address")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            name="nn"
                                            disabled
                                            placeholder={t("")}
                                            type="text"
                                            value={
                                                dataInfo["adresseEmail"] ||
                                                dataInfo["email"]
                                            }
                                            onChange={(e: any) => {}}
                                            style={{
                                                width: "628px",
                                                background: "#E6E6E6",
                                            }}
                                            className="icc_inp_FRadresseEmail"
                                        />
                                    </AvGroup>
                                </div>
                                <div className="p-1">
                                    <AvGroup style={{ width: "628px" }}>
                                        <StyledLabel htmlFor="path">
                                            {t("Website")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            name="nn"
                                            disabled
                                            placeholder={t("")}
                                            type="text"
                                            value={
                                                dataInfo["siteweb"] ||
                                                dataInfo["siteWeb"]
                                            }
                                            onChange={(e: any) => {}}
                                            style={{
                                                width: "628px",
                                                background: "#E6E6E6",
                                            }}
                                            className="icc_inp_FRwebsite"
                                        />
                                    </AvGroup>
                                </div>
                            </div>
                        </div>
                    </AvForm>
                </fieldset>
            </div>
        </React.Fragment>
    );
}
