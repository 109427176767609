import React from "react";
import { CheckboxIcon, StyledH2, StyledLabel } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

// import {
//     resetStore,
//     setCheckModificationEffect,
//     setIsAddPeripheralClicked,
// } from "./store";

import { capitalize } from "@helpers/general";
import { store as device } from "../../../components/VerticalLayout/store";

import { useSnapshot } from "valtio";
import { toast } from "react-toastify";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type PrintersPathsModalType = {
    setIsDeviceDissociateModalOpened: Function;
    isDeviceDissociateModalOpened: boolean;
    selectedRows: any;
    isDataUpdated: boolean | number;
    setDataIsUpdated: Function;
};

export default function DeviceDissociateModal({
    setIsDeviceDissociateModalOpened,
    isDeviceDissociateModalOpened,
    selectedRows,
    isDataUpdated,
    setDataIsUpdated,
}: PrintersPathsModalType) {
    const { t } = useTranslation();

    const { oneShop, userID, shopID, franchiseID, operatorID } = useSnapshot(
        device
    );
    const [
        isClickedBouttonValider,
        setIsClickedBouttonValider,
    ] = React.useState<boolean>(true);

    async function dissociateDevice() {
        setIsClickedBouttonValider(false);
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/application/kds/printer`;

        let data = {
            userId: userID,
            shopId: oneShop ? shopID : selectedRows[0].shopId,
            franchiseId: franchiseID,
            appId: "KDS",
            appId_children: selectedRows[0]?.appId,
            operatorId: operatorID,
            designation: selectedRows[0].applicationDesignation,
            peripheralName: selectedRows[0]["Périphérique"],
            peripheralId: selectedRows[0]?.peripheralId,
        };
        try {
            fetch(apiUrlAdd, {
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bareer ${localStorage.getItem("jwt")}`,
                },
                body: JSON.stringify(data),
                method: "delete",
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        throw Error("error");
                    }
                    toast.success(
                        `${t(
                            "Unpairing of this device has been successfully completed"
                        )!}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            theme: "colored",
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setIsDeviceDissociateModalOpened!(
                        !isDeviceDissociateModalOpened
                    );
                    setIsClickedBouttonValider(true);

                    setDataIsUpdated!(true);
                });
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }
    return (
        <React.Fragment>
            <Modal centered isOpen={isDeviceDissociateModalOpened}>
                <ModalHeader
                    toggle={() => {
                        setIsDeviceDissociateModalOpened!(
                            !isDeviceDissociateModalOpened
                        );
                        setIsClickedBouttonValider(true);
                        setDataIsUpdated(!isDataUpdated);
                    }}
                >
                    <StyledH2>{capitalize(t("Alert"))}</StyledH2>
                </ModalHeader>
                <ModalBody>
                    <StyledLabel className="pl-3 m-0">
                        {t("Please confirm unpairing this device")}
                    </StyledLabel>
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <CustomSecondaryColorButton
                        outline
                        variant="light"
                        onClick={() => {
                            setIsDeviceDissociateModalOpened!(
                                !isDeviceDissociateModalOpened
                            );
                            setDataIsUpdated!(true);
                            setIsClickedBouttonValider(true);
                        }}
                        rounded
                    >
                        {t("Cancel")}
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        className="mr-2"
                        disabled={!isClickedBouttonValider}
                        variant="primary"
                        onClick={() => {
                            dissociateDevice();
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
