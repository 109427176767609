import { CardWrapperSpecific } from "./CardWrapperSpecific";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { StyledH2 } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { setItemActivation, store } from "../store";

export default function Activation() {
    const { t } = useTranslation();
    const { itemActivation, dataSetupApk } = useSnapshot(store);
    const [items] = React.useState<any>([
        {
            name: t("C&C website"),
            active: false,
            image: "./images/applicationc&c.png",
        },
        {
            name: t("C&C app"),
            active: false,
            image: "./images/sitec&c.png",
        },
    ]);
console.log(t("C&C website"));
    useEffect(() => {
        if (
            dataSetupApk[0] !== undefined &&
            dataSetupApk[0].itemActivation !== undefined
        ) {
            setItemActivation(dataSetupApk[0].itemActivation);
        } else {
            setItemActivation(items);
        }
    }, [dataSetupApk, items]);

    return (
        <React.Fragment>
            <StyledH2
                style={{
                    font: "normal normal 600 45px/61px Nunito Sans",
                    color: "#000000",
                    opacity: "1",
                }}
                className="ml-5 pt-5"
            >
                {t("Activation")}
            </StyledH2>
            <div
                className="d-flex flex-row justify-content-center align-items-center mt-5"
                style={{ gap: "100px" }}
            >
                {itemActivation.map((item: any, index: number) => (
                    <CardWrapperSpecific
                        items={itemActivation}
                        index={index}
                        setItems={setItemActivation}
                    />
                ))}
            </div>
        </React.Fragment>
    );
}
