import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    CheckboxIcon,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";

import { store } from "../../../../components/VerticalLayout/store";
import { setIsDataUpdated, store as logoStore } from "../store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationDeleteMessageType = {
    setIsModalConfirmationOpened: Function;
    isModalConfirmationOpened: boolean;
    setDataIsUpdated: Function;
    selectedRows: any;
    setToastLimit: Function;
};

export function ConfirmationActivePaymentMethod({
    setIsModalConfirmationOpened,
    isModalConfirmationOpened,
    setDataIsUpdated,
    selectedRows,
    setToastLimit,
}: ConfirmationDeleteMessageType) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const { shopsPaymentMethodsData } = useSnapshot(logoStore);

    const [error, setError] = React.useState(false);

    function ArchivedPaymentMethod(e: any) {
        let idsAssociated = ["0"];
        if (shopsPaymentMethodsData !== undefined) {
            shopsPaymentMethodsData.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    if (el.id === e.id) idsAssociated.push(el.idShop);
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];

        return idsAssociated;
    }

    async function activePaymentMethod(isValidation: boolean) {
        setToastLimit(1);
        toast.dismiss();
        let localData: any[] = [];
        Object.values(selectedRows).forEach(async (element: any) => {
            const id = element.id;
            let shopsArchived = ArchivedPaymentMethod(element);
            let objectData = {
                userId: userID,
                shopId: isValidation ? shopsArchived : ["0"],
                franchiseId: franchiseID,
                operatorId: operatorID,
                data: {
                    [id]: {
                        designation: element[t("Type of payment method")],
                        currency: element.Devise,
                        display: element[t("Display name")],
                        shopId: element.idShop,
                        saleSupport: element[t("Support de vente")],
                        country: element[t("Country")],
                        tag: element.Tag,
                        isActive: element.isActive,
                        isArchived: false,
                    },
                },
            };

            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod`;
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(objectData),
            };

            localData.push(
                fetch(apiUrlAdd, requestOptions).then((response) =>
                    response.json()
                )
            );

            try {
                mutate(apiUrlAdd, await Promise.all(localData).then(() => {}));
            } catch (e) {
                setError(true);
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        });

        if (error === false) {
            toast.success(
                `${t("The Payment method is successfully unarchived")!}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
            setDataIsUpdated!(true);
            setIsDataUpdated(true);
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalConfirmationOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setDataIsUpdated(true);
                    setIsModalConfirmationOpened(!isModalConfirmationOpened);
                }}
            >
                <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        "Attention! the payment method is archived at store level. Do you want to activate it in shops too?"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        activePaymentMethod(false);
                        setIsModalConfirmationOpened(
                            !isModalConfirmationOpened
                        );
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        activePaymentMethod(true);
                        setIsModalConfirmationOpened(
                            !isModalConfirmationOpened
                        );
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
