import React from "react";
import { CSSTransition } from "react-transition-group";
import { useSnapshot } from "valtio";
import { Card } from "reactstrap";

import { designStore, generalConfigStore } from "@store";

import { getImageContentById, truncateString } from "@helpers/general";

import { ActionButton } from "./ActionButton";
import { ContainerWrapper } from "./ContainerWrapper";

export function Preview(): JSX.Element {
    const {
        selectedLanguage,
        paymentMainContent,
        TPAValinaModalContent,
        paymentCreditCardContent,
    } = useSnapshot(designStore);

    const {
        chosenTransition,
        isAnimationIn,
        project: {
            template: {
                content: { generalDesign },
            },
            files: images,
        },
    } = useSnapshot(generalConfigStore);

    const selectedTPAValinaItem = paymentMainContent.filter((item) => {
        return item.shortName === "creditCard";
    })[0].languages[selectedLanguage].paymentInstructions.TPAValina;

    const logoId = images.find((image) => {
        return image.name === "paymentIcon";
    })?.id;

    const logoContent = getImageContentById(
        logoId as string,
        images,
        selectedLanguage
    );
    const logoSrc =
        logoContent?.includes("http") || logoContent?.includes("https")
            ? logoContent
            : `./images/${logoContent}`;

    return (
        <React.Fragment>
            <div
                className="d-flex justify-content-center align-items-center"
                style={{
                    backgroundColor: "#363636",
                    borderRadius: "10px",
                    height: "720px",
                    width: "400px",
                }}
            >
                <CSSTransition
                    in={isAnimationIn}
                    appear={true}
                    timeout={chosenTransition.timeout}
                    classNames={chosenTransition.className}
                >
                    {selectedTPAValinaItem !== undefined ? (
                        <div
                            style={{
                                height: "700px",
                                width: "380px",
                                borderRadius: "10px",
                            }}
                        >
                            <div
                                className="d-flex justify-content-center align-items-center"
                                style={{
                                    height: "700px",
                                }}
                            >
                                <Card
                                    className="d-grid__clz"
                                    style={{
                                        width: "340px",
                                        height: "500px",
                                        borderRadius: "48px",
                                        background:
                                            "#FFFFFF 0% 0% no-repeat padding-box",
                                        borderTop: `6px solid ${
                                            (generalDesign as GeneralDesignType)
                                                .colors[0].content
                                        }`,
                                        boxShadow: "0px 3px 6px #00000029",
                                        font: "Segoe UI",
                                        gridTemplateRows:
                                            "0.8fr 0.8fr 1.8fr 0.6fr",
                                    }}
                                >
                                    <ContainerWrapper
                                        isIconActive={
                                            (TPAValinaModalContent.header
                                                .icon as IconType).active
                                        }
                                        isInformationMessageActive={
                                            TPAValinaModalContent.header
                                                .informationMessage.active
                                        }
                                        isActionButtonsActive={
                                            TPAValinaModalContent.actionsButtons
                                                .items[0].active
                                        }
                                    >
                                        {(TPAValinaModalContent.header
                                            .icon as IconType).active ===
                                        true ? (
                                            <div className="d-flex justify-content-center align-items-center">
                                                <img
                                                    alt="img"
                                                    src={logoSrc}
                                                    style={{
                                                        height: "64px",
                                                        width: "92px",
                                                    }}
                                                />
                                            </div>
                                        ) : null}
                                        {TPAValinaModalContent.header
                                            .informationMessage.active ===
                                        true ? (
                                            <div
                                                className="d-flex justify-content-center align-items-center cursor__clz"
                                                style={{
                                                    textAlign: "center",
                                                    font:
                                                        " normal normal 600 23px/25px Segoe UI",
                                                    color: "#0D0C0C",
                                                }}
                                            >
                                                {truncateString(
                                                    TPAValinaModalContent.header
                                                        .informationMessage
                                                        .languages[
                                                        selectedLanguage
                                                    ].content,
                                                    27
                                                )}
                                            </div>
                                        ) : null}
                                        <div
                                            className="d-flex justify-content-center align-items-center"
                                            style={{
                                                gap: "20px",
                                                width: "350px",
                                            }}
                                        >
                                            {paymentCreditCardContent.languages[
                                                selectedLanguage
                                            ].paymentInstructions.TPAValina?.items.map(
                                                (item, index: number) => (
                                                    <div
                                                        key={index}
                                                        style={{
                                                            display: "grid",
                                                            gridTemplateRows:
                                                                " 110px 25px",
                                                            gap: "5px",
                                                        }}
                                                    >
                                                        <div>
                                                            <img
                                                                alt="img"
                                                                src={
                                                                    getImageContentById(
                                                                        item.id,
                                                                        images,
                                                                        selectedLanguage
                                                                    )?.includes(
                                                                        "http"
                                                                    ) ||
                                                                    getImageContentById(
                                                                        item.id,
                                                                        images,
                                                                        selectedLanguage
                                                                    )?.includes(
                                                                        "https"
                                                                    )
                                                                        ? getImageContentById(
                                                                              item.id,
                                                                              images,
                                                                              selectedLanguage
                                                                          )
                                                                        : `./images/${getImageContentById(
                                                                              item.id,
                                                                              images,
                                                                              selectedLanguage
                                                                          )}`
                                                                }
                                                                style={{
                                                                    width:
                                                                        "103px",
                                                                    height:
                                                                        "106px",
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="text-center cursor__clz">
                                                            {item.isNameVisible ===
                                                            true
                                                                ? truncateString(
                                                                      item.name,
                                                                      11
                                                                  )
                                                                : null}
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        {TPAValinaModalContent.actionsButtons
                                            .items[0].active === true ? (
                                            <ActionButton />
                                        ) : null}
                                    </ContainerWrapper>
                                </Card>
                            </div>
                        </div>
                    ) : null}
                </CSSTransition>
            </div>
        </React.Fragment>
    );
}
