import React from "react";
import { useTable } from "react-table";
import {
    DragDropContext,
    Droppable,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    DropResult,
} from "react-beautiful-dnd";
import styled from "styled-components";

import SimpleBar from "simplebar-react";

const Tr = styled.tr`
    background-color: white;
    display: ${({ isDragging }: { isDragging: boolean }) =>
        isDragging ? "table" : ""};
`;

// eslint-disable-next-line
export function Table({
    columns,
    activeStateScreenItems,
    setActiveStateScreenItems,
}: any) {
    const table = useTable({
        columns,
        data: activeStateScreenItems,
    });

    const { getTableProps, headerGroups, prepareRow, rows } = table;

    function handleDragEnd(result: DropResult) {
        if (!result.destination) return;

        const newData = [...activeStateScreenItems];
        const [movedRow] = newData.splice(result.source.index, 1);
        newData.splice(result.destination.index, 0, movedRow);

        setActiveStateScreenItems(newData);
    }

    return (
        <table {...getTableProps()}>
            <thead>
                {
                    // eslint-disable-next-line
                    headerGroups.map((headerGroup: any, index: number) => (
                        <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(
                                // eslint-disable-next-line
                                (column: any, index: number) => (
                                    <th
                                        key={index}
                                        {...column.getHeaderProps()}
                                    >
                                        {column.render("Header")}
                                    </th>
                                )
                            )}
                        </tr>
                    ))
                }
            </thead>
            <SimpleBar autoHide={true} style={{ maxHeight: 500 }}>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="table-body">
                        {(provided) => (
                            <tbody
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {
                                    // eslint-disable-next-line
                                    rows.map((row: any) => {
                                        prepareRow(row);
                                        return (
                                            <Draggable
                                                draggableId={row.original.id}
                                                key={row.original.id}
                                                index={row.index}
                                            >
                                                {(
                                                    provided: DraggableProvided,
                                                    snapshot: DraggableStateSnapshot
                                                ) => {
                                                    return (
                                                        <Tr
                                                            {...row.getRowProps()}
                                                            {...provided.draggableProps}
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            isDragging={
                                                                snapshot.isDragging
                                                            }
                                                        >
                                                            {row.cells.map((
                                                                // eslint-disable-next-line
                                                                cell: any,
                                                                index: number
                                                            ) => (
                                                                <td
                                                                    key={index}
                                                                    {...cell.getCellProps()}
                                                                >
                                                                    {cell.render(
                                                                        "Cell",
                                                                        {
                                                                            dragHandleProps:
                                                                                provided.dragHandleProps,
                                                                            isSomethingDragging:
                                                                                snapshot.draggingOver,
                                                                        }
                                                                    )}
                                                                </td>
                                                            ))}
                                                        </Tr>
                                                    );
                                                }}
                                            </Draggable>
                                        );
                                    })
                                }
                                {provided.placeholder}
                            </tbody>
                        )}
                    </Droppable>
                </DragDropContext>
            </SimpleBar>
        </table>
    );
}
