import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledLabel, CheckboxIcon } from "@aureskonnect/react-ui";
import { mutate } from "swr";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";

import { store } from "@components/VerticalLayout/store";
import {
    store as generalSettingStore,
    setIsAssociateModalOpened,
    setNumberMultiShopActive,
    setIsArchived,
    setGlobalDataIsUpdated,
} from "./store";

import { capitalize } from "@helpers/general";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationAssociatedMessageType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    setDataIsUpdated?: Function;
    setRowData?: Function;
};

export function ConfirmationAssociatedMessage({
    isModalOpened,
    setIsModalOpened,
    setDataIsUpdated,
    setRowData,
}: ConfirmationAssociatedMessageType) {
    const { t } = useTranslation();
    const { franchiseID, userID, oneShop, operatorID } = useSnapshot(store);
    const {
        isAssociateModalOpened,
        localShop,
        numberMultiShopActive,
        selectedRows,
        ids,
        message,
        shopRowData,
        isArchived,
        globalDataIsUpdated,
    } = useSnapshot(generalSettingStore);

    const [error] = React.useState<boolean>(false);

    function checkIfExceptionalHoursAssociated(shopId: string) {
        let associated = shopRowData.map((elt: any) => {
            if (elt.shopId === shopId) {
                return elt.subRows;
            } else {
                return null;
            }
        });
        return associated.filter((el: any) => el)[0];
    }

    async function AssociateExceptionalHours() {
        toast.dismiss();
        let isReassignment = false;
        let localData: any = [];
        for (let element of localShop) {
            let associated = checkIfExceptionalHoursAssociated(element);
            let objectData = {};
            let hours: any[] = [];
            if (selectedRows.length !== 0) {
                selectedRows.forEach(async (el: any) => {
                    if (el.subRows !== undefined) {
                        el.subRows.forEach((element: any) => {
                            hours.push({
                                startTime: element[t("Start hour")],
                                endTime: element[t("End hour")],
                                overLappedStart: false,
                                overLappedEnd: false,
                                inputEndTime: false,
                                inputStartTime: false,
                            });
                        });
                        let indexInAssociated =
                            !oneShop && associated !== undefined
                                ? associated.findIndex(
                                      (item: any) => item.Id === el.Id
                                  )
                                : -1;

                        if (oneShop || indexInAssociated === -1) {
                            const id: any = el.Id;
                            Object.assign(objectData, {
                                [id]: {
                                    id: id,
                                    designation: el[t("Designation")],
                                    state: el.state,
                                    startDate: el.startDate,
                                    endDate: el.endDate,
                                    hours: hours,
                                    isArchived: false,
                                    isActive: false,
                                    recurrence: el[t("Recurrence")],
                                    endDate_recurrence: el.endDate_recurrence,
                                    recurrenceDays: el.recurrenceDays,
                                    shopId: element,
                                },
                            });
                        }
                    }
                });
                let dataExceptionalHours = {
                    userId: userID,
                    shopId: [element],
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    data: objectData,
                    isShop: true,
                    isAssociate: true,
                };
                // eslint-disable-next-line
                Object.values(objectData).forEach((elt: any) => {
                    if (elt.isReassignment === true) {
                        isReassignment = true;
                    }
                });

                localData.push(
                    fetch(
                        `${process.env.REACT_APP_API_V2_URL}/settings/general/exceptionalHours`,
                        {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify(dataExceptionalHours),
                        }
                    ).then((response) => response.json())
                );
            }
        }
        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/exceptionalHours`,
                await Promise.all(localData).then((result) => {})
            );
        } catch (e) {
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
        if (error === false) {
            toast.success(
                `${t(
                    isReassignment
                        ? "The reassignment was successful"
                        : "The assignment was successful"
                )}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
            setNumberMultiShopActive(
                numberMultiShopActive +
                    selectedRows.length * localShop.length -
                    ids
            );
            setDataIsUpdated !== undefined && setDataIsUpdated(true);
            setRowData !== undefined && setRowData([]);
            setGlobalDataIsUpdated(!globalDataIsUpdated);
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => setIsModalOpened(!isModalOpened)}
            >
                <span>{capitalize(t("Alert"))}</span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {message}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                {isArchived ? (
                    <CustomMainColorButton
                        rounded
                        className="mr-2"
                        variant="primary"
                        onClick={() => {
                            setIsModalOpened(!isModalOpened);
                            setIsArchived(false);
                        }}
                    >
                        {t("Ok")}
                    </CustomMainColorButton>
                ) : (
                    <React.Fragment>
                        <CustomSecondaryColorButton
                            outline
                            variant="light"
                            onClick={() => {
                                setIsModalOpened(!isModalOpened);
                            }}
                            rounded
                        >
                            {t("Cancel")}
                        </CustomSecondaryColorButton>
                        <CustomMainColorButton
                            rounded
                            className="mr-2"
                            variant="primary"
                            onClick={() => {
                                AssociateExceptionalHours();
                                setIsModalOpened(!isModalOpened);
                                setIsAssociateModalOpened(
                                    !isAssociateModalOpened
                                );
                            }}
                        >
                            {t("Validate")}
                        </CustomMainColorButton>
                    </React.Fragment>
                )}
            </ModalFooter>
        </StyledModal>
    );
}
