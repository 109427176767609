import React from "react";
import classnames from "classnames";
import ReactSwitch from "react-switch";
import { toast } from "react-toastify";

import { CheckboxIcon, EyeIcon, TrashIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { Tooltip } from "@mui/material";
import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import { store as logo } from "../../../../components/VerticalLayout/store";
import store, {
    setIsHistoryShopsModalOpened,
    setNumberActiveMultiShop,
    setNumberArchiveMultiShop,
    setSelectedLogo,
} from "../store";
import ErrorToast from "@components/Common/ErrorTost";

export function CustomIcons({
    selectedRowData,
    setDataIsUpdated,
    isLogoArchived,
}: any) {
    const { t } = useTranslation();
    const {
        numberActiveMultiShop,
        numberArchiveMultiShop,
        isHistoryShopsModalOpened,
        shopsSelectedRow,
        logoData,
        modify,
    } = useSnapshot(store);
    const { franchiseID, userID, operatorID } = useSnapshot(logo);

    const [choiceState, setLogoState] = React.useState<boolean>(
        selectedRowData.selectedRow?.isActive
    );
    const [isClicked, setIsClicked] = React.useState<boolean>(false);
    function ArchivedLogo() {
        let idsAssociated: any[] = [];
        if (logoData !== undefined) {
            logoData.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    let dataArchive = ArchivedLogo();

    async function handleStateChange(dataState: boolean) {
        let id = selectedRowData?.selectedRow.Id;
        let dataLogo = {
            userId: userID,
            shopId: [selectedRowData?.selectedRow.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            data: {
                [id]: {
                    designation: selectedRowData?.selectedRow[t("Designation")],
                    tag: selectedRowData?.selectedRow.tag,
                    shopId: selectedRowData?.selectedRow.shopId,
                    isArchived: selectedRowData?.selectedRow.isArchived,
                    isActive: dataState,
                    support_de_vente:
                        selectedRowData.selectedRow[t("Sales support")],
                    urlImage: selectedRowData.selectedRow[t("Image")],
                },
            },
        };
        const data = new FormData();
        data.append("dataLogo", JSON.stringify(dataLogo));
        data.append("image", "");
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/logo`;
        const requestOptions = {
            method: "POST",

            body: data,
        };
        mutate(
            apiUrl,
            await fetch(apiUrl, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }

                    toast.success(
                        !choiceState
                            ? `${t("logo has been successfully activated")}`
                            : `${t("logo has been successfully deactivated")}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setDataIsUpdated!(true);
                })
                .catch((error: any) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }

    async function handleActiveLogo(selectedRowData: any) {
        setIsClicked(false);
        toast.dismiss();
        let dataLogo = {
            userId: userID,
            shopId: [selectedRowData.selectedRow.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            isShop: true,
            data: {
                [selectedRowData.selectedRow.Id]: {
                    designation: selectedRowData.selectedRow[t("Designation")],
                    support_de_vente:
                        selectedRowData.selectedRow[t("Sales support")],
                    urlImage: selectedRowData.selectedRow[t("Image")],

                    shopId: selectedRowData.selectedRow.shopId,

                    tag: selectedRowData.selectedRow.tag,
                    isActive: false,
                    isArchived: false,
                },
            },
        };
        const data = new FormData();
        data.append("dataLogo", JSON.stringify(dataLogo));
        data.append("image", "");

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/logo`;
        const requestOptions = {
            method: "POST",

            body: data,
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t("logo has been successfully activated")!}`,

                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated!(true);
                        setNumberActiveMultiShop(numberActiveMultiShop + 1);
                        setNumberArchiveMultiShop(numberArchiveMultiShop - 1);
                        setIsClicked(true);
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function handleArchiveLogo(selectedRowData: any) {
        setIsClicked(false);
        let dataLogo = {
            userId: userID,
            shopId: [selectedRowData?.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            data: {
                [selectedRowData?.Id]: {
                    designation: selectedRowData[t("Designation")],
                    support_de_vente: selectedRowData[t("Sales support")],
                    urlImage: selectedRowData[t("Image")],

                    shopId: selectedRowData?.shopId,

                    tag: selectedRowData?.tag,
                    isActive: false,
                    isArchived: true,
                },
            },
        };
        const data = new FormData();
        data.append("dataLogo", JSON.stringify(dataLogo));
        data.append("image", "");
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/logo`;

        const requestOptions = {
            method: "POST",

            body: data,
        };
        mutate(
            apiUrl,
            await fetch(apiUrl, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }
                    toast.success(`${t("The logo is successfully archived")}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    });
                    setDataIsUpdated!(true);
                    setNumberActiveMultiShop(numberActiveMultiShop - 1);
                    setNumberArchiveMultiShop(numberArchiveMultiShop + 1);
                    setIsClicked(true);
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }

    return (
        <React.Fragment>
            {selectedRowData.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    {modify === true ? (
                        <>
                            <Tooltip
                                title={
                                    isLogoArchived
                                        ? ""
                                        : choiceState === false
                                        ? t("Activate")
                                        : t("Unactivate")
                                }
                            >
                                <div>
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={choiceState}
                                        onChange={async (e: boolean) => {
                                            setLogoState(!choiceState);

                                            handleStateChange(e);
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                        className={`${classnames(
                                            "pointer__clz ml-2 cmn_icn_BTenableDisable",
                                            {
                                                "not-allowed-icon__clz": isLogoArchived,
                                            }
                                        )}`}
                                    />
                                </div>
                            </Tooltip>

                            {!isLogoArchived ? (
                                <Tooltip title={t("Archive")}>
                                    <div>
                                        <TrashIcon
                                            onClick={() => {
                                                if (
                                                    shopsSelectedRow.length > 1
                                                ) {
                                                    ErrorToast(
                                                        `${t(
                                                            "Attention, it is a single action. Please select one item"
                                                        )!}`
                                                    );
                                                } else {
                                                    handleArchiveLogo(
                                                        selectedRowData?.selectedRow
                                                    );
                                                }
                                            }}
                                            height={25}
                                            width={25}
                                            fill="red"
                                            style={{ cursor: "pointer" }}
                                            className={`${classnames(
                                                "pointer__clz ml-2 cmn_icn_BTarchived",
                                                {
                                                    "not-allowed-icon__clz": isClicked,
                                                }
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={
                                        dataArchive.includes(
                                            selectedRowData.selectedRow.Id
                                        )
                                            ? ""
                                            : t("Unarchive")
                                    }
                                >
                                    <div>
                                        <EyeIcon
                                            height={25}
                                            width={25}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                handleActiveLogo(
                                                    selectedRowData
                                                );
                                            }}
                                            className={`${classnames(
                                                "pointer__clz ml-2 cmn_icn_BTunarchive",
                                                {
                                                    "not-allowed-icon__clz": dataArchive.includes(
                                                        selectedRowData
                                                            .selectedRow.Id
                                                    ),
                                                }
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : null}
                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                className="pointer__clz ml-2 cmn_icn_BThistory"
                                onClick={() => {
                                    setIsHistoryShopsModalOpened(
                                        !isHistoryShopsModalOpened
                                    );

                                    setSelectedLogo([
                                        {
                                            [selectedRowData.selectedRow
                                                .shopId]:
                                                selectedRowData.selectedRow.Id,
                                        },
                                    ]);
                                }}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
        </React.Fragment>
    );
}
