import React from "react";
import { useSnapshot } from "valtio";

import { designStore, generalConfigStore } from "@store";

import { getImageContentById } from "@helpers/general";

export function ActionsButtonsWrapper(): JSX.Element {
    const {
        project: { template, files: images },
    } = useSnapshot(generalConfigStore);
    const { selectedLanguage } = useSnapshot(designStore);

    const actionsButtonsOrderTakingItems = (template.pages.ways[
        "orderTaking"
    ] as OrderTakingPageType).actionsButtons.items.filter(
        (actionButton) =>
            actionButton.name === "Pay" ||
            actionButton.name === "Continue order"
    );

    const activeActionsButtons = actionsButtonsOrderTakingItems.filter(
        (item) => {
            return item.active === true;
        }
    );

    const mainActiveActionsButtons = activeActionsButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    return (
        <React.Fragment>
            {mainActiveActionsButtons.length > 1 ? (
                <div
                    className="d-flex flex-row justify-content-between align-items-center"
                    style={{ gap: "10px" }}
                >
                    {mainActiveActionsButtons.map((item, index) => {
                        const imageContent = getImageContentById(
                            item.id,
                            images,
                            selectedLanguage
                        );

                        const imageSrc =
                            imageContent?.includes("http") ||
                            imageContent?.includes("https")
                                ? imageContent
                                : `./images/${imageContent}`;

                        return (
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <img
                                    alt={item.name}
                                    src={imageSrc}
                                    style={{
                                        width: "200px",
                                        height: "25px",
                                        position: "relative",
                                        zIndex: 1,
                                    }}
                                />
                                {index === 1 ? (
                                    <div
                                        style={{
                                            width: "100px",
                                            zIndex: 100,
                                            position: "absolute",
                                            color: "white",
                                            fontSize: "13px",
                                            fontWeight: "bold",
                                            padding: "10px",
                                            left: "1722px",
                                        }}
                                    >
                                        125.00 €
                                    </div>
                                ) : null}
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}
                >
                    <img
                        alt={mainActiveActionsButtons[0].name}
                        src={
                            getImageContentById(
                                mainActiveActionsButtons[0].id,
                                images,
                                selectedLanguage
                            )?.includes("http") ||   getImageContentById(
                                mainActiveActionsButtons[0].id,
                                images,
                                selectedLanguage
                            )?.includes("https")
                                ? getImageContentById(
                                      mainActiveActionsButtons[0].id,
                                      images,
                                      selectedLanguage
                                  )
                                : `./images/${getImageContentById(
                                      mainActiveActionsButtons[0].id,
                                      images,
                                      selectedLanguage
                                  )}`
                        }
                        style={{
                            width: "200px",
                            height: "25px",
                            position: "relative",
                            zIndex: 1,
                        }}
                    />
                    <div
                        style={{
                            width: "100px",
                            zIndex: 100,
                            position: "absolute",
                            color: "white",
                            fontSize: "13px",
                            fontWeight: "bold",
                            padding: "10px",
                            left: "1617px",
                        }}
                    >
                        125.00 €
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}
