import React from "react";
import { StyledLabel, StyledSelectInput } from "@aureskonnect/react-ui";
import classnames from "classnames";
import { AvGroup, AvField } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";

type NeptingConfigType = {
    port: string;
    isConsult: boolean;
    setPort: Function;
    setIsEdited: Function;
    InputIp: boolean;
    ipExist: boolean;
    setIpAddress: Function;
    ipMatchWord: any;
    setInputIp: Function;
    ipAddress: string;
    InputPort: boolean;
    setInputPort: Function;
    numberMatchWord: any;
    InputCode: boolean;
    setMerchantCode: Function;
    setInputCode: Function;
    merchantCode: string;
    paymentType: string;
    SelectInput: boolean;
    paramsTypes: any;
    setSelectInput: Function;
    setPaymentType: Function;
    InputUrl: boolean;
    setUrl: Function;
    setInputUrl: Function;
    url: string;
    inputSetupType: boolean;
    typeConfig: string;
    setTypeConfig: Function;
    typeOfConfig: any;
    setInputSetupType: Function;
    inputTerminalType: boolean;
    typeTerminal: string;
    typeOfTerminal: any;
    setTypeTerminal: Function;
    setInputTerminalType: Function;
};
export function NeptingConfig({
    port,
    isConsult,
    setPort,
    setIsEdited,
    InputIp,
    ipExist,
    setIpAddress,
    ipMatchWord,
    setInputIp,
    ipAddress,
    InputPort,
    setInputPort,
    numberMatchWord,
    InputCode,
    setMerchantCode,
    setInputCode,
    merchantCode,
    paymentType,
    SelectInput,
    paramsTypes,
    setSelectInput,
    setPaymentType,
    InputUrl,
    setUrl,
    setInputUrl,
    url,
    inputSetupType,
    typeConfig,
    setTypeConfig,
    typeOfConfig,
    setInputSetupType,
    inputTerminalType,
    typeTerminal,
    typeOfTerminal,
    setTypeTerminal,
    setInputTerminalType,
}: NeptingConfigType) {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <AvGroup>
                <StyledLabel className="mt-3 required__clz" htmlFor="text">
                    {t("IP address")}
                </StyledLabel>
                <AvField
                    className={classnames("mnt_inp_ipAdress", {
                        input__clz: InputIp || ipExist,
                        readOnly__clz: isConsult,
                    })}
                    autocomplete="off"
                    id="ipAddress"
                    name="ipAddress"
                    placeholder={t("Enter")}
                    type="text"
                    onChange={(e: any) => {
                        setIpAddress(e.target.value);
                        if (
                            !e.target.value.match(ipMatchWord) &&
                            e.target.value !== ""
                        ) {
                            setInputIp(true);
                        } else {
                            setInputIp(false);
                        }
                        setIsEdited(true);
                    }}
                    value={ipAddress}
                />
                {InputIp || ipExist ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {
                            t(
                                ipAddress === ""
                                    ? "Please enter a IP address"
                                    : !ipAddress.match(ipMatchWord)
                                    ? "IP address is invalid"
                                    : "The ip adress is already exists"
                            )!
                        }
                    </div>
                ) : null}
            </AvGroup>
            <AvGroup>
                <StyledLabel className="mt-3 required__clz" htmlFor="text">
                    {t("Port number")}
                </StyledLabel>
                <AvField
                    className={classnames("mnt_inp_portNumber", {
                        input__clz: InputPort,
                        readOnly__clz: isConsult,
                    })}
                    autocomplete="off"
                    id="port"
                    name="port"
                    placeholder={t("Enter")}
                    type="text"
                    onChange={(e: any) => {
                        setPort(e.target.value);
                        setInputPort(false);
                        setIsEdited(true);
                    }}
                    value={port}
                    validate={{
                        pattern: {
                            value: numberMatchWord,
                            errorMessage: t("Port number is invalid"),
                        },
                    }}
                />
                {InputPort && (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please enter a port number")}
                    </div>
                )}
            </AvGroup>
            <AvGroup>
                <StyledLabel className="mt-3 required__clz" htmlFor="text">
                    {t("Merchant code")}
                </StyledLabel>
                <AvField
                    className={classnames("mnt_inp_workingCode", {
                        input__clz: InputCode,
                        readOnly__clz: isConsult,
                    })}
                    autocomplete="off"
                    id="merchantCode"
                    name="merchantCode"
                    placeholder={t("Enter")}
                    type="text"
                    onChange={(e: any) => {
                        setMerchantCode(e.target.value);
                        setInputCode(false);
                        setIsEdited(true);
                    }}
                    value={merchantCode}
                />
                {InputCode && (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please enter a merchant code")}
                    </div>
                )}
            </AvGroup>
            <AvGroup>
                <StyledLabel className="mt-3 required__clz" htmlFor="text">
                    {t("Mode of payment")}
                </StyledLabel>

                <StyledSelectInput
                    value={
                        paymentType === ""
                            ? null
                            : {
                                  label: paymentType,
                                  value: paymentType,
                              }
                    }
                    className={classnames(
                        "mnt_inp_typeOfPayment",

                        {
                            invalid__clz: SelectInput,
                            readOnly__clz: isConsult,
                        }
                    )}
                    name="paymentType"
                    options={paramsTypes}
                    onChange={(e: any) => {
                        setSelectInput(false);
                        setPaymentType(e.value);
                        setIsEdited(true);
                    }}
                    placeholder={t("Select")}
                    noOptionsMessage={() => t("No options")}
                />

                {SelectInput && (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please select a mode of payement")}
                    </div>
                )}
            </AvGroup>
            <AvGroup>
                <StyledLabel className="mt-3 required__clz" htmlFor="text">
                    {t("URL")}
                </StyledLabel>
                <AvField
                    className={classnames("mnt_inp_url", {
                        input__clz: InputUrl,
                        readOnly__clz: isConsult,
                    })}
                    autocomplete="off"
                    id="url"
                    name="url"
                    placeholder={t("Enter")}
                    type="text"
                    onChange={(e: any) => {
                        setUrl(e.target.value);
                        setInputUrl(false);
                        setIsEdited(true);
                    }}
                    value={url}
                />
                {InputUrl && (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please enter a URL")}
                    </div>
                )}
            </AvGroup>
            <AvGroup>
                <StyledLabel className="mt-3 required__clz" htmlFor="text">
                    {t("Setup Type")}
                </StyledLabel>
                <StyledSelectInput
                    className={classnames("mnt_drp_setupType", {
                        invalid__clz: inputSetupType,
                        readOnly__clz: isConsult,
                    })}
                    value={
                        Object.keys(typeConfig).length > 0 ? typeConfig : null
                    }
                    id="Method_of_payment"
                    name="Method_of_payment"
                    options={typeOfConfig}
                    onChange={(e: any) => {
                        if (e !== null) {
                            setTypeConfig(e);
                            setInputSetupType(false);
                        } else {
                            setInputSetupType(true);
                        }
                        setIsEdited(true);
                    }}
                    placeholder={t("Select")}
                    noOptionsMessage={() => t("No options")}
                    maxMenuHeight="80px"
                />
                {inputSetupType && (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please select a setup type")}
                    </div>
                )}
            </AvGroup>
            <AvGroup>
                <StyledLabel className="mt-3 required__clz" htmlFor="text">
                    {t("Terminal Type")}
                </StyledLabel>

                <StyledSelectInput
                    className={classnames("mnt_drp_terminalType", {
                        invalid__clz: inputTerminalType,
                        readOnly__clz: isConsult,
                    })}
                    value={
                        Object.keys(typeTerminal).length > 0
                            ? typeTerminal
                            : null
                    }
                    id="typeTerminal"
                    name="typeTerminal"
                    options={typeOfTerminal}
                    onChange={(e: any) => {
                        if (e !== null) {
                            setTypeTerminal(e);
                            setInputTerminalType(false);
                        } else {
                            setInputTerminalType(true);
                        }
                        setIsEdited(true);
                    }}
                    placeholder={t("Select")}
                    noOptionsMessage={() => t("No options")}
                    maxMenuHeight="80px"
                />
                {inputTerminalType && (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please select a terminal type")}
                    </div>
                )}
            </AvGroup>
        </React.Fragment>
    );
}
