import React from "react";
import classnames from "classnames";
import { VerticalDotsIcon } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import {
    store as hoursStore,
    setIsAssociateModalOpened,
    setIsHistoryShopsModalOpened,
} from "../../store";

import ErrorToast from "../../../../../components/Common/ErrorTost";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

export function CustomSideFilter({
    selectedRows,
    isModalOpened,
    setIsModalOpened,
    setLocalFilterActive,
    setDaysDesignations,
    setIsAllHoursEqual,
    setShopsHoursData,
    setDaysData,
}: any) {
    const { t } = useTranslation();
    const { isHistoryShopsModalOpened, localShop, modify } = useSnapshot(
        hoursStore
    );
    const getArrayOfRows = () => {
        let arrayOfRows: any[] = [];
        if (selectedRows.length > 0) {
            selectedRows.forEach(async (el: any) => {
                if (el[t("Shop")] === "" && el[t("Day of week")] !== "") {
                    arrayOfRows.push(el);
                }
            });
        }
        return arrayOfRows;
    };

    const getIsArrayOfRowsShop = () => {
        let isArrayShop: boolean = false;
        if (selectedRows.length > 0) {
            selectedRows.forEach(async (el: any) => {
                if (Object.values(el).includes(el.Id)) {
                    isArrayShop = true;
                }
            });
        }
        return isArrayShop;
    };

    const getArrayOfSelectedRows = () => {
        let arrayOfRows: any[] = [];
        selectedRows.forEach(async (el: any) => {
            if (el[t("Day of week")] !== "" && el[t("Shop")] === "")
                arrayOfRows.push(el);
        });

        if (arrayOfRows.length > 0) {
            let days: any = [];
            arrayOfRows.forEach((elt: any) => {
                days.push(t(elt[t("Day of week")]).slice(0, 3));
            });
            setDaysDesignations(days);
            let firstElement = arrayOfRows[0].subRows;
            let firstStateDay = arrayOfRows[0][t("State")];
            let isSimilar = [];
            for (let i = 1; i < arrayOfRows.length; i++) {
                isSimilar.push(
                    arrayOfRows[i].subRows.length === firstElement.length &&
                        firstStateDay === arrayOfRows[i][t("State")] &&
                        arrayOfRows[i].subRows.every(
                            (value: any, key: number) =>
                                value[t("Opening time")] ===
                                    firstElement[key][t("Opening time")] &&
                                value[t("Closing time")] ===
                                    firstElement[key][t("Closing time")] &&
                                value[t("Status")] ===
                                    firstElement[key][t("Status")]
                        )
                );
            }
            const isSameElts = isSimilar.every((elt: any) => elt === true);
            setIsAllHoursEqual(isSimilar.every((elt: any) => elt === true));
            let settingDayRows: any = [];
            arrayOfRows[0].subRows.forEach((elt: any) => {
                settingDayRows.push({
                    openingHour: elt[t("Opening time")],
                    closingHour: elt[t("Closing time")],
                    stateHour: elt[t("Status")],
                    isArchived: false,
                    overLappedStart: false,
                    overLappedEnd: false,
                    inputStartTime: false,
                    inputEndTime: false,
                    hoursNumber: elt[t("Total hours")],
                });
            });
            setDaysData({
                Id: "0",
                [t("Shop")]: "",
                [t("State")]: isSameElts ? arrayOfRows[0][t("State")] : false,
                [t("Day of week")]: arrayOfRows[0][t("Day of week")],
                [t("Opening time")]: "",
                [t("Closing time")]: "",
                [t("Status")]: "",
                [t("Total hours")]: arrayOfRows[0][t("Total hours")],
                shopId: "",
                subRows:
                    isSameElts && arrayOfRows[0].subRows.length > 0
                        ? settingDayRows
                        : [
                              {
                                  openingHour: "",
                                  closingHour: "",
                                  stateHour: true,
                                  isArchived: false,
                                  overLappedStart: false,
                                  overLappedEnd: false,
                                  inputStartTime: false,
                                  inputEndTime: false,
                                  hoursNumber: 0,
                              },
                          ],
            });
        }
        setShopsHoursData(arrayOfRows);
        return arrayOfRows;
    };

    return (
        <div className="d-flex justify-content-start align-items-center ml-2">
            {modify === true ? (
                <>
                    <CustomMainColorButton
                        className={`${classnames(
                            " w-100 ml-2 hov_btn_BTassignSchedule",
                            {
                                "not-allowed-icon__clz": localShop.length === 0,
                            },
                            "cmn_btn_BTassign"
                        )}`}
                        rounded
                        disabled={false}
                        variant="primary"
                        onClick={() => {
                            setLocalFilterActive(false);
                            setIsAssociateModalOpened(true);
                        }}
                    >
                        {t("Assign schedule")}
                    </CustomMainColorButton>
                </>
            ) : null}
            <VerticalDotsIcon
                className="hov_drp_BTmenu"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={25}
                fill="black"
            />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames(
                        "dropdown-item pl-1 hov_btn_BThistory",
                        {
                            "not-allowed-icon__clz":
                                selectedRows.length === 0 ||
                                getArrayOfRows().length === 0 ||
                                !getIsArrayOfRowsShop() ||
                                getArrayOfRows().length === 1,
                        }
                    )}
                    onClick={() => {
                        if (getArrayOfSelectedRows().length < 2) {
                            ErrorToast(
                                `${t(
                                    "Attention, it is a multiple action. Please select at least two items"
                                )!}`
                            );
                        } else {
                            setIsHistoryShopsModalOpened(
                                !isHistoryShopsModalOpened
                            );
                        }
                    }}
                >
                    {t("Historical")}
                </div>
                {modify === true ? (
                    <>
                        <div
                            style={{ cursor: "pointer" }}
                            className={classnames(
                                "dropdown-item pl-1",
                                {
                                    "not-allowed-icon__clz":
                                        selectedRows.length === 0 ||
                                        getArrayOfRows().length === 0 ||
                                        !getIsArrayOfRowsShop() ||
                                        getArrayOfRows().length === 1,
                                },
                                "hov_btn_BTmultipleTimeSetting"
                            )}
                            onClick={() => {
                                if (getArrayOfSelectedRows().length < 2) {
                                    ErrorToast(
                                        `${t(
                                            "Attention, it is a multiple action. Please select at least two items"
                                        )!}`
                                    );
                                } else {
                                    setIsModalOpened(!isModalOpened);
                                }
                            }}
                        >
                            {t("Multiple time configuration")}
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    );
}
