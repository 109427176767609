import React from "react";

import { OptionItem } from "@components/OptionItem";

import { OptionsMatrixPropsType } from "./OptionsNumberBetweenFourAndSix";

export function OptionsNumberBetweenSixAndEight({
    options,
    setOptions,
}: OptionsMatrixPropsType): JSX.Element {
    return (
        <div
            className="d-flex flex-column justify-content-center"
            style={{ rowGap: "50px" }}
        >
            <div
                className="d-flex justify-content-center"
                style={{ columnGap: "50px" }}
            >
                {options
                    .slice(0, 4)
                    .map((option: OptionsItemType, index: number) => (
                        <OptionItem
                            key={index}
                            option={option}
                            index={index}
                            setOptions={setOptions}
                        />
                    ))}
            </div>
            <div
                className="d-flex justify-content-center"
                style={{ columnGap: "50px" }}
            >
                {options
                    .slice(4)
                    .map((option: OptionsItemType, index: number) => (
                        <OptionItem
                            key={index + 4}
                            option={option}
                            index={index + 4}
                            setOptions={setOptions}
                        />
                    ))}
            </div>
        </div>
    );
}
