import React from "react";
import styled from "styled-components";
import { useSnapshot } from "valtio";

import { store } from "@store";
interface MainColorTabsWrapperProps {
    mainColor: string;
}

const BorderWrapper = styled.div<MainColorTabsWrapperProps>`
    .nav-item-active__clz .active-tab__clz {
        border-bottom: 3px solid ${(props) => props.mainColor} !important;
    }
`;

export function MainColorTabsWrapper({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element {
    const { mainColor } = useSnapshot(store);

    return <BorderWrapper mainColor={mainColor}>{children}</BorderWrapper>;
}
