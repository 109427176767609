import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";

import { store } from "../../../../components/VerticalLayout/store";
import {
    store as saleSupportStore,
    setLocalShop,
    setShopsSaleSupportData,
    setShopsSelectedRow,
    setNumberArchiveMultiShop,
    setNumberActiveMultiShop,
    setAssociatedSaleSupport,
    setShopDataIsUpdated,
    setIsHistoryShopsModalOpened,
    setSelectedSaleSupports,
} from "../store";

import { CustomSideFilter } from "./CustomSideFilter";
import { CustomIcons } from "./CustomIcons";
import { SaleSupportAssociateModal } from "./SaleSupportAssociateModal";
import HistoryModal from "../HistoryModal";
import SalesSupportRow from "./SalesSupportRow";

import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";

import "./index.css";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import { removeArrayOfObjectsDuplicates } from "@helpers/general";

type saleSupportType = {
    isArchivedSaleSupportClicked: boolean;
    setIsArchivedSaleSupportClicked: Function;
    setLimit: Function;
};
export default function SaleSupportShops({
    isArchivedSaleSupportClicked,
    setIsArchivedSaleSupportClicked,
    setLimit,
}: saleSupportType) {
    const { oneShop, userID, franchiseID } = useSnapshot(store);
    const { shopDataIsUpdated, isHistoryShopsModalOpened } = useSnapshot(
        saleSupportStore
    );
    const { t } = useTranslation();
    const [
        isAddNewModeOfSaleButtonClicked,
        setIsAddNewModeOfSaleButtonClicked,
    ] = useState(false);
    const [data, setData] = React.useState<any>({});
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [isShopSelected, setIsShopSelected] = React.useState<boolean>(false);
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [selectedShopsRows, setSelectedShopsRows] = React.useState<any[]>([]);
    const [isAssociateModalOpened, setIsAssociateModalOpened] = React.useState<
        boolean
    >(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const urlArchive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport?userId=${userID}&franchiseId=${franchiseID}&type=archived`;
    const urlActive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport?userId=${userID}&franchiseId=${franchiseID}&type=activated`;

    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: oneShop ? 6 : 7,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    setLimit={setLimit}
                    selectedRowData={e}
                    isArchivedSaleSupportClicked={isArchivedSaleSupportClicked}
                />
            ),
            disableFilter: false,
        },
        {
            indexOFColumn: 3,
            columnName: t("Sale support"),
            customJsx: (e: any) => <SalesSupportRow element={e} />,
            filterName: t("Sale support"),
            canFilter: true,
        },
    ];

    React.useEffect(() => {
        let array: any = [];
        let localShop: any = [];
        selectedRows.forEach((el: any) => {
            if (el[t("Shop")] !== "" && el[t("Shop")] !== undefined)
                localShop.push({
                    id: el.shopId,
                    label: el[t("Shop")],
                });
        });
        setLocalShop(localShop);
        let associatedSaleSupport: any[] = [];
        selectedRows.forEach((element: any) => {
            if (element[t("Shop")] !== undefined) {
                element.subRows.forEach((el: any) => {
                    array.push({ [el.shopId]: el.Id });
                    associatedSaleSupport.push(el);
                });
            } else {
                array.push({ [element.shopId]: element.Id });
            }
        });
        setAssociatedSaleSupport(associatedSaleSupport);
        setShopsSelectedRow(selectedRows);
        setSelectedSaleSupports(removeArrayOfObjectsDuplicates(array));
    }, [selectedRows, t]);

    React.useEffect(() => {
        let localShop: string[] = [];
        selectedRows.forEach((el: any) => {
            if (el[t("Shop")] !== "" && el[t("Shop")] !== undefined)
                localShop.push(el[t("Shop")]);
        });

        setIsShopSelected(localShop.length >= 1);

        let localArchivedSaleSupport: number = 0;
        let localActiveSaleSupport: number = 0;

        if (selectedRows.length > 0) {
            selectedRows.forEach((element: any) => {
                if (element[t("Shop")] !== undefined) {
                    localActiveSaleSupport +=
                        element.saleSupportNumber.activated;
                    localArchivedSaleSupport +=
                        element.saleSupportNumber.archived;
                }
            });
            if (selectedRows.filter((el: any) => el[t("Shop")]).length > 0) {
                setNumberActiveMultiShop(localActiveSaleSupport);
                setNumberArchiveMultiShop(localArchivedSaleSupport);
            } else {
                setNumberActiveMultiShop(
                    data.othersData !== undefined
                        ? data.othersData.activated
                        : 0
                );
                setNumberArchiveMultiShop(
                    data.othersData !== undefined ? data.othersData.archived : 0
                );
            }
        } else {
            setNumberActiveMultiShop(
                data.othersData !== undefined ? data.othersData.activated : 0
            );
            setNumberArchiveMultiShop(
                data.othersData !== undefined ? data.othersData.archived : 0
            );
        }

        setShopsSaleSupportData(data.data);
    }, [selectedRows, data, t]);

    return (
        <React.Fragment>
            <div
                style={{
                    display: "grid",
                    minHeight: oneShop ? "650px" : "600px",
                    gridTemplateColumns: "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    url={isArchivedSaleSupportClicked ? urlArchive : urlActive}
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    canExpand
                    canSelect
                    setData={setData}
                    customSelect
                    setSelectedRows={setSelectedRows}
                    setLocalFilterActive={setLocalFilterActive}
                    canMovedCheckboxLeftOnExpand
                    customJsxSideFilterButton={
                        <CustomSideFilter
                            setLimit={setLimit}
                            isShopSelected={isShopSelected}
                            selectedRows={selectedRows}
                            isModalOpened={isModalOpened}
                            setIsModalOpened={setIsModalOpened}
                            setIsAssociateModalOpened={
                                setIsAssociateModalOpened
                            }
                            isAssociateModalOpened={isAssociateModalOpened}
                            setLocalFilterActive={setLocalFilterActive}
                            isArchivedSaleSupportClicked={
                                isArchivedSaleSupportClicked
                            }
                            setIsArchivedSaleSupportClicked={
                                setIsArchivedSaleSupportClicked
                            }
                            setSelectedShopsRows={setSelectedShopsRows}
                        />
                    }
                    canResize
                    showGlobalFilter
                    showFilter
                    filterActive={filterActive}
                    actionColumn={ActionColumn}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={
                        setShopDataIsUpdated as
                            | React.Dispatch<
                                  React.SetStateAction<number | boolean>
                              >
                            | undefined
                    }
                    dataIsUpdated={shopDataIsUpdated}
                    elevationTable={1}
                    minHeight={oneShop ? "500px" : "450px"}
                    maxHeight={oneShop ? "500px" : "450px"}
                    name="saleSupport"
                />
                {isAssociateModalOpened ? (
                    <SaleSupportAssociateModal
                        setIsAssociateModalOpened={setIsAssociateModalOpened}
                        isAssociateModalOpened={isAssociateModalOpened}
                        selectedShopsRows={selectedShopsRows}
                    />
                ) : null}
            </div>

            {isInInitializationModalOpened === true ? (
                <ConfirmationInitializationMessage
                    isModalOpened={isInInitializationModalOpened}
                    setIsModalOpened={setIsInInitializationModalOpened}
                    setIsClicked={setIsAddNewModeOfSaleButtonClicked}
                    isClicked={isAddNewModeOfSaleButtonClicked}
                />
            ) : null}
            {isHistoryShopsModalOpened ? (
                <HistoryModal
                    ids={selectedRows}
                    setIsModalOpened={setIsHistoryShopsModalOpened}
                    isModalOpened={isHistoryShopsModalOpened}
                />
            ) : null}
        </React.Fragment>
    );
}
