import { proxy } from "valtio";

type StoreType = {
    localShopIdList: any[];
    isUpdateOperation: boolean;
    isMultiDeletePeripheralModalOpened: boolean;
    selectedMonnyeur: any[];
    isActionsClicked: boolean;
    isEdited: boolean;
    actions: Function;
    validationAction: any;
    modify: boolean;
};

export const store = proxy<StoreType>({
    localShopIdList: [],
    isUpdateOperation: false,
    isMultiDeletePeripheralModalOpened: false,
    selectedMonnyeur: [],
    isActionsClicked: false,
    isEdited: false,
    actions: () => {},
    validationAction: {},
    modify: false,
});
export function setModify(value: boolean): void {
    store.modify = value;
}
export function setIsUpdateOperation(x: boolean): void {
    store.isUpdateOperation = x;
}
export function setIsMultiDeletePeripheralModalOpened(
    isMultiDeletePeripheralModalOpened: boolean
): void {
    store.isMultiDeletePeripheralModalOpened = isMultiDeletePeripheralModalOpened;
}

export function setLocalShopIdList(localShopId: any[]): void {
    store.localShopIdList = localShopId;
}
export function setSelectedMonnyeur(selectedMonnyeur: any[]): any {
    store.selectedMonnyeur = selectedMonnyeur;
}

export function setIsEdited(isEdited: boolean): any {
    store.isEdited = isEdited;
}

export function setIsActionsClicked(isActionsClicked: boolean): any {
    store.isActionsClicked = isActionsClicked;
}

export function setValidationAction(validationAction: any): any {
    store.validationAction = validationAction;
}

export function setActions(actions: any): any {
    store.actions = actions;
}

export default store;
