import React from "react";
import { Card, CardBody } from "reactstrap";
import { useSnapshot } from "valtio";

import NoDataSvgIcon from "@components/Common/SvgIcons/NoDataSvgIcon";

import FourthImageModel from "../../../image/FourthImageModel.png";
import { store } from "../store";
import { resetStore } from "./store";

import { PrinterFooter } from "../PrinterFooter";

import "./index.css";

type FourthModelType = {
    rowData: any;
    oneShop: boolean;
    setCheckboxIconFourthModel: Function;
};

export default function FourthModel({
    rowData,
    setCheckboxIconFourthModel,
}: FourthModelType) {
    const { checkboxIconFourthModel } = useSnapshot(store);

    return (
        <React.Fragment>
            <div className="card_modale__clz">
                <div
                    className="card card-model-second__clz"
                    style={{ fontSize: "17px" }}
                >
                    <CardBody className="d-flex align-items-center justify-content-center">
                        <NoDataSvgIcon />
                    </CardBody>
                </div>
                <div className="card-model-first__clz d-flex align-items-center justify-content-center">
                    <div>
                        <input
                            className="check-style__clz"
                            style={{
                                marginBottom: "530px",
                                marginRight: "122px",
                            }}
                            type="checkbox"
                            checked={checkboxIconFourthModel}
                            onChange={() => {
                                setCheckboxIconFourthModel(
                                    !checkboxIconFourthModel
                                );
                            }}
                        />
                    </div>
                    <Card>
                        <img
                            src={FourthImageModel}
                            alt="Logo"
                            className="style-logo__clz"
                        />
                    </Card>
                </div>
            </div>
            <PrinterFooter
                rowData={rowData}
                resetStore={resetStore}
            />
        </React.Fragment>
    );
}
