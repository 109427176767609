import React from "react";
import { useSnapshot } from "valtio";
import { ListManager } from "react-beautiful-dnd-grid";
import { uid } from "react-uid";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { t } from "i18next";

import {
    setIsInformationModesSkipped,
    generalConfigStore,
    setConfigInformationModes,
    setProjectFiles,
    setImageKey,
} from "@store";
import kioskSettingStore from "../store";

import { SalesMethodsSelectWrapper } from "@components/Common/SalesMethodsSelectWrapper";
import { InformationModesCardWrapper } from "@components/Common/InformationModesCardWrapper";
import { FlexboxGrid } from "@components/Common/FlexboxGrid";
import { ResetModalWrapper } from "@components/Common/ResetModalWrapper";
import { SwitchWrapper } from "@components/Common/SwitchWrapper";
import { ScrollContainer } from "@components/ScrollContainer";
import { CustomErrorBoundary } from "@components/CustomErrorBoundary";
import { InformationModeCard } from "@components/Common/CardWrapperSpecific/InformationModeCard";

export function InformationModes(): JSX.Element {
    const {
        project: { template, files: images },
        defaultLanguage,
        selectedSaleModeValue,
        isResetModalOpened,
        templates,
        initItemsFromGeneralSetting,
    } = useSnapshot(generalConfigStore);
    const { isKioskSetting } = useSnapshot(kioskSettingStore);

    const [items, setItems] = React.useState<ProjectMainContentItemType[]>([]);
    const [
        isSkipThisStepSwitchChecked,
        setIsSkipThisStepSwitchChecked,
    ] = React.useState<boolean>(false);

    React.useState<boolean>(false);
    const [
        localIsResetModalOpened,
        setLocalIsResetModalOpened,
    ] = React.useState<boolean>(isResetModalOpened);

    function handleSkipThisStepOnClickEvent() {
        setIsSkipThisStepSwitchChecked(!isSkipThisStepSwitchChecked);
        setIsInformationModesSkipped(
            !isSkipThisStepSwitchChecked,
            selectedSaleModeValue
        );
    }

    function handleOnDragEndEvent(
        sourceIndex: number,
        destinationIndex: number
    ): void {
        if (destinationIndex === sourceIndex) {
            return;
        }

        const newItems = [...items];
        const [reorderedItem] = newItems.splice(sourceIndex, 1);
        newItems.splice(destinationIndex, 0, reorderedItem);
        setItems(newItems);
        setConfigInformationModes(newItems);
    }

    async function handleResetOnClickEvent(): Promise<void> {
        const initInformationModes = initItemsFromGeneralSetting.informationModes as ProjectMainContentItemType[];

        const initSkipStep = (templates.filter(
            (element) => element.id === template.id
        )[0].pages.elements["informationModes"] as PageType)
            ?.skipped as ItemContentType;

        setConfigInformationModes(initInformationModes);
        setItems(
            (template.content
                .informationModes as ProjectContentItemType).items.filter(
                (item: ProjectMainContentItemType) => {
                    return (template.content
                        .salesMethods as ProjectContentItemType).items
                        .filter((saleMethod) => {
                            return saleMethod.name === selectedSaleModeValue;
                        })[0]
                        .informationModes?.items?.includes(item.id);
                }
            )
        );
        (templates.filter((element) => element.id === template.id)[0].content
            .salesMethods as ProjectContentItemType).items.forEach((item) => {
            setIsSkipThisStepSwitchChecked(initSkipStep[item.name]);
            setIsInformationModesSkipped(initSkipStep[item.name], item.name);
        });

        const imagesArray = [...images];
        initInformationModes
            .map((item) => item.id)
            .forEach((id) => {
                const image = imagesArray.find((element) => element.id === id);

                if (image !== undefined) {
                    const imageIndex = imagesArray.indexOf(image);

                    const localContent = image.content as ImageItemLanguagesType;

                    imagesArray[imageIndex] = {
                        ...image,
                        content: {
                            ...localContent,
                            [defaultLanguage.name]: {
                                ...localContent[defaultLanguage.name],
                                path:
                                    localContent[defaultLanguage.name]
                                        .defaultImage,
                            },
                        } as ImageItemLanguagesType,
                    };
                }
            });

        setProjectFiles(imagesArray);
        setImageKey(uid(imagesArray));
    }

    React.useEffect(() => {
        if (selectedSaleModeValue !== "") {
            setItems(
                (template.content
                    .informationModes as ProjectContentItemType).items.filter(
                    (item: ProjectMainContentItemType) => {
                        return (template.content
                            .salesMethods as ProjectContentItemType).items
                            .filter((saleMethod) => {
                                return (
                                    saleMethod.name === selectedSaleModeValue
                                );
                            })[0]
                            .informationModes?.items?.includes(item.id);
                    }
                )
            );
            if (
                (template.pages.ways["informationModes"] as PageType) !==
                undefined
            ) {
                setIsSkipThisStepSwitchChecked(
                    ((template.pages.ways["informationModes"] as PageType)
                        ?.skipped as ItemContentType)[selectedSaleModeValue]
                );
            } else {
                setIsSkipThisStepSwitchChecked(
                    ((template.pages.elements["informationModes"] as PageType)
                        ?.skipped as ItemContentType)[selectedSaleModeValue]
                );
            }
        }
    }, [
        selectedSaleModeValue,
        template.content.informationModes,
        template.content.salesMethods,
        template.pages.elements,
        template.pages.ways,
    ]);

    React.useEffect(() => {
        if (
            items.filter(
                (item: any) => item.active[selectedSaleModeValue] === true
            ).length > 1
        ) {
            setIsSkipThisStepSwitchChecked(false);
            setIsInformationModesSkipped(false, selectedSaleModeValue);
        }
    }, [items, selectedSaleModeValue]);
    return (
        <CustomErrorBoundary>
            <div
                className="d-grid__clz h-100"
                style={{
                    gridTemplateRows: "0.2fr 0.8fr",

                    gap: isKioskSetting && items.length < 4 ? "138px" : "5px",
                }}
            >
                <div>
                    <FlexboxGrid
                        alignItemsCentered={true}
                        className="cursor__clz"
                        gap="5px"
                    >
                        <div
                            style={{
                                font: "normal normal 600 35px/35px Nunito Sans",

                                marginBottom: isKioskSetting ? "20px" : "0px",
                            }}
                        >
                            {t("Choice of information modes")}
                        </div>
                        <div
                            style={{
                                font: "normal normal 600 18px/27px Nunito Sans",
                                marginBottom: isKioskSetting ? "20px" : "0px",
                            }}
                        >
                            ({t("Default language")}: {defaultLanguage.title})
                        </div>
                    </FlexboxGrid>

                    {!isKioskSetting ? (
                        <FlexboxGrid
                            alignItemsCentered={true}
                            className="justify-content-between"
                        >
                            <span
                                style={{
                                    font:
                                        "normal normal 300 18px/27px Nunito Sans",
                                    color: "#A0A0A0",
                                }}
                            >
                                {t(
                                    "Drag and drop elements to change the order"
                                )}
                            </span>
                            <StyledIconButton
                                title={t("Reset to default settings")}
                                outline={true}
                                variant="danger"
                                className="m-0 p-0"
                                style={{ height: "20%" }}
                                onClick={() => {
                                    setLocalIsResetModalOpened(
                                        !localIsResetModalOpened
                                    );
                                }}
                            >
                                <RefreshIcon
                                    height={20}
                                    width={20}
                                    className="Cmn_btn_resetModifications"
                                />
                            </StyledIconButton>
                        </FlexboxGrid>
                    ) : null}
                    <FlexboxGrid className="justify-content-between">
                        <SalesMethodsSelectWrapper />
                        {selectedSaleModeValue !== "" ? (
                            items.filter(
                                (item) =>
                                    (item.active as ItemContentType)[
                                        selectedSaleModeValue
                                    ] === true
                            ).length === 1 ? (
                                <FlexboxGrid
                                    alignItemsCentered={true}
                                    className="justify-content-start cursor__clz"
                                    gap="20px"
                                >
                                    <div
                                        style={{
                                            font:
                                                "normal normal 600 24px/32px Nunito Sans",
                                        }}
                                    >
                                        {t(
                                            "Do not offer this step in the customer ways"
                                        )}
                                    </div>
                                    <div className="Cmn_icn_proposeStageRoute">
                                        <SwitchWrapper
                                            onChange={
                                                handleSkipThisStepOnClickEvent
                                            }
                                            checked={
                                                isSkipThisStepSwitchChecked !==
                                                undefined
                                                    ? isSkipThisStepSwitchChecked
                                                    : false
                                            }
                                        />
                                    </div>
                                </FlexboxGrid>
                            ) : null
                        ) : null}
                    </FlexboxGrid>
                </div>

                {selectedSaleModeValue !== "" ? (
                    items.length > 8 ? (
                        <ScrollContainer>
                            {isKioskSetting === true ? (
                                <React.Fragment>
                                    <div
                                        className="mt-5 d-flex justify-content-center"
                                        style={{
                                            gap: "40px",
                                        }}
                                    >
                                        {items
                                            .slice(0, 4)
                                            .map((item, index) => {
                                                return (
                                                    <InformationModeCard
                                                        index={index}
                                                        item={item}
                                                        setItems={setItems}
                                                        setConfig={
                                                            setConfigInformationModes
                                                        }
                                                    />
                                                );
                                            })}
                                    </div>
                                    <div
                                        className="mt-5 d-flex justify-content-center"
                                        style={{
                                            gap: "40px",
                                        }}
                                    >
                                        {items.slice(4).map((item, index) => {
                                            return (
                                                <InformationModeCard
                                                    index={index}
                                                    item={item}
                                                    setItems={setItems}
                                                    setConfig={
                                                        setConfigInformationModes
                                                    }
                                                />
                                            );
                                        })}
                                    </div>
                                </React.Fragment>
                            ) : (
                                <FlexboxGrid
                                    justifyContentCentered={true}
                                    className="drag-drop-wrapper__clz flex-column cursor__clz"
                                    gap="25px"
                                >
                                    <ListManager
                                        items={items}
                                        direction="horizontal"
                                        maxItems={4}
                                        render={(item) => {
                                            const index = items.findIndex(
                                                (object) => {
                                                    return (
                                                        object.name ===
                                                        item.name
                                                    );
                                                }
                                            );

                                            return (
                                                <InformationModesCardWrapper
                                                    item={item}
                                                    index={index}
                                                    key={index}
                                                    setConfig={
                                                        setConfigInformationModes
                                                    }
                                                    setItems={setItems}
                                                    hasTooltip={true}
                                                />
                                            );
                                        }}
                                        onDragEnd={handleOnDragEndEvent}
                                    />
                                </FlexboxGrid>
                            )}
                        </ScrollContainer>
                    ) : isKioskSetting === true ? (
                        <React.Fragment>
                            <div
                                className="d-flex flex-row justify-content-center align-items-center"
                                style={{ gap: "50px" }}
                            >
                                {items.slice(0, 3).map((item, index) => {
                                    return (
                                        <InformationModeCard
                                            index={index}
                                            item={item}
                                            setItems={setItems}
                                            setConfig={
                                                setConfigInformationModes
                                            }
                                        />
                                    );
                                })}
                            </div>
                            <div
                                className="d-flex flex-row justify-content-center align-items-center"
                                style={{ gap: "50px" }}
                            >
                                {items.slice(3).map((item, index) => {
                                    return (
                                        <InformationModeCard
                                            index={index}
                                            item={item}
                                            setItems={setItems}
                                            setConfig={
                                                setConfigInformationModes
                                            }
                                        />
                                    );
                                })}
                            </div>
                        </React.Fragment>
                    ) : (
                        <FlexboxGrid
                            justifyContentCentered={true}
                            className="drag-drop-wrapper__clz flex-column"
                            gap="6px"
                        >
                            <ListManager
                                items={items}
                                direction="horizontal"
                                maxItems={
                                    items.length > 4 && items.length <= 6
                                        ? 3
                                        : 4
                                }
                                render={(item) => {
                                    const index = items.findIndex((object) => {
                                        return object.name === item.name;
                                    });

                                    return (
                                        <InformationModesCardWrapper
                                            item={item}
                                            index={index}
                                            key={index}
                                            setConfig={
                                                setConfigInformationModes
                                            }
                                            setItems={setItems}
                                            hasTooltip={true}
                                        />
                                    );
                                }}
                                onDragEnd={handleOnDragEndEvent}
                            />
                        </FlexboxGrid>
                    )
                ) : null}
            </div>

            <ResetModalWrapper
                handleResetOnClickEvent={handleResetOnClickEvent}
                localIsResetModalOpened={localIsResetModalOpened}
                setLocalIsResetModalOpened={setLocalIsResetModalOpened}
            />
        </CustomErrorBoundary>
    );
}
