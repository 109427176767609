import React from "react";

import { useTranslation } from "react-i18next";
import { StyledModal, StyledLabel, CheckboxIcon } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";
import { mutate } from "swr";

import { store as modeOfSale, setGlobalDataIsUpdated } from "../store";
import { store } from "@components/VerticalLayout/store";

import { capitalize } from "@helpers/general";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationModificationMessageType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    setIsEdited: Function;
    setDataIsUpdated: Function;
    associated: any[];
    isAddModeOfSaleClicked: boolean;
    setIsAddModeOfSaleClicked: Function;
    salesSupport: any;
    dataSale: any;
    setLimit: Function;
};

export function ConfirmationModificationMessage({
    isModalOpened,
    setIsModalOpened,
    setIsEdited,
    setDataIsUpdated,
    associated,
    isAddModeOfSaleClicked,
    setIsAddModeOfSaleClicked,
    salesSupport,
    dataSale,
    setLimit,
}: ConfirmationModificationMessageType) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const {
        salesSupports,
        shopsModeSaleData,
        editedDataModeOfSale,
    } = useSnapshot(modeOfSale);

    const [error, setError] = React.useState<boolean>(false);
    const [isSalesSupportEmpty, setIsSalesSupportEmpty] = React.useState<
        boolean[]
    >([]);
    const [isDataChanged, setIsDataChanged] = React.useState<boolean>(false);

    async function editAllModeOfSale() {
        toast.dismiss();
        let objectData = {};
        let localData: any[] = [];

        associated.forEach(async (el: any) => {
            Object.assign(objectData, {
                [el.Id]: {
                    designation: editedDataModeOfSale.data[el.Id].designation,
                    tag: editedDataModeOfSale.data[el.Id].tag,
                    display_name: editedDataModeOfSale.data[el.Id].display_name,
                    support_vente: salesSupport,
                    isArchived: el.isArchived,
                    isFavorite: el.isFavorite,
                    shopId: el.shopId,
                    state: el.state,
                },
            });

            let dataModeOfSale = {
                userId: userID,
                shopId: [el.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                data: objectData,
                isShop: true,
                isAssociate: true,
            };

            localData.push(dataModeOfSale);
        });
        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`,
                await fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(localData),
                    }
                ).then((result) => console.log(result))
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setError(true);
        }

        if (error === false) {
            if (!isSalesSupportEmpty.includes(false)) {
                toast.success(
                    `${t("Shop sales method has been changed successfully")!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
            } else if (
                (isSalesSupportEmpty.includes(true) &&
                    isSalesSupportEmpty.includes(false)) ||
                (!isSalesSupportEmpty.includes(true) && isDataChanged)
            ) {
                setLimit(2);
                toast.warn(
                    `${t(
                        "Please note that the modification could not be made at one or more stores.Please check the data settings of the store(s)"
                    )}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
                toast.success(
                    `${t("Shop sales method has been changed successfully")!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
            } else if (!isSalesSupportEmpty.includes(true) && !isDataChanged) {
                toast.warn(
                    `${t(
                        "Please note that the modification could not be made at one or more stores.Please check the data settings of the store(s)"
                    )}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
            }

            setDataIsUpdated(true);
            setGlobalDataIsUpdated(true);
            setIsAddModeOfSaleClicked(!isAddModeOfSaleClicked);
            setIsEdited(false);
        }
    }

    function isInclude(element: any, array: any[]) {
        return array.includes(element);
    }

    React.useEffect(() => {
        let allModeOfSaleShop: any = [];
        let localSalesSupport: boolean[] = [];
        if (shopsModeSaleData.data !== undefined) {
            shopsModeSaleData.data.forEach((el: any) => {
                if (el.subRows !== undefined)
                    el.subRows.forEach((element: any) => {
                        allModeOfSaleShop.push(element.shopId);
                    });
            });
        }
        salesSupports.forEach((el: any) => {
            if (allModeOfSaleShop.includes(el.id)) {
                let filteredSalesSupport: any = el.salesSupport.filter(
                    (value: any) => salesSupport.includes(value)
                );
                localSalesSupport.push(
                    filteredSalesSupport.length > 0 &&
                        salesSupport.length === filteredSalesSupport.length
                );
            }
        });
        let localDataChanged: any = Object.values(editedDataModeOfSale.data)[0];
        let local = [...localDataChanged.tag];

        setIsDataChanged(
            dataSale.display_name !== localDataChanged.display_name ||
                (!dataSale.tag.every((el: any) => isInclude(el, local)) &&
                    !local.every((el: any) => isInclude(el, dataSale.tag)))
        );

        setIsSalesSupportEmpty(localSalesSupport);
    }, [
        shopsModeSaleData,
        salesSupports,
        salesSupport,
        dataSale,
        editedDataModeOfSale.data,
        t,
    ]);

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                }}
            >
                <span
                    style={{
                        fontFamily: "Segoe UI Semibold",
                        color: "#2b2828",
                        fontSize: "20px",
                    }}
                >
                    {capitalize(t("Alert"))}
                </span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        'Do you want to apply these changes for all stores? \n Attention ! If you click on "Validate", the specific settings of the shops will be modified.'
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                        setIsAddModeOfSaleClicked(false);
                        setDataIsUpdated(true);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                        editAllModeOfSale();
                        setGlobalDataIsUpdated(true);
                        setIsAddModeOfSaleClicked(false);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
