import React from "react";
import { useTranslation } from "react-i18next";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { CardBody, CardFooter } from "reactstrap";
import {
    CheckboxIcon,
    PlusIcon,
} from "@aureskonnect/react-ui";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";

import store, { resetStore, setApplicationListData } from "./store";
import {
    setIsModalConfirmationOpened,
    store as userStore,
} from "@components/VerticalLayout/store";

import { ConfirmationDeleteMessage } from "@components/Common/ConfirmationDeleteMessage";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import { AlertDeletePeripheralMessage } from "@components/Common/AlertDeletePeripheralMessage";
import { ConfirmationMessage } from "@components/Common/ConfirmationMessage";
import { ApplicationConfigure } from "../ApplicationConfigure";
import { ActionColumn } from "./ActionColumn";
import { SelectionButton } from "./SelectionButton";
import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";
import { CustomMainColorButtonWithIcon } from "@components/Common/CustomMainColorButtonWithIcon";

import "../index.css";

type CustomColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
};

type ApplicationListType = {
    setIsDeviceAssociateModalOpened: Function;
    isDeviceAssociateModalOpened: boolean;
    rowData: any;
    dataIsUpdated: boolean | number;
    setDataIsUpdated: Function;
};

export default function ApplicationList({
    setIsDeviceAssociateModalOpened,
    isDeviceAssociateModalOpened,
    rowData,
    dataIsUpdated,
    setDataIsUpdated,
}: ApplicationListType) {
    const { applications, applicationsToBeDeleted } = useSnapshot(store);
    const { t } = useTranslation();

    const {
        oneShop,
        userID,
        franchiseID,
        shopID,
        isModalConfirmationOpened,
        operatorID,
    } = useSnapshot(userStore);

    const [isAddAppClicked, setIsAddAppClicked] = React.useState<boolean>(
        false
    );
    const APPID = "KIOSK";

    const [editedData, setEditedData] = React.useState<any>([]);
    const [deleteApplication, setDeleteApplication] = React.useState<any>([]);
    const [
        dataIsApplicationUpdated,
        setDataApplicationIsUpdated,
    ] = React.useState<boolean | number>(false);
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [
        isAddNewApplicationButtonClicked,
        setIsAddNewApplicationButtonClicked,
    ] = React.useState(false);
    const [
        isConfirmationEditModalOpened,
        setIsConfirmationEditModalOpened,
    ] = React.useState<boolean>(false);
    const [applicationId, setApplicationId] = React.useState<string>("");
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [
        isModalDeleteAssociatePeripheralOpened,
        setIsModalDeleteAssociatePeripheralOpened,
    ] = React.useState<boolean>(false);
    const [dataTable, setDataTable] = React.useState<any>({});
    const [isComingFromModify, setIsComingFromModify] = React.useState<boolean>(
        false
    );
    const [isDataModified, setIsDataModified] = React.useState<boolean>(true);
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [isEdited, setIsEdited] = React.useState<boolean>(false);
    const [inputDesignation, setInputDesignation] = React.useState<boolean>(
        false
    );
    let arrayOfCustomColumns: CustomColumnProps[] = [
        {
            indexOFColumn: 999,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <SelectionButton element={e.selectedRow} rowData={rowData} />
            ),
        },
    ];

    async function handleValidateButtonClickEvent() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/${rowData.peripheralType}`;
        let userId: number;
        let shopId: string;
        let franchiseId: string;
        if (oneShop) {
            userId = userID;
            shopId = shopID.toString();
            franchiseId = franchiseID;
        } else {
            userId = userID;
            shopId = rowData.shopId;
            franchiseId = franchiseID;
        }
        let savedData;
        switch (rowData.peripheralType) {
            case "monetic":
                savedData = {
                    userId: userId,
                    shopId: shopId,
                    franchiseId: franchiseId,
                    appId: APPID,
                    operatorId: operatorID,
                    appId_children: applications.map((e: any) => e.appId),
                    data: {
                        [rowData["peripheralId"]]: {
                            id_monetic: rowData["peripheralId"],
                            designation: rowData[t("Peripheral")],
                            mark: rowData[t("Mark")],
                            port: rowData["port"],
                            note: rowData[t("Remark")],
                            status: "in line",
                            state: "to configure",
                            path: rowData["path"],
                            android: rowData["android"],
                            merchant_code: rowData["merchant_code"],
                            tpe_name: rowData["tpe_name"],
                            device_code: rowData["device_code"],
                            speed: rowData["speed"],
                            ip: rowData["ip"],
                            payment_type: rowData["payment_type"],
                            kiss: rowData["kiss"],
                            reference: rowData["reference"],
                            type: rowData[t("Mark")],
                            peripheralType: rowData["peripheralType"],
                            general: {},
                            customer_ticket: {},
                            Kitchen_ticket: {},
                            modelKitchenTicket: {},
                            divers: {},
                        },
                    },
                };
                break;
            case "printer":
                savedData = {
                    userId: userId,
                    shopId: shopId,
                    franchiseId: franchiseId,
                    appId: APPID,
                    appId_children: applications.map((e: any) => e.appId),
                    data: {
                        [rowData["peripheralId"]]: {
                            id_printer: rowData["peripheralId"],
                            designation: rowData[t("Peripheral")],
                            mark: rowData[t("Mark")],
                            port: rowData["port"],
                            note: rowData[t("Remark")],
                            status: "in line",
                            state: "to configure",
                            path: rowData["path"],
                            font_size: rowData["font_size"],
                            edit_date: rowData["edit_date"],
                            type: rowData["type"],
                            peripheralType: rowData["peripheralType"],
                            add_date: rowData["add_date"],
                            speed: rowData["speed"],
                            port_series: rowData["port_series"],
                            ip: rowData["ip"],
                            ticket_width: rowData["ticket_width"],
                            general: {},
                            customer_ticket: {},
                            Kitchen_ticket: {},
                            modelKitchenTicket: {},
                            divers: {},
                        },
                    },
                };
                break;
            case "cash_management":
                savedData = {
                    userId: userId,
                    shopId: shopId,
                    franchiseId: franchiseId,
                    appId: APPID,
                    appId_children: applications.map((e: any) => e.appId),
                    data: {
                        [rowData["peripheralId"]]: {
                            id_cash_management: rowData["peripheralId"],
                            designation: rowData[t("Peripheral")],
                            mark: rowData[t("Marque")],
                            port_number: rowData["port_number"],
                            reference: rowData["reference"],
                            note: rowData[t("Remark")],
                            server_directory: rowData["server_directory"],
                            activate: "",
                            user: rowData["user"],
                            ip: rowData["ip"],
                            add_date: rowData["add_date"],
                            edit_date: rowData["edit_date"],
                            password: rowData["password"],
                            pos_id: rowData["pos_id"],
                            coin_loading_alert: {},
                            empty_coin_alert: {},
                            coin_status: {},
                            collection_alert: {},
                            withdrawal_Reason: {},
                            maintenance: {},
                            cash_Fund: {},
                            accepted_denomination: {
                                "5.00 €": false,
                                "10.00 €": false,
                                "20.00 €": false,
                                "50.00 €": false,
                                "100.00 €": false,
                                "200.00 €": false,
                                "500.00 €": false,
                            },
                        },
                    },
                };
                break;
        }
        try {
            if (deleteApplication.length !== 0) {
                deleteApplication.forEach(async (el: any) => {
                    mutate(
                        apiUrl,
                        await fetch(apiUrl, {
                            headers: {
                                "Content-Type": "application/json",
                                authorization: `Bearer ${localStorage.getItem(
                                    "jwt"
                                )}`,
                            },
                            method: "DELETE",
                            body: JSON.stringify({
                                userId: userID,
                                shopId: oneShop ? shopID : rowData.shopId,
                                franchiseId: franchiseID,
                                operatorId: operatorID,
                                appId: APPID,
                                appId_children: [el.appId],
                                peripheralId: rowData.peripheralId,
                                peripheralName: rowData[t("Device")],
                            }),
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                if (data.error) {
                                    throw Error(
                                        "Error while delete selection!"
                                    );
                                }
                            })
                    );
                });
            }
            if (applications.length !== 0) {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bareer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify(savedData),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error while saving selection!");
                            }
                            toast.success(
                                `${t(
                                    "This device was assigned successfully"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            resetStore();
                            setIsDeviceAssociateModalOpened(
                                !isDeviceAssociateModalOpened
                            );
                            setDataIsUpdated(!dataIsUpdated);
                        })
                );
            } else {
                setIsModalConfirmationOpened(true);
            }
        } catch (e: any) {
            toast.error(`${t("There's an error")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    React.useEffect(() => {
        let localApplications: any = [];
        let localDeleteApplication: any = [];
        let localApplicationRow: any = [];
        applications.forEach((el: any) => {
            localApplications.push(el[t("Désignation")]);
        });

        rowData.subRows.forEach((el: any) => {
            localApplicationRow.push(el.Application);
        });

        localApplicationRow.forEach((element: any) => {
            if (!localApplications.includes(element)) {
                localDeleteApplication.push(
                    rowData.subRows.find(
                        (el: any) => element === el.Application
                    )
                );
            }
        });
        setDeleteApplication(localDeleteApplication);
        const isSameApplications =
            localApplicationRow.length === localApplications.length &&
            localApplicationRow.every(function (element: any, index: any) {
                return element === localApplications[index];
            });
        setIsDataModified(!isSameApplications);
        setApplicationListData(dataTable.data);
    }, [applicationsToBeDeleted, applications, rowData, t, dataTable]);

    return (
        <React.Fragment>
            <CardBody
                className="p-1"
                style={{
                    maxHeight: isAddAppClicked ? "initial" : "720px",
                }}
            >
                <div
                    style={{
                        display: "grid",
                        minHeight: "650px",
                        gridTemplateColumns: isAddAppClicked
                            ? "2fr 1fr "
                            : "auto",
                        gridColumnGap: "4px",
                        border: 0,
                        marginBottom: isAddAppClicked ? "70px" : "0px",
                    }}
                >
                    <DynamicTable
                        url={`${process.env.REACT_APP_API_V2_URL}/settings/AllApplication?userId=${userID}&shopId=${rowData.shopId}&franchiseId=${franchiseID}&appId=${APPID}`}
                        customJsxSideFilterButton={
                            isAddAppClicked !== true ? (
                                <div className="d-flex justify-content-start align-items-center">
                                    <span
                                        onClick={() => {
                                            setIsAddAppClicked(
                                                !isAddAppClicked
                                            );

                                            setIsComingFromModify(false);
                                        }}
                                    >
                                        <CustomMainColorButtonWithIcon
                                            icon="PlusIcon"
                                            iconPosition="left"
                                            className="w-100"
                                            style={{ height: "0px" }}
                                            rounded
                                            variant="primary"
                                        >
                                            {t("Add an application")}
                                        </CustomMainColorButtonWithIcon>
                                    </span>
                                </div>
                            ) : (
                                <div className="d-flex justify-content-start">
                                    <CustomMainColorIconButton
                                        icon="PlusIcon"
                                        rounded
                                        disabled={
                                            editedData.length === 0
                                                ? false
                                                : true
                                        }
                                        onClick={() => {
                                            if (isEdited === true) {
                                                setIsInInitializationModalOpened(
                                                    true
                                                );
                                            } else {
                                                setInputDesignation(false);
                                                setIsAddNewApplicationButtonClicked(
                                                    !isAddNewApplicationButtonClicked
                                                );
                                            }
                                        }}
                                    >
                                        <PlusIcon
                                            height={20}
                                            width={20}
                                            fill="white"
                                        />
                                    </CustomMainColorIconButton>
                                </div>
                            )
                        }
                        actionColumn={(e: any) => (
                            <ActionColumn
                                e={e}
                                isModalOpened={isModalOpened}
                                setIsModalOpened={setIsModalOpened}
                                setEditedData={setEditedData}
                                dataIsApplicationUpdated={
                                    dataIsApplicationUpdated
                                }
                                setDataApplicationIsUpdated={
                                    setDataApplicationIsUpdated
                                }
                                setIsComingFromModify={setIsComingFromModify}
                                setApplicationId={setApplicationId}
                                rowData={rowData}
                                setIsModalDeleteAssociatePeripheralOpened={
                                    setIsModalDeleteAssociatePeripheralOpened
                                }
                                isModalDeleteAssociatePeripheralOpened={
                                    isModalDeleteAssociatePeripheralOpened
                                }
                                setIsAddAppClicked={setIsAddAppClicked}
                            />
                        )}
                        setData={setDataTable}
                        setDataIsUpdated={setDataApplicationIsUpdated}
                        dataIsUpdated={dataIsApplicationUpdated}
                        showGlobalFilter
                        minHeight="520px"
                        maxHeight="520px"
                        filterActive={filterActive}
                        setLocalFilterActive={setLocalFilterActive}
                        arrayOfCustomColumns={arrayOfCustomColumns}
                    />
                    {isAddAppClicked ? (
                        <ApplicationConfigure
                            setIsAddAppClicked={setIsAddAppClicked}
                            isAddAppClicked={isAddAppClicked}
                            setDataIsUpdated={setDataApplicationIsUpdated}
                            selectedRows={
                                isComingFromModify === true
                                    ? editedData.original
                                    : { shopId: rowData.shopId }
                            }
                            isComingFromModify={isComingFromModify}
                            isAddNewAppButtonClicked={
                                isAddNewApplicationButtonClicked
                            }
                            setIsEdited={setIsEdited}
                            inputDesignation={inputDesignation}
                            setInputDesignation={setInputDesignation}
                            setIsConfirmationEditModalOpened={
                                setIsConfirmationEditModalOpened
                            }
                            data={dataTable}
                            setIsConsult={() => {}}
                            isConsult={false}
                        />
                    ) : null}
                </div>
            </CardBody>
            {!isAddAppClicked ? (
                <CardFooter className="Card-footer__clz">
                    <div className="justify-content-between d-flex align-items-right float-right p-1">
                        <CustomSecondaryColorButton
                            outline
                            rounded
                            className="mr-2"
                            variant="light"
                            onClick={() => {
                                resetStore();
                                setIsDeviceAssociateModalOpened(
                                    !isDeviceAssociateModalOpened
                                );
                            }}
                        >
                            <span> {t("Cancel")}</span>
                        </CustomSecondaryColorButton>
                        <CustomMainColorButton
                            rounded
                            variant="primary"
                            onClick={() => {
                                if (isDataModified === true) {
                                    handleValidateButtonClickEvent();
                                } else {
                                    setIsModalConfirmationOpened(true);
                                }
                            }}
                        >
                            {t("Validate")}
                        </CustomMainColorButton>
                    </div>
                </CardFooter>
            ) : null}
            {isModalConfirmationOpened ? <ConfirmationMessage /> : null}
            <ConfirmationInitializationMessage
                isModalOpened={isInInitializationModalOpened}
                setIsModalOpened={setIsInInitializationModalOpened}
                setIsClicked={setIsAddNewApplicationButtonClicked}
                isClicked={isAddNewApplicationButtonClicked}
                setIsEdited={setIsEdited}
            />
            {isModalDeleteAssociatePeripheralOpened === false ? (
                <ConfirmationDeleteMessage
                    isModalOpened={isModalOpened}
                    setIsModalOpened={setIsModalOpened}
                    setDataIsUpdated={setDataApplicationIsUpdated}
                    id={applicationId}
                    text={t("Please confirm deletion of this application")}
                    message={t("The application was successfully removed")}
                    data={{
                        userId: userID,
                        shopId: oneShop ? shopID : rowData.shopId,
                        franchiseId: franchiseID,
                        appId: APPID,
                        appId_children: applicationId,
                    }}
                    apiUrl={`${
                        process.env.REACT_APP_API_V2_URL
                    }/settings/application?userId=${userID}&shopId=${
                        oneShop ? shopID : rowData.shopId
                    }&franchiseId=${franchiseID}`}
                />
            ) : (
                <AlertDeletePeripheralMessage
                    text={t(
                        "This app is associated with one or more device(s), Unable to perform delete action"
                    )}
                    isModalDeleteAssociatePeripheralOpened={
                        isModalDeleteAssociatePeripheralOpened
                    }
                    setIsModalDeleteAssociatePeripheralOpened={
                        setIsModalDeleteAssociatePeripheralOpened
                    }
                />
            )}
            {isConfirmationEditModalOpened ? (
                <ConfirmationEditMessage
                    setDataIsUpdated={setDataApplicationIsUpdated}
                    isModalOpened={isConfirmationEditModalOpened}
                    setIsModalOpened={setIsConfirmationEditModalOpened}
                    isAddClicked={isAddAppClicked}
                    setIsAddClicked={setIsAddAppClicked}
                />
            ) : null}
        </React.Fragment>
    );
}
