import React from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { useSnapshot } from "valtio";

import PageTitle from "@components/Common/PageTitle";
import MessageInfo from "@components/Common/MessageInfo";
import { StyledBreadcrumb, Tabs } from "@aureskonnect/react-ui";

import { store } from "../../../components/VerticalLayout/store";

import CustomerAccounts from "./CustomerAccounts";
import CustomerAccountShop from "./CustomerAccountShop";

import { MainColorTabsWrapper } from "@components/Common/MainColorTabsWrapper";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

export default function VatRate() {
    const { t } = useTranslation();
    const { oneShop } = useSnapshot(store);

    const tabs1 = <CustomerAccountShop franchise={false} />;

    const tabs2 = (
        <Tabs
            tabs={[
                {
                    content: <CustomerAccountShop franchise={true} />,
                    disabled: false,
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark cmn_btn_boutique"
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Customer Account")}
                        </div>
                    ),
                },
                {
                    content: <CustomerAccounts />,
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark border-0 cmn_btn_franchise"
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Shops Customer Account")}
                        </div>
                    ),
                },
            ]}
        />
    );
    return (
        <React.Fragment>
            <ToastContainer limit={1} />
            <PageTitle title={t("Peripherals")} />
            <div className="page-content__clz">
                <StyledBreadcrumb
                    items={[
                        {
                            item: "ETK Settings".toUpperCase(),
                            link: "https://dev-portail.aureskonnect.com",
                        },
                        {
                            item:
                                localStorage
                                    .getItem("selectedAccount")
                                    ?.toUpperCase() || "",
                            link: "/compte",
                        },
                        {
                            item: t("General settings").toUpperCase(),
                            link: "#",
                        },
                        {
                            item: t(
                                "Customer account informations"
                            ).toUpperCase(),
                            link: "#",
                        },
                    ]}
                />
                <MessageInfo
                    message={t(
                        "Please consult your customer account information"
                    )}
                />
                <MainColorTabsWrapper>
                    {oneShop ? tabs1 : tabs2}
                </MainColorTabsWrapper>
            </div>
        </React.Fragment>
    );
}
