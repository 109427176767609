import React from "react";
import classnames from "classnames";
import ReactSwitch from "react-switch";
import { toast } from "react-toastify";

import { CheckboxIcon, EyeIcon, TrashIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { Tooltip } from "@mui/material";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";

import { store as ModeOfInformation } from "../../../../components/VerticalLayout/store";
import store, {
    setFnDelete,
    setIsDeleteCheckClick,
    setNumberActiveMultiShop,
    setNumberArchiveMultiShop,
} from "../store";

export function CustomIcons({
    e,
    setRowData,
    setDataIsUpdated,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    setSelectedRows,
    isModeOfInformationArchived,
    setIsModeOfInformationArchived,
}: any) {
    const { t } = useTranslation();
    const {
        numberActiveMultiShop,
        numberArchiveMultiShop,
        modeInformationData,
        modify,
    } = useSnapshot(store);
    const { franchiseID, userID, oneShop, operatorID } = useSnapshot(
        ModeOfInformation
    );
    const [modeOfInformationState, setModeOfInformationState] = React.useState<
        boolean
    >(e.selectedRow.state);

    let dataArchive: any[] = [];

    if (!oneShop) {
        dataArchive = ArchiveModeOfInformation();
    }
    function ArchiveModeOfInformation() {
        let idsAssociated: any = [];
        if (modeInformationData.data !== undefined) {
            modeInformationData.data.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    async function handleStateChange() {
        let id = e.selectedRow.Id;
        if (id !== undefined) {
            let obj = [
                {
                    userId: userID,
                    operatorId: operatorID,
                    shopId: [e.selectedRow.shopId],
                    franchiseId: franchiseID,
                    isShop: false,
                    data: {
                        [id]: {
                            designation:
                                e.selectedRow[t("Mode of information")],
                            supportOfSale: e.selectedRow[t("Sales support")],
                            salesSupportData: [
                                e.selectedRow[t("Sales support")],
                            ],
                            displayName: e.selectedRow[t("Display name")],
                            modeOfSale: e.selectedRow[t("Sales method")],
                            tag: e.selectedRow.tag,
                            isArchived: false,
                            state: !modeOfInformationState,
                            shopId: e.selectedRow.shopId,
                            stateShop: !modeOfInformationState,
                        },
                    },
                },
            ];

            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`;
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(obj),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.error) {
                            throw Error(result.message);
                        }

                        toast.success(
                            modeOfInformationState === true
                                ? `${t(
                                      "The Mode of information has been successfully deactivated"
                                  )}`
                                : `${t(
                                      "The Mode of information has been successfully activated"
                                  )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated!(true);
                    })
                    .catch((error: any) => {
                        toast.error(`${t("There's an error")!}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    })
            );
        }
    }

    async function activeModeOfInformation() {
        toast.dismiss();
        if (e.selectedRow.Id !== undefined) {
            let dataModeOfInformation = [
                {
                    userId: userID,
                    operatorId: operatorID,
                    shopId: [e.selectedRow.shopId],
                    franchiseId: franchiseID,
                    isShop: false,
                    data: {
                        [e.selectedRow.Id]: {
                            designation:
                                e.selectedRow[t("Mode of information")],
                            supportOfSale: e.selectedRow[t("Sales support")],
                            salesSupportData: [
                                e.selectedRow[t("Sales support")],
                            ],
                            displayName: e.selectedRow[t("Display name")],
                            modeOfSale: e.selectedRow[t("Sales method")],
                            tag: e.selectedRow.tag,
                            isArchived: false,
                            state: e.selectedRow.state,
                            shopId: e.selectedRow.shopId,
                            stateShop: e.selectedRow.state,
                        },
                    },
                },
            ];

            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`;
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(dataModeOfInformation),
            };
            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            toast.success(
                                `${t(
                                    "Information mode has been successfully unarchived"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setDataIsUpdated!(true);
                        })
                );
            } catch (e: any) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }
    async function archiveModeOfInformation() {
        toast.dismiss();
        if (e.selectedRow.Id !== undefined) {
            let dataModeOfInformation = [
                {
                    userId: userID,
                    operatorId: operatorID,
                    shopId: [e.selectedRow.shopId],
                    franchiseId: franchiseID,
                    isShop: false,
                    data: {
                        [e.selectedRow.Id]: {
                            designation:
                                e.selectedRow[t("Mode of information")],
                            supportOfSale: e.selectedRow[t("Sales support")],
                            salesSupportData: [
                                e.selectedRow[t("Sales support")],
                            ],
                            displayName: e.selectedRow[t("Display name")],
                            modeOfSale: e.selectedRow[t("Sales method")],
                            tag: e.selectedRow.tag,
                            isArchived: true,
                            state: false,
                            shopId: e.selectedRow.shopId,
                            stateShop: e.selectedRow.state,
                        },
                    },
                },
            ];

            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`;
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(dataModeOfInformation),
            };
            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            toast.success(
                                `${t(
                                    "The Information mode has been successfully archived"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setDataIsUpdated!(true);
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around ">
                    {modify === true ? (
                        <>
                            <Tooltip
                                title={
                                    isModeOfInformationArchived
                                        ? ""
                                        : modeOfInformationState === false
                                        ? t("Activate")
                                        : t("Unactivate")
                                }
                            >
                                <div>
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={modeOfInformationState}
                                        onChange={async () => {
                                            setModeOfInformationState(
                                                !modeOfInformationState
                                            );
                                            handleStateChange();
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                        className={`${classnames(
                                            "pointer__clz ml-1 cmn_icn_BTenableDisable",
                                            {
                                                "not-allowed-icon__clz": isModeOfInformationArchived,
                                            }
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                            {!isModeOfInformationArchived ? (
                                <Tooltip title={t("Archive")}>
                                    <div>
                                        <TrashIcon
                                            onClick={() => {
                                                setIsDeleteCheckClick(true);
                                                setFnDelete(async () => {
                                                    archiveModeOfInformation();
                                                    setNumberActiveMultiShop(
                                                        numberActiveMultiShop -
                                                            1
                                                    );
                                                    setNumberArchiveMultiShop(
                                                        numberArchiveMultiShop +
                                                            1
                                                    );
                                                });
                                            }}
                                            height={25}
                                            width={25}
                                            fill="red"
                                            style={{ cursor: "pointer" }}
                                            className="ml-3 cmn_icn_BTarchived"
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={
                                        dataArchive.includes(e.selectedRow.Id)
                                            ? ""
                                            : t("Unarchive")
                                    }
                                >
                                    <div>
                                        <EyeIcon
                                            height={25}
                                            width={25}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                activeModeOfInformation();
                                                setNumberActiveMultiShop(
                                                    numberActiveMultiShop + 1
                                                );
                                                setNumberArchiveMultiShop(
                                                    numberArchiveMultiShop - 1
                                                );
                                            }}
                                            className={`${classnames(
                                                "pointer__clz ml-2 cmn_icn_BTunarchive",
                                                {
                                                    "not-allowed-icon__clz": dataArchive.includes(
                                                        e.selectedRow.Id
                                                    ),
                                                }
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : null}
                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                className="pointer__clz ml-2 cmn_icn_BThistory"
                                onClick={() => {
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );
                                    setRowData(e.selectedRow);
                                    setSelectedRows([e.selectedRow]);
                                }}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
        </React.Fragment>
    );
}
