import {
    StyledIconButton,
    TrashIcon,
} from "@aureskonnect/react-ui";
import swal from "@sweetalert/with-react";
import { t } from "i18next";
import React from "react";
import ReactSwitch from "react-switch";
import styled from "styled-components";
import { useSnapshot } from "valtio";

import {
    store,
    setProjectFiles,
    setUploadedFiles,
} from "../../store";

import { Uploader } from "@components/Uploader";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { Table } from "./Table";

const Styles = styled.div`
    padding: 1rem;
    height: 550px;
    table {
        border: 1px solid rgb(198, 198, 198);
        table-layout: fixed;
        border-spacing: 0;
        border-collapse: collapse;
        transition: background-color 0.5s ease;
        thead {
            border-bottom: 2px solid black;
            tr {
                :first-child {
                    color: black;
                    th {
                        :first-child {
                            padding-left: 16px;
                        }
                        height: 60px;
                        text-align: left;
                    }
                }
            }
        }
        th,
        td {
            margin: 10px;
            padding: 0.5rem;
            height: 30px;
            width: 30%;
            border-right: 0px;
            border-left: 0px;
            :first-child {
                width: 15%;
                input {
                    width: auto;
                    text-align: left;
                }
            }
            :last-child {
                width: 10%;
                text-align: right;
            }
            input {
                width: 35%;
                text-align: center;
                background-color: inherit;
                font-size: 1rem;
                padding: 5px;
                margin: 0;
                border-radius: 5px;
            }
        }
    }
`;

// eslint-disable-next-line

export default function ActiveStateScreenTable({
    setActiveStateScreenItems,
    activeStateScreenItems,
}:any): JSX.Element {
    const {  uploadedFiles } = useSnapshot(store);

    const uploadInputRef = React.useRef() as React.MutableRefObject<
        HTMLInputElement
    >;

    const validUploadedTypes = [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "video/mp4",
        "video/webm",
    ];

    const activeScreens = activeStateScreenItems.filter(
        (item: StandbyScreenItemType) => item.active
    );

    function EditableNumberCell({ row, cell }: any) {
        const [value, setValue] = React.useState(cell.value);

        const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(Math.abs(Number(e.target.value)));
        };

        const onBlur = () => {
            const localData = [...activeStateScreenItems];
            localData[row.id] = {
                ...localData[row.id],
                timeout: Number(value),
            };

            setActiveStateScreenItems(localData);
        };
        return row.original.type.includes("video") ? (
            <input
                name="input"
                type="number"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                min={0}
                title="input"
                defaultValue={row.original.timeout}
                className="epb_inp_timeAS"
            />
        ) : (
            ""
        );
    }

    function ActionsCell({ row }: any) {
        return (
            <div className="d-flex align-items-center justify-content-end position-relative">
                <ReactSwitch
                    disabled={
                        activeScreens.length === 1 && row.original.active
                            ? true
                            : false
                    }
                    uncheckedIcon={false}
                    checkedIcon={false}
                    handleDiameter={26}
                    offColor="#f7b4b8"
                    offHandleColor="#E30613"
                    checked={row.original.active}
                    onChange={() => {
                        let localData = [...activeStateScreenItems];

                        if (localData[row.id].active === false) {
                            localData.map((element: any, key: number) => {
                                element = { ...element };

                                if (key !== Number(row.id)) {
                                    return (localData[key] = {
                                        ...localData[key],
                                        active: false,
                                    });
                                } else
                                    return (localData[key] = {
                                        ...localData[row.id],
                                        active: true,
                                    });
                            });
                        }

                        setActiveStateScreenItems(localData);
                    }}
                    onColor="#c2eddd"
                    onHandleColor="#34C38F"
                    width={55}
                    height={20}
                />
                <StyledIconButton
                    icon="TrashIcon"
                    disabled={
                        activeScreens.length === 1 && row.original.active
                            ? true
                            : false
                    }
                    onClick={() => {}}
                    aria-label="delete"
                    className="m-0 epb_icn_archiveFileAS"
                >
                    <TrashIcon
                        style={{
                            fill: "red",
                        }}
                        height={20}
                        width={20}
                    />
                </StyledIconButton>
            </div>
        );
    }

 
    function UploaderCell({ row }: any) {
        return (
            <div className="epb_icn_editFileAS">
                <Uploader
                    uploadedFileTitle={
                        row.original.content.split("/")[
                            row.original.content.split("/").length - 1
                        ]
                    }
                    id={row.original.id}
                    screenItemId={row.original.id}
                    screenItems={activeStateScreenItems}
                    setScreenItems={setActiveStateScreenItems}
                />
            </div>
        );
    }

    const columns = React.useMemo(
        () => [
            {
                Header: t("Image or video"),
                accessor: "content",
                Cell: UploaderCell,
            },
            {
                Header: t("Display time (sec)"),
                accessor: "timeout",
                Cell: EditableNumberCell,
            },
            {
                Header: t("Actions"),
                accessor: t("Actions"),
                Cell: ActionsCell,
            },
        ],
        // eslint-disable-next-line
        [activeScreens, setActiveStateScreenItems, activeStateScreenItems]
    );

    function handleUploadImageOnClickEvent(): void {
        uploadInputRef?.current?.click();
    }

    async function handleUploadedDataOnChangeEvent(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        const fileTarget = (event.target as HTMLInputElement)?.files;

        if (fileTarget !== null) {
            handleUploadingNewFileRequest(fileTarget[0]);
        }
    }

    async function handleUploadingNewFileRequest(file: File | undefined) {
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/orb/upload`;

        if (file !== undefined) {
            if (!validUploadedTypes.includes(file.type)) {
                return swal({
                    icon: "error",
                    content: <p>{t("File type is not valid")}!</p>,
                    buttons: false,
                    timer: 2000,
                });
            }

            const data = new FormData();
            data.append("file", file as File);

            await fetch(apiUrl, {
                body: data,
                method: "PUT",
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        throw Error(data.message);
                    }

                    let newImages: ImageItemType[] = [];
                    newImages = [...newImages, data.data];

                    setProjectFiles(newImages);

                    const filesArray = [...uploadedFiles];
                    filesArray.push({
                        id: data.data.id as string,
                        uploadedFile: data.data,
                    });

                    setUploadedFiles(filesArray);

                    const localData: StandbyScreenItemType[] = [
                        ...activeStateScreenItems,
                    ];
                    const searchedFileIndex = localData.indexOf(
                        // eslint-disable-next-line
                        localData.find(
                            (element) => element.id === data.data.id
                        )!
                    );

                    let data1 = [...localData];
                    if (searchedFileIndex !== -1) {
                        localData[searchedFileIndex] = {
                            ...localData[searchedFileIndex],
                            content: data.data.content,
                            timeout: data.data.type.includes("video")
                                ? data.data.timeout
                                : 3,
                            type: data.data.type,
                        };
                        data1[searchedFileIndex] = {
                            ...localData[searchedFileIndex],
                            content: `${URL.createObjectURL(file)}`,
                            timeout: data.data.type.includes("video")
                                ? data.data.timeout
                                : 3,
                            type: data.data.type,
                        };
                    } else {
                        localData.push({
                            id: data.data.id,
                            active: localData.length > 0 ? false : true,
                            content: data.data.content,
                            timeout: data.data.type.includes("video")
                                ? data.data.timeout
                                : 3,
                            type: data.data.type,
                        });
                        data1.push({
                            id: data.data.id,
                            active: localData.length > 0 ? false : true,
                            content: `${URL.createObjectURL(file)}`,
                            timeout: data.data.type.includes("video")
                                ? data.data.timeout
                                : 3,
                            type: data.data.type,
                        });
                    }

                    // let path = `${URL.createObjectURL(file)}`;

                    setActiveStateScreenItems(localData);

                    return swal({
                        icon: "success",
                        content: <p>{t(data.message)}!</p>,
                        buttons: false,
                        timer: 2000,
                    });
                })
                .catch((error) => {
                    return swal({
                        icon: "error",
                        content: <p>{t(error.message)}</p>,
                        buttons: false,
                        timer: 2000,
                    });
                });
        }
    }

   

    return (
        <div className="d-flex flex-column cursor__clz" style={{ gap: "10px" }}>
            <span
                style={{
                    font: "normal normal 600 35px/47px Segoe UI",
                }}
            >
                {t("Standby screen setup")}
            </span>

            <div className="d-flex justify-content-between align-items-center">
                <span>
                    <div
                        style={{
                            font: "normal normal normal 15px/20px Nunito Sans",
                            marginLeft: "15px",
                        }}
                    >
                        {t("Insert a logo, advertising image or video.")}
                    </div>
                    <div
                        style={{
                            font: " normal normal normal 15px/20px Nunito Sans",
                            marginLeft: "15px",
                            color: "rgb(166, 163, 163)",
                        }}
                    >
                        {t(
                            "Only one advertising animation can be activated on the ORB screen."
                        )}
                    </div>
                </span>

                <div
                    className="d-flex align-items-center"
                    style={{
                        gap: "20px",
                    }}
                >
                    <div className="d-flex">
                        <input
                            accept="video/*,image/*"
                            multiple={false}
                            type="file"
                            id="fileUpload"
                            hidden={true}
                            ref={uploadInputRef}
                            onChange={handleUploadedDataOnChangeEvent}
                        />
                        <CustomMainColorButton
                            rounded={true}
                            variant="primary"
                            className="epb_btn_addFileAS"
                            onClick={() => {
                                handleUploadImageOnClickEvent();
                            }}
                        >
                            {t("Add")}
                        </CustomMainColorButton>
                    </div>
                </div>
            </div>

            <Styles>
                <Table
                    columns={columns}
                    activeStateScreenItems={activeStateScreenItems}
                    setActiveStateScreenItems={setActiveStateScreenItems}
                />
            </Styles>
        </div>
    );
}
