import React from "react";
import classnames from "classnames";
import { CheckboxIcon, EyeIcon, TrashIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { mutate } from "swr";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import moment, * as Moment from "moment";
import { extendMoment } from "moment-range";
import { Tooltip } from "@mui/material";

import { store } from "@components/VerticalLayout/store";
import {
    store as exceptionalHoursStore,
    setIsValidateShopArchiveOpened,
} from "../store";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";

type CustomIconsType = {
    e: any;
    setRowData: Function;
    setDataIsUpdated: Function;
    setIsHistoryModalOpened: Function;
    isHistoryModalOpened: boolean;
    dataIsUpdated: boolean | number;
    isArchivedExceptionalHoursClicked: boolean;
    setSelectedRows: Function;
};

export function CustomIcons({
    e,
    setRowData,
    setDataIsUpdated,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    setSelectedRows,
    isArchivedExceptionalHoursClicked,
    dataIsUpdated,
}: CustomIconsType) {
    const { t } = useTranslation();
    const momentRange = extendMoment(Moment);

    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const { exceptionalHoursData, modify } = useSnapshot(exceptionalHoursStore);

    const handleCheckIfDatesOverLapped = () => {
        let isOverLapped = false;
        let localDate = momentRange.range(
            moment(e.selectedRow.startDate, "YYYY-MM-DD"),
            moment(e.selectedRow.endDate, "YYYY-MM-DD")
        );
        if (e.selectedRow.activeExceptionalHours !== undefined) {
            e.selectedRow.activeExceptionalHours.forEach((element: any) => {
                element.recurrenceList.forEach((elt: any) => {
                    let recurrenceDate = momentRange.range(
                        moment(
                            moment(elt[t("Start date")], "DD/MM/YYYY").format(
                                "YYYY-MM-DD"
                            )
                        ),
                        moment(
                            moment(elt[t("End date")], "DD/MM/YYYY").format(
                                "YYYY-MM-DD"
                            )
                        )
                    );
                    if (
                        recurrenceDate.overlaps(localDate) ||
                        recurrenceDate.adjacent(localDate) ||
                        moment(e.selectedRow.startDate, "YYYY-MM-DD").isBetween(
                            moment(
                                moment(
                                    elt[t("Start date")],
                                    "DD/MM/YYYY"
                                ).format("YYYY-MM-DD"),
                                "YYYY-MM-DD"
                            ),
                            moment(
                                moment(elt[t("End date")], "DD/MM/YYYY").format(
                                    "YYYY-MM-DD"
                                ),
                                "YYYY-MM-DD"
                            )
                        )
                    ) {
                        isOverLapped = true;
                    }
                });
            });
        }
        return isOverLapped;
    };

    async function archiveActiveExceptionalHours(isArchive: boolean) {
        toast.dismiss();
        let isOverlap = false;
        if (isArchive === false) {
            isOverlap = handleCheckIfDatesOverLapped();
            if (isOverlap === true) {
                toast.warn(
                    t(
                        "Please note that activating is not possible. Overlap detected."
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
            }
        }
        if (isOverlap === false) {
            let hours: any[] = [];
            e.selectedRow.subRows.forEach((element: any) => {
                hours.push({
                    startTime: element[t("Start hour")],
                    endTime: element[t("End hour")],
                    overLappedStart: false,
                    overLappedEnd: false,
                    inputEndTime: false,
                    inputStartTime: false,
                });
            });
            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/exceptionalHours`;
            let id = e.selectedRow.Id;
            let data = {
                userId: userID,
                shopId: [e.selectedRow.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                data: {
                    [id]: {
                        id: id,
                        designation: e.selectedRow[t("Designation")],
                        state: e.selectedRow.state,
                        startDate: e.selectedRow.startDate,
                        endDate: e.selectedRow.endDate,
                        hours: hours,
                        isArchived: isArchive,
                        isActive: false,
                        shopId: e.selectedRow.shopId,
                        recurrence: e.selectedRow[t("Recurrence")],
                        endDate_recurrence: e.selectedRow.endDate_recurrence,
                        recurrenceDays: e.selectedRow.recurrenceDays,
                    },
                },
            };
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
            };
            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            toast.success(
                                `${t(
                                    isArchive === true
                                        ? "The exceptional schedule was successfully archived"
                                        : "The exceptional schedule was successfully unarchive"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setDataIsUpdated(!dataIsUpdated);
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    function ArchivedExceptionalHours() {
        let idsAssociated: any[] = [];
        if (exceptionalHoursData !== undefined) {
            exceptionalHoursData.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    let dataArchive = ArchivedExceptionalHours();

    return (
        <React.Fragment>
            {e.selectedRow?.Id !== undefined ? (
                <div className="d-flex justify-content-around">
                    {modify === true ? (
                        <>
                            {!isArchivedExceptionalHoursClicked ? (
                                <Tooltip title={t("Archive")}>
                                    <div>
                                        {" "}
                                        <TrashIcon
                                            onClick={() => {
                                                setSelectedRows([
                                                    e.selectedRow,
                                                ]);
                                                setIsValidateShopArchiveOpened(
                                                    true
                                                );
                                            }}
                                            height={25}
                                            width={25}
                                            fill="red"
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            className="cmn_icn_BTarchived ml-3"
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={
                                        dataArchive.includes(e.selectedRow.Id)
                                            ? ""
                                            : t("Unarchive")
                                    }
                                >
                                    <div>
                                        <EyeIcon
                                            height={25}
                                            width={25}
                                            onClick={() =>
                                                archiveActiveExceptionalHours(
                                                    false
                                                )
                                            }
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            className={`${classnames(
                                                "pointer__clz cmn_icn_BTunarchive ml-3",
                                                {
                                                    "not-allowed-icon__clz": dataArchive.includes(
                                                        e.selectedRow.Id
                                                    ),
                                                }
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : null}
                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                style={{ cursor: "pointer" }}
                                className="pointer__clz cmn_icn_BThistory ml-3"
                                onClick={() => {
                                    setSelectedRows([e.selectedRow]);
                                    setRowData(e.selectedRow);
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );
                                }}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
        </React.Fragment>
    );
}
