import styled from "styled-components";

type DragItemPropsType = {
    item:
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
        | OrderTakingPageType;
    isDragging: boolean;
};

export const DragItem = styled.div<DragItemPropsType>`
    display: grid;

    ${({ item }: DragItemPropsType) =>
        item.prefix === "elements"
            ? "justify-content: center;align-items: center;padding: 10px;padding: 10px 0px 10px 0px"
            : ""};

    background-color: ${({ isDragging, item }: DragItemPropsType) =>
        isDragging
            ? "transparent"
            : item.prefix === "elements"
            ? "#F8F8F8"
            : ""};
`;
