import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    CheckboxIcon,
    StyledLabel,
} from "@aureskonnect/react-ui";

import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";
import moment from "moment";

import { store as userStore } from "@components/VerticalLayout/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationArchiveType = {
    setIsDataUpdated: Function;
    setIsModalArchiveOpened: Function;
    isModalArchiveOpened: boolean;
    selectedRows: any;
    isArchivedTemplateClicked: boolean;
    dataTable: any;
};

export function ConfirmationArchiveTemplate({
    setIsDataUpdated,
    setIsModalArchiveOpened,
    isModalArchiveOpened,
    selectedRows,
    isArchivedTemplateClicked,
}: ConfirmationArchiveType): JSX.Element {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID } = useSnapshot(userStore);
    async function archiveTemplate() {
        let apiUrlPlan = `${process.env.REACT_APP_API_V2_URL}/settings/project`;
        toast.dismiss();
        let localData: any = [];
        if (selectedRows.length > 0) {
            selectedRows.forEach(async (el: any) => {
                const id: any = el.projectId;
                let savedData = {
                    userId: userID,
                    franchiseId: franchiseID,
                    shopId: "0",
                    operatorId: operatorID,
                    etat: "Archivage",
                    data: {
                        PROJECT: {
                            project_id: "PROJECT",
                            data_project: {
                                [id]: {
                                    projectId: id,
                                    designation: el[t("Designation")],
                                    associates: el.associates,
                                    template: el.template,
                                    files: el.files,
                                    note: el[t("Note")],
                                    nombre: el.Nombre,
                                    isArchived: true,
                                    status: "en attente",
                                    hourlyInterval: el.hourlyInterval,
                                    definedTime: el.definedTime,
                                    startDate: el.startDate,
                                    time: el.time,
                                    days: el.days,
                                    date: el.date,
                                    modified_at: `le ${moment().format(
                                        "DD/MM/YY à HH:mm a"
                                    )}`,
                                    hour: `${moment().format(
                                        "DD/MM/YY - HH:mm a"
                                    )}`,
                                },
                            },
                        },
                    },
                };
                localData.push(
                    fetch(apiUrlPlan, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(savedData),
                    }).then((response) => response.json())
                );
            });
            try {
                mutate(
                    apiUrlPlan,
                    await Promise.all(localData).then((result: any) => {
                        let errors: boolean[] = result.map(
                            (el: any) => el.error
                        );
                        if (!errors.includes(true)) {
                            toast.success(
                                `${t(
                                    "Template have been successfully archived"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setIsDataUpdated(true);
                        } else {
                            throw Error(result.message);
                        }
                    })
                );
            } catch (e: any) {
                toast.error(`${t("There's an error")}!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    async function activeTemplate() {
        let apiUrlPlan = `${process.env.REACT_APP_API_V2_URL}/settings/project`;
        toast.dismiss();
        let localData: any = [];
        if (selectedRows.length > 0) {
            selectedRows.forEach(async (el: any) => {
                const id: any = el.projectId;
                let savedData = {
                    userId: userID,
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    shopId: "0",
                    etat: "Désarchivage",

                    data: {
                        PROJECT: {
                            project_id: "PROJECT",
                            data_project: {
                                [id]: {
                                    projectId: id,
                                    designation: el[t("Designation")],
                                    associates: el.associates,
                                    template: el.template,
                                    files: el.files,
                                    note: el[t("Note")],
                                    nombre: el.Nombre,
                                    isArchived: false,
                                    hourlyInterval: el.hourlyInterval,
                                    definedTime: el.definedTime,
                                    startDate: el.startDate,
                                    time: el.time,
                                    days: el.days,
                                    date: el.date,
                                    status: el.Status,
                                    modified_at: `le ${moment().format(
                                        "DD/MM/YY à HH:mm a"
                                    )}`,
                                    hour: `${moment().format(
                                        "DD/MM/YY - HH:mm a"
                                    )}`,
                                },
                            },
                        },
                    },
                };
                localData.push(
                    fetch(apiUrlPlan, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(savedData),
                    }).then((response) => response.json())
                );
            });
            try {
                mutate(
                    apiUrlPlan,
                    await Promise.all(localData).then((result: any) => {
                        let errors: boolean[] = result.map(
                            (el: any) => el.error
                        );
                        if (!errors.includes(true)) {
                            toast.success(
                                `${t(
                                    "Template have been successfully activated"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setIsDataUpdated(true);
                        } else {
                            throw Error(result.message);
                        }
                    })
                );
            } catch (e: any) {
                toast.error(`${t("There's an error")}!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalArchiveOpened} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => setIsModalArchiveOpened(!isModalArchiveOpened)}
            >
                <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {!isArchivedTemplateClicked
                        ? selectedRows.length > 1
                            ? t("Please confirm templates archive")
                            : t("Please confirm template archive")
                        : t("Please confirm template active")}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    className="cmn_btn_cancelAlert"
                    onClick={() => {
                        setIsModalArchiveOpened(!isModalArchiveOpened);
                        setIsDataUpdated(true);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2 cmn_btn_validateAlert"
                    variant="primary"
                    onClick={async () => {
                        if (!isArchivedTemplateClicked) {
                            archiveTemplate();
                        } else {
                            activeTemplate();
                        }
                        setIsModalArchiveOpened(!isModalArchiveOpened);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
