import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { CheckboxIcon, StyledH2, StyledModal } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { mutate } from "swr";
import { toast } from "react-toastify";

import { store } from "@components/VerticalLayout/store";
import {
    setIds,
    setIsDataUpdated,
    setSelectedRow,
    store as dataStore,
} from "../store";

import ErrorToast from "@components/Common/ErrorTost";

import { CustomIconsAssociateModal } from "./CustomIconsAssociateModal";
import { ConfirmationMessage } from "./ConfirmationMessage";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type PaymentModalAssociateModalType = {
    isAssociateModalOpened: boolean;
    setIsAssociateModalOpened: Function;
    selectedShopsRows: any;
    setToastLimit: Function;
};

export function PaymentModalAssociateModal({
    setIsAssociateModalOpened,
    isAssociateModalOpened,
    selectedShopsRows,
    setToastLimit,
}: PaymentModalAssociateModalType) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const {
        idsLocalShop,
        activePaymentMethods,
        shopsSelectedRow,
        localShop,
        associatedPaymentMethods,
        salesSupports,
    } = useSnapshot(dataStore);

    const shopId = "0";

    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [isValidateClicked, setIsValidateClicked] = React.useState<
        boolean | number
    >(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isModalArchivedOpened, setIsModalArchivedOpened] = React.useState<
        boolean
    >(false);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState<
        any[]
    >([]);

    const url =
        localShop.length === 1
            ? `${
                  process.env.REACT_APP_API_V2_URL
              }/settings/general/filtered_archived_paymentMethod?userId=${userID}&shopId=${
                  localShop[0].id
              }&franchiseId=${franchiseID}&type=activated${`&paysQuery=${localShop[0].pays}`}`
            : `${
                  process.env.REACT_APP_API_V2_URL
              }/settings/general/filtered_archived_paymentMethod?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&paysQuery=${localShop
                  .map((el: any) => {
                      return el.pays;
                  })
                  .join(",")}`;

    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 0,
            columnName: t("Display name"),
            customJsx: (e: any) => {
                return (
                    <CustomColumnDisplay text={e.selectedRow["Display name"]} />
                );
            },
        },
        {
            indexOFColumn: 1,
            columnName: t("Type of payment method"),
            customJsx: (e: any) => {
                return (
                    <CustomColumnDisplay
                        text={e.selectedRow["Type of payment method"]}
                    />
                );
            },
        },
        {
            indexOFColumn: 2,
            columnName: t("Country"),
            customJsx: (e: any) => {
                return <CustomColumnDisplay text={e.selectedRow.Country} />;
            },
        },
        {
            indexOFColumn: 3,
            columnName: t("Sales support"),
            customJsx: (e: any) => {
                return (
                    <CustomColumnDisplay
                        text={e.selectedRow["Sales support"]}
                    />
                );
            },
        },
        {
            indexOFColumn: 6,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIconsAssociateModal
                    element={e}
                    isOneShopClicked={
                        selectedShopsRows.filter(
                            (el: any) => el.subRows !== undefined
                        ).length < 2
                    }
                    selectedPaymentMethod={selectedPaymentMethod}
                />
            ),
        },
    ];

    async function handleAssociatePaymentMethodsButtonClick() {
        let localData: any = [];
        let archived: any = {};
        let salesSupportInShops: boolean[] = [];

        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod`;

        if (shopsSelectedRow.length > 0) {
            shopsSelectedRow.forEach((element: any) => {
                if (element?.archivedPaymentMethods !== undefined) {
                    element?.archivedPaymentMethods.forEach((el: any) => {
                        Object.assign(archived, {
                            [el.idShop]: el.id,
                        });
                    });
                }
            });
        }
        let isReassignment: boolean[] = [];
        for (let element of selectedShopsRows) {
            let indexInAssociated: number;

            if (selectedRows.length !== 0) {
                selectedRows.forEach(async (el: any) => {
                    if (element.subRows !== undefined) {
                        indexInAssociated = element.subRows.findIndex(
                            (item: any) => item.id === el.id
                        );
                    }

                    let salesSupportInShop: boolean = el[t("Sales support")]
                        .split(",")
                        .some(
                            (elm: any) =>
                                salesSupports
                                    .find((el: any) => el.id === element.idShop)
                                    .salesSupport.indexOf(elm) >= 0
                        );
                    salesSupportInShops.push(salesSupportInShop);

                    let filteredSalesSupport: any = el[t("Sales support")]
                        .split(",")
                        .filter((value: any) =>
                            salesSupports
                                .find((el: any) => el.id === element.idShop)
                                .salesSupport.includes(value)
                        )
                        .join(",");

                    let isLocalAssociated: boolean =
                        element.subRows !== undefined &&
                        element.subRows.length > 0 &&
                        element.subRows[indexInAssociated] !== undefined &&
                        activePaymentMethods.find(
                            (elt: any) => elt.id === el.id
                        ) &&
                        activePaymentMethods &&
                        element.subRows[indexInAssociated].isActive !==
                            activePaymentMethods.find((element: any) => {
                                return element.id === el.id;
                            }).state;
                    let displayNameChanged: boolean =
                        element.subRows !== undefined &&
                        element.subRows.length > 0 &&
                        element.subRows[indexInAssociated] &&
                        element.subRows[indexInAssociated][
                            t("Display name")
                        ] !== el[t("Display name")];

                    isReassignment.push(isLocalAssociated);
                    let shopsSalesSupport: any[] = [];
                    if (shopsSelectedRow.length > 0) {
                        shopsSelectedRow.forEach((elem: any) => {
                            if (elem.idShop === element.idShop) {
                                element.subRows.forEach((e: any) => {
                                    if (e.id === el.id) {
                                        shopsSalesSupport = e[
                                            t("Sales support")
                                        ].split(",");
                                    }
                                });
                            }
                        });
                    }
                    let isSalesSupportsInShopsAndList: boolean =
                        el[t("Sales support")].split(",").length ===
                            shopsSalesSupport.length &&
                        el[t("Sales support")]
                            .split(",")
                            .every(
                                (value: any, index: number) =>
                                    value === shopsSalesSupport[index]
                            );
                    if (
                        (el.Pays.split(",").includes(element.Pays) &&
                            salesSupportInShop &&
                            indexInAssociated === -1) ||
                        (el.Pays.split(",").includes(element.Pays) &&
                            salesSupportInShop &&
                            isLocalAssociated) ||
                        (el.Pays.split(",").includes(element.Pays) &&
                            salesSupportInShop &&
                            displayNameChanged) ||
                        (el.Pays.split(",").includes(element.Pays) &&
                            salesSupportInShop &&
                            !isSalesSupportsInShopsAndList)
                    ) {
                        let objectData: any = {
                            affectation:
                                isLocalAssociated !== true &&
                                displayNameChanged !== true
                                    ? "true"
                                    : "reassignment",
                            userId: userID,
                            shopId: [element.idShop],
                            franchiseId: franchiseID,
                            operatorId: operatorID,
                            data: {
                                [el.id]: {
                                    designation:
                                        el[t("Type of payment method")],
                                    currency: el.Devise,
                                    display: el[t("Display name")],
                                    shopId: element.idShop,
                                    saleSupport: filteredSalesSupport,
                                    country: el[t("Country")],
                                    tag: el.Tag,
                                    isActive:
                                        activePaymentMethods &&
                                        activePaymentMethods.find(
                                            (element: any) =>
                                                element.id === el.id
                                        ).state,
                                    isArchived: el.isArchived,
                                },
                            },
                        };
                        localData.push(objectData);
                    }
                });
            }
        }
        if (localData.length !== 0) {
            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            data: localData,
                            type: "multiple",
                        }),
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            setToastLimit(2);
                            if (!salesSupportInShops.includes(true)) {
                                toast.warn(
                                    t(
                                        "Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings."
                                    ),
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 2000,
                                        theme: "colored",
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                    }
                                );
                            } else if (!salesSupportInShops.includes(false)) {
                                toast.success(
                                    !isReassignment.includes(true)
                                        ? `${t(
                                              "The assignment was successful"
                                          )}`
                                        : `${t(
                                              "The reassignment was successful"
                                          )}`,
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 2000,
                                        theme: "colored",
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        icon: (
                                            <CheckboxIcon
                                                height={25}
                                                width={25}
                                                fill="white"
                                            />
                                        ),
                                    }
                                );
                            } else if (
                                salesSupportInShops.includes(true) &&
                                salesSupportInShops.includes(false)
                            ) {
                                toast.success(
                                    !isReassignment.includes(true)
                                        ? `${t(
                                              "The assignment was successful"
                                          )}`
                                        : `${t(
                                              "The reassignment was successful"
                                          )}`,
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 2000,
                                        theme: "colored",
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        icon: (
                                            <CheckboxIcon
                                                height={25}
                                                width={25}
                                                fill="white"
                                            />
                                        ),
                                    }
                                );
                                toast.warn(
                                    t(
                                        "Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings."
                                    ),
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 2000,
                                        theme: "colored",
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                    }
                                );
                            }

                            setIsValidateClicked(false);
                            setIsAssociateModalOpened(!isAssociateModalOpened);
                            setIsDataUpdated!(true);
                            setSelectedRows([]);
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        } else if (!salesSupportInShops.includes(true)) {
            toast.warn(
                t(
                    "Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings."
                ),
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                }
            );
            setIsDataUpdated!(true);
            setIsValidateClicked(false);
            setIsAssociateModalOpened(!isAssociateModalOpened);
        } else {
            toast.success(`${t("The assignment was successful")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                icon: <CheckboxIcon height={25} width={25} fill="white" />,
            });
            setIsDataUpdated!(true);
            setIsValidateClicked(false);
            setIsAssociateModalOpened(!isAssociateModalOpened);
        }
    }

    function CountIds() {
        let number = 0;
        idsLocalShop.forEach((el: any) => {
            selectedRows.forEach((v: any) => {
                if (v.Id === el) {
                    number = number + 1;
                }
            });
        });
        setIds(number);
    }

    function checkIfPaymentMethodExistAndArchived() {
        let archived: any[] = [];
        if (shopsSelectedRow.length > 0) {
            shopsSelectedRow.forEach((element: any) => {
                if (element?.archivedPaymentMethods !== undefined) {
                    element?.archivedPaymentMethods.forEach((el: any) => {
                        archived.push(el.id);
                    });
                }
            });
        }
        return archived;
    }

    React.useEffect(() => {
        let localSelectedPaymentMethod: any = [];
        selectedShopsRows.forEach((el: any) => {
            if (el.subRows !== undefined)
                el.subRows.forEach((element: any) => {
                    localSelectedPaymentMethod.push(element);
                });
        });
        setSelectedPaymentMethod(localSelectedPaymentMethod);
    }, [selectedShopsRows, selectedRows, activePaymentMethods]);

    React.useEffect(() => {
        if (isValidateClicked === true)
            handleAssociatePaymentMethodsButtonClick();
        // eslint-disable-next-line
    }, [isValidateClicked]);

    return (
        <React.Fragment>
            <StyledModal
                toggle={true}
                isOpen={isAssociateModalOpened!}
                centered
                className="modal-wrapper__clz"
                backdrop="static"
                fade={false}
                scrollable
            >
                <ModalHeader
                    className="text-capitalize"
                    toggle={() => {
                        setIsAssociateModalOpened(!isAssociateModalOpened);
                    }}
                >
                    <StyledH2 className="text-uppercase mdr_txt_AFlistOfPaymentMethods">
                        {t("List of payment methods")}
                    </StyledH2>
                </ModalHeader>
                <ModalBody>
                    <DynamicTable
                        url={url}
                        canSelect
                        customSelect
                        canResize
                        setSelectedRows={setSelectedRows}
                        actionColumn={ActionColumn}
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        showGlobalFilter
                        setDataIsUpdated={setDataIsUpdated}
                        dataIsUpdated={dataIsUpdated}
                        elevationTable={1}
                        minHeight="500px"
                        maxHeight="500px"
                        name="associateModeOfSale"
                    />
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <CustomSecondaryColorButton
                        outline
                        variant="light"
                        onClick={() => {
                            setIsAssociateModalOpened(!isAssociateModalOpened);
                        }}
                        rounded
                        className="mdr_btn_AFcancel"
                    >
                        {t("Cancel")}
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        className="mdr_btn_AFvalidate"
                        rounded
                        variant="primary"
                        onClick={() => {
                            let archived = checkIfPaymentMethodExistAndArchived();

                            if (selectedRows.length === 0) {
                                ErrorToast(
                                    `${t(
                                        "Attention ! You must select at least one payment method"
                                    )!}`
                                );
                            } else {
                                CountIds();
                                try {
                                    setSelectedRow(selectedRows);
                                    selectedRows.forEach((el: any) => {
                                        associatedPaymentMethods.forEach(
                                            (element: any) => {
                                                if (
                                                    element.id === el.id &&
                                                    activePaymentMethods.find(
                                                        (elt: any) =>
                                                            elt.id === el.id
                                                    ) &&
                                                    element.isActive !==
                                                        activePaymentMethods.find(
                                                            (elt: any) =>
                                                                elt.id === el.id
                                                        ).state
                                                ) {
                                                    setIsModalOpened(
                                                        !isModalOpened
                                                    );
                                                    // eslint-disable-next-line
                                                    throw "Break";
                                                }
                                            }
                                        );
                                    });
                                    selectedRows.forEach((el: any) => {
                                        if (archived.includes(el.id)) {
                                            setIsModalArchivedOpened(
                                                !isModalArchivedOpened
                                            );
                                            // eslint-disable-next-line
                                            throw "Break";
                                        }
                                    });
                                    handleAssociatePaymentMethodsButtonClick();
                                } catch (e) {
                                    if (e !== "Break") throw e;
                                }
                            }
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </ModalFooter>
            </StyledModal>
            {isModalOpened ? (
                <ConfirmationMessage
                    msg={t(
                        'Do you want to apply these changes for all stores? \n Attention ! If you click on "Validate", the specific settings of the shops will be modified.'
                    )}
                    setIsModalOpened={setIsModalOpened}
                    isModalOpened={isModalOpened}
                    setIsValidateClicked={setIsValidateClicked}
                />
            ) : null}
            {isModalArchivedOpened ? (
                <ConfirmationMessage
                    msg={t(
                        "Attention! One or more selected payment methods are already assigned and archived at the store level.Would you unarchive them?"
                    )}
                    setIsModalOpened={setIsModalArchivedOpened}
                    isModalOpened={isModalArchivedOpened}
                    setIsValidateClicked={setIsValidateClicked}
                />
            ) : null}
        </React.Fragment>
    );
}
