import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import classNames from "classnames";
import { uid } from "react-uid";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import ReactSwitch from "react-switch";

import {
    generalConfigStore,
    setAccountCreationMethodsOptions,
    setImageKey,
    setProjectFiles,
    setIsAccountCreationMethodsOptionActive,
    setIsAccountCreationSkipped,
} from "@store";

import { SwitchWrapper } from "@components/Common/SwitchWrapper";
import { ResetModalWrapper } from "@components/Common/ResetModalWrapper";

import { CustomerAccountDragDropWrapper } from "../CustomerAccountDragDropWrapper";

import "../index.css";

export function AccountCreation(): JSX.Element {
    const { t } = useTranslation();

    const {
        project: { template, files: images },
        templates,
        defaultLanguage,
        isResetModalOpened,
    } = useSnapshot(generalConfigStore);

    const [skipStep, setSkipStep] = React.useState<boolean>(
        // eslint-disable-next-line
        (template.content.options as OptionsType).items.filter(
            (item: OptionsItemType) => item.title === "Customer account"
        )[0].accountCreationMethods!.skipped!
    );

    const [accountCreationMethods, setAccountCreationMethods] = React.useState<
        CustomerAccountItemType[]
    >(
        // eslint-disable-next-line
        (template.content.options as OptionsType).items.filter(
            (item: OptionsItemType) => item.title === "Customer account"
        )[0].accountCreationMethods!.items
    );

    const [
        isAccountCreationMethodsActive,
        setIsAccountCreationMethodsActive,
    ] = React.useState<boolean>(
        // eslint-disable-next-line
        (template.content.options as OptionsType).items.filter(
            (item: OptionsItemType) => item.title === "Customer account"
        )[0].accountCreationMethods!.active!
    );
    const [
        localIsResetModalOpened,
        setLocalIsResetModalOpened,
    ] = React.useState<boolean>(isResetModalOpened);

    const initAccountCreationMethods = ((templates.filter(
        (element) => element.id === template.id
    )[0].content.options as OptionsType).items.filter(
        (item: OptionsItemType) => item.title === "Customer account"
    )[0].accountCreationMethods as CustomerAccountType).items;
    const initSkipStep = ((templates.filter(
        (element) => element.id === template.id
    )[0].content.options as OptionsType).items.filter(
        (item: OptionsItemType) => item.title === "Customer account"
    )[0].accountCreationMethods as CustomerAccountType).skipped;
    const initIsAccountCreationMethodsActive = ((templates.filter(
        (element) => element.id === template.id
    )[0].content.options as OptionsType).items.filter(
        (item: OptionsItemType) => item.title === "Customer account"
    )[0].accountCreationMethods as CustomerAccountType).active as boolean;

    function handleResetOnClickEvent() {
        setIsAccountCreationMethodsOptionActive(
            initIsAccountCreationMethodsActive
        );
        setIsAccountCreationMethodsActive(initIsAccountCreationMethodsActive);
        setAccountCreationMethodsOptions(initAccountCreationMethods);
        setAccountCreationMethods(initAccountCreationMethods);

        const imagesArray = [...images];
        accountCreationMethods
            .map((item) => item.id)
            .forEach((id) => {
                const image = imagesArray.find((element) => element.id === id);

                if (image !== undefined) {
                    const imageIndex = imagesArray.indexOf(image);

                    const localContent = image.content as ImageItemLanguagesType;

                    imagesArray[imageIndex] = {
                        ...image,
                        content: {
                            ...localContent,
                            [defaultLanguage.name]: {
                                ...localContent[defaultLanguage.name],
                                path:
                                    localContent[defaultLanguage.name]
                                        .defaultImage,
                            },
                        } as ImageItemLanguagesType,
                    };
                }
            });

        setProjectFiles(imagesArray);
        setImageKey(uid(imagesArray));
        setSkipStep(initSkipStep as boolean);
        setIsAccountCreationSkipped(initSkipStep as boolean);
    }

    return (
        <React.Fragment>
            <div
                className="h-100"
                style={{
                    display: "grid",
                    gridTemplateRows: "0.1fr auto",
                }}
            >
                <div
                    className={classNames("d-grid__clz", {
                        reset__clz:
                            accountCreationMethods.filter((item) => item.active)
                                .length !== 1,
                    })}
                >
                    <div
                        className={classNames({
                            "d-flex justify-content-between cursor__clz":
                                accountCreationMethods.filter(
                                    (item) => item.active
                                ).length === 1,
                        })}
                    >
                        <div
                            className="d-flex align-items-center"
                            style={{ gap: "30px" }}
                        >
                            <span
                                style={{
                                    font:
                                        "normal normal 600 35px/61px Nunito Sans",
                                }}
                            >
                                {t("Account creation")}
                            </span>

                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={isAccountCreationMethodsActive}
                                onChange={() => {
                                    setIsAccountCreationMethodsActive(
                                        !isAccountCreationMethodsActive
                                    );
                                    setIsAccountCreationMethodsOptionActive(
                                        !isAccountCreationMethodsActive
                                    );
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={50}
                                height={20}
                                disabled={true}
                                className="Cmn_icn_proposeStageRoute"
                            />
                        </div>

                        {accountCreationMethods?.filter((item) => item.active)
                            .length === 1 ? (
                            <div
                                className={classNames(
                                    "d-flex align-items-center cursor__clz",
                                    {
                                        "disable-account-creation-items__clz": !isAccountCreationMethodsActive,
                                    }
                                )}
                                style={{ gap: "20px" }}
                            >
                                <div
                                    style={{
                                        font:
                                            "normal normal 600 24px/32px Nunito Sans",
                                    }}
                                >
                                    {t(
                                        "Do not offer this step in the customer ways"
                                    )}
                                </div>
                                <SwitchWrapper
                                    onChange={() => {
                                        setSkipStep(!skipStep);
                                        setIsAccountCreationSkipped(!skipStep);
                                    }}
                                    checked={skipStep}
                                />
                            </div>
                        ) : null}
                    </div>
                    <div className="d-flex align-items-center justify-content-end">
                        <StyledIconButton
                            title={t("Reset to default settings")}
                            outline={true}
                            variant="danger"
                            className="m-0 p-0 disable-account-creation-items__clz"
                            icon="RefreshIcon"
                            style={{ height: "20%" }}
                            onClick={() => {
                                setLocalIsResetModalOpened(
                                    !localIsResetModalOpened
                                );
                            }}
                        >
                            <RefreshIcon
                                height={20}
                                width={20}
                                className="Cmn_btn_resetModifications"
                            />
                        </StyledIconButton>
                    </div>
                </div>

                <div
                    className={classNames(
                        "d-flex justify-content-center align-items-center cursor__clz",
                        {
                            "disable-account-creation-items__clz": !isAccountCreationMethodsActive,
                        }
                    )}
                >
                    <CustomerAccountDragDropWrapper
                        items={accountCreationMethods}
                        setItems={setAccountCreationMethods}
                        setConfig={setAccountCreationMethodsOptions}
                    />
                </div>
            </div>

            <ResetModalWrapper
                handleResetOnClickEvent={handleResetOnClickEvent}
                localIsResetModalOpened={localIsResetModalOpened}
                setLocalIsResetModalOpened={setLocalIsResetModalOpened}
            />
        </React.Fragment>
    );
}
