import { proxy } from "valtio";

type StoreType = {
    designation: string;
    note: string;
    mark: string;
    reference: string;
    ipAddress: string;
    portNumber: string;
    password: string;
    posId: string;
    user: string;
    logDirectory: string;
    isNameExist: boolean;
    inputMarque: boolean;
    isModalOpened: boolean;
    selectMark: boolean;
    inputPort: boolean;
    inputPosId: boolean;
    inputUser: boolean;
    inputPassword: boolean;
    isValidateClicked: boolean;
    inputDesignation: boolean;
    inputIp: boolean;
    emptyIp: boolean;
    ipExist: boolean;
};

export const store = proxy<StoreType>({
    user: "",
    designation: "",
    note: "",
    mark: "",
    reference: "",
    ipAddress: "",
    portNumber: "",
    password: "",
    posId: "",
    logDirectory: "",
    isNameExist: false,
    inputMarque: false,
    isModalOpened: false,
    selectMark: false,
    inputPort: false,
    inputPosId: false,
    inputUser: false,
    inputIp: false,
    inputPassword: false,
    isValidateClicked: false,
    inputDesignation: false,
    emptyIp: false,
    ipExist: false,
});

export function setCashManagementData(data: any, type: string): void {
    switch (type) {
        case "ipExist":
            store.ipExist = data;
            break;
        case "emptyIp":
            store.emptyIp = data;
            break;
        case "designation":
            store.designation = data;
            break;
        case "note":
            store.note = data;
            break;
        case "mark":
            store.mark = data;
            break;
        case "reference":
            store.reference = data;
            break;
        case "ipAddress":
            store.ipAddress = data;
            break;
        case "portNumber":
            store.portNumber = data;
            break;
        case "password":
            store.password = data;
            break;
        case "posId":
            store.posId = data;
            break;
        case "logDirectory":
            store.logDirectory = data;
            break;
        case "isNameExist":
            store.isNameExist = data;
            break;
        case "inputMarque":
            store.inputMarque = data;
            break;
        case "isModalOpened":
            store.isModalOpened = data;
            break;
        case "selectMark":
            store.selectMark = data;
            break;
        case "inputPort":
            store.inputPort = data;
            break;
        case "inputPosId":
            store.inputPosId = data;
            break;
        case "inputUser":
            store.inputUser = data;
            break;
        case "inputIp":
            store.inputIp = data;
            break;
        case "inputPassword":
            store.inputPassword = data;
            break;
        case "isValidateClicked":
            store.isValidateClicked = data;
            break;
        case "user":
            store.user = data;
            break;
        case "inputDesignation":
            store.inputDesignation = data;
            break;
    }
}
export function resetCashManagementData(): void {
    store.user = "";
    store.designation = "";
    store.note = "";
    store.mark = "";
    store.reference = "";
    store.ipAddress = "";
    store.portNumber = "";
    store.password = "";
    store.posId = "";
    store.logDirectory = "";
    store.isNameExist = false;
    store.inputMarque = false;
    store.isModalOpened = false;
    store.selectMark = false;
    store.inputPort = false;
    store.inputPosId = false;
    store.inputUser = false;
    store.inputIp = false;
    store.inputPassword = false;
    store.isValidateClicked = false;
    store.inputDesignation = false;
}
export default store;
