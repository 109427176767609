import React from "react";
import { useSnapshot } from "valtio";

import { designStore, generalConfigStore } from "@store";

import { getTopBannerImagePath } from "@helpers/general";

type PropsType = {
    children: React.ReactNode;
    selectedSubStep?: ProjectMainContentItemType;
};

export function PreviewTopBannerBackgroundWrapper({
    children,
}: PropsType): JSX.Element {
    const {
        project: { files: images },
    } = useSnapshot(generalConfigStore);
    const { activePageName } = useSnapshot(designStore);

    const topBannerBackgroundImageSrc = getTopBannerImagePath(
        activePageName,
        images
    );

    const possibleExtensions = ["mp4", "mov", "avi", "flv", "wmv"];

    const extension =
        topBannerBackgroundImageSrc.split(".")[
            topBannerBackgroundImageSrc.split(".").length - 1
        ];

    return (
        <React.Fragment>
            {possibleExtensions.includes(extension) === true ? (
                <React.Fragment>
                    <video
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        style={{
                            height: "110px",
                            width: "430px",
                            objectFit: "cover",
                            borderRadius: "2px",
                            position: "fixed",
                            zIndex: 0,
                        }}
                        src={topBannerBackgroundImageSrc}
                    />
                    {children}
                </React.Fragment>
            ) : (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                        backgroundImage: `url(${topBannerBackgroundImageSrc})`,
                        width: "430px",
                        backgroundSize: "cover",
                        height: "110px",
                        borderRadius: "2px",
                    }}
                >
                    {children}
                </div>
            )}
        </React.Fragment>
    );
}
