import React from "react";
import { useTranslation } from "react-i18next";
import { StyledH2, StyledLabel } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useSnapshot } from "valtio";

import CardFooterCreatedCoping from "./CardFooterCreatedCoping";

import {
    store,
    setIsCreatedCoping,
} from "../../../store/project";

import { capitalize } from "@helpers/general";

type ModalCreatedCopingType = {
    rowData: any;
    setIsDataUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    shopId: string;
    dataProject: any;
};

export default function ModalCreatedCoping({
    rowData,
    setIsDataUpdated,
    shopId,
    dataProject,
}: ModalCreatedCopingType) {
    const { t } = useTranslation();
    const { isCreatedCoping } = useSnapshot(store);
    return (
        <React.Fragment>
            <Modal centered isOpen={isCreatedCoping}>
                <ModalHeader
                    toggle={() => {
                        setIsCreatedCoping!(!{ isCreatedCoping });
                    }}
                >
                    <StyledH2>{capitalize(t("Project duplication"))}</StyledH2>
                </ModalHeader>
                <ModalBody>
                    <StyledLabel
                        className="pl-3 m-0"
                        style={{ font: "normal normal 400 18px/30px Segoe UI" }}
                    >
                        {`${t(
                            "Do you want to confirm the duplication of the project"
                        )} ''${rowData.Désignation}''`} ?
                    </StyledLabel>
                </ModalBody>
                <CardFooterCreatedCoping
                    rowData={rowData}
                    setIsDataUpdated={setIsDataUpdated}
                    shopId={shopId}
                    dataProject={dataProject}
                />
            </Modal>
        </React.Fragment>
    );
}
