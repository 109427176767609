import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import {
    CrossIcon,
    StyledLabel,
    StyledTextInput,
    CheckboxIcon,
    StyledSelectInput,
} from "@aureskonnect/react-ui";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { mutate } from "swr";
import { toast } from "react-toastify";
import classnames from "classnames";
import { useSnapshot } from "valtio";

import { store } from "@components/VerticalLayout/store";
import {
    store as saleStore,
    setNumberActive,
    setIsArchived,
    setEditedDataModeOfSale,
    setIsActionsClicked,
    setActions,
    setIsEdited,
} from "../store";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";

import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import {
    CustomCardBody,
    CustomCardFooter,
    CustomCardHeader,
} from "@components/Common/CustomCardStyled";
import MultiCustomCreatableSelection from "@components/Common/MultiCustomCreatableSelection";
import MultiCustomSelect from "@components/Common/MultiCustomSelect";

import { ConfirmationModificationMessage } from "./ConfirmationModificationMessage";
import { ConfirmationAssociationModal } from "./ConfirmationAssociationModal";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

type ModeOfSaleFormType = {
    setIsAddModeOfSaleClicked: Function;
    isAddModeOfSaleClicked: boolean;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<boolean | number>>;
    dataSale: React.SetStateAction<any>;
    isAddNewModeOfSaleButtonClicked: boolean;
    setDataSale: Function;
    setLocalFilterActive: Function;
    inputSaleMode: boolean;
    setInputSaleMode: Function;
    setSelectSales: Function;
    selectSales: boolean;
    selectName: boolean;
    setSelectName: Function;
    setLimit: Function;
};

export default function SaleForm({
    setIsAddModeOfSaleClicked,
    isAddModeOfSaleClicked,
    setDataIsUpdated,
    dataSale,
    isAddNewModeOfSaleButtonClicked,
    setDataSale,
    setLocalFilterActive,
    inputSaleMode,
    setInputSaleMode,
    selectSales,
    setSelectSales,
    selectName,
    setSelectName,
    setLimit,
}: ModeOfSaleFormType) {
    const { t } = useTranslation();

    const { userID, franchiseID, shopID, oneShop, operatorID } = useSnapshot(
        store
    );
    const {
        numberActive,
        shopsModeSaleData,
        modeSaleData,
        modeOfSaleFranchise,
        dataArchive,
        dataFranchiseArchive,
        isActionsClicked,
        isEdited,
    } = useSnapshot(saleStore);
    const [saleMode, setSaleMode] = React.useState<string>("");
    const [tag, setTag] = React.useState<string>("");
    const [title, setTitle] = React.useState<string>("");
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const shopId = oneShop ? shopID : 0;

    const [tags, setTags] = React.useState<any[]>([]);
    const [salesMode, setSalesMode] = React.useState<any[]>([]);
    const [isNameExist, setIsNameExist] = React.useState<boolean>(false);
    const [associated, setAssociated] = React.useState<any[]>([]);
    const [isModalModifiedOpened, setIsModalModifiedOpened] = React.useState<
        boolean
    >(false);
    const [tagsData, setTagsData] = React.useState<any>([]);
    const pathname = window.location.pathname.toString();
    const [isSendingTag, setIsSendingTag] = React.useState<boolean>(false);
    const [displayName, setDisplayName] = React.useState<string>("");
    const [salesSupport, setSalesSupport] = React.useState<any>([]);
    const [salesSupportData, setSalesSupportData] = React.useState<any>([]);

    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState<boolean>(false);

    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    function checkIfModeOfSaleExist() {
        let isModeOfSaleExist = false;
        if (modeSaleData.data !== undefined && dataArchive !== undefined) {
            let allData: any[] = [...modeSaleData.data, ...dataArchive];
            allData.forEach((el: any) => {
                if (el["Mode of sale"] === saleMode) isModeOfSaleExist = true;
                return;
            });
        }

        return isModeOfSaleExist;
    }

    function checkIfModeOfSaleFranchiseExist() {
        let isModeOfSaleExist = false;
        if (modeOfSaleFranchise.othersData !== undefined) {
            if (
                modeOfSaleFranchise.othersData.modeOfSaleFranchise !== undefined
            ) {
                modeOfSaleFranchise.othersData.modeOfSaleFranchise.forEach(
                    (el: any) => {
                        if (el["Mode of sale"] === saleMode)
                            isModeOfSaleExist = true;
                        return;
                    }
                );
            }
        }
        return isModeOfSaleExist;
    }

    function checkIfModeOfSaleFranchiseExistAndArchived() {
        let isModeOfSaleExist = false;
        if (dataFranchiseArchive !== undefined)
            dataFranchiseArchive.forEach((el: any) => {
                if (el["Mode of sale"] === saleMode) isModeOfSaleExist = true;
                return;
            });

        return isModeOfSaleExist;
    }

    function isInclude(element: any, array: any[]) {
        return array.includes(element);
    }

    async function addModeOfSale() {
        let isModeOfSaleFranchiseExist = checkIfModeOfSaleFranchiseExist();
        let isModeOfSaleFranchiseExistAndArchived = checkIfModeOfSaleFranchiseExistAndArchived();
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`;
        let id = salesMode.find((elt: any) => elt.label === saleMode).value;

        let data = [
            {
                userId: userID,
                shopId: oneShop ? [shopId, 0] : [shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,

                data: {
                    [id]: {
                        designation: saleMode,
                        tag: tagsData,
                        display_name: displayName,
                        support_vente: salesSupport,
                        isArchived: false,
                        isFavorite: false,
                        state: false,
                        shopId: shopId,
                    },
                },
            },
        ];

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        if (isModeOfSaleFranchiseExist && oneShop) {
            setIsModalModifiedOpened(true);
            setIsArchived(false);
        } else if (isModeOfSaleFranchiseExistAndArchived && oneShop) {
            setIsModalModifiedOpened(true);
            setIsArchived(true);
        } else {
            try {
                mutate(
                    apiUrlAdd,
                    await fetch(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            toast.success(
                                `${t("Sale mode is successfully added")!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );

                            setIsSendingTag(true);
                            setDataSale([]);
                            setIsEdited(false);
                            setLocalFilterActive(false);
                            setNumberActive(numberActive + 1);
                            setTagsData([]);
                            setDataIsUpdated!(true);
                            handleGetSalesModesTypesAndTagsButtonOnClickEvent();
                            clearMultiCustomSelectionData();
                            clearData();
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    async function editModeOfSale() {
        let dataAssociate: any[] = [];
        toast.dismiss();
        const apiUrlUpdate = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale`;
        let dataConfig = [
            {
                userId: userID,
                shopId: [shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                data: {
                    [dataSale.Id]: {
                        designation: saleMode,
                        tag: tagsData,
                        display_name: displayName,
                        support_vente: salesSupport,
                        isArchived: dataSale.isArchived,
                        state: dataSale.state,
                        isFavorite: dataSale.isFavorite,
                        shopId: shopId,
                    },
                },
            },
        ];
        setEditedDataModeOfSale(dataConfig[0]);
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataConfig),
        };

        try {
            mutate(
                apiUrlUpdate,
                await fetch(apiUrlUpdate, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        if (!oneShop) {
                            dataAssociate = associateModeOfSale();
                        }
                        toast.success(
                            `${t(
                                dataAssociate.length > 0
                                    ? "Franchise sale mode has been changed successfully"
                                    : "The sales method is changed successfully"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );

                        if (dataAssociate.length > 0) {
                            setIsModalOpen(true);
                        } else {
                            setIsSendingTag(true);
                            setIsAddModeOfSaleClicked(!isAddModeOfSaleClicked);
                            setIsEdited(false);
                            clearMultiCustomSelectionData();
                            clearData();
                            setTagsData([]);
                        }

                        setDataIsUpdated!(true);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function handleGetSalesModesTypesAndTagsButtonOnClickEvent() {
        const newList: any[] = [];
        const newListSaleMode: any[] = [];
        const newListSaleSupport: any[] = [];

        await Promise.all([
            fetch(
                `${
                    process.env.REACT_APP_API_V2_URL
                }/settings/general/tag?userId=${userID}&shopId=${"0"}&franchiseId=${franchiseID}`
            ).then((value) => value.json()),
            fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/generalList?userId=${userID}&franchiseId=${franchiseID}&type=mode_of_sale_list`
            ).then((value) => value.json()),
            fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=activated`,
                {
                    method: "GET",
                    headers: { "Accept-Language": i18nextLng },
                }
            ).then((value) => value.json()),
        ]).then(
            (result) => {
                for (let element of result[0].data) {
                    newList.push(element.Designation);
                }
                for (let element of result[1].data) {
                    newListSaleMode.push({
                        value: element.Id,
                        label: element.Designation,
                    });
                }

                for (let element of result[2].data) {
                    newListSaleSupport.push(element[t("Sale support")]);
                }

                setTags(newList);
                setSalesMode(newListSaleMode);
                setSalesSupportData(newListSaleSupport);
            },

            (error) => {
                console.log(error);
            }
        );
    }

    const formatCreateLabel = (inputValue: any) => (
        <span>
            {inputValue} ( {t("New tag")} )
        </span>
    );

    const formatCreateLabelModeOfSale = (inputValue: any) => (
        <span>
            {inputValue} ( {t("New sales method")} )
        </span>
    );

    function associateModeOfSale() {
        let idsAssociated: any = [];
        let associated: any[] = [];
        if (shopsModeSaleData.data !== undefined) {
            shopsModeSaleData.data.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    if (el.Id === dataSale.Id) {
                        idsAssociated.push(el.shopId);
                        associated.push(el);
                    }
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return associated;
    }

    useEffect(() => {
        if (dataSale.Id) {
            setTitle(t("Modify a sales method"));
            setSaleMode(dataSale["Mode of sale"]);
            setTagsData(dataSale.tag);
            setDisplayName(dataSale.display_name);
            setSalesSupport(dataSale.support_vente);
        } else {
            setSaleMode("");
            setTitle(t("Add a sales method"));
            setTag("");
            setIsNameExist(false);
            setDisplayName("");
            setSalesSupport("");
            setSelectName(false);
            setInputSaleMode(false);
            setSelectSales(false);
            setTagsData([]);
            setSalesSupport([]);
        }

        setAssociated(associateModeOfSale);

        // eslint-disable-next-line
    }, [dataSale, t, isAddNewModeOfSaleButtonClicked]);

    useEffect(() => {
        handleGetSalesModesTypesAndTagsButtonOnClickEvent();

        // eslint-disable-next-line
    }, [tag, saleMode]);
    useEffect(() => {
        clearData();
        clearMultiCustomSelectionData();
    }, [isAddModeOfSaleClicked, dataSale.Id]);

    useEffect(() => {
        let isModeOfSaleExist = checkIfModeOfSaleExist();
        if (
            isModeOfSaleExist &&
            (dataSale.Id === undefined ||
                (dataSale.Id !== undefined &&
                    dataSale["Mode of sale"] !== saleMode))
        ) {
            setIsNameExist(true);
        } else {
            setIsNameExist(false);
        }
        // eslint-disable-next-line
    }, [saleMode, dataSale, t]);

    useEffect(() => {
        if (dataSale.Id === undefined) {
            setDisplayName(saleMode);
        }
    }, [saleMode, dataSale]);

    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader className="d-flex align-items-center justify-content-between">
                    <h5
                        className={`${classnames("pt-3", {
                            mdv_txt_FReditSalesMode: dataSale.Id !== undefined,
                            mdv_txt_FRtitleFormModeOfSale:
                                dataSale.Id === undefined,
                        })}`}
                    >
                        {title}
                    </h5>
                    <CrossIcon
                        className="mdv_icn_FRcloseForm"
                        height={13}
                        width={13}
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setDataSale([]);
                                    setIsAddModeOfSaleClicked!(false);
                                    setIsEdited(false);
                                    clearMultiCustomSelectionData();
                                    clearData();
                                    setInputSaleMode(false);
                                    setDataIsUpdated!(true);
                                });
                            } else {
                                setDataSale([]);
                                setIsAddModeOfSaleClicked!(false);
                                setIsEdited(false);
                                clearMultiCustomSelectionData();
                                clearData();
                                setInputSaleMode(false);
                                setDataIsUpdated!(true);
                            }
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className="pl-3 pr-5"
                    style={{
                        maxHeight:
                            pathname === "/peripherals/Choice"
                                ? "530px"
                                : "600px",
                        overflowY: " scroll",
                    }}
                >
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Sales method")}
                        </StyledLabel>

                        <StyledSelectInput
                            options={salesMode}
                            placeholder={t("Select")}
                            className={classnames("mdv_inp_FRmodeOfSale", {
                                invalid__clz: inputSaleMode || isNameExist,
                                "not-allowed-input__clz":
                                    dataSale.Id !== undefined,
                            })}
                            value={
                                saleMode === ""
                                    ? null
                                    : {
                                          label: saleMode,
                                          value: saleMode,
                                      }
                            }
                            onChange={(e: any) => {
                                setSaleMode(e === null ? "" : e.label.trim());
                                setIsEdited(true);
                                setInputSaleMode(false);
                                setIsNameExist(false);
                                setSelectName(false);
                            }}
                            isClearable
                            noOptionsMessage={() => t("No options")}
                            formatCreateLabel={formatCreateLabelModeOfSale}
                            autoFocus={dataSale.Id === undefined}
                        />
                        {inputSaleMode ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a sales method")}
                            </div>
                        ) : null}
                        {isNameExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("This sales method already exists")!}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Display name")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames("mdv_inp_FRdisplayName", {
                                input__clz: selectName,
                            })}
                            autocomplete="off"
                            name="designation"
                            onChange={(e: any) => {
                                setDisplayName(e.target.value.trim());
                                setIsEdited(true);
                                setSelectName(false);
                            }}
                            value={displayName}
                            placeholder={t("Write")}
                            type="text"
                        />
                        <div className="d-flex flex-wrap "></div>
                        {selectName ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a display name")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Sales support")}
                        </StyledLabel>
                        <MultiCustomSelect
                            onDelete={() => {
                                setIsEdited(true);
                            }}
                            result={salesSupport}
                            setResult={setSalesSupport}
                            data={salesSupportData}
                            onChange={(e: any) => {
                                setIsEdited(true);
                                setSelectSales(false);
                            }}
                            className={classnames("mdv_inp_FRsalesSupport", {
                                invalid__clz: selectSales,
                            })}
                            placeholder={`${t("Select")}`}
                            value={t("Choose")}
                            name="sales support"
                        />

                        {selectSales ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a sales support")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel htmlFor="text" className="mt-3">
                            {t("Tag")}
                        </StyledLabel>

                        <MultiCustomCreatableSelection
                            onDelete={() => {
                                setIsEdited(true);
                            }}
                            isSendingTag={isSendingTag}
                            setResult={setTagsData}
                            result={tagsData}
                            data={tags}
                            onChange={(e: any) => {
                                setIsEdited(true);
                            }}
                            onCreateOption={(e: any) => {
                                setIsEdited(true);
                            }}
                            placeholder={`${t("Select")}`}
                            value={t("Choose")}
                            name="tag"
                            noOptionsMessage={() => t("No options")}
                            formatCreateLabel={formatCreateLabel}
                            max-width="100%"
                            className="mdv_inp_FRtags"
                        />
                        <div className="d-flex flex-wrap"></div>
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="mr-2 mdv_btn_FRcancel"
                        variant="light"
                        onClick={() => {
                            if (isEdited === true) {
                                setActions(() => {
                                    setIsAddModeOfSaleClicked(
                                        !isAddModeOfSaleClicked
                                    );
                                    setDataSale([]);
                                    setIsEdited(false);
                                    setDataIsUpdated!(true);
                                    clearData();
                                    clearMultiCustomSelectionData();
                                });
                                setIsActionsClicked(!isActionsClicked);
                            } else {
                                setIsAddModeOfSaleClicked(
                                    !isAddModeOfSaleClicked
                                );
                                setDataSale([]);
                                setIsEdited(false);
                                setDataIsUpdated!(true);
                                clearData();
                                clearMultiCustomSelectionData();
                            }
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        className="mdv_btn_FRvalidate"
                        disabled={isValidateButtonDisabled}
                        onClick={() => {
                            setIsValidateButtonDisabled(true);
                            if (saleMode === "") {
                                setInputSaleMode(true);
                            }
                            if (salesSupport.length === 0) {
                                setSelectSales(true);
                            }
                            if (displayName === "") {
                                setSelectName(true);
                            }
                            if (dataSale.Id) {
                                if (
                                    dataSale["Mode of sale"] === saleMode &&
                                    dataSale.display_name === displayName &&
                                    dataSale.tag.every((el: any) =>
                                        isInclude(el, tagsData)
                                    ) &&
                                    tagsData.every((el: any) =>
                                        isInclude(el, dataSale.tag)
                                    ) &&
                                    dataSale.support_vente.every((el: any) =>
                                        isInclude(el, salesSupport)
                                    ) &&
                                    salesSupport.every((el: any) =>
                                        isInclude(el, dataSale.support_vente)
                                    )
                                ) {
                                    setIsModalOpened(!isModalOpened);
                                } else if (
                                    saleMode !== "" &&
                                    !isNameExist &&
                                    displayName !== "" &&
                                    salesSupport !== "" &&
                                    salesSupport.length !== 0
                                )
                                    editModeOfSale();
                            } else if (
                                saleMode !== "" &&
                                !isNameExist &&
                                displayName !== "" &&
                                salesSupport !== "" &&
                                salesSupport.length !== 0
                            ) {
                                addModeOfSale();
                            }
                            setTimeout(() => {
                                setIsValidateButtonDisabled(false);
                            }, 500);
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
            <ConfirmationEditMessage
                setDataIsUpdated={setDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddModeOfSaleClicked}
                setIsAddClicked={setIsAddModeOfSaleClicked}
                setEditedData={setDataSale}
            />
            {isModalOpen && (
                <ConfirmationModificationMessage
                    isModalOpened={isModalOpen}
                    setIsModalOpened={setIsModalOpen}
                    setIsEdited={setIsEdited}
                    setDataIsUpdated={setDataIsUpdated}
                    associated={associated}
                    isAddModeOfSaleClicked={isAddModeOfSaleClicked}
                    setIsAddModeOfSaleClicked={setIsAddModeOfSaleClicked}
                    salesSupport={salesSupport}
                    dataSale={dataSale}
                    setLimit={setLimit}
                />
            )}
            {isModalModifiedOpened && (
                <ConfirmationAssociationModal
                    isModalOpened={isModalModifiedOpened}
                    setIsModalOpened={setIsModalModifiedOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    data={{
                        saleMode: saleMode,
                        displayName: displayName,
                        salesSupport: salesSupport,
                        tag: tagsData,
                    }}
                    setDataSale={setDataSale}
                    setTagsData={setTagsData}
                />
            )}
        </React.Fragment>
    );
}
