import React from "react";
export function LoadingSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
       <svg xmlns="http://purl.org/dc/elements/1.1/"    version="1.1" height="50" width="50" viewBox="4 4 26 27" {...props}><metadata id="metadata14"/><defs id="defs12"/>
    
    <g id="_01_align_center" data-name="01 align center">
        <path  id="path3" d="m 17.764,25.86 1.141,-2.113 c 0.262,-0.486 0.081,-1.093 -0.405,-1.355 -0.486,-0.262 -1.093,-0.081 -1.355,0.405 l -1.981,3.669 c -0.304523,0.552542 -0.165498,0.969172 0.131,1.258 l 3,2.985 c 0.391,0.389 1.025,0.388 1.414,-0.004 0.389,-0.391 0.388,-1.025 -0.004,-1.414 L 18.217,27.81 C 23.783,26.769 28,21.881 28,16.015 28,9.974 23.526,4.97 17.713,4.137 17.167,4.059 16.66,4.439 16.582,4.985 c -0.079,0.546 0.301,1.053 0.848,1.132 4.843,0.694 8.57,4.864 8.57,9.898 0,4.917 -3.556,9.01 -8.236,9.845 z"/>
        <path  id="path5" d="M 11.459,24.925 C 10.916,24.648 10.403,24.323 9.923,23.955 9.485,23.62 8.857,23.702 8.521,24.14 c -0.335,0.438 -0.253,1.067 0.185,1.402 0.576,0.441 1.192,0.832 1.842,1.164 0.491,0.251 1.094,0.056 1.346,-0.435 0.251,-0.491 0.056,-1.094 -0.435,-1.346 z M 7.494,21.273 C 7.177,20.761 6.904,20.218 6.682,19.65 6.481,19.136 5.901,18.882 5.387,19.083 c -0.514,0.201 -0.769,0.781 -0.568,1.295 0.267,0.682 0.594,1.334 0.975,1.949 0.291,0.469 0.908,0.614 1.377,0.323 0.469,-0.291 0.614,-0.908 0.323,-1.377 z M 6,16.099 C 5.9889984,15.469022 6.0837619,14.824912 6.149,14.289 6.243,13.745 5.879,13.227 5.335,13.132 4.791,13.037 4.273,13.402 4.178,13.945 4.0435829,14.715053 4.0248334,15.440017 4,16.116 4.005,16.668 4.457,17.112 5.009,17.108 5.561,17.103 6.005,16.651 6,16.099 Z M 7.407,10.901 C 7.719,10.379 8.077,9.887 8.475,9.432 8.839,9.017 8.797,8.384 8.382,8.021 7.967,7.657 7.334,7.699 6.971,8.114 6.493,8.66 6.064,9.249 5.69,9.876 5.407,10.349 5.562,10.964 6.036,11.247 6.509,11.53 7.124,11.375 7.407,10.901 Z M 11.31,7.183 C 11.84,6.9 12.4,6.664 12.982,6.479 13.508,6.313 13.8,5.75 13.634,5.224 13.467,4.698 12.905,4.406 12.379,4.572 11.679,4.794 11.007,5.078 10.369,5.418 9.882,5.677 9.697,6.283 9.957,6.77 c 0.259,0.487 0.865,0.672 1.353,0.413 z"/>
    </g>
</svg>
    );
}
