import React from "react";
import { useTranslation } from "react-i18next";
import { CheckboxIcon } from "@aureskonnect/react-ui";
import { ModalFooter } from "reactstrap";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";
import { mutate } from "swr";

import { store, setIsModesModalDisassociate } from "../../../store/project";

import { store as userStore } from "@components/VerticalLayout/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

type ModalPublishType = {
    rowData: any;
    setIsDataUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
};

export default function CardFooterModalPublichProject({
    rowData,
    setIsDataUpdated,
}: ModalPublishType) {
    const { t } = useTranslation();

    const { isModesModalDisassociate } = useSnapshot(store);
    const { userID, franchiseID, operatorID } = useSnapshot(userStore);

    async function handleSaveButtonOnClickEvent() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/projects/delete`;
        let localData: any = [];
        let savedData = {
            userId: userID,
            franchiseId: franchiseID,
            shopId: rowData.id_boutique,
            projectId: "PROJECT",
            operatorId: operatorID,
            project_id: rowData.id_project,
        };
        localData.push(
            fetch(apiUrl, {
                method: "delete",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(savedData),
            }).then((response) => response.json())
        );

        try {
            mutate(
                apiUrl,
                await Promise.all(localData).then((result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                        dissociationShops();
                        setIsDataUpdated(true);
                    } else {
                        throw Error(result.message);
                    }
                })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                icon: true,
            });
        }
    }
    async function dissociationShops() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/projects/dissociation/shops`;

        let savedData = {
            userId: userID,
            franchiseId: franchiseID,
            project_id: "PROJECT",
            projectId: rowData.id_project,
            shopsKey: rowData.id_boutique,
            operatorId: operatorID,
        };

        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(savedData),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                        toast.success(
                            `${t(
                                "The dissociation was successfully completed."
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );

                        setIsDataUpdated(true);
                    })
            );
        } catch (e: any) {
            console.log(`${t("There's an error")}!`);
        }
    }

    return (
        <ModalFooter className="border-top-0">
            <CustomMainColorButton
                outline
                variant="primary"
                className="cmn_btn_cancelAlert"
                onClick={() => {
                    setIsModesModalDisassociate!(!{ isModesModalDisassociate });
                    setIsDataUpdated(true);
                }}
                rounded
            >
                {t("Cancel")}
            </CustomMainColorButton>
            <CustomMainColorButton
                rounded
                className="mr-2 cmn_btn_validateAlert"
                variant="primary"
                onClick={() => {
                    handleSaveButtonOnClickEvent();

                    setIsModesModalDisassociate(!isModesModalDisassociate);
                }}
            >
                {t("to confirm")}
            </CustomMainColorButton>
        </ModalFooter>
    );
}
