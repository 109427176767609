import { HeartIcon } from "@aureskonnect/react-ui";
import React from "react";
import { useSnapshot } from "valtio";
import { Tooltip } from "@mui/material";

import { setSalesSupportFavored, store } from "../store";
import { useTranslation } from "react-i18next";

type HeartIconComponentType = {
    element: any;
};

export function HeartIconComponent({ element }: HeartIconComponentType) {
    const { salesSupportFavored } = useSnapshot(store);
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Tooltip
                title={
                    salesSupportFavored.find(
                        (el: any) => el.id === element.selectedRow.Id
                    ) !== undefined &&
                    salesSupportFavored.find(
                        (el: any) => el.id === element.selectedRow.Id
                    ).status
                        ? t("Disable favorite state")
                        : t("Enable favorite state")
                }
            >
                <div>
                    <HeartIcon
                        onClick={() => {
                            setSalesSupportFavored({
                                id: element.selectedRow.Id,
                                status:
                                    salesSupportFavored.find(
                                        (el: any) =>
                                            el.id === element.selectedRow.Id
                                    ) !== undefined &&
                                    !salesSupportFavored.find(
                                        (el: any) =>
                                            el.id === element.selectedRow.Id
                                    ).status,
                            });
                        }}
                        height={25}
                        width={25}
                        name="heart"
                        fill={
                            salesSupportFavored.find(
                                (el: any) => el.id === element.selectedRow.Id
                            ) !== undefined &&
                            salesSupportFavored.find(
                                (el: any) => el.id === element.selectedRow.Id
                            ).status
                                ? "red"
                                : "black"
                        }
                    />
                </div>
            </Tooltip>
        </React.Fragment>
    );
}
