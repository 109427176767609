import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import {
    CheckboxIcon,
    EyeIcon,
    PencilIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";
import ReactSwitch from "react-switch";
import { useSnapshot } from "valtio";
import { mutate } from "swr";
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import ErrorToast from "@components/Common/ErrorTost";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";

import store, {
    setNumberActive,
    setNumberArchived,
    setSelectedServices,
    setIsHistoryModalOpened,
    setIsActionsClicked,
    setIsEdited,
    setActions,
} from "../store";

import { store as useStore } from "../../../../components/VerticalLayout/store";
import { ConfirmationArchive } from "../ConfirmationArchive";

export function CustomIcons({
    selectedRowData,
    setShopId,
    setService,
    setDataIsUpdated,
    setIsModalConfirmationOpened,
    isModalConfirmationOpened,
    setSelectedRows,
    isAddServiceClicked,
    setIsAddServiceClicked,
    dataArchived,
    isServicesArchived,
    selectedRows,
    setLocalFilterActive,
}: any) {
    const {
        numberActive,
        numberArchived,
        shopsServiceData,
        isHistoryModalOpened,
        isActionsClicked,
        isEdited,
        modify,
    } = useSnapshot(store);
    const { franchiseID, userID, oneShop, operatorID } = useSnapshot(useStore);

    const { t } = useTranslation();
    const [choiceState, setServiceState] = React.useState<boolean>(
        selectedRowData.selectedRow?.isActive
    );
    const [isClicked, setIsClicked] = React.useState<boolean>(false);
    const [isArchiveModalOpened, setIsArchiveModalOpened] = React.useState<
        boolean
    >(false);
    let dataArchive: any[] = [];

    if (!oneShop) {
        dataArchive = ArchivedServices();
    } else {
        dataArchive = ArchivedServicesFranchise();
    }

    function ArchivedServicesFranchise() {
        let idsAssociated: any[] = [];
        if (dataArchived?.data !== undefined) {
            Object.values(dataArchived?.data).forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    function ArchivedServices() {
        let idsAssociated: any[] = [];
        if (shopsServiceData !== undefined) {
            shopsServiceData.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    idsAssociated.push(el.Id);
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    async function handleStateChange(dataState: boolean) {
        let id = selectedRowData?.selectedRow.Id;
        let obj = {
            userId: userID,
            shopId: [selectedRowData?.selectedRow.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            data: {
                [id]: {
                    designation: selectedRowData?.selectedRow[t("Designation")],
                    tag: selectedRowData?.selectedRow.tag,
                    shopId: selectedRowData?.selectedRow.shopId,
                    isArchived: selectedRowData?.selectedRow.isArchived,
                    isActive: dataState,
                    heure_début: selectedRowData?.selectedRow[t("Start hour")],
                    heure_fin: selectedRowData?.selectedRow[t("End hour")],
                    activeDays: selectedRowData?.selectedRow?.activeDays,
                    jour_de_la_semaine:
                        selectedRowData?.selectedRow[t("Day of the week")],
                },
            },
        };

        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/services`;
        mutate(
            apiUrl,
            await fetch(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(obj),
                method: "POST",
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }

                    toast.success(
                        !choiceState
                            ? `${t("service has been successfully activated")}`
                            : `${t(
                                  "service has been successfully deactivated"
                              )}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setDataIsUpdated!(true);
                })
                .catch((error: any) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }
    function handleTrashButtonClicked(selectedRow: any) {
        let idsAssociated: any = [];
        shopsServiceData.forEach((el: any) => {
            el.subRows.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        });
        if (idsAssociated.includes(selectedRow.Id) && !oneShop) {
            ErrorToast(
                `${t(
                    "Please note that you cannot archive an item assigned to stores."
                )!}`
            );
            setDataIsUpdated(true);
        } else {
            setIsArchiveModalOpened!(!isArchiveModalOpened);
        }
    }

    async function activeService(selectedRowData: any) {
        setIsClicked(true);
        toast.dismiss();
        let dataModeOfSale = {
            userId: userID,
            shopId: [selectedRowData.selectedRow.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            data: {
                [selectedRowData.selectedRow.Id]: {
                    designation: selectedRowData.selectedRow[t("Designation")],
                    heure_début: selectedRowData.selectedRow[t("Start hour")],
                    heure_fin: selectedRowData.selectedRow[t("End hour")],
                    tag: selectedRowData.selectedRow.tag,
                    activeDays: selectedRowData.selectedRow?.activeDays,
                    jour_de_la_semaine:
                        selectedRowData.selectedRow[t("Day of the week")],
                    isActive: selectedRowData.selectedRow.isActive,
                    isArchived: false,
                },
            },
        };

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/services`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataModeOfSale),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t("The service was successfully unarchived")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated!(true);
                        setNumberActive(numberActive + 1);
                        setNumberArchived(numberArchived - 1);
                        setIsClicked(false);
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <React.Fragment>
            {selectedRowData.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    {modify === true ? (
                        <>
                            <Tooltip
                                title={isServicesArchived ? "" : t("Edit")}
                            >
                                <div>
                                    <PencilIcon
                                        onClick={() => {
                                            if (isEdited === true) {
                                                setIsActionsClicked(
                                                    !isActionsClicked
                                                );
                                                setActions(() => {
                                                    setIsEdited(false);
                                                    setShopId(
                                                        selectedRowData
                                                            .selectedRow.shopId
                                                    );
                                                    setService(
                                                        selectedRowData.selectedRow
                                                    );
                                                    setLocalFilterActive(false);
                                                    if (!isAddServiceClicked) {
                                                        setIsAddServiceClicked(
                                                            !isAddServiceClicked
                                                        );
                                                    }
                                                });
                                            } else {
                                                setShopId(
                                                    selectedRowData.selectedRow
                                                        .shopId
                                                );
                                                setService(
                                                    selectedRowData.selectedRow
                                                );
                                                setLocalFilterActive(false);
                                                if (!isAddServiceClicked) {
                                                    setIsAddServiceClicked(
                                                        !isAddServiceClicked
                                                    );
                                                }
                                            }
                                        }}
                                        height={25}
                                        width={25}
                                        style={{ cursor: "pointer" }}
                                        className={`${classnames(
                                            "pointer__clz cmn_icn_FRedit",
                                            {
                                                "not-allowed-icon__clz": isServicesArchived,
                                            },
                                            oneShop ? "mr-2" : "ml-3"
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                            {oneShop && (
                                <Tooltip
                                    title={
                                        isServicesArchived
                                            ? ""
                                            : choiceState === false
                                            ? t("Activate")
                                            : t("Unactivate")
                                    }
                                >
                                    <div>
                                        <ReactSwitch
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            handleDiameter={26}
                                            offColor="#f7b4b8"
                                            offHandleColor="#E30613"
                                            checked={choiceState}
                                            onChange={async (e: boolean) => {
                                                setServiceState(!choiceState);

                                                handleStateChange(e);
                                            }}
                                            onColor="#c2eddd"
                                            onHandleColor="#34C38F"
                                            width={50}
                                            height={20}
                                            className={`${classnames(
                                                "lgo_icn_BTiconeModifier",
                                                {
                                                    "not-allowed-icon__clz": isServicesArchived,
                                                },
                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                            {!isServicesArchived ? (
                                <Tooltip title={t("Archive")}>
                                    <div>
                                        <TrashIcon
                                            onClick={() => {
                                                if (isEdited === true) {
                                                    setIsActionsClicked(
                                                        !isActionsClicked
                                                    );
                                                    setActions(() => {
                                                        setIsEdited(false);
                                                        setService(
                                                            selectedRowData.selectedRow
                                                        );
                                                        handleTrashButtonClicked(
                                                            selectedRowData.selectedRow
                                                        );
                                                    });
                                                } else {
                                                    setService(
                                                        selectedRowData.selectedRow
                                                    );
                                                    handleTrashButtonClicked(
                                                        selectedRowData.selectedRow
                                                    );
                                                }
                                            }}
                                            height={25}
                                            width={25}
                                            fill="red"
                                            style={{ cursor: "pointer" }}
                                            className={`${classnames(
                                                "pointer__clz cmn_icn_FRarchived",
                                                {
                                                    "not-allowed-icon__clz": isClicked,
                                                },
                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={
                                        dataArchive.includes(
                                            selectedRowData.selectedRow.Id
                                        ) && oneShop
                                            ? ""
                                            : t("Unarchive")
                                    }
                                >
                                    <div>
                                        <EyeIcon
                                            height={25}
                                            width={25}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setSelectedRows(
                                                    selectedRowData
                                                );
                                                if (
                                                    dataArchive.includes(
                                                        selectedRowData
                                                            .selectedRow.Id
                                                    ) &&
                                                    oneShop
                                                ) {
                                                    ErrorToast(
                                                        `${t(
                                                            "Warning, you cannot activate an archived  in franchise"
                                                        )!}`
                                                    );
                                                } else if (
                                                    dataArchive.includes(
                                                        selectedRowData
                                                            .selectedRow.Id
                                                    ) &&
                                                    !oneShop
                                                ) {
                                                    setIsModalConfirmationOpened(
                                                        !isModalConfirmationOpened
                                                    );
                                                } else {
                                                    activeService(
                                                        selectedRowData
                                                    );
                                                }
                                            }}
                                            className={`${classnames(
                                                "pointer__clz cmn_icn_FRunarchive",
                                                {
                                                    "not-allowed-icon__clz":
                                                        (dataArchive.includes(
                                                            selectedRowData
                                                                .selectedRow.Id
                                                        ) &&
                                                            oneShop) ||
                                                        isClicked,
                                                },
                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : null}
                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                className={`${classnames(
                                    "pointer__clz cmn_icn_FRhistory",
                                    oneShop ? "mr-2" : "ml-3"
                                )}`}
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(() => {
                                            setIsEdited(false);
                                            setIsHistoryModalOpened(
                                                !isHistoryModalOpened
                                            );

                                            setSelectedServices([
                                                {
                                                    [selectedRowData.selectedRow
                                                        .shopId]:
                                                        selectedRowData
                                                            .selectedRow.Id,
                                                },
                                            ]);
                                        });
                                    } else {
                                        setIsHistoryModalOpened(
                                            !isHistoryModalOpened
                                        );

                                        setSelectedServices([
                                            {
                                                [selectedRowData.selectedRow
                                                    .shopId]:
                                                    selectedRowData.selectedRow
                                                        .Id,
                                            },
                                        ]);
                                    }
                                }}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
            {isArchiveModalOpened ? (
                <ConfirmationArchive
                    isModalOpened={isArchiveModalOpened}
                    setIsModalOpened={setIsArchiveModalOpened}
                    e={selectedRowData}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                />
            ) : null}
        </React.Fragment>
    );
}
