import React from "react";
import { StyledH2, StyledLabel } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { capitalize } from "@helpers/general";

import "./index.css";

type LogPathsModalType = {
    setIsAlertLogModalOpened: Function;
    isAlertLogModalOpened: boolean;
    rowData: any;
};

export default function AlertLog({
    setIsAlertLogModalOpened,
    isAlertLogModalOpened,
    rowData,
}: LogPathsModalType) {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Modal
                size="xl"
                isOpen={isAlertLogModalOpened}
                style={{
                    marginTop: "12%",
                    marginLeft: "30%",
                    width: "942px",
                    height: "517px",
                }}
            >
                <ModalHeader
                    toggle={() =>
                        setIsAlertLogModalOpened!(!isAlertLogModalOpened)
                    }
                >
                    <StyledH2>
                        {capitalize(t("Coin mechanism log alert"))}{" "}
                        {rowData.Application === ""
                            ? `${rowData.Périphérique} - ${rowData.appId}`
                            : `${
                                  rowData.peripheral === undefined
                                      ? ""
                                      : rowData.peripheral
                              } / ${rowData.Application}`}
                    </StyledH2>
                </ModalHeader>

                <ModalBody style={{ height: "500px" }}>
                    <StyledLabel
                        style={{
                            marginTop: "100px",
                            marginLeft: "200px",
                            fontSize: "21px",
                            textAlign: "center",
                        }}
                    >
                        {t("Coin-operated log is being downloaded")}
                        <br />
                        <br />
                        {t("Please wait a few minutes")} ...
                    </StyledLabel>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}
