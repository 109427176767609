import React from "react";

import { EyeIcon, PencilIcon, TrashIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";

import store, { setIsActionsClicked, setIsEdited, setActions } from "./store";
import { store as userStore } from "@components/VerticalLayout/store";

import {
    verifyIfPeripheralAssociate,
    verifyIfPeripheralAssociateApplicationKds,
} from "../../../helpers/peripheralsHelpers";
import classnames from "classnames";
import ErrorToast from "@components/Common/ErrorTost";
import { t } from "i18next";
import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import ConsultSvgIcon from "@components/Common/SvgIcons/ConsultSvgIcon";
import { Tooltip } from "@mui/material";

export function CustomIconsComponent({
    e,
    setShopId,
    handleTestBeeperButtonOnClickEvent,
    setEditedData,
    setIsModalOpened,
    setBeeperId,
    isModalOpened,
    isModalDeleteAssociatePeripheralOpened,
    setIsModalDeleteAssociatePeripheralOpened,
    setInputIp,
    setIsConfirmationEditRunning,
    typeDisplay,
    setIsModalOpenedHistory,
    isModalOpenedHistory,
    setSelectedShopId,
    setIsConsult,
    setIsAddBeeperClicked,
    isAddBeeperClicked,
}: any) {
    const {
        isUpdateOperation,
        isActionsClicked,
        isEdited,
        modify,
    } = useSnapshot(store);
    const { userID, franchiseID } = useSnapshot(userStore);
    const appId = "KIOSK";
    const idApp = "KDS";
    let ifPeripheralAssociateObject: {
        isPeripheralAssociate: boolean;
        appId_children: any[];
    };
    let ifPeripheralAssociateApplicationKds: {
        isPeripheralAssociate: boolean;
        appId_children: any[];
    };
    return (
        <React.Fragment>
            {e.selectedRow.subRows === undefined ? (
                <div className="d-flex justify-content-around ">
                    {modify === true ? (
                        <>
                            <div className="p-1">
                                {typeDisplay === "notArchived" ? (
                                    <Tooltip title={`${t("Edit")}`}>
                                        <div>
                                            <PencilIcon
                                                className={` ${classnames(
                                                    "cmn_icn_FRedit",
                                                    {
                                                        "not-allowed-icon__clz":
                                                            typeDisplay ===
                                                            "archived",
                                                    }
                                                )}`}
                                                height={25}
                                                width={25}
                                                onClick={() => {
                                                    if (isEdited === true) {
                                                        setIsActionsClicked(
                                                            true
                                                        );
                                                        setActions(() => {
                                                            setIsEdited(false);
                                                            setEditedData(
                                                                e.selectedRow
                                                            );
                                                            setShopId(
                                                                e.selectedRow
                                                                    .shopId
                                                            );
                                                            setInputIp(false);
                                                            setIsAddBeeperClicked(
                                                                true
                                                            );
                                                        });
                                                    } else {
                                                        setEditedData(
                                                            e.selectedRow
                                                        );
                                                        setShopId(
                                                            e.selectedRow.shopId
                                                        );
                                                        setInputIp(false);
                                                        setIsAddBeeperClicked(
                                                            true
                                                        );
                                                    }
                                                }}
                                                style={{ cursor: "pointer" }}
                                            />
                                        </div>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title={t("To consult").toString()}>
                                        <div>
                                            <ConsultSvgIcon
                                                className="cmn_icn_toConsult"
                                                height={35}
                                                width={35}
                                                onClick={() => {
                                                    setEditedData(
                                                        e.selectedRow
                                                    );
                                                    setIsConsult(true);
                                                    setShopId(
                                                        e.selectedRow.shopId
                                                    );
                                                    setInputIp(false);
                                                    setIsAddBeeperClicked(true);
                                                }}
                                                style={{
                                                    cursor: "pointer",
                                                    position: "relative",
                                                    bottom: "5px",
                                                }}
                                            />
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                            <div className="p-1">
                                {typeDisplay === "notArchived" ? (
                                    <Tooltip title={t("Archive").toString()}>
                                        <div>
                                            <TrashIcon
                                                className="cmn_icn_FRarchived"
                                                onClick={async () => {
                                                    if (isEdited === true) {
                                                        setIsActionsClicked(
                                                            !isActionsClicked
                                                        );
                                                        setActions(async () => {
                                                            setIsEdited(false);
                                                            setIsAddBeeperClicked(
                                                                false
                                                            );
                                                            if (
                                                                isUpdateOperation ===
                                                                    true &&
                                                                isAddBeeperClicked ===
                                                                    true
                                                            ) {
                                                                setIsConfirmationEditRunning(
                                                                    true
                                                                );
                                                            } else {
                                                                setShopId(
                                                                    e
                                                                        .selectedRow
                                                                        .shopId
                                                                );
                                                                ifPeripheralAssociateObject = await verifyIfPeripheralAssociate(
                                                                    e
                                                                        .selectedRow
                                                                        .Id,
                                                                    e
                                                                        .selectedRow
                                                                        .shopId,
                                                                    franchiseID,
                                                                    userID,
                                                                    appId
                                                                );
                                                                ifPeripheralAssociateApplicationKds = await verifyIfPeripheralAssociateApplicationKds(
                                                                    e
                                                                        .selectedRow
                                                                        .Id,
                                                                    e
                                                                        .selectedRow
                                                                        .shopId,
                                                                    franchiseID,
                                                                    userID,
                                                                    idApp
                                                                );

                                                                if (
                                                                    ifPeripheralAssociateObject.isPeripheralAssociate ||
                                                                    ifPeripheralAssociateApplicationKds.isPeripheralAssociate
                                                                ) {
                                                                    ErrorToast(
                                                                        t(
                                                                            "Please note that you cannot archive a device associated with an application."
                                                                        )
                                                                    );
                                                                } else {
                                                                    setIsModalOpened(
                                                                        !isModalOpened
                                                                    );
                                                                }

                                                                setBeeperId(
                                                                    e
                                                                        .selectedRow
                                                                        .Id
                                                                );
                                                            }
                                                        });
                                                    } else {
                                                        if (
                                                            isUpdateOperation ===
                                                                true &&
                                                            isAddBeeperClicked ===
                                                                true
                                                        ) {
                                                            setIsConfirmationEditRunning(
                                                                true
                                                            );
                                                        } else {
                                                            setShopId(
                                                                e.selectedRow
                                                                    .shopId
                                                            );
                                                            ifPeripheralAssociateObject = await verifyIfPeripheralAssociate(
                                                                e.selectedRow
                                                                    .Id,
                                                                e.selectedRow
                                                                    .shopId,
                                                                franchiseID,
                                                                userID,
                                                                appId
                                                            );
                                                            ifPeripheralAssociateApplicationKds = await verifyIfPeripheralAssociateApplicationKds(
                                                                e.selectedRow
                                                                    .Id,
                                                                e.selectedRow
                                                                    .shopId,
                                                                franchiseID,
                                                                userID,
                                                                idApp
                                                            );
                                                            if (
                                                                ifPeripheralAssociateObject.isPeripheralAssociate ||
                                                                ifPeripheralAssociateApplicationKds.isPeripheralAssociate
                                                            ) {
                                                                ErrorToast(
                                                                    t(
                                                                        "Please note that you cannot archive a device associated with an application."
                                                                    )
                                                                );
                                                            } else {
                                                                setIsModalOpened(
                                                                    !isModalOpened
                                                                );
                                                            }

                                                            setBeeperId(
                                                                e.selectedRow.Id
                                                            );
                                                        }
                                                    }
                                                }}
                                                style={{ cursor: "pointer" }}
                                                height={25}
                                                width={25}
                                                fill="red"
                                            />
                                        </div>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title={t("Unarchive").toString()}>
                                        <div>
                                            <EyeIcon
                                                className="cmn_icn_unarchive"
                                                onClick={async () => {
                                                    if (
                                                        isUpdateOperation ===
                                                            true &&
                                                        isAddBeeperClicked ===
                                                            true
                                                    ) {
                                                        setIsConfirmationEditRunning(
                                                            true
                                                        );
                                                    } else {
                                                        setShopId(
                                                            e.selectedRow.shopId
                                                        );
                                                        ifPeripheralAssociateObject = await verifyIfPeripheralAssociate(
                                                            e.selectedRow.Id,
                                                            e.selectedRow
                                                                .shopId,
                                                            franchiseID,
                                                            userID,
                                                            appId
                                                        );

                                                        if (
                                                            ifPeripheralAssociateObject.isPeripheralAssociate
                                                        ) {
                                                            setIsModalDeleteAssociatePeripheralOpened(
                                                                !isModalDeleteAssociatePeripheralOpened
                                                            );
                                                        } else {
                                                            setIsModalOpened(
                                                                !isModalOpened
                                                            );
                                                        }
                                                        setBeeperId(
                                                            e.selectedRow.Id
                                                        );
                                                    }
                                                }}
                                                style={{ cursor: "pointer" }}
                                                height={25}
                                                width={25}
                                                fill="black"
                                            />
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                            <div className="p-1">
                                <Tooltip
                                    title={t("Consult history").toString()}
                                >
                                    <div>
                                        <TimePastSvgIcon
                                            height={25}
                                            width={25}
                                            fill="black"
                                            onClick={() => {
                                                if (isEdited === true) {
                                                    setIsActionsClicked(
                                                        !isActionsClicked
                                                    );
                                                    setActions(async () => {
                                                        setIsEdited(false);
                                                        setIsAddBeeperClicked(
                                                            false
                                                        );
                                                        setBeeperId(
                                                            e.selectedRow.Id
                                                        );
                                                        setSelectedShopId(
                                                            e.selectedRow.shopId
                                                        );
                                                        setIsModalOpenedHistory(
                                                            !isModalOpenedHistory
                                                        );
                                                    });
                                                } else {
                                                    setBeeperId(
                                                        e.selectedRow.Id
                                                    );
                                                    setSelectedShopId(
                                                        e.selectedRow.shopId
                                                    );
                                                    setIsModalOpenedHistory(
                                                        !isModalOpenedHistory
                                                    );
                                                }
                                            }}
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            className={`${classnames(
                                                "pointer__clz cmn_icn_BThistory cmn_icn_history",
                                                {}
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="p-1">
                                <Tooltip title={t("To consult").toString()}>
                                    <div>
                                        <ConsultSvgIcon
                                            className="cmn_icn_toConsult"
                                            height={35}
                                            width={35}
                                            onClick={() => {
                                                setEditedData(e.selectedRow);
                                                setIsConsult(true);
                                                setShopId(e.selectedRow.shopId);
                                                setInputIp(false);
                                                setIsAddBeeperClicked(true);
                                            }}
                                            style={{
                                                cursor: "pointer",
                                                position: "relative",
                                                bottom: "5px",
                                            }}
                                        />
                                    </div>
                                </Tooltip>
                            </div>
                            <div className="p-1">
                                <Tooltip
                                    title={t("Consult history").toString()}
                                >
                                    <div>
                                        <TimePastSvgIcon
                                            height={25}
                                            width={25}
                                            fill="black"
                                            onClick={() => {
                                                if (isEdited === true) {
                                                    setIsActionsClicked(
                                                        !isActionsClicked
                                                    );
                                                    setActions(async () => {
                                                        setIsEdited(false);
                                                        setIsAddBeeperClicked(
                                                            false
                                                        );
                                                        setBeeperId(
                                                            e.selectedRow.Id
                                                        );
                                                        setSelectedShopId(
                                                            e.selectedRow.shopId
                                                        );
                                                        setIsModalOpenedHistory(
                                                            !isModalOpenedHistory
                                                        );
                                                    });
                                                } else {
                                                    setBeeperId(
                                                        e.selectedRow.Id
                                                    );
                                                    setSelectedShopId(
                                                        e.selectedRow.shopId
                                                    );
                                                    setIsModalOpenedHistory(
                                                        !isModalOpenedHistory
                                                    );
                                                }
                                            }}
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            className={`${classnames(
                                                "pointer__clz cmn_icn_BThistory cmn_icn_history",
                                                {}
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            </div>
                        </>
                    )}
                </div>
            ) : null}
        </React.Fragment>
    );
}
