import React from "react";
import classnames from "classnames";
import ReactSwitch from "react-switch";
import {
    CheckboxIcon,
    EyeIcon,
    HeartIcon,
    PencilIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { mutate } from "swr";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";

import {
    store as generalSettingStore,
    setNumberArchived,
    setNumberActive,
    setIsActivation,
    setIsValidateArchiveOpened,
    setIsActionsClicked,
    setIsEdited,
    setActions,
} from "../store";
import { store } from "@components/VerticalLayout/store";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import { ConfirmationAssociateModifiedMessage } from "./ConfirmationAssociateModifiedMessage";
import ErrorToast from "@components/Common/ErrorTost/index";

type CustomIconsType = {
    e: any;
    setShopId: Function;
    setRowData: Function;
    setLanguage: Function;
    setIsModalOpened: Function;
    isModalOpened: boolean;
    setDataIsUpdated: Function;
    setIsHistoryModalOpened: Function;
    isHistoryModalOpened: boolean;
    isArchivedLanguagesClicked: boolean;
    setSelectedRows: Function;
    dataIsUpdated: boolean | number;
    setIsAddLanguageClicked: Function;
    isAddLanguageClicked: boolean;
};

export function CustomIcons({
    e,
    setShopId,
    setRowData,
    setLanguage,
    setIsModalOpened,
    isModalOpened,
    setDataIsUpdated,
    dataIsUpdated,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    setSelectedRows,
    isArchivedLanguagesClicked,
    setIsAddLanguageClicked,
    isAddLanguageClicked,
}: CustomIconsType) {
    const { t } = useTranslation();
    const {
        numberArchived,
        numberActive,
        shopsLanguagesData,
        archivedLanguageFranchise,
        isActionsClicked,
        isEdited,
        modify,
    } = useSnapshot(generalSettingStore);
    const { userID, franchiseID, oneShop, operatorID } = useSnapshot(store);
    let dataAssociate: any[] = [];
    let dataArchive: any[] = [];

    const [languageState, setLanguageState] = React.useState<boolean>(
        e.selectedRow.isActive
    );
    const [isAssociateModified, setIsAssociateModified] = React.useState<
        boolean
    >(false);

    if (!oneShop) {
        dataAssociate = AssociateLangues();
        dataArchive = ArchivedLanguages();
    } else {
        dataArchive = ArchivedLanguagesFranchise();
    }

    function AssociateLangues() {
        let associated: any[] = [e.selectedRow];
        if (shopsLanguagesData !== undefined) {
            shopsLanguagesData.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    if (el.Id === e.selectedRow.Id) {
                        associated.push(el);
                    }
                });
            });
        }
        return associated;
    }

    function ArchivedLanguagesFranchise() {
        let idsAssociated: any[] = [];
        if (archivedLanguageFranchise !== undefined) {
            archivedLanguageFranchise.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    async function activeLanguage() {
        toast.dismiss();
        const id = e.selectedRow.Id;
        let dataLanguage = {
            userId: userID,
            shopId: [e.selectedRow.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            isShop: false,
            data: {
                [id]: {
                    id: id,
                    language: e.selectedRow.Language,
                    display_name: e.selectedRow.display_name,
                    country: e.selectedRow.country,
                    urlImage: e.selectedRow.url_image,
                    isArchived: false,
                    isFavorite: e.selectedRow.isFavorite,
                    isFavoriteShop: e.selectedRow.isFavorite,
                    isActive: e.selectedRow.isActive,
                    shopId: e.selectedRow.shopId,
                    isActiveShop: e.selectedRow.isActive,
                    abbreviation: e.selectedRow.abbreviation,
                },
            },
        };
        const data = new FormData();
        data.append("dataLanguage", JSON.stringify(dataLanguage));
        data.append("image", "");
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/language`;
        const requestOptions = {
            method: "POST",
            body: data,
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t("The language was successfully unarchived")}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated(!dataIsUpdated);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function favoriteLanguage(isFavorite: boolean) {
        toast.dismiss();
        const id = e.selectedRow.Id;
        let dataLanguage = {
            userId: userID,
            shopId: [e.selectedRow.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            isShop: false,
            data: {
                [id]: {
                    id: id,
                    language: e.selectedRow.Language,
                    display_name: e.selectedRow.display_name,
                    country: e.selectedRow.country,
                    urlImage: e.selectedRow.url_image,
                    isArchived: e.selectedRow.isArchived,
                    isFavorite: isFavorite,
                    isFavoriteShop: isFavorite,
                    isActive: e.selectedRow.isActive,
                    shopId: e.selectedRow.shopId,
                    isActiveShop: e.selectedRow.isActive,
                    abbreviation: e.selectedRow.abbreviation,
                },
            },
        };
        const data = new FormData();
        data.append("dataLanguage", JSON.stringify(dataLanguage));
        data.append("image", "");
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/language`;
        const requestOptions = {
            method: "POST",
            body: data,
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        setDataIsUpdated(!dataIsUpdated);
                        toast.success(
                            isFavorite
                                ? `${t(
                                      "Favorite state has been enabled successfully"
                                  )}`
                                : `${t(
                                      "Favorite state has been disabled successfully"
                                  )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function enableLanguage() {
        toast.dismiss();
        const id = e.selectedRow.Id;
        let dataLanguage = {
            userId: userID,
            shopId: [e.selectedRow.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            isShop: false,
            data: {
                [id]: {
                    id: id,
                    language: e.selectedRow.Language,
                    display_name: e.selectedRow.display_name,
                    country: e.selectedRow.country,
                    urlImage: e.selectedRow.url_image,
                    isArchived: e.selectedRow.isArchived,
                    isFavorite: e.selectedRow.isFavorite,
                    isFavoriteShop: e.selectedRow.isFavorite,
                    isActive: !languageState,
                    shopId: e.selectedRow.shopId,
                    abbreviation: e.selectedRow.abbreviation,
                },
            },
        };
        const data = new FormData();
        data.append("dataLanguage", JSON.stringify(dataLanguage));
        data.append("image", "");
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/language`;
        const requestOptions = {
            method: "POST",
            body: data,
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        setDataIsUpdated(!dataIsUpdated);
                        toast.success(
                            languageState
                                ? `${t(
                                      "The language was successfully deactivated"
                                  )}`
                                : `${t(
                                      "The language was successfully activated"
                                  )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    function ArchivedLanguages() {
        let idsAssociated: any[] = [];
        if (shopsLanguagesData !== undefined) {
            shopsLanguagesData.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    idsAssociated.push(el.Id);
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    {modify === true ? (
                        <>
                            <Tooltip
                                title={
                                    isArchivedLanguagesClicked
                                        ? ""
                                        : e.selectedRow.isFavorite === false
                                        ? t("Enable favorite state")
                                        : t("Disable favorite state")
                                }
                            >
                                <div>
                                    <HeartIcon
                                        onClick={() => {
                                            if (isEdited === true) {
                                                setIsActionsClicked(
                                                    !isActionsClicked
                                                );
                                                setActions(() => {
                                                    setIsAddLanguageClicked(
                                                        !isAddLanguageClicked
                                                    );
                                                    setIsEdited(false);
                                                    if (
                                                        dataAssociate.length > 1
                                                    ) {
                                                        setIsActivation(false);
                                                        setIsAssociateModified(
                                                            true
                                                        );
                                                    } else {
                                                        setRowData(
                                                            e.selectedRow
                                                        );
                                                        setShopId(
                                                            e.selectedRow.shopId
                                                        );
                                                        setLanguage(
                                                            e.selectedRow
                                                        );
                                                        favoriteLanguage(
                                                            !e.selectedRow
                                                                .isFavorite
                                                        );
                                                    }
                                                });
                                            } else {
                                                if (dataAssociate.length > 1) {
                                                    setIsActivation(false);
                                                    setIsAssociateModified(
                                                        true
                                                    );
                                                } else {
                                                    setRowData(e.selectedRow);
                                                    setShopId(
                                                        e.selectedRow.shopId
                                                    );
                                                    setLanguage(e.selectedRow);
                                                    favoriteLanguage(
                                                        !e.selectedRow
                                                            .isFavorite
                                                    );
                                                }
                                            }
                                        }}
                                        height={25}
                                        width={25}
                                        name="heart"
                                        fill={
                                            e.selectedRow.isFavorite
                                                ? "red"
                                                : "black"
                                        }
                                        className={`${classnames(
                                            "pointer__clz lan_icn_FRlike",
                                            {
                                                "not-allowed-icon__clz": isArchivedLanguagesClicked,
                                            },
                                            oneShop ? "mr-2" : ""
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                            <Tooltip
                                title={
                                    isArchivedLanguagesClicked ? "" : t("Edit")
                                }
                            >
                                <div>
                                    <PencilIcon
                                        onClick={() => {
                                            if (isEdited === true) {
                                                setIsActionsClicked(
                                                    !isActionsClicked
                                                );
                                                setActions(() => {
                                                    setIsEdited(false);
                                                    setRowData(e.selectedRow);
                                                    setShopId(
                                                        e.selectedRow.shopId
                                                    );
                                                    setLanguage(e.selectedRow);
                                                    setIsAddLanguageClicked(
                                                        true
                                                    );
                                                });
                                            } else {
                                                setRowData(e.selectedRow);
                                                setShopId(e.selectedRow.shopId);
                                                setLanguage(e.selectedRow);
                                                setIsAddLanguageClicked(true);
                                            }
                                        }}
                                        height={25}
                                        width={25}
                                        style={{ cursor: "pointer" }}
                                        className={`${classnames(
                                            "pointer__clz cmn_icn_FRedit",
                                            {
                                                "not-allowed-icon__clz": isArchivedLanguagesClicked,
                                            },
                                            oneShop ? "mr-2" : "ml-3"
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                            {oneShop && (
                                <Tooltip
                                    title={
                                        isArchivedLanguagesClicked
                                            ? ""
                                            : languageState === false
                                            ? t("Activate")
                                            : t("Unactivate")
                                    }
                                >
                                    <div>
                                        <ReactSwitch
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            handleDiameter={26}
                                            offColor="#f7b4b8"
                                            offHandleColor="#E30613"
                                            checked={languageState}
                                            onChange={async () => {
                                                if (
                                                    dataAssociate.includes(
                                                        e.selectedRow.Id
                                                    )
                                                ) {
                                                    setIsActivation(true);
                                                    setIsAssociateModified(
                                                        true
                                                    );
                                                } else {
                                                    setLanguageState(
                                                        !languageState
                                                    );
                                                    enableLanguage();
                                                }
                                            }}
                                            onColor="#c2eddd"
                                            onHandleColor="#34C38F"
                                            width={50}
                                            height={20}
                                            className={`${classnames(
                                                "pointer__clz",
                                                {
                                                    "not-allowed-icon__clz": isArchivedLanguagesClicked,
                                                },
                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}

                            {!isArchivedLanguagesClicked ? (
                                <Tooltip title={t("Archive")}>
                                    <div>
                                        <TrashIcon
                                            onClick={() => {
                                                if (isEdited === true) {
                                                    setIsActionsClicked(
                                                        !isActionsClicked
                                                    );
                                                    setActions(() => {
                                                        setIsAddLanguageClicked(
                                                            false
                                                        );
                                                        setIsEdited(false);
                                                        if (
                                                            dataAssociate.length >
                                                            1
                                                        ) {
                                                            ErrorToast(
                                                                `${t(
                                                                    "Please note that you cannot archive an item assigned to stores."
                                                                )!}`
                                                            );
                                                        } else {
                                                            setSelectedRows([
                                                                e.selectedRow,
                                                            ]);
                                                            setIsValidateArchiveOpened(
                                                                true
                                                            );
                                                        }
                                                    });
                                                } else {
                                                    setIsAddLanguageClicked(
                                                        false
                                                    );
                                                    if (
                                                        dataAssociate.length > 1
                                                    ) {
                                                        ErrorToast(
                                                            `${t(
                                                                "Please note that you cannot archive an item assigned to stores."
                                                            )!}`
                                                        );
                                                    } else {
                                                        setSelectedRows([
                                                            e.selectedRow,
                                                        ]);
                                                        setIsValidateArchiveOpened(
                                                            true
                                                        );
                                                    }
                                                }
                                            }}
                                            height={25}
                                            width={25}
                                            fill="red"
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            className={`${classnames(
                                                "pointer__clz cmn_icn_FRarchived",
                                                {
                                                    "not-allowed-icon__clz": isArchivedLanguagesClicked,
                                                },
                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={
                                        dataArchive.includes(
                                            e.selectedRow.Id
                                        ) && oneShop
                                            ? ""
                                            : t("Unarchive")
                                    }
                                >
                                    <div>
                                        <EyeIcon
                                            height={25}
                                            width={25}
                                            onClick={() => {
                                                setRowData(e.selectedRow);
                                                setSelectedRows([
                                                    e.selectedRow,
                                                ]);
                                                if (
                                                    dataArchive.includes(
                                                        e.selectedRow.Id
                                                    ) &&
                                                    !oneShop
                                                ) {
                                                    setIsModalOpened(
                                                        !isModalOpened
                                                    );
                                                } else {
                                                    activeLanguage();
                                                    setNumberActive(
                                                        numberActive + 1
                                                    );
                                                    setNumberArchived(
                                                        numberArchived - 1
                                                    );
                                                }
                                            }}
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            className={`${classnames(
                                                "pointer__clz cmn_icn_FRunarchive",
                                                {
                                                    "not-allowed-icon__clz":
                                                        dataArchive.includes(
                                                            e.selectedRow.Id
                                                        ) && oneShop,
                                                },
                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : null}
                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(() => {
                                            setIsAddLanguageClicked(
                                                !isAddLanguageClicked
                                            );
                                            setIsEdited(false);
                                            setSelectedRows([e.selectedRow]);
                                            setRowData(e.selectedRow);
                                            setIsHistoryModalOpened(
                                                !isHistoryModalOpened
                                            );
                                        });
                                    } else {
                                        setIsAddLanguageClicked(
                                            !isAddLanguageClicked
                                        );
                                        setSelectedRows([e.selectedRow]);
                                        setRowData(e.selectedRow);
                                        setIsHistoryModalOpened(
                                            !isHistoryModalOpened
                                        );
                                    }
                                }}
                                className={`${classnames(
                                    "pointer__clz cmn_icn_FRhistory",
                                    oneShop ? "mr-2" : "ml-3"
                                )}`}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}

            {isAssociateModified && (
                <ConfirmationAssociateModifiedMessage
                    isModalOpened={isAssociateModified}
                    setIsModalOpened={setIsAssociateModified}
                    e={e}
                    languageState={languageState}
                    associated={dataAssociate}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                />
            )}
        </React.Fragment>
    );
}
