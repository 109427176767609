import React from "react";
import classNames from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import {
    AngleSmallLeftIcon,
    AngleSmallRightIcon,
    StyledIconButton,
} from "@aureskonnect/react-ui";

import {
    computedStore,
    designStore,
    setActivePageName,
    setActiveSlideIndex,
} from "@store";

import { capitalize } from "@helpers/general";

import "./index.css";
import "swiper/swiper-bundle.min.css";

export function WaysSwiperWrapper(): JSX.Element {
    const { t } = useTranslation();

    const { activeSlideIndex } = useSnapshot(designStore);
    const { elements } = useSnapshot(computedStore);

    const navigationPrevRef = React.useRef(null);
    const navigationNextRef = React.useRef(null);

    return (
        <React.Fragment>
            {elements.length < 6 ? (
                <div
                    className="d-flex flex-row justify-content-center align-items-center"
                    style={{
                        width: "98%",
                        height: "36px !important",
                        gap: "10px",
                        marginLeft: "10px",
                        marginBottom: "5px",
                        marginTop: "5px",
                    }}
                >
                    {elements.map((item, itemIndex) => {
                        return (
                            <div
                                className={classNames(
                                    "width__clz border border-white rounded",
                                    {
                                        "slide-color__clz":
                                            activeSlideIndex === itemIndex,
                                    }
                                )}
                                style={{
                                    width: "100%",
                                }}
                                key={itemIndex}
                                onClick={() => {
                                    setActiveSlideIndex(itemIndex);
                                    setActivePageName(item.name);
                                }}
                            >
                                <span>{t(capitalize(item.title))}</span>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div className="d-flex justify-content-center align-items-center">
                    <StyledIconButton
                        ref={navigationPrevRef}
                        className="m-0 p-0 swiper-button__clz"
                    >
                        <AngleSmallLeftIcon
                            height={50}
                            width={40}
                            fill="#323332"
                        />
                    </StyledIconButton>
                    <Swiper
                        className="design-ways-swiper-wrapper__clz"
                        modules={[Navigation]}
                        navigation={{
                            prevEl: navigationPrevRef.current,
                            nextEl: navigationNextRef.current,
                        }}
                        spaceBetween={14}
                        slidesPerView={5}
                        centeredSlides={true}
                        centeredSlidesBounds={true}
                    >
                        {elements.map((item, itemIndex) => {
                            return (
                                <SwiperSlide
                                    className={classNames(
                                        "width__clz border border-white rounded",
                                        {
                                            "slide-color__clz":
                                                activeSlideIndex === itemIndex,
                                        }
                                    )}
                                    key={itemIndex}
                                    onClick={() => {
                                        setActiveSlideIndex(itemIndex);
                                        setActivePageName(item.name);
                                    }}
                                >
                                    <span>{t(capitalize(item.title))}</span>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                    <StyledIconButton
                        ref={navigationNextRef}
                        className="m-0 p-0 swiper-button__clz"
                    >
                        <AngleSmallRightIcon
                            height={50}
                            width={40}
                            fill="#323332"
                        />
                    </StyledIconButton>
                </div>
            )}
        </React.Fragment>
    );
}
