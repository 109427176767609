import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledH2, StyledLabel } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useSnapshot } from "valtio";

import {
    store,
    setIsModalConfirmationOpened,
} from "../../VerticalLayout/store";
import { store as associateStore } from "@pages/Applications/DeviceAssociateModal/store";
import { store as applicationStore } from "@pages/Applications/ApplicationAssociateModal/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import { capitalize } from "@helpers/general";

export function ConfirmationMessage() {
    const { t } = useTranslation();
    const { isModalConfirmationOpened } = useSnapshot(store);
    const { applications } = useSnapshot(applicationStore);
    const {
        cashManagements,
        printers,
        monetics,
        selectedPeripherals,
    } = useSnapshot(associateStore);
    return (
        <StyledModal toggle={true} isOpen={isModalConfirmationOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() =>
                    setIsModalConfirmationOpened(!isModalConfirmationOpened)
                }
            >
                <StyledH2>{capitalize(t("Alert"))}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-4">
                    {printers.length > 0 ||
                    monetics.length > 0 ||
                    cashManagements.length > 0 ||
                    applications.length > 0 ||
                    selectedPeripherals.length !== 0
                        ? t(
                              "The data has not changed! Do you want to continue?"
                          )
                        : t("Attention ! You must select at least one device")}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomMainColorButton
                    rounded
                    variant="primary"
                    onClick={() => {
                        setIsModalConfirmationOpened(
                            !isModalConfirmationOpened
                        );
                    }}
                >
                    {t("Ok")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
