import React from "react";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { useTranslation } from "react-i18next/";
import { mutate } from "swr";
import { CheckboxIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";
import { toast } from "react-toastify";

import ErrorToast from "@components/Common/ErrorTost";

import store from "../store";
import { store as fidelity } from "../../../../components/VerticalLayout/store";
import { ConfirmationArchive } from "../ConfirmationArchive";

export function ActionColumn({
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    selectedRows,
    setDataIsUpdated,
    setIsFidelityArchived,
    isFidelityArchived,
    setLimit,
}: any) {
    const { t } = useTranslation();
    const {
        numberActiveMultiShop,
        numberArchiveMultiShop,
        archivedFidelityFranchise,
        shopsFidelityData,
        modify,
    } = useSnapshot(store);
    const { franchiseID, userID, operatorID } = useSnapshot(fidelity);

    const [error, setError] = React.useState(false);
    const [isArchiveModalOpened, setIsArchiveModalOpened] = React.useState<
        boolean
    >(false);

    function archiveFidelityFranchise() {
        let idsAssociated: any = [];
        if (
            archivedFidelityFranchise.othersData.archivedFranchise.data !==
            undefined
        ) {
            archivedFidelityFranchise.othersData.archivedFranchise.data.forEach(
                (element: any) => {
                    idsAssociated.push(element.Id);
                }
            );
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    const getArrayOfRows = () => {
        let arrayOfRows: any[] = [];
        let ids: any[] = [];

        selectedRows.forEach(async (el: any) => {
            if (
                el[t("Shop")] === undefined &&
                ids.indexOf(`${el.shopId}${el.Id}`) === -1
            ) {
                arrayOfRows.push(el);
                ids.push(`${el.shopId}${el.Id}`);
            }
        });
        arrayOfRows = [...new Set(arrayOfRows)];
        return arrayOfRows;
    };
    let arrayOfRows = getArrayOfRows();

    function shopsIdArchiveFidelity(e: any) {
        let idsAssociated: any = [];
        let shopSelectedRows: any[] = [];
        selectedRows.forEach((el: any) => {
            if (el.shopId === e.shopId) {
                shopSelectedRows.push(el.shopId);
            }
        });

        if (shopsFidelityData.othersData.allFidelity.data !== undefined) {
            shopsFidelityData.othersData.allFidelity.data.forEach(
                (element: any) => {
                    if (
                        element.subRows !== undefined &&
                        element.subRows.length === 0 &&
                        ((element.archivedFidelity !== undefined &&
                            element.archivedFidelity.length === 1) ||
                            (selectedRows.subRows === undefined &&
                                shopSelectedRows.length === 1))
                    ) {
                        element.archivedFidelity.forEach((el: any) => {
                            if (el.Id === e.Id && el.shopId === e.shopId)
                                idsAssociated.push(el.shopId);
                        });
                    }
                }
            );
        }

        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    async function activeFidelity() {
        toast.dismiss();
        let localData: any = [];
        let isNotEmpty: boolean = false;
        let isEmpty: boolean = false;
        let arrayOfRows = getArrayOfRows();
        arrayOfRows.forEach(async (el: any) => {
            let shopsIdArchived = shopsIdArchiveFidelity(el);
            let dataFidelity = {
                userId: userID,
                shopId: shopsIdArchived,
                franchiseId: franchiseID,
                operatorId: operatorID,
                isShop: false,
                data: {
                    [el.Id]: {
                        designation: el[t("Display name")],
                        type: el[t("Fidelity type")],
                        number_fidelity_points: el.number_fidelity_points,
                        money_correspondence: el.moneyCorrespondence,
                        devise: el.Devise,
                        pays: el.Pays,
                        authentication_mode: el.authentication_mode,
                        sortedVisibility: el.sortedVisibility,
                        Login: el.Login,
                        password: el[t("Password")],
                        note: el[t("Remark")],
                        isActive: false,
                        isArchived: false,
                        shopId: el.shopId,
                        dashboardLink: el["Dashboard Link"],
                        user: el.user,
                        apiKey: el["API key"],
                        auresKonnectGroup: el[t("Group")],
                        isCentralizationActive: el[t("State")],
                    },
                },
            };
            if (dataFidelity.shopId.length !== 0) {
                isNotEmpty = true;
            } else {
                isEmpty = true;
            }

            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/fidelity`;
            localData.push(
                fetch(apiUrlAdd, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(dataFidelity),
                }).then((response) => response.json())
            );
        });

        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/fidelity`,
                await Promise.all(localData).then((result) => {})
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setError(true);
        }

        if (error === false && isNotEmpty) {
            toast.success(
                `${t("The fidelity setting has been successfully unarchive")!}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );

            setDataIsUpdated!(true);
        }
        if (isEmpty) {
            ErrorToast(
                t(
                    "Attention,a shop cannot have more than one fidelity settings"
                )
            );

            setDataIsUpdated!(true);
        }
        setLimit(2);
    }

    return (
        <React.Fragment>
            <div>
                <div className="dropdown">
                    <VerticalDotsIcon
                        id="dropdownMenuButton1"
                        className="cmn_drp_BTmenu"
                        data-bs-toggle="dropdown"
                        style={{ cursor: "pointer" }}
                        height={25}
                        width={25}
                        fill="black"
                    />
                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <div
                            className="dropdown-item pl-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setIsFidelityArchived(false);
                            }}
                        >
                            <span className="ml-2 cmn_btn_BTlistActivated">
                                {t("Fidelity Enabled(s)")} (
                                {numberActiveMultiShop})
                            </span>
                        </div>
                        <div
                            className="dropdown-item pl-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setIsFidelityArchived(true);
                            }}
                        >
                            <span className="ml-2 cmn_btn_BTlistArchived">
                                {t("Archived fidelity(s)")}(
                                {numberArchiveMultiShop})
                            </span>
                        </div>
                        <div
                            style={{ cursor: "pointer" }}
                            className={`${classnames(
                                " dropdown-item pl-1 pointer__clz",
                                {
                                    "not-allowed-icon__clz":
                                        selectedRows.length === 0 ||
                                        arrayOfRows.length === 0,
                                }
                            )}`}
                            onClick={() => {
                                if (arrayOfRows.length < 2) {
                                    ErrorToast(
                                        `${t(
                                            "Attention, it is a multiple action. Please select at least two items"
                                        )!}`
                                    );
                                } else {
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );
                                }
                            }}
                        >
                            <span className="ml-2 cmn_btn_BThistory">
                                {t("Historical")}
                            </span>
                        </div>
                        {modify === true ? (
                            <>
                                <div
                                    style={{ cursor: "pointer" }}
                                    className={`${classnames(
                                        " dropdown-item pl-1 pointer__clz",
                                        {
                                            "not-allowed-icon__clz":
                                                selectedRows.length === 0 ||
                                                (isFidelityArchived &&
                                                    getArrayOfRows().some(
                                                        (element: any) =>
                                                            archiveFidelityFranchise().includes(
                                                                element.Id
                                                            )
                                                    )) ||
                                                arrayOfRows.length === 0,
                                        }
                                    )}`}
                                    onClick={() => {
                                        if (arrayOfRows.length < 2) {
                                            ErrorToast(
                                                `${t(
                                                    "Attention, it is a multiple action. Please select at least two items"
                                                )!}`
                                            );
                                        } else {
                                            isFidelityArchived
                                                ? activeFidelity()
                                                : setIsArchiveModalOpened(true);
                                        }
                                    }}
                                >
                                    <span className="ml-2 cmn_btn_BTarchived cmn_btn_BTunarchive">
                                        {isFidelityArchived
                                            ? t("unarchive")
                                            : t("archive")}
                                    </span>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
            {setIsArchiveModalOpened ? (
                <ConfirmationArchive
                    isModalOpened={isArchiveModalOpened}
                    setIsModalOpened={setIsArchiveModalOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                    setLimit={setLimit}
                />
            ) : null}
        </React.Fragment>
    );
}
