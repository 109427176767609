import React from "react";
import { Collapse } from "reactstrap";
import { NavLink } from "react-router-dom";

import { StyledSidebarMenuPropsType, MenuItemsType, isVisibleElement } from ".";

import { ArrowDownIcon } from "../../Common/SvgIcons/ArrowDownIcon";
import { ArrowUpIcon } from "../../Common/SvgIcons/ArrowUpIcon";

type WrapperPropsType = Pick<
    StyledSidebarMenuPropsType,
    "backgroundColor" | "collapsed"
>;

type MenuItemType = {
    Icon: any;
    setIsMenuClicked: Function;
    menuCollapsed: number;
    index: number;
    setSubMenuCollapsed: Function;
    subMenuCollapsed: number;
} & WrapperPropsType &
    MenuItemsType;

export function SubMenu({
    link,
    menuItem,
    subMenu,
    icon,
    index,
    setIsMenuClicked,
    setSubMenuCollapsed,
    subMenuCollapsed,
    menuCollapsed,
    id,
}: MenuItemType) {
    const Icon: any = icon !== undefined ? icon : null;

    return (
        <React.Fragment>
            {isVisibleElement(id) ? (
                <NavLink
                    to={
                        (subMenu !== undefined && link === "/kiosk") ||
                        (subMenu !== undefined && link === "/c&c") ||
                        (subMenu !== undefined && link === "/kds") ||
                        (subMenu !== undefined && link === "/sco") ||
                        (subMenu !== undefined && link === "/orb")
                            ? link
                            : (subMenu !== undefined && link !== "/kiosk") ||
                              (subMenu !== undefined && link !== "/c&c") ||
                              (subMenu !== undefined && link === "/kds") ||
                              (subMenu !== undefined && link === "/sco") ||
                              (subMenu !== undefined && link === "/orb")
                            ? "#"
                            : link
                    }
                    onClick={() => {
                        setIsMenuClicked(true);
                        if (subMenuCollapsed === index) {
                            setSubMenuCollapsed(-1);
                        } else setSubMenuCollapsed(index);
                    }}
                    className={(navData: any) => {
                        return navData.isActive
                            ? "active d-flex align-items-center justify-content-between"
                            : "d-flex align-items-center justify-content-between";
                    }}
                    style={{
                        padding: ".4rem 1.5rem .4rem 3.5rem",
                    }}
                >
                    {icon ? (
                        <Icon
                            style={{
                                marginRight: "7px",
                                height: "1.25em",
                                width: "1.25em",
                                fill: "white",
                            }}
                        />
                    ) : null}
                    {menuItem}
                    {subMenu === undefined ? null : subMenuCollapsed ===
                      index ? (
                        <ArrowUpIcon />
                    ) : (
                        <ArrowDownIcon />
                    )}
                </NavLink>
            ) : null}
            {subMenu !== undefined ? (
                <Collapse isOpen={subMenuCollapsed === index}>
                    <ul>
                        {subMenu.map(
                            (
                                {
                                    link,
                                    menuItem,
                                    icon,
                                    subMenu,
                                    customClassName,
                                    id,
                                }: MenuItemsType,
                                index: number
                            ) => {
                                const Icon: any =
                                    icon !== undefined ? icon : null;
                                return isVisibleElement(id) ? (
                                    <li key={index} className={customClassName}>
                                        <NavLink
                                            to={link}
                                            className={(navData) =>
                                                navData.isActive
                                                    ? "active d-flex align-items-center justify-content-between"
                                                    : "d-flex align-items-center justify-content-between"
                                            }
                                            style={{
                                                padding:
                                                    ".4rem 1.5rem .4rem 4.5rem",
                                            }}
                                        >
                                            {icon ? (
                                                <Icon
                                                    style={{
                                                        marginRight: "7px",
                                                        height: "1.25em",
                                                        width: "1.25em",
                                                        fill: "white",
                                                    }}
                                                />
                                            ) : null}
                                            {menuItem}
                                            {subMenu ===
                                            undefined ? null : menuCollapsed ===
                                              index ? (
                                                <ArrowDownIcon />
                                            ) : (
                                                <ArrowUpIcon />
                                            )}
                                        </NavLink>
                                    </li>
                                ) : null;
                            }
                        )}
                    </ul>
                </Collapse>
            ) : null}
        </React.Fragment>
    );
}
