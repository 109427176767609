import useSWR from "swr";
import swal from "@sweetalert/with-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { isTokenExpired } from "@helpers/backend_helper";
import { fetcherWithToken } from "@helpers/fetcherWithToken";

export default function useResource(url: string) {
    const { t } = useTranslation();
    const jwtTokenExpireDate = JSON.parse(localStorage.getItem("authUser")!)
        ?.exp_client;

    let navigate = useNavigate();

    if (jwtTokenExpireDate && isTokenExpired(jwtTokenExpireDate)) {
        navigate("/login");
        const swalContent = document.createElement("p");
        swalContent.innerText = `${t("Your session has expired")}!`;

        swal({
            icon: "error",
            content: swalContent,
        });
    }

    let { data: resourceData, error, mutate } = useSWR(
        [url],
        fetcherWithToken,
        {
            suspense: true,
            revalidateOnFocus: false,
        }
    );

    if (resourceData?.error) {
        error = resourceData.message;
        throw error;
    }

    return { resourceData, error, mutate };
}
