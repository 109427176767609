import React from "react";

import "./index.css";

type BadgeNumberType = {
    value: string;
};

export default function BadgeNumber({ value }: BadgeNumberType) {
    return <h6 className="badge__clz ">{value !== "" ? value : 0}</h6>;
}
