import React from "react";

import "@components/Common/Design/index.css";

import { MainContent } from "./MainContent";
import { Translation } from "./Translation";

export function Header(): JSX.Element {
    return (
        <React.Fragment>
            <MainContent />
            <Translation />
        </React.Fragment>
    );
}
