import classNames from "classnames";
import React from "react";
import { useSnapshot } from "valtio";

import { designStore, generalConfigStore } from "@store";

import { PreviewBackgroundWrapper } from "../PreviewBackgroundWrapper";
import { CardWrapper } from "./CardWrapper";
import { CategoriesSwiperWrapper } from "./CategoriesSwiperWrapper";
import { TagsSwiperWrapper } from "./TagsSwiperWrapper";

export function MainContentWrapper(): JSX.Element {
    const {
        activeTabIndex,
        selectedCategory,
        isHeaderOrderTakingActive,
        isBackgroundActive,
    } = useSnapshot(designStore);

    const {
        isOrderTakingTagsActive,
        isOrderTakingTopBannerActive,
        isOrderTakingSelectedCategoryTitleActive,
    } = useSnapshot(generalConfigStore);

    return (
        <div
            className={classNames({
                "mt-2": [
                    isOrderTakingTopBannerActive,
                    isHeaderOrderTakingActive,
                ].some((value) => value === false),
                "mt-3": [
                    isOrderTakingTopBannerActive,
                    isHeaderOrderTakingActive,
                ].every((value) => value === false),
            })}
            style={{
                display: "grid",
                gridTemplateColumns: "0.5fr 1.5fr",
                width: "400px",
            }}
        >
            <div
                className={classNames(
                    "d-flex justify-content-center align-items-center",
                    {
                        "border-wrapper__clz": activeTabIndex === 2,
                    }
                )}
                style={{
                    width: "90px",
                    height: "430px",
                }}
            >
                <CategoriesSwiperWrapper />
            </div>
            {isBackgroundActive === true ? (
                <PreviewBackgroundWrapper>
                    <div
                        className={classNames({
                            "border-wrapper__clz": activeTabIndex === 3,
                        })}
                        style={{
                            width: "335px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "5px",
                                height: "400px",
                            }}
                        >
                            {isOrderTakingSelectedCategoryTitleActive ===
                            true ? (
                                <span
                                    style={{
                                        zIndex: 1,
                                        fontWeight: "bold",
                                        fontSize: "17px",
                                        fontFamily: "Segoe UI",
                                        textAlign: "left",
                                    }}
                                >
                                    {selectedCategory.name}
                                </span>
                            ) : null}

                            <div
                                className={classNames(
                                    "d-flex justify-content-start align-items-start",
                                    {
                                        "mt-2":
                                            isOrderTakingSelectedCategoryTitleActive ===
                                            false,
                                    }
                                )}
                            >
                                {isOrderTakingTagsActive === true ? (
                                    <TagsSwiperWrapper />
                                ) : null}
                            </div>

                            <CardWrapper />
                        </div>
                    </div>
                </PreviewBackgroundWrapper>
            ) : (
                <div
                    className={classNames({
                        "border-wrapper__clz": activeTabIndex === 3,
                    })}
                    style={{
                        width: "335px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                            height: "400px",
                        }}
                    >
                        {isOrderTakingSelectedCategoryTitleActive === true ? (
                            <span
                                style={{
                                    zIndex: 1,
                                    fontWeight: "bold",
                                    fontSize: "17px",
                                    fontFamily: "Segoe UI",
                                    textAlign: "left",
                                }}
                            >
                                {selectedCategory.name}
                            </span>
                        ) : null}

                        <div
                            className={classNames(
                                "d-flex justify-content-start align-items-start",
                                {
                                    "mt-2":
                                        isOrderTakingSelectedCategoryTitleActive ===
                                        false,
                                }
                            )}
                        >
                            {isOrderTakingTagsActive === true ? (
                                <TagsSwiperWrapper />
                            ) : null}
                        </div>

                        <CardWrapper />
                    </div>
                </div>
            )}
        </div>
    );
}
