import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    CrossIcon,
    StyledLabel,
    StyledTextInput,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { mutate } from "swr";
import { toast } from "react-toastify";
import classnames from "classnames";
import { arrayDiff } from "@helpers/general";

import { useSnapshot } from "valtio";
import { uuid } from "uuidv4";

import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";

import {
    CustomCardBody,
    CustomCardFooter,
    CustomCardHeader,
} from "@components/Common/CustomCardStyled";
import { store } from "@components/VerticalLayout/store";
import {
    store as InformationStore,
    setIsArchived,
    setCanValidate,
    setIsActionsClicked,
    setActions,
    setIsEdited,
} from "../store";
import { ConfirmationModificationMessage } from "./ConfirmationModificationMessage";
import { ConfirmationAssociationModal } from "./ConfirmationAssociationModal";
import MultiCustomCreatableSelection from "@components/Common/MultiCustomCreatableSelection";
import Creatable from "react-select/creatable";
import MultiCustomSelect from "@components/Common/MultiCustomSelect";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

type ModeOfInformationFormType = {
    setIsAddModeOfInformationClicked: Function;
    isAddModeOfInformationClicked: boolean;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<boolean | number>>;
    dataInformation: React.SetStateAction<any>;
    isAddNewModeOfInformationButtonClicked: boolean;
    setDataInformation: Function;
    inputInformationMode: boolean;
    setInputInformationMode: Function;
    dataIsUpdated?: boolean | number;
    dataTable?: any;
    setLimit: Function;
};

export default function InformationForm({
    setIsAddModeOfInformationClicked,
    isAddModeOfInformationClicked,
    setDataIsUpdated,
    dataInformation,
    isAddNewModeOfInformationButtonClicked,
    setDataInformation,
    inputInformationMode,
    setInputInformationMode,
    dataIsUpdated,
    dataTable,
    setLimit,
}: ModeOfInformationFormType) {
    const { t } = useTranslation();

    const { userID, franchiseID, shopID, oneShop, operatorID } = useSnapshot(
        store
    );
    const [isSendingTag, setIsSendingTag] = React.useState<boolean>(false);

    const [tagsData, setTagsData] = React.useState<any>([]);

    const {
        modeInformationData,
        shopsModeInformationData,
        modeInformationFranchise,
        dataFranchiseArchive,
        canValidate,
        dataArchive,
        isActionsClicked,
        isEdited,
    } = useSnapshot(InformationStore);
    const [informationMode, setInformationMode] = React.useState<string>("");
    const [supportOfSale, setSupportOfSale] = React.useState<any>({});
    const [informationModes, setInformationModes] = React.useState<any>([]);
    const [supportOfSales, setSupportOfSales] = React.useState<any>([]);
    const [modeOfSales, setModeOfSales] = React.useState<any>([]);
    const [modeOfSale, setModeOfSale] = React.useState<any>([]);
    const [inputSupportOfSale, setInputSupportOfSale] = React.useState<boolean>(
        false
    );
    const [inputModeOfSale, setInputModeOfSale] = React.useState<boolean>(
        false
    );

    const [, setTag] = React.useState<any>([]);

    const [title, setTitle] = React.useState<string>("");

    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const shopId = oneShop ? shopID : 0;

    const [tags, setTags] = React.useState<any[]>([]);
    const [isNameExist, setIsNameExist] = React.useState<boolean>(false);
    const [associated, setAssociated] = React.useState<any[]>([]);
    const [dataEdited, setDataEdited] = React.useState<any>();
    const [isModalModifiedOpened, setIsModalModifiedOpened] = React.useState<
        boolean
    >(false);
    const [displayName, setDisplayName] = React.useState<string>("");

    const [selectName, setSelectName] = React.useState<boolean>(false);
    const pathname = window.location.pathname.toString();

    useEffect(() => {
        setInformationMode("");
        setTitle(t("Add a Mode of information"));
        setTag([]);
        setTagsData([]);
        setModeOfSale([]);
        setSupportOfSale({});
        setInputInformationMode(false);
        setInputModeOfSale(false);
        setInputSupportOfSale(false);
        setSelectName(false);
        setIsEdited(false);
        setCanValidate(true);

        setDisplayName("");

        setIsNameExist(false);

        clearData();
        clearMultiCustomSelectionData();
        setInputInformationMode(false);
        setInputModeOfSale(false);
        setInputSupportOfSale(false);
        setSelectName(false);
        setIsEdited(false);
        setCanValidate(true);
        getTag();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAddModeOfInformationClicked, dataIsUpdated]);
    let NamesFranchise: string[] = [];
    NamesFranchise = fetchDataDesignation();
    function checkIfModeOfInformationFranchiseExist() {
        let isExist: boolean = false;

        modeInformationFranchise.forEach((el: any) => {
            if (
                el[t("Mode of information")] === informationMode &&
                el[t("Sales support")].label === supportOfSale.label
            ) {
                isExist = true;
            }
        });
        return isExist;
    }

    function checkIfModeOfInformationFranchiseExistAndArchived() {
        let isModeOfInformationExist = false;
        if (dataFranchiseArchive !== undefined)
            dataFranchiseArchive.forEach((el: any) => {
                if (
                    el[t("Mode of information")] === informationMode &&
                    el[t("Sales support")].label === supportOfSale.label
                )
                    isModeOfInformationExist = true;
                return;
            });

        return isModeOfInformationExist;
    }
    function fetchDataDesignation() {
        if (modeInformationFranchise !== undefined) {
            modeInformationFranchise.forEach((el: any) => {
                NamesFranchise.push(el[t("Mode of information")]);
            });
        }

        return NamesFranchise;
    }

    function fetchDataDesignationArchive() {
        if (dataFranchiseArchive !== undefined) {
            dataFranchiseArchive.forEach((el: any) => {
                NamesFranchiseArchive.push(
                    el[t("Mode of information")].toUpperCase()
                );
            });
        }

        return NamesFranchiseArchive;
    }
    let NamesFranchiseArchive: any[] = [];

    NamesFranchiseArchive = fetchDataDesignationArchive();

    async function addModeOfInformation() {
        if (canValidate) {
            setCanValidate(false);
            let isModeOfInformationFranchiseExist = checkIfModeOfInformationFranchiseExist();
            let isModeOfInformationFranchiseExistAndArchived = checkIfModeOfInformationFranchiseExistAndArchived();
            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`;

            let data = [
                {
                    userId: userID,
                    operatorId: operatorID,
                    shopId: oneShop ? [shopId, 0] : [shopId],
                    franchiseId: franchiseID,
                    adding: true,
                    data: {
                        [uuid()]: {
                            designation: informationMode,
                            supportOfSale: supportOfSale,
                            salesSupportData: [supportOfSale],
                            modeOfSale: modeOfSale,
                            displayName: displayName,
                            tag: tagsData,
                            isArchived: false,
                            state: false,
                            shopId: shopId,
                            stateShop: true,
                        },
                    },
                },
            ];

            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
            };

            if (isModeOfInformationFranchiseExist && oneShop) {
                setIsModalModifiedOpened(true);
                setIsArchived(false);
            } else if (
                isModeOfInformationFranchiseExistAndArchived &&
                oneShop
            ) {
                setIsModalModifiedOpened(true);
                setIsArchived(true);
            } else {
                try {
                    mutate(
                        apiUrlAdd,
                        await fetch(apiUrlAdd, requestOptions)
                            .then((response) => response.json())
                            .then((data) => {
                                if (data.error) {
                                    throw Error("error");
                                }

                                toast.success(
                                    `${t(
                                        "Information mode is successfully added"
                                    )!}`,
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        theme: "colored",
                                        icon: (
                                            <CheckboxIcon
                                                height={25}
                                                width={25}
                                                fill="white"
                                            />
                                        ),
                                    }
                                );
                                setInformationMode("");
                                setTitle(t("Add a Mode of information"));
                                setTag([]);
                                setIsSendingTag(true);
                                setTagsData([]);
                                setModeOfSale([]);
                                setSupportOfSale({});
                                clearMultiCustomSelectionData();
                                clearData();
                                setDataIsUpdated!(true);
                                setInputInformationMode(false);
                                setInputModeOfSale(false);
                                setInputSupportOfSale(false);
                                setSelectName(false);
                                setIsEdited(false);
                                setCanValidate(true);

                                setDisplayName("");

                                setIsNameExist(false);
                                getTag();
                            })
                    );
                } catch (e) {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        }
    }

    async function editModeOfInformation() {
        let dataAssociate: any[] = [];
        toast.dismiss();
        const apiUrlUpdate = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information`;
        if (dataInformation.Id !== undefined) {
            let dataConfig = [
                {
                    userId: userID,
                    operatorId: operatorID,
                    shopId: [shopId],
                    franchiseId: franchiseID,
                    data: {
                        [dataInformation.Id]: {
                            designation: informationMode,
                            supportOfSale: supportOfSale,
                            salesSupportData: [supportOfSale],
                            displayName: displayName,
                            modeOfSale: modeOfSale,
                            tag: tagsData,
                            isArchived: dataInformation.isArchived,
                            state: dataInformation.state,
                            shopId: shopId,
                            stateShop: true,
                        },
                    },
                },
            ];

            setDataEdited(dataConfig[0]);
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(dataConfig),
            };
            if (!oneShop) {
                dataAssociate = AssociateModeOfInformation();
            }
            if (dataAssociate.length > 0) {
                setIsModalOpen(true);
            }
            try {
                mutate(
                    apiUrlUpdate,
                    await fetch(apiUrlUpdate, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }

                            setIsSendingTag(true);
                            toast.success(
                                `${
                                    dataAssociate.length > 0
                                        ? t(
                                              "The franchise mode of information has been successfully changed"
                                          )
                                        : t(
                                              "The Mode of information is changed successfully"
                                          )!
                                }`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );

                            if (dataAssociate.length === 0 && !oneShop) {
                                setDataIsUpdated!(true);
                                setIsAddModeOfInformationClicked(
                                    !isAddModeOfInformationClicked
                                );
                            } else if (oneShop) {
                                setDataIsUpdated!(true);
                                setIsAddModeOfInformationClicked(
                                    !isAddModeOfInformationClicked
                                );
                            }

                            setIsEdited(false);
                            getTag();
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    async function getTag() {
        const newList: any[] = [];
        await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/tag?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&deviceType=Information_mode_tag`
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    for (let element of result.data) {
                        newList.push(element.Designation);
                    }
                    setTags(newList);
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    async function getMOS(url: string) {
        const newList: any[] = [];
        await fetch(url)
            .then((res) => res.json())
            .then(
                (result) => {
                    for (let element of result) {
                        newList.push(element[`designation`]);
                    }
                    setModeOfSales(newList);
                },
                (error) => {
                    console.log(error);
                }
            );
    }
    async function getSOS() {
        const newList: any[] = [];
        await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport?userId=${userID}&franchiseId=${franchiseID}&shopId=${shopID}&type=activated`
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    for (let element of result.data) {
                        newList.push({
                            label: element[`Support de vente`],
                            value: element[`Id`],
                        });
                    }
                    setSupportOfSales(newList);
                },
                (error) => {
                    console.log(error);
                }
            );
    }
    async function getMI() {
        const newList: any[] = [];
        await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/generalList?userId=${userID}&franchiseId=${franchiseID}&type=mode_of_information_list`
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    for (let element of result.data) {
                        newList.push({
                            value: element.Id,
                            label: element.Designation,
                        });
                    }
                    setInformationModes(newList);
                },
                (error) => {
                    console.log(error);
                }
            );
    }
    function checkIfExistCombination() {
        let isExist: boolean = false;

        let allDataMI: any = [...modeInformationData.data, ...dataArchive];
        allDataMI.forEach((el: any) => {
            if (
                el[t("Mode of information")] === informationMode &&
                el[t("Sales support")].label === supportOfSale.label
            ) {
                isExist = true;
            }
        });
        return isExist;
    }
    const formatCreateLabel = (inputValue: any) => (
        <span>
            {inputValue} ({t("New tag")})
        </span>
    );

    function AssociateModeOfInformation() {
        let idsAssociated = [0];
        let associated: any[] = [];
        if (shopsModeInformationData.data !== undefined) {
            if (shopsModeInformationData.data.length > 0) {
                shopsModeInformationData.data.forEach((element: any) => {
                    if (element.subRows !== undefined) {
                        element.subRows.forEach((el: any) => {
                            if (el.Id === dataInformation.Id) {
                                idsAssociated.push(el.shopId);
                                associated.push(el);
                            }
                        });
                    }
                });
            }
        }
        idsAssociated = [...new Set(idsAssociated)];
        return associated;
    }

    useEffect(() => {
        if (dataInformation.Id) {
            setInputInformationMode(false);
            setInputModeOfSale(false);
            setInputSupportOfSale(false);
            setSelectName(false);
            setIsEdited(false);
            setCanValidate(true);
            setTitle(t("Modify a mode d'information"));
            setInformationMode(dataInformation[t("Information mode")]);

            setTagsData(
                Array.isArray(dataInformation.tag) ? dataInformation.tag : []
            );
            setSupportOfSale(
                Object.keys(dataInformation[t("Sales support")]).length > 0
                    ? dataInformation[t("Sales support")]
                    : {}
            );
            getMOS(
                `${
                    process.env.REACT_APP_API_V2_URL
                }/settings/general/MI_Get_MV_From_SV?userId=${userID}&franchiseId=${franchiseID}&shopId=${shopID}&idSV=${
                    dataInformation[t("Sales support")]?.value
                }`
            );
            setModeOfSale(
                Array.isArray(dataInformation[t("Sales method")])
                    ? dataInformation[t("Sales method")]
                    : []
            );

            setTag(
                Array.isArray(dataInformation["tag"])
                    ? dataInformation["tag"]
                    : []
            );
            setDisplayName(dataInformation["Nom d'affichage"]);
        } else {
            setInformationMode("");
            setTitle(t("Add a Mode of information"));
            setTag([]);
            setTagsData([]);
            clearData();
            setInputInformationMode(false);
            setInputModeOfSale(false);
            setInputSupportOfSale(false);
            setSelectName(false);
            setIsEdited(false);
            setCanValidate(true);
            clearMultiCustomSelectionData();
            setDisplayName("");
            setModeOfSale([]);
            setSupportOfSale({});
            setIsNameExist(false);
            setCanValidate(true);
        }
        setAssociated(AssociateModeOfInformation);
        // eslint-disable-next-line
    }, [dataInformation, t, isAddNewModeOfInformationButtonClicked]);
    React.useEffect(() => {
        let exist: boolean = checkIfExistCombination();

        if (
            exist &&
            informationMode !== null &&
            ((dataInformation.Id === undefined &&
                informationMode !== "" &&
                supportOfSale.label !== "") ||
                (dataInformation.Id !== undefined &&
                    dataInformation[t("Mode of information")] !==
                        informationMode &&
                    dataInformation[t("Sales support")].label !==
                        supportOfSale.label))
        ) {
            setIsNameExist(true);
        } else {
            setIsNameExist(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataInformation, supportOfSale, informationMode]);

    useEffect(() => {
        getMI();
        getSOS();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        getTag();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSendingTag]);

    useEffect(() => {
        clearData();
        clearMultiCustomSelectionData();
    }, [isAddModeOfInformationClicked, dataInformation.Id]);

    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader className="d-flex align-items-center justify-content-between">
                    <h5
                        className={classnames(
                            "pt-3 mdi_txt_FRtitleFormModeOfInformation",
                            {
                                mdi_txt_FReditModeInformation:
                                    title === t("Modify a mode d'information"),
                            }
                        )}
                    >
                        {title}
                    </h5>
                    <CrossIcon
                        className="icon-close-notification__clz mdi_icn_FRcloseForm"
                        height={13}
                        width={13}
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsEdited(false);
                                    setDataInformation([]);
                                    setIsAddModeOfInformationClicked!(
                                        !isAddModeOfInformationClicked
                                    );
                                    setIsEdited(false);
                                });
                            } else {
                                setDataInformation([]);
                                setIsAddModeOfInformationClicked!(
                                    !isAddModeOfInformationClicked
                                );
                                setIsEdited(false);
                            }
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className="pl-3 pr-5"
                    style={{
                        maxHeight:
                            pathname === "/peripherals/Choice"
                                ? "530px"
                                : "530px",
                        overflowY: " scroll",
                    }}
                >
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Mode of information")}
                        </StyledLabel>
                        <Creatable
                            className={classnames(
                                "mdi_inp_FRinformationModes",
                                {
                                    invalid__clz:
                                        inputInformationMode || isNameExist,
                                    "not-allowed-input__clz":
                                        dataInformation.Id !== undefined ||
                                        (oneShop &&
                                            dataInformation.Id !== undefined),
                                }
                            )}
                            isClearable
                            autocomplete="off"
                            name="InformationMode"
                            onChange={(e: any) => {
                                setInformationMode(e === null ? "" : e.label);
                                setIsEdited(true);
                                setInputInformationMode(
                                    e === null ? false : false
                                );
                                if (e !== null) {
                                    if (
                                        e.label !== "" &&
                                        e.label !== undefined
                                    ) {
                                        setDisplayName(e.label.trim());
                                        setSelectName(false);
                                    }
                                } else {
                                    setDisplayName("");
                                }
                            }}
                            value={
                                informationMode === ""
                                    ? null
                                    : {
                                          label: informationMode,

                                          value: informationMode,
                                      }
                            }
                            options={informationModes}
                            placeholder={`${t("Select")}...`}
                            type="text"
                            maxMenuHeight={150}
                            isValidNewOption={() => false}
                            //formatCreateLabel={formatCreateLabelMI}
                            autoFocus={dataInformation.Id === undefined}
                        />
                        {inputInformationMode ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a mode of information")}
                            </div>
                        ) : null}
                        {isNameExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {
                                    t(
                                        "The combination mode of information , support of sale already exists"
                                    )!
                                }
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Display name")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames("mdi_inp_FRdisplayName", {
                                input__clz: selectName,
                            })}
                            autocomplete="off"
                            name="designation"
                            onChange={(e: any) => {
                                setDisplayName(e.target.value);
                                setIsEdited(true);
                                setSelectName(false);
                            }}
                            value={displayName}
                            placeholder={t("Write")}
                            type="text"
                            autoFocus={dataInformation.Id !== undefined}
                        />
                        <div className="d-flex flex-wrap "></div>
                        {selectName ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a display name")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Support of sale")}
                        </StyledLabel>
                        <Creatable
                            isClearable
                            maxMenuHeight={150}
                            isValidNewOption={() => false}
                            onDelete={() => {
                                setIsEdited(true);
                                setInputSupportOfSale(false);
                            }}
                            options={supportOfSales}
                            onChange={(e: any) => {
                                setIsEdited(true);
                                setInputSupportOfSale(false);
                                setSupportOfSale(e === null ? "" : e);
                                if (e !== null) {
                                    getMOS(
                                        `${process.env.REACT_APP_API_V2_URL}/settings/general/MI_Get_MV_From_SV?userId=${userID}&franchiseId=${franchiseID}&shopId=${shopID}&idSV=${e.value}`
                                    );
                                    setModeOfSale([]);
                                    clearMultiCustomSelectionData();
                                }
                            }}
                            clearable
                            value={
                                Object.keys(supportOfSale).length === 0
                                    ? null
                                    : {
                                          label: supportOfSale?.label,
                                          value: supportOfSale?.value,
                                      }
                            }
                            className={classnames("mdi_inp_FRsalesSupport", {
                                invalid__clz: inputSupportOfSale || isNameExist,
                                "not-allowed-input__clz":
                                    dataInformation.Id !== undefined ||
                                    (oneShop &&
                                        dataInformation.Id !== undefined),
                            })}
                            name="supportOfSale"
                            placeholder={`${t("Select")}...`}
                        />
                        {inputSupportOfSale ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a support of sale")}
                            </div>
                        ) : null}
                        {isNameExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {
                                    t(
                                        "The combination mode of information , support of sale already exists"
                                    )!
                                }
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Sales method")}
                        </StyledLabel>

                        <MultiCustomSelect
                            onDelete={() => {
                                setIsEdited(true);
                                setInputModeOfSale(false);
                            }}
                            setResult={setModeOfSale}
                            result={modeOfSale}
                            data={modeOfSales}
                            onChange={(e: any) => {
                                setIsEdited(true);
                                setInputModeOfSale(false);
                                setModeOfSale([...modeOfSale, e.label]);
                            }}
                            className={classnames("mdi_inp_FRmodeOfSale", {
                                invalid__clz: inputModeOfSale,
                                "not-allowed-input__clz":
                                    Object.keys(supportOfSale).length === 0,
                            })}
                            value={`${t("Select")}...`}
                            placeholder={`${t("Select")}...`}
                            name="modeOfSale"
                        />
                        {inputModeOfSale ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a mode of sale")}
                            </div>
                        ) : null}
                    </AvGroup>

                    <AvGroup>
                        <StyledLabel htmlFor="text" className="mt-3">
                            {t("Tag")}
                        </StyledLabel>
                        <MultiCustomCreatableSelection
                            isSendingTag={isSendingTag}
                            onDelete={() => {
                                setIsEdited(true);
                            }}
                            setResult={setTagsData}
                            result={tagsData}
                            data={tags}
                            onChange={(e: any) => {
                                setIsEdited(true);
                            }}
                            className="mdi_inp_FRtags"
                            placeholder={`${t("Select")}…`}
                            value={""}
                            name="tag"
                            noOptionsMessage={() => t("No options")}
                            formatCreateLabel={formatCreateLabel}
                        />
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="mr-2 mdi_btn_FRcancel"
                        variant="light"
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsAddModeOfInformationClicked(
                                        !isAddModeOfInformationClicked
                                    );
                                    setIsEdited(false);
                                });
                            } else {
                                setIsAddModeOfInformationClicked(
                                    !isAddModeOfInformationClicked
                                );
                                setIsEdited(false);
                            }
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        className="mdi_btn_FRvalidate"
                        variant="primary"
                        onClick={() => {
                            if (dataInformation.Id !== undefined) {
                                let sameModeOfInformation: any = modeInformationFranchise.find(
                                    (el: any) => el.Id === dataInformation.Id
                                );

                                let tagDiff: any[] = arrayDiff(
                                    sameModeOfInformation.tag,
                                    tagsData
                                );
                                let SOSDiff: any[] = arrayDiff(
                                    sameModeOfInformation[t("Support of sale")],
                                    supportOfSale
                                );
                                let MOSDiff: any[] = arrayDiff(
                                    sameModeOfInformation[t("Sales method")],
                                    modeOfSale
                                );

                                if (displayName === "") {
                                    setSelectName(true);
                                } else {
                                    setSelectName(false);
                                }
                                if (informationMode === "") {
                                    setInputInformationMode(true);
                                } else {
                                    setInputInformationMode(false);
                                }
                                if (modeOfSale.length === 0) {
                                    setInputModeOfSale(true);
                                } else {
                                    setInputModeOfSale(false);
                                }
                                if (Object.keys(supportOfSale).length === 0) {
                                    setInputSupportOfSale(true);
                                } else {
                                    setInputSupportOfSale(false);
                                }
                                if (
                                    isEdited === false &&
                                    tagDiff.length === 0 &&
                                    MOSDiff.length === 0 &&
                                    SOSDiff.length === 0 &&
                                    sameModeOfInformation[
                                        t("Mode of information")
                                    ].toUpperCase() ===
                                        informationMode.toUpperCase()
                                ) {
                                    setIsModalOpened(true);
                                    setCanValidate(true);
                                } else if (
                                    displayName.trim() !== "" &&
                                    informationMode.trim() !== "" &&
                                    modeOfSale.length !== 0 &&
                                    supportOfSale.length !== 0 &&
                                    !isNameExist
                                ) {
                                    editModeOfInformation();
                                }
                            } else {
                                if (displayName === "") {
                                    setSelectName(true);
                                } else {
                                    setSelectName(false);
                                }
                                if (informationMode === "") {
                                    setInputInformationMode(true);
                                } else {
                                    setInputInformationMode(false);
                                }
                                if (modeOfSale.length === 0) {
                                    setInputModeOfSale(true);
                                } else {
                                    setInputModeOfSale(false);
                                }
                                if (Object.keys(supportOfSale).length === 0) {
                                    setInputSupportOfSale(true);
                                } else {
                                    setInputSupportOfSale(false);
                                }

                                if (
                                    displayName.trim() !== "" &&
                                    informationMode.trim() !== "" &&
                                    modeOfSale.length !== 0 &&
                                    supportOfSale.length !== 0 &&
                                    !isNameExist
                                ) {
                                    addModeOfInformation();
                                }
                            }
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
            <ConfirmationEditMessage
                setDataIsUpdated={setDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddModeOfInformationClicked}
                setIsAddClicked={setIsAddModeOfInformationClicked}
                setEditedData={setDataInformation}
            />
            {isModalOpen && (
                <ConfirmationModificationMessage
                    isModalOpened={isModalOpen}
                    setIsModalOpened={setIsModalOpen}
                    setIsEdited={setIsEdited}
                    setDataIsUpdated={setDataIsUpdated}
                    dataModeInformation={dataEdited}
                    associated={associated}
                    setIsSendingTag={setIsSendingTag}
                    dataTable={dataTable}
                    setIsAddModeOfInformationClicked={
                        setIsAddModeOfInformationClicked
                    }
                    setLimit={setLimit}
                />
            )}

            {isModalModifiedOpened && (
                <ConfirmationAssociationModal
                    isModalOpened={isModalModifiedOpened}
                    setIsModalOpened={setIsModalModifiedOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    InformationMode={informationMode}
                    supportOfSale={supportOfSale}
                    modeOfSale={modeOfSale}
                    displayName={displayName}
                    tags={tagsData}
                    setIsSending={setIsSendingTag}
                />
            )}
        </React.Fragment>
    );
}
