import React from "react";
import { useTranslation } from "react-i18next";
import { CheckboxIcon, StyledH2, StyledLabel } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useSnapshot } from "valtio";
import { mutate } from "swr";
import { toast } from "react-toastify";

import {
    store,
    setIsModalConfirmationConfigurationKiosk,
} from "../../store/project/index";
import {
    setChosenTemplate,
    setInitItemsFromGeneralSetting,
    setProject,
} from "@store";
import { store as userStore } from "@components/VerticalLayout/store";
import kioskSettingStore from "@pages/GeneralConfig/store";

import { capitalize } from "../../helpers/general";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

type PropsType = {
    setIsDataUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    isDataUpdated: boolean | number;
};

export default function ModalConfirmationConfigurationKiosk({
    setIsDataUpdated,
    isDataUpdated,
}: PropsType) {
    const { t } = useTranslation();
    const { isModalConfirmationConfigurationKiosk, kioskProject } = useSnapshot(
        store
    );
    const { userID, projectId, franchiseID, oneShop, operatorID } = useSnapshot(
        userStore
    );
    const { isKioskSetting, projectRowData } = useSnapshot(kioskSettingStore);

    async function handleUpdateProjectShopsButtonOnClickEvent() {
        let apiUrl: any;
        let idShop: any[] = [];
        projectRowData.subRows !== undefined &&
            projectRowData.subRows.forEach((row: any) => {
                idShop.push(row.id_boutique);
            });
        apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/template`;
        idShop.forEach(async (element: any) => {
            let shopProject: any = await getDataFromGeneralSetting(element);
            let dataShop = {
                userId: userID,
                franchiseId: franchiseID,
                project_id: "PROJECT",
                projectId: projectId,
                shopId: element,
                operatorId: operatorID,
                data: {
                    template: shopProject.template,
                    files: shopProject.files,
                },
            };

            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bareer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify(dataShop),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error while saving selection!");
                            }
                            toast.success(
                                `${t("Template saved successfully")}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setIsModalConfirmationConfigurationKiosk(
                                !isModalConfirmationConfigurationKiosk
                            );
                            setIsDataUpdated(true);
                        })
                );
            } catch (e: any) {
                toast.error(`${t("There's an error")}!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        });
    }

    async function OverwriteTemplateOneShop() {
        let apiUrlOverwiteTemplate = `${process.env.REACT_APP_API_V2_URL}/settings/application/overwite/template`;
        if (projectRowData.subRows.length !== 0) {
            projectRowData.subRows.forEach(async (element: any) => {
                let savedData = {
                    userId: userID,
                    franchiseId: franchiseID,
                    shopId: projectRowData.idShop,
                    appId: "KIOSK",
                    operatorId: operatorID,
                    appId_children: element.appId,
                    templateKey: projectRowData.projectId,
                };
                try {
                    mutate(
                        apiUrlOverwiteTemplate,
                        await fetch(apiUrlOverwiteTemplate, {
                            headers: {
                                "Content-Type": "application/json",
                                authorization: `Bareer ${localStorage.getItem(
                                    "jwt"
                                )}`,
                            },
                            body: JSON.stringify(savedData),
                            method: "POST",
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                if (data.error) {
                                    throw Error(
                                        "Error while saving selection!"
                                    );
                                } else {
                                    toast.success(
                                        `${t("Template saved successfully")}`,
                                        {
                                            position: toast.POSITION.TOP_CENTER,
                                            autoClose: 2000,
                                            theme: "colored",
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            icon: (
                                                <CheckboxIcon
                                                    height={25}
                                                    width={25}
                                                    fill="white"
                                                />
                                            ),
                                        }
                                    );
                                }
                                setIsModalConfirmationConfigurationKiosk(
                                    !isModalConfirmationConfigurationKiosk
                                );

                                setIsDataUpdated(!isDataUpdated);
                            })
                    );
                } catch (e: any) {
                    console.log(`${t("There's an error")}!`);
                }
            });
        }
    }

    async function OverwriteTemplate() {
        let apiUrlOverwiteTemplate = `${process.env.REACT_APP_API_V2_URL}/settings/application/overwite/template`;
        if (isKioskSetting || oneShop) {
            projectRowData.subRows.forEach(async (element: any) => {
                if (element.template !== "") {
                    let savedData = {
                        userId: userID,
                        operatorId: operatorID,
                        franchiseId: franchiseID,
                        shopId: projectRowData.id_boutique,
                        appId: "KIOSK",
                        appId_children: element.appId,
                        templateKey: projectRowData.id_project,
                    };
                    try {
                        mutate(
                            apiUrlOverwiteTemplate,
                            await fetch(apiUrlOverwiteTemplate, {
                                headers: {
                                    "Content-Type": "application/json",
                                    authorization: `Bareer ${localStorage.getItem(
                                        "jwt"
                                    )}`,
                                },
                                body: JSON.stringify(savedData),
                                method: "POST",
                            })
                                .then((response) => response.json())
                                .then((data) => {
                                    if (data.error) {
                                        throw Error(
                                            "Error while saving selection!"
                                        );
                                    } else {
                                        handleUpdateProjectShopsButtonOnClickEvent();
                                    }
                                })
                        );
                    } catch (e: any) {
                        console.log(`${t("There's an error")}!`);
                    }
                }
            });
        } else {
            projectRowData?.subRows.forEach((element: any) => {
                if (element.subRows.length !== 0) {
                    element?.subRows.forEach((el: any) => {
                        let savedData = {
                            userId: userID,
                            franchiseId: franchiseID,
                            shopId: element.id_boutique,
                            operatorId: operatorID,
                            appId: "KIOSK",
                            appId_children: el.appId,
                            templateKey: el.id_project,
                        };
                        try {
                            mutate(
                                apiUrlOverwiteTemplate,
                                fetch(apiUrlOverwiteTemplate, {
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: `Bareer ${localStorage.getItem(
                                            "jwt"
                                        )}`,
                                    },
                                    body: JSON.stringify(savedData),
                                    method: "POST",
                                })
                                    .then((response) => response.json())
                                    .then((data) => {
                                        if (data.error) {
                                            throw Error(
                                                "Error while saving selection!"
                                            );
                                        } else {
                                            handleUpdateProjectShopsButtonOnClickEvent();
                                        }
                                    })
                            );
                        } catch (e: any) {
                            console.log(`${t("There's an error")}!`);
                        }
                    });
                }
            });
        }
    }

    async function getDataFromGeneralSetting(shopId: string) {
        let template: any;
        const getLanguagesUrl: Response = await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/consomation/langue?franchiseId=${franchiseID}&shopId=${shopId}`
        );
        const languages = await getLanguagesUrl.json();

        const getReglementUrl: Response = await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/consomation/reglement?franchiseId=${franchiseID}&shopId=${shopId}`
        );
        const reglements = await getReglementUrl.json();

        const getInformationMethodUrl: Response = await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/consomation/information?franchiseId=${franchiseID}&shopId=${shopId}`
        );

        const getSalesMethodUrl: Response = await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/consomation/sale?franchiseId=${franchiseID}&shopId=${shopId}`
        );

        const getLogoUrl: Response = await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/consomation/logo?franchiseId=${franchiseID}&shopId=${shopId}`
        );

        const informationModes = await getInformationMethodUrl.json();
        const saleMethods = await getSalesMethodUrl.json();
        const logo = await getLogoUrl.json();

        JSON.parse(
            JSON.stringify(kioskProject.template.content.salesMethods)
        ).items.forEach((element: any) => {
            saleMethods.forEach((el: any) => {
                if (el.id === element.id) {
                    el.active = element.active;
                    el.languages = element.languages;
                }
            });
        });
        JSON.parse(
            JSON.stringify(kioskProject.template.content.languages)
        ).items.forEach((element: any) => {
            languages.forEach((el: any) => {
                if (el.id === element.id) {
                    el.active = element.active;
                }
            });
        });

        JSON.parse(
            JSON.stringify(kioskProject.template.content.informationModes)
        ).items.forEach((element: any) => {
            informationModes.forEach((el: any) => {
                if (el.id === element.id) {
                    el.active = element.active;
                    el.languages = element.languages;
                }
            });
        });
        JSON.parse(
            JSON.stringify(kioskProject.template.content.meansOfPayment)
        ).items.forEach((element: any) => {
            reglements.forEach((el: any) => {
                if (el.id === element.id) {
                    el.active = element.active;
                    el.languages = element.languages;
                }
            });
        });

        template = {
            ...kioskProject.template,
            content: {
                ...kioskProject.template.content,
                languages: {
                    ...kioskProject.template.content.languages,
                    items: languages,
                },
                salesMethods: {
                    items: saleMethods,
                },
                meansOfPayment: {
                    items: reglements,
                },
                informationModes: {
                    items: informationModes,
                },
                generalDesign: {
                    ...kioskProject.template.content.generalDesign,
                    logo,
                },
            },
        };

        let files = kioskProject.files;
        setProject({
            ...kioskProject,
            template,
            files,
        });
        setInitItemsFromGeneralSetting({
            informationModes,
            saleMethods,
            reglements,
            languages,
            logo,
        });

        setChosenTemplate(template, files);
        let project1 = {
            ...kioskProject,
            template,
        };
        return project1;
        // }
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={isModalConfirmationConfigurationKiosk}
                style={{
                    maxWidth: "650px",
                    maxHeight: "500px",
                    marginTop: "300px",
                }}
            >
                <ModalHeader
                    toggle={() => {
                        setIsModalConfirmationConfigurationKiosk(
                            !isModalConfirmationConfigurationKiosk
                        );
                        setIsDataUpdated(!isDataUpdated);
                    }}
                >
                    <StyledH2>{capitalize(t("Alert"))}</StyledH2>
                </ModalHeader>
                <ModalBody>
                    <StyledLabel
                        className="pl-3 m-0"
                        style={{
                            font: "normal normal normal 20px/27px Segoe UI",
                            whiteSpace: "pre-line",
                            opacity: "1",
                            textAlign: "left",
                            color: "#000000",
                        }}
                    >
                        {!isKioskSetting && !oneShop
                            ? t(
                                  "Attention, if you click on validate, the specific configuration of the shops will be overwritten."
                              )
                            : t(
                                  "Be careful, if you click on validate, the specific configuration of the applications will be overwritten."
                              )}
                        !
                    </StyledLabel>
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <CustomMainColorButton
                        outline
                        variant="primary"
                        onClick={() => {
                            setIsModalConfirmationConfigurationKiosk(
                                !isModalConfirmationConfigurationKiosk
                            );
                            setIsDataUpdated(!isDataUpdated);
                        }}
                        rounded
                    >
                        {t("Cancel")}
                    </CustomMainColorButton>
                    <CustomMainColorButton
                        rounded
                        className="mr-2"
                        variant="primary"
                        onClick={() => {
                            if (oneShop) {
                                OverwriteTemplateOneShop();
                            } else {
                                OverwriteTemplate();
                            }
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
