import React from "react";
import {
    ModalBody,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { LoadingValidationAssociateIcon } from "@components/Common/SvgIcons";
import { useSnapshot } from "valtio";

import { setIsAddPeripheralClicked } from "@pages/Applications/DeviceAssociateModal/store";
import { store as userStore } from "@components/VerticalLayout/store";

import Printer from "../Printer";
import { Monetics } from "../../DeviceAssociateModal/Monetics";
import CashManagement from "../../DeviceAssociateModal/CashManagementApplicationForm/CashManagement";

import "../../index.css";

type MenuWrapperType = {
    setIsDeviceAssociateModalOpened: Function;
    oneShop: boolean;
    isDeviceAssociateModalOpened: boolean;
    rowData: any;
    dataIsUpdated: boolean | number;
    setDataIsUpdated: Function;
};

type TabsMenuType = {
    title: string;
    content: React.ReactNode;
    subMenu?: {
        title: string;
        content: React.ReactNode;
    }[];
};

export function MenuWrapperAssociate({
    setIsDeviceAssociateModalOpened,
    oneShop,
    isDeviceAssociateModalOpened,
    rowData,
    dataIsUpdated,
    setDataIsUpdated,
}: MenuWrapperType) {
    const { t } = useTranslation();
    const { isLoadingAssociateValidation } = useSnapshot(userStore);
    const [activeTab, setActiveTab] = React.useState<number>(0);
    const [activeSubTab, setActiveSubTab] = React.useState<number>(0);
    const [isSubMenuCollapsed, setIsSubMenuCollapsed] = React.useState<boolean>(
        false
    );

    const tabs: TabsMenuType[] = [
        {
            title: t("Printers"),
            content: (
                <Printer
                    setIsDeviceAssociateModalOpened={
                        setIsDeviceAssociateModalOpened
                    }
                    rowData={rowData}
                    oneShop={oneShop}
                    isDeviceAssociateModalOpened={isDeviceAssociateModalOpened}
                    dataIsUpdated={dataIsUpdated}
                    setDataIsUpdated={setDataIsUpdated}
                />
            ),
        },
        {
            title: t("Monetics"),
            content: (
                <Monetics
                    rowData={rowData}
                    setIsDeviceAssociateModalOpened={
                        setIsDeviceAssociateModalOpened
                    }
                    isDeviceAssociateModalOpened={isDeviceAssociateModalOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                />
            ),
        },
        {
            title: t("Coiners"),
            content: (
                <CashManagement
                    rowData={rowData}
                    setIsDeviceAssociateModalOpened={
                        setIsDeviceAssociateModalOpened
                    }
                    isDeviceAssociateModalOpened={isDeviceAssociateModalOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                />
            ),
        },
        {
            title: t("Beepers"),
            content: "",
        },
    ];

    function toggleNav(tab: number) {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
        if (isSubMenuCollapsed === true) {
            setIsSubMenuCollapsed(false);
        }
    }

    function toggleSubNav(tab: number) {
        if (activeSubTab !== tab) {
            setActiveSubTab(tab);
        }
    }

    return (
        <ModalBody className="p-0 modal-associate__clz">
            <div style={{ display: "grid", gridTemplateColumns: "220px 1fr" }}>
                <Nav
                    tabs
                    className="d-flex flex-column"
                    style={{
                        backgroundColor: "#323333",
                        marginBottom: "81px",
                        fontSize: "15px",
                    }}
                >
                    {tabs.map((element, index) => {
                        return (
                            <NavItem
                                key={index}
                                className={classNames("pb-1", {
                                    "active menu-wrapper-items-nav-link-active__clz":
                                        activeTab === index,
                                })}
                            >
                                <NavLink
                                    className={classNames(
                                        "border-0 menu-wrapper-nav-link__clz",
                                        {
                                            "active menu-wrapper-items-nav-link-active__clz":
                                                activeTab === index,
                                            "not-allowed-icon__clz":
                                                element.title ===
                                                    t("Monetics") ||
                                                element.title === t("Coiners"),
                                        }
                                    )}
                                    onClick={() => {
                                        toggleNav(index);
                                        setIsAddPeripheralClicked(false);
                                        if (
                                            element.title === t("Scale") ||
                                            element.title === t("Beepers") ||
                                            element.title === t("Smartill")
                                        ) {
                                            setActiveTab(0);
                                        }
                                    }}
                                >
                                    <span className="px-2">
                                        {element.title}
                                    </span>
                                </NavLink>
                                {element.title === t("Scale") &&
                                isSubMenuCollapsed
                                    ? element.subMenu?.map(
                                          (
                                              item: {
                                                  title: string;
                                                  content: React.ReactNode;
                                              },
                                              subMenuIndex: number
                                          ): JSX.Element => (
                                              <NavLink
                                                  disabled={
                                                      item.title ===
                                                          t("Monetics") ||
                                                      item.title ===
                                                          t("Coiners")
                                                  }
                                                  key={subMenuIndex}
                                                  className={classNames(
                                                      "border-0 text-nowrap pl-5 menu-wrapper-nav-link__clz",
                                                      {
                                                          "active options-nav-link-active__clz":
                                                              activeSubTab ===
                                                              subMenuIndex,
                                                      }
                                                  )}
                                                  onClick={() => {
                                                      toggleSubNav(
                                                          subMenuIndex
                                                      );
                                                  }}
                                              >
                                                  <span className="px-2">
                                                      {item.title}
                                                  </span>
                                              </NavLink>
                                          )
                                      )
                                    : null}
                            </NavItem>
                        );
                    })}
                </Nav>
                <TabContent activeTab={activeTab}>
                    {tabs.map((element, index) => {
                        return element.subMenu !== undefined &&
                            isSubMenuCollapsed ? (
                            element.subMenu?.map(
                                ({ content }, subMenuIndex) => {
                                    return (
                                        <TabPane
                                            tabId={index}
                                            className={classNames({
                                                "d-none":
                                                    subMenuIndex !==
                                                    activeSubTab,
                                            })}
                                            style={{
                                                height: "100%",
                                                width: "100%",
                                                overflow: "hidden",
                                            }}
                                            key={subMenuIndex}
                                        >
                                            {content}
                                        </TabPane>
                                    );
                                }
                            )
                        ) : (
                            <TabPane
                                tabId={index}
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    overflow: "hidden",
                                }}
                                key={index}
                            >
                                {element.content}
                            </TabPane>
                        );
                    })}
                </TabContent>
                <LoadingValidationAssociateIcon
                    style={{
                        position: "absolute",
                        left: "44%",
                        top: "30%",
                        zIndex: 10,
                        display: isLoadingAssociateValidation ? "" : "none",
                    }}
                />
            </div>
        </ModalBody>
    );
}
