import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { ListManager } from "react-beautiful-dnd-grid";
import { uid } from "react-uid";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import classNames from "classnames";

import {
    generalConfigStore,
    setAuthenticationModesOptions,
    setImageKey,
    setProjectFiles,
    setIsAuthenticationModeSkipped,
    setIsPageSkipped,
} from "@store";

import { ResetModalWrapper } from "@components/Common/ResetModalWrapper";
import { SwitchWrapper } from "@components/Common/SwitchWrapper";
import { ScrollContainer } from "@components/ScrollContainer";
import kioskSettingStore from "@pages/GeneralConfig/store";
import { CardWrapperSpecific } from "@components/Common/CardWrapperSpecific";
import { AuthenticationModesCardWrapper } from "./AuthenticationModesCardWrapper";

import "../index.css";

export function AuthenticationMode(): JSX.Element {
    const { t } = useTranslation();

    const {
        project: { template, files: images },
        templates,
        defaultLanguage,
        isResetModalOpened,
    } = useSnapshot(generalConfigStore);
    const { isKioskSetting } = useSnapshot(kioskSettingStore);

    const authenticationModesItems = (template.content
        .authenticationModes as ProjectContentItemType).items;

    let isAuthenticationModesSkipped: boolean | ItemContentType;

    if ((template.pages.ways.authenticationModes as PageType) !== undefined) {
        isAuthenticationModesSkipped = (template.pages.ways
            .authenticationModes as PageType).skipped;
    } else {
        isAuthenticationModesSkipped = (template.pages.elements
            .authenticationModes as PageType).skipped;
    }
    const [authenticationModes, setAuthenticationModes] = React.useState<
        ProjectMainContentItemType[]
    >([]);
    const [
        localIsResetModalOpened,
        setLocalIsResetModalOpened,
    ] = React.useState<boolean>(isResetModalOpened);

    const [skipStep, setSkipStep] = React.useState<boolean>(false);

    function handleOnDragEndEvent(
        sourceIndex: number,
        destinationIndex: number
    ) {
        if (destinationIndex === sourceIndex) {
            return;
        }

        const newItems = [...authenticationModes];
        const [reorderedItem] = newItems.splice(sourceIndex, 1);
        newItems.splice(destinationIndex, 0, reorderedItem);
        setAuthenticationModes(newItems);
        setAuthenticationModesOptions(newItems);
    }

    const initAuthenticationModes = (templates.filter(
        (element) => element.id === template.id
    )[0].content.authenticationModes as ProjectContentItemType).items;

    const initSkipStep = (templates.filter(
        (element) => element.id === template.id
    )[0].pages.elements.authenticationModes as PageType).skipped;

    function handleResetOnClickEvent() {
        setAuthenticationModes(initAuthenticationModes);
        setAuthenticationModesOptions(initAuthenticationModes);

        const imagesArray = [...images];
        authenticationModes
            .map((item) => item.id)
            .forEach((id) => {
                const image = imagesArray.find((element) => element.id === id);

                if (image !== undefined) {
                    const imageIndex = imagesArray.indexOf(image);

                    const localContent = image.content as ImageItemLanguagesType;

                    imagesArray[imageIndex] = {
                        ...image,
                        content: {
                            ...localContent,
                            [defaultLanguage.name]: {
                                ...localContent[defaultLanguage.name],
                                path:
                                    localContent[defaultLanguage.name]
                                        .defaultImage,
                            },
                        } as ImageItemLanguagesType,
                    };
                }
            });

        setProjectFiles(imagesArray);
        setImageKey(uid(imagesArray));
        setSkipStep(initSkipStep as boolean);
        setIsAuthenticationModeSkipped(initSkipStep as boolean);
    }

    React.useEffect(() => {
        setAuthenticationModes(
            (template.content.authenticationModes as ProjectContentItemType)
                .items
        );
        if (
            (template.pages.ways.authenticationModes as PageType) !== undefined
        ) {
            setSkipStep(
                (template.pages.ways.authenticationModes as PageType)
                    .skipped as boolean
            );
        } else {
            setSkipStep(
                (template.pages.elements.authenticationModes as PageType)
                    .skipped as boolean
            );
        }
    }, [template]);

    React.useEffect(() => {
        if (authenticationModesItems.filter((item) => item.active).length > 1) {
            setIsAuthenticationModeSkipped(false);
            setIsPageSkipped("authenticationModes", false);
            setSkipStep(false);
        }
    }, [authenticationModesItems]);

    return (
        <React.Fragment>
            <div
                className="h-100"
                style={{
                    display: "grid",
                    gridTemplateRows: "0.1fr auto",
                }}
            >
                <div
                    className={classNames("d-grid__clz ", {
                        reset__clz:
                            authenticationModesItems.filter(
                                (item) => item.active
                            ).length !== 1,
                    })}
                >
                    <div className="d-flex align-items-center justify-content-between cursor__clz">
                        <span
                            style={{
                                font: "normal normal 600 35px/61px Nunito Sans",
                            }}
                        >
                            {t("Authentication modes")}
                        </span>
                        {authenticationModesItems.filter((item) => item.active)
                            .length === 1 ? (
                            <div
                                className="d-flex align-items-center justify-content-start cursor__clz"
                                style={{ gap: "20px" }}
                            >
                                <div
                                    style={{
                                        font:
                                            "normal normal 600 24px/32px Nunito Sans",
                                    }}
                                >
                                    {t(
                                        "Do not offer this step in the customer ways"
                                    )}
                                </div>
                                <SwitchWrapper
                                    onChange={() => {
                                        setSkipStep(!skipStep);

                                        setIsAuthenticationModeSkipped(
                                            !isAuthenticationModesSkipped
                                        );
                                    }}
                                    checked={skipStep}
                                />
                            </div>
                        ) : null}
                    </div>
                    <div className="d-flex align-items-center justify-content-end">
                        <StyledIconButton
                            title={t("Reset to default settings")}
                            outline={true}
                            variant="danger"
                            className="m-0 p-0"
                            icon="RefreshIcon"
                            style={{ height: "20%" }}
                            onClick={() => {
                                setLocalIsResetModalOpened(
                                    !localIsResetModalOpened
                                );
                            }}
                        >
                            <RefreshIcon
                                height={20}
                                width={20}
                                className="Cmn_btn_resetModifications"
                            />
                        </StyledIconButton>
                    </div>
                </div>

                {authenticationModes.length > 8 ? (
                    <ScrollContainer>
                        {isKioskSetting === true ? (
                            <React.Fragment>
                                <div
                                    className="d-flex flex-row justify-content-center align-items-center"
                                    style={{ gap: "50px" }}
                                >
                                    {authenticationModes
                                        .slice(0, 4)
                                        .map((item, index) => {
                                            return (
                                                <CardWrapperSpecific
                                                    index={index}
                                                    item={item}
                                                    setItems={
                                                        setAuthenticationModes
                                                    }
                                                    setConfig={
                                                        setAuthenticationModesOptions
                                                    }
                                                />
                                            );
                                        })}
                                </div>
                                <div
                                    className="d-flex flex-row justify-content-center align-items-center"
                                    style={{ gap: "50px" }}
                                >
                                    {authenticationModes
                                        .slice(4)
                                        .map((item, index) => {
                                            return (
                                                <CardWrapperSpecific
                                                    index={index}
                                                    item={item}
                                                    setItems={
                                                        setAuthenticationModes
                                                    }
                                                    setConfig={
                                                        setAuthenticationModesOptions
                                                    }
                                                />
                                            );
                                        })}
                                </div>
                            </React.Fragment>
                        ) : (
                            <div
                                className="drag-drop-wrapper__clz d-flex flex-column justify-content-center cursor__clz"
                                style={{
                                    gap: "25px",
                                }}
                            >
                                <ListManager
                                    items={authenticationModes}
                                    direction="horizontal"
                                    maxItems={4}
                                    render={(item) => {
                                        const index = authenticationModes.findIndex(
                                            (
                                                authMode: ProjectMainContentItemType
                                            ) => {
                                                return (
                                                    authMode.name === item.name
                                                );
                                            }
                                        );

                                        return (
                                            <AuthenticationModesCardWrapper
                                                key={index}
                                                item={item}
                                                index={index}
                                                setItems={
                                                    setAuthenticationModes
                                                }
                                            />
                                        );
                                    }}
                                    onDragEnd={handleOnDragEndEvent}
                                />
                            </div>
                        )}
                    </ScrollContainer>
                ) : isKioskSetting === true ? (
                    <React.Fragment>
                        <div
                            className="d-flex flex-row justify-content-center align-items-center"
                            style={{ gap: "50px" }}
                        >
                            {authenticationModes
                                .slice(0, 3)
                                .map((item, index) => {
                                    return (
                                        <CardWrapperSpecific
                                            index={index}
                                            item={item}
                                            setItems={setAuthenticationModes}
                                            setConfig={
                                                setAuthenticationModesOptions
                                            }
                                        />
                                    );
                                })}
                        </div>
                        <div
                            className="d-flex flex-row justify-content-center align-items-center"
                            style={{ gap: "50px" }}
                        >
                            {authenticationModes.slice(3).map((item, index) => {
                                return (
                                    <CardWrapperSpecific
                                        index={index}
                                        item={item}
                                        setItems={setAuthenticationModes}
                                        setConfig={
                                            setAuthenticationModesOptions
                                        }
                                    />
                                );
                            })}
                        </div>
                    </React.Fragment>
                ) : (
                    <div
                        className="drag-drop-wrapper__clz d-flex flex-column justify-content-center cursor__clz"
                        style={{ gap: "30px" }}
                    >
                        <ListManager
                            items={authenticationModes}
                            direction="horizontal"
                            maxItems={
                                authenticationModes.length > 4 &&
                                authenticationModes.length <= 6
                                    ? 3
                                    : 4
                            }
                            render={(item) => {
                                const index = authenticationModes.findIndex(
                                    (authMode: ProjectMainContentItemType) => {
                                        return authMode.name === item.name;
                                    }
                                );

                                return (
                                    <AuthenticationModesCardWrapper
                                        key={index}
                                        item={item}
                                        index={index}
                                        setItems={setAuthenticationModes}
                                    />
                                );
                            }}
                            onDragEnd={handleOnDragEndEvent}
                        />
                    </div>
                )}
            </div>

            <ResetModalWrapper
                handleResetOnClickEvent={handleResetOnClickEvent}
                localIsResetModalOpened={localIsResetModalOpened}
                setLocalIsResetModalOpened={setLocalIsResetModalOpened}
            />
        </React.Fragment>
    );
}
