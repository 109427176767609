import React from "react";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import {
    Dropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
} from "reactstrap";
import { toast } from "react-toastify";
import {
    VerticalDotsIcon,
    SettingsIcon,
    DuplicateIcon,
    TrashIcon,
    EyeIcon,
    ShieldExclamationIcon,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";

import {
    store,
    setIsModalAssociateStore,
    setIsModesModalPublishOpened,
    setIsCreatedCoping,
    setIsModalArchiveOpened,
    setIsModalDissociateOpened,
    setIsConfirmationPublishProject,
} from "../../store/project/index";
import { setProjectId } from "@components/VerticalLayout/store";
import {
    setChosenTemplate,
    setIsConfigActive,
    setIsProjectModesModalOpened,
    setProject,
    setSelectedSaleModeValue,
    setItems,
    setIsCustomerAccountActive,
    setSelectedLanguage,
    setDefaultLanguage,
    setIsModalOpened,
    setInitDesignStoreVariables,
    setInitStoreVariables,
    setDefaultWays,
    setInitItemsFromGeneralSetting,
    setPaymentSelectedRegulationModeValue,
    setPaymentMainContent,
    generalConfigStore,
    setConfigOptionsCustomerAccount,
    setIsStateChanged,
} from "@store";
import { setIsModalOpenedPlaning, setIsConsult } from "../../store/project";
import { setIsKioskSetting, setProjectRowData } from "../GeneralConfig/store";
import { store as userStore } from "../../components/VerticalLayout/store";

import { Modal, ModalHeader, ModalBody } from "reactstrap";
import ErrorToast from "@components/Common/ErrorTost";

import { ModesModal } from "./ModesModal";

import { FlexboxGrid } from "@components/Common/FlexboxGrid";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import "./index.css";

type DropDownWrapperType = {
    element: any;
    setIsDataUpdated: Function;
    setSelectedRows: Function;
    isArchivedTemplateClicked: boolean;
    isDataUpdated: boolean | number;
    dataProject: any;
    isCreatedProject: boolean;
    setIsCreatedProject: Function;
};

export function DropDownWrapper({
    element,
    setSelectedRows,
    setIsDataUpdated,
    isArchivedTemplateClicked,
    isDataUpdated,
    dataProject,
    setIsCreatedProject,
}: DropDownWrapperType): JSX.Element {
    const { t } = useTranslation();
    const {
        isModalAssociateStore,
        isModesModalPublishOpened,
        isCreatedCoping,
        isModalArchiveOpened,
        isModalDissociateOpened,
        projectData,
        isConfirmationPublishProject,
        isCreateProjectClicked,
        modify,
    } = useSnapshot(store);
    const { oneShop } = useSnapshot(userStore);
    const { project } = useSnapshot(generalConfigStore);
    const [isDropdownOpened, setIsDropdownOpened] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);

    const [isAlertModalOpened, setIsAlertModalOpened] = React.useState<boolean>(
        false
    );

    function alertToggle() {
        setIsAlertModalOpened(!isAlertModalOpened);
    }
    function toggleDropdown() {
        setIsDropdownOpened((prevState) => !prevState);
    }

    const getProject = () => {
        if (projectData) {
            let project1 = projectData.filter(
                (elt) =>
                    elt.projectId === element?.selectedRow?.original?.id_project
            );
            return project1[0];
        } else {
            return project;
        }
    };
    const isExistShops = React.useCallback(() => {
        let isExist: boolean = false;
        dataProject?.dataProjectFranchise?.data.forEach((el: any) => {
            if (el.projectId === element.selectedRow.original.projectId) {
                if (el.shopKey.length > 1) {
                    isExist = true;
                }
            }
        });
        return isExist;
    }, [element.selectedRow.original.projectId, dataProject]);
    let isExist = isExistShops();
    const isExistPublishProject = React.useCallback(() => {
        let isExist: boolean = false;
        if (projectData !== undefined) {
            projectData.forEach((el: any) => {
                if (el?.Status === "Publié") {
                    isExist = true;
                }
            });
            return isExist;
        }
        return isExist;
    }, [projectData]);
    let isExistProjectPublish = isExistPublishProject();

    const handleSettingIconOnClickEvent = async () => {
        if (
            (element.selectedRow.original.template.content
                .salesMethods as ProjectContentItemType).items.filter(
                (item: any) => item.active === true
            ).length === 0
        ) {
            setIsOpen(false);
            setIsAlertModalOpened(!isAlertModalOpened);
        } else {
            setPaymentSelectedRegulationModeValue(
                (element?.selectedRow?.original?.template.content
                    .payment as PaymentContentItemType).items[0].shortName
            );

            setPaymentMainContent(
                ((element?.selectedRow?.original?.template
                    .content as ProjectContentType)[
                    "payment"
                ] as PaymentContentItemType).items
            );

            setIsOpen(true);
            setProjectId(element?.selectedRow?.original?.id_project);

            setSelectedRows(element?.selectedRow?.original);
            setIsKioskSetting(true);
            setIsModalOpened(true);
            setIsConfigActive(true);

            setProject({
                ...element?.selectedRow?.original,
            });
            setChosenTemplate(
                element?.selectedRow?.original.template,
                element?.selectedRow?.original.files
            );

            setInitItemsFromGeneralSetting({
                informationModes:
                    element?.selectedRow?.original.template.content
                        .informationModes.items,
                saleMethods:
                    element?.selectedRow?.original.template.content.salesMethods
                        .items,
                reglements:
                    element?.selectedRow?.original.template.content
                        .meansOfPayment.items,
                languages:
                    element?.selectedRow?.original.template.content.languages
                        .items,
                logo:
                    element?.selectedRow?.original.template.content
                        .generalDesign.logo,
            });

            setDefaultLanguage(
                (element?.selectedRow?.original.template.content
                    .languages as LanguagesType).items.filter(
                    (language) => language.isDefault === true
                )[0]
            );
            setSelectedLanguage(
                (element?.selectedRow?.original.template.content
                    .languages as LanguagesType).items.filter(
                    (language) => language.isDefault === true
                )[0].name
            );
            const selectedSaleMethodDeliveryExist = (element.selectedRow
                .original.template.content
                .salesMethods as ProjectContentItemType).items.filter(
                (sale: any) => sale.name === "Delivery"
            )[0];
            if (
                selectedSaleMethodDeliveryExist !== undefined &&
                selectedSaleMethodDeliveryExist.active === true
            ) {
                setIsCustomerAccountActive(true);

                setIsStateChanged(true);
                setConfigOptionsCustomerAccount(true);
            } else {
                setIsCustomerAccountActive(
                    (element.selectedRow.original.template.content
                        .options as OptionsType).items.filter(
                        (item) => item.title === "Customer account"
                    )[0].active
                );
            }
            setSelectedSaleModeValue(
                (element?.selectedRow?.original.template.content
                    .salesMethods as ProjectContentItemType).items.filter(
                    (saleMethod) => {
                        return (
                            saleMethod.name !== "Retrait C&C" &&
                            saleMethod.active === true
                        );
                    }
                )[0].name
            );

            setItems(element?.selectedRow?.original.template.pages);
            setDefaultWays(element?.selectedRow?.original.template.pages);

            let hoursOfUse = element.selectedRow.original.hoursOfUse;
            localStorage.setItem("hoursOfUse", JSON.stringify(hoursOfUse));
        }
    };

    return (
        <React.Fragment>
            {isOpen ? (
                <ModesModal
                    rowData={element?.selectedRow?.original}
                    setIsDataUpdated={setIsDataUpdated}
                    isDataUpdated={isDataUpdated}
                    setIsCreatedProject={setIsCreatedProject}
                />
            ) : null}
            <Modal
                isOpen={isAlertModalOpened}
                toggle={alertToggle}
                backdrop={false}
                fade={false}
                centered
            >
                <ModalHeader toggle={alertToggle}>
                    <span
                        style={{
                            textAlign: "left",
                            font: " normal normal 600 25px Segoe UI",
                            color: "#000000",
                        }}
                    >
                        {" "}
                        {t("Alert")}
                    </span>
                </ModalHeader>
                <ModalBody
                    className="d-flex flex-column"
                    style={{ gap: "10px" }}
                >
                    <div
                        style={{
                            textAlign: "center",
                            font: " normal normal 600 16px/10px Segoe UI",
                            color: "#2B2828",
                        }}
                    >
                        {t("Warning! Missing setting.")}
                    </div>
                    <div
                        style={{
                            font: " normal normal 600 16px/20px Segoe UI",
                            textAlign: "left",
                            color: "#2B2828",
                            padding: "14px",
                        }}
                    >
                        {t(
                            "To configure your KIOSK, it is essential to configure the elements in the general configuration section."
                        )}
                    </div>
                    <FlexboxGrid
                        className="pt-4 justify-content-end"
                        gap="10px"
                    >
                        <CustomMainColorButton
                            style={{
                                height: "37px !important",
                                width: "8px",
                                minWidth: "115px",
                            }}
                            rounded={true}
                            variant="primary"
                            outline
                            onClick={() => setIsAlertModalOpened(false)}
                        >
                            {t("Close")}
                        </CustomMainColorButton>
                    </FlexboxGrid>
                </ModalBody>
            </Modal>
            <React.Fragment>
                {modify === true && (
                    <>
                        {element.selectedRow.original.projectId ? (
                            <Dropdown
                                isOpen={isDropdownOpened}
                                toggle={toggleDropdown}
                            >
                                <DropdownToggle
                                    id="page-header-user-dropdown"
                                    tag="button"
                                    className={`${classnames(
                                        "pointer__clz btn btn header-item waves-effect",
                                        {
                                            "not-allowed-icon__clz":
                                                isCreateProjectClicked === true,
                                        }
                                    )}`}
                                >
                                    <VerticalDotsIcon
                                        height={25}
                                        width={25}
                                        className="Cgt_drp_menuProject"
                                    />
                                </DropdownToggle>
                                <DropdownMenu>
                                    {element.selectedRow.original.Status ===
                                    "Publié" ? (
                                        <DropdownItem
                                            style={{ cursor: "pointer" }}
                                            className="cgt_icn_consult"
                                            onClick={() => {
                                                setIsOpen(true);
                                                setIsKioskSetting(false);
                                                setInitStoreVariables();
                                                setInitDesignStoreVariables();
                                                setIsConfigActive(true);
                                                setSelectedRows(
                                                    element.selectedRow.original
                                                );
                                                setProject(
                                                    element.selectedRow.original
                                                );
                                                if (
                                                    element.selectedRow.original
                                                        .template.id !== ""
                                                ) {
                                                    if (
                                                        (element.selectedRow
                                                            .original.template
                                                            .content
                                                            .salesMethods as ProjectContentItemType).items.filter(
                                                            (item: any) =>
                                                                item.active ===
                                                                true
                                                        ).length === 0
                                                    ) {
                                                        setIsOpen(false);
                                                        setIsAlertModalOpened(
                                                            !isAlertModalOpened
                                                        );
                                                    } else {
                                                        setChosenTemplate(
                                                            element.selectedRow
                                                                .original
                                                                .template,
                                                            element.selectedRow
                                                                .original.files
                                                        );

                                                        setDefaultLanguage(
                                                            (element.selectedRow
                                                                .original
                                                                .template
                                                                .content
                                                                .languages as LanguagesType).items.filter(
                                                                (language) =>
                                                                    language.isDefault ===
                                                                    true
                                                            )[0]
                                                        );
                                                        setSelectedLanguage(
                                                            (element.selectedRow
                                                                .original
                                                                .template
                                                                .content
                                                                .languages as LanguagesType).items.filter(
                                                                (language) =>
                                                                    language.isDefault ===
                                                                    true
                                                            )[0].name
                                                        );
                                                        const selectedSaleMethodDeliveryExist = (element
                                                            .selectedRow
                                                            .original.template
                                                            .content
                                                            .salesMethods as ProjectContentItemType).items.filter(
                                                            (sale: any) =>
                                                                sale.name ===
                                                                "Delivery"
                                                        )[0];
                                                        if (
                                                            selectedSaleMethodDeliveryExist !==
                                                                undefined &&
                                                            selectedSaleMethodDeliveryExist.active ===
                                                                true
                                                        ) {
                                                            setIsCustomerAccountActive(
                                                                true
                                                            );

                                                            setIsStateChanged(
                                                                true
                                                            );
                                                            setConfigOptionsCustomerAccount(
                                                                true
                                                            );
                                                        } else {
                                                            setIsCustomerAccountActive(
                                                                (element
                                                                    .selectedRow
                                                                    .original
                                                                    .template
                                                                    .content
                                                                    .options as OptionsType).items.filter(
                                                                    (item) =>
                                                                        item.title ===
                                                                        "Customer account"
                                                                )[0].active
                                                            );
                                                        }
                                                        setSelectedSaleModeValue(
                                                            (element.selectedRow
                                                                .original
                                                                .template
                                                                .content
                                                                .salesMethods as ProjectContentItemType).items.filter(
                                                                (
                                                                    saleMethod
                                                                ) => {
                                                                    return (
                                                                        saleMethod.name !==
                                                                            "Retrait C&C" &&
                                                                        saleMethod.active ===
                                                                            true
                                                                    );
                                                                }
                                                            )[0].name
                                                        );
                                                        setInitItemsFromGeneralSetting(
                                                            {
                                                                informationModes:
                                                                    element
                                                                        .selectedRow
                                                                        .original
                                                                        .template
                                                                        .content
                                                                        .informationModes
                                                                        .items,
                                                                saleMethods:
                                                                    element
                                                                        ?.selectedRow
                                                                        ?.original
                                                                        .template
                                                                        .content
                                                                        .salesMethods
                                                                        .items,
                                                                reglements:
                                                                    element
                                                                        ?.selectedRow
                                                                        ?.original
                                                                        .template
                                                                        .content
                                                                        .meansOfPayment
                                                                        .items,
                                                                languages:
                                                                    element
                                                                        ?.selectedRow
                                                                        ?.original
                                                                        .template
                                                                        .content
                                                                        .languages
                                                                        .items,
                                                                logo:
                                                                    element
                                                                        ?.selectedRow
                                                                        ?.original
                                                                        .template
                                                                        .content
                                                                        .generalDesign
                                                                        .logo,
                                                            }
                                                        );
                                                        setItems(
                                                            element.selectedRow
                                                                .original
                                                                .template.pages
                                                        );
                                                        setDefaultWays(
                                                            element.selectedRow
                                                                .original
                                                                .template.pages
                                                        );
                                                    }
                                                }
                                                setProjectId(
                                                    element.selectedRow.original
                                                        .projectId
                                                );
                                                setIsConsult(true);
                                                setIsModalOpened(true);
                                                if (
                                                    (element.selectedRow
                                                        .original.template
                                                        .content
                                                        .salesMethods as ProjectContentItemType).items.filter(
                                                        (item: any) =>
                                                            item.active === true
                                                    ).length !== 0
                                                )
                                                    toast.info(
                                                        `${t(
                                                            "Please note that you cannot modify a published template"
                                                        )!}`,
                                                        {
                                                            position:
                                                                toast.POSITION
                                                                    .TOP_CENTER,
                                                            autoClose: 2000,
                                                            theme: "colored",
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: true,
                                                        }
                                                    );
                                            }}
                                        >
                                            <SettingsIcon
                                                height={15}
                                                width={15}
                                            />
                                            <span>{t("To consult")}</span>
                                        </DropdownItem>
                                    ) : (
                                        <DropdownItem
                                            className={classnames(
                                                "Cgt_icn_configuration",
                                                {
                                                    "not-allowed-icon__clz":
                                                        isArchivedTemplateClicked ||
                                                        element.selectedRow
                                                            .original.Status ===
                                                            "Planifié",
                                                }
                                            )}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setSelectedRows(
                                                    element.selectedRow.original
                                                );
                                                setProjectRowData(
                                                    element.selectedRow.original
                                                );

                                                setIsOpen(true);
                                                setIsConsult(false);
                                                setIsKioskSetting(false);
                                                setInitStoreVariables();
                                                setInitDesignStoreVariables();
                                                setIsConfigActive(true);
                                                setProject(
                                                    element.selectedRow.original
                                                );
                                                if (
                                                    element.selectedRow.original
                                                        .template.id !== ""
                                                ) {
                                                    if (
                                                        (element.selectedRow
                                                            .original.template
                                                            .content
                                                            .salesMethods as ProjectContentItemType).items.filter(
                                                            (item: any) =>
                                                                item.active ===
                                                                true
                                                        ).length === 0
                                                    ) {
                                                        setIsAlertModalOpened(
                                                            !isAlertModalOpened
                                                        );
                                                        setIsOpen(false);
                                                    } else {
                                                        setChosenTemplate(
                                                            element.selectedRow
                                                                .original
                                                                .template,
                                                            element.selectedRow
                                                                .original.files
                                                        );
                                                        setDefaultLanguage(
                                                            (element.selectedRow
                                                                .original
                                                                .template
                                                                .content
                                                                .languages as LanguagesType).items.filter(
                                                                (language) =>
                                                                    language.isDefault ===
                                                                    true
                                                            )[0]
                                                        );
                                                        setSelectedLanguage(
                                                            (element.selectedRow
                                                                .original
                                                                .template
                                                                .content
                                                                .languages as LanguagesType).items.filter(
                                                                (language) =>
                                                                    language.isDefault ===
                                                                    true
                                                            )[0].name
                                                        );
                                                        setIsCustomerAccountActive(
                                                            (element.selectedRow
                                                                .original
                                                                .template
                                                                .content
                                                                .options as OptionsType).items.filter(
                                                                (
                                                                    item: OptionsItemType
                                                                ) =>
                                                                    item.title ===
                                                                    "Customer account"
                                                            )[0].active
                                                        );
                                                        setSelectedSaleModeValue(
                                                            (element.selectedRow
                                                                .original
                                                                .template
                                                                .content
                                                                .salesMethods as ProjectContentItemType).items.filter(
                                                                (
                                                                    saleMethod
                                                                ) => {
                                                                    return (
                                                                        saleMethod.name !==
                                                                            "Retrait C&C" &&
                                                                        saleMethod.active ===
                                                                            true
                                                                    );
                                                                }
                                                            )[0].name
                                                        );
                                                        setInitItemsFromGeneralSetting(
                                                            {
                                                                informationModes:
                                                                    element
                                                                        ?.selectedRow
                                                                        ?.original
                                                                        .template
                                                                        .content
                                                                        .informationModes
                                                                        .items,
                                                                saleMethods:
                                                                    element
                                                                        ?.selectedRow
                                                                        ?.original
                                                                        .template
                                                                        .content
                                                                        .salesMethods
                                                                        .items,
                                                                reglements:
                                                                    element
                                                                        ?.selectedRow
                                                                        ?.original
                                                                        .template
                                                                        .content
                                                                        .meansOfPayment
                                                                        .items,
                                                                languages:
                                                                    element
                                                                        ?.selectedRow
                                                                        ?.original
                                                                        .template
                                                                        .content
                                                                        .languages
                                                                        .items,
                                                                logo:
                                                                    element
                                                                        ?.selectedRow
                                                                        ?.original
                                                                        .template
                                                                        .content
                                                                        .generalDesign
                                                                        .logo,
                                                            }
                                                        );

                                                        setItems(
                                                            element.selectedRow
                                                                .original
                                                                .template.pages
                                                        );
                                                        setDefaultWays(
                                                            element.selectedRow
                                                                .original
                                                                .template.pages
                                                        );
                                                    }
                                                }
                                                setProjectId(
                                                    element.selectedRow.original
                                                        .projectId
                                                );

                                                setIsProjectModesModalOpened(
                                                    true
                                                );
                                            }}
                                        >
                                            <SettingsIcon
                                                height={15}
                                                width={15}
                                            />
                                            <span>{t("Configure")}</span>
                                        </DropdownItem>
                                    )}

                                    <DropdownItem
                                        style={{ cursor: "pointer" }}
                                        className={classnames(
                                            "Cgt_icn_toPlan",
                                            {
                                                "not-allowed-icon__clz":
                                                    element.selectedRow.original
                                                        .Status === "Publié" ||
                                                    isArchivedTemplateClicked ||
                                                    (isExist && oneShop) ||
                                                    oneShop ||
                                                    !oneShop,
                                            }
                                        )}
                                        onClick={() => {
                                            if (
                                                element.selectedRow.original
                                                    .Status !== "Publié" ||
                                                !isArchivedTemplateClicked
                                            ) {
                                                setSelectedRows(
                                                    element.selectedRow.original
                                                );
                                                setIsModalOpenedPlaning(true);
                                            }
                                        }}
                                    >
                                        <SettingsIcon
                                            height={15}
                                            width={15}
                                            style={{ cursor: "pointer" }}
                                        />
                                        <span>
                                            {element.selectedRow.original
                                                .Status === "Planifié"
                                                ? `${t("Edit Schedule")}`
                                                : `${t("Palnify")}`}
                                        </span>
                                    </DropdownItem>
                                    <DropdownItem
                                        style={{ cursor: "pointer" }}
                                        className={classnames(
                                            "Cgt_icn_publish",
                                            {
                                                "not-allowed-icon__clz":
                                                    element.selectedRow.original
                                                        .Status === "Publié" ||
                                                    isArchivedTemplateClicked ||
                                                    (isExist && oneShop),
                                            }
                                        )}
                                        onClick={() => {
                                            if (
                                                isExistProjectPublish &&
                                                oneShop
                                            ) {
                                                setIsConfirmationPublishProject(
                                                    !isConfirmationPublishProject
                                                );
                                            } else if (
                                                Object.keys(
                                                    element.selectedRow.original
                                                        .associates
                                                ).length === 0 &&
                                                !oneShop
                                            ) {
                                                toast.error(
                                                    `${t(
                                                        "Unable to publish a template not associated with one or more store(s)"
                                                    )!}`,
                                                    {
                                                        position:
                                                            toast.POSITION
                                                                .TOP_CENTER,
                                                        autoClose: 2000,
                                                        theme: "colored",
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        icon: (
                                                            <ShieldExclamationIcon
                                                                width={25}
                                                                height={25}
                                                                fill="white"
                                                            />
                                                        ),
                                                    }
                                                );
                                            } else if (
                                                element.selectedRow.original
                                                    .template.id === ""
                                            ) {
                                                toast.error(
                                                    `${t(
                                                        "Unable to publish an unconfigured Template"
                                                    )!}`,
                                                    {
                                                        position:
                                                            toast.POSITION
                                                                .TOP_CENTER,
                                                        autoClose: 2000,
                                                        theme: "colored",
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        icon: (
                                                            <ShieldExclamationIcon
                                                                width={25}
                                                                height={25}
                                                                fill="white"
                                                            />
                                                        ),
                                                    }
                                                );
                                            } else {
                                                setIsModesModalPublishOpened(
                                                    !isModesModalPublishOpened
                                                );
                                            }
                                            setSelectedRows(
                                                element.selectedRow.original
                                            );
                                        }}
                                    >
                                        <DuplicateIcon height={15} width={15} />
                                        <span>{t("Publish")}</span>
                                    </DropdownItem>
                                    <DropdownItem
                                        style={{ cursor: "pointer" }}
                                        className={classnames(
                                            "Cgt_icn_duplicate",
                                            {
                                                "not-allowed-icon__clz":
                                                    isArchivedTemplateClicked ||
                                                    (isExist && oneShop),
                                            }
                                        )}
                                        onClick={() => {
                                            setIsCreatedCoping(
                                                !isCreatedCoping
                                            );
                                            setSelectedRows(
                                                element.selectedRow.original
                                            );
                                            setProjectId(
                                                element.selectedRow.original
                                                    .projectId
                                            );
                                        }}
                                    >
                                        <DuplicateIcon height={15} width={15} />
                                        <span>{t("Duplicate")}</span>
                                    </DropdownItem>
                                    {!oneShop ? (
                                        <DropdownItem
                                            style={{ cursor: "pointer" }}
                                            className={classnames(
                                                "Cgt_icn_associateStore",
                                                {
                                                    "not-allowed-icon__clz":
                                                        isArchivedTemplateClicked ||
                                                        oneShop ||
                                                        element.selectedRow
                                                            .original.Status ===
                                                            "Publié",
                                                }
                                            )}
                                            onClick={() => {
                                                setSelectedRows(
                                                    element.selectedRow.original
                                                );
                                                setIsModalAssociateStore(
                                                    !isModalAssociateStore
                                                );
                                            }}
                                        >
                                            <DuplicateIcon
                                                height={15}
                                                width={15}
                                            />
                                            <span>
                                                {t("Associate to store(s)")}
                                            </span>
                                        </DropdownItem>
                                    ) : null}
                                    <DropdownItem
                                        className={classnames(
                                            "Cgt_icn_archive cmn_btn_FRunarchive",
                                            {
                                                "not-allowed-icon__clz":
                                                    element.selectedRow.original
                                                        .Status === "Publié",
                                            }
                                        )}
                                        onClick={() => {
                                            if (oneShop) {
                                                setSelectedRows([
                                                    element.selectedRow
                                                        .original,
                                                ]);
                                                setIsModalDissociateOpened(
                                                    !isModalDissociateOpened
                                                );
                                            } else if (
                                                element.selectedRow.original
                                                    .subRows.length > 0 &&
                                                !oneShop
                                            ) {
                                                return ErrorToast(
                                                    `${t(
                                                        "Please note that you cannot archive a Template associated with the store(s)."
                                                    )!}`
                                                );
                                            } else {
                                                setIsModalArchiveOpened(
                                                    !isModalArchiveOpened
                                                );
                                                setSelectedRows([
                                                    element.selectedRow
                                                        .original,
                                                ]);
                                            }
                                        }}
                                    >
                                        {!isArchivedTemplateClicked ? (
                                            <TrashIcon height={15} width={15} />
                                        ) : (
                                            <EyeIcon height={15} width={15} />
                                        )}

                                        <span
                                            className={classnames("", {
                                                "not-allowed-icon__clz":
                                                    element.selectedRow.original
                                                        .Status === "Publié",
                                            })}
                                        >
                                            {!isArchivedTemplateClicked
                                                ? oneShop
                                                    ? `${t("Delete")}`
                                                    : `${t("Archive")}`
                                                : `${t("Enable")}`}
                                        </span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        ) : null}
                        {element.selectedRow.original.id_boutique ? (
                            <Dropdown
                                isOpen={isDropdownOpened}
                                toggle={toggleDropdown}
                            >
                                <DropdownToggle
                                    id="page-header-user-dropdown"
                                    tag="button"
                                    className={`${classnames(
                                        "pointer__clz btn header-item waves-effect",
                                        {
                                            "not-allowed-icon__clz":
                                                getProject()?.template?.id ===
                                                    "" ||
                                                isArchivedTemplateClicked,
                                        }
                                    )}`}
                                >
                                    <VerticalDotsIcon height={25} width={25} />
                                </DropdownToggle>
                                <DropdownMenu
                                    dropdown-menu
                                    aria-labelledby="dropdownMenuButton"
                                >
                                    <DropdownItem
                                        style={{ cursor: "pointer" }}
                                        onClick={handleSettingIconOnClickEvent}
                                    >
                                        <SettingsIcon height={15} width={15} />
                                        <span>{t("Configure")}</span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        ) : null}
                    </>
                )}
            </React.Fragment>
        </React.Fragment>
    );
}
