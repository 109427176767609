import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledLabel,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { mutate } from "swr";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";

import {
    store as generalSettingStore,
    setIsModified,
    setIsLanguesAssociatedModified,
    setGlobalDataIsUpdated,
} from "../store";
import { store } from "@components/VerticalLayout/store";

import { capitalize } from "@helpers/general";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationModifiedMessageType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    e: any;
    languageState: any;
    associated: any[];
    setDataIsUpdated: Function;
    dataIsUpdated: boolean | number;
};

export function ConfirmationAssociateModifiedMessage({
    isModalOpened,
    setIsModalOpened,
    e,
    languageState,
    associated,
    setDataIsUpdated,
    dataIsUpdated,
}: ConfirmationModifiedMessageType) {
    const { t } = useTranslation();
    const { franchiseID, userID, operatorID } = useSnapshot(store);
    const { isModified, isActivation, globalDataIsUpdated } = useSnapshot(
        generalSettingStore
    );

    const [error, setError] = React.useState<boolean>(false);

    async function enableAllLanguage() {
        toast.dismiss();
        const id = e.selectedRow.Id;
        let dataLanguage = {
            userId: userID,
            shopId: e.selectedRow.shopId,
            franchiseId: franchiseID,
            operatorId: operatorID,
            isShop: true,
            data: {
                [id]: {
                    id: id,
                    language: e.selectedRow.Language,
                    display_name: e.selectedRow.display_name,
                    country: e.selectedRow.country,
                    urlImage: e.selectedRow.url_image,
                    isArchived: e.selectedRow.isArchived,
                    isFavorite: e.selectedRow.isFavorite,
                    isFavoriteShop: e.selectedRow.isFavorite,
                    isActive: !languageState,
                    shopId: e.selectedRow.shopId,
                    isActiveShop: !languageState,
                    abbreviation: e.selectedRow.abbreviation,
                },
            },
        };

        const data = new FormData();
        data.append("dataLanguage", JSON.stringify(dataLanguage));
        data.append("image", "");
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/language`;
        const requestOptions = {
            method: "POST",
            body: data,
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function enableLocalLanguage() {
        toast.dismiss();
        const id = e.selectedRow.Id;
        let dataLanguage = {
            userId: userID,
            shopId: e.selectedRow.shopId,
            franchiseId: franchiseID,
            operatorId: operatorID,
            isShop: true,

            data: {
                [id]: {
                    id: id,
                    language: e.selectedRow.Language,
                    display_name: e.selectedRow.display_name,
                    country: e.selectedRow.country,
                    urlImage: e.selectedRow.url_image,
                    isArchived: e.selectedRow.isArchived,
                    isFavorite: e.selectedRow.isFavorite,
                    isFavoriteShop: e.selectedRow.isFavorite,
                    isActive: !languageState,
                    shopId: e.selectedRow.shopId,
                    isActiveShop: !languageState,
                    abbreviation: e.selectedRow.abbreviation,
                },
            },
        };
        const data = new FormData();
        data.append("dataLanguage", JSON.stringify(dataLanguage));
        data.append("image", "");
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/language`;
        const requestOptions = {
            method: "POST",
            body: data,
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function favoriteAllLanguage(isFavorite: boolean) {
        toast.dismiss();
        let objectData = {};
        let localData: any[] = [];
        associated.forEach(async (el: any) => {
            const id: any = el.Id;
            Object.assign(objectData, {
                [id]: {
                    id: id,
                    language: el.Language,
                    display_name: el.display_name,
                    country: el.country,
                    urlImage: el.url_image,
                    isArchived: el.isArchived,
                    isFavorite: isFavorite,
                    isActive: el.isActive,
                    shopId: el.shopId,
                    abbreviation: el.abbreviation,
                },
            });
            let dataLanguage = {
                userId: userID,
                shopId: [el.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                data: objectData,
            };
            const data = new FormData();
            data.append("dataLanguage", JSON.stringify(dataLanguage));
            data.append("image", "");
            localData.push(
                fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/language`,
                    {
                        method: "POST",
                        body: data,
                    }
                ).then((response) => response.json())
            );
        });
        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/language`,
                await Promise.all(localData).then((result) => {
                    result.forEach((element: any) => {
                        if (element.error === true) setError(true);
                    });
                })
            );
        } catch (e) {
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
        if (error === false) {
            toast.success(
                isFavorite
                    ? `${t("Favorite state has been enabled successfully")}`
                    : `${t("Favorite state has been disabled successfully")}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
            setDataIsUpdated(!dataIsUpdated);
            setIsLanguesAssociatedModified(true);
            setGlobalDataIsUpdated(!globalDataIsUpdated);
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => setIsModalOpened(!isModalOpened)}
            >
                <StyledH2>{capitalize(t("Alert"))}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        'Do you want to apply these changes for all stores? \n Attention ! If you click on "Validate", the specific settings of the shops will be modified.'
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                        isActivation && enableLocalLanguage();
                        setIsModified(!isModified);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        isActivation
                            ? enableAllLanguage()
                            : favoriteAllLanguage(!e.selectedRow.isFavorite);
                        setIsModalOpened(!isModalOpened);
                        setIsModified(!isModified);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
