import React from "react";
import ReactSwitch from "react-switch";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import {
    designStore,
    generalConfigStore,
    setIsSummaryHeaderAccumulatedLoyaltyPointsActive,
    setIsSummaryHeaderLogoActive,
    setPageOrderTakingSummary,
} from "@store";

export function MainContent(): JSX.Element {
    const { t } = useTranslation();

    const {
        selectedLanguage,
        isSummaryHeaderLogoActive,
        isSummaryHeaderAccumulatedLoyaltyPointsActive,
        summaryHeaderAccumulatedLoyaltyPointsItems,
    } = useSnapshot(designStore);
    const {
        project: { template },
    } = useSnapshot(generalConfigStore);

    function handleIsLogoActiveSwitchOnClickEvent() {
        setIsSummaryHeaderLogoActive(!isSummaryHeaderLogoActive);
        setPageOrderTakingSummary({
            ...(template.pages.ways["orderTaking"] as OrderTakingPageType)
                .summary,
            summaryHeader: {
                ...(template.pages.ways["orderTaking"] as OrderTakingPageType)
                    .summary.summaryHeader,
                logo: {
                    active: !isSummaryHeaderLogoActive,
                },
            },
        });
        if (!isSummaryHeaderLogoActive === false) {
            setIsSummaryHeaderLogoActive(false);
            setPageOrderTakingSummary({
                ...(template.pages.ways["orderTaking"] as OrderTakingPageType)
                    .summary,
                summaryHeader: {
                    ...(template.pages.ways[
                        "orderTaking"
                    ] as OrderTakingPageType).summary.summaryHeader,
                    logo: {
                        active: false,
                    },
                },
            });
        }
    }

    function handleIsAccumulatedLoyaltyPointsActiveSwitchOnClickEvent() {
        setIsSummaryHeaderAccumulatedLoyaltyPointsActive(
            !isSummaryHeaderAccumulatedLoyaltyPointsActive
        );
        setPageOrderTakingSummary({
            ...(template.pages.ways["orderTaking"] as OrderTakingPageType)
                .summary,
            summaryHeader: {
                ...(template.pages.ways["orderTaking"] as OrderTakingPageType)
                    .summary.summaryHeader,
                accumulatedLoyaltyPoints: {
                    ...(template.pages.ways[
                        "orderTaking"
                    ] as OrderTakingPageType).summary.summaryHeader
                        .accumulatedLoyaltyPoints,
                    active: !isSummaryHeaderAccumulatedLoyaltyPointsActive,
                },
            },
        });

        if (!isSummaryHeaderAccumulatedLoyaltyPointsActive === false) {
            setIsSummaryHeaderAccumulatedLoyaltyPointsActive(false);
            setPageOrderTakingSummary({
                ...(template.pages.ways["orderTaking"] as OrderTakingPageType)
                    .summary,
                summaryHeader: {
                    ...(template.pages.ways[
                        "orderTaking"
                    ] as OrderTakingPageType).summary.summaryHeader,
                    accumulatedLoyaltyPoints: {
                        ...(template.pages.ways[
                            "orderTaking"
                        ] as OrderTakingPageType).summary.summaryHeader
                            .accumulatedLoyaltyPoints,
                        active: false,
                    },
                },
            });
        }
    }

    return (
        <div
            className="mx-2 m-2 rounded border"
            style={{ borderColor: "#CECECE" }}
        >
            <div
                className="text-left px-4 d-flex align-items-center cursor__clz"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                {t("Header")}
            </div>

            <div className="p-4" style={{ height: "148px" }}>
                <div className="py-2 d-flex flex-rows justify-content-between align-items-center">
                    <div>{"Logo"}</div>
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isSummaryHeaderLogoActive}
                        onChange={handleIsLogoActiveSwitchOnClickEvent}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
                <div className="py-2 d-flex flex-rows justify-content-between align-items-center">
                    <div className="d-flex flex-column ">
                        {t(
                            "Display the loyalty points accumulated during the order"
                        )}

                        {summaryHeaderAccumulatedLoyaltyPointsItems.map(
                            (item: {
                                name: string;
                                languages: {
                                    [key: string]: {
                                        content: string;
                                        defaultContent: string;
                                    };
                                };
                            }) => {
                                return (
                                    <span
                                        style={{
                                            color: "#B7B7B7",
                                            fontSize: "13px",
                                        }}
                                    >
                                        {item.languages[selectedLanguage]
                                            .defaultContent !== undefined
                                            ? item.languages[selectedLanguage]
                                                  .defaultContent
                                            : ""}
                                    </span>
                                );
                            }
                        )}
                    </div>

                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isSummaryHeaderAccumulatedLoyaltyPointsActive}
                        onChange={
                            handleIsAccumulatedLoyaltyPointsActiveSwitchOnClickEvent
                        }
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
            </div>
        </div>
    );
}
