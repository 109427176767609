import React from "react";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next/";
import { motion } from "framer-motion/dist/framer-motion";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import useResource from "@hooks/useResource";
import { store as userStore } from "@components/VerticalLayout/store";
import PageTitle from "@components/Common/PageTitle";

import CardMenu from "./CardMenu";
import { CARD_MENU_LIST } from "./cardMenuList";

import "./Cartestyle.css";

export default function ApplicationHome() {
    const { t } = useTranslation();
    const [numberKiosk, setNumberKiosk] = React.useState<any>();
    const { oneShop, userID, franchiseID, shopID } = useSnapshot(userStore);
    const [url, setUrl] = React.useState<string>(
        oneShop
            ? `${process.env.REACT_APP_API_V2_URL}/settings/Kiosk/list?userId=${userID}&shopId=${shopID}&franchiseId=${franchiseID}`
            : `${process.env.REACT_APP_API_V2_URL}/settings/Kiosk/list?userId=${userID}&franchiseId=${franchiseID}`
    );
    const { resourceData } = useResource(url);

    React.useEffect(() => {
        setUrl(
            oneShop
                ? `${process.env.REACT_APP_API_V2_URL}/settings/Kiosk/list?userId=${userID}&shopId=${shopID}&franchiseId=${franchiseID}`
                : `${process.env.REACT_APP_API_V2_URL}/settings/Kiosk/list?userId=${userID}&franchiseId=${franchiseID}`
        );
        setNumberKiosk(resourceData.listKiosk.count);
    }, [resourceData, url, userID, oneShop, franchiseID, shopID]);

    return (
        <React.Fragment>
            <PageTitle title={"Applications"} />
            <div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 1 }}
                    className="page-content__clz"
                >
                    <StyledBreadcrumb
                        items={[
                            {
                                item: "ETK Settings".toUpperCase(),
                                link: "https://dev-portail.aureskonnect.com",
                            },
                            {
                                item:
                                    localStorage
                                        .getItem("selectedAccount")
                                        ?.toUpperCase() || "",
                                link: "/compte",
                            },
                            {
                                item: t("Application").toUpperCase(),
                                link: "#",
                            },
                        ]}
                    />
                    <div className="row p-5">
                        {CARD_MENU_LIST.map((cardMenu, index) => {
                            return (
                                <CardMenu
                                    key={index}
                                    title={cardMenu.title}
                                    number={cardMenu.number}
                                    Icon={cardMenu.Icon}
                                    description={cardMenu.description}
                                    path={cardMenu.path}
                                    numberKiosk={numberKiosk}
                                />
                            );
                        })}
                    </div>
                </motion.div>
            </div>
        </React.Fragment>
    );
}
