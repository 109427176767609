import React from "react";
import { useTranslation } from "react-i18next";
import { ModalFooter } from "reactstrap";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ModalFooterType = {
    setDataIsUpdated: Function;
    isConfigurationCashManagementClicked: boolean;
    setIsConfigurationCashManagementClicked: Function;
    handleValidateButtonClickEvent: Function;
};

export function Footer({
    isConfigurationCashManagementClicked,
    setIsConfigurationCashManagementClicked,
    handleValidateButtonClickEvent,
    setDataIsUpdated,
}: ModalFooterType) {
    const { t } = useTranslation();

    return (
        <ModalFooter>
            <CustomSecondaryColorButton
                outline
                rounded
                className="mr-2"
                variant="light"
                onClick={() => {
                    setIsConfigurationCashManagementClicked(
                        !isConfigurationCashManagementClicked
                    );
                    setDataIsUpdated(true);
                }}
            >
                <span>{t("Cancel")}</span>
            </CustomSecondaryColorButton>
            <CustomMainColorButton
                rounded
                variant="primary"
                onClick={() => {
                    handleValidateButtonClickEvent();
                }}
            >
                {t("Validate")}
            </CustomMainColorButton>
        </ModalFooter>
    );
}
