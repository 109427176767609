import React from "react";
import classNames from "classnames";
import { useSnapshot } from "valtio";
import { designStore, generalConfigStore } from "@store";

import { HeaderWrapper } from "./HeaderWrapper";
import { ActionsButtonsWrapper } from "./ActionsButtonsWrapper";
import { PreviewBackgroundWrapper } from "./PreviewBackgroundWrapper";
import { ContentWrapper } from "./ContentWrapper";

import "./index.css";

export function SummaryPreview(): JSX.Element {
    const { activeTabIndex } = useSnapshot(designStore);
    const { isBackgroundSummaryActive } = useSnapshot(generalConfigStore);

    return (
        <div
            style={{
                display: "grid",
                gridTemplateRows: "684px 29px",
                gap: "11px",
            }}
        >
            {isBackgroundSummaryActive === true ? (
                <PreviewBackgroundWrapper>
                    <div
                        className="d-flex flex-column"
                        style={{ gap: "11px", zIndex: 1 }}
                    >
                        <HeaderWrapper />
                        <ContentWrapper />
                    </div>
                </PreviewBackgroundWrapper>
            ) : (
                <div
                    className="d-flex flex-column"
                    style={{ gap: "11px", zIndex: 1 }}
                >
                    <HeaderWrapper />
                    <ContentWrapper />
                </div>
            )}

            <div
                className={classNames({
                    "border-wrapper__clz": activeTabIndex === 3,
                })}
                style={{ width: "429px", height: "29px", marginLeft: "-3px" }}
            >
                <div
                    className="ml-2 d-flex flex-row justify-content-center align-items-center"
                    style={{ gap: "10px" }}
                >
                    <ActionsButtonsWrapper />
                </div>
            </div>
        </div>
    );
}
