import { ZoomFileSvgIcon } from "@components/Common/SvgIcons";
import React from "react";
import { setDataModal } from "../store";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

export function CustomIcons({
    e,
    setIsAddCustomerAccountModalClicked,
    isAddCustomerAccountModalClicked,
    setCosutmerName,
    setUid,
}: any) {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <div className="d-flex justify-content-between w-50 pl-5 icc_icn_toConsult">
                <div className="p-1">
                    <Tooltip title={t("To consult")}>
                        <div>
                            <ZoomFileSvgIcon
                                onClick={() => {
                                    e.selectedRow["Société"] =
                                        e.selectedRow[t("Shop")];
                                    setDataModal(e.selectedRow);
                                    setIsAddCustomerAccountModalClicked(
                                        !isAddCustomerAccountModalClicked
                                    );
                                    setCosutmerName(e.selectedRow[t("Shop")]);
                                    setUid(e.selectedRow.uid);
                                }}
                                height={25}
                                width={25}
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                    </Tooltip>
                </div>
            </div>
        </React.Fragment>
    );
}
