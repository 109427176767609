import React from "react";
import { StyledH2 } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { capitalize } from "@helpers/general";
import { resetStore } from "@pages/Applications/DeviceAssociateModal/store";
import { useSnapshot } from "valtio";
import classnames from "classnames";

import { store } from "@components/VerticalLayout/store";
import { store as storeData } from "../store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

export default function HistoryModal({
    ids,
    isModalOpened,
    setIsModalOpened,
}: any) {
    const { t } = useTranslation();
    const { selectedHours } = useSnapshot(storeData);
    const { franchiseID, userID, shopID } = useSnapshot(store);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);

    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };

    const url = `${
        process.env.REACT_APP_API_V2_URL
    }/settings/general/hour/history?userId=${userID}&franchiseId=${franchiseID}&shopId=${shopID}&day=${selectedHours
        .map((el: any) => JSON.stringify(el))
        .join()}`;
    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 0,
            columnName: t("Day of week"),
            customJsx: (e: any) => (
                <div>{t(e.selectedRow[t("Day of week")])}</div>
            ),
        },
        {
            indexOFColumn: 1,
            columnName: t("Actions"),
            customJsx: (e: any) => {
                let actionsArray: any = [];
                e.selectedRow.actions.forEach((el: any) => {
                    if (el.item === "settingDay") {
                        actionsArray.push(t("Hours"));
                    } else if (el.item === "stateDay") {
                        actionsArray.push(t("State of day"));
                    }
                });
                if (e.selectedRow.operation === "modify") {
                    if (e.selectedRow.actions.length > 0) {
                        return (
                            <span>
                                {t("Modification")} {actionsArray.join(",")}
                            </span>
                        );
                    }
                } else if (e.selectedRow.operation === "creation") {
                    return <span>{t("Creation")}</span>;
                } else if (e.selectedRow.operation === "reassignment") {
                    return <span>{t("Reassignment")}</span>;
                } else {
                    return (
                        <span>
                            {t("Affectation")}
                            {e.selectedRow.operation}
                        </span>
                    );
                }
            },
        },
        {
            indexOFColumn: 2,
            columnName: t("Before modification"),
            customJsx: (e: any) => {
                if (e.selectedRow.operation === "modify") {
                    // eslint-disable-next-line array-callback-return
                    return e.selectedRow.actions.map((el: any) => {
                        if (
                            el.item === "settingDay" &&
                            el.oldValue !== undefined &&
                            el.oldValue !== null
                        ) {
                            return (
                                <span>
                                    {Object.values(el.oldValue).map(
                                        (subEl: any) =>
                                            `   ${subEl.openingHour} | ${
                                                subEl.closingHour
                                            } : ${
                                                subEl.stateHour
                                                    ? t("Opened")
                                                    : t("Closed")
                                            }  `
                                    )}
                                </span>
                            );
                        } else if (
                            el.item === "stateDay" &&
                            el.oldValue !== undefined &&
                            el.oldValue !== null
                        ) {
                            return el.oldValue === true ? (
                                <span>{t("Opened")}</span>
                            ) : (
                                <span>{t("Closed")}</span>
                            );
                        }
                    });
                } else if (e.selectedRow.operation === "creation") {
                    return "";
                } else {
                    return <span></span>;
                }
            },
        },
        {
            indexOFColumn: 3,
            columnName: t("After modification"),
            customJsx: (e: any) => {
                if (e.selectedRow.operation === "modify") {
                    // eslint-disable-next-line array-callback-return
                    return e.selectedRow.actions.map((el: any) => {
                        if (
                            el.item === "settingDay" &&
                            el.newValue !== undefined &&
                            el.newValue !== null
                        ) {
                            return (
                                <span>
                                    {Object.values(el.newValue).map(
                                        (subEl: any) =>
                                            `   ${subEl.openingHour} | ${
                                                subEl.closingHour
                                            } : ${
                                                subEl.stateHour
                                                    ? t("Opened")
                                                    : t("Closed")
                                            }  `
                                    )}
                                </span>
                            );
                        } else if (
                            el.item === "stateDay" &&
                            el.newValue !== undefined &&
                            el.newValue !== null
                        ) {
                            return el.newValue === true ? (
                                <span>{t("Opened")}</span>
                            ) : (
                                <span>{t("Closed")}</span>
                            );
                        }
                    });
                } else if (e.selectedRow.operation === "creation") {
                    return "";
                } else {
                    return <span></span>;
                }
            },
        },
    ];

    return (
        <React.Fragment>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                className="modal-wrapper__clz"
                isOpen={isModalOpened}
            >
                <ModalHeader
                    toggle={() => {
                        setIsModalOpened!(!isModalOpened);

                        resetStore();
                    }}
                    className="sdv_icn_HSclose"
                >
                    <StyledH2 className="hov_txt_HStitleHistory">
                        {capitalize(t("Historical"))}
                    </StyledH2>
                </ModalHeader>

                <ModalBody>
                    <div
                        className={classnames(
                            "title__clz not-allowed-icon__clz"
                        )}
                    >
                        {ids.length > 1 ? (
                            <div className={classnames(" ml-3")}>
                                {" "}
                                {t("Opening hour")}{" "}
                            </div>
                        ) : (
                            <div className={classnames(" ml-3")}>
                                {t("Opening hour")}:{" "}
                                {t(ids[0][t("Day of week")])}
                            </div>
                        )}
                    </div>

                    <DynamicTable
                        url={url}
                        requestHeader={{ "Accept-Language": i18nextLng }}
                        canResize
                        actionColumn={ActionColumn}
                        showGlobalFilter
                        showFilter
                        setLocalFilterActive={setLocalFilterActive}
                        filterActive={filterActive}
                        setDataIsUpdated={setDataIsUpdated}
                        dataIsUpdated={dataIsUpdated}
                        elevationTable={1}
                        minHeight="500px"
                        maxHeight="500px"
                        name="historyModeOfSale"
                        arrayOfCustomColumns={arrayOfCustomColumns}
                    />
                </ModalBody>
                <ModalFooter className="border-top-0 ">
                    <CustomMainColorButton
                        variant="primary"
                        onClick={() => {
                            setIsModalOpened(!isModalOpened);
                        }}
                        rounded
                        className="hov_btn_HSclose"
                    >
                        {t("Close")}
                    </CustomMainColorButton>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
