import React from "react";
import { StyledModal } from "@aureskonnect/react-ui";
import { ModalBody } from "reactstrap";

import "./index.css";
type ModalResponsiveMobileFormType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    JsxContent: Function;
};
export function ModalResponsiveMobileForm({
    isModalOpened,
    setIsModalOpened,
    JsxContent,
}: ModalResponsiveMobileFormType) {
    return (
        <StyledModal
            size="fullscreen"
            toggle={true}
            fullscreen={true}
            isOpen={isModalOpened!}
            centered
            className="fullscreen"
        >
            <ModalBody className="responsiveModal__clz">
                {JsxContent()}
            </ModalBody>
        </StyledModal>
    );
}
