import React from "react";
import { useSnapshot } from "valtio";
import classNames from "classnames";

import { designStore } from "@store";

import { PreviewBackgroundWrapper } from "@components/Common/Design/Preview/PreviewBackgroundWrapper";

import { MainContentWrapper } from "./MainContentWrapper";
import { ContainerWrapper } from "./ContainerWrapper";
import { ActionsButtonsWrapper } from "./ActionsButtonsWrapper";
import { TopBannerWrapper } from "./TopBannerWrapper";
import { HeaderWrapper } from "./HeaderWrapper";
import { PaymentConsummationCard } from "./PaymentConsummationCard";
import { MainContentPaymentPreviewRestoflash } from "./MainContentPaymentPreviewRestoflash";

import "@pages/GeneralConfig/Design/index.css";

export function Preview(): JSX.Element {
    const {
        isTopBannerActive,
        isHeaderActive,
        isActionButtonsActive,
        isMainContentChangeMachine,
        paymentSelectedRegulationModeValue,
    } = useSnapshot(designStore);

    return (
        <PreviewBackgroundWrapper>
            <ContainerWrapper
                isTopBannerActive={isTopBannerActive}
                isHeaderActive={isHeaderActive}
                isActionButtonsActive={isActionButtonsActive}
            >
                {isTopBannerActive ? <TopBannerWrapper /> : null}

                {isHeaderActive ? (
                    <div
                        className={classNames("d-flex align-items-center", {
                            "justify-content-end mt-5":
                                isTopBannerActive !== isHeaderActive,
                        })}
                    >
                        <HeaderWrapper />
                    </div>
                ) : null}

                <div
                    className={classNames(
                        "d-flex flex-column align-items-center p-2",
                        {
                            "justify-content-center":
                                isMainContentChangeMachine === true,
                            "payment-consummation__clz":
                                isTopBannerActive === false &&
                                isHeaderActive === false &&
                                isMainContentChangeMachine === false,
                        }
                    )}
                    style={{
                        gap:
                            paymentSelectedRegulationModeValue !== "restoflash"
                                ? "20px"
                                : "unset",
                        maxWidth: "500px",
                    }}
                >
                    <PaymentConsummationCard />
                    {paymentSelectedRegulationModeValue !== "restoflash" ? (
                        isMainContentChangeMachine === true ? (
                            <MainContentWrapper />
                        ) : null
                    ) : (
                        <MainContentPaymentPreviewRestoflash />
                    )}
                </div>
                {isActionButtonsActive ? <ActionsButtonsWrapper /> : null}
            </ContainerWrapper>
        </PreviewBackgroundWrapper>
    );
}
