import React from "react";
import classnames from "classnames";
import ReactSwitch from "react-switch";
import { toast } from "react-toastify";
import { CheckboxIcon, EyeIcon, TrashIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { Tooltip } from "@mui/material";

import store, {
    setGlobalDataIsUpdated,
    setIsDataUpdated,
    setIsHistoryShopsModalOpened,
    setSelectedPaymentMethods,
    setSelectedHistoryPaymentMethods,
} from "../store";
import { store as dataStore } from "@components/VerticalLayout/store";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import { ArchivedValidationModal } from "@components/Common/ArchivedValidationModal";

type CustomIconsType = {
    selectedRowData: any;
    isArchivedPaymentMethodsClicked: boolean;
};

export function CustomIcons({
    selectedRowData,
    isArchivedPaymentMethodsClicked,
}: CustomIconsType) {
    const { t } = useTranslation();
    const { franchiseID, userID, operatorID } = useSnapshot(dataStore);
    const {
        isHistoryShopsModalOpened,
        paymentMethodsData,
        modify,
    } = useSnapshot(store);

    const [choiceState, setPaymentMethodState] = React.useState<boolean>(
        selectedRowData.selectedRow.isActive
    );

    const [isValidateClicked, setIsValidateClicked] = React.useState<boolean>(
        false
    );
    const [
        isArchivedValidationModalOpened,
        setIsArchivedValidationModalOpened,
    ] = React.useState<boolean>(false);

    async function handleStateChange(data: boolean) {
        let dataConfig = {
            userId: userID,
            shopId: [selectedRowData.selectedRow.idShop],
            franchiseId: franchiseID,
            operatorId: operatorID,
            data: {
                [selectedRowData.selectedRow.id]: {
                    designation:
                        selectedRowData.selectedRow[t("Payment method")],
                    currency: selectedRowData.selectedRow.Devise,
                    display: selectedRowData.selectedRow[t("Display name")],
                    shopId: selectedRowData.selectedRow.idShop,
                    saleSupport:
                        selectedRowData.selectedRow[t("Support de vente")],
                    country: selectedRowData.selectedRow[t("Country")],
                    tag: selectedRowData.selectedRow.Tag,
                    isArchived: selectedRowData.selectedRow.isArchived,
                    isActive: data,
                },
            },
        };
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod`;

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataConfig),
        };
        mutate(
            apiUrl,
            await fetch(apiUrl, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }

                    toast.success(
                        data === true
                            ? `${t(
                                  "Payment method has been successfully activated"
                              )}`
                            : `${t(
                                  "Payment method has been successfully deactivated"
                              )}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setGlobalDataIsUpdated!(true);
                    setIsDataUpdated(true);
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }

    async function handleActivePaymentMethod(selectedRowData: any) {
        let dataConfig = {
            userId: userID,
            shopId: [selectedRowData.selectedRow.idShop],
            franchiseId: franchiseID,
            operatorId: operatorID,
            data: {
                [selectedRowData.selectedRow.id]: {
                    designation:
                        selectedRowData.selectedRow[t("Payment method")],
                    currency: selectedRowData.selectedRow.Devise,
                    display: selectedRowData.selectedRow[t("Display name")],
                    shopId: selectedRowData.selectedRow.idShop,
                    saleSupport:
                        selectedRowData.selectedRow[t("Support de vente")],
                    country: selectedRowData.selectedRow[t("Country")],
                    tag: selectedRowData.selectedRow.tag,
                    isActive: selectedRowData.selectedRow.isActive,
                    isArchived: false,
                },
            },
        };
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod`;

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataConfig),
        };
        mutate(
            apiUrl,
            await fetch(apiUrl, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }
                    toast.success(
                        `${t("The Payment method is successfully activated")}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setIsDataUpdated(true);
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }

    async function handleArchivePaymentMethod(selectedRowData: any) {
        let dataConfig = {
            userId: userID,
            shopId: [selectedRowData.idShop],
            franchiseId: franchiseID,
            operatorId: operatorID,
            data: {
                [selectedRowData.id]: {
                    designation: selectedRowData[t("Payment method")],
                    currency: selectedRowData.Devise,
                    display: selectedRowData[t("Display name")],
                    shopId: selectedRowData.idShop,
                    saleSupport: selectedRowData[t("Support de vente")],
                    country: selectedRowData[t("Country")],
                    tag: selectedRowData.tag,
                    isActive: false,
                    isArchived: true,
                },
            },
        };
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod`;

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataConfig),
        };
        mutate(
            apiUrl,
            await fetch(apiUrl, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }
                    toast.success(
                        `${t("The Payment method is successfully archived")}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setIsValidateClicked(false);
                    setIsArchivedValidationModalOpened(false);
                    setIsDataUpdated(true);
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }

    React.useEffect(() => {
        if (isValidateClicked) {
            handleArchivePaymentMethod(selectedRowData.selectedRow);
        }
        // eslint-disable-next-line
    }, [isValidateClicked, selectedRowData]);

    return (
        <React.Fragment>
            {selectedRowData.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    {modify === true ? (
                        <>
                            <Tooltip
                                title={
                                    isArchivedPaymentMethodsClicked
                                        ? ""
                                        : choiceState === false
                                        ? t("Activate")
                                        : t("Unactivate")
                                }
                            >
                                <div>
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={choiceState}
                                        onChange={async (e: boolean) => {
                                            setPaymentMethodState(!choiceState);
                                            handleStateChange(e);
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                        className={`${classnames(
                                            "cmn_icn_BTenableDisable",
                                            {
                                                "not-allowed-icon__clz": isArchivedPaymentMethodsClicked,
                                            }
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                            <div>
                                {!isArchivedPaymentMethodsClicked ? (
                                    <Tooltip title={t("Archive")}>
                                        <div>
                                            <TrashIcon
                                                onClick={() => {
                                                    setIsArchivedValidationModalOpened(
                                                        !isArchivedValidationModalOpened
                                                    );
                                                }}
                                                height={25}
                                                width={25}
                                                fill="red"
                                                style={{ cursor: "pointer" }}
                                                className="ml-2 cmn_icn_BTarchived"
                                            />
                                        </div>
                                    </Tooltip>
                                ) : (
                                    <Tooltip
                                        title={
                                            paymentMethodsData.find(
                                                (el: any) =>
                                                    el.id ===
                                                    selectedRowData.selectedRow
                                                        .id
                                            ) !== undefined
                                                ? ""
                                                : t("Unarchive")
                                        }
                                    >
                                        <div>
                                            <EyeIcon
                                                height={25}
                                                width={25}
                                                onClick={(e: any) => {
                                                    handleActivePaymentMethod(
                                                        selectedRowData
                                                    );
                                                }}
                                                className={`${classnames(
                                                    "pointer__clz ml-2 cmn_icn_BTunarchive",
                                                    {
                                                        "not-allowed-icon__clz":
                                                            paymentMethodsData.find(
                                                                (el: any) =>
                                                                    el.id ===
                                                                    selectedRowData
                                                                        .selectedRow
                                                                        .id
                                                            ) !== undefined,
                                                    }
                                                )}`}
                                            />
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                        </>
                    ) : null}
                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                onClick={() => {
                                    setIsHistoryShopsModalOpened(
                                        !isHistoryShopsModalOpened
                                    );
                                    setSelectedHistoryPaymentMethods(
                                        selectedRowData.selectedRow
                                    );
                                    setSelectedPaymentMethods([
                                        {
                                            [selectedRowData.selectedRow
                                                .idShop]:
                                                selectedRowData.selectedRow.id,
                                        },
                                    ]);
                                }}
                                className="pointer__clz ml-2 cmn_icn_BThistory"
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
            {isArchivedValidationModalOpened ? (
                <ArchivedValidationModal
                    text={t(
                        "Attention, this (these) payment method can be used by other modules.\n\nDo you want to confirm the archiving of the selected of payment method(s)"
                    )}
                    isModalOpened={isArchivedValidationModalOpened}
                    setIsModalOpened={setIsArchivedValidationModalOpened}
                    setIsValidateClicked={setIsValidateClicked}
                />
            ) : null}
        </React.Fragment>
    );
}
