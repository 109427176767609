import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { AvForm } from "availity-reactstrap-validation";
import { Table } from "reactstrap";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";

import {
    designStore,
    setSubStepPageInformationMessageItems,
    setSubStepInformationMessageItems,
} from "@store";

import { InformationMessageRow } from "@components/Common/Design/MainContentWrapper/PersonalizedInformationModes/TranslationTable/InformationMessageRow";

import { SwiperLanguagesWrapper } from "@components/Common/Design/SwiperLanguagesWrapper";

export function TranslationTable(): JSX.Element {
    const { t } = useTranslation();

    const {
        subStepInformationMessageItems,
        selectedInformationModes,
        isSubStepInformationMessageActive,
    } = useSnapshot(designStore);

    function handleResetTranslationOnClickEvent() {
        const informationMessageItems = Object.fromEntries(
            Object.entries(
                subStepInformationMessageItems
            ).map(([key, value]) => [
                key,
                { ...value, content: value.defaultContent as string },
            ])
        );
        const localInformationMessageItems: HeaderLanguageItemsType = informationMessageItems;

        setSubStepInformationMessageItems(localInformationMessageItems);
        setSubStepPageInformationMessageItems(
            localInformationMessageItems,
            (selectedInformationModes as ProjectMainContentItemType)
                .shortName as string
        );
    }

    return (
        <div
            className="mt-3 rounded border"
            style={{
                width: "93%",
                height: "96%",
                borderColor: "#CECECE",
            }}
        >
            <div
                className="d-flex justify-content-between align-items-center"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                <div className="text-left px-4 d-flex align-items-center cursor__clz">
                    {t("Translation")}
                </div>
                <StyledIconButton
                    title={t("Reset")}
                    icon="RefreshIcon m-0"
                    onClick={() => {
                        handleResetTranslationOnClickEvent();
                    }}
                >
                    <RefreshIcon height={15} width={15} />
                </StyledIconButton>
            </div>

            <div className="p-2" style={{ width: "798px" }}>
                <SwiperLanguagesWrapper />
                <div className="mt-3 mx-4">
                    <AvForm>
                        <Table bordered className="languages-table__clz">
                            <thead>
                                <tr>
                                    <th
                                        style={{
                                            width: "30%",
                                            verticalAlign: "middle",
                                        }}
                                    >
                                        <div className="ml-2 d-flex align-items-center cursor__clz">
                                            {t("Default text")}
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="ml-2 cursor__clz">
                                                {t("Custom")}
                                            </div>

                                            <StyledIconButton
                                                title={t("Reset")}
                                                icon="RefreshIcon m-0"
                                                onClick={() => {
                                                    handleResetTranslationOnClickEvent();
                                                }}
                                            >
                                                <RefreshIcon
                                                    height={15}
                                                    width={15}
                                                />
                                            </StyledIconButton>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {isSubStepInformationMessageActive ? (
                                    <InformationMessageRow />
                                ) : null}
                            </tbody>
                        </Table>
                    </AvForm>
                </div>
            </div>
        </div>
    );
}
