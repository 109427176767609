import React from "react";
import { useSnapshot } from "valtio";
import classNames from "classnames";

import { designStore } from "@store";

import { ActionsButtonsImageContent } from "@components/Common/Design/Preview/ActionsButtonsImageContent";

export function ActionsButtons(): JSX.Element {
    const { subStepActionsButtonsItems, activeTabIndex } = useSnapshot(
        designStore
    );

    const activeActionsButtons = subStepActionsButtonsItems.filter((item) => {
        return item.active === true;
    });

    const mainActiveActionsButtons = activeActionsButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    return (
        <React.Fragment>
            {activeActionsButtons.length === 1 ? (
                <div
                    className={classNames(
                        "d-flex justify-content-center align-items-center",
                        {
                            "border-wrapper__clz": activeTabIndex === 3,
                        }
                    )}
                >
                    <ActionsButtonsImageContent
                        item={activeActionsButtons[0]}
                    />
                </div>
            ) : activeActionsButtons.length === 2 ? (
                <div
                    className={classNames(
                        "d-flex justify-content-center align-items-center",
                        {
                            "border-wrapper__clz": activeTabIndex === 3,
                        }
                    )}
                    style={{ gap: "5px" }}
                >
                    {mainActiveActionsButtons.map((item) => {
                        return (
                            <ActionsButtonsImageContent
                                key={item.id}
                                item={item}
                            />
                        );
                    })}
                </div>
            ) : activeActionsButtons.length === 3 ? (
                <div className="d-grid__clz">
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ gap: "5px" }}
                    >
                        {mainActiveActionsButtons.slice(-2).map((item) => {
                            return (
                                <ActionsButtonsImageContent
                                    key={item.id}
                                    item={item}
                                />
                            );
                        })}
                    </div>

                    <div className="d-flex justify-content-center align-items-center">
                        {mainActiveActionsButtons.slice(0, 1).map((item) => {
                            return (
                                <ActionsButtonsImageContent
                                    key={item.id}
                                    item={item}
                                />
                            );
                        })}
                    </div>
                </div>
            ) : null}
        </React.Fragment>
    );
}
