import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledButton } from "@aureskonnect/react-ui";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";

import { store } from "../../../../components/VerticalLayout/store";
import {
    store as InformationStore,
    setIsAddModeOfInformationClicked,
    setLocalShop,
    setIsAssociateModalOpened,
    setNumberActiveMultiShop,
    setNumberArchiveMultiShop,
    setIdsLocalShop,
    setIsAssociatedModeOfInformation,
    setShopsModeInformationData,
    setRowsShop,
    setGlobalDataIsUpdated,
    setAssociatedModeInformation,
    setLocalShopWithInformation,
} from "../store";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";
import { ActionColumnModeInformation } from "./ActionColumnModeInformation";
import { ConfirmationActiveModeOfInformation } from "../ConfirmationActiveModeOfInformation";
import { ModeOfInformationAssociateModal } from "./ModeOfInformationAssociateModal";
import { ConfirmationAssociatedMessage } from "./ConfirmationAssociatedMessage";
import { CustomIcons } from "./CustomIcons";
import HistoryModal from "../HistoryModal";
import { mutate } from "swr";

import "./index.css";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type ShopsModeOfInformationsType = {
    isModeOfInformationArchived: boolean;
    setIsModeOfInformationArchived: Function;
    setLimit: Function;
};
export default function ShopsModeOfInformations({
    isModeOfInformationArchived,
    setIsModeOfInformationArchived,
    setLimit,
}: ShopsModeOfInformationsType) {
    const { oneShop, userID, franchiseID } = useSnapshot(store);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const {
        isAssociateModalOpened,
        isAssociatedModeOfInformation,
        globalDataIsUpdated,
        modify,
    } = useSnapshot(InformationStore);

    const { t } = useTranslation();
    const [rowData, setRowData] = useState<any>({});
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [isShopSelected, setIsShopSelected] = React.useState<boolean>(false);

    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isHistoryModalOpened, setIsHistoryModalOpened] = React.useState<
        boolean
    >(false);
    const [Information, setInformation] = useState<any[]>([]);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [dataTable, setDataTable] = React.useState<any>({});
    const [selectedIds, setSelectedIds] = React.useState<any[]>([]);
    const urlArchive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information_archived?userId=${userID}&franchiseId=${franchiseID}`;

    const urlActive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_Information?userId=${userID}&franchiseId=${franchiseID}`;
    React.useEffect(() => {
        let arrayIds: any = [];
        let existIds: any = [];
        if (Array.isArray(selectedRows)) {
            selectedRows.forEach((element: any) => {
                if (element[t("Shop")] === undefined) {
                    if (
                        existIds.indexOf(`${element.Id}${element.shopId}`) ===
                        -1
                    ) {
                        arrayIds.push(element.Id);
                        existIds.push(`${element.Id}${element.shopId}`);
                    }
                }
            });
            setSelectedIds(arrayIds);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows]);
    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 7,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    e={e}
                    setRowData={setRowData}
                    setDataIsUpdated={setGlobalDataIsUpdated}
                    setIsHistoryModalOpened={setIsHistoryModalOpened}
                    isHistoryModalOpened={isHistoryModalOpened}
                    setSelectedRows={setSelectedRows}
                    isModeOfInformationArchived={isModeOfInformationArchived}
                    setIsModeOfInformationArchived={
                        setIsModeOfInformationArchived
                    }
                />
            ),
            disableFilter: false,
        },
        {
            indexOFColumn: 2,
            columnName: t("Display name"),
            customJsx: (e: any) => (
                <CustomColumnDisplay
                    text={
                        e.selectedRow[t("Display name")] !== undefined
                            ? e.selectedRow[t("Display name")]
                            : ""
                    }
                />
            ),
            filterName: t("Information mode"),
            canFilter: true,
        },
        {
            indexOFColumn: 3,
            columnName: t("Information mode"),
            customJsx: (e: any) => <NumberModeOfInformation e={e} />,
            filterName: t("Information mode"),
            canFilter: true,
        },
        {
            indexOFColumn: 4,
            columnName: t("Support of sale"),
            customJsx: (e: any) => (
                <CustomColumnDisplay
                    text={
                        e.selectedRow[t("Sales support")] !== undefined &&
                        Object.keys(e.selectedRow[t("Sales support")]).length >
                            0
                            ? e.selectedRow[t("Sales support")]?.label
                            : ""
                    }
                />
            ),
            filterName: t("Support of sale"),
            canFilter: true,
        },
        {
            indexOFColumn: 5,
            columnName: t("Sales method"),
            customJsx: (e: any) => (
                <CustomColumnDisplay
                    text={
                        Array.isArray(e.selectedRow[t("Sales method")])
                            ? e.selectedRow[t("Sales method")].join(",")
                            : e.selectedRow[t("Sales method")]
                    }
                />
            ),
            filterName: t("Sales method"),
            canFilter: true,
        },

        {
            indexOFColumn: 6,
            columnName: t("Tag"),
            customJsx: (e: any) => (
                <CustomColumnDisplay
                    text={
                        Array.isArray(e.selectedRow.tag)
                            ? e.selectedRow.tag.join(",")
                            : ""
                    }
                />
            ),
            filterName: t("Tag"),
            canFilter: true,
        },
    ];

    function NumberActiveAndArchive() {
        let sumActive = 0;
        let sumArchive = 0;
        if (dataTable.data !== undefined) {
            selectedRows.length === 0
                ? dataTable.data.forEach((item: any) => {
                      sumActive =
                          item.numberActive !== undefined
                              ? sumActive + item.numberActive
                              : sumActive;
                      sumArchive =
                          item.numberArchive !== undefined
                              ? sumArchive + item.numberArchive
                              : sumArchive;
                  })
                : selectedRows.forEach((item: any) => {
                      sumActive =
                          item.numberActive !== undefined
                              ? sumActive + item.numberActive
                              : sumActive;
                      sumArchive =
                          item.numberArchive !== undefined
                              ? sumArchive + item.numberArchive
                              : sumArchive;
                  });
            setNumberActiveMultiShop(sumActive);
            setNumberArchiveMultiShop(sumArchive);
        }
    }

    function NumberModeOfInformation(e: any) {
        return (
            <React.Fragment>
                {e.e.selectedRow?.subRows !== undefined ? (
                    <h6 className="badge__clz mdi_icn_BTcounter">
                        {e.e.selectedRow[t("Shop")] !== ""
                            ? e.e.selectedRow.subRows?.length
                            : 0}
                    </h6>
                ) : (
                    <CustomColumnDisplay
                        text={e.e.selectedRow[t("Mode of information")]}
                    />
                )}
            </React.Fragment>
        );
    }
    React.useEffect(() => {
        if (filterActive) setIsAddModeOfInformationClicked(false);
    }, [filterActive]);
    async function getShopsInformation(ids: any) {
        let data: any = [];

        for (let index = 0; index < ids.length; index++) {
            const elx = ids[index];
            let x: any = await getShopInformation(elx);
            data.push(x);
        }
        setLocalShopWithInformation(data);
    }
    async function getShopInformation(id: string) {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        };
        let apiUrl: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale?userId=${userID}&shopId=${id}&franchiseId=${franchiseID}`;
        let dataMOS: any = [];
        let dataSOS: any = [];
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        dataMOS = [];

                        if (data.data.length > 0) {
                            dataMOS = data.data.map(
                                (x: any) => x["Mode of sale"]
                            );
                        }
                    })
            );
            apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport?userId=${userID}&shopId=${id}&franchiseId=${franchiseID}&type=activated`;

            mutate(
                apiUrl,
                await fetch(apiUrl, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }

                        if (data.data.length > 0) {
                            dataSOS = data.data.map(
                                (x: any) => x["Support de vente"]
                            );
                        }
                    })
            );
        } catch (e) {}

        return {
            supportOfSale: dataSOS,
            modeOfSale: dataMOS,
            shopId: id,
        };
    }
    React.useEffect(() => {
        let localShop: string[] = [];

        let idsLocalShop: string[] = [];
        let associatedModeInformation: any[] = [];
        selectedRows.forEach(async (el: any) => {
            if (el[t("Shop")] !== "" && el[t("Shop")] !== undefined) {
                localShop.push(el.shopId);
            }

            for (let i in el.subRows) {
                idsLocalShop.push(el.subRows[i].Id);
                associatedModeInformation.push(el.subRows[i]);
            }
        });
        setAssociatedModeInformation(associatedModeInformation);
        setRowsShop(selectedRows);
        setIdsLocalShop(idsLocalShop);
        setLocalShop(localShop);
        setIsShopSelected(localShop.length !== 0);
        setShopsModeInformationData(dataTable);
        getShopsInformation(localShop);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows, dataTable]);

    React.useEffect(() => {
        NumberActiveAndArchive();
        // eslint-disable-next-line
    }, [dataTable]);

    return (
        <React.Fragment>
            <div
                style={{
                    display: "grid",
                    minHeight: oneShop ? "650px" : "600px",
                    gridTemplateColumns: "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    url={isModeOfInformationArchived ? urlArchive : urlActive}
                    setData={setDataTable}
                    canExpand
                    canSelect
                    customSelect
                    canMovedCheckboxLeftOnExpand
                    setSelectedRows={setSelectedRows}
                    customJsxSideFilterButton={
                        <React.Fragment>
                            <div className="d-flex justify-content-start align-items-center">
                                {modify === true ? (
                                    <>
                                        <StyledButton
                                            className="w-100 ml-3 mdi_btn_BTassignInformationMode"
                                            rounded
                                            variant="primary"
                                            disabled={
                                                isModeOfInformationArchived
                                                    ? true
                                                    : isShopSelected
                                                    ? false
                                                    : true
                                            }
                                            onClick={() => {
                                                setInformation([]);
                                                setIsAssociateModalOpened(true);
                                                setLocalFilterActive(false);
                                            }}
                                        >
                                            {t("Assign information mode")}
                                        </StyledButton>
                                        <div></div>
                                    </>
                                ) : null}
                            </div>

                            <ActionColumnModeInformation
                                setIsHistoryModalOpened={
                                    setIsHistoryModalOpened
                                }
                                isHistoryModalOpened={isHistoryModalOpened}
                                selectedRows={selectedRows}
                                setDataIsUpdated={setGlobalDataIsUpdated}
                                isModeOfInformationArchived={
                                    isModeOfInformationArchived
                                }
                                setIsModeOfInformationArchived={
                                    setIsModeOfInformationArchived
                                }
                                selectedIds={selectedIds}
                            />
                        </React.Fragment>
                    }
                    canResize
                    showGlobalFilter
                    showFilter
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    actionColumn={() => <></>}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={
                        setGlobalDataIsUpdated as
                            | React.Dispatch<
                                  React.SetStateAction<number | boolean>
                              >
                            | undefined
                    }
                    dataIsUpdated={globalDataIsUpdated}
                    elevationTable={1}
                    minHeight={oneShop ? "500px" : "450px"}
                    maxHeight={oneShop ? "500px" : "450px"}
                    name="ModeOfInformation"
                />
            </div>

            <ConfirmationActiveModeOfInformation
                e={Information}
                setIsModalOpened={setIsModalOpened}
                isModalOpened={isModalOpened}
                setDataIsUpdated={setGlobalDataIsUpdated}
                selectedRows={selectedRows}
                isOneShop={false}
            />
            {isHistoryModalOpened ? (
                <HistoryModal
                    ids={selectedRows}
                    setIsModalOpened={setIsHistoryModalOpened}
                    isModalOpened={isHistoryModalOpened}
                    isOneShop={false}
                    rowData={rowData}
                    setData={setGlobalDataIsUpdated}
                />
            ) : null}
            {isAssociateModalOpened ? (
                <ModeOfInformationAssociateModal
                    setData={setGlobalDataIsUpdated}
                    isModeOfInformationArchived={isModeOfInformationArchived}
                    setIsModeOfInformationArchived={
                        setIsModeOfInformationArchived
                    }
                    setLimit={setLimit}
                />
            ) : null}

            {isAssociatedModeOfInformation ? (
                <ConfirmationAssociatedMessage
                    isModalOpened={isAssociatedModeOfInformation}
                    setIsModalOpened={setIsAssociatedModeOfInformation}
                    setData={setGlobalDataIsUpdated}
                    setLimit={setLimit}
                />
            ) : null}
        </React.Fragment>
    );
}
