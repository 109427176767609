import React from "react";
import classnames from "classnames";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next/";
import { StyledButton } from "@aureskonnect/react-ui";

import {
    store,
    setCashManagements,
    setSelectedPeripherals,
    setCheckModificationEffect,
} from "../store";
import { store as useStore } from "../../PeripheralConfigure/MoneticPeripheralConfigure/store";

import "../index.css";
import { getAuthorizationStates } from "@components/VerticalLayout/StyledSidebarMenu";

export default function CashManagementActionColumn({
    rowData,
    element,
    isDataCashManagementUpdated,
}: any) {
    const { t } = useTranslation();
    const { cashManagements } = useSnapshot(store);
    const { dataTable } = useSnapshot(useStore);
    const [isSelected, setIsSelected] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (rowData !== undefined && rowData.subRows !== undefined) {
            rowData.subRows.forEach((el: any) => {
                if (
                    element.Id === el.peripheralId ||
                    cashManagements
                        .map((item) => item.Id)
                        .indexOf(element.Id) !== -1
                ) {
                    setCashManagements(element);
                    setIsSelected(true);
                }
            });
        }
        // eslint-disable-next-line
    }, [rowData, isDataCashManagementUpdated]);

    function isSelectedCashManagment() {
        let isSelectedmonetics: any[] = [];
        dataTable.forEach((element: any) => {
            if (rowData.shopId === element.shopId) {
                for (let el of element.subRows) {
                    if (el?.subRows !== undefined) {
                        for (let row of el.subRows) {
                            if (row.peripheralType === "cash_management") {
                                if (
                                    cashManagements
                                        .map((item) => item.Id)
                                        .indexOf(row.peripheralId) === -1
                                ) {
                                    isSelectedmonetics.push(row);
                                }
                            }
                        }
                    }
                }
            }
        });
        return isSelectedmonetics;
    }
    let cashManagmentList: any[] = JSON.parse(
        JSON.stringify(isSelectedCashManagment())
    );
    const modify = getAuthorizationStates(
        "5590fe0f-69fc-9ff9-699d-d9a9defd12ad"
    ).Modifie;
    return (
        <StyledButton
            rounded
            outline
            variant={
                cashManagements.map((item) => item.Id).indexOf(element.Id) !==
                -1
                    ? "danger"
                    : "light"
            }
            className={`${classnames("button-style-select___clz", {
                "not-allowed-icon__clz":
                    (cashManagements.length === 1 &&
                        cashManagements
                            .map((item) => item.Id)
                            .indexOf(element.Id) === -1) ||
                    cashManagmentList
                        .map((item) => item.peripheralId)
                        .indexOf(element.Id) !== -1 ||
                    modify === false,
            })}`}
            onClick={() => {
                setCashManagements(element);
                setSelectedPeripherals(element);
                setIsSelected(!isSelected);
                setCheckModificationEffect(true);
            }}
        >
            <span>
                {cashManagements.map((item) => item.Id).indexOf(element.Id) !==
                -1
                    ? t("Deselect")
                    : t("Select")}
            </span>
        </StyledButton>
    );
}
