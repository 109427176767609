import React from "react";
import { useSnapshot } from "valtio";
import { t } from "i18next";
import { uid } from "react-uid";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";

import {
    generalConfigStore,
    setProjectFiles,
    setImageKey,
    setIsSalesMethodsSkipped,
    setConfigSalesMethods,
} from "@store";
import kioskSettingStore from "../store";

import { FlexboxGrid } from "@components/Common/FlexboxGrid";
import { ResetModalWrapper } from "@components/Common/ResetModalWrapper";
import { SkipThisStepContainer } from "@pages/GeneralConfig/SalesMethods/SkipThisStepContainer";

type PropsType = {
    items: ProjectMainContentItemType[];
    setItems: React.Dispatch<
        React.SetStateAction<ProjectMainContentItemType[]>
    >;
};

export function Header({ items, setItems }: PropsType): JSX.Element {
    const {
        defaultLanguage,
        isResetModalOpened,
        templates,
        project: { template, files: images },
        initItemsFromGeneralSetting,
    } = useSnapshot(generalConfigStore);
    const { isKioskSetting } = useSnapshot(kioskSettingStore);

    const [
        localIsResetModalOpened,
        setLocalIsResetModalOpened,
    ] = React.useState<boolean>(isResetModalOpened);

    async function handleResetOnClickEvent(): Promise<void> {
        const initSalesModes = initItemsFromGeneralSetting.saleMethods as ProjectMainContentItemType[];
        const initSkipStep = (templates.filter(
            (element) => element.id === template.id
        )[0].pages.elements.salesMethods as PageType).skipped;

        setItems(initSalesModes);
        setIsSalesMethodsSkipped(initSkipStep as boolean);
        const imagesArray = [...images];
        initSalesModes
            .map((item) => item.id)
            .forEach((id) => {
                const image = imagesArray.find((element) => element.id === id);

                if (image !== undefined) {
                    const imageIndex = imagesArray.indexOf(image);

                    const localContent = image.content as ImageItemLanguagesType;

                    imagesArray[imageIndex] = {
                        ...image,
                        content: {
                            ...localContent,
                            [defaultLanguage.name]: {
                                ...localContent[defaultLanguage.name],
                                path:
                                    localContent[defaultLanguage.name]
                                        .defaultImage,
                            },
                        } as ImageItemLanguagesType,
                    };
                }
            });

        setProjectFiles(imagesArray);
        setImageKey(uid(imagesArray));
        setConfigSalesMethods(initSalesModes);
    }

    return (
        <React.Fragment>
            <div>
                <div className="d-flex justify-content-between">
                    <div
                        className="d-flex align-items-center cursor__clz"
                        style={{ gap: "10px" }}
                    >
                        <div
                            style={{
                                font: "normal normal 600 35px/61px Nunito Sans",
                            }}
                        >
                            {t("Sales methods choice")}
                        </div>
                        <div
                            style={{
                                font: "normal normal 600 18px/27px Nunito Sans",
                            }}
                        >
                            ({t("Default language")}: {defaultLanguage.title})
                        </div>
                        {/* {!isKioskSetting ? (
                            <div>
                                <HelpIcon
                                    id="languages-config"
                                    height={25}
                                    width={25}
                                />
                                <UncontrolledTooltip
                                    placement="bottom"
                                    target="languages-config"
                                >
                                    {t(
                                        "You can configure other languages in expert mode"
                                    )}
                                </UncontrolledTooltip>
                            </div>
                        ) : null} */}
                    </div>
                    {items.filter((item) => item.active).length === 1 ? (
                        <SkipThisStepContainer />
                    ) : null}
                </div>
                {!isKioskSetting ? (
                    <FlexboxGrid
                        alignItemsCentered={true}
                        className="justify-content-between"
                    >
                        <span
                            style={{
                                font: "normal normal 300 20px/27px Nunito Sans",
                                color: "#A0A0A0",
                            }}
                        >
                            {t("Drag and drop elements to change the order")}
                        </span>
                        <StyledIconButton
                            title={t("Reset to default settings")}
                            outline={true}
                            variant="danger"
                            className="m-0 p-0"
                            icon="RefreshIcon"
                            style={{ height: "20%" }}
                            onClick={() => {
                                setLocalIsResetModalOpened(
                                    !localIsResetModalOpened
                                );
                            }}
                        >
                            <RefreshIcon
                                height={20}
                                width={20}
                                className="Cmn_btn_resetModifications"
                            />
                        </StyledIconButton>
                    </FlexboxGrid>
                ) : null}
            </div>

            <ResetModalWrapper
                handleResetOnClickEvent={handleResetOnClickEvent}
                localIsResetModalOpened={localIsResetModalOpened}
                setLocalIsResetModalOpened={setLocalIsResetModalOpened}
            />
        </React.Fragment>
    );
}
