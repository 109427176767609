import React from "react";
import classnames from "classnames";
import ReactSwitch from "react-switch";
import { toast } from "react-toastify";
import {
    CheckboxIcon,
    EyeIcon,
    HeartIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { Tooltip } from "@mui/material";

import { store as saleSupport } from "../../../../components/VerticalLayout/store";
import store, {
    setFranchiseDataIsUpdated,
    setIsHistoryShopsModalOpened,
    setNumberActiveMultiShop,
    setNumberArchiveMultiShop,
    setSaleSupportToBeArchived,
    setSelectedHistorySaleSupports,
    setSelectedSaleSupports,
    setShopDataIsUpdated,
} from "../store";
import { setIsDataUpdated } from "@pages/Applications/Kiosk/store";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";

import { ConfirmationArchive } from "../ConfirmationArchive";

export function CustomIcons({
    selectedRowData,
    isArchivedSaleSupportClicked,
    setLimit,
}: any) {
    const { t } = useTranslation();
    const {
        numberActiveMultiShop,
        numberArchiveMultiShop,
        isHistoryShopsModalOpened,
        saleSupportData,
        shopsSaleSupportData,
        saleSupportToBeArchived,
        modify,
    } = useSnapshot(store);
    const { franchiseID, userID, operatorID } = useSnapshot(saleSupport);
    const [saleSupportState, setSaleSupportState] = React.useState<boolean>(
        selectedRowData.selectedRow?.isActive
            ? selectedRowData.selectedRow?.isActive
            : false
    );
    const [isValidateClicked, setIsValidateClicked] = React.useState<boolean>(
        false
    );
    const [isArchiveSVModalOpened, setIsArchiveSVModalOpened] = React.useState<
        boolean
    >(false);
    async function handleStateChange(data: boolean) {
        setLimit(1);
        let id = selectedRowData?.selectedRow.Id;
        let obj = [
            {
                userId: userID,
                shopId: [selectedRowData?.selectedRow.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                data: {
                    [id]: {
                        id: id,
                        displayName:
                            selectedRowData?.selectedRow[t("Display name")],
                        tag: selectedRowData?.selectedRow.Tag,
                        support_de_vente:
                            selectedRowData?.selectedRow[t("Sale support")],
                        shopId: selectedRowData?.selectedRow.shopId,
                        isArchived: selectedRowData?.selectedRow.isArchived,
                        isActive: data,
                        isFavorite: selectedRowData.selectedRow.isFavorite,
                    },
                },
            },
        ];

        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport`;
        mutate(
            apiUrl,
            await fetch(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(obj),
                method: "POST",
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }
                    toast.success(
                        data === true
                            ? `${t(
                                  "Sale support has been successfully activated"
                              )}`
                            : `${t(
                                  "Sale support has been successfully deactivated"
                              )}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setIsDataUpdated!(true);
                    setShopDataIsUpdated!(true);
                })
                .catch((error: any) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }

    async function handleActiveSaleSupport(selectedRowData: any) {
        toast.dismiss();
        setLimit(1);
        let dataService = [
            {
                userId: userID,
                shopId: [selectedRowData.selectedRow.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                isShop: true,
                data: {
                    [selectedRowData.selectedRow.Id]: {
                        id: selectedRowData.selectedRow.Id,
                        displayName:
                            selectedRowData?.selectedRow[t("Display name")],
                        tag: selectedRowData?.selectedRow.Tag,
                        support_de_vente:
                            selectedRowData?.selectedRow[t("Sale support")],
                        shopId: selectedRowData?.selectedRow.shopId,
                        isActive: selectedRowData.selectedRow.isActive,
                        isArchived: false,
                        isFavorite: selectedRowData.selectedRow.isFavorite,
                    },
                },
            },
        ];

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataService),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                "Sale support has been successfully activated"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsDataUpdated!(true);
                        setShopDataIsUpdated!(true);
                        setFranchiseDataIsUpdated!(true);
                        setNumberActiveMultiShop(numberActiveMultiShop + 1);
                        setNumberArchiveMultiShop(numberArchiveMultiShop - 1);
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function handleArchiveSaleSupport(selectedRowData: any) {
        setLimit(1);
        let saleSupportUsed: any = [];
        const listOfSaleSupport = encodeURIComponent(
            selectedRowData[t("Sale support")]
        );
        const url = `${process.env.REACT_APP_API_V2_URL}/settings/general/isSaleSupportUsed?userId=${userID}&shopId=${selectedRowData?.shopId}&franchiseId=${franchiseID}&saleSupport=${listOfSaleSupport}`;
        mutate(
            url,
            await fetch(url, {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.saleSupportUsed.length > 0) {
                        saleSupportUsed = result.saleSupportUsed;
                        toast.error(
                            `${t(
                                "Attention, you cannot archive a used element."
                            )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                    }
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );

        if (saleSupportUsed.length === 0) {
            setIsArchiveSVModalOpened(true);
        }
    }
    async function handleArchiveSaleSupportShop(selectedRowData: any) {
        setLimit(1);
        let dataConfig = [
            {
                userId: userID,
                shopId: [selectedRowData?.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                data: {
                    [selectedRowData?.Id]: {
                        id: selectedRowData.Id,
                        displayName: selectedRowData[t("Display name")],
                        tag: selectedRowData.Tag,
                        support_de_vente: selectedRowData[t("Sale support")],
                        shopId: selectedRowData.shopId,
                        isActive: false,
                        isArchived: true,
                        isFavorite: selectedRowData.isFavorite,
                    },
                },
            },
        ];
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport`;

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataConfig),
        };
        mutate(
            apiUrl,
            await fetch(apiUrl, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }
                    toast.success(
                        `${t("Sale Support is successfully archived")}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setIsDataUpdated!(true);
                    setShopDataIsUpdated!(true);
                    setFranchiseDataIsUpdated!(true);
                    setNumberActiveMultiShop(numberActiveMultiShop - 1);
                    setNumberArchiveMultiShop(numberArchiveMultiShop + 1);
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }
    async function favoriteSaleSupport() {
        setLimit(1);
        toast.dismiss();
        let dataSS: any = [];
        shopsSaleSupportData.forEach((el: any) => {
            let favoriteSaleSupportData;
            el.subRows.forEach((saleSupport: any) => {
                if (
                    saleSupport.Id === selectedRowData.selectedRow.Id &&
                    saleSupport.shopId === selectedRowData.selectedRow.shopId
                ) {
                    favoriteSaleSupportData = {
                        userId: userID,
                        shopId: [selectedRowData.selectedRow.shopId],
                        franchiseId: franchiseID,
                        operatorId: operatorID,
                        isShop: false,
                        data: {
                            [saleSupport.Id]: {
                                id: saleSupport.Id,
                                displayName: saleSupport[t("Display name")],
                                tag: saleSupport.Tag,
                                support_de_vente:
                                    saleSupport[t("Sale support")],
                                shopId: saleSupport.shopId,
                                isActive: saleSupport.isActive,
                                isArchived: saleSupport.isArchived,
                                isFavorite: true,
                            },
                        },
                    };
                    dataSS.push(favoriteSaleSupportData);
                } else if (
                    saleSupport.Id !== selectedRowData.selectedRow.Id &&
                    saleSupport.shopId === selectedRowData.selectedRow.shopId
                ) {
                    favoriteSaleSupportData = {
                        userId: userID,
                        shopId: [selectedRowData.selectedRow.shopId],
                        franchiseId: franchiseID,
                        operatorId: operatorID,
                        isShop: false,
                        data: {
                            [saleSupport.Id]: {
                                id: saleSupport.Id,
                                displayName: saleSupport[t("Display name")],
                                tag: saleSupport.Tag,
                                support_de_vente:
                                    saleSupport[t("Sale support")],
                                shopId: saleSupport.shopId,
                                isActive: saleSupport.isActive,
                                isArchived: saleSupport.isArchived,
                                isFavorite: false,
                            },
                        },
                    };
                    dataSS.push(favoriteSaleSupportData);
                }
            });
        });

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataSS),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                "Favorite state has been enabled successfully"
                            )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setFranchiseDataIsUpdated!(true);
                        setShopDataIsUpdated(true);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function unfavoriteSaleSupport() {
        toast.dismiss();
        setLimit(1);
        let dataSaleSupport = [
            {
                userId: userID,
                shopId: [selectedRowData.selectedRow.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                isShop: false,
                data: {
                    [selectedRowData.selectedRow.Id]: {
                        id: selectedRowData.selectedRow.Id,
                        displayName:
                            selectedRowData.selectedRow[t("Display name")],
                        tag: selectedRowData.selectedRow.Tag,
                        support_de_vente:
                            selectedRowData.selectedRow[t("Sale support")],
                        shopId: selectedRowData.selectedRow.shopId,
                        isActive: selectedRowData.selectedRow.isActive,
                        isArchived: selectedRowData.selectedRow.isArchived,
                        isFavorite: false,
                    },
                },
            },
        ];

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataSaleSupport),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                "Favorite state has been disabled successfully"
                            )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setShopDataIsUpdated!(true);
                        setFranchiseDataIsUpdated(true);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }
    React.useEffect(() => {
        if (isValidateClicked === true) {
            handleArchiveSaleSupportShop(saleSupportToBeArchived);
        }
        // eslint-disable-next-line
    }, [isValidateClicked]);

    return (
        <React.Fragment>
            {selectedRowData.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    {modify === true ? (
                        <>
                            <Tooltip
                                title={
                                    isArchivedSaleSupportClicked
                                        ? ""
                                        : selectedRowData.selectedRow
                                              .isFavorite === false
                                        ? t("Enable favorite state")
                                        : t("Disable favorite state")
                                }
                            >
                                <div>
                                    <HeartIcon
                                        onClick={() => {
                                            if (
                                                selectedRowData.selectedRow
                                                    .isFavorite === true
                                            ) {
                                                unfavoriteSaleSupport();
                                            } else {
                                                favoriteSaleSupport();
                                            }
                                        }}
                                        height={25}
                                        width={25}
                                        name="heart"
                                        fill={
                                            selectedRowData.selectedRow
                                                .isFavorite
                                                ? "red"
                                                : "black"
                                        }
                                        className={`${classnames(
                                            "pointer__clz",
                                            {
                                                "not-allowed-icon__clz": isArchivedSaleSupportClicked,
                                            }
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                            <Tooltip
                                title={
                                    isArchivedSaleSupportClicked
                                        ? ""
                                        : saleSupportState === false
                                        ? t("Activate")
                                        : t("Unactivate")
                                }
                            >
                                <div>
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={saleSupportState}
                                        onChange={async (e: boolean) => {
                                            setSaleSupportState(
                                                !saleSupportState
                                            );
                                            handleStateChange(e);
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                        className={`${classnames(
                                            "pointer__clz ml-1 cmn_icn_BTenableDisable",
                                            {
                                                "not-allowed-icon__clz": isArchivedSaleSupportClicked,
                                            }
                                        )}`}
                                    />
                                </div>
                            </Tooltip>

                            {!isArchivedSaleSupportClicked ? (
                                <Tooltip title={t("Archive")}>
                                    <div>
                                        <TrashIcon
                                            onClick={() => {
                                                setSaleSupportToBeArchived(
                                                    selectedRowData.selectedRow
                                                );
                                                handleArchiveSaleSupport(
                                                    selectedRowData?.selectedRow
                                                );
                                            }}
                                            height={25}
                                            width={25}
                                            fill="red"
                                            style={{ cursor: "pointer" }}
                                            className="ml-1 cmn_icn_BTarchived"
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={
                                        saleSupportData.find(
                                            (el: any) =>
                                                el.Id ===
                                                selectedRowData.selectedRow.Id
                                        ) !== undefined
                                            ? ""
                                            : t("Unarchive")
                                    }
                                >
                                    <div>
                                        <EyeIcon
                                            height={25}
                                            width={25}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                handleActiveSaleSupport(
                                                    selectedRowData
                                                );
                                            }}
                                            className={`${classnames(
                                                "pointer__clz ml-1 cmn_icn_BTunarchive",
                                                {
                                                    "not-allowed-icon__clz":
                                                        saleSupportData.find(
                                                            (el: any) =>
                                                                el.Id ===
                                                                selectedRowData
                                                                    .selectedRow
                                                                    .Id
                                                        ) !== undefined,
                                                }
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : null}
                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                className="pointer__clz ml-1 cmn_icn_BThistory"
                                onClick={() => {
                                    setIsHistoryShopsModalOpened(
                                        !isHistoryShopsModalOpened
                                    );
                                    setSelectedHistorySaleSupports(
                                        selectedRowData.selectedRow
                                    );
                                    setSelectedSaleSupports([
                                        {
                                            [selectedRowData.selectedRow
                                                .shopId]:
                                                selectedRowData.selectedRow.Id,
                                        },
                                    ]);
                                }}
                                height={25}
                                width={25}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
            {isArchiveSVModalOpened ? (
                <ConfirmationArchive
                    isArchiveSVModalOpened={isArchiveSVModalOpened}
                    setIsArchiveSVModalOpened={setIsArchiveSVModalOpened}
                    setIsValidateClicked={setIsValidateClicked}
                />
            ) : null}
        </React.Fragment>
    );
}
