import React from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { useSnapshot } from "valtio";

import {
    generalConfigStore,
    setIsCommentArticleActive,
    setIsCommentRecoveryActive,
    setIsFreeCommentActive,
    setIsPageCommentArticleActive,
    setIsPageCommentRecoveryActive,
    setIsPageFreeCommentActive,
} from "@store";

export function ArticleComment(): JSX.Element {
    const { t } = useTranslation();
    const {
        isCommentArticleActive,
        isFreeCommentActive,
        isCommentRecoveryActive,
    } = useSnapshot(generalConfigStore);

    function handleIsCommentArticleActiveSwitchOnClickEvent() {
        setIsCommentArticleActive(!isCommentArticleActive);
        setIsPageCommentArticleActive(!isCommentArticleActive);

        setIsFreeCommentActive(!isCommentArticleActive);
        setIsPageFreeCommentActive(!isCommentArticleActive);
        setIsCommentRecoveryActive(!isCommentArticleActive);
        setIsPageCommentRecoveryActive(!isCommentArticleActive);
    }

    return (
        <div
            style={{
                display: "grid",
                gridTemplateRows: "0.4fr 1.6fr",
                gap: "40px",
            }}
        >
            <div className="d-flex align-items-center mt-3">
                <span
                    style={{
                        font: "normal normal 600 35px/61px Nunito Sans",
                    }}
                >
                    {t("Article comment")}
                </span>
            </div>

            <div className="d-flex flex-column" style={{ gap: "30px" }}>
                <div className="d-flex flex-row justify-content-between align-items-center">
                    {t("Activate the module")}

                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isCommentArticleActive}
                        onChange={() => {
                            handleIsCommentArticleActiveSwitchOnClickEvent();
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
                <div className=" d-flex flex-row justify-content-between align-items-center">
                    {t("Enable free comment")}

                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isFreeCommentActive}
                        disabled={
                            !isCommentArticleActive || !isCommentRecoveryActive
                        }
                        onChange={() => {
                            setIsFreeCommentActive(!isFreeCommentActive);
                            setIsPageFreeCommentActive(!isFreeCommentActive);
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
                <div className=" d-flex flex-row justify-content-between align-items-center">
                    {t("Enable comment recovery")}

                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isCommentRecoveryActive}
                        disabled={
                            !isCommentArticleActive || !isFreeCommentActive
                        }
                        onChange={() => {
                            setIsCommentRecoveryActive(
                                !isCommentRecoveryActive
                            );
                            setIsPageCommentRecoveryActive(
                                !isCommentRecoveryActive
                            );
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
            </div>
        </div>
    );
}
