import React from "react";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { Card } from "reactstrap";

import {
    generalConfigStore,
    setLogo,
    designStore,
    setProjectFiles,
    setIsResetOneImageButtonClicked,
} from "@store";

import { getImageContentById } from "@helpers/general";

import { Uploader } from "@components/Uploader";

export function LogoWrapper(): JSX.Element {
    const { t } = useTranslation();

    const {
        project: { template, files: images },
    } = useSnapshot(generalConfigStore);
    const { selectedLanguage } = useSnapshot(designStore);

    const [localLogo, setLocalLogo] = React.useState<GeneralDesignLogoType>(
        (template.content.generalDesign as GeneralDesignType).logo
    );

    const imageContent = getImageContentById(
        localLogo.id,
        images,
        selectedLanguage
    );

    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    function handleUploadImageOnClickEvent(id: string, image: string) {
        setLocalLogo(
            (prevState: GeneralDesignLogoType): GeneralDesignLogoType => {
                const newState = { ...prevState };
                if (newState.id === id) {
                    return { ...newState, content: image };
                }
                setLogo(newState);
                return newState;
            }
        );
    }
    function handleResetImagesOnClickEvent(id: string) {
        const imagesArray = [...images];

        const image = imagesArray.find(
            (element) => element.key === "Logo" && element.id === id
        );
        if (image !== undefined) {
            const imageIndex = imagesArray.indexOf(image);

            imagesArray[imageIndex] = {
                ...image,
                content: imagesArray[imageIndex].defaultImage as string,
            };
        }

        setProjectFiles(imagesArray);
    }

    return (
        <div className="d-flex flex-column cursor__clz" style={{ gap: "10px" }}>
            <Card
                style={{
                    width: "270px",
                    height: "230px",
                    borderRadius: "20px",
                    display: "grid",
                    gridTemplateRows: "0.1fr 0.8fr auto",
                    gap: "10px",
                }}
            >
                <div className="d-flex justify-content-end">
                    <StyledIconButton
                        title={t("Reset to default image")}
                        outline={true}
                        variant="danger"
                        className="m-0 p-0 "
                        icon="RefreshIcon"
                        style={{ height: "20%", width: "20px" }}
                        onClick={() => {
                            handleResetImagesOnClickEvent(localLogo.id);
                            setIsResetOneImageButtonClicked(true);
                        }}
                    >
                        <RefreshIcon height={20} width={20} />
                    </StyledIconButton>
                </div>
                <div className="d-flex justify-content-center align-items-end">
                    <img
                        alt="img"
                        src={imageSrc}
                        style={{
                            width: "100px",
                            height: "100px",
                        }}
                    />
                </div>

                <div
                    className="d-flex justify-content-center align-items-center h-100"
                    onClick={() =>
                        handleUploadImageOnClickEvent(
                            localLogo.id,
                            imageSrc as string
                        )
                    }
                >
                    <StyledIconButton
                        style={{
                            width: "50px",
                            height: "50px",
                            boxShadow: "0px 3px 6px #00000029",
                        }}
                        className="m-0"
                    >
                        <Uploader id={localLogo.id} />
                    </StyledIconButton>
                </div>
            </Card>

            <div className="d-flex justify-content-center align-items-center">
                <span
                    style={{
                        font: "normal normal normal 15px/20px Nunito Sans",
                        color: "black",
                    }}
                >
                    {t(localLogo.name)}
                </span>
            </div>
        </div>
    );
}
