import React from "react";
import { useSnapshot } from "valtio";

import { designStore, generalConfigStore } from "@store";

import { getImageContentById, truncateString } from "@helpers/general";

export function HeaderWrapper(): JSX.Element {
    const {
        isUserAccountOrderTakingActive,
        userAccountOrderTakingMessageItems,
        selectedLanguage,
    } = useSnapshot(designStore);

    const {
        isCustomerAccountOrderTakingActive,
        isAllergenOrderTakingActive,
        project: { template, files: images },
    } = useSnapshot(generalConfigStore);

    const accountButton = ((template.pages.ways[
        "orderTaking"
    ] as OrderTakingPageType).design as ActionsButtonsDesignType).items.filter(
        (item) => item.name === "Customer account"
    )[0];

    const accountButtonContent = getImageContentById(
        accountButton.id as string,
        images,
        selectedLanguage
    );
    const accountButtonImageSrc =
        accountButtonContent?.includes("http") ||
        accountButtonContent?.includes("https")
            ? accountButtonContent
            : `./images/${accountButtonContent}`;

    const allergensButton = ((template.pages.ways[
        "orderTaking"
    ] as OrderTakingPageType).design as ActionsButtonsDesignType).items.filter(
        (item) => item.name === "Allergen"
    )[0];

    const allergensButtonContent = getImageContentById(
        allergensButton.id as string,
        images,
        selectedLanguage
    );
    const allergensButtonImageSrc =
        allergensButtonContent?.includes("http") ||
        allergensButtonContent?.includes("https")
            ? allergensButtonContent
            : `./images/${allergensButtonContent}`;

    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                alignItems: "center",
            }}
        >
            <div>
                {isUserAccountOrderTakingActive === true ? (
                    <div
                        className="ml-2 d-flex justify-content-start align-items-center"
                        style={{ gap: "3px" }}
                    >
                        <span>
                            {userAccountOrderTakingMessageItems[
                                selectedLanguage
                            ].content !== undefined
                                ? truncateString(
                                      userAccountOrderTakingMessageItems[
                                          selectedLanguage
                                      ].content,
                                      13
                                  )
                                : ""}
                        </span>
                    </div>
                ) : null}
            </div>
            <div
                className="d-flex justify-content-end align-items-center"
                style={{ marginTop: "4px" }}
            >
                <div
                    className="d-flex justify-content-end align-items-center px-3"
                    style={{ gap: "10px" }}
                >
                    {isAllergenOrderTakingActive === true ? (
                        <img
                            alt="allergens"
                            src={allergensButtonImageSrc}
                            style={{ width: "80px", height: "30px" }}
                        />
                    ) : null}

                    {isCustomerAccountOrderTakingActive === true ? (
                        <img
                            alt="account"
                            src={accountButtonImageSrc}
                            style={{ width: "80px", height: "30px" }}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
}
