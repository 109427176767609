import React from "react";

export default function BellSvgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={46}
            height={46}
            viewBox="0 0 46 46"
            {...props}
        >
            <g data-name="Groupe 32553" transform="translate(16212 8355)">
                <path
                    fill="#fff"
                    d="M6 0h34a6 6 0 016 6v34a6 6 0 01-6 6H6a6 6 0 01-6-6V6a6 6 0 016-6z"
                    transform="translate(-16212 -8355)"
                ></path>
                <path
                    fill="#393939"
                    d="M16.769 12.324l-3.032 4.948a4.208 4.208 0 01-3.111 1.991 4.273 4.273 0 01-.5.03 4.19 4.19 0 01-2.412-.768 3.811 3.811 0 01-5.377-5.278l-1.062-1.055A4.232 4.232 0 012.043 5.6l4.636-2.867a7.122 7.122 0 018.3.443l1.334-1.334a.8.8 0 111.137 1.137l-1.331 1.333a7.2 7.2 0 01.65 8.012zM6.484 17.4l-2.99-2.99a2.187 2.187 0 00.372 2.624 2.273 2.273 0 002.618.366zm7.975-12.54a5.536 5.536 0 00-6.927-.76L2.888 6.966a2.625 2.625 0 00-.476 4.089l5.86 5.861a2.625 2.625 0 004.094-.482l3.019-4.924a5.576 5.576 0 00-.926-6.653zm1.028 14.432a.8.8 0 01-.475-1.453 6.94 6.94 0 002.665-4.347.8.8 0 111.57.346 8.6 8.6 0 01-3.286 5.3.8.8 0 01-.473.155zM.8 4.613a.8.8 0 01-.638-1.281A8.6 8.6 0 015.558.016a.8.8 0 01.322 1.576 6.942 6.942 0 00-4.432 2.7.8.8 0 01-.648.321z"
                    transform="translate(-16198.005 -8340)"
                ></path>
            </g>
        </svg>
    );
}
