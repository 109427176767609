import React from "react";
import { StyledButton } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import store, { setApplications, setApplicationsToBeDeleted } from "./store";

import "./index.css";

export function SelectionButton({ element, rowData }: any) {
    const { t } = useTranslation();

    const { applications } = useSnapshot(store);

    const [isSelected, setIsSelected] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (rowData !== undefined) {
            rowData.subRows.forEach((el: any) => {
                setApplicationsToBeDeleted(element);
                if (el.appId === element["appId"]) {
                    setApplications(element);
                    setIsSelected(true);
                }
            });
        }
        // eslint-disable-next-line
    }, [rowData]);

    return (
        <StyledButton
            rounded
            outline
            className={`${classnames("button-style-select___clz", {
                "not-allowed-icon__clz":
                    // isAddAppClicked ||
                    isSelected === false &&
                    applications.length === 1 &&
                    rowData.peripheralType !== "printer",
            })}`}
            variant={isSelected ? "danger" : "light"}
            style={{}}
            onClick={() => {
                setApplications(element);
                setIsSelected(!isSelected);
            }}
        >
            <span>{isSelected ? t("Deselect") : t("Select")}</span>
        </StyledButton>
    );
}
