import React from "react";
import { CheckboxIcon, StyledH2 } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { capitalize } from "@helpers/general";
import { toast } from "react-toastify";

import store, { setSelected } from "./store";
import { useSnapshot } from "valtio";
import PrinterActionColumns from "./PrinterActionColumns";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type PrintersPathsModalType = {
    setIsPrintersPathsModalOpened: Function;
    isPrintersPathsModalOpened: boolean;
    setPrinterPath: Function;
    printerPath: string;
};

type CustomColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
};

export default function PrintersPathsModal({
    setIsPrintersPathsModalOpened,
    isPrintersPathsModalOpened,
    setPrinterPath,
    printerPath,
}: PrintersPathsModalType) {
    const { t } = useTranslation();
    const { path } = useSnapshot(store);

    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);

    async function handleValidateButtonClickEvent() {
        if (path !== "") {
            toast.success(`${t("The selected path is added")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                icon: <CheckboxIcon height={25} width={25} fill="white" />,
            });

            setIsPrintersPathsModalOpened!(!isPrintersPathsModalOpened);
        } else {
            toast.error(`${t("No path added")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    let arrayOfCustomColumns: CustomColumnProps[] = [];

    arrayOfCustomColumns.push({
        indexOFColumn: 1,
        columnName: t("Actions"),
        customJsx: PrinterActionColumns,
    });

    return (
        <React.Fragment>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                className="modal-wrapper__clz"
                isOpen={isPrintersPathsModalOpened}
            >
                <ModalHeader
                    toggle={() => {
                        setIsPrintersPathsModalOpened!(
                            !isPrintersPathsModalOpened
                        );
                    }}
                >
                    <StyledH2>
                        {capitalize(
                            t("Select a printer installed under windows")
                        )}
                    </StyledH2>
                </ModalHeader>
                <ModalBody>
                    <DynamicTable
                        url={`${process.env.REACT_APP_API_V2_URL}/settings/printers/paths`}
                        showGlobalFilter
                        filterActive={filterActive}
                        setLocalFilterActive={setLocalFilterActive}
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        onClick={(rows: any) => {
                            setPrinterPath!(rows.allCells[0].value);
                        }}
                    />
                </ModalBody>
                <ModalFooter>
                    <CustomSecondaryColorButton
                        rounded
                        variant="light"
                        outline
                        onClick={() => {
                            setIsPrintersPathsModalOpened!(
                                !isPrintersPathsModalOpened
                            );
                            setSelected(printerPath);
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        onClick={() => {
                            handleValidateButtonClickEvent();
                            setSelected(path);
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
