import React from "react";
import { useTranslation } from "react-i18next";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { Nav } from "reactstrap";
import { useSnapshot } from "valtio";

import {
    designStore,
    generalConfigStore,
    setSelectedRegulationModeValue,
    setFinalMessageItems,
    setProjectFinalMessageItems,
} from "@store";

import { SwiperLanguagesWrapper } from "@components/Common/Design/SwiperLanguagesWrapper";
import { TableContent } from "@pages/GeneralConfig/Design/Content/FinalMessage/Design/MessagesTranslationWrapper/TableContent";
import { removeDuplicatesFromArrayOfObjectsByKey } from "@helpers/general";

export function MessagesTranslationWrapper(): JSX.Element {
    const { t } = useTranslation();

    const { selectedRegulationModeValue, finalMessageItems } = useSnapshot(
        designStore
    );

    const {
        project: {
            template: {
                content: { meansOfPayment },
            },
        },
    } = useSnapshot(generalConfigStore);

    function handleResetTranslationOnClickEvent() {
        const localItems = [...finalMessageItems];

        const initialFinalMessagesItems = localItems
            .filter((item: FinalMessageItemType) => {
                return item.title === selectedRegulationModeValue;
            })
            .map((item) => {
                const newFinalMessageItem = Object.keys(item)
                    .filter((informationMode) => informationMode !== "title")
                    .map((informationMode: string) => {
                        const languages = Object.fromEntries(
                            Object.entries(item[informationMode].languages).map(
                                ([key, value]) => [
                                    key,
                                    {
                                        ...value,
                                        content: value.defaultContent,
                                    },
                                ]
                            )
                        );
                        return {
                            [informationMode]: {
                                ...item[informationMode],
                                languages,
                            },
                        };
                    });

                return newFinalMessageItem;
            })[0];

        const element = localItems.find(
            (element) => element.title === selectedRegulationModeValue
        );

        if (element !== undefined) {
            const newInitialFinalMessagesItems = initialFinalMessagesItems.reduce(
                (result, item) => {
                    const key = Object.keys(item)[0];
                    result[key] = item[key];
                    return result;
                },
                {}
            );

            const newLocalItems = localItems.filter(
                (item: FinalMessageItemType) => {
                    return item.title !== selectedRegulationModeValue;
                }
            );

            const newFinalMessageItems = removeDuplicatesFromArrayOfObjectsByKey(
                [
                    ...newLocalItems,
                    {
                        ...newInitialFinalMessagesItems,
                        title: selectedRegulationModeValue,
                    },
                ],
                "title"
            ) as FinalMessageItemType[];

            setFinalMessageItems(newFinalMessageItems);

            setProjectFinalMessageItems(newFinalMessageItems);
        }
    }

    return (
        <React.Fragment>
            <div
                className="d-flex align-items-center"
                style={{ height: "60px" }}
            >
                <Nav
                    tabs
                    className="d-grid__clz"
                    style={{
                        backgroundColor: "white",
                        width: "50px",
                        gridTemplateColumns: `repeat(${
                            (meansOfPayment as ProjectContentItemType).items.filter(
                                (item: ProjectMainContentItemType) => {
                                    return item.active === true;
                                }
                            ).length
                        }, 1fr)`,
                        border: "unset",
                        gap: "15px",
                    }}
                >
                    {(meansOfPayment as ProjectContentItemType).items
                        .filter((item: ProjectMainContentItemType) => {
                            return item.active === true;
                        })
                        .map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "150px",
                                        height: "34px",
                                        borderRadius: "5px",
                                        background:
                                            selectedRegulationModeValue ===
                                            item.name
                                                ? "#323333 0% 0% no-repeat padding-box"
                                                : "#E9E9E9 0% 0% no-repeat padding-box",
                                        gap: "10px",
                                        textAlign: "center",
                                        font:
                                            "normal normal 600 15px/20px Segoe UI",
                                        letterSpacing: "0px",
                                        color:
                                            selectedRegulationModeValue ===
                                            item.name
                                                ? "#FFFFFF"
                                                : "#000000",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setSelectedRegulationModeValue(
                                            item.name
                                        );
                                    }}
                                >
                                    {t(item.name)}
                                </div>
                            );
                        })}
                </Nav>
            </div>
            <div
                className="mt-3 rounded border"
                style={{
                    width: "800px",
                    height: "490px",
                    borderColor: "#CECECE",
                }}
            >
                <div
                    className="d-flex justify-content-between align-items-center"
                    style={{
                        backgroundColor: "#EDEDED",
                        height: "40px",
                        fontSize: "20px",
                    }}
                >
                    <div className="text-left px-4 d-flex align-items-center cursor__clz">
                        {t("Translation")}
                    </div>
                    <StyledIconButton
                        title={t("Reset")}
                        icon="RefreshIcon m-0"
                        onClick={() => {
                            handleResetTranslationOnClickEvent();
                        }}
                    >
                        <RefreshIcon height={15} width={15} />
                    </StyledIconButton>
                </div>

                <div className="p-2" style={{ width: "798px" }}>
                    <SwiperLanguagesWrapper />
                    <TableContent />
                </div>
            </div>
        </React.Fragment>
    );
}
