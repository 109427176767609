import React from "react";
import { useSnapshot } from "valtio";

import { designStore } from "@store";

import { UsualNumericKeyboardWrapper } from "@components/Common/UsualNumericKeyboardWrapper";
import { UsualKeyboardWrapper } from "@components/Common/UsualKeyboardWrapper";
import { LanguagesWrapper } from "@components/Common/Design/Preview/LanguagesWrapper";
import { ConfigOptionsWrapper } from "@components/Common/Design/Preview/ConfigOptionsWrapper";
import { SubStepContainerWrapper } from "@components/Common/Design/Preview/SubStepWrapper/SubStepContainerWrapper";
import { ActionsButtons } from "@components/Common/Design/Preview/PersonalizedInformationModes/ActionsButtons";

import { TopBannerWrapper } from "./TopBannerWrapper";
import { HeaderWrapper } from "./HeaderWrapper";
import { PreviewBackgroundWrapper } from "../PreviewBackgroundWrapper";

export function SubStepWrapper(): JSX.Element {
    const {
        isLanguagesActive,
        isConfigOptionsActive,
        isSubStepTopBannerActive,
        isSubStepActionButtonsActive,
        isIconActive,
        isSubStepInformationMessageActive,
        selectedSubStep,
    } = useSnapshot(designStore);

    return (
        <PreviewBackgroundWrapper selectedSubStep={selectedSubStep}>
            <SubStepContainerWrapper
                isSubStepTopBannerActive={isSubStepTopBannerActive}
                isSubStepIconActive={isIconActive}
                isSubStepInformationMessageActive={
                    isSubStepInformationMessageActive
                }
                isLanguagesActive={isLanguagesActive}
                isConfigOptionsActive={isConfigOptionsActive}
                isSubStepActionButtonsActive={isSubStepActionButtonsActive}
            >
                {isSubStepTopBannerActive ? <TopBannerWrapper /> : null}
                <HeaderWrapper />
                <div className="d-flex justify-content-center align-items-center">
                    {selectedSubStep.shortName === "emailAndPassword" ? (
                        <UsualKeyboardWrapper />
                    ) : (
                        <UsualNumericKeyboardWrapper />
                    )}
                </div>

                <ActionsButtons />

                {isLanguagesActive ? <LanguagesWrapper /> : null}

                {isConfigOptionsActive ? <ConfigOptionsWrapper /> : null}
            </SubStepContainerWrapper>
        </PreviewBackgroundWrapper>
    );
}
