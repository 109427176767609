import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";

import {
    CheckboxIcon,
    EyeIcon,
    PencilIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { mutate } from "swr";
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";

import ErrorToast from "@components/Common/ErrorTost";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";

import store, {
    setNumberActive,
    setNumberArchived,
    setSelectedLogo,
    setArchivedData,
    setIsArchiveModalOpened,
    setIsHistoryModalOpened,
    setIsActionsClicked,
    setIsEdited,
    setActions,
} from "../store";

import { store as useStore } from "../../../../components/VerticalLayout/store";

export function CustomIcons({
    selectedRowData,
    setSelectedRows,
    setShopId,
    setLogo,
    setDataIsUpdated,
    setIsModalConfirmationOpened,
    isModalConfirmationOpened,
    isAddLogoClicked,
    setIsAddLogoClicked,
    isLogoArchived,
    setLocalFilterActive,
}: any) {
    const {
        numberActive,
        numberArchived,
        shopsLogoData,
        isArchiveModalOpened,
        isHistoryModalOpened,
        isActionsClicked,
        isEdited,
        modify,
    } = useSnapshot(store);
    const { franchiseID, userID, oneShop, operatorID } = useSnapshot(useStore);
    const [choiceState, setLogoState] = React.useState<boolean>(
        selectedRowData.selectedRow.isActive
    );
    const [isClicked, setIsClicked] = React.useState<boolean>(false);

    const { t } = useTranslation();

    let dataArchive: any[] = [];

    if (!oneShop) {
        dataArchive = ArchivedLogo();
    } else {
        dataArchive = ArchivedLogoFranchise();
    }

    function ArchivedLogoFranchise() {
        let idsAssociated: any[] = [];
        if (shopsLogoData !== undefined) {
            shopsLogoData.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    function ArchivedLogo() {
        let idsAssociated: any[] = [];
        if (shopsLogoData !== undefined) {
            Object.values(shopsLogoData).forEach((element: any) => {
                element?.subRows.forEach((el: any) => {
                    idsAssociated.push(el.Id);
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    async function handleStateChange(dataState: boolean) {
        let id = selectedRowData?.selectedRow.Id;
        let dataLogo = {
            userId: userID,
            shopId: [selectedRowData?.selectedRow.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            data: {
                [id]: {
                    designation: selectedRowData?.selectedRow[t("Designation")],
                    tag: selectedRowData?.selectedRow.tag,
                    shopId: selectedRowData?.selectedRow.shopId,
                    isArchived: selectedRowData?.selectedRow.isArchived,
                    isActive: dataState,
                    support_de_vente:
                        selectedRowData.selectedRow[t("Sales support")],
                    urlImage: selectedRowData.selectedRow[t("Image")],
                    img: selectedRowData.selectedRow.img,
                },
            },
        };
        const data = new FormData();
        data.append("dataLogo", JSON.stringify(dataLogo));
        data.append("image", "");
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/logo`;
        const requestOptions = {
            method: "POST",

            body: data,
        };
        mutate(
            apiUrl,
            await fetch(apiUrl, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }

                    toast.success(
                        !choiceState
                            ? `${t("logo has been successfully activated")}`
                            : `${t("logo has been successfully deactivated")}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setDataIsUpdated!(true);
                })
                .catch((error: any) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }

    function handleTrashButtonClicked(selectedRow: any) {
        let idsAssociated: any = [];
        shopsLogoData.forEach((el: any) => {
            el.subRows.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        });
        if (idsAssociated.includes(selectedRow.Id) && !oneShop) {
            ErrorToast(
                `${t(
                    "Please note that you cannot archive an item assigned to stores."
                )!}`
            );
            setDataIsUpdated(true);
        } else {
            setArchivedData(selectedRowData);
            setIsArchiveModalOpened!(!isArchiveModalOpened);
            archiveLogo(selectedRow);
        }
    }
    async function archiveLogo(element: any) {
        setIsClicked(false);
        toast.dismiss();
        let dataLogo = {
            userId: userID,
            shopId: [element.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,

            data: {
                [element.Id]: {
                    designation: element[t("Designation")],
                    support_de_vente: element[t("Sales support")],
                    urlImage: element[t("Image")],
                    shopId: element.shopId,
                    img: element.img,
                    tag: element.tag,
                    isActive: element.isActive,
                    isArchived: true,
                },
            },
        };

        const data = new FormData();
        data.append("dataLogo", JSON.stringify(dataLogo));
        data.append("image", "");
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/logo`;
        const requestOptions = {
            method: "POST",
            body: data,
        };
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t("The logo is successfully archived")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated!(true);
                        setNumberActive(numberActive - 1);
                        setNumberArchived(numberArchived + 1);
                        setIsClicked(true);
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function activeLogo(selectedRowData: any) {
        setIsClicked(false);
        toast.dismiss();
        let dataLogo = {
            userId: userID,
            shopId: [selectedRowData.selectedRow.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,

            data: {
                [selectedRowData.selectedRow.Id]: {
                    designation: selectedRowData?.selectedRow[t("Designation")],
                    tag: selectedRowData?.selectedRow.tag,
                    shopId: selectedRowData?.selectedRow.shopId,

                    isActive: false,
                    support_de_vente:
                        selectedRowData.selectedRow[t("Sales support")],
                    urlImage: selectedRowData.selectedRow[t("Image")],
                    img: selectedRowData.selectedRow.img,
                    isArchived: false,
                },
            },
        };
        const data = new FormData();
        data.append("dataLogo", JSON.stringify(dataLogo));
        data.append("image", "");
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/logo`;
        const requestOptions = {
            method: "POST",
            body: data,
        };
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t("logo has been successfully activated")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated!(true);
                        setNumberActive(numberActive + 1);
                        setNumberArchived(numberArchived - 1);
                        setIsClicked(true);
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <React.Fragment>
            {selectedRowData.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    {modify === true ? (
                        <>
                            <Tooltip title={isLogoArchived ? "" : t("Edit")}>
                                <div>
                                    <PencilIcon
                                        onClick={() => {
                                            if (isEdited === true) {
                                                setIsActionsClicked(
                                                    !isActionsClicked
                                                );
                                                setActions(() => {
                                                    setIsEdited(false);
                                                    setShopId(
                                                        selectedRowData
                                                            .selectedRow.shopId
                                                    );
                                                    setLogo(
                                                        selectedRowData.selectedRow
                                                    );

                                                    if (!isAddLogoClicked) {
                                                        setIsAddLogoClicked(
                                                            !isAddLogoClicked
                                                        );
                                                    }
                                                    setLocalFilterActive(false);
                                                });
                                            } else {
                                                setShopId(
                                                    selectedRowData.selectedRow
                                                        .shopId
                                                );
                                                setLogo(
                                                    selectedRowData.selectedRow
                                                );

                                                if (!isAddLogoClicked) {
                                                    setIsAddLogoClicked(
                                                        !isAddLogoClicked
                                                    );
                                                }
                                                setLocalFilterActive(false);
                                            }
                                        }}
                                        height={25}
                                        width={25}
                                        style={{ cursor: "pointer" }}
                                        className={`${classnames(
                                            "pointer__clz cmn_icn_FRedit",
                                            {
                                                "not-allowed-icon__clz": isLogoArchived,
                                            },
                                            oneShop ? "mr-2" : "ml-3"
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                            {oneShop && (
                                <Tooltip
                                    title={
                                        isLogoArchived
                                            ? ""
                                            : choiceState === false
                                            ? t("Activate")
                                            : t("Unactivate")
                                    }
                                >
                                    <div>
                                        <ReactSwitch
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            handleDiameter={26}
                                            offColor="#f7b4b8"
                                            offHandleColor="#E30613"
                                            checked={choiceState}
                                            onChange={async (e: boolean) => {
                                                setLogoState(!choiceState);

                                                handleStateChange(e);
                                            }}
                                            onColor="#c2eddd"
                                            onHandleColor="#34C38F"
                                            width={50}
                                            height={20}
                                            className={`${classnames(
                                                "lgo_icn_BTiconEdit  lgo_icn_BTiconEnableDisable",
                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}

                            {!isLogoArchived ? (
                                <Tooltip title={t("Archive")}>
                                    <div>
                                        <TrashIcon
                                            onClick={() => {
                                                if (isEdited === true) {
                                                    setIsActionsClicked(
                                                        !isActionsClicked
                                                    );
                                                    setActions(() => {
                                                        setIsEdited(false);
                                                        setLogo(
                                                            selectedRowData.selectedRow
                                                        );

                                                        handleTrashButtonClicked(
                                                            selectedRowData.selectedRow
                                                        );
                                                    });
                                                } else {
                                                    setLogo(
                                                        selectedRowData.selectedRow
                                                    );

                                                    handleTrashButtonClicked(
                                                        selectedRowData.selectedRow
                                                    );
                                                }
                                            }}
                                            height={25}
                                            width={25}
                                            fill="red"
                                            style={{ cursor: "pointer" }}
                                            className={`${classnames(
                                                "pointer__clz  cmn_icn_FRarchived",
                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={
                                        dataArchive.includes(
                                            selectedRowData.selectedRow.Id
                                        ) && oneShop
                                            ? ""
                                            : t("Unarchive")
                                    }
                                >
                                    <div>
                                        <EyeIcon
                                            height={25}
                                            width={25}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setLogo(
                                                    selectedRowData.selectedRow
                                                );
                                                setSelectedRows(
                                                    selectedRowData
                                                );
                                                if (
                                                    dataArchive.includes(
                                                        selectedRowData
                                                            .selectedRow.Id
                                                    ) &&
                                                    !oneShop
                                                ) {
                                                    setIsModalConfirmationOpened(
                                                        !isModalConfirmationOpened
                                                    );
                                                } else {
                                                    activeLogo(selectedRowData);
                                                }
                                            }}
                                            className={`${classnames(
                                                "pointer__clz cmn_icn_FRunarchive",
                                                {
                                                    "not-allowed-icon__clz":
                                                        (dataArchive.includes(
                                                            selectedRowData
                                                                .selectedRow.Id
                                                        ) &&
                                                            oneShop) ||
                                                        isClicked,
                                                },
                                                oneShop ? "mr-2" : "ml-3"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : null}
                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                className={`${classnames(
                                    "pointer__clz cmn_icn_FRhistory",
                                    oneShop ? "mr-2" : "ml-3"
                                )}`}
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(() => {
                                            setIsEdited(false);
                                            setIsAddLogoClicked(false);
                                            setIsHistoryModalOpened(
                                                !isHistoryModalOpened
                                            );

                                            setSelectedLogo([
                                                {
                                                    [selectedRowData.selectedRow
                                                        .shopId]:
                                                        selectedRowData
                                                            .selectedRow.Id,
                                                },
                                            ]);
                                        });
                                    } else {
                                        setIsHistoryModalOpened(
                                            !isHistoryModalOpened
                                        );
                                        setSelectedLogo([
                                            {
                                                [selectedRowData.selectedRow
                                                    .shopId]:
                                                    selectedRowData.selectedRow
                                                        .Id,
                                            },
                                        ]);
                                    }
                                }}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
        </React.Fragment>
    );
}
