import { proxy } from "valtio";

type StoreType = {
    emptyAlertValue: any;
    loadingAlertValue: any;
    isSettingIconEmptyClicked: boolean;
    isSettingIconLoadingClicked: boolean;

};

export const store = proxy<StoreType>({
    emptyAlertValue: {},
    loadingAlertValue: {},
    isSettingIconEmptyClicked: false,
    isSettingIconLoadingClicked: false
});

export function setEmptyAlertValue(
    value: any
): void {
    store.emptyAlertValue[Object.keys(value)[0]] = Object.values(value)[0]
}
export function setLoadingAlertValue(
    value: any
): void {
    store.loadingAlertValue[Object.keys(value)[0]] = Object.values(value)[0]
}

export function defaultEmptyAlertValue(
    value: any
): void {
    store.emptyAlertValue = value
}

export function defaultLoadingAlertValue(
    value: any
): void {
    store.loadingAlertValue = value
}

export function resetEmptyAlertValue(
): void {
    store.emptyAlertValue = {};
}

export function resetLoadingAlertValue(
): void {
    store.loadingAlertValue = {};
}

export function confirmDeleteEmptyAlertValue(
    id: string
): void {
    delete store.emptyAlertValue[id];
}

export function confirmDeleteLoadingAlertValue(
    id: string
): void {
    delete store.loadingAlertValue[id];
}
export function setIsSettingIconEmptyClicked(
    value: boolean
): void {
    store.isSettingIconEmptyClicked = value;
}
export function setIsSettingIconLoadingClicked(
    value: boolean
): void {
    store.isSettingIconLoadingClicked = value;
}
export default store;
