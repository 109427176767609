import React, { useEffect, useRef } from "react";
import { StyledIconButton } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";

import { store } from "@store";

export function CustomMainColorIconButton(props: any) {
    const { mainColor } = useSnapshot(store);
    const elementRef = useRef(null);
    const [rgbColor, setRgbColor] = React.useState([]);

    useEffect(() => {
        if (elementRef.current) {
            const element = elementRef.current;
            const computedColor = (window.getComputedStyle(element) as any)[
                "background-color"
            ];
            var rgbValues = computedColor.match(/\d+/g);
            setRgbColor(rgbValues);
        }
    }, []);

    return (
        <StyledIconButton
            {...props}
            ref={elementRef}
            style={{
                backgroundColor: mainColor,
                color:
                    Number(rgbColor[0]) > 200 &&
                    Number(rgbColor[1]) > 200 &&
                    Number(rgbColor[2]) > 200
                        ? "black"
                        : "white",
            }}
        />
    );
}
