import React from "react";
import {
    StyledButton,
    StyledLabel,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { AvGroup } from "availity-reactstrap-validation";
import classnames from "classnames";
import ShowPasswordSvgIcon from "@components/Common/SvgIcons/ShowPasswordSvgIcon";
import HidePasswordSvgIcon from "@components/Common/SvgIcons/HidePasswordSvgIcon";
import { Col } from "reactstrap";
import { checkIpIfExist } from "@helpers/general";

import { store } from "../../../../components/VerticalLayout/store";
import { useSnapshot } from "valtio";

import { ipMatchWord, numberMatchWord } from "../../../../constants";

import "react-dropzone-uploader/dist/styles.css";
import "react-toastify/dist/ReactToastify.css";

type PeripheralsConfigurationsType = {
    data: any;
    setDataIsUpdated: Function;
    isEdited: boolean;
    setIsEdited: Function;
    editedData: any;
    localShopId: any;
    isConsult: boolean;
};

type CustomColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
};

export default function CashManagementConfiguration({
    data,
    setDataIsUpdated,
    isEdited,
    setIsEdited,
    editedData,
    localShopId,
    isConsult,
}: PeripheralsConfigurationsType) {
    const { t } = useTranslation();
    const [isNewPasswordDisplayed, setIsNewPasswordDisplayed] = React.useState<
        boolean
    >(false);
    const { resourceIp } = useSnapshot(store);

    let arrayOfCustomColumns: CustomColumnProps[] = [];

    arrayOfCustomColumns.push({
        indexOFColumn: 1,
        columnName: t("Actions"),
        customJsx: () => (
            <StyledButton rounded variant="light">
                <span className="text-dark">{t("Select")}</span>
            </StyledButton>
        ),
    });
    React.useEffect(() => {
        data.setIpExist(
            checkIpIfExist(
                resourceIp,
                data.ipAddress,
                editedData?.Id !== undefined ? editedData?.Id : "",
                editedData?.Id !== undefined
                    ? editedData?.shopId
                    : localShopId.length === 1
                    ? localShopId[0]
                    : ""
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedData, localShopId, data.ipAddress]);
    return (
        <React.Fragment>
            {data.mark === "Cashdro" && (
                <React.Fragment>
                    <AvGroup>
                        <StyledLabel
                            className="required__clz mt-3"
                            htmlFor="name"
                        >
                            {t("IP address")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames("mon_inp_ipAdress", {
                                input__clz:
                                    data.inputIp ||
                                    data.ipExist ||
                                    data.emptyIp,
                                readOnly__clz: isConsult,
                            })}
                            autocomplete="off"
                            id="ipAddress"
                            name="ipAddress"
                            placeholder={t("Enter")}
                            type="text"
                            onChange={(e: any) => {
                                data.setIpAddress(e.target.value);
                                setDataIsUpdated(true);
                                setIsEdited(true);
                                if (
                                    !e.target.value.match(ipMatchWord) &&
                                    e.target.value !== ""
                                ) {
                                    data.setInputIp(true);
                                } else {
                                    data.setInputIp(false);
                                }
                                if (e.target.value !== "") {
                                    data.setEmptyIp(false);
                                }
                                data.setIpExist(
                                    checkIpIfExist(
                                        resourceIp,
                                        e.target.value,
                                        editedData?.Id !== undefined
                                            ? editedData?.Id
                                            : "",
                                        editedData?.Id !== undefined
                                            ? editedData?.shopId
                                            : localShopId.length === 1
                                            ? localShopId[0]
                                            : ""
                                    )
                                );

                                setIsEdited(true);
                            }}
                            value={data.ipAddress}
                        />
                        {data.inputIp || data.ipExist || data.emptyIp ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {
                                    t(
                                        data.emptyIp
                                            ? "Please enter a IP address"
                                            : !data.ipAddress.match(ipMatchWord)
                                            ? "IP address is invalid"
                                            : data.ipExist
                                            ? "The ip address is already exists"
                                            : ""
                                    )!
                                }
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            className="required__clz mt-3"
                            htmlFor="name"
                        >
                            {t("Port number")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames("mon_inp_portNumber", {
                                readOnly__clz: isConsult,
                            })}
                            autocomplete="off"
                            id="portNumber"
                            name="portNumber"
                            placeholder={t("Enter")}
                            type="text"
                            onChange={(e: any) => {
                                data.setPortNumber(e.target.value);
                                setIsEdited(true);
                                setDataIsUpdated(true);
                                data.setInputPort(false);
                            }}
                            value={data.portNumber}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: `${t(
                                        "Please enter a port number"
                                    )!}`,
                                },
                                pattern: {
                                    value: numberMatchWord,
                                    errorMessage: t("Port number is invalid"),
                                },
                            }}
                        />
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            className="required__clz mt-3"
                            htmlFor="name"
                        >
                            {t("User")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames("mon_inp_user", {
                                input__clz: data.inputUser,
                                readOnly__clz: isConsult,
                            })}
                            autocomplete="off"
                            id="user"
                            name="user"
                            placeholder={t("Enter")}
                            type="text"
                            onChange={(e: any) => {
                                data.setUser(e.target.value);
                                setIsEdited(true);
                                setDataIsUpdated(true);
                                data.setInputUser(false);
                            }}
                            value={data.user}
                        />
                        {data.inputUser ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a user")}
                            </div>
                        ) : null}
                    </AvGroup>{" "}
                    <Col className="px-0">
                        <StyledLabel
                            className="required__clz mt-3"
                            htmlFor="name"
                        >
                            {t("Password")}
                        </StyledLabel>
                        <div className="new-password-toggle__clz mr-2 ">
                            {!isNewPasswordDisplayed ? (
                                <HidePasswordSvgIcon
                                    onClick={() =>
                                        setIsNewPasswordDisplayed(
                                            (prevState: boolean) => !prevState
                                        )
                                    }
                                />
                            ) : (
                                <ShowPasswordSvgIcon
                                    onClick={() =>
                                        setIsNewPasswordDisplayed(
                                            (prevState: boolean) => !prevState
                                        )
                                    }
                                />
                            )}
                        </div>
                        <StyledTextInput
                            className={classnames("mon_inp_password", {
                                input__clz: data.inputPassword,
                                readOnly__clz: isConsult,
                            })}
                            autocomplete="off"
                            id="password"
                            name="password"
                            placeholder={t("Enter")}
                            type={isNewPasswordDisplayed ? "text" : "password"}
                            onChange={(e: any) => {
                                data.setPassword(e.target.value);
                                setIsEdited(true);
                                setDataIsUpdated(true);
                                data.setInputPassword(false);
                            }}
                            value={data.password}
                        />
                        {data.inputPassword ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter an password")}
                            </div>
                        ) : null}
                    </Col>
                    <AvGroup>
                        <StyledLabel
                            className="required__clz mt-3"
                            htmlFor="name"
                        >
                            {t("POS ID")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames("mon_inp_idPos", {
                                input__clz: data.inputPosId,
                                readOnly__clz: isConsult,
                            })}
                            autocomplete="off"
                            id="posId"
                            name="posId"
                            placeholder={t("Enter")}
                            type="text"
                            onChange={(e: any) => {
                                data.setPosId(e.target.value);
                                setIsEdited(true);
                                setDataIsUpdated(true);
                                data.setInputPosId(false);
                            }}
                            value={data.posId}
                        />
                        {data.inputPosId ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a POS ID")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel className="mt-3" htmlFor="name">
                            {t("Log directory")}
                        </StyledLabel>
                        <StyledTextInput
                            autocomplete="off"
                            id="logDirectory"
                            className={classnames("mon_inp_logDirectory", {
                                readOnly__clz: isConsult,
                            })}
                            name="logDirectory"
                            placeholder={t("Enter")}
                            type="text"
                            onChange={(e: any) => {
                                setIsEdited(true);
                                data.setLogDirectory(e.target.value);
                                setDataIsUpdated(true);
                            }}
                            value={data.logDirectory}
                        />
                    </AvGroup>
                </React.Fragment>
            )}
            {data.mark === "Glory" && (
                <React.Fragment>
                    <AvGroup>
                        <StyledLabel
                            className="required__clz mt-3"
                            htmlFor="name"
                        >
                            {t("IP address")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames("mon_inp_ipAdress", {
                                input__clz:
                                    data.inputIp ||
                                    data.ipExist ||
                                    data.emptyIp,
                                readOnly__clz: isConsult,
                            })}
                            autocomplete="off"
                            id="ipAddress"
                            name="ipAddress"
                            placeholder={t("Enter")}
                            type="text"
                            onChange={(e: any) => {
                                data.setIpAddress(e.target.value);

                                if (
                                    !e.target.value.match(ipMatchWord) &&
                                    e.target.value !== ""
                                ) {
                                    data.setInputIp(true);
                                } else {
                                    data.setInputIp(false);
                                }
                                if (e.target.value === "") {
                                    data.setEmptyIp(false);
                                }

                                data.setIpExist(
                                    checkIpIfExist(
                                        resourceIp,
                                        e.target.value,
                                        editedData?.Id !== undefined
                                            ? editedData?.Id
                                            : "",
                                        editedData?.Id !== undefined
                                            ? editedData?.shopId
                                            : localShopId.length === 1
                                            ? localShopId[0]
                                            : ""
                                    )
                                );

                                setIsEdited(true);
                            }}
                            value={data.ipAddress}
                        />
                        {data.inputIp || data.ipExist || data.emptyIp ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {
                                    t(
                                        data.emptyIp
                                            ? "Please enter a IP address"
                                            : !data.ipAddress.match(ipMatchWord)
                                            ? "IP address is invalid"
                                            : data.ipExist
                                            ? "The ip address is already exists"
                                            : ""
                                    )!
                                }
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            className="required__clz mt-3"
                            htmlFor="name"
                        >
                            {t("Port number")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames("mon_inp_portNumber", {
                                readOnly__clz: isConsult,
                            })}
                            autocomplete="off"
                            id="portNumber"
                            name="portNumber"
                            placeholder={t("Enter")}
                            type="text"
                            onChange={(e: any) => {
                                data.setPortNumber(e.target.value);
                                setDataIsUpdated(true);
                                data.setInputPort(false);
                                setIsEdited(true);
                            }}
                            value={data.portNumber}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: `${t(
                                        "Please enter a port number"
                                    )!}`,
                                },
                                pattern: {
                                    value: numberMatchWord,
                                    errorMessage: t("Port number is invalid"),
                                },
                            }}
                        />
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel className="mt-3" htmlFor="name">
                            {t("Log directory")}
                        </StyledLabel>
                        <StyledTextInput
                            autocomplete="off"
                            id="logDirectory"
                            name="logDirectory"
                            placeholder={t("Enter")}
                            className={classnames("mon_inp_logDirectory", {
                                readOnly__clz: isConsult,
                            })}
                            type="text"
                            onChange={(e: any) => {
                                data.setLogDirectory(e.target.value);
                                setDataIsUpdated(true);
                                setIsEdited(true);
                            }}
                            value={data.logDirectory}
                        />
                    </AvGroup>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
