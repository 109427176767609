import React from "react";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { uid } from "react-uid";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";

import {
    generalConfigStore,
    setIsRegulationModesSkipped,
    setRegulationModesItems,
    setProjectFiles,
    setImageKey,
    setIsPageSkipped,
} from "@store";
import { kioskSettingStore } from "../store";

import { SwitchWrapper } from "@components/Common/SwitchWrapper";
import { FlexboxGrid } from "@components/Common/FlexboxGrid";
import { ResetModalWrapper } from "@components/Common/ResetModalWrapper";
import { CustomErrorBoundary } from "@components/CustomErrorBoundary";
import { DragDropWrapper } from "@pages/GeneralConfig/DragDropWrapper";
import { CardWrapperSpecific } from "@components/Common/CardWrapperSpecific";

export function MeansOfPayment(): JSX.Element {
    const { t } = useTranslation();

    const {
        project: { template, files: images },
        defaultLanguage,
        isResetModalOpened,
        templates,
        initItemsFromGeneralSetting,
    } = useSnapshot(generalConfigStore);
    const { isKioskSetting } = useSnapshot(kioskSettingStore);

    let isRegulationModesSkipped: boolean;
    if ((template.pages.ways.meansOfPayment as PageType) !== undefined) {
        isRegulationModesSkipped = (template.pages.ways
            .meansOfPayment as PageType).skipped as boolean;
    } else {
        isRegulationModesSkipped = (template.pages.elements
            .meansOfPayment as PageType).skipped as boolean;
    }
    const [skipStep, setSkipStep] = React.useState<boolean>(
        isRegulationModesSkipped
    );
    const [items, setItems] = React.useState(
        (template.content.meansOfPayment as ProjectContentItemType).items
    );
    const [
        localIsResetModalOpened,
        setLocalIsResetModalOpened,
    ] = React.useState<boolean>(isResetModalOpened);

    function handleResetOnClickEvent() {
        const initRegulationModes = initItemsFromGeneralSetting.reglements as ProjectMainContentItemType[];
        const initSkipStep = (templates.filter(
            (element) => element.id === template.id
        )[0].pages.elements.meansOfPayment as PageType).skipped;
        setItems(initRegulationModes);
        setSkipStep(initSkipStep as boolean);
        setIsRegulationModesSkipped(initSkipStep as boolean);
        const imagesArray = [...images];
        items
            .map((item) => item.id)
            .forEach((id) => {
                const image = imagesArray.find((element) => element.id === id);

                if (image !== undefined) {
                    const imageIndex = imagesArray.indexOf(image);

                    const localContent = image.content as ImageItemLanguagesType;

                    imagesArray[imageIndex] = {
                        ...image,
                        content: {
                            ...localContent,
                            [defaultLanguage.name]: {
                                ...localContent[defaultLanguage.name],
                                path:
                                    localContent[defaultLanguage.name]
                                        .defaultImage,
                            },
                        } as ImageItemLanguagesType,
                    };
                }
            });

        setProjectFiles(imagesArray);
        setImageKey(uid(imagesArray));
        setRegulationModesItems(initRegulationModes);
    }
    React.useEffect(() => {
        if (items.filter((item) => item.active).length > 1) {
            setIsPageSkipped("meansOfPayment", false);
            setSkipStep(false);
            setIsRegulationModesSkipped(false);
        }
    }, [items]);
    return (
        <CustomErrorBoundary>
            <div
                className="h-100"
                style={{
                    display: "grid",
                    gridTemplateRows: "0.1fr 0.9fr",
                    gap: isKioskSetting && items.length < 4 ? "138px" : "unset",
                }}
            >
                <div>
                    <div className="d-flex justify-content-between">
                        <div
                            className="d-flex align-items-center cursor__clz"
                            style={{ gap: "10px" }}
                        >
                            <div
                                style={{
                                    font:
                                        "normal normal 600 35px/61px Nunito Sans",
                                }}
                            >
                                {t("Choice of means of payment")}
                            </div>
                            <div
                                style={{
                                    font:
                                        "normal normal 600 18px/27px Nunito Sans",
                                }}
                            >
                                ({t("Default language")}:{defaultLanguage.title}
                                )
                            </div>
                            {/* {!isKioskSetting ? (
                                <div className="mb-0 py-2">
                                    <HelpIcon
                                        id="languages-config"
                                        height={25}
                                        width={25}
                                    />
                                    <UncontrolledTooltip
                                        placement="bottom"
                                        target="languages-config"
                                    >
                                        {t(
                                            "You can configure other languages in expert mode"
                                        )}
                                    </UncontrolledTooltip>
                                </div>
                            ) : null} */}
                        </div>
                        {items.filter((item) => item.active).length === 1 ? (
                            <div
                                className="d-flex align-items-center justify-content-start cursor__clz"
                                style={{ gap: "20px" }}
                            >
                                <div
                                    style={{
                                        font:
                                            "normal normal 600 24px/32px Nunito Sans",
                                    }}
                                >
                                    {t(
                                        "Do not offer this step in the customer ways"
                                    )}
                                </div>
                                <SwitchWrapper
                                    onChange={() => {
                                        setSkipStep(!skipStep);
                                        setIsRegulationModesSkipped(
                                            !isRegulationModesSkipped
                                        );
                                    }}
                                    checked={skipStep}
                                />
                            </div>
                        ) : null}
                    </div>
                    {!isKioskSetting ? (
                        <FlexboxGrid
                            alignItemsCentered={true}
                            className="justify-content-between"
                        >
                            <span
                                style={{
                                    font:
                                        "normal normal 300 20px/27px Nunito Sans",
                                    color: "#A0A0A0",
                                }}
                            >
                                {t(
                                    "Drag and drop elements to change the order"
                                )}
                            </span>
                            <StyledIconButton
                                title={t("Reset to default settings")}
                                outline={true}
                                variant="danger"
                                className="m-0 p-0 Cmn_btn_resetModifications"
                                icon="RefreshIcon"
                                style={{ minHeight: "30px" }}
                                onClick={() => {
                                    setLocalIsResetModalOpened(
                                        !localIsResetModalOpened
                                    );
                                }}
                            >
                                <RefreshIcon height={20} width={20} />
                            </StyledIconButton>
                        </FlexboxGrid>
                    ) : null}
                </div>
                {isKioskSetting === true ? (
                    <React.Fragment>
                        <div
                            className="mt-5 d-flex justify-content-center"
                            style={{
                                gap: "40px",
                            }}
                        >
                            {items.slice(0, 3).map((item, index) => {
                                return (
                                    <CardWrapperSpecific
                                        index={index}
                                        item={item}
                                        setItems={setItems}
                                        setConfig={setRegulationModesItems}
                                    />
                                );
                            })}
                        </div>
                        <div
                            className="mt-5 d-flex justify-content-center"
                            style={{
                                gap: "40px",
                            }}
                        >
                            {items.slice(3).map((item, index) => {
                                return (
                                    <CardWrapperSpecific
                                        index={index + 3}
                                        item={item}
                                        setItems={setItems}
                                        setConfig={setRegulationModesItems}
                                    />
                                );
                            })}
                        </div>
                    </React.Fragment>
                ) : (
                    <DragDropWrapper
                        items={items}
                        setItems={setItems}
                        setConfig={setRegulationModesItems}
                    />
                )}
            </div>
            <ResetModalWrapper
                handleResetOnClickEvent={handleResetOnClickEvent}
                localIsResetModalOpened={localIsResetModalOpened}
                setLocalIsResetModalOpened={setLocalIsResetModalOpened}
            />
        </CustomErrorBoundary>
    );
}
