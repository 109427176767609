import React from "react";
import { useTranslation } from "react-i18next";
import {
    RefreshIcon,
    StyledButton,
    StyledIconButton,
} from "@aureskonnect/react-ui";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useSnapshot } from "valtio";

import { generalConfigStore, setItems } from "@store";

import { FlexboxGrid } from "@components/Common/FlexboxGrid";

import { DragList } from "./DragList";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import "./index.css";

export function Ways(): JSX.Element {
    const { t } = useTranslation();
    const { defaultWays } = useSnapshot(generalConfigStore);

    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);

    function handleResetItemsElementsWaysOnClickEvent() {
        setItems(defaultWays);
    }

    function toggle() {
        setIsModalOpened(!isModalOpened);
    }

    return (
        <React.Fragment>
            <div style={{ display: "grid", gridTemplateRows: "45px 700px" }}>
                <FlexboxGrid
                    alignItemsCentered={true}
                    className="mr-2 justify-content-end"
                >
                    <StyledIconButton
                        title={t("Reset to default settings")}
                        outline={true}
                        variant="danger"
                        className="m-0 p-0"
                        icon="RefreshIcon"
                        style={{ height: "20%" }}
                        onClick={() => {
                            setIsModalOpened(!isModalOpened);
                        }}
                    >
                        <RefreshIcon height={20} width={20} />
                    </StyledIconButton>
                </FlexboxGrid>
                <div className="draggable-element-wrapper__clz">
                    <DragList />
                </div>
            </div>
            <Modal
                isOpen={isModalOpened}
                toggle={toggle}
                backdrop={false}
                fade={false}
                centered
            >
                <ModalHeader toggle={toggle}>{t("Alert")}</ModalHeader>
                <ModalBody>
                    <div>
                        {t(
                            "Resetting the ways will cause you to lose all changes made"
                        )}
                    </div>
                    <FlexboxGrid
                        justifyContentCentered={true}
                        className="pt-4"
                        gap="10px"
                    >
                        <CustomMainColorButton
                            rounded={true}
                            variant="primary"
                            outline
                            onClick={() => setIsModalOpened(false)}
                        >
                            {t("Cancel")}
                        </CustomMainColorButton>
                        <StyledButton
                            rounded={true}
                            variant="danger"
                            onClick={() => {
                                handleResetItemsElementsWaysOnClickEvent();
                                setIsModalOpened(false);
                            }}
                        >
                            {t("Reset")}
                        </StyledButton>
                    </FlexboxGrid>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}
