import React from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { CrossIcon, StyledIconButton } from "@aureskonnect/react-ui";

import { setIsModalOpened } from "@store";

import { SwiperWrapper } from "./SwiperWrapper";
import { ExitButtonWrapper } from "./ExitButtonWrapper";

import "./index.css";

export function FullscreenPreview(): JSX.Element {
    const fullScreen = useFullScreenHandle();

    React.useEffect(() => {
        fullScreen.enter();
        // eslint-disable-next-line
    }, []);

    return (
        <FullScreen
            className="fullscreen-bg-color__clz"
            handle={fullScreen}
            onChange={(state: boolean) => {
                if (!state) {
                    setIsModalOpened(true);
                }
            }}
        >
            <div
                style={{
                    minHeight: "100vh",
                    display: "grid",
                    gridTemplateColumns: "1.9fr 0.1fr",
                }}
            >
                <SwiperWrapper />
                <ExitButtonWrapper>
                    <StyledIconButton
                        icon="CrossIcon"
                        className="escape-fullscreen-mode-button__clz m-2"
                        style={{
                            borderRadius: "1rem",
                        }}
                        onClick={() => {
                            fullScreen.exit();
                        }}
                    >
                        <CrossIcon fill="white" height={30} width={30} />
                    </StyledIconButton>
                </ExitButtonWrapper>
            </div>
        </FullScreen>
    );
}
