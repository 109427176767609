import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Nav, NavItem, TabContent, TabPane, NavLink } from "reactstrap";

import { setActiveTabIndex } from "@store";

import { Design } from "./Design";
import { Model } from "./Model";

export function Summary(): JSX.Element {
    const { t } = useTranslation();

    const tabs: TabsType[] = [
        {
            title: t("Model"),
            content: <Model />,
        },
        {
            title: t("Design"),
            content: <Design />,
        },
    ];

    const [activeTab, setActiveTab] = React.useState<number>(0);

    function toggleNav(tab: number) {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    React.useEffect(() => {
        if (activeTab === 1) {
            setActiveTabIndex(0);
        } else {
            setActiveTabIndex(-1);
        }
    }, [activeTab]);

    return (
        <React.Fragment>
            <div className="mt-3" style={{ height: "590px" }}>
                <div style={{ width: "384px" }}>
                    <Nav tabs className="bg-white">
                        {tabs.map((element, index) => {
                            return (
                                <NavItem
                                    key={index}
                                    className={classNames({
                                        "advanced-nav-item-active__clz":
                                            activeTab === index,
                                    })}
                                    style={{ border: "1px solid #E5E5E5" }}
                                >
                                    <NavLink
                                        className={classNames(
                                            "border-0 sales-methods-nav-link__clz",
                                            {
                                                "active nav-tabs-active-link__clz":
                                                    activeTab === index,
                                            }
                                        )}
                                        onClick={() => {
                                            toggleNav(index);
                                        }}
                                    >
                                        <span className="px-2">
                                            {element.title}
                                        </span>
                                    </NavLink>
                                </NavItem>
                            );
                        })}
                    </Nav>
                </div>
                <TabContent
                    activeTab={activeTab}
                    style={{
                        width: "100%",
                        height: "93%",
                    }}
                    className="bg-white border-0 rounded"
                >
                    {tabs.map((element, index) => {
                        return (
                            <TabPane
                                tabId={index}
                                style={{ height: "100%", width: "100%" }}
                                key={index}
                            >
                                {element.content}
                            </TabPane>
                        );
                    })}
                </TabContent>
            </div>
        </React.Fragment>
    );
}
