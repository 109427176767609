import React from "react";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { StyledIconButton, RefreshIcon } from "@aureskonnect/react-ui";
import { Card } from "reactstrap";

import {
    generalConfigStore,
    setPRM,
    designStore,
    setIsResetOneImageButtonClicked,
    setProjectFiles,
    store,
} from "@store";

import { getImageContentById } from "@helpers/general";

import { Uploader } from "@components/Uploader";

export function PrmWrapper(): JSX.Element {
    const { t } = useTranslation();

    const {
        project: { template, files: images },
    } = useSnapshot(generalConfigStore);
    const { selectedLanguage } = useSnapshot(designStore);
    const { images: imagesGlobal } = useSnapshot(store);

    const [localPrm, setLocalPrm] = React.useState<GeneralDesignPrmType>(
        (template.content.generalDesign as GeneralDesignType).prm
    );

    const data = images.filter((image) => image.id === localPrm.id)[0];
    const templateFiles = imagesGlobal.filter(
        (image: ImageType) => image.id === template.id
    )[0].files;

    const imageContent = getImageContentById(
        localPrm.id,
        images,
        selectedLanguage
    );

    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    function handleUploadImageOnClickEvent(id: string, data: ImageItemType) {
        setLocalPrm(
            (prevState: GeneralDesignPrmType): GeneralDesignPrmType => {
                const newState = { ...prevState };
                if (newState.id === id) {
                    return {
                        ...newState,
                        content: data.content as string,
                        type: data.type as string,
                    };
                }
                setPRM(newState);
                return newState;
            }
        );
    }

    function handleResetImagesOnClickEvent() {
        const imagesArray = [...templateFiles];

        const image = imagesArray.find((element) => element.id === data.id);
        if (image !== undefined) {
            const imageIndex = imagesArray.indexOf(image);
            setLocalPrm(
                (prevState: GeneralDesignPrmType): GeneralDesignPrmType => {
                    const newState = { ...prevState };
                    if (newState.id === data.id) {
                        return {
                            ...newState,
                            content: imagesArray[imageIndex].content as string,
                            type: imagesArray[imageIndex].type as string,
                        };
                    }
                    setPRM(newState);
                    return newState;
                }
            );
        }
        setProjectFiles(imagesArray);
    }

    return (
        <div className="d-flex flex-column cursor__clz" style={{ gap: "10px" }}>
            <Card
                style={{
                    width: "270px",
                    height: "230px",
                    borderRadius: "20px",
                    display: "grid",
                    gridTemplateRows: "0.1fr 0.8fr auto",
                    gap: "10px",
                }}
            >
                <div className="d-flex justify-content-end">
                    <StyledIconButton
                        title={t("Reset to default image")}
                        outline={true}
                        variant="danger"
                        className="m-0 p-0 "
                        icon="RefreshIcon"
                        style={{ height: "20%", width: "20px" }}
                        onClick={() => {
                            handleResetImagesOnClickEvent();
                            setIsResetOneImageButtonClicked(true);
                        }}
                    >
                        <RefreshIcon height={20} width={20} />
                    </StyledIconButton>
                </div>
                <div className="d-flex justify-content-center align-items-end">
                    {(data.type as string).includes("video") ? (
                        <video
                            autoPlay
                            muted
                            loop
                            src={imageSrc}
                            style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "fill",
                                borderRadius: "15px",
                            }}
                        />
                    ) : (
                        <img
                            alt="img"
                            src={imageSrc}
                            style={{
                                width: "100px",
                                height: "100px",
                                borderRadius: "15px",
                            }}
                        />
                    )}
                </div>

                <div
                    className="d-flex justify-content-center align-items-center h-100"
                    onClick={() =>
                        handleUploadImageOnClickEvent(localPrm.id, data)
                    }
                >
                    <StyledIconButton
                        style={{
                            width: "50px",
                            height: "50px",
                            boxShadow: "0px 3px 6px #00000029",
                        }}
                        className="m-0"
                    >
                        <Uploader id={localPrm.id} />
                    </StyledIconButton>
                </div>
            </Card>

            <div className="d-flex justify-content-center align-items-center">
                <span
                    style={{
                        font: "normal normal normal 15px/20px Nunito Sans",
                        color: "black",
                    }}
                >
                    {t(localPrm.name)}
                </span>
            </div>
        </div>
    );
}
