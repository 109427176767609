import React from "react";
import { useSnapshot } from "valtio";
import ReactSwitch from "react-switch";
import { useTranslation } from "react-i18next";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { uid } from "react-uid";

import {
    designStore,
    setIsBackgroundActive,
    setIsLogoActive,
    setIsTopBannerActive,
    computedStore,
    setImageKey,
    setProjectFiles,
    setIsPageBackgroundActive,
    setIsPageLogoActive,
    setIsPageTopBannerActive,
    generalConfigStore,
} from "@store";

import { Uploader } from "@components/Uploader";

import "@pages/GeneralConfig/Design/index.css";

export function TopBannerWrapper(): JSX.Element {
    const { t } = useTranslation();

    const {
        isTopBannerActive,
        isLogoActive,
        isBackgroundActive,
        activeSlideIndex,
        activePageName,
    } = useSnapshot(designStore);
    const { elements } = useSnapshot(computedStore);
    const {
        project: { files: images },
        imageKey,
    } = useSnapshot(generalConfigStore);

    const background = (elements[activeSlideIndex] as PageType).background;

    const topBanner = (elements[activeSlideIndex] as PageType).topBanner;

    function handleResetImagesOnClickEvent(id: string) {
        const imagesArray = [...images];
        const image = imagesArray.find((element) => element.id === id);
        if (image !== undefined) {
            const imageIndex = imagesArray.indexOf(image);
            imagesArray[imageIndex] = {
                ...image,
                content: image.defaultImage as string,
            };
        }

        setProjectFiles(imagesArray);
        setImageKey(uid(imagesArray));
    }

    function handleIsBackgroundActiveSwitchOnClickEvent() {
        setIsBackgroundActive(!isBackgroundActive);
        setIsPageBackgroundActive(!isBackgroundActive, activePageName);
    }

    function handleIsLogoActiveSwitchOnClickEvent() {
        setIsLogoActive(!isLogoActive);
        setIsPageLogoActive(!isLogoActive, activePageName);
        if (!isLogoActive === false) {
            setIsLogoActive(false);
        }
    }

    function handleIsTopBannerActiveSwitchOnClickEvent() {
        setIsTopBannerActive(!isTopBannerActive);
        setIsPageTopBannerActive(!isTopBannerActive, activePageName);
        if (!isTopBannerActive === false) {
            setIsLogoActive(false);
            setIsPageLogoActive(false, activePageName);
        }
    }

    return (
        <div
            className="mt-4 rounded border"
            style={{ width: "93%", height: "96%", borderColor: "#CECECE" }}
        >
            <div
                className="text-left px-4 d-flex align-items-center cursor__clz"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                {t("General")}
            </div>
            <div
                className="p-4 d-flex flex-column justify-content-between"
                style={{ gap: "20px" }}
            >
                <div
                    className="align-items-center"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "242px 241px auto",
                    }}
                >
                    <label>{t("General background")}</label>
                    <div
                        className="d-flex flex-row align-items-center justify-content-start"
                        style={{ gap: "10px" }}
                    >
                        <StyledIconButton
                            title={t("Reset")}
                            className="m-0"
                            icon="RefreshIcon"
                            onClick={() => {
                                handleResetImagesOnClickEvent(
                                    background.id as string
                                );
                            }}
                            disabled={
                                isBackgroundActive === true ? false : true
                            }
                        >
                            <RefreshIcon height={15} width={15} />
                        </StyledIconButton>
                        <Uploader
                            key={imageKey}
                            uploadedFileTitle={background.content as string}
                            id={background.id}
                            disabled={
                                isBackgroundActive === true ? false : true
                            }
                        />
                    </div>
                    <div className="d-flex justify-content-end">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isBackgroundActive}
                            onChange={() => {
                                handleIsBackgroundActiveSwitchOnClickEvent();
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                </div>
                <div
                    className="align-items-center"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "242px 241px auto",
                    }}
                >
                    <label>{t("Banner")}</label>
                    <div
                        className="d-flex flex-row align-items-center justify-content-start"
                        style={{ gap: "10px" }}
                    >
                        <StyledIconButton
                            title={t("Reset")}
                            className="m-0"
                            icon="RefreshIcon"
                            onClick={() => {
                                handleResetImagesOnClickEvent(topBanner.id);
                            }}
                            disabled={isTopBannerActive === true ? false : true}
                        >
                            <RefreshIcon height={15} width={15} />
                        </StyledIconButton>
                        <Uploader
                            key={imageKey}
                            uploadedFileTitle={topBanner.background.content}
                            id={topBanner.id}
                            disabled={isTopBannerActive === true ? false : true}
                        />
                    </div>
                    <div className="d-flex justify-content-end">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isTopBannerActive}
                            onChange={() => {
                                handleIsTopBannerActiveSwitchOnClickEvent();
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center cursor__clz">
                    <label>{t("Logo")}</label>
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isLogoActive}
                        onChange={() => {
                            handleIsLogoActiveSwitchOnClickEvent();
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                        disabled={isTopBannerActive === true ? false : true}
                    />
                </div>
            </div>
        </div>
    );
}
