import React from "react";
import { useSnapshot } from "valtio";

import {
    generalConfigStore,
    setStandbyScreenFile,
    setStandbyStateScreenItems,
} from "@store";

import { StandbyStateScreenTable } from "./StandbyStateScreenTable";

export function StandbyStateScreen(): JSX.Element {
    const {
        project: {
            template: {
                content: { standbyStateScreen },
            },
        },
    } = useSnapshot(generalConfigStore);

    React.useEffect(() => {
        setStandbyStateScreenItems(
            (standbyStateScreen as StandbyStateScreenItemsType)
                .items as StandbyScreenItemType[]
        );
        setStandbyScreenFile(
            (standbyStateScreen as StandbyStateScreenItemsType)
                .items as StandbyScreenItemType[]
        );

        // eslint-disable-next-line
    }, []);

    return <StandbyStateScreenTable />;
}
