import React from "react";
import { RefreshIcon, StyledIconButton } from "@aureskonnect/react-ui";
import { Card } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { uid } from "react-uid";

import {
    generalConfigStore,
    setDesignButtonsItems,
    setProjectFiles,
    setIsResetOneImageButtonClicked,
} from "@store";

import { getImageContentById } from "@helpers/general";

import { Uploader } from "@components/Uploader";

type PropsType = {
    item: ActionsButtonsDesignItemType;
    index: number;
    setItems: React.Dispatch<
        React.SetStateAction<ActionsButtonsDesignItemType[]>
    >;
};

export function ActionButtonItemWrapper({
    item,
    index,
    setItems,
}: PropsType): JSX.Element {
    const { t } = useTranslation();

    const {
        selectedActionsButtonsLanguage,
        project: { files: images },
        imageKey,
        isResetOneImageButtonClicked,
    } = useSnapshot(generalConfigStore);

    const [cardImageKey, setCardImageKey] = React.useState<string>("");

    const imageContent = getImageContentById(
        item.id,
        images,
        selectedActionsButtonsLanguage
    );

    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    function handleUploadImageOnClickEvent(id: string, image: string) {
        setItems((prevState: ActionsButtonsDesignItemType[]) => {
            let newState = [...prevState];

            newState = newState.map((item: ActionsButtonsDesignItemType) => {
                if (item.id === id) {
                    return {
                        ...item,
                        languages: {
                            ...item.languages,
                            [selectedActionsButtonsLanguage]: {
                                ...item.languages[
                                    selectedActionsButtonsLanguage
                                ],
                                path: image,
                            },
                        },
                    };
                }

                return { ...item };
            });
            setDesignButtonsItems(newState);
            return newState;
        });
    }
    function handleResetImagesOnClickEvent(id: string) {
        const imagesArray = [...images];

        const image = imagesArray.find((element) => element.id === id);
        if (image !== undefined) {
            const imageIndex = imagesArray.indexOf(image);

            const localContent = image.content as ImageItemLanguagesType;
            imagesArray[imageIndex] = {
                ...image,
                content: {
                    ...localContent,
                    [selectedActionsButtonsLanguage as string]: {
                        ...localContent[
                            selectedActionsButtonsLanguage as string
                        ],
                        path: localContent[
                            selectedActionsButtonsLanguage as string
                        ].defaultImage as string,
                    },
                } as ImageItemLanguagesType,
            };
        }

        setProjectFiles(imagesArray);
        setCardImageKey(uid(imagesArray));
    }

    return (
        <div
            className="d-flex flex-column justify-content-center cursor__clz"
            key={index}
            style={{ gap: "10px" }}
        >
            <div
                className="d-flex align-items-center text-muted"
                style={{
                    gap: "10px",
                }}
            >
                {t(item.name)}
            </div>

            <Card
                style={{
                    width: "240px",
                    height: "214px",
                    borderRadius: "20px",
                    display: "grid",
                    gridTemplateRows: ["Yes", "No", "Alert"].includes(item.name)
                        ? "0.2fr 0.4fr 0.4fr"
                        : "01fr 0.8fr 0.1fr",
                    gap: "21px",
                }}
            >
                <div className="d-flex justify-content-end">
                    <StyledIconButton
                        title={t("Reset to default image")}
                        outline={true}
                        variant="danger"
                        className="m-0 p-0"
                        icon="RefreshIcon"
                        style={{ height: "20%", width: "20px" }}
                        onClick={() => {
                            handleResetImagesOnClickEvent(item.id);
                            setIsResetOneImageButtonClicked(true);
                        }}
                    >
                        <RefreshIcon height={20} width={20} style={{}} />
                    </StyledIconButton>
                </div>
                <div className="d-flex justify-content-center align-items-end">
                    <img
                        alt="img"
                        src={imageSrc}
                        style={{
                            width: ["Yes", "No", "Alert"].includes(item.name)
                                ? "initial"
                                : "210px",
                            height: "63px",
                        }}
                    />
                </div>

                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "58px" }}
                    onClick={() =>
                        handleUploadImageOnClickEvent(item.id, imageSrc)
                    }
                >
                    <StyledIconButton
                        style={{
                            width: "50px",
                            height: "50px",
                            boxShadow: "0px 3px 6px #00000029",
                        }}
                    >
                        <Uploader
                            key={
                                isResetOneImageButtonClicked === false
                                    ? imageKey
                                    : cardImageKey
                            }
                            id={item.id}
                            activeLanguage={selectedActionsButtonsLanguage}
                        />
                    </StyledIconButton>
                </div>
            </Card>
        </div>
    );
}
