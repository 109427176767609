import React from "react";

import "./index.css";
type PropsType = {
    globalColor: ColorType;
    width: number;
    height: number;
    onClick?: Function;
};

export function RemoveIcon(props: PropsType): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 48 48"
        >
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="0.5"
                    x2="0.5"
                    y2="1"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stop-color={props.globalColor} />
                    <stop offset="1" stop-color={props.globalColor} />
                </linearGradient>
                <filter
                    id="Ellipse_1260"
                    x="0"
                    y="0"
                    width="48"
                    height="48"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood flood-opacity="0.243" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g
                id="Groupe_30557"
                data-name="Groupe 30557"
                transform="translate(8.735 5.723)"
            >
                <g
                    id="Groupe_30556"
                    data-name="Groupe 30556"
                    transform="translate(0 0)"
                >
                    <g
                        id="Groupe_30555"
                        data-name="Groupe 30555"
                        transform="translate(0 0)"
                    >
                        <g
                            transform="matrix(1, 0, 0, 1, -8.73, -5.72)"
                            filter="url(#Ellipse_1260)"
                        >
                            <circle
                                id="Ellipse_1260-2"
                                data-name="Ellipse 1260"
                                cx="15"
                                cy="15"
                                r="15"
                                transform="translate(9 6)"
                                fill="url(#linear-gradient)"
                            />
                        </g>
                        <g
                            id="Groupe_30554"
                            data-name="Groupe 30554"
                            transform="translate(1.001 1)"
                        >
                            <circle
                                id="Ellipse_1261"
                                data-name="Ellipse 1261"
                                cx="14"
                                cy="14"
                                r="14"
                                transform="translate(0.265 0.276)"
                                fill="#fff"
                            />
                            <g
                                id="Icon_feather-plus"
                                data-name="Icon feather-plus"
                                transform="translate(11.352 14.363)"
                            >
                                <path
                                    id="Tracé_21818"
                                    data-name="Tracé 21818"
                                    d="M7.5,18h6.832"
                                    transform="translate(-7.5 -18)"
                                    fill="none"
                                    stroke={props.globalColor}
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
