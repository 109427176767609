import { store } from ".";

export function setMainColor(mainColor: string): void {
    store.mainColor = mainColor;
}

export function setSecondaryColor(secondaryColor: string): void {
    store.secondaryColor = secondaryColor;
}
export function setCollapsed(collapsed: boolean): void {
    store.collapsed = collapsed;
}

export function setIsEdited(isEdited: boolean): any {
    store.isEdited = isEdited;
}

export function setIsActionsClicked(isActionsClicked: boolean): any {
    store.isActionsClicked = isActionsClicked;
}

export function setValidationAction(validationAction: any): any {
    store.validationAction = validationAction;
}

export function setActions(actions: any): any {
    store.actions = actions;
}
