import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useSnapshot } from "valtio";

import {
    setActiveSubTab,
    setIsWaysTabActive,
    generalConfigStore,
    computedStore,
    designStore,
    setIsAllergenActive,
    setIsCustomerAccountActive,
    setIsOrderTakingTopBannerActive,
    setIsOrderTakingTagsActive,
    setIsOrderTakingCategoryTitleActive,
    setIsOrderTakingProductInformationActive,
    setIsCaloryActive,
    setIsPRMActive,
    setIsHelpActive,
    setSelectedSaleModeValue,
    setIsConfigured,
    setUserAccountOrderTakingMessageItems,
    setIsUserAccountOrderTakingActive,
    setDefaultActiveStateScreenItems,
    setDefaultStandbyStateScreenItems,
    setIsStateChanged,
    setConfigOptionsCustomerAccount,
    setIsOrderTakingSelectedCategoryTitleActive,
    setIsOrderTakingPromoCodeActive,
    setIsCommentArticleActive,
    setIsCommentRecoveryActive,
    setIsFreeCommentActive,
} from "@store";
import kioskSettingStore from "@pages/GeneralConfig/store";

import { AdvertisingScreen } from "@pages/GeneralConfig/AdvertisingScreen";
import { CustomerAccount } from "@pages/GeneralConfig/CustomerAccount";
import { Design } from "@pages/GeneralConfig/Design";
import { InformationModes } from "@pages/GeneralConfig/InformationModes";
import { Languages } from "@pages/GeneralConfig/Languages";
import { Options } from "@pages/GeneralConfig/Options";
import { OrderTaking } from "@pages/GeneralConfig/OrderTaking";
import { MeansOfPayment } from "@pages/GeneralConfig/MeansOfPayment";
import { SalesMethods } from "@pages/GeneralConfig/SalesMethods";
import { TemplateSelection } from "@pages/GeneralConfig/TemplateSelection";
import { VariousTabs } from "@pages/GeneralConfig/Various/VariousTabs";
import { Ways } from "@pages/GeneralConfig/Ways";
import { HoursOfUse } from "@pages/GeneralConfig/HoursOfUse";
import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";

import "./index.css";

type TabsMenuType = {
    title: any;
    content: React.ReactNode;
    customClassName: string;
    subMenu?: {
        title: string;
        content: React.ReactNode;
        customClassName: string;
    }[];
};

export function ModalContentWrapper(): JSX.Element {
    const { t } = useTranslation();
    let tabs: TabsMenuType[] = React.useMemo(() => [], []);

    const {
        isWaysTabActive,
        chosenTemplate,
        activeSubTab,
        isCustomerAccountActive,
        project: { template },
        templates,
        isInformationModesEmpty,
        isConfigured,
    } = useSnapshot(generalConfigStore);
    const { isKioskSetting } = useSnapshot(kioskSettingStore);
    const { elements } = useSnapshot(computedStore);
    const { activeSlideIndex } = useSnapshot(designStore);

    const [activeTab, setActiveTab] = React.useState<number>(0);
    const [isSubMenuCollapsed, setIsSubMenuCollapsed] = React.useState<boolean>(
        isKioskSetting ? true : false
    );

    if (isConfigured === true) {
        tabs = !isKioskSetting
            ? [
                  {
                      title: t("Template Selection"),
                      content: <TemplateSelection />,
                      customClassName: "pme_txt_menuTemplateChoice",
                  },
                  {
                      title: t("Configuration"),
                      content: "",
                      customClassName: "pme_txt_menuConfiguration",
                      subMenu: [
                          {
                              title: t("Options"),
                              content: <Options />,
                              customClassName: "pme_txt_menuOptions",
                          },
                          {
                              title: t("Advertising screen"),
                              content: <AdvertisingScreen />,
                              customClassName: "pme_txt_menuAdversitingScreen",
                          },
                          {
                              title: t("Sale Mode"),
                              content: <SalesMethods />,
                              customClassName: "pme_txt_menuSaleMode",
                          },
                          {
                              title: t("Means of payment"),
                              content: <MeansOfPayment />,
                              customClassName: "pme_txt_menuPaymentMethod",
                          },
                          {
                              title: t("Information modes"),
                              content: <InformationModes />,
                              customClassName: "pme_txt_menuInformationMode",
                          },
                          {
                              title: t("Customer account"),
                              content: <CustomerAccount />,
                              customClassName: "pme_txt_menuCustomerAccount",
                          },
                          {
                              title: t("Order taking"),
                              content: <OrderTaking />,
                              customClassName: "pme_txt_menuOrderTaking",
                          },
                          {
                              title: t("Languages"),
                              content: <Languages />,
                              customClassName: "pme_txt_menuLanguages",
                          },
                          {
                              title: t("Various"),
                              content: <VariousTabs />,
                              customClassName: "pme_txt_menuDrivers",
                          },
                      ],
                  },
                  {
                      title: t("Ways"),
                      content: <Ways />,
                      customClassName: "pme_txt_menuWays",
                  },
                  {
                      title: t("Design"),
                      content: <Design />,
                      customClassName: "pme_txt_menuDesign",
                  },
              ]
            : [
                  {
                      title: t("Configuration"),
                      content: "",
                      customClassName: "",
                      subMenu: [
                          {
                              title: t("Options"),
                              content: <Options />,
                              customClassName: "",
                          },
                          {
                              title: t("Advertising screen"),
                              content: <AdvertisingScreen />,
                              customClassName: "",
                          },
                          {
                              title: t("Sale Mode"),
                              content: <SalesMethods />,
                              customClassName: "",
                          },
                          {
                              title: t("Means of payment"),
                              content: <MeansOfPayment />,
                              customClassName: "",
                          },
                          {
                              title: t("Information modes"),
                              content: <InformationModes />,
                              customClassName: "",
                          },
                          {
                              title: t("Customer account"),
                              content: <CustomerAccount />,
                              customClassName: "",
                          },
                      ],
                  },
                  {
                      title: (
                          <span>
                              <TimePastSvgIcon
                                  height={15}
                                  width={15}
                                  fill="white"
                                  style={{
                                      marginRight: "10px",
                                      marginBottom: "3px",
                                  }}
                              />
                              {t("Hours of use")}
                          </span>
                      ),
                      content: <HoursOfUse />,
                      customClassName: "",
                  },
              ];
    } else {
        tabs = [
            {
                title: t("Template Selection"),
                content: <TemplateSelection />,
                customClassName: "pme_txt_menuTemplateChoice",
            },
            {
                title: t("Configuration"),
                content: "",
                customClassName: "pme_txt_menuConfiguration",
            },
            {
                title: t("Ways"),
                content: "",
                customClassName: "pme_txt_menuWays",
            },
            {
                title: t("Design"),
                content: "",
                customClassName: "pme_txt_menuDesign",
            },
        ];
    }

    function toggleNav(tab: number) {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
        if (isSubMenuCollapsed === true) {
            setIsSubMenuCollapsed(false);
        }
    }

    function toggleSubNav(tab: number) {
        if (activeSubTab !== tab) {
            setActiveSubTab(tab);
        }
    }

    React.useEffect(() => {
        if (template.id !== "") {
            setIsWaysTabActive(
                tabs.findIndex((item) => {
                    return item.title === t("Ways");
                }) === activeTab
            );
            setIsConfigured(true);
        }
    }, [tabs, activeTab, template.id, t]);

    React.useEffect(() => {
        if (template.id !== "") {
            let templateSelected = templates.filter(
                (defaultTemplate: any) => defaultTemplate.id === template.id
            )[0];

            if (
                (templateSelected.pages.elements
                    .standbyScreen as StandbyScreenPageType) !== undefined
            ) {
                setDefaultActiveStateScreenItems(
                    (templateSelected.pages.elements
                        .standbyScreen as StandbyScreenPageType).items
                );
            } else {
                setDefaultActiveStateScreenItems(
                    (templateSelected.pages.ways
                        .standbyScreen as StandbyScreenPageType).items
                );
            }

            setDefaultStandbyStateScreenItems(
                (templateSelected.content
                    .standbyStateScreen as StandbyStateScreenItemsType)
                    .items as StandbyScreenItemType[]
            );
        }
        // eslint-disable-next-line
    }, [template.id]);

    React.useEffect(() => {
        if (template.id !== "") {
            if (
                elements.length > 0 &&
                elements[activeSlideIndex] !== undefined
            ) {
                setIsAllergenActive(
                    (template.content.options as OptionsType).items.filter(
                        (item) => item.title === "Allergen"
                    )[0].active
                );
                setIsCaloryActive(
                    (template.content.options as OptionsType).items.filter(
                        (item) => item.title === "Calorie"
                    )[0].active
                );
                setIsPRMActive(
                    (template.content.options as OptionsType).items.filter(
                        (item) => item.title === "PRM/Child"
                    )[0].active
                );
                setIsHelpActive(
                    (template.content.options as OptionsType).items.filter(
                        (item) => item.title === "Ask for help"
                    )[0].active
                );

                const selectedSaleMethodDeliveryExist = (template.content
                    .salesMethods as ProjectContentItemType).items.filter(
                    (sale: any) => sale.name === "Delivery"
                )[0];

                if (
                    selectedSaleMethodDeliveryExist !== undefined &&
                    selectedSaleMethodDeliveryExist.active === true
                ) {
                    setIsCustomerAccountActive(true);

                    setIsStateChanged(true);
                    setConfigOptionsCustomerAccount(true);
                } else {
                    setIsCustomerAccountActive(
                        (template.content.options as OptionsType).items.filter(
                            (item) => item.title === "Customer account"
                        )[0].active
                    );
                }

                setIsOrderTakingTopBannerActive(
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .topBanner.active
                );
                setIsOrderTakingTagsActive(
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .orderTakingProduct.tags
                );
                setIsOrderTakingSelectedCategoryTitleActive(
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .orderTakingProduct.selectedCategoryTitle
                );
                setIsOrderTakingPromoCodeActive(
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .summary.promoCodeActive
                );
                setIsOrderTakingCategoryTitleActive(
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .orderTakingProduct.categoryTitle
                );
                setIsOrderTakingProductInformationActive(
                    (template.pages.ways["orderTaking"] as OrderTakingPageType)
                        .orderTakingProduct.productAccessIcon.active
                );

                setSelectedSaleModeValue(
                    (template.content
                        .salesMethods as ProjectContentItemType).items.filter(
                        (saleMethod) => {
                            return (
                                saleMethod.name !== "Retrait C&C" &&
                                saleMethod.active === true
                            );
                        }
                    )[0].name
                );
                if (
                    (template.content.options as OptionsType).items.filter(
                        (item) => item.title === "Customer account"
                    )[0].active === false
                ) {
                    setIsUserAccountOrderTakingActive(false);
                } else {
                    setUserAccountOrderTakingMessageItems(
                        ((template.pages.ways[
                            "orderTaking"
                        ] as OrderTakingPageType).header
                            .userAccount as UserAccountType).languages
                    );
                }
                 setIsCommentArticleActive(
                     (template.pages.ways["orderTaking"] as OrderTakingPageType)
                         .orderTakingProduct.isCommentArticleActive
                 );
                 setIsFreeCommentActive(
                     (template.pages.ways["orderTaking"] as OrderTakingPageType)
                         .orderTakingProduct.isFreeCommentActive
                 );
                 setIsCommentRecoveryActive(
                     (template.pages.ways["orderTaking"] as OrderTakingPageType)
                         .orderTakingProduct.isCommentRecoveryActive
                 );
            }
        }
    }, [
        activeSlideIndex,
        elements,
        template.content.options,
        template.content.salesMethods,
        template.id,
        template.pages.ways,
        isKioskSetting,
        template.pages.elements.standbyScreen,
        template.content.standbyStateScreen,
    ]);

    return (
        <div
            className="d-grid__clz h-100"
            style={{ gridTemplateColumns: "220px 1fr" }}
        >
            <Nav
                tabs
                className="d-flex flex-column"
                style={{ backgroundColor: "#323333" }}
            >
                {tabs.map((element, index) => {
                    return (
                        <NavItem
                            key={index}
                            className={classNames(
                                "pb-1",
                                element.customClassName
                            )}
                        >
                            <NavLink
                                disabled={chosenTemplate.id === ""}
                                className={classNames(
                                    "border-0 menu-wrapper-nav-link__clz",
                                    {
                                        "active menu-wrapper-items-nav-link-active__clz":
                                            activeTab === index,
                                    }
                                )}
                                onClick={() => {
                                    toggleNav(index);

                                    if (element.title === t("Configuration")) {
                                        setIsSubMenuCollapsed(
                                            !isSubMenuCollapsed
                                        );
                                    }
                                }}
                            >
                                <span className="px-2">{element.title}</span>
                            </NavLink>
                            {element.title === t("Configuration") &&
                            isSubMenuCollapsed
                                ? element.subMenu?.map(
                                      (
                                          item: {
                                              title: string;
                                              content: React.ReactNode;
                                              customClassName: string;
                                          },
                                          subMenuIndex: number
                                      ): JSX.Element => (
                                          <NavLink
                                              disabled={
                                                  (item.title ===
                                                      t("Customer account") &&
                                                      !isCustomerAccountActive) ||
                                                  (item.title ===
                                                      t("Information modes") &&
                                                      isInformationModesEmpty)
                                              }
                                              key={subMenuIndex}
                                              className={classNames(
                                                  "border-0 text-nowrap pl-5 menu-wrapper-nav-link__clz",

                                                  {
                                                      "active sub-menu-wrapper-items-nav-link-active__clz":
                                                          activeSubTab ===
                                                          subMenuIndex,
                                                  },

                                                  item.customClassName
                                              )}
                                              onClick={() => {
                                                  toggleSubNav(subMenuIndex);
                                              }}
                                          >
                                              <span>{item.title}</span>
                                          </NavLink>
                                      )
                                  )
                                : null}
                        </NavItem>
                    );
                })}
            </Nav>

            <TabContent
                activeTab={activeTab}
                className={classNames({
                    "px-5": !isWaysTabActive && activeTab !== 3,
                })}
                style={{
                    overflowY: isWaysTabActive ? "hidden" : "auto",
                }}
            >
                {tabs.map((element, index) => {
                    return element.subMenu !== undefined &&
                        isSubMenuCollapsed ? (
                        element.subMenu?.map(({ content }, subMenuIndex) => {
                            return (
                                <TabPane
                                    tabId={index}
                                    className={classNames({
                                        "d-none": subMenuIndex !== activeSubTab,
                                    })}
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                        overflow: "hidden",
                                    }}
                                    key={subMenuIndex}
                                >
                                    {content}
                                </TabPane>
                            );
                        })
                    ) : (
                        <TabPane
                            tabId={index}
                            key={index}
                            className={classNames("h-100", {
                                "design-active-tab__clz": activeTab === 3,
                            })}
                        >
                            {element.content}
                        </TabPane>
                    );
                })}
            </TabContent>
        </div>
    );
}
