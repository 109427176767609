import React from "react";
import { StyledH2 } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import NumInput from "react-numeric-input";
import classNames from "classnames";

import { setIsValidateCashFundForum } from "./store";

import "./index.css";

type CashFundAlert = {
    alertForm: string;
    isControleActive: boolean;
    totalPieces: number;
    TotalPieces: Function;
    pieces: any;
    setPieces: Function;
    setPieceOrigin: Function;
};
export function CashFundCoins({
    alertForm,
    isControleActive,
    totalPieces,
    TotalPieces,
    pieces,
    setPieces,
    setPieceOrigin,
}: CashFundAlert) {
    const { t } = useTranslation();
    const handleAmountPiecesChange = (event: any, key: any) => {
        let newPieces = structuredClone(pieces);
        newPieces[key].quantiteretire = event;
        if (
            event >
                (alertForm === t("By number")
                    ? newPieces[key].quantitedispo
                    : 100) ||
            event < 0
        ) {
            newPieces[key].quantiteretire = 0;
        }
        setPieces(newPieces);
    };

    React.useEffect(() => {
        TotalPieces();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalPieces]);

    React.useEffect(() => {
        setPieceOrigin(pieces);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pieces]);

    return (
        <React.Fragment>
            <Col xl="6">
                <div>
                    <StyledH2 className="m-3">{t("Pieces")}</StyledH2>
                    <fieldset
                        className="border px-2 mx-3"
                        style={{ height: "480px" }}
                    >
                        <Row>
                            <Col
                                xl="4"
                                className="my-2 d-flex align-items-center justify-content-center justif-content-center"
                            >
                                <h5
                                    style={{
                                        color: "black",
                                        marginLeft: "30px",
                                    }}
                                >
                                    {t("Denomination")}
                                </h5>
                            </Col>
                            <Col
                                xl="4"
                                className="my-2 d-flex align-items-center justify-content-center justif-content-center"
                            >
                                <h5
                                    style={{
                                        color: "black",
                                        marginLeft: "14px",
                                    }}
                                >
                                    {t("Amount to leave")}
                                </h5>
                            </Col>
                            <Col
                                xl="4"
                                className="my-2 d-flex align-items-center justify-content-center justif-content-center"
                            >
                                <h5 style={{ color: "black" }}>
                                    {t("Maximum capacity")}
                                </h5>
                            </Col>
                        </Row>
                        <Row>
                            {pieces.map((p: any, key: number) => (
                                <React.Fragment>
                                    <Col
                                        xl="4"
                                        style={{
                                            color: "black",
                                            font:
                                                "normal normal normal 20px/32px Segoe UI",
                                        }}
                                        className="d-flex my-1 align-items-center justify-content-center justif-content-center"
                                    >
                                        {p.denomination.toFixed(2)} €{" "}
                                    </Col>
                                    <Col
                                        xl="3"
                                        className="my-1 d-flex align-items-center justify-content-center justif-content-center"
                                    >
                                        <NumInput
                                            autoComplete="off"
                                            className={classNames(
                                                "placeholder__clz",
                                                "numInput__clz"
                                            )}
                                            type="number"
                                            placeholder={
                                                alertForm === t("By number")
                                                    ? "0"
                                                    : "0%"
                                            }
                                            id="example-input"
                                            name="example-input"
                                            onFocus={(e: any) =>
                                                (e.target.placeholder = "")
                                            }
                                            onBlur={(e: any) => {
                                                alertForm === t("By number")
                                                    ? (e.target.placeholder =
                                                          "0")
                                                    : (e.target.placeholder =
                                                          "0%");
                                            }}
                                            disabled={!isControleActive}
                                            format={(o) =>
                                                alertForm === t("By number")
                                                    ? `${o}`
                                                    : `${o} %`
                                            }
                                            value={
                                                p.quantiteretire !== 0
                                                    ? p.quantiteretire
                                                    : null
                                            }
                                            onChange={(e: any) => {
                                                handleAmountPiecesChange(
                                                    e,
                                                    key
                                                );

                                                if (
                                                    (p.quantiteretire <=
                                                        p.quantitedispo &&
                                                        alertForm ===
                                                            t("By number")) ||
                                                    (p.quantiteretire <= 100 &&
                                                        alertForm ===
                                                            t("By percentage"))
                                                ) {
                                                    setIsValidateCashFundForum(
                                                        true
                                                    );
                                                    TotalPieces();
                                                } else {
                                                    setIsValidateCashFundForum(
                                                        false
                                                    );
                                                }
                                            }}
                                            max={
                                                alertForm === t("By number")
                                                    ? p.quantitedispo
                                                    : 100
                                            }
                                            min={0}
                                        />
                                    </Col>
                                    <Col
                                        xl="1"
                                        className="my-1 d-flex align-items-center justify-content-center justif-content-center"
                                    >
                                        {alertForm === t("By number") ? (
                                            ""
                                        ) : (
                                            <div
                                                style={{
                                                    marginLeft: "9px",
                                                }}
                                            >
                                                (
                                                {(
                                                    ((p.quantiteretire *
                                                        p.quantitedispo) /
                                                        100) *
                                                    p.denomination
                                                ).toFixed(2)}
                                                <span
                                                    style={{
                                                        marginLeft: "2px",
                                                    }}
                                                >
                                                    €
                                                </span>
                                                )
                                            </div>
                                        )}
                                    </Col>
                                    <Col
                                        xl="4"
                                        className=" my-1 d-flex align-items-center justify-content-center justif-content-center"
                                        style={{
                                            color: "#BE0A0A",
                                            font:
                                                "normal normal normal 20px/32px Segoe UI",
                                        }}
                                    >
                                        {p.quantitedispo} €{" "}
                                    </Col>
                                </React.Fragment>
                            ))}
                        </Row>
                    </fieldset>
                </div>
                <fieldset
                    className="border pl-2 mx-3"
                    style={{ height: "40px" }}
                >
                    <h5 style={{ color: "black" }} className="mt-2">
                        {t("Total pieces")}:{" "}
                        {totalPieces > 0 ? totalPieces.toFixed(2) : "0.00"} €
                    </h5>
                </fieldset>
            </Col>
        </React.Fragment>
    );
}
