import React from "react";
import classnames from "classnames";

import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next/";

import { CheckboxIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";
import { mutate } from "swr";
import { toast } from "react-toastify";
import ErrorToast from "@components/Common/ErrorTost";
import {
    storeVatRate,
    setUrlHistory,
    setIsAddVatRateOriginHistoryClicked,
    setListLinks,
    setDataIsUpdatedShops,
    setIsDeleteCheckClick,
    setFnDelete,
} from "../store";
import { store } from "../../../../components/VerticalLayout/store";

export function isExists(array1: any, array2: any) {
    let res: number = -1;
    array1.forEach((el1: any) => {
        if (array2.indexOf(el1) !== -1) {
            res++;
        }
    });
    return res;
}
export default function DropdownActionList({
    selectedRows,
    isModalOpened,
    setIsModalOpened,
    selectedIds,
    userID,
    franchiseID,
    typeDisplayShop,
    setTypeDisplayShop,
}: any) {
    const { t } = useTranslation();
    const { oneShop, operatorID } = useSnapshot(store);
    const {
        dataFranchise,
        isAddVatRateOriginHistoryClicked,
        ListNumberBoutique,
        modify,
    } = useSnapshot(storeVatRate);

    async function archiveVatRateAssocMulti(listLinks: any, ids: any) {
        let message =
            typeDisplayShop === "notArchived"
                ? t("The vat rate is successfully archived")
                : t("The vat rate is successfully restored");
        let OtherSiteArray: any = [];

        let select: any;

        if (typeDisplayShop === "archived") {
            select = true;
            if (dataFranchise.length > 0) {
                dataFranchise.forEach((el: any) => {
                    ids.forEach((elm: any) => {
                        let Id = elm;

                        if (el.Id === Id && el.archived === select) {
                            OtherSiteArray.push(Id);
                        }
                    });
                });
            }
        }

        try {
            for (let i = 0; i < listLinks.length; i++) {
                let el = listLinks[i];

                await mutate(
                    el,
                    await fetch(el, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        method: "DELETE",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error while delete selection!");
                            }
                            if (i === listLinks.length - 1) {
                                setDataIsUpdatedShops!(true);
                                toast.success(message, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                });
                            }
                        })
                );
            }
        } catch (e) {}
    }
    return (
        <React.Fragment>
            <div>
                <div className={`cmn_drp_BTmenu ${classnames("dropdown", {})}`}>
                    <VerticalDotsIcon
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        style={{ cursor: "pointer" }}
                        height={25}
                        width={25}
                        fill="black"
                    />
                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <div
                            className="dropdown-item pl-1 cmn_btn_BTlistActivated"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setTypeDisplayShop("notArchived");
                            }}
                        >
                            <span className="ml-2">
                                {t("Activated VAT rate(s)")} (
                                {ListNumberBoutique.nbrNotArchived})
                            </span>
                        </div>
                        <div
                            className="dropdown-item pl-1 cmn_btn_BTlistArchived"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setTypeDisplayShop("archived");
                            }}
                        >
                            <span className="ml-2">
                                {t("Archived vat rate(s)")} (
                                {ListNumberBoutique.nbrArchived})
                            </span>
                        </div>
                        <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                var str = selectedIds.join(",");
                                var shops: string = "";
                                if (selectedRows.length > 0) {
                                    selectedRows.forEach((el: any) => {
                                        shops = shops + "," + el.shopId;
                                    });
                                    shops = shops.substring(1);
                                }

                                oneShop
                                    ? setUrlHistory(
                                          `${process.env.REACT_APP_API_V2_URL}/settings/general/vatRate/origin_history?userId=${userID}&franchiseId=${franchiseID}&typeDisplay=associate&idVatRates=${str}&shopIds=${shops}&operatorId=${operatorID}`
                                      )
                                    : setUrlHistory(
                                          `${process.env.REACT_APP_API_V2_URL}/settings/general/vatRate/origin_history?userId=${userID}&franchiseId=${franchiseID}&typeDisplay=associate&idVatRates=${str}&shopIds=${shops}&operatorId=${operatorID}`
                                      );
                                if (selectedIds.length === 1) {
                                    ErrorToast(
                                        t(
                                            "Attention, it is a multiple action. Please select at least two items"
                                        )
                                    );
                                } else {
                                    setIsAddVatRateOriginHistoryClicked(
                                        !isAddVatRateOriginHistoryClicked
                                    );
                                }
                            }}
                            className={`cmn_btn_BThistory ${classnames(
                                " dropdown-item pl-1 pointer__clz",
                                {
                                    "not-allowed-icon__clz":
                                        selectedIds !== undefined &&
                                        selectedIds.length === 0,
                                }
                            )}`}
                        >
                            <span className="ml-2">{t("Historical")}</span>
                        </div>
                        {modify === true && (
                            <div
                                style={{ cursor: "pointer" }}
                                className={`${classnames(
                                    " dropdown-item pl-1 pointer__clz cmn_btn_BTarchived cmn_btn_BTunarchive",
                                    {
                                        "not-allowed-icon__clz":
                                            (selectedIds !== undefined &&
                                                selectedIds.length === 0) ||
                                            (typeDisplayShop === "archived" &&
                                                // eslint-disable-next-line array-callback-return
                                                dataFranchise.filter(function (
                                                    x: any
                                                ) {
                                                    if (
                                                        x.archived &&
                                                        selectedIds.indexOf(
                                                            x.Id
                                                        ) !== -1 &&
                                                        x
                                                    ) {
                                                        return x;
                                                    }
                                                }).length > 0),
                                    }
                                )}`}
                                onClick={() => {
                                    let listLinks: any = [];
                                    let ids: any = [];

                                    let idsTrace: any = [];
                                    selectedRows.forEach((elx: any) => {
                                        if (
                                            elx.Boutique === undefined ||
                                            elx.Boutique === ""
                                        ) {
                                            if (
                                                idsTrace.indexOf(
                                                    `${elx.Id}${elx.shopId}`
                                                ) === -1
                                            ) {
                                                idsTrace.push(
                                                    `${elx.Id}${elx.shopId}`
                                                );
                                                ids.push(elx.Id);
                                                listLinks.push(
                                                    `${
                                                        process.env
                                                            .REACT_APP_API_V2_URL
                                                    }/settings/general/vatRateArchiveAssocEdit?userId=${userID}&archived=${
                                                        typeDisplayShop ===
                                                        "notArchived"
                                                            ? true
                                                            : false
                                                    }&franchiseId=${franchiseID}&idVatRate=${
                                                        elx.Id
                                                    }&shopId=${
                                                        elx.shopId
                                                    }&operatorId=${operatorID}`
                                                );
                                            }
                                        }
                                    });

                                    setListLinks(listLinks);

                                    if (selectedIds.length <= 1) {
                                        ErrorToast(
                                            t(
                                                "Attention, it is a multiple action. Please select at least two items"
                                            )
                                        );
                                    } else {
                                        if (typeDisplayShop === "notArchived") {
                                            setFnDelete(() => {
                                                archiveVatRateAssocMulti(
                                                    listLinks,
                                                    ids
                                                );
                                            });
                                            setIsDeleteCheckClick(true);
                                        } else {
                                            archiveVatRateAssocMulti(
                                                listLinks,
                                                ids
                                            );
                                        }
                                    }
                                }}
                            >
                                <span className="ml-2">
                                    {typeDisplayShop === "notArchived"
                                        ? t("Archive")
                                        : t("Dearchive")}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
