import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";
import { store } from "../../../../../components/VerticalLayout/store";
import {
    store as hoursStore,
    setFranchiseDataIsUpdated,
    setIsHistoryModalOpened,
    setSelectedHours,
} from "../../store";
import { CustomIcons } from "./CustomIcons";
import HoursSettingModal from "./HoursSettingModal";
import { CustomSideFilter } from "./CustomSideFilter";
import HistoryModal from "../HistoryModal";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

export default function HoursFranchise() {
    const { t } = useTranslation();
    const { oneShop, userID, franchiseID, shopID, operatorID } = useSnapshot(
        store
    );
    const { franchiseDataIsUpdated, isHistoryModalOpened } = useSnapshot(
        hoursStore
    );
    const shopId: string = oneShop ? shopID : "0";
    const [isConfigureHoursClicked, setIsConfigureHoursClicked] = useState(
        false
    );
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [data, setData] = React.useState<any>({});
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const url: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/hour?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=activated&operatorId=${operatorID}`;

    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };
    const [day, setDay] = React.useState<any>({});
    const [daysDesignations, setDaysDesignations] = React.useState<any>("");
    const [isAllHoursEqual, setIsAllHoursEqual] = React.useState<boolean>(
        false
    );
    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 1,
            columnName: t("Day of week"),
            customJsx: (e: any) => (
                <div>{t(e.selectedRow[t("Day of week")])}</div>
            ),
        },
        {
            indexOFColumn: 2,
            columnName: t("State"),
            customJsx: (e: any) => (
                <div>
                    {e.selectedRow[t("State")] === true
                        ? t("Opened")
                        : e.selectedRow[t("State")] === false
                        ? t("Closed")
                        : ""}
                </div>
            ),
        },
        {
            indexOFColumn: 5,
            columnName: t("Status"),
            customJsx: (e: any) => (
                <div>
                    {e.selectedRow[t("Status")] === true
                        ? t("Opened")
                        : e.selectedRow[t("Status")] === false
                        ? t("Fermé")
                        : ""}
                </div>
            ),
        },
        {
            indexOFColumn: 7,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    selectedRowData={e}
                    userData={{
                        userId: userID,
                        shopId: shopId,
                        franchiseId: franchiseID,
                    }}
                    setIsConfigureHoursClicked={setIsConfigureHoursClicked}
                    setDay={setDay}
                    setSelectedRows={setSelectedRows}
                />
            ),
        },
    ];

    React.useEffect(() => {
        if (filterActive) {
            setIsConfigureHoursClicked(false);
        }
    }, [filterActive]);
    React.useEffect(() => {
        let array: any = [];

        selectedRows.forEach((element: any) => {
            array.push({ [element.shopId]: element.Id });
        });

        setSelectedHours(array);
    }, [data, selectedRows]);

    return (
        <React.Fragment>
            <div
                style={{
                    display: "grid",
                    minHeight: oneShop ? "650px" : "600px",
                    gridTemplateColumns: "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    url={url}
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    canExpand={true}
                    canSelect
                    canResize
                    setData={setData}
                    customSelect
                    setSelectedRows={setSelectedRows}
                    showGlobalFilter
                    showFilter
                    actionColumn={ActionColumn}
                    canMovedCheckboxLeftOnExpand
                    customJsxSideFilterButton={
                        <CustomSideFilter
                            selectedRows={selectedRows}
                            setDataIsUpdated={setFranchiseDataIsUpdated}
                            setLocalFilterActive={setLocalFilterActive}
                            setIsModalOpened={setIsConfigureHoursClicked}
                            isModalOpened={isConfigureHoursClicked}
                            day={day}
                            setDay={setDay}
                            daysDesignations={daysDesignations}
                            setDaysDesignations={setDaysDesignations}
                            isAllHoursEqual={isAllHoursEqual}
                            setIsAllHoursEqual={setIsAllHoursEqual}
                        />
                    }
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={
                        setFranchiseDataIsUpdated as
                            | React.Dispatch<
                                  React.SetStateAction<number | boolean>
                              >
                            | undefined
                    }
                    dataIsUpdated={franchiseDataIsUpdated}
                    elevationTable={1}
                    minHeight={oneShop ? "500px" : "450px"}
                    maxHeight={oneShop ? "500px" : "450px"}
                    name="hours"
                />
                {isConfigureHoursClicked ? (
                    <HoursSettingModal
                        selectedRows={selectedRows}
                        setIsModalOpened={setIsConfigureHoursClicked}
                        isModalOpened={isConfigureHoursClicked}
                        day={day}
                        setDay={setDay}
                        daysDesignations={daysDesignations}
                        setDaysDesignations={setDaysDesignations}
                        setIsAllHoursEqual={setIsAllHoursEqual}
                    />
                ) : null}
                {isHistoryModalOpened ? (
                    <HistoryModal
                        ids={selectedRows}
                        setIsModalOpened={setIsHistoryModalOpened}
                        isModalOpened={isHistoryModalOpened}
                    />
                ) : null}
            </div>
        </React.Fragment>
    );
}
