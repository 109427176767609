import React from "react";
import { ListManager } from "react-beautiful-dnd-grid";
import { useSnapshot } from "valtio";

import kioskSettingStore from "../store";

import { ScrollContainer } from "@components/ScrollContainer";
import { CustomerAccountItemCardWrapper } from "./CustomerAccountItemCardWrapper";
import { CardWrapperSpecific } from "@components/Common/CardWrapperSpecific";

export type CustomerAccountDragDropWrapperPropsType = {
    items: CustomerAccountItemType[];
    setItems: React.Dispatch<React.SetStateAction<CustomerAccountItemType[]>>;
    setConfig: (customerAccountOptions: CustomerAccountItemType[]) => void;
};

export function CustomerAccountDragDropWrapper({
    items,
    setItems,
    setConfig,
}: CustomerAccountDragDropWrapperPropsType): JSX.Element {
    const { isKioskSetting } = useSnapshot(kioskSettingStore);

    function handleOnDragEndEvent(
        sourceIndex: number,
        destinationIndex: number
    ) {
        if (destinationIndex === sourceIndex) {
            return;
        }

        const newItems = [...items];
        const [reorderedItem] = newItems.splice(sourceIndex, 1);
        newItems.splice(destinationIndex, 0, reorderedItem);
        setItems(newItems);
        setConfig(newItems);
    }

    return (
        <React.Fragment>
            {items.length > 8 ? (
                <ScrollContainer>
                    {isKioskSetting === true ? (
                        <React.Fragment>
                            <div
                                className="d-flex flex-row justify-content-center align-items-center"
                                style={{ gap: "50px" }}
                            >
                                {items.slice(0, 4).map((item, index) => {
                                    return (
                                        <CardWrapperSpecific
                                            index={index}
                                            item={item}
                                            setItems={setItems}
                                            setConfig={setConfig}
                                        />
                                    );
                                })}
                            </div>
                            <div
                                className="d-flex flex-row justify-content-center align-items-center"
                                style={{ gap: "50px" }}
                            >
                                {items.slice(4).map((item, index) => {
                                    return (
                                        <CardWrapperSpecific
                                            index={index}
                                            item={item}
                                            setItems={setItems}
                                            setConfig={setConfig}
                                        />
                                    );
                                })}
                            </div>
                        </React.Fragment>
                    ) : (
                        <div
                            className="drag-drop-wrapper__clz d-flex flex-column justify-content-center"
                            style={{
                                gap: "25px",
                            }}
                        >
                            <ListManager
                                items={items}
                                direction="horizontal"
                                maxItems={4}
                                render={(item) => {
                                    const index = items.findIndex((object) => {
                                        return object.name === item.name;
                                    });

                                    return (
                                        <CustomerAccountItemCardWrapper
                                            item={item}
                                            index={index}
                                            setItems={setItems}
                                            setConfig={setConfig}
                                        />
                                    );
                                }}
                                onDragEnd={handleOnDragEndEvent}
                            />
                        </div>
                    )}
                </ScrollContainer>
            ) : isKioskSetting === true ? (
                <React.Fragment>
                    <div
                        className="d-flex flex-row justify-content-center align-items-center"
                        style={{ gap: "50px" }}
                    >
                        {items.slice(0, 3).map((item, index) => {
                            return (
                                <CardWrapperSpecific
                                    index={index}
                                    item={item}
                                    setItems={setItems}
                                    setConfig={setConfig}
                                />
                            );
                        })}
                    </div>
                    <div
                        className="d-flex flex-row justify-content-center align-items-center"
                        style={{ gap: "50px" }}
                    >
                        {items.slice(3).map((item, index) => {
                            return (
                                <CardWrapperSpecific
                                    index={index + 3}
                                    item={item}
                                    setItems={setItems}
                                    setConfig={setConfig}
                                />
                            );
                        })}
                    </div>
                </React.Fragment>
            ) : (
                <div
                    className="drag-drop-wrapper__clz d-flex flex-column justify-content-center "
                    style={{ gap: "30px" }}
                >
                    <ListManager
                        items={items}
                        direction="horizontal"
                        maxItems={items.length > 4 && items.length <= 6 ? 3 : 4}
                        render={(item) => {
                            const index = items.findIndex((object) => {
                                return object.name === item.name;
                            });

                            return (
                                <CustomerAccountItemCardWrapper
                                    item={item}
                                    index={index}
                                    setItems={setItems}
                                    setConfig={setConfig}
                                />
                            );
                        }}
                        onDragEnd={handleOnDragEndEvent}
                    />
                </div>
            )}
        </React.Fragment>
    );
}
