import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { inventarCard, inventarCoin } from "./DataJson";

import { CostumTable } from "@components/Common/CostumTable";

import "./index.css";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type PatternType = {
    rowData: any;
    oneShop: boolean;
};

export function InventarPanel({ rowData, oneShop }: PatternType) {
    const { t } = useTranslation();

    function exportInvenarData(Type: string) {
        switch (Type) {
            case "XML":
                // var convert = require("xml-js");
                // var options = { compact: true, ignoreComment: true, spaces: 4 };
                // var result = convert.json2xml(inventarCoin.data, options);
                break;
            case "XLS":
                break;
            case "PDF":
                break;
            case "A4":
                break;
            case "RECEIPT":
                break;
        }
    }
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    function toggleDropdown() {
        setDropdownOpen(!dropdownOpen);
    }
    let ApexChartInventarCoinsState = {
        options: {
            legend: {
                show: false,
            },
            fill: { colors: ["#50a5f1", "#e83e8c"] },
            tooltip: {
                enabled: false,
            },
        },
        series: [20.16, 87.2],
        labels: ["", ""],
    };

    return (
        <React.Fragment>
            <div
                className="float-top 
            "
                style={{ height: "84.5%" }}
            >
                <div
                    className=" "
                    style={{
                        display: "grid",
                        height: "auto",
                        maxHeight: "80%",

                        gridTemplateColumns: "auto",

                        border: 0,
                    }}
                >
                    <fieldset
                        className="border p-1 m-1 justify-content-between d-flex float-top border-0"
                        style={{
                            width: "100%",
                            height: "auto",
                            border: "0px !important",
                        }}
                    >
                        <div
                            style={{
                                minHeight: "auto",
                                width: "50%",
                                marginLeft: "9px",
                            }}
                        >
                            <CostumTable
                                Pagination={false}
                                maxHeight="330px"
                                DefaultPageLength={10}
                                PageNumberArray={[10, 20, 30, 40, 50, 250]}
                                resource={inventarCoin}
                                fontSize={{ th: "17px", td: "16px" }}
                                FooterDiv={
                                    <div>
                                        <b style={{ fontWeight: "900" }}>
                                            {t("Total coins")}
                                        </b>
                                    </div>
                                }
                            />
                        </div>
                        <div
                            className="  d-flex float-center"
                            style={{
                                width: "46%",
                                paddingLeft: "40px !important",
                            }}
                        >
                            <div className="d-flex align-items-center bg-chart__clz">
                                <div className="d-flex flex-column bd-highlight mb-3 ml-3">
                                    <div
                                        className="pt-2 pb-4 pl-2 pr-2  bd-highlight"
                                        style={{
                                            width: "117%",
                                            zIndex: "100",
                                            top: "-3vh",
                                            marginLeft: "8px",
                                            position: "relative",
                                        }}
                                    >
                                        <h6 style={{ fontSize: "17px" }}>
                                            <b> {t("Coins capacity")}</b>
                                        </h6>
                                    </div>
                                    <div className="p-2 bd-highlight">
                                        <span
                                            style={{
                                                width: "10px",
                                                height: "10px",
                                                padding: "0px 10px 0px 8px",
                                                background: "#e83e8c",
                                                border: "1px solid #707070",
                                                opacity: "1",
                                            }}
                                        ></span>
                                        <span
                                            className="pl-3"
                                            style={{
                                                fontSize: "17px",
                                                fontWeight: "500",
                                            }}
                                        >
                                            {t("Used")}
                                        </span>
                                    </div>
                                    <div className="p-2 bd-highlight">
                                        {" "}
                                        <span
                                            style={{
                                                width: "10px",
                                                height: "10px",
                                                padding: "0px 10px 0px 8px",
                                                background: "#50a5f1",
                                                border: "1px solid #707070",
                                                opacity: "1",
                                            }}
                                        ></span>
                                        <span
                                            className="pl-3"
                                            style={{
                                                fontSize: "17px",
                                                fontWeight: "500",
                                            }}
                                        >
                                            {t("Free")}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center bg-chart__clz">
                                <ReactApexChart
                                    options={
                                        ApexChartInventarCoinsState.options
                                    }
                                    series={ApexChartInventarCoinsState.series}
                                    type="donut"
                                    width="330"
                                    style={{ marginRight: "3px" }}
                                />
                            </div>
                            <div
                                className="d-flex align-items-center  "
                                style={{ position: "relative", top: "-15.5vh" }}
                            >
                                <Dropdown
                                    className="mr-1 ml-3"
                                    isOpen={dropdownOpen}
                                    toggle={toggleDropdown}
                                >
                                    <DropdownToggle
                                        style={{
                                            border: "1px solid black",
                                            paddingLeft: "50px",
                                            paddingRight: "50px",
                                        }}
                                        color="white"
                                        caret
                                    >
                                        {t("Actions")}
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                                exportInvenarData("XLS")
                                            }
                                        >
                                            {t(" Export XLS")}
                                        </DropdownItem>
                                        <DropdownItem
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                                exportInvenarData("PDF")
                                            }
                                        >
                                            {t("Export PDF")}
                                        </DropdownItem>
                                        <DropdownItem
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                                exportInvenarData("XML")
                                            }
                                        >
                                            {t("Export XML")}
                                        </DropdownItem>
                                        <DropdownItem
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                                exportInvenarData("A4")
                                            }
                                        >
                                            {t("Print A4")}
                                        </DropdownItem>
                                        <DropdownItem
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                                exportInvenarData("RECEIPT")
                                            }
                                        >
                                            {t("Print receipt")}
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset
                        className="border p-1 m-1 justify-content-between d-flex float-top border-0"
                        style={{
                            width: "100%",
                            height: "auto",
                            position: "relative",
                            top: "-5px",
                        }}
                    >
                        <div
                            style={{
                                minHeight: "auto",
                                width: "50%",
                                marginLeft: "9px",
                            }}
                        >
                            <CostumTable
                                maxHeight="280px"
                                DefaultPageLength={10}
                                PageNumberArray={[10, 20, 30, 40, 50, 250]}
                                resource={inventarCard}
                                Pagination={false}
                                fontSize={{ th: "17px", td: "16px" }}
                                FooterDiv={
                                    <div>
                                        <b style={{ fontWeight: "900" }}>
                                            {t("Total cards")}
                                        </b>
                                    </div>
                                }
                            />
                        </div>
                        <div
                            className="  d-flex float-center"
                            style={{
                                width: "46%",
                                paddingLeft: "40px !important",
                            }}
                        >
                            <div className="d-flex align-items-center bg-chart__clz">
                                <div className="d-flex flex-column bd-highlight mb-3 ml-3">
                                    <div
                                        className="pt-2 pb-4 pl-2 pr-2  bd-highlight"
                                        style={{
                                            width: "117%",
                                            zIndex: "100",
                                            top: "-3vh",
                                            marginLeft: "8px",
                                            position: "relative",
                                        }}
                                    >
                                        <h6 style={{ fontSize: "17px" }}>
                                            <b> {t("Cards capacity")}</b>
                                        </h6>
                                    </div>
                                    <div className="pl-2 pr-2 pt-2  bd-highlight">
                                        <span
                                            style={{
                                                width: "10px",
                                                height: "10px",
                                                padding: "0px 10px 0px 8px",
                                                background: "#e83e8c",
                                                border: "1px solid #707070",
                                                opacity: "1",
                                            }}
                                        ></span>
                                        <span
                                            className="pl-3"
                                            style={{
                                                fontSize: "17px",
                                                fontWeight: "500",
                                            }}
                                        >
                                            {t("Used")}
                                        </span>
                                    </div>
                                    <div className="p-2 bd-highlight">
                                        {" "}
                                        <span
                                            style={{
                                                width: "10px",
                                                height: "10px",
                                                padding: "0px 10px 0px 8px",
                                                background: "#50a5f1",
                                                border: "1px solid #707070",
                                                opacity: "1",
                                            }}
                                        ></span>
                                        <span
                                            className="pl-3"
                                            style={{
                                                fontSize: "17px",
                                                fontWeight: "500",
                                            }}
                                        >
                                            {t("Free")}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center bg-chart__clz">
                                <ReactApexChart
                                    options={
                                        ApexChartInventarCoinsState.options
                                    }
                                    series={ApexChartInventarCoinsState.series}
                                    type="donut"
                                    width="330"
                                    style={{ marginRight: "30px" }}
                                />
                            </div>
                            <div
                                className="d-flex align-items-center  "
                                style={{
                                    position: "relative",
                                    top: "-14vh",
                                    width: "204px",
                                }}
                            ></div>
                        </div>
                    </fieldset>
                    <div className="border-footer__clz" />
                    <div
                        style={{
                            padding: "1px",
                            width: "103%",
                            position: "relative",
                            left: "-17px",
                            top: "-8px",
                        }}
                    >
                        <h5 style={{ paddingLeft: "35px" }}>
                            {t("Total amount")} :{" "}
                            <b style={{ paddingLeft: "50px" }}>222.35</b>
                        </h5>
                    </div>
                </div>
            </div>
            <div className="border-footer-inventar__clz" />
        </React.Fragment>
    );
}
