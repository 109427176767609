import React from "react";
import { StyledH2 } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import classnames from "classnames";
import { useSnapshot } from "valtio";

import { capitalize } from "../../../helpers/general";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";

import { store } from "../../../components/VerticalLayout/store";
import { store as storeData } from "./store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

export default function HistoryModal({
    isModalOpened,
    setIsModalOpened,
    setIsDataUpdated,
}: any) {
    const { t } = useTranslation();

    const { selectedApplicationKds } = useSnapshot(storeData);
    const { franchiseID, userID } = useSnapshot(store);

    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [, setArrayIds] = React.useState<any[]>([]);
    const [data, setData] = React.useState<any>({});
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };

    const url = `${
        process.env.REACT_APP_API_V2_URL
    }/settings/general/application_kds/history?userId=${userID}&franchiseId=${franchiseID}&application=${selectedApplicationKds
        .map((el: any) => JSON.stringify(el))
        .join()}`;
    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 1,
            columnName: t("Actions"),
            customJsx: (e: any) => {
                let actionsArray: any = [];
                e.selectedRow.actions.forEach((el: any) => {
                    if (el.item !== "isActive") {
                        if (el.item === "designation") {
                            actionsArray.push(t("Designation"));
                        } else if (el.item === "designation") {
                            actionsArray.push(t("Désignation"));
                        } else if (el.item === "note") {
                            actionsArray.push(t("Note"));
                        } else if (el.item === "typeOfKDS") {
                            actionsArray.push(t("Type of KDS"));
                        } else if (el.item === "ip") {
                            actionsArray.push(t("Address IP"));
                        }
                    }
                });
                if (e.selectedRow.operation === "modify") {
                    return (
                        <span>
                            {t("Modification")}:{actionsArray.join(",")}
                        </span>
                    );
                } else if (e.selectedRow.operation === "creation") {
                    return <span>{t("Creation")}</span>;
                } else if (e.selectedRow.operation === "Archivage") {
                    return <span>{t("Archiving")}</span>;
                } else if (e.selectedRow.operation === "Désarchivage") {
                    return <span>{t("Unarchiving")}</span>;
                } else if (e.selectedRow.operation.search("Dissociation")) {
                    return <span>{e.selectedRow.operation}</span>;
                } else if (e.selectedRow.operation.search("Associate")) {
                    return <span>{e.selectedRow.operation}</span>;
                }
            },
        },
        {
            indexOFColumn: 2,
            columnName: t("Before modification"),
            customJsx: (e: any) => {
                let oldValueArray: any = [];
                e.selectedRow.actions.forEach((el: any) => {
                    if (el.item !== "shopId") oldValueArray.push(el.oldValue);
                });

                if (e.selectedRow.operation === "modify") {
                    return (
                        <CustomColumnDisplay text={oldValueArray.join(",")} />
                    );
                } else if (e.selectedRow.operation === "creation") {
                    return null;
                } else if (e.selectedRow.operation === "Désarchivage") {
                    return (
                        <span>
                            {t("Status")}: {t("Archived")}
                        </span>
                    );
                } else if (e.selectedRow.operation === "Archivage") {
                    return (
                        <span>
                            {t("Status")}: {t("Unarchived")}
                        </span>
                    );
                } else {
                    return <span>{""}</span>;
                }
            },
        },
        {
            indexOFColumn: 3,
            columnName: t("After modification"),
            customJsx: (e: any) => {
                let newValueArray: any = [];
                e.selectedRow.actions.forEach((el: any) => {
                    if (el.item !== "shopId") newValueArray.push(el.newValue);
                });

                if (e.selectedRow.operation === "modify") {
                    return (
                        <CustomColumnDisplay text={newValueArray.join(",")} />
                    );
                } else if (e.selectedRow.operation === "creation") {
                    return e.selectedRow[t("Designation")] !== undefined
                        ? e.selectedRow[t("Designation")]
                        : "--";
                } else if (e.selectedRow.operation === "Désarchivage") {
                    return (
                        <span>
                            {t("Status")}: {t("Unarchived")}
                        </span>
                    );
                } else if (e.selectedRow.operation === "Archivage") {
                    return (
                        <span>
                            {t("Status")}: {t("Archived")}
                        </span>
                    );
                } else {
                    return <span>{""}</span>;
                }
            },
        },
    ];

    React.useEffect(() => {
        setArrayIds(selectedApplicationKds.map((el) => el.appId));
    }, [selectedApplicationKds]);
    return (
        <React.Fragment>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                className="modal-wrapper__clz"
                isOpen={isModalOpened}
            >
                <ModalHeader
                    toggle={() => {
                        setIsModalOpened!(!isModalOpened);
                        setIsDataUpdated(true);
                    }}
                    className="dev_icn_HSclose"
                >
                    <StyledH2 className="dev_txt_HStitleHistory">
                        {capitalize(t("Historical"))}
                    </StyledH2>
                </ModalHeader>

                <ModalBody>
                    <div
                        className={`${classnames("title__clz", {
                            "not-allowed-icon__clz":
                                selectedApplicationKds.length > 1,
                        })}`}
                    >
                        {t("Designation")} :
                        {selectedApplicationKds.length > 1
                            ? null
                            : data?.data !== undefined
                            ? data?.data![0][t("Designation")]
                            : ""}
                    </div>

                    <DynamicTable
                        url={url}
                        canResize
                        actionColumn={ActionColumn}
                        showGlobalFilter
                        requestHeader={{ "Accept-Language": i18nextLng }}
                        showFilter
                        setLocalFilterActive={setLocalFilterActive}
                        filterActive={filterActive}
                        setDataIsUpdated={setDataIsUpdated}
                        dataIsUpdated={dataIsUpdated}
                        elevationTable={1}
                        minHeight="500px"
                        maxHeight="500px"
                        name="historyProject"
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        setData={setData}
                    />
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <CustomMainColorButton
                        variant="primary"
                        className="fed_btn_HSclose"
                        onClick={() => {
                            setIsModalOpened(!isModalOpened);
                            setIsDataUpdated(true);
                        }}
                        rounded
                    >
                        {t("Close")}
                    </CustomMainColorButton>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
