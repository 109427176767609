import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationArchiveType = {
    setIsArchiveSVModalOpened: Function;
    isArchiveSVModalOpened: boolean;
    setIsValidateClicked: Function;
};

export function ConfirmationArchive({
    setIsArchiveSVModalOpened,
    isArchiveSVModalOpened,
    setIsValidateClicked,
}: ConfirmationArchiveType) {
    const { t } = useTranslation();

    return (
        <StyledModal toggle={true} isOpen={isArchiveSVModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsArchiveSVModalOpened(!isArchiveSVModalOpened);
                }}
            >
                <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        "Attention, note that this sale support(s) can be used by other modules.\n Do you want to confirm the archive of the selected sale support(s)?"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsArchiveSVModalOpened(!isArchiveSVModalOpened);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        setIsValidateClicked(true);
                        setIsArchiveSVModalOpened(!isArchiveSVModalOpened);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
