import React from "react";
import { capitalize } from "@helpers/general";

import {
    StyledModal,
    StyledH2,
    StyledLabel,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useSnapshot } from "valtio";
import { store } from "../../../../components/VerticalLayout/store";
import storeVatRate from "../store";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { mutate } from "swr";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationAssociationType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    Fn: Function;
    obj: any;
    handleValidate: Function;
    franchiseData: any;
    setIsSendingTag: Function;
    setCanValidate: Function;
};
export function ConfirmationAssociation({
    isModalOpened,
    setIsModalOpened,
    Fn,
    obj,
    handleValidate,
    franchiseData,
    setIsSendingTag,
    setCanValidate,
}: ConfirmationAssociationType) {
    const { t } = useTranslation();
    const { shopID } = useSnapshot(store);
    const { isFormShopUpdatedPays, dataFranchise } = useSnapshot(storeVatRate);
    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalOpened(!isModalOpened);
                    setCanValidate(false);
                }}
            >
                <StyledH2>{capitalize(t("Alert"))}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {t(
                        "The VAT rate already exists in the franchise. Would you like to associate it with this store?"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                        setCanValidate(false);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={async () => {
                        let dataFr: any = dataFranchise.find((x: any) => {
                            return (
                                x.value === franchiseData.value &&
                                x.code === franchiseData.code
                            );
                        });
                        let AncienPays: any = dataFr[t("Country")].ids;

                        setIsModalOpened(!isModalOpened);
                        let tag = obj.data[0].tag;

                        let object = obj;

                        if (isFormShopUpdatedPays === true) {
                            let dataFr: any = dataFranchise.find((x: any) => {
                                return (
                                    x.value === franchiseData.value &&
                                    x.code === franchiseData.code
                                );
                            });
                            franchiseData.tag = dataFr.tag;

                            let FrObj: any = obj;
                            FrObj.shopId = "0";
                            FrObj.canReplace = false;

                            FrObj.data[0] = franchiseData;
                            const requestOptions = {
                                method: "POST",
                                headers: { "Content-Type": "application/json" },
                                body: JSON.stringify(FrObj),
                            };

                            let apiUrl: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/editVatRate`;

                            mutate(
                                apiUrl,
                                await fetch(apiUrl, requestOptions)
                                    .then((response) => response.json())
                                    .then(async (data) => {
                                        if (data.error) {
                                            throw Error("error");
                                        }
                                    })
                            );
                            object.updateCountry = true;
                            object.baseCountry = AncienPays;
                        }
                        object.data[0].state = false;
                        object.shopId = shopID;
                        object.canReplace = true;
                        object.data[0].tag = tag;

                        setIsSendingTag(true);
                        await Fn(object);
                        toast.success(
                            `${t("The vat rate is successfully associated")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
