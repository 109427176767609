import React from "react";
import { StyledButton } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";

import { store } from "@store";

export function CustomSecondaryColorButton(props: any) {
    const { secondaryColor } = useSnapshot(store);

    return (
        <StyledButton {...props} style={{ backgroundColor: secondaryColor }} />
    );
}
