import React from "react";
import { StyledLabel } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Col, ModalFooter } from "reactstrap";
import { AvField } from "availity-reactstrap-validation";
import classnames from "classnames";
import { ipMatchWord } from "@constants/index";
import ShowPasswordSvgIcon from "@components/Common/SvgIcons/ShowPasswordSvgIcon";
import HidePasswordSvgIcon from "@components/Common/SvgIcons/HidePasswordSvgIcon";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "react-toastify/dist/ReactToastify.css";
import "react-dropzone-uploader/dist/styles.css";
import "./index.css";

type PeripheralsModalConfigurationsType = {
    setIsConfiguredCashManagementClicked: Function;
    isConfiguredCashManagementClicked: boolean;
    data: any;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<boolean | number>>;
    ipAddress: string;
    port: string;
    user: string;
    password: string;
    pos_id: string;
    setIpAddress: Function;
    setPort: Function;
    setUser: Function;
    setPosId: Function;
    setPassword: Function;
    setServerDirectory: Function;
    serverDirectory: string;
    addConfiguration: Function;
};

export default function CasdroConfigurations({
    ipAddress,
    port,
    user,
    password,
    pos_id,
    setIpAddress,
    setPort,
    setUser,
    setPosId,
    setIsConfiguredCashManagementClicked,
    isConfiguredCashManagementClicked,
    data,
    setDataIsUpdated,
    setServerDirectory,
    serverDirectory,
    setPassword,
    addConfiguration,
}: PeripheralsModalConfigurationsType) {
    const { t } = useTranslation();

    const [isNewPasswordDisplayed, setIsNewPasswordDisplayed] = React.useState<
        boolean
    >(false);
    const [InputPort, setInputPort] = React.useState<boolean>(false);
    const [InputIp, setInputIp] = React.useState<boolean>(false);
    const [InputUser, setInputUser] = React.useState<boolean>(false);
    const [InputPassword, setInputPassword] = React.useState<boolean>(false);
    const [InputPos, setInputPos] = React.useState<boolean>(false);
    // const ipMatchWord = new RegExp(
    //     /\b(0?\d{2}|0|1\d{1,2}|2[0-4]\d|25[0-5]|\d)\.([^0]\d|0|1\d{1,2}|2[0-4]\d|25[0-5]|\d)\.([^0]\d|0|1\d{1,2}|2[0-4]\d|25[0-5]|\d)\.([^0]\d|0|1\d{1,2}|2[0-4]\d|25[0-5]|\d)\b/i
    // );

    const numberMatchWord = new RegExp(/^[1-9]\d*$/);

    return (
        <React.Fragment>
            <div className="d-flex">
                <Col xs="12" sm="6">
                    <StyledLabel
                        htmlFor="example-input"
                        className="required__clz"
                    >
                        {t("IP address")}
                    </StyledLabel>
                    <AvField
                        className={classnames({
                            input__clz: InputIp,
                        })}
                        autocomplete="off"
                        id="ipadress"
                        name="ipadress"
                        placeholder={t("Enter")}
                        type="text"
                        onChange={(e: any) => {
                            setIpAddress(e.target.value);
                            setInputIp(false);
                        }}
                        value={ipAddress}
                    />
                    {InputIp && (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t(
                                ipAddress === ""
                                    ? "Please enter a IP address"
                                    : "IP address is invalid"
                            )}
                        </div>
                    )}
                </Col>

                <Col xs="12" sm="6">
                    <StyledLabel
                        htmlFor="example-input"
                        className="required__clz"
                    >
                        {t("Port number")}
                    </StyledLabel>
                    <AvField
                        className={classnames({
                            input__clz: InputPort,
                        })}
                        autocomplete="off"
                        id="port"
                        name="port"
                        placeholder={t("Enter")}
                        type="text"
                        onChange={(e: any) => {
                            setPort(e.target.value);
                            setInputPort(false);
                        }}
                        value={port}
                        validate={{
                            pattern: {
                                value: numberMatchWord,
                                errorMessage: t("Port number is invalid"),
                            },
                        }}
                    />
                    {InputPort && (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("Please enter a port number")}
                        </div>
                    )}
                </Col>
            </div>
            <div className="d-flex mt-3">
                <Col xs="12" sm="6">
                    <StyledLabel
                        htmlFor="example-input"
                        className="required__clz"
                    >
                        {t("User")}
                    </StyledLabel>
                    <AvField
                        className={classnames({
                            input__clz: InputUser,
                        })}
                        autocomplete="off"
                        id="user"
                        name="user"
                        placeholder={t("Enter")}
                        type="text"
                        onChange={(e: any) => {
                            setUser(e.target.value);
                            setInputUser(false);
                        }}
                        value={user}
                    />
                    {InputUser && (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("Please select user")}
                        </div>
                    )}
                </Col>
                <Col xs="12" sm="6">
                    <StyledLabel
                        htmlFor="example-input"
                        className="required__clz"
                    >
                        {t("Password")}
                    </StyledLabel>
                    <div className="new-password-toggle__clz mr-3">
                        {!isNewPasswordDisplayed ? (
                            <HidePasswordSvgIcon
                                onClick={() =>
                                    setIsNewPasswordDisplayed(
                                        (prevState: boolean) => !prevState
                                    )
                                }
                            />
                        ) : (
                            <ShowPasswordSvgIcon
                                onClick={() =>
                                    setIsNewPasswordDisplayed(
                                        (prevState: boolean) => !prevState
                                    )
                                }
                            />
                        )}
                    </div>
                    <AvField
                        className={classnames({
                            input__clz: InputPassword,
                        })}
                        autocomplete="off"
                        id="password"
                        name="Password"
                        placeholder={t("Enter")}
                        type={isNewPasswordDisplayed ? "text" : "password"}
                        onChange={(e: any) => {
                            setPassword(e.target.value);
                            setInputPassword(false);
                        }}
                        value={password}
                    />
                    {InputPassword && (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("Please select password")}
                        </div>
                    )}
                </Col>
            </div>
            <div className="d-flex mt-3">
                <Col xs="12" sm="6">
                    <StyledLabel
                        htmlFor="example-input"
                        className="required__clz"
                    >
                        {"POS ID"}
                    </StyledLabel>
                    <AvField
                        className={classnames({
                            input__clz: InputPos,
                        })}
                        autocomplete="off"
                        id="pos_id"
                        name="pos_id"
                        placeholder={t("Enter")}
                        type="text"
                        onChange={(e: any) => {
                            setPosId(e.target.value);
                            setInputPos(false);
                        }}
                        value={pos_id}
                    />
                    {InputPos && (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("Please enter a Pos ID")}
                        </div>
                    )}
                </Col>
                <Col>
                    <StyledLabel htmlFor="example-input">
                        {t("Log directory")}
                    </StyledLabel>
                    <div>
                        <AvField
                            autocomplete="off"
                            id="serverDirectory"
                            name="serverDirectory"
                            type="text"
                            onChange={(e: any) =>
                                setServerDirectory(e.target.value)
                            }
                            value={serverDirectory}
                            defaultValue={`${".\\logMonnayeur"}`}
                        />
                    </div>
                </Col>
            </div>

            <div className="d-flex"></div>

            <ModalFooter>
                <CustomSecondaryColorButton
                    outline
                    rounded
                    type="button"
                    className="mr-2"
                    variant="light"
                    onClick={() => {
                        setIsConfiguredCashManagementClicked!(
                            !isConfiguredCashManagementClicked
                        );
                        setDataIsUpdated!(true);
                    }}
                >
                    <span> {t("Cancel")}</span>
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    variant="primary"
                    type="submit"
                    className="pull-right w-auto h-50"
                    onClick={() => {
                        if (ipAddress === "") {
                            setInputIp(true);
                        }
                        if (port === "") {
                            setInputPort(true);
                        }
                        if (user === "") {
                            setInputUser(true);
                        }
                        if (password === "") {
                            setInputPassword(true);
                        }
                        if (pos_id === "") {
                            setInputPos(true);
                        }
                        if (
                            ipAddress?.length !== 0 &&
                            port?.length !== 0 &&
                            user?.length !== 0 &&
                            password?.length !== 0 &&
                            pos_id?.length !== 0 &&
                            Number(port) &&
                            ipAddress.match(ipMatchWord)
                        )
                            addConfiguration();
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </React.Fragment>
    );
}
