import React from "react";
import { Input } from "reactstrap";
import { useSnapshot } from "valtio";

import {
    computedStore,
    designStore,
    setFooterTicketMessageItems,
    setFooterTicketNumberMessage,
} from "@store";

export function TicketNumberMessageRow(): JSX.Element {
    const { selectedLanguage, footerTicketNumberMessage, activeSlideIndex } =
        useSnapshot(designStore);

    const { elements } = useSnapshot(computedStore);

    function handleUserAccountMessageItemInputOnChangeEvent(content: string) {
        const localItems: HeaderLanguageItemsType = {
            ...footerTicketNumberMessage,
            [selectedLanguage]: {
                defaultContent:
                    footerTicketNumberMessage[selectedLanguage].defaultContent,
                content: content,
            },
        };

        setFooterTicketNumberMessage(localItems);
        setFooterTicketMessageItems(
            localItems,
            elements[activeSlideIndex].name
        );
    }

    return (
        <tr>
            <td className="cursor__clz" style={{ width: "35%" }}>
                {footerTicketNumberMessage[selectedLanguage].defaultContent !==
                undefined
                    ? footerTicketNumberMessage[selectedLanguage].defaultContent
                    : null}
            </td>
            <td>
                <Input
                    id="card-title"
                    name="card-title"
                    type="text"
                    value={
                        footerTicketNumberMessage[selectedLanguage].content !==
                        undefined
                            ? footerTicketNumberMessage[selectedLanguage]
                                  .content
                            : "null"
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        handleUserAccountMessageItemInputOnChangeEvent(
                            event.target.value
                        );
                    }}
                />
            </td>
        </tr>
    );
}
