import styled from "styled-components";

type ContainerWrapperPropsType = {
    isTopBannerActive: boolean;
    isHeaderActive: boolean;
};

export const ContainerWrapper = styled.div<ContainerWrapperPropsType>`
    display: grid;
    background-color: transparent;
    position: relative;
    grid-template-rows: ${({
        isTopBannerActive,
        isHeaderActive,
    }: ContainerWrapperPropsType) =>
        isTopBannerActive && isHeaderActive
            ? "80px 40px 445px 130px 30px"
            : !isHeaderActive && !isTopBannerActive
            ? "585px 110px 30px;"
            : isHeaderActive && !isTopBannerActive
            ? "50px 535px 110px 30px"
            : !isHeaderActive && isTopBannerActive
            ? "80px 505px 110px 30px"
            : ""};
    height: 100%;
`;
