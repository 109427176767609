import React from "react";
import classnames from "classnames";
import ReactSwitch from "react-switch";
import { Tooltip } from "@mui/material";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";

import { setListAfterAssociatedVatRate, storeVatRate } from "../store";
import { searchPosInArrayObject } from "@helpers/general";

export function CustomIconsAssociateModal({ e, setData }: any) {
    const { t } = useTranslation();

    const {
        isVatRateArchived,
        listAfterAssociatedVatRate,
        associatedShops,
        dataShops,
    } = useSnapshot(storeVatRate);

    let posShop = searchPosInArrayObject(
        dataShops,
        "shopId",
        associatedShops[0]?.shopId
    );
    let shopsVatRateOrigin: any = [];
    if (posShop !== -1) {
        shopsVatRateOrigin = dataShops[posShop].subRows;
    }

    let shopsVatRate: any = [];
    shopsVatRateOrigin.forEach((elx: any) => {
        if (elx.archived === false) {
            shopsVatRate.push(elx);
        }
    });

    const [VatRateState, setVatRateState] = React.useState<boolean>(
        e.selectedRow.status
    );

    async function handleStateChange(id: string, value: boolean) {
        let assocAux = JSON.parse(JSON.stringify(listAfterAssociatedVatRate));
        var pos = searchPosInArrayObject(assocAux, "Id", id);
        let objAC = assocAux[pos];
        objAC.status = value;
        assocAux[pos] = objAC;
        setListAfterAssociatedVatRate(assocAux);
    }

    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    <Tooltip
                        title={
                            VatRateState === false
                                ? t("Activate")
                                : t("Unactivate")
                        }
                    >
                        <div>
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={VatRateState}
                                onChange={async () => {
                                    setVatRateState(!VatRateState);
                                    let pos = searchPosInArrayObject(
                                        listAfterAssociatedVatRate,
                                        "Id",
                                        e.selectedRow.Id
                                    );

                                    let objData = JSON.parse(
                                        JSON.stringify(
                                            listAfterAssociatedVatRate
                                        )
                                    );
                                    objData[pos].status = !VatRateState;

                                    setData({ data: objData, othersData: [] });
                                    handleStateChange(
                                        e.selectedRow.Id,
                                        !VatRateState
                                    );
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={50}
                                height={20}
                                className={`${classnames(
                                    "pointer__clz ml-3 tva_icn_AFenableDisable",
                                    {
                                        "not-allowed-icon__clz": isVatRateArchived,
                                    }
                                )}`}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
        </React.Fragment>
    );
}
