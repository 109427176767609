import React from "react";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import { useSnapshot } from "valtio";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import { useLocation } from "react-router-dom";

import { store as userStore } from "@components/VerticalLayout/store";
import {
    store as appStore,
    generalConfigStore,
    setIsActionsClicked,
    setActions,
    setValidationAction,
    setIsCreateProjectClicked,
    setIsEdited,
} from "@store";
import {
    store,
    setIsModalArchiveOpened,
    setDataProject,
    setNumberArchived,
    setNumberActive,
    setIsModalDissociateOpened,
    setIsConfirmationPublishProject,
    setPublishProject,
    setIsArchivedTemplateClicked,
    setDataProjectFranchise,
    setSelectedProject,
    setModify,
} from "../../store/project/index";

import MessageInfo from "@components/Common/MessageInfo";
import NProgressWrapper from "@components/Common/NProgressWrapper";
import PageTitle from "@components/Common/PageTitle";
import { ModalContentWrapper } from "@components/ModalContentWrapper";
import { useCallbackPrompt } from "@hooks/useCallbackPrompt";
import { ConfirmationUpdatingDataMessageModal } from "@components/Common/ConfirmationUpdatingDataMessageModal";

import { DropDownWrapper } from "./DropDownWrapper";
import { AddProject } from "./AddProject";
import { ProjectCreationButtonWrapper } from "./ProjectCreationButtonWrapper";
import { ConfirmationArchiveTemplate } from "./ConfirmationArchiveTemplate";
import ModalAssociateStore from "./ModalAssociateStore";
import ModalPublish from "./ModalPublish";
import ModalCreatedCoping from "./CreatedCoping";
import ModalPublishProject from "./ModalPublishProject";
import ModalPlaning from "./ModalPlanning";
import ModalConfirmationConfigurationKiosk from "./ModalConfirmationConfigurationKiosk";
import { CustomIcons } from "./CustomIcon";
import kioskSettingStore from "@pages/GeneralConfig/store";
import { ModalWrapperKioskSetting } from "./ModalWrapperKioskSetting";
import { ConfirmationDissociateTemplate } from "./ConfirmationDissociateTemplate";
import { ConfigurationProjectPublish } from "./ConfigurationProjectPublish";
import HistoryModal from "./HistoryModal";
import BadgeNumber from "@components/Common/BadgeNumber";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";

import "@assets/swal.css";
import "@assets/theme.css";
import "./index.css";
import { getPathAuthorization } from "@components/VerticalLayout";

type CustomColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
};
export function Project(): JSX.Element {
    const { t } = useTranslation();
    const location = useLocation();
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const {
        isEdited,
        actions,
        validationAction,
        isActionsClicked,
    } = useSnapshot(appStore);
    const { isModalOpened } = useSnapshot(generalConfigStore);
    const { userID, franchiseID, oneShop, shopID, operatorID } = useSnapshot(
        userStore
    );
    const {
        isModalConfirmationConfigurationKiosk,
        isLoading,
        publishProject,
    } = useSnapshot(store);
    const [
        isAddNewProjectButtonClicked,
        setIsAddNewProjectButtonClicked,
    ] = React.useState(false);
    const {
        isModalAssociateStore,
        isCreatedCoping,
        isArchivedTemplateClicked,
        isModesModalDisassociate,
        isModalArchiveOpened,
        isModalDissociateOpened,
        isConfirmationPublishProject,
    } = useSnapshot(store);
    const { isKioskSetting } = useSnapshot(kioskSettingStore);
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        isEdited
    );

    const [shopId, setShopId] = React.useState<string>(oneShop ? shopID : "0");
    const [limit, setLimit] = React.useState<number>(1);

    const urlActive = `${process.env.REACT_APP_API_V2_URL}/settings/projects?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&operatorId=${operatorID}`;
    const urlArchive = `${process.env.REACT_APP_API_V2_URL}/settings/projects/list/archived?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&operatorId=${operatorID}`;
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [isDataUpdated, setIsDataUpdated] = React.useState<boolean | number>(
        false
    );
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [data, setData] = React.useState<any>({});
    const [isCreatedProject, setIsCreatedProject] = React.useState<boolean>(
        false
    );
    const [isHistoryModalOpened, setIsHistoryModalOpened] = React.useState<
        boolean
    >(false);

    let arrayOfCustomColumns: CustomColumnProps[] = [
        {
            indexOFColumn: 3,
            columnName: t("Status"),
            customJsx: (e: any) => {
                let testTime = "";
                if (e.selectedRow.definedTime === true) {
                    testTime = `${moment(e.selectedRow.startDate).format(
                        "DD/MM/YYYY"
                    )}-${moment(e.selectedRow.time).format("HH:mm a")}`;
                }
                if (
                    e.selectedRow.hourlyInterval === true &&
                    e.selectedRow.interval.length === 1
                ) {
                    testTime = `De ${moment(e.selectedRow.startDate).format(
                        "DD/MM/YYYY"
                    )} à ${moment(e.selectedRow.endDate).format("DD/MM/YYYY")}`;
                }
                if (
                    e.selectedRow.hourlyInterval === true &&
                    e.selectedRow.interval.length > 1
                ) {
                    testTime = ` ${moment(e.selectedRow.startDate).format(
                        "DD/MM/YYYY"
                    )}`;
                }
                return e.selectedRow.key_project !== undefined &&
                    e.selectedRow.Status === "en attente" &&
                    !isArchivedTemplateClicked ? (
                    <h4 style={{ paddingTop: "8px" }}>
                        <span
                            className="badge badge-pill badge-danger"
                            style={{
                                paddingTop: "8px",
                                paddingBottom: "9px",
                                fontSize: "10px",
                                textAlign: "center",
                                fontWeight: "bold",
                            }}
                        >
                            {`${t("pending")}`}
                            <br />
                            {`${e.selectedRow.hour}`}
                        </span>
                    </h4>
                ) : e.selectedRow.key_project !== undefined &&
                  e.selectedRow.Status === "en attente" &&
                  isArchivedTemplateClicked ? (
                    <h4 style={{ paddingTop: "8px" }}>
                        <span
                            className="badge-secondary_clz"
                            style={{
                                paddingTop: "8px",
                                paddingBottom: "9px",
                                fontSize: "10px",
                                textAlign: "center",
                                fontWeight: "bold",
                            }}
                        >
                            {t("Archived")}
                            <br />
                            {`${e.selectedRow.hour}`}
                        </span>
                    </h4>
                ) : e.selectedRow.key_project !== undefined &&
                  e.selectedRow.Status === "Publié" ? (
                    <h4 style={{ paddingTop: "8px" }}>
                        <span
                            className="badge-success__clz"
                            style={{
                                paddingTop: "8px",
                                paddingBottom: "9px",
                                fontSize: "10px",
                                textAlign: "center",
                                fontWeight: "bold",
                            }}
                        >
                            <span>
                                {isArchivedTemplateClicked
                                    ? `${t("Archived")}`
                                    : `${t("Published")}`}
                            </span>
                            <br />

                            {`${e.selectedRow.hour}`}
                        </span>
                    </h4>
                ) : e.selectedRow.key_project !== undefined &&
                  e.selectedRow.Status === "Planifié" ? (
                    <Dropdown>
                        <Dropdown.Toggle
                            id="dropdown-button-dark-example1"
                            style={{
                                paddingTop: "3px",
                                paddingBottom: "3px",
                                fontSize: "10px",
                                textAlign: "center",
                                borderRadius: "25px",
                                color: !isArchivedTemplateClicked
                                    ? "#8811f9"
                                    : "#808080",
                                backgroundColor: "#ebdffe",
                                borderColor: "#ebdffe",
                                fontWeight: "bold",
                            }}
                            className="style-button-planing__clz"
                        >
                            {isArchivedTemplateClicked
                                ? `${t("Archived")}`
                                : `${t("Planned")}`}
                            <br />
                            {testTime}
                        </Dropdown.Toggle>
                        {e.selectedRow.hourlyInterval ? (
                            <Dropdown.Menu>
                                {e.selectedRow.interval.length > 1 ? (
                                    e.selectedRow.days.map((day: any) => (
                                        <Dropdown.Item>
                                            {moment(day.Start).format(
                                                "HH:mm a"
                                            )}
                                            -{moment(day.End).format("HH:mm a")}
                                        </Dropdown.Item>
                                    ))
                                ) : (
                                    <Dropdown.Item>
                                        {moment(
                                            e.selectedRow.days[0].Start
                                        ).format("HH:mm a")}
                                        -
                                        {moment(
                                            e.selectedRow.days[0].End
                                        ).format("HH:mm a")}
                                    </Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        ) : null}
                    </Dropdown>
                ) : null;
            },
        },
        {
            indexOFColumn: 4,
            columnName: t("Number/IP address"),
            customJsx: (e: any) => {
                return (
                    <span>
                        {e.selectedRow["Nombre/adresse Ip"] !== "" &&
                        e.selectedRow["appId"] !== undefined ? (
                            e.selectedRow["Nombre/adresse Ip"]
                        ) : e.selectedRows?.associates === undefined &&
                          e.selectedRow["Nombre/adresse Ip"] !== "" &&
                          e.selectedRow["Nombre/adresse Ip"] !== undefined ? (
                            <BadgeNumber
                                value={e.selectedRow["Nombre/adresse Ip"]}
                            />
                        ) : (
                            ""
                        )}
                    </span>
                );
            },
        },

        {
            indexOFColumn: 999,
            columnName: t("Actions"),
            customJsx: (e: any) => {
                return (
                    <CustomIcons
                        e={e}
                        setRowData={setSelectedRows}
                        setIsCreatedProject={setIsCreatedProject}
                        isCreatedProject={isCreatedProject}
                        isHistoryModalOpened={isHistoryModalOpened}
                        setIsHistoryModalOpened={setIsHistoryModalOpened}
                    />
                );
            },
        },
        {
            indexOFColumn: 1,
            columnName: t("Designation"),
            customJsx: (e: any) => {
                return (
                    <CustomColumnDisplay
                        text={e.selectedRow[t("Designation")]}
                    />
                );
            },
        },
    ];

    React.useEffect(() => {
        if (data.data !== undefined) {
            setDataProject(data.data);
        }
    }, [data]);
    React.useEffect(() => {
        if (i18nextLng === "fr" || i18nextLng === "en") {
            setIsDataUpdated(true);
        }
    }, [i18nextLng]);
    React.useEffect(() => {
        oneShop ? setShopId(shopId) : setShopId("0");
        if (oneShop) {
            setIsArchivedTemplateClicked(false);
        }
        setIsCreateProjectClicked(isCreatedProject);
        // eslint-disable-next-line
    }, [oneShop, isCreatedProject]);

    React.useEffect(() => {
        let array: any = [];
        Object.values(selectedRows).forEach((element: any) => {
            array.push({ [shopId]: element.projectId });
        });
        setSelectedProject(array);
        setNumberArchived(
            data.othersData !== undefined ? data.othersData.archivedProjects : 0
        );
        setNumberActive(
            data.othersData !== undefined ? data.othersData.activedProjects : 0
        );
        if (data.data !== undefined) {
            data?.data.forEach((el: any) => {
                if (el?.Status === "Publié") {
                    setPublishProject(el);
                }
            });
            setDataProjectFranchise(
                data.othersData !== undefined
                    ? data.othersData.dataProjectFranchise?.data
                    : []
            );
        }
    }, [isArchivedTemplateClicked, data, selectedRows, shopId]);

    React.useEffect(() => {
        setActions(confirmNavigation);
        setIsActionsClicked(showPrompt as boolean);
        setValidationAction(cancelNavigation);
    }, [showPrompt, confirmNavigation, cancelNavigation]);
    setModify(getPathAuthorization(location.pathname).Modifie);
    return (
        <React.Fragment>
            <PageTitle title={t("Applications")} />
            <div className="page-content__clz">
                <StyledBreadcrumb
                    items={[
                        {
                            item: "ETK Settings".toUpperCase(),
                            link: "https://dev-portail.aureskonnect.com",
                        },
                        {
                            item:
                                localStorage
                                    .getItem("selectedAccount")
                                    ?.toUpperCase() || "",
                            link: "/compte",
                        },
                        {
                            item: t("Application").toUpperCase(),
                            link: "applications",
                        },
                        {
                            item: t("KIOSK").toUpperCase(),
                            link: "kiosk",
                        },
                        {
                            item: isArchivedTemplateClicked
                                ? t("Archived template").toUpperCase()
                                : t("CONFIGURATION TEMPLATE").toUpperCase(),
                            link: "#",
                        },
                    ]}
                />
                <MessageInfo message={t("Please set your template")} />

                <div
                    style={{
                        display: "grid",
                        minHeight: "650px",
                        gridTemplateColumns: isCreatedProject
                            ? "2fr 1fr"
                            : "auto",
                        gridColumnGap: "4px",
                        border: 0,
                    }}
                >
                    <DynamicTable
                        url={
                            !oneShop
                                ? isArchivedTemplateClicked
                                    ? urlArchive
                                    : urlActive
                                : urlActive
                        }
                        requestHeader={{ "Accept-Language": i18nextLng }}
                        setData={setData}
                        customJsxSideFilterButton={
                            <ProjectCreationButtonWrapper
                                isModalArchiveOpened={isModalArchiveOpened}
                                setIsModalArchiveOpened={
                                    setIsModalArchiveOpened
                                }
                                setIsDataUpdated={setIsDataUpdated}
                                selectedRows={selectedRows}
                                isArchivedTemplateClicked={
                                    isArchivedTemplateClicked
                                }
                                isModalDissociateOpened={
                                    isModalDissociateOpened
                                }
                                setIsModalDissociateOpened={
                                    setIsModalDissociateOpened
                                }
                                isCreatedProject={isCreatedProject}
                                setIsCreatedProject={setIsCreatedProject}
                                setIsInInitializationModalOpened={
                                    setIsInInitializationModalOpened
                                }
                                setSelectedRows={setSelectedRows}
                            />
                        }
                        setDataIsUpdated={setIsDataUpdated}
                        dataIsUpdated={isDataUpdated}
                        canResize
                        customSelect
                        canSelect
                        filterActive={filterActive}
                        setLocalFilterActive={setLocalFilterActive}
                        canMovedCheckboxLeftOnExpand
                        actionColumn={(e: any) => {
                            return (
                                <DropDownWrapper
                                    element={e}
                                    setIsDataUpdated={setIsDataUpdated}
                                    isDataUpdated={isDataUpdated}
                                    setSelectedRows={setSelectedRows}
                                    isArchivedTemplateClicked={
                                        isArchivedTemplateClicked
                                    }
                                    dataProject={
                                        data.othersData !== undefined
                                            ? data.othersData
                                            : []
                                    }
                                    isCreatedProject={isCreatedProject}
                                    setIsCreatedProject={setIsCreatedProject}
                                />
                            );
                        }}
                        showGlobalFilter
                        canExpand
                        elevationTable={1}
                        minHeight="500px"
                        maxHeight="500px"
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        setSelectedRows={setSelectedRows}
                    />
                    {isCreatedProject ? (
                        <AddProject
                            setIsDataUpdated={setIsDataUpdated}
                            rowData={selectedRows}
                            shopId={shopId}
                            isCreatedProject={isCreatedProject}
                            setIsCreatedProject={setIsCreatedProject}
                            setSelectedRows={setSelectedRows}
                            isAddNewProjectButtonClicked={
                                isAddNewProjectButtonClicked
                            }
                        />
                    ) : null}
                    {isModalAssociateStore === true ? (
                        <ModalAssociateStore
                            rowData={selectedRows}
                            setIsDataUpdated={setIsDataUpdated}
                            setLimit={setLimit}
                        />
                    ) : null}
                    <ModalPublish
                        rowData={selectedRows}
                        setIsDataUpdated={setIsDataUpdated}
                    />
                    {isCreatedCoping === true ? (
                        <ModalCreatedCoping
                            rowData={selectedRows}
                            setIsDataUpdated={setIsDataUpdated}
                            shopId={shopId}
                            dataProject={
                                data.othersData !== undefined
                                    ? data.othersData
                                    : []
                            }
                        />
                    ) : null}
                    {isModesModalDisassociate ? (
                        <ModalPublishProject
                            rowData={selectedRows}
                            setIsDataUpdated={setIsDataUpdated}
                            shopId={shopId}
                        />
                    ) : null}
                    <ModalPlaning
                        rowData={selectedRows}
                        setIsDataUpdated={setIsDataUpdated}
                        isArchivedTemplateClicked={isArchivedTemplateClicked}
                    />
                    {isModalArchiveOpened ? (
                        <ConfirmationArchiveTemplate
                            isModalArchiveOpened={isModalArchiveOpened}
                            setIsModalArchiveOpened={setIsModalArchiveOpened}
                            setIsDataUpdated={setIsDataUpdated}
                            selectedRows={selectedRows}
                            isArchivedTemplateClicked={
                                isArchivedTemplateClicked
                            }
                            dataTable={data}
                        />
                    ) : null}
                    {isModalConfirmationConfigurationKiosk ? (
                        <ModalConfirmationConfigurationKiosk
                            setIsDataUpdated={setIsDataUpdated}
                            isDataUpdated={isDataUpdated}
                        />
                    ) : null}
                    {isKioskSetting === true && isModalOpened === true ? (
                        <ModalWrapperKioskSetting
                            rowData={selectedRows}
                            setSelectedRows={setSelectedRows}
                            setIsDataUpdated={setIsDataUpdated}
                            isDataUpdated={isDataUpdated}
                        >
                            <ModalContentWrapper />
                        </ModalWrapperKioskSetting>
                    ) : null}
                    {isModalDissociateOpened ? (
                        <ConfirmationDissociateTemplate
                            isModalDissociateOpened={isModalDissociateOpened}
                            setIsModalDissociateOpened={
                                setIsModalDissociateOpened
                            }
                            setIsDataUpdated={setIsDataUpdated}
                            selectedRows={selectedRows}
                        />
                    ) : null}
                    {isConfirmationPublishProject ? (
                        <ConfigurationProjectPublish
                            isConfirmationPublishProject={
                                isConfirmationPublishProject
                            }
                            setIsConfirmationPublishProject={
                                setIsConfirmationPublishProject
                            }
                            setIsDataUpdated={setIsDataUpdated}
                            selectedRows={selectedRows}
                            dataTable={publishProject}
                            dataProject={
                                data.othersData !== undefined
                                    ? data.othersData
                                    : []
                            }
                        />
                    ) : null}
                    {isHistoryModalOpened ? (
                        <HistoryModal
                            ids={selectedRows}
                            setIsModalOpened={setIsHistoryModalOpened}
                            isModalOpened={isHistoryModalOpened}
                            setIsDataUpdated={setIsDataUpdated}
                            setIsCreatedProject={setIsCreatedProject}
                        />
                    ) : null}
                    {isActionsClicked === true ? (
                        <ConfirmationUpdatingDataMessageModal
                            isModalOpened={isActionsClicked}
                            setIsModalOpened={setIsActionsClicked}
                            validationAction={validationAction}
                            actions={actions}
                            setIsEdited={setIsEdited}
                        />
                    ) : null}
                    {isInInitializationModalOpened === true ? (
                        <ConfirmationInitializationMessage
                            isModalOpened={isInInitializationModalOpened}
                            setIsModalOpened={setIsInInitializationModalOpened}
                            setIsClicked={setIsAddNewProjectButtonClicked}
                            isClicked={isAddNewProjectButtonClicked}
                            setIsEdited={setIsEdited}
                        />
                    ) : null}
                    <NProgressWrapper isLoading={isLoading} />
                    <ToastContainer limit={limit} />
                </div>
            </div>
        </React.Fragment>
    );
}
