import React from "react";
import { useSnapshot } from "valtio";

import { getImageContentById } from "@helpers/general";

import { designStore, generalConfigStore } from "@store";

import "./index.css";

type ImageContentPropsType = {
    item: ActionsButtonsDesignItemType;
};

export function ActionsButtonsImageContent({
    item,
}: ImageContentPropsType): JSX.Element {
    const {
        project: { files: images },
    } = useSnapshot(generalConfigStore);
    const { selectedLanguage } = useSnapshot(designStore);

    const imageContent = getImageContentById(item.id, images, selectedLanguage);

    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    return (
        <img
            src={imageSrc}
            style={{ height: "36px", width: "29%" }}
            alt="img"
        />
    );
}
