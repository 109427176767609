import React from "react";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next/";
import { StyledButton } from "@aureskonnect/react-ui";

import store, { setPath } from "./store";

export default function PrinterActionColumns(rows: any) {
    const { t } = useTranslation();
    const { path } = useSnapshot(store);

    return (
        <StyledButton
            rounded
            variant={
                path === rows.selectedRow["Chemin de l'imprimante"]
                    ? "danger"
                    : "light"
            }
            outline
            className="button-style-select___clz"
            onClick={() => {
                setPath(rows.selectedRow["Chemin de l'imprimante"]);
            }}
        >
            {path === rows.selectedRow["Chemin de l'imprimante"]
                ? t("Deselect")
                : t("Select")}
        </StyledButton>
    );
}
