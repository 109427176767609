import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import {
    CheckboxIcon,
    StyledH2,
    StyledModal,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { DynamicTable } from "@maherunlocker/custom-react-table";
import { CustomIconsAssociateModal } from "./CustomIconsAssociateModal";

import { store } from "@components/VerticalLayout/store";
import {
    storeVatRate,
    setIsAssociateModalOpened,
    setListAfterAssociatedVatRate,
    setFinalArrayAssoc,
    setIsConfirmationAffectModalClicked,
    setDataIsUpdatedFranchise,
    setDataIsUpdatedShops,
    setIsConfirmationAffectArchivedModalClicked,
} from "../store";
import { ConfirmationArchivateShops } from "./ConfirmationArchivateShops";
import { searchPosInArrayObject } from "@helpers/general";
import { mutate } from "swr";
import { toast } from "react-toastify";
import { ConfirmationAffectation } from "./ConfirmationAffectation";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

type CustomColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
};

export function VatRateAssociateModal({ setLimit }: any) {
    const { t } = useTranslation();
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const {
        isAssociateModalOpened,
        listAfterAssociatedVatRate,
        isConfirmationAffectModalClicked,
        associatedShops,
        finalArrayAssoc,
        localShop,
        dataShops,
        isConfirmationAffectArchivedModalClicked,
    } = useSnapshot(storeVatRate);
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const [data, setData] = React.useState<any>({});
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);

    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [modifiedRows, setModifiedRows] = React.useState<any[]>([]);

    const url = `${
        process.env.REACT_APP_API_V2_URL
    }/settings/general/vatRate?userId=${userID}&franchiseId=${franchiseID}&typeDisplay=notArchived&paysQuery=${localShop
        .map(({ pays }) => pays)
        .join(",")}${
        localShop.length === 1
            ? `&QueryShopCheckArchive=${localShop[0].id}`
            : ``
    }&AffectionShopIds=${localShop.map(({ id }) => id).join(",")}`;

    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };
    const ListOfPays = (e: any) => {
        let list: string = "";
        let arr =
            e.e.selectedRow[t("Country")]?.labels !== undefined
                ? e.e.selectedRow[t("Country")]?.labels
                : [];
        list = JSON.parse(JSON.stringify(arr)).join(",");
        return <CustomColumnDisplay text={`${list}`} />;
    };
    let arrayOfCustomColumns: CustomColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: t("VAT rate"),

            customJsx: (e: any) => <Fragment>{e.selectedRow.value} %</Fragment>,
        },
        {
            indexOFColumn: 997,
            columnName: t("Country"),

            customJsx: (e: any) => <ListOfPays e={e} />,
        },
        {
            indexOFColumn: 996,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIconsAssociateModal
                    e={e}
                    setDataIsUpdated={setDataIsUpdated}
                    modifiedRows={modifiedRows}
                    setModifiedRows={setModifiedRows}
                    data={data}
                    setData={setData}
                />
            ),
        },
    ];
    React.useEffect(() => {
        setListAfterAssociatedVatRate(data.data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        let finalArrayAssoc: any = [];

        if (selectedRows.length > 0) {
            selectedRows.forEach((el: any) => {
                let check = searchPosInArrayObject(
                    finalArrayAssoc,
                    "id",
                    el?.Id
                );
                let val = el;

                val["id"] = val["Id"];
                val["pays"] =
                    val[t("Country")]["ids"] !== undefined
                        ? val[t("Country")]["ids"]
                        : [];
                delete val[t("VAT rate")];
                delete val[t("VAT code")];
                delete val["Id"];
                delete val[t("Country")];
                if (check === -1) {
                    finalArrayAssoc.push(val);
                }
            });
        }
        setFinalArrayAssoc(finalArrayAssoc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows]);
    React.useEffect(() => {
        setListAfterAssociatedVatRate(data.data);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    async function associateVatRate(obj: any) {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/vatRate/associate`;
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(obj),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error!");
                        }
                        return data;
                    })
            );
        } catch (e: any) {}
    }

    async function associateHandle() {
        try {
            let searchIfAllReadyAffected = false;
            let ids = [];
            let finalArray = [];
            let listNo: number = 0;
            let blockedAssignArray: boolean[] = [];
            for (let i = 0; i < selectedRows.length; i++) {
                ids.push(selectedRows[i].id);

                let pos = searchPosInArrayObject(
                    JSON.parse(JSON.stringify(listAfterAssociatedVatRate)),
                    "Id",
                    selectedRows[i].id
                );

                if (pos !== -1) {
                    finalArray.push(listAfterAssociatedVatRate[pos]);
                }
            }
            let finalArrayVatRate = JSON.parse(JSON.stringify(finalArray));

            for (let i = 0; i < finalArrayVatRate.length; i++) {
                finalArrayVatRate[i]["id"] = finalArrayVatRate[i]["Id"];
                finalArrayVatRate[i]["pays"] =
                    finalArrayVatRate[i][t("Country")]["ids"];
                delete finalArrayVatRate[i][t("VAT rate")];
                delete finalArrayVatRate[i][t("VAT code")];
                delete finalArrayVatRate[i]["Id"];
                delete finalArrayVatRate[i][t("Country")];
            }

            for (let i = 0; i < associatedShops.length; i++) {
                if (associatedShops[i]?.subRows?.length > 0) {
                    for (
                        let j = 0;
                        j < associatedShops[i].subRows.length;
                        j++
                    ) {
                        if (
                            ids.indexOf(associatedShops[i].subRows[j].Id) !== -1
                        ) {
                            let posOriginVR: number = searchPosInArrayObject(
                                finalArrayVatRate,
                                "id",
                                associatedShops[i].subRows[j].Id
                            );
                            let originVR: any = {};
                            if (posOriginVR !== -1) {
                                originVR = finalArrayVatRate[posOriginVR];
                            }
                            if (
                                associatedShops[i].subRows[j].status !==
                                originVR.status
                            ) {
                                searchIfAllReadyAffected = true;
                            } else {
                                listNo++;
                            }
                        }
                    }
                }
            }
            setFinalArrayAssoc(finalArray);

            let dataBT: any = dataShops.filter(
                (x: any) =>
                    localShop.map(({ id }) => id).indexOf(x.shopId) !== -1
            );
            let arrayVTArchived: any = [];

            for (let i = 0; i < dataBT.length; i++) {
                const el = dataBT[i];
                for (let j = 0; j < el.subRows.length; j++) {
                    const elt = el.subRows[j];
                    if (elt.archived === true && ids.indexOf(elt.Id) !== -1) {
                        arrayVTArchived.push(elt.Id);
                    }
                }
            }

            if (arrayVTArchived.length > 0) {
                setIsConfirmationAffectArchivedModalClicked(true);
            } else if (searchIfAllReadyAffected) {
                setIsConfirmationAffectModalClicked(true);
            } else {
                let LS: any;
                let VR: any;

                for (let indexLS = 0; indexLS < localShop.length; indexLS++) {
                    LS = localShop[indexLS];
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    for (
                        let indexVR = 0;
                        indexVR < finalArrayVatRate.length;
                        indexVR++
                    ) {
                        VR = finalArrayVatRate[indexVR];
                        let dataObj = {
                            archived: VR.archived,
                            id: VR.id,
                            pays: VR.pays,
                            status: VR.status,
                            tag: VR.tag,
                            value: VR.value,
                            code: VR.code,
                        };
                        let obj = {
                            userId: userID,
                            operatorId: operatorID,
                            franchiseId: franchiseID,
                            canReplace: true,
                            shopId: LS.id,
                            data: [dataObj],
                        };
                        let posVT = searchPosInArrayObject(
                            data.data,
                            "Id",
                            VR.id
                        );
                        let hisPays = data.data[posVT][t("Country")].labels;

                        if (hisPays.indexOf(LS.pays) !== -1) {
                            await associateVatRate(obj);

                            blockedAssignArray.push(false);
                        } else {
                            blockedAssignArray.push(true);
                        }
                    }
                }
                if (
                    listNo === finalArrayAssoc.length &&
                    listNo === ids.length
                ) {
                    blockedAssignArray.push(true);
                }
                setDataIsUpdatedFranchise!(true);
                setDataIsUpdatedShops!(true);
                setIsAssociateModalOpened(!isAssociateModalOpened);
                if (blockedAssignArray.includes(false)) {
                    toast.success(
                        `${t("The vat rate is successfully associated")!}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            theme: "colored",
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                }
                if (blockedAssignArray.includes(true)) {
                    setLimit(2);
                    toast.warn(
                        t(
                            "Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings."
                        ),
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        }
                    );
                }
            }
        } catch (error) {
            console.log(error);

            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }
    async function associateHandleYes() {
        try {
            let ids = [];
            let finalArray = [];
            let blockedAssignArray: any = [];
            for (let i = 0; i < selectedRows.length; i++) {
                ids.push(selectedRows[i].id);
                let pos = searchPosInArrayObject(
                    listAfterAssociatedVatRate,
                    "Id",
                    selectedRows[i].id
                );
                if (pos !== -1) {
                    finalArray.push(listAfterAssociatedVatRate[pos]);
                }
            }
            let finalArrayVatRate = JSON.parse(JSON.stringify(finalArray));

            for (let i = 0; i < finalArrayVatRate.length; i++) {
                finalArrayVatRate[i]["id"] = finalArrayVatRate[i]["Id"];
                finalArrayVatRate[i]["pays"] =
                    finalArrayVatRate[i][t("Country")]["ids"];
                delete finalArrayVatRate[i][t("VAT rate")];
                delete finalArrayVatRate[i][t("VAT code")];
                delete finalArrayVatRate[i]["Id"];
                delete finalArrayVatRate[i][t("Country")];
            }

            setFinalArrayAssoc(finalArrayVatRate);

            let LS: any;
            let VR: any;
            let checkNotify: boolean = false;
            for (let indexLS = 0; indexLS < localShop.length; indexLS++) {
                LS = localShop[indexLS];
                // eslint-disable-next-line react-hooks/exhaustive-deps
                for (
                    let indexVR = 0;
                    indexVR < finalArrayVatRate.length;
                    indexVR++
                ) {
                    VR = finalArrayVatRate[indexVR];
                    let dataObj = {
                        archived: VR.archived,
                        id: VR.id,
                        pays: VR.pays,
                        status: VR.status,
                        tag: VR.tag,
                        value: VR.value,
                        code: VR.code,
                    };
                    let obj = {
                        userId: userID,
                        operatorId: operatorID,
                        franchiseId: franchiseID,
                        canReplace: true,
                        shopId: LS.id,
                        data: [dataObj],
                    };
                    let posVT = searchPosInArrayObject(data.data, "Id", VR.id);
                    let hisPays = data.data[posVT][t("Country")].labels;

                    if (hisPays.indexOf(LS.pays) !== -1) {
                        await associateVatRate(obj);
                        checkNotify = true;
                        blockedAssignArray.push(false);
                    } else {
                        blockedAssignArray.push(true);
                    }
                }
            }
            setDataIsUpdatedFranchise!(true);
            setDataIsUpdatedShops!(true);
            setIsAssociateModalOpened(!isAssociateModalOpened);
            if (checkNotify) {
                toast.success(
                    `${t("The vat rate is successfully associated")!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
            }
            if (blockedAssignArray.includes(true)) {
                setLimit(2);
                toast.warn(
                    t(
                        "Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings."
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
            }
        } catch (error) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }
    async function associateHandleNo() {
        try {
            let ids = [];
            let finalArray = [];
            let checkNotify: boolean = false;
            let blockedAssignArray: any = [];
            for (let i = 0; i < selectedRows.length; i++) {
                ids.push(selectedRows[i].id);
                let pos = searchPosInArrayObject(
                    listAfterAssociatedVatRate,
                    "Id",
                    selectedRows[i].id
                );

                if (pos !== -1) {
                    finalArray.push(listAfterAssociatedVatRate[pos]);
                }
            }
            let finalArrayVatRate = JSON.parse(JSON.stringify(finalArray));

            for (let i = 0; i < finalArrayVatRate.length; i++) {
                finalArrayVatRate[i]["id"] = finalArrayVatRate[i]["Id"];
                finalArrayVatRate[i]["pays"] =
                    finalArrayVatRate[i][t("Country")]["ids"];
                delete finalArrayVatRate[i][t("VAT rate")];
                delete finalArrayVatRate[i][t("VAT code")];
                delete finalArrayVatRate[i]["Id"];
                delete finalArrayVatRate[i][t("Country")];
            }
            setFinalArrayAssoc(finalArrayVatRate);

            let LS: any;
            let VR: any;
            for (let indexLS = 0; indexLS < localShop.length; indexLS++) {
                LS = localShop[indexLS];
                // eslint-disable-next-line react-hooks/exhaustive-deps
                for (
                    let indexVR = 0;
                    indexVR < finalArrayVatRate.length;
                    indexVR++
                ) {
                    VR = finalArrayVatRate[indexVR];
                    let dataObj = {
                        archived: VR.archived,
                        id: VR.id,
                        pays: VR.pays,
                        status: VR.status,
                        tag: VR.tag,
                        value: VR.value,
                        code: VR.code,
                    };
                    let obj = {
                        userId: userID,
                        operatorId: operatorID,
                        franchiseId: franchiseID,
                        canReplace: false,
                        shopId: LS.id,
                        data: [dataObj],
                    };

                    let posVT = searchPosInArrayObject(data.data, "Id", VR.id);
                    let hisPays = data.data[posVT][t("Country")].labels;

                    if (hisPays.indexOf(LS.pays) !== -1) {
                        await associateVatRate(obj);
                        checkNotify = true;
                        blockedAssignArray.push(false);
                    } else {
                        blockedAssignArray.push(true);
                    }
                }
            }

            setDataIsUpdatedFranchise!(true);
            setDataIsUpdatedShops!(true);
            setDataIsUpdated!(true);
            if (checkNotify) {
                toast.success(
                    `${t("The vat rate is successfully associated")!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
            }
            if (blockedAssignArray.includes(true)) {
                setLimit(2);
                toast.warn(
                    t(
                        "Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings."
                    ),
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
            }
        } catch (error) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }
    return (
        <React.Fragment>
            <StyledModal
                toggle={true}
                isOpen={isAssociateModalOpened}
                centered
                className="modal-wrapper__clz"
                backdrop="static"
                fade={false}
                scrollable
            >
                <ModalHeader
                    className="text-capitalize"
                    toggle={() => {
                        setIsAssociateModalOpened(!isAssociateModalOpened);
                    }}
                >
                    <StyledH2 className="text-uppercase tva_txt_AFlistOfVatRates">
                        {t("List of vat rate")}
                    </StyledH2>
                </ModalHeader>
                <ModalBody>
                    <DynamicTable
                        requestHeader={{ "Accept-Language": i18nextLng }}
                        url={url}
                        canSelect
                        customSelect
                        setSelectedRows={setSelectedRows}
                        setData={setData}
                        canResize
                        actionColumn={ActionColumn}
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        showGlobalFilter
                        setDataIsUpdated={setDataIsUpdated}
                        dataIsUpdated={dataIsUpdated}
                        elevationTable={1}
                        minHeight="500px"
                        maxHeight="500px"
                        name="associateVatRate"
                        canMovedCheckboxLeftOnExpand
                    />
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <CustomSecondaryColorButton
                        outline
                        variant="light"
                        className="tva_btn_AFcancel"
                        onClick={() => {
                            setIsAssociateModalOpened(!isAssociateModalOpened);
                        }}
                        rounded
                    >
                        {t("Cancel")}
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        className="tva_btn_AFvalidate"
                        disabled={selectedRows.length === 0}
                        variant="primary"
                        onClick={async () => {
                            await associateHandle();
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </ModalFooter>
            </StyledModal>
            <ConfirmationAffectation
                isModalOpened={isConfirmationAffectModalClicked}
                setIsModalOpened={setIsConfirmationAffectModalClicked}
                FnYes={() => {
                    setIsConfirmationAffectModalClicked(
                        !isConfirmationAffectModalClicked
                    );
                    associateHandleYes();
                }}
                FnNo={() => {
                    setIsConfirmationAffectModalClicked(
                        !isConfirmationAffectModalClicked
                    );
                    associateHandleNo();
                    setIsAssociateModalOpened(!isAssociateModalOpened);
                }}
                setLimit={setLimit}
            />
            <ConfirmationArchivateShops
                isModalOpened={isConfirmationAffectArchivedModalClicked}
                setIsModalOpened={setIsConfirmationAffectArchivedModalClicked}
                FnYes={() => {
                    setIsConfirmationAffectArchivedModalClicked(false);
                    associateHandleYes();
                }}
                FnNo={() => {
                    setIsConfirmationAffectArchivedModalClicked(false);

                    setIsAssociateModalOpened(!isAssociateModalOpened);
                }}
            />
        </React.Fragment>
    );
}
