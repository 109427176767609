import React from "react";
import classnames from "classnames";
import {
    DisassociateIcon,
    PencilIcon,
    SettingsIcon,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next/";
import { useSnapshot } from "valtio";

import store, {
    setIsModalDissociateOpened,
    setSelectedApplicationKds,
} from "./store";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";

export function CustomIcons({
    e,
    setSelectedRows,
    isAddProject,
    setIsAddProject,
    isProjectArchived,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    setIsConfigurationModalOpened,
    setIdStore,
}: any) {
    const { t } = useTranslation();
    const { isModalDissociateOpened } = useSnapshot(store);

    return (
        <React.Fragment>
            {e.selectedRow?.subRows !== undefined &&
            e.selectedRow.key_project !== undefined ? (
                <div className="d-flex justify-content-between mt-2">
                    <div>
                        <PencilIcon
                            onClick={() => {
                                setSelectedRows(e.selectedRow);
                                if (!isAddProject) {
                                    setIsAddProject(!isAddProject);
                                }
                            }}
                            height={25}
                            width={25}
                            style={{ cursor: "pointer" }}
                            className={`${classnames(
                                "pointer__clz cmn_icn_FRedit",
                                {
                                    "not-allowed-icon__clz": isProjectArchived,
                                }
                            )}`}
                        />
                    </div>
                    <div>
                        <TimePastSvgIcon
                            onClick={() => {
                                setSelectedApplicationKds([
                                    {
                                        [e.selectedRow.shopId]:
                                            e.selectedRow.ProjectId,
                                    },
                                ]);
                                setIsHistoryModalOpened(!isHistoryModalOpened);

                                setSelectedRows([e.selectedRow]);
                            }}
                            className="pointer__clz ml-2 cmn_icn_FRhistory"
                        />
                    </div>
                </div>
            ) : e.selectedRow[t("Designation")] !== "" &&
              e.selectedRow.id_boutique !== undefined ? (
                <div>
                    <SettingsIcon
                        className="pointer__clz ml-4"
                        onClick={() => {
                            setIsConfigurationModalOpened(true);
                            setSelectedRows(e.selectedRow);
                            
                            if (
                                e.selectedRow.id_boutique ===
                                "casa_italiaboutique1"
                            ) {
                                setIdStore(2);
                            } else if (
                                e.selectedRow.id_boutique ===
                                "casa_italiaboutique2"
                            ) {
                                setIdStore(3);
                            }
                        }}
                        height={25}
                        width={25}
                    />

                    <DisassociateIcon
                        height={40}
                        width={35}
                        fill="white"
                        style={{ position: "relative", bottom: "13px" }}
                        className={`${classnames("pointer__clz ml-2 mt-3", {
                            "not-allowed-icon__clz": isProjectArchived,
                        })}`}
                        onClick={() => {
                            setSelectedRows([e.selectedRow]);
                            setIsModalDissociateOpened(
                                !isModalDissociateOpened
                            );
                        }}
                    />
                </div>
            ) : (
                <SettingsIcon
                    className="pointer__clz ml-4"
                    onClick={() => {
                        setIsConfigurationModalOpened(true);
                        setSelectedRows(e.selectedRow);
                        
                        if (
                            e.selectedRow.id_boutique === "casa_italiaboutique1"
                        ) {
                            setIdStore(2);
                        } else if (
                            e.selectedRow.id_boutique === "casa_italiaboutique2"
                        ) {
                            setIdStore(3);
                        }
                    }}
                    height={22}
                    width={22}
                />
            )}
        </React.Fragment>
    );
}
