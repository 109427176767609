import React from "react";
import ReactSwitch from "react-switch";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import {
    computedStore,
    designStore,
    setIsHeaderActive,
    setIsInformationMessageActive,
    setIsUserAccountActive,
    setIsPageHeaderActive,
    setIsPageInformationMessageActive,
    setIsPageUserAccountActive,
} from "@store";

export function MainContent(): JSX.Element {
    const { t } = useTranslation();

    const {
        isUserAccountActive,
        isInformationMessageActive,
        isHeaderActive,
        activeSlideIndex,
        selectedLanguage,
        informationMessageItems,
        userAccountMessageItems,
    } = useSnapshot(designStore);
    const { elements } = useSnapshot(computedStore);

    function handleInformationMessageSwitchOnClickEvent() {
        if (
            [
                "loyaltyConsommation",
                "authenticationModes",
                "connection",
                "finalMessage",
            ].includes(elements[activeSlideIndex].name)
        ) {
            if (isHeaderActive === false) {
                setIsHeaderActive(true);
                setIsPageHeaderActive(true, elements[activeSlideIndex].name);
            }
            setIsInformationMessageActive(!isInformationMessageActive);
            setIsPageInformationMessageActive(
                !isInformationMessageActive,
                elements[activeSlideIndex].name
            );

            if (isHeaderActive === true) {
                setIsHeaderActive(false);
                setIsPageHeaderActive(false, elements[activeSlideIndex].name);
            }
        } else {
            if (isHeaderActive === false) {
                setIsHeaderActive(true);
                setIsPageHeaderActive(true, elements[activeSlideIndex].name);
            }

            setIsInformationMessageActive(!isInformationMessageActive);
            setIsPageInformationMessageActive(
                !isInformationMessageActive,
                elements[activeSlideIndex].name
            );

            if (
                isUserAccountActive === false &&
                !isInformationMessageActive === false
            ) {
                setIsHeaderActive(false);
                setIsPageHeaderActive(false, elements[activeSlideIndex].name);
            }
        }
    }

    function handleUserAccountSwitchOnClickEvent() {
        if (isHeaderActive === false) {
            setIsHeaderActive(true);
            setIsPageHeaderActive(true, elements[activeSlideIndex].name);
        }

        setIsUserAccountActive(!isUserAccountActive);
        setIsPageUserAccountActive(
            !isUserAccountActive,
            elements[activeSlideIndex].name
        );

        if (
            !isUserAccountActive === false &&
            isInformationMessageActive === false
        ) {
            setIsHeaderActive(false);
            setIsPageHeaderActive(false, elements[activeSlideIndex].name);
        }
    }

    return (
        <div
            className="mx-2 mt-4 rounded border"
            style={{ borderColor: "#CECECE" }}
        >
            <div
                className="text-left px-4 d-flex align-items-center cursor__clz"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                {t("Header")}
            </div>
            <div className="p-4" style={{ maxWidth: "400px" }}>
                {![
                    "authenticationModes",
                    "connection",
                    "loyaltyConsommation",
                    "finalMessage",
                ].includes(elements[activeSlideIndex].name) ? (
                    <div className="py-2 switch-items__clz cursor__clz">
                        <div>
                            {t("Display identified customer name")}
                            <div style={{ color: "#B7B7B7", fontSize: "13px" }}>
                                {userAccountMessageItems[selectedLanguage]
                                    ?.defaultContent !== undefined
                                    ? userAccountMessageItems[selectedLanguage]
                                          .defaultContent
                                    : null}
                            </div>
                        </div>
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isUserAccountActive}
                            onChange={handleUserAccountSwitchOnClickEvent}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                        />
                    </div>
                ) : null}

                <div className="py-2 switch-items__clz cursor__clz">
                    <div>
                        {t("Information message")}
                        <div style={{ color: "#B7B7B7", fontSize: "13px" }}>
                            {informationMessageItems[selectedLanguage]
                                ?.defaultContent !== undefined
                                ? informationMessageItems[selectedLanguage]
                                      .defaultContent
                                : null}
                        </div>
                    </div>
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isInformationMessageActive}
                        onChange={handleInformationMessageSwitchOnClickEvent}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
            </div>
        </div>
    );
}
