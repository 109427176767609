import React from "react";
import classnames from "classnames";
import ReactSwitch from "react-switch";
import { toast } from "react-toastify";
import {
    CheckboxIcon,
    EyeIcon,
    PencilIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { Tooltip } from "@mui/material";

import store, {
    setArchivedData,
    setGlobalDataIsUpdated,
    setIsArchiveModalOpened,
    setIsHistoryModalOpened,
    setSelectedPaymentMethods,
    setSelectedHistoryPaymentMethods,
    setIsInputUpdated,
    setIsActionsClicked,
    setIsEdited,
    setActions,
} from "../store";
import { store as storeData } from "@components/VerticalLayout/store";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import ErrorToast from "@components/Common/ErrorTost";
import { ArchivedValidationModal } from "@components/Common/ArchivedValidationModal";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";

type CustomIconsType = {
    selectedRows: any;
    selectedRowData: any;
    setPaymentMethod: Function;
    userData: {
        userId: number;
        shopId: number | string;
        franchiseId: string;
        operatorID: string;
    };
    setIsAddChoiceClicked: Function;
    setIsModalConfirmationOpened: Function;
    isModalConfirmationOpened: boolean;
    setSelectedRows: Function;
    isArchivedPaymentMethodsClicked: boolean;
};

export function CustomIcons({
    selectedRowData,
    setPaymentMethod,
    userData,
    setIsAddChoiceClicked,
    setIsModalConfirmationOpened,
    isModalConfirmationOpened,
    setSelectedRows,
    isArchivedPaymentMethodsClicked,
}: CustomIconsType) {
    const { t } = useTranslation();
    const { oneShop, shopID, operatorID } = useSnapshot(storeData);
    const {
        isInputUpdated,
        isArchiveModalOpened,
        isHistoryModalOpened,
        shopsPaymentMethodsData,
        listFranchiseArchived,
        isActionsClicked,
        isEdited,
        modify,
    } = useSnapshot(store);
    const [choiceState, setPaymentMethodState] = React.useState<boolean>(
        selectedRowData.selectedRow.isActive
    );
    const [isValidateClicked, setIsValidateClicked] = React.useState<boolean>(
        false
    );
    const [
        isArchivedValidationModalOpened,
        setIsArchivedValidationModalOpened,
    ] = React.useState<boolean>(false);

    function handleTrashButtonClicked(selectedRow: any) {
        let idsAssociated: any = [];
        shopsPaymentMethodsData.forEach((el: any) => {
            el.subRows.forEach((element: any) => {
                idsAssociated.push(element.id);
            });
        });

        if (
            selectedRow.idShop === "0" &&
            idsAssociated.includes(selectedRow.id)
        ) {
            ErrorToast(
                `${t(
                    "Please note that you cannot archive an item assigned to stores."
                )!}`
            );
        } else {
            setIsArchivedValidationModalOpened(
                !isArchivedValidationModalOpened
            );
        }
    }

    async function handleStateChange(state: any) {
        let dataConfig = {
            userId: userData.userId,
            shopId: [selectedRowData.selectedRow.idShop],
            franchiseId: userData.franchiseId,
            operatorId: operatorID,
            data: {
                [selectedRowData.selectedRow.id]: {
                    designation:
                        selectedRowData.selectedRow[
                            t("Type of payment method")
                        ],
                    currency: selectedRowData.selectedRow.Devise,
                    display: selectedRowData.selectedRow[t("Display name")],
                    saleSupport:
                        selectedRowData.selectedRow[t("Support de vente")],
                    shopId: userData.shopId,
                    country: selectedRowData.selectedRow[t("Country")],
                    tag: selectedRowData.selectedRow.Tag,
                    isActive: state,
                    isArchived: selectedRowData.selectedRow.isArchived,
                },
            },
        };
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod`;

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataConfig),
        };
        mutate(
            apiUrl,
            await fetch(apiUrl, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }

                    toast.success(
                        state === true
                            ? `${t(
                                  "Payment method has been successfully activated"
                              )}`
                            : `${t(
                                  "Payment method has been successfully deactivated"
                              )}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setGlobalDataIsUpdated(true);
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }

    async function handleActivePaymentMethod(selectedRowData: any) {
        let dataConfig = {
            userId: userData.userId,
            shopId: [selectedRowData.selectedRow.idShop],
            franchiseId: userData.franchiseId,
            operatorId: operatorID,
            data: {
                [selectedRowData.selectedRow.id]: {
                    designation:
                        selectedRowData.selectedRow[
                            t("Type of payment method")
                        ],
                    currency: selectedRowData.selectedRow.Devise,
                    display: selectedRowData.selectedRow[t("Display name")],
                    shopId: selectedRowData.selectedRow.idShop,
                    saleSupport:
                        selectedRowData.selectedRow[t("Support de vente")],
                    country: selectedRowData.selectedRow[t("Country")],
                    tag: selectedRowData.selectedRow.Tag,
                    isActive: selectedRowData.selectedRow.isActive,
                    isArchived: false,
                },
            },
        };
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod`;

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataConfig),
        };
        mutate(
            apiUrl,
            await fetch(apiUrl, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }
                    toast.success(
                        `${t("The Payment method is successfully unarchived")}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setGlobalDataIsUpdated(true);
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }

    async function handleArchivePaymentMethod(element: any) {
        setArchivedData(selectedRowData);
        setIsArchiveModalOpened!(!isArchiveModalOpened);
        let dataConfig = {
            userId: userData.userId,
            shopId: [element.idShop],
            franchiseId: userData.franchiseId,
            operatorId: operatorID,
            data: {
                [element.id]: {
                    designation: element[t("Type of payment method")],
                    currency: element.Devise,
                    display: selectedRowData.selectedRow[t("Display name")],
                    saleSupport:
                        selectedRowData.selectedRow[t("Support de vente")],
                    shopId: element.idShop,
                    country: element[t("Country")],
                    tag: element.Tag,
                    isActive: element.isActive,
                    isArchived: true,
                },
            },
        };
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod`;

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataConfig),
        };
        mutate(
            apiUrl,
            await fetch(apiUrl, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }
                    toast.success(
                        `${t("The Payment method is successfully archived")}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setGlobalDataIsUpdated(true);
                    setIsValidateClicked(false);
                    setIsArchivedValidationModalOpened(false);
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }

    let dataArchive: any[] = [];

    if (!oneShop) {
        dataArchive = ArchivedPaymentMethod();
    } else {
        dataArchive = ArchivedPaymentMethodFranchise();
    }

    function ArchivedPaymentMethodFranchise() {
        let idsAssociated: any[] = [];
        if (shopsPaymentMethodsData !== undefined) {
            shopsPaymentMethodsData.forEach((element: any) => {
                idsAssociated.push(element.id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function ArchivedPaymentMethod() {
        let idsAssociated: any[] = [];
        if (shopsPaymentMethodsData !== undefined) {
            Object.values(shopsPaymentMethodsData).forEach((element: any) => {
                element?.subRows.forEach((el: any) => {
                    idsAssociated.push(el.id);
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    React.useEffect(() => {
        if (isValidateClicked) {
            handleArchivePaymentMethod(selectedRowData.selectedRow);
        }
        // eslint-disable-next-line
    }, [isValidateClicked, selectedRowData]);

    return (
        <React.Fragment>
            <div className="d-flex justify-content-around">
                {modify === true ? (
                    <>
                        <Tooltip
                            title={
                                isArchivedPaymentMethodsClicked ? "" : t("Edit")
                            }
                        >
                            <div>
                                <PencilIcon
                                    onClick={() => {
                                        if (isEdited === true) {
                                            setIsActionsClicked(
                                                !isActionsClicked
                                            );
                                            setActions(() => {
                                                setIsEdited(false);
                                                clearData();
                                                clearMultiCustomSelectionData();
                                                setPaymentMethod(
                                                    selectedRowData.selectedRow
                                                );
                                            });
                                        } else {
                                            setPaymentMethod(
                                                selectedRowData.selectedRow
                                            );
                                            setIsAddChoiceClicked(true);
                                            setIsInputUpdated(!isInputUpdated);
                                        }
                                    }}
                                    height={25}
                                    width={25}
                                    style={{ cursor: "pointer" }}
                                    className={`${classnames(
                                        "pointer__clz cmn_icn_FRedit",
                                        {
                                            "not-allowed-icon__clz": isArchivedPaymentMethodsClicked,
                                        },
                                        oneShop ? "mr-2" : "ml-3"
                                    )}`}
                                />
                            </div>
                        </Tooltip>
                        {selectedRowData.selectedRow.idShop !== "0" ? (
                            <Tooltip
                                title={
                                    isArchivedPaymentMethodsClicked
                                        ? ""
                                        : choiceState === false
                                        ? t("Activate")
                                        : t("Unactivate")
                                }
                            >
                                <div>
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={choiceState}
                                        onChange={async (e: boolean) => {
                                            setPaymentMethodState(!choiceState);
                                            handleStateChange(e);
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                        className={`${classnames(
                                            "cmn_icn_BTenableDisable",
                                            {
                                                "not-allowed-icon__clz": isArchivedPaymentMethodsClicked,
                                            },
                                            oneShop ? "mr-2" : "ml-3"
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                        ) : null}

                        {!isArchivedPaymentMethodsClicked ? (
                            <Tooltip title={t("Archive")}>
                                <div>
                                    <TrashIcon
                                        onClick={() => {
                                            if (isEdited === true) {
                                                setIsActionsClicked(
                                                    !isActionsClicked
                                                );
                                                setActions(() => {
                                                    setIsEdited(false);
                                                    handleTrashButtonClicked(
                                                        selectedRowData.selectedRow
                                                    );
                                                    setPaymentMethod([]);
                                                    setIsAddChoiceClicked(
                                                        false
                                                    );
                                                    clearData();
                                                    clearMultiCustomSelectionData();
                                                });
                                            } else {
                                                handleTrashButtonClicked(
                                                    selectedRowData.selectedRow
                                                );
                                            }
                                        }}
                                        height={25}
                                        width={25}
                                        fill="red"
                                        style={{ cursor: "pointer" }}
                                        className={`${classnames(
                                            "pointer__clz  cmn_icn_FRarchived",
                                            {
                                                "not-allowed-icon__clz": isArchivedPaymentMethodsClicked,
                                            },
                                            oneShop ? "mr-2" : "ml-3"
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                        ) : (
                            <Tooltip
                                title={
                                    shopID !== "0" &&
                                    listFranchiseArchived.find(
                                        (el: any) =>
                                            el.id ===
                                            selectedRowData.selectedRow.id
                                    ) !== undefined
                                        ? ""
                                        : t("Unarchive")
                                }
                            >
                                <div>
                                    <EyeIcon
                                        height={25}
                                        width={25}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setSelectedRows([
                                                selectedRowData.selectedRow,
                                            ]);
                                            if (
                                                dataArchive.includes(
                                                    selectedRowData.selectedRow
                                                        .id
                                                ) &&
                                                !oneShop
                                            ) {
                                                setIsModalConfirmationOpened(
                                                    !isModalConfirmationOpened
                                                );
                                            } else {
                                                handleActivePaymentMethod(
                                                    selectedRowData
                                                );
                                            }
                                        }}
                                        className={`${classnames(
                                            "pointer__clz cmn_icn_FRunarchive",
                                            {
                                                "not-allowed-icon__clz":
                                                    shopID !== "0" &&
                                                    listFranchiseArchived.find(
                                                        (el: any) =>
                                                            el.id ===
                                                            selectedRowData
                                                                .selectedRow.id
                                                    ) !== undefined,
                                            },
                                            oneShop ? "mr-2" : "ml-3"
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                        )}
                    </>
                ) : null}
                <Tooltip title={t("Consult history")}>
                    <div>
                        <TimePastSvgIcon
                            style={{
                                cursor: "pointer",
                            }}
                            className={`${classnames(
                                "pointer__clz  cmn_icn_FRhistory",

                                oneShop ? "mr-2" : "ml-3"
                            )}`}
                            onClick={(e: any) => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsHistoryModalOpened(
                                            !isHistoryModalOpened
                                        );
                                        setIsEdited(false);
                                        setPaymentMethod([]);
                                        setIsAddChoiceClicked(false);
                                        clearData();
                                        clearMultiCustomSelectionData();
                                    });
                                    setSelectedHistoryPaymentMethods(
                                        selectedRowData.selectedRow
                                    );

                                    setSelectedPaymentMethods([
                                        {
                                            [selectedRowData.selectedRow
                                                .idShop]:
                                                selectedRowData.selectedRow.id,
                                        },
                                    ]);
                                } else {
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );
                                    setSelectedHistoryPaymentMethods(
                                        selectedRowData.selectedRow
                                    );

                                    setSelectedPaymentMethods([
                                        {
                                            [selectedRowData.selectedRow
                                                .idShop]:
                                                selectedRowData.selectedRow.id,
                                        },
                                    ]);
                                }
                            }}
                        />
                    </div>
                </Tooltip>
            </div>
            {isArchivedValidationModalOpened ? (
                <ArchivedValidationModal
                    text={t(
                        "Attention, this (these) payment method can be used by other modules.\n\nDo you want to confirm the archiving of the selected of payment method(s)"
                    )}
                    isModalOpened={isArchivedValidationModalOpened}
                    setIsModalOpened={setIsArchivedValidationModalOpened}
                    setIsValidateClicked={setIsValidateClicked}
                />
            ) : null}
        </React.Fragment>
    );
}
