import React from "react";
import { useSnapshot } from "valtio";
import { t } from "i18next";

import {
    generalConfigStore,
    setIsPageSkipped,
    setIsSalesMethodsSkipped,
} from "@store";

import { SwitchWrapper } from "@components/Common/SwitchWrapper";

export function SkipThisStepContainer(): JSX.Element {
    const {
        project: { template },
    } = useSnapshot(generalConfigStore);

    let isSalesMethodsSkipped: boolean;
    if ((template.pages.ways.salesMethods as PageType) !== undefined) {
        isSalesMethodsSkipped = (template.pages.ways.salesMethods as PageType)
            .skipped as boolean;
    } else {
        isSalesMethodsSkipped = (template.pages.elements
            .salesMethods as PageType).skipped as boolean;
    }

    const [skipStep, setSkipStep] = React.useState<boolean>(
        isSalesMethodsSkipped
    );

    function handleSkipThisStepOnClickEvent() {
        setSkipStep(!skipStep);
        setIsPageSkipped("salesMethods", !skipStep);
        setIsSalesMethodsSkipped(!isSalesMethodsSkipped);
    }

    return (
        <div
            className="d-flex align-items-center justify-content-start cursor__clz"
            style={{ gap: "20px" }}
        >
            <div
                style={{
                    font: "normal normal 600 24px/32px Nunito Sans",
                }}
            >
                {t("Do not offer this step in the customer ways")}
            </div>
            <div className="Cmn_icn_proposeStageRoute">
                <SwitchWrapper
                    onChange={handleSkipThisStepOnClickEvent}
                    checked={skipStep}
                />
            </div>
        </div>
    );
}
