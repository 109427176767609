import React from "react";
import { toast } from "react-toastify";
import { ShieldExclamationIcon } from "@aureskonnect/react-ui";

export default function ErrorToast(message: string) {
    return toast.error(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        theme: "colored",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        icon: <ShieldExclamationIcon width={25} height={25} fill="white" />,
    });
}
