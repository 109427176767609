import React from "react";
import { useTranslation } from "react-i18next";
import { AvForm, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";

type FormaAlertType = {
    isEmailInclude: boolean;
    alertForm: any;
    setForm: Function;
    setIsEmailInclude: Function;
    alertMeans: any;
};
export function AlertForm({
    isEmailInclude,
    alertForm,
    setForm,
    setIsEmailInclude,
    alertMeans,
}: FormaAlertType) {
    const { t } = useTranslation();
    React.useEffect(() => {
        setIsEmailInclude(alertMeans.includes("E-mail"));
        setForm(alertForm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alertMeans]);
    return (
        <div className="align-items-center pl-5" >
            <AvForm
                model={{
                    form: alertForm,
                    checkItOut: true,
                }}
            >
                <AvRadioGroup
                    name="form"
                    required
                    inline
                    errorMessage="Pick one!"
                    disabled={!isEmailInclude}
                >
                    <AvRadio
                        id="XLS"
                        label={`${t("XLS")}`}
                        value="XLS"
                        name="radio"
                        checked={alertForm === "XLS"}
                        onChange={() => {
                            setForm("XLS");
                        }}
                    />

                    <AvRadio
                        id="twoRadio"
                        label={`${t("Word")}`}
                        name="radio"
                        value="Word"
                        checked={alertForm === "Word"}
                        onChange={() => {
                            setForm("Word");
                        }}
                    />

                    <AvRadio
                        id="threeRadio"
                        label={`${t("XML")}`}
                        name="radio"
                        value="XML"
                        checked={alertForm === "XML"}
                        onChange={() => {
                            setForm("XML");
                        }}
                    />

                    <AvRadio
                        id="threeRadio"
                        label={`${t("Txt")}`}
                        name="radio"
                        value="Txt"
                        checked={alertForm === "Txt"}
                        onChange={() => {
                            setForm("Txt");
                        }}
                    />
                </AvRadioGroup>
            </AvForm>
        </div>
    );
}
