import React from "react";
import ReactSwitch from "react-switch";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { setActiveLogo } from "../store";

type CustomIconsAssociateModalType = {
    element: any;
    isOneShopClicked: boolean;
    selectedLogo: any;
};

export function CustomIconsAssociateModal({
    element,
    isOneShopClicked,
    selectedLogo,
}: CustomIconsAssociateModalType) {
    const { t } = useTranslation();
    const [logoState, setLogoState] = React.useState<boolean>(
        isOneShopClicked === true &&
            selectedLogo.findIndex(
                (el: any) => el.Id === element.selectedRow.Id
            ) !== -1
            ? selectedLogo[
                  selectedLogo.findIndex(
                      (el: any) => el.Id === element.selectedRow.Id
                  )
              ].isActive
            : true
    );

    React.useEffect(() => {
        setActiveLogo({
            id: element.selectedRow.Id,
            state: logoState,
        });
        // eslint-disable-next-line
    }, [logoState]);

    return (
        <React.Fragment>
            {element.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    <Tooltip
                        title={
                            logoState === false
                                ? t("Activate")
                                : t("Unactivate")
                        }
                    >
                        <div>
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={logoState}
                                onChange={() => {
                                    setLogoState(!logoState);
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={50}
                                height={20}
                                className="lgo_icn_AFenableDisable"
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
        </React.Fragment>
    );
}
