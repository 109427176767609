import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { CardBody } from "reactstrap";
import {
    StyledIconButton,
    PlusIcon,
    StyledButtonWithIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";

import { store, setIsAddPeripheralClicked } from "../../../store";
import { store as userStore } from "@components/VerticalLayout/store";

import { AlertDeletePeripheralMessage } from "@components/Common/AlertDeletePeripheralMessage";
import { ConfirmationDeleteMessage } from "@components/Common/ConfirmationDeleteMessage";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import { ConfirmationMessageEditPeripheral } from "@components/Common/ConfirmationMessageEditPeripheral";
import MoneticsApplicationForm from ".";
import PrepheralCardFooter from "../PrepheralCardFooter";
import DropDownAction from "../../../DropDownAction";
import MoneticsActionColumn from "./MoneticsActionColumn";

type CustomColumnProps = {
    indexOFColumn: number;
    columnName: string;
    customJsx: React.ReactNode;
};

type DeviceAssociateModalType = {
    setIsDeviceAssociateModalOpened: Function;
    isDeviceAssociateModalOpened: boolean;
    rowData: any;
    dataIsUpdated: boolean | number;
    setDataIsUpdated: Function;
};

export function Monetics({
    setIsDeviceAssociateModalOpened,
    isDeviceAssociateModalOpened,
    rowData,
    dataIsUpdated,
    setDataIsUpdated,
}: DeviceAssociateModalType) {
    const { t } = useTranslation();
    const { isAddPeripheralClicked } = useSnapshot(store);
    const { oneShop, userID, franchiseID, shopID } = useSnapshot(userStore);
    const shopId = oneShop ? shopID : rowData.shopId;

    const [
        isAddNewMoneticsButtonClicked,
        setIsAddNewMoneticsButtonClicked,
    ] = useState(false);
    const [isModalOpened, setIsModalOpened] = useState(false);
    const [data, setData] = useState<any>({});
    const [
        isConfirmationEditRunning,
        setIsConfirmationEditRunning,
    ] = React.useState<boolean>(false);
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const [isEdited, setIsEdited] = React.useState<boolean>(false);
    const [editedData, setEditedData] = React.useState<any>([]);
    // const [moneticId, setMoneticId] = React.useState<string>("");
    const moneticId:string=""
    const [dataMoneticIsUpdated, setDataMoneticIsUpdated] = React.useState<
        boolean | number
    >(false);
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [
        isModalDeleteAssociatePeripheralOpened,
        setIsModalDeleteAssociatePeripheralOpened,
    ] = React.useState<boolean>(false);
    const [selectMark, setSelectMark] = React.useState<boolean>(false);
    const [inputDesignation, setInputDesignation] = React.useState<boolean>(
        false
    );
    const [InputIp, setInputIp] = React.useState<boolean>(false);
    const [ipExist, setIpExist] = React.useState<boolean>(false);

    let arrayOfCustomColumns: CustomColumnProps[] = [];
    function customJsxComponent(e: any) {
        return (
            (e.selectedRow.original[t("Application")] !== "" ||
                e.selectedRow.original[t("Peripheral")] !== "") && (
                <div className="w-100 d-flex justify-content-center">
                    <DropDownAction
                        element={e}
                        setEditedData={setEditedData}
                        setInputIp={setInputIp}
                    />
                </div>
            )
        );
    }
    arrayOfCustomColumns.push({
        indexOFColumn: 999,
        columnName: t("Actions"),
        customJsx: (e: any) => (
            <MoneticsActionColumn
                rowData={rowData}
                element={e.selectedRow}
                dataIsMoneticUpdated={dataMoneticIsUpdated}
            />
        ),
    });

    arrayOfCustomColumns.push({
        indexOFColumn: 0,
        columnName: t("Designation"),
        customJsx: (e: any) => {
            return <div>{e.selectedRow["Désignation"]}</div>;
        },
    });
    return (
        <React.Fragment>
            <CardBody
                className="p-1"
                style={{
                    maxHeight: isAddPeripheralClicked ? "initial" : "720px",
                }}
            >
                <div
                    style={{
                        display: "grid",
                        minHeight: "650px",
                        gridTemplateColumns: isAddPeripheralClicked
                            ? "2fr 1fr "
                            : "auto",
                        gridColumnGap: "4px",
                        marginBottom: isAddPeripheralClicked ? "70px" : "0px",
                        border: 0,
                    }}
                >
                    <DynamicTable
                        requestHeader={{ "Accept-Language": i18nextLng }}
                        url={`${
                            process.env.REACT_APP_API_V2_URL
                        }/settings/monetics?userId=${userID}&shopId=${
                            oneShop ? shopID : rowData.shopId
                        }&franchiseId=${franchiseID}&typeDisplay=notArchived`}
                        showGlobalFilter
                        filterActive={filterActive}
                        setDataIsUpdated={setDataMoneticIsUpdated}
                        dataIsUpdated={dataMoneticIsUpdated}
                        setLocalFilterActive={setLocalFilterActive}
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        actionColumn={customJsxComponent}
                        minHeight="520px"
                        maxHeight="520px"
                        setData={setData}
                        customJsxSideFilterButton={
                            isAddPeripheralClicked !== true ? (
                                <div className="d-flex justify-content-start align-items-center">
                                    <span
                                        onClick={() => {
                                            setEditedData([]);
                                            setIsAddPeripheralClicked(
                                                !isAddPeripheralClicked
                                            );
                                        }}
                                    >
                                        <StyledButtonWithIcon
                                            icon="PlusIcon"
                                            iconPosition="left"
                                            className="w-100"
                                            rounded
                                            style={{ height: "0px" }}
                                            variant="primary"
                                        >
                                            {t("Add device")}
                                        </StyledButtonWithIcon>
                                    </span>
                                </div>
                            ) : (
                                <div className="d-flex justify-content-start">
                                    <StyledIconButton
                                        icon="PlusIcon"
                                        className="bg-dark"
                                        rounded
                                        disabled={
                                            editedData.length === 0
                                                ? false
                                                : true
                                        }
                                        onClick={() => {
                                            if (isEdited === true)
                                                setIsInInitializationModalOpened(
                                                    true
                                                );
                                            else {
                                                setInputDesignation(false);
                                                setSelectMark(false);
                                            }
                                        }}
                                    >
                                        <PlusIcon
                                            height={20}
                                            width={20}
                                            fill="white"
                                        />
                                    </StyledIconButton>
                                </div>
                            )
                        }
                    />
                    {isAddPeripheralClicked ? (
                        <MoneticsApplicationForm
                            isAddMoneticsClicked={isAddPeripheralClicked}
                            setIsAddMoneticsClicked={setIsAddPeripheralClicked}
                            idShop={rowData.shopId}
                            setDataIsUpdated={setDataMoneticIsUpdated}
                            setIsAddNewMoneticsButtonClicked={
                                setIsAddNewMoneticsButtonClicked
                            }
                            isAddNewMoneticsButtonClicked={
                                isAddNewMoneticsButtonClicked
                            }
                            setEditedData={setEditedData}
                            editedData={editedData}
                            isEdited={isEdited}
                            setIsEdited={setIsEdited}
                            selectMark={selectMark}
                            setSelectMark={setSelectMark}
                            inputDesignation={inputDesignation}
                            setInputDesignation={setInputDesignation}
                            InputIp={InputIp}
                            setInputIp={setInputIp}
                            tableData={data}
                            shopIds={[shopId]}
                            setIpExist={setIpExist}
                            ipExist={ipExist}
                            isConsult={false}
                            setIsConsult={() => {}}
                        />
                    ) : null}
                </div>
                {isModalDeleteAssociatePeripheralOpened === false ? (
                    <ConfirmationDeleteMessage
                        text={t("Please confirm deletion of this device")}
                        isModalOpened={isModalOpened}
                        setIsModalOpened={setIsModalOpened}
                        setDataIsUpdated={setDataMoneticIsUpdated}
                        apiUrl={`${process.env.REACT_APP_API_V2_URL}/settings/monetics?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&id=${moneticId}&isArchived=true`}
                        data={{
                            userId: userID,
                            shopId: shopId,
                            franchiseId: franchiseID,
                        }}
                        message={t("The device was successfully removed")}
                        setGlobalDataIsUpdated={setDataIsUpdated}
                    />
                ) : (
                    <AlertDeletePeripheralMessage
                        text={t(
                            "This device is associated with one or more applications! Unable to perform delete action"
                        )}
                        isModalDeleteAssociatePeripheralOpened={
                            isModalDeleteAssociatePeripheralOpened
                        }
                        setIsModalDeleteAssociatePeripheralOpened={
                            setIsModalDeleteAssociatePeripheralOpened
                        }
                    />
                )}

                {isInInitializationModalOpened === true ? (
                    <ConfirmationInitializationMessage
                        isModalOpened={isInInitializationModalOpened}
                        setIsModalOpened={setIsInInitializationModalOpened}
                        setIsClicked={setIsAddNewMoneticsButtonClicked}
                        isClicked={isAddNewMoneticsButtonClicked}
                        setIsEdited={setIsEdited}
                    />
                ) : null}
            </CardBody>
            {!isAddPeripheralClicked ? (
                <PrepheralCardFooter
                    dataIsUpdated={dataIsUpdated}
                    setIsEdited={setIsEdited}
                    setIsDeviceAssociateModalOpened={
                        setIsDeviceAssociateModalOpened
                    }
                    isDeviceAssociateModalOpened={isDeviceAssociateModalOpened}
                    rowData={rowData}
                    setDataIsUpdated={setDataIsUpdated}
                />
            ) : null}
            {isConfirmationEditRunning === true ? (
                <ConfirmationMessageEditPeripheral
                    isModalOpened={isConfirmationEditRunning}
                    setIsModalOpened={setIsConfirmationEditRunning}
                    setCancel={setIsAddPeripheralClicked}
                    archive={false}
                />
            ) : null}
        </React.Fragment>
    );
}
