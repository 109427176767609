import React, { ReactElement } from "react";
import "./style.css";

interface BackgroundI {
    onClick: Function;
    children: any;
}

const Background = ({
    children,
    onClick,
}: BackgroundI): ReactElement<BackgroundI, "modal-background"> => (
    <div className="background">{children}</div>
);

const ClickCapturer = ({ onClick, children }: any) => (
    <div className="click-capturer" onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClick} />
        {children}
    </div>
);

const CloseButton = ({ onClick }: any) => (
    <button type="button" onClick={onClick} className="close">
        {""}
    </button>
);

interface ModalI {
    children: any;
    close: Function;
}

export const Modal = ({ children, close }: ModalI) => {
    return (
        <Background onClick={close}>
            <ClickCapturer onClick={close}>{children}</ClickCapturer>
        </Background>
    );
};
