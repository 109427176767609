import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledLabel, CheckboxIcon } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useSnapshot } from "valtio";
import { mutate } from "swr";
import { toast } from "react-toastify";

import {
    store as generalSettingStore,
    setIsValidateArchiveOpened,
    setIsValidateShopArchiveOpened,
} from "./store";
import { store } from "@components/VerticalLayout/store";

import { capitalize } from "@helpers/general";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationArchiveExceptionalHoursType = {
    selectedRows: any;
    setDataIsUpdated: Function;
    dataIsUpdated: boolean | number;
};

export function ConfirmationArchiveExceptionalHours({
    selectedRows,
    setDataIsUpdated,
    dataIsUpdated,
}: ConfirmationArchiveExceptionalHoursType) {
    const { t } = useTranslation();
    const {
        isValidateArchiveOpened,
        isValidateShopArchiveOpened,
    } = useSnapshot(generalSettingStore);
    const { userID, franchiseID, shopID, operatorID } = useSnapshot(store);

    const [error, setError] = React.useState<boolean>(false);

    async function archiveFranchiseExceptionalHours() {
        toast.dismiss();
        let localData: any = {};
        selectedRows.forEach(async (el: any) => {
            let newHours: any[] = [];
            if (el.subRows !== undefined) {
                el.subRows.forEach((element: any) => {
                    newHours.push({
                        startTime: element[t("Start hour")],
                        endTime: element[t("End hour")],
                        overLappedStart: false,
                        overLappedEnd: false,
                        inputEndTime: false,
                        inputStartTime: false,
                    });
                });
                Object.assign(localData, {
                    [el.Id]: {
                        id: el.Id,
                        designation: el[t("Designation")],
                        state: el.state === true ? "Opened" : "Closed",
                        startDate: el.startDate,
                        endDate: el.endDate,
                        hours: newHours,
                        isArchived: true,
                        shopId: el.shopId,
                        recurrence: el[t("Recurrence")],
                        endDate_recurrence: el.endDate_recurrence,
                        recurrenceDays: el.recurrenceDays,
                    },
                });
            }
        });
        let dataExceptionalHours = {
            userId: userID,
            shopId: [shopID],
            franchiseId: franchiseID,
            operatorId: operatorID,
            isShop: false,
            data: localData,
        };

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/exceptionalHours`;
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(dataExceptionalHours),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                "The exceptional schedule was successfully archived"
                            )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated(!dataIsUpdated);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    const getArrayOfRows = () => {
        let arrayOfRows: any[] = [];
        selectedRows.forEach(async (el: any) => {
            if (el[t("Shop")] === "") {
                arrayOfRows.push(el);
            }
        });
        return arrayOfRows;
    };

    async function archiveShopExceptionalHours() {
        toast.dismiss();
        let localData: any = [];
        let arrayOfRows = getArrayOfRows();
        arrayOfRows.forEach(async (el: any) => {
            const id = el.Id;
            let hours: any[] = [];
            if (el.subRows !== undefined) {
                el.subRows.forEach((element: any) => {
                    hours.push({
                        startTime: element[t("Start hour")],
                        endTime: element[t("End hour")],
                        overLappedStart: false,
                        overLappedEnd: false,
                        inputEndTime: false,
                        inputStartTime: false,
                    });
                });
                let dataExceptionalHours = {
                    userId: userID,
                    shopId: [el.shopId],
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    isShop: true,
                    data: {
                        [id]: {
                            id: id,
                            designation: el[t("Designation")],
                            state: el.state,
                            startDate: el.startDate,
                            endDate: el.endDate,
                            hours: hours,
                            isArchived: true,
                            shopId: el.shopId,
                            recurrence: el[t("Recurrence")],
                            endDate_recurrence: el.endDate_recurrence,
                            recurrenceDays: el.recurrenceDays,
                        },
                    },
                };
                const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/exceptionalHours`;
                localData.push(
                    fetch(apiUrlAdd, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(dataExceptionalHours),
                    }).then((response) => response.json())
                );
            }
        });
        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/exceptionalHours`,
                await Promise.all(localData).then((result) => {})
            );
        } catch (e) {
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setError(true);
        }
        if (error === false) {
            toast.success(
                `${t("The exceptional schedule was successfully archived")}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
            setDataIsUpdated!(true);
        }
    }

    return (
        <StyledModal
            toggle={true}
            isOpen={isValidateArchiveOpened || isValidateShopArchiveOpened}
            centered
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsValidateArchiveOpened(false);
                    setIsValidateShopArchiveOpened(false);
                }}
            >
                <span>{capitalize(t("Alert"))}</span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        "Attention, these Exceptional(s) Hour(s) can be used by other modules.\n Do you want to confirm the archiving of the selected Exceptional(s) Hour(s)"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <React.Fragment>
                    <CustomSecondaryColorButton
                        outline
                        variant="light"
                        onClick={() => {
                            setIsValidateArchiveOpened(false);
                            setIsValidateShopArchiveOpened(false);
                        }}
                        rounded
                    >
                        {t("Cancel")}
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        className="mr-2"
                        variant="primary"
                        onClick={() => {
                            isValidateArchiveOpened
                                ? archiveFranchiseExceptionalHours()
                                : archiveShopExceptionalHours();
                            setIsValidateArchiveOpened(false);
                            setIsValidateShopArchiveOpened(false);
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </React.Fragment>
            </ModalFooter>
        </StyledModal>
    );
}
