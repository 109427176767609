import { getImageContentById } from "@helpers/general";
import { generalConfigStore } from "@store";
import React from "react";
import { useSnapshot } from "valtio";
export function LogoWrapper({
    width,
    height,
}: {
    width: number;
    height: number;
}): JSX.Element {
    const {
        defaultLanguage,
        project: { template, files: images },
    } = useSnapshot(generalConfigStore);
    const logo = (template.content.generalDesign as GeneralDesignType).logo;
    const imageContent = getImageContentById(
        logo.id,
        images,
        defaultLanguage.name
    );

    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    return (
        <React.Fragment>
            <img
                src={imageSrc}
                alt="logo"
                style={{ width: width, height: height }}
            />
        </React.Fragment>
    );
}
