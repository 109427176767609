import React, { useEffect } from "react";
import {
    StyledLabel,
    StyledSelectInput,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { AvGroup, AvField } from "availity-reactstrap-validation";
import classnames from "classnames";
import { useSnapshot } from "valtio";
import { store as userStore } from "@components/VerticalLayout/store";

import { ipMatchWord } from "@constants/index";
import { setMoneticData, store } from "./store";
import Switch from "react-switch";

import { PORTS, SPEEDS } from "../../../../constants";
import { checkIpIfExist } from "@helpers/general";
import { AdyenConfiguration } from "./AdyenConfig";
import { ValinaSwissConfig } from "./ValinaSwissConfig";

type MoneticsType = {
    editedData: any;
    setIsEdited: Function;
    selectedRows: any;
};

export function MoneticPeripheralConfigure({
    editedData,
    setIsEdited,
    selectedRows,
}: MoneticsType) {
    const { t } = useTranslation();

    const { shopID, oneShop, resourceIp } = useSnapshot(userStore);
    const {
        designation,
        mark,
        reference,
        note,
        merchantCode,
        paymentType,
        url,
        port,
        selectMark,
        speed,
        ipAddress,
        inputIp,
        inputPort,
        InputCode,
        InputUrl,
        inputDesignation,
        isNameExist,
        portSelectInput,
        speedSelectInput,
        SelectInput,
        kiss,
        typeConfig,
        typeTerminal,
        timeout,
        inputTimeout,
        paymentMethod,
        inputPaymentMethod,
        inputSetupType,
        inputTerminalType,
        ipExist,
        emptyIp,
        adyenIpAddress,
    } = useSnapshot(store);

    const numberMatchWord = new RegExp(/^[1-9]\d*$/);
    const notStartWithSpaceRegExp = new RegExp(/^\S/);
    const valinaReferencesArray: {
        label: string;
        value: string;
    }[] = ["EXEMPLE 1", "EXEMPLE 2", "EXEMPLE 3", "EXEMPLE 4"].map(
        (element: string) => {
            return {
                label: element,
                value: element,
            };
        }
    );
    const neptingReferencesArray: {
        label: string;
        value: string;
    }[] = ["S300", "IM 20", "IM 30", "Q25"].map((element: string) => {
        return {
            label: element,
            value: element,
        };
    });
    const paramsTypes: {
        label: string;
        value: string;
    }[] = [t("Check"), t("Ticket restaurant"), t("Bank card")].map(
        (element: string) => {
            return {
                label: element,
                value: element,
            };
        }
    );
    const typeOfConfig: {
        label: string;
        value: string;
    }[] = ["ERT 45", "ERT 46", "ERT 10", "Autre"].map((element: string) => {
        return {
            label: element,
            value: element,
        };
    });
    const payment_method: {
        label: string;
        value: string;
    }[] = [
        { label: t("By default"), value: "1" },
        { label: t("Without contact"), value: "3" },
        { label: t("With contact"), value: "5" },
    ];
    const typeOfTerminal: {
        label: string;
        value: string;
    }[] = ["25", "22", "Autre"].map((element: string) => {
        return {
            label: element,
            value: element,
        };
    });
    const ports: LocalOptionsType[] = PORTS.map((element: string) => {
        return {
            label: element,
            value: element,
        };
    });

    const speeds: LocalOptionsType[] = SPEEDS.map((element: number) => {
        return {
            label: element,
            value: element,
        };
    });
    const shopId = oneShop ? shopID : selectedRows?.shopId;

    useEffect(() => {
        if (editedData.length !== 0) {
            setMoneticData(editedData[t("Peripheral")], "designation");
            setMoneticData(editedData.reference, "reference");
            setMoneticData(editedData.mark, "mark");

            setMoneticData(editedData.note, "note");
            setMoneticData(editedData.peripheralId, "identifiant");
            setMoneticData(editedData.tpe_name, "nomTpe");
            setMoneticData(editedData.ip, "ipAddress");
            setMoneticData(editedData.merchant_code, "merchantCode");
            setMoneticData(editedData.port, "port");
            setMoneticData(editedData.payment_type, "paymentType");
            setMoneticData(editedData.device_code, "deviceCode");
            setMoneticData(editedData.setup_type, "typeConfig");
            setMoneticData(editedData.terminal_type, "typeTerminal");
            setMoneticData(editedData.url, "url");
            setMoneticData(editedData.speed, "speed");
            setMoneticData(editedData.payment_method, "paymentMethod");
            setMoneticData(editedData.time_out, "timeout");
            setMoneticData(editedData.adyenIp, "adyenIpAddress");
            setMoneticData(editedData.mode, "mode");
            setMoneticData(editedData.webhook, "webhook");
            setMoneticData(editedData.idIntegrator, "idIntegrator");
            setMoneticData(editedData.autoValidate, "autoValidate");
            setMoneticData(editedData.currency, "currency");
        } else {
            setMoneticData("", "designation");
            setMoneticData("", "reference");
            setMoneticData("", "mark");
            setMoneticData("", "note");
            setMoneticData("", "identifiant");
            setMoneticData("", "nomTpe");
            setMoneticData("", "ipadress");
            setMoneticData("", "merchantcode");
            setMoneticData("", "port");
            setMoneticData("", "paymentType");
            setMoneticData("", "deviceCode");
            setMoneticData("", "typeConfig");
            setMoneticData("", "typeTerminal");
            setMoneticData("", "paymentMethod");
            setMoneticData("", "timeout");
            setMoneticData("", "adyenIpAddress");
            setMoneticData("Production", "mode");
            setMoneticData("", "webhook");
            setMoneticData("", "idIntegrator");
            setMoneticData("", "autoValidate");
            setMoneticData("", "currency");
            setMoneticData(false, "adyenIpExist");
        }
        setMoneticData(false, "selectMark");
        setMoneticData(false, "inputDesignation");
        setMoneticData(false, "inputSetupType");
        setMoneticData(false, "inputTerminalType");
        setMoneticData(false, "inputAdyenIpAddress");
        setMoneticData(false, "inputMode");
        setMoneticData(false, "isConsult");
        setMoneticData(false, "inputWebHook");
        setMoneticData(false, "inputIdIntegrator");
        setMoneticData(false, "inputAutoValidate");
        setMoneticData(false, "inputCurrency");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedData]);

    React.useEffect(() => {
        setMoneticData(
            checkIpIfExist(
                resourceIp,
                ipAddress,
                editedData?.peripheralId !== undefined
                    ? editedData?.peripheralId
                    : "",
                editedData?.peripheralId !== undefined
                    ? editedData?.shopId
                    : shopId
            ),
            "ipExist"
        );
        setMoneticData(
            checkIpIfExist(
                resourceIp,
                adyenIpAddress,
                editedData?.peripheralId !== undefined
                    ? editedData?.peripheralId
                    : "",
                editedData?.peripheralId !== undefined
                    ? editedData?.shopId
                    : shopId
            ),
            "adyenIpExist"
        );
        if (ipAddress !== "") {
            setMoneticData(false, "emptyIp");
            setMoneticData(!ipAddress.match(ipMatchWord), "inputIp");
        }
        if (ipAddress === "") {
            setMoneticData(false, "ipExist");
            setMoneticData(false, "inputIp");
            setMoneticData(false, "emptyIp");
        }
        if (adyenIpAddress === "") {
            setMoneticData(false, "adyenIpExist");
            setMoneticData(false, "inputAdyenIpAddress");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedData, shopId, ipAddress, adyenIpAddress]);

    return (
        <React.Fragment>
            <AvGroup>
                <StyledLabel htmlFor="text" className="required__clz mt-3">
                    {t("Designation")}
                </StyledLabel>
                <StyledTextInput
                    className={classnames("afp_inp_deviceName", {
                        input__clz:
                            inputDesignation ||
                            isNameExist ||
                            (designation?.trim() === "" && designation !== ""),
                    })}
                    autocomplete="off"
                    name="designation"
                    onChange={(e: any) => {
                        setMoneticData(e.target.value, "designation");
                        setIsEdited(true);
                        setMoneticData(false, "inputDesignation");
                    }}
                    value={designation}
                    placeholder={t("Write")}
                    type="text"
                    validate={{
                        pattern: {
                            value: notStartWithSpaceRegExp,
                            errorMessage: t("Designation is invalid"),
                        },
                    }}
                />
                {isNameExist ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("The name is already exists")!}
                    </div>
                ) : null}
                {inputDesignation ||
                (designation?.trim() === "" && designation !== "") ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please enter a designation")}
                    </div>
                ) : null}
            </AvGroup>
            <AvGroup>
                <StyledLabel htmlFor="text" className="required__clz mt-3">
                    {t("Monetic type")}
                </StyledLabel>
                <React.Fragment>
                    <StyledSelectInput
                        className={classnames("afp_drp_mark", {
                            invalid__clz: selectMark,
                        })}
                        placeholder={t("Select")}
                        onChange={(e: any) => {
                            setMoneticData(false, "selectMark");
                            setMoneticData(false, "portSelectInput");
                            setMoneticData(false, "speedSelectInput");
                            setMoneticData(false, "inputUrl");
                            setMoneticData(false, "selectInput");
                            setMoneticData(false, "inputCode");
                            setMoneticData(false, "inputPort");
                            setMoneticData(false, "inputIp");
                            setMoneticData(false, "inputSetupType");
                            setMoneticData(false, "inputTerminalType");
                            // setMoneticData(e.value, "mark");
                            setIsEdited(true);
                            setMoneticData(false, "inputDesignation");
                            if (e.value === "Valina") {
                                setMoneticData("EXAMPLE 1", "reference");
                                setMoneticData("", "port");
                            } else if (e.value === "Nepting") {
                                setMoneticData("S300", "reference");
                                setMoneticData("8888", "port");
                            } else {
                                setMoneticData("", "reference");
                                setMoneticData("COM 1", "port");
                            }
                        }}
                        options={[
                            {
                                label: "Nepting",
                                value: "Nepting",
                            },
                            {
                                label: "Valina",
                                value: "Valina",
                            },
                            {
                                label: "Adyen",
                                value: "Adyen",
                            },
                            {
                                label: "Valina swiss",
                                value: "Valina swiss",
                            },
                        ]}
                        value={
                            mark === ""
                                ? null
                                : {
                                      label: mark,
                                      value: mark,
                                  }
                        }
                        name="mark"
                        noOptionsMessage={() => t("No options")}
                    />
                </React.Fragment>

                {selectMark ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please select a mark")}
                    </div>
                ) : null}
            </AvGroup>
            {mark !== "Adyen" && mark !== "Valina swiss" ? (
                <AvGroup>
                    <StyledLabel htmlFor="text" className="mt-3">
                        {t("Reference")}
                    </StyledLabel>

                    <StyledSelectInput
                        isDisabled={mark === ""}
                        onChange={(e: any) => {
                            setMoneticData(e.value, "reference");
                            setIsEdited(true);
                        }}
                        value={
                            reference === ""
                                ? null
                                : {
                                      label: reference,
                                      value: reference,
                                  }
                        }
                        options={
                            mark === "Nepting"
                                ? neptingReferencesArray
                                : valinaReferencesArray
                        }
                        name="reference"
                        placeholder={t("Select")}
                        noOptionsMessage={() => t("No options")}
                        className="afp_drp_reference"
                    />
                </AvGroup>
            ) : null}
            {mark === "Valina" ? (
                <React.Fragment>
                    <AvGroup>
                        <StyledLabel
                            className="mt-3 required__clz"
                            htmlFor="text"
                        >
                            {t("Port number")}
                        </StyledLabel>

                        <StyledSelectInput
                            className={classnames("afp_inp_portNumber", {
                                invalid__clz: portSelectInput,
                            })}
                            value={
                                port === ""
                                    ? null
                                    : {
                                          label: port,
                                          value: port,
                                      }
                            }
                            id="port"
                            name="port"
                            options={ports}
                            onChange={(e: any) => {
                                setMoneticData(false, "portSelectInput");
                                setMoneticData(e.label, "port");
                                setIsEdited(true);
                            }}
                            placeholder={t("Select")}
                            noOptionsMessage={() => t("No options")}
                        />

                        {portSelectInput && (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a port number")}
                            </div>
                        )}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            className="mt-3 required__clz"
                            htmlFor="text"
                        >
                            {t("Speed")}
                        </StyledLabel>

                        <StyledSelectInput
                            className={classnames("afp_inp_speed", {
                                invalid__clz: speedSelectInput,
                            })}
                            value={
                                speed === ""
                                    ? null
                                    : {
                                          label: speed,
                                          value: speed,
                                      }
                            }
                            id="speed"
                            name="speed"
                            options={speeds}
                            onChange={(e: any) => {
                                setMoneticData(false, "speedSelectInput");
                                setMoneticData(e.label, "speed");
                                setMoneticData(true, "isEdited");
                            }}
                            placeholder={t("Select")}
                            noOptionsMessage={() => t("No options")}
                        />

                        {speedSelectInput && (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a speed")}
                            </div>
                        )}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            className="mt-3 required__clz"
                            htmlFor="text"
                        >
                            {t("Method of payment")}
                        </StyledLabel>

                        <StyledSelectInput
                            className={classnames(
                                "mnt_inp_speed afp_drp_methodOfPayment",
                                {
                                    invalid__clz: inputPaymentMethod,
                                }
                            )}
                            value={paymentMethod === "" ? null : paymentMethod}
                            id="Method_of_payment"
                            name="Method_of_payment"
                            options={payment_method}
                            onChange={(e: any) => {
                                setMoneticData(false, "paymentMethod");
                                setMoneticData(e, "paymentMethod");
                                setIsEdited(true);
                            }}
                            placeholder={t("Select")}
                            noOptionsMessage={() => t("No options")}
                            maxMenuHeight="80px"
                        />

                        {inputPaymentMethod && (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a method of payment")}
                            </div>
                        )}
                    </AvGroup>

                    <AvGroup>
                        <StyledLabel
                            className="mt-3 required__clz"
                            htmlFor="text"
                        >
                            {t("Time out")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames(
                                "mnt_inp_ipAdress afp_inp_break",
                                {
                                    input__clz: inputTimeout,
                                }
                            )}
                            autocomplete="off"
                            id="ipAddress"
                            name="ipAddress"
                            placeholder={t("Enter")}
                            type="number"
                            onChange={(e: any) => {
                                setMoneticData(e.target.value, "timeout");
                                if (e.target.value === "") {
                                    setMoneticData(true, "inputTimeout");
                                } else {
                                    setMoneticData(false, "inputTimeout");
                                }

                                setIsEdited(true);
                            }}
                            value={timeout}
                        />
                        {inputTimeout ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter the time out")!}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel className="mt-3" htmlFor="text">
                            {t("Activate the protocol KISS")}
                        </StyledLabel>
                        <div className="float-right afp_icn_kiss">
                            <Switch
                                offColor="#faafb4"
                                offHandleColor="#E30613"
                                onColor="#c2eec4"
                                className="mt-3  pl-2"
                                onHandleColor="#34C38F"
                                uncheckedIcon={false}
                                checkedIcon={false}
                                checked={kiss}
                                height={20}
                                handleDiameter={24}
                                name="activate-protocol-kiss"
                                onChange={(e: any) => {
                                    setMoneticData(!kiss, "kiss");
                                    setIsEdited(true);
                                }}
                            />
                        </div>
                    </AvGroup>
                </React.Fragment>
            ) : mark === "Nepting" ? (
                <React.Fragment>
                    <AvGroup>
                        <StyledLabel
                            className="mt-3 required__clz"
                            htmlFor="text"
                        >
                            {t("IP address")}
                        </StyledLabel>
                        <AvField
                            className={classnames("afp_inp_ipAdresse", {
                                input__clz: inputIp || emptyIp || ipExist,
                            })}
                            autocomplete="off"
                            id="ipAddress"
                            name="ipAddress"
                            placeholder={t("Enter")}
                            type="text"
                            onChange={(e: any) => {
                                if (!e.target.value.match(ipMatchWord)) {
                                    setMoneticData(true, "inputIp");
                                    setMoneticData(e.target.value, "ipAddress");
                                } else {
                                    setMoneticData(e.target.value, "ipAddress");
                                    setMoneticData(false, "inputIp");
                                }
                                if (e.target.value === "") {
                                    setMoneticData(false, "emptyIp");
                                }
                                setIsEdited(true);
                            }}
                            value={ipAddress}
                        />
                        {inputIp || emptyIp || ipExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t(
                                    emptyIp
                                        ? "Please enter a IP address"
                                        : inputIp
                                        ? "IP address is invalid"
                                        : "The ip address is already exists"
                                )}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            className="mt-3 required__clz"
                            htmlFor="text"
                        >
                            {t("Port number")}
                        </StyledLabel>
                        <AvField
                            className={classnames("afp_inp_portNumber", {
                                input__clz: inputPort,
                            })}
                            autocomplete="off"
                            id="port"
                            name="port"
                            placeholder={t("Enter")}
                            type="text"
                            onChange={(e: any) => {
                                setMoneticData(e.target.value, "port");
                                setMoneticData(false, "inputPort");
                                setIsEdited(true);
                            }}
                            value={port}
                            validate={{
                                pattern: {
                                    value: numberMatchWord,
                                    errorMessage: t("Port number is invalid"),
                                },
                            }}
                        />
                        {inputPort && (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a port number")}
                            </div>
                        )}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            className="mt-3 required__clz"
                            htmlFor="text"
                        >
                            {t("Merchant code")}
                        </StyledLabel>
                        <AvField
                            className={classnames("afp_inp_workingCode", {
                                input__clz: InputCode,
                            })}
                            autocomplete="off"
                            id="merchantCode"
                            name="merchantCode"
                            placeholder={t("Enter")}
                            type="text"
                            onChange={(e: any) => {
                                setMoneticData(e.target.value, "merchantCode");
                                setMoneticData(false, "InputCode");
                                setIsEdited(true);
                            }}
                            value={merchantCode}
                        />
                        {InputCode && (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a merchant code")}
                            </div>
                        )}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            className="mt-3 required__clz"
                            htmlFor="text"
                        >
                            {t("Mode of payment")}
                        </StyledLabel>

                        <StyledSelectInput
                            value={
                                paymentType === ""
                                    ? null
                                    : {
                                          label: paymentType,
                                          value: paymentType,
                                      }
                            }
                            className={classnames("afp_inp_typeOfPayment", {
                                invalid__clz: SelectInput,
                            })}
                            name="paymentType"
                            options={paramsTypes}
                            onChange={(e: any) => {
                                setMoneticData(false, "SelectInput");
                                setMoneticData(e.value, "paymentType");
                                setIsEdited(true);
                            }}
                            placeholder={t("Select")}
                            noOptionsMessage={() => t("No options")}
                        />

                        {SelectInput && (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a mode of payement")}
                            </div>
                        )}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            className="mt-3 required__clz"
                            htmlFor="text"
                        >
                            {t("URL")}
                        </StyledLabel>
                        <AvField
                            className={classnames("afp_inp_url", {
                                input__clz: InputUrl,
                            })}
                            autocomplete="off"
                            id="url"
                            name="url"
                            placeholder={t("Enter")}
                            type="text"
                            onChange={(e: any) => {
                                setMoneticData(e.target.value, "url");
                                setMoneticData(false, "InputUrl");
                                setIsEdited(true);
                            }}
                            value={url}
                        />
                        {InputUrl && (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a URL")}
                            </div>
                        )}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            className="mt-3 required__clz"
                            htmlFor="text"
                        >
                            {t("Setup Type")}
                        </StyledLabel>

                        <StyledSelectInput
                            className={classnames(
                                "mnt_inp_speed afp_drp_setupType",
                                {
                                    invalid__clz: inputSetupType,
                                }
                            )}
                            value={typeConfig !== "" ? typeConfig : null}
                            id="Method_of_payment"
                            name="Method_of_payment"
                            options={typeOfConfig}
                            onChange={(e: any) => {
                                if (e !== null) {
                                    setMoneticData(e, "typeConfig");
                                    setMoneticData(false, "inputSetupType");
                                } else {
                                    setMoneticData(true, "inputSetupType");
                                }

                                setIsEdited(true);
                            }}
                            placeholder={t("Select")}
                            noOptionsMessage={() => t("No options")}
                            maxMenuHeight="80px"
                        />
                        {inputSetupType && (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a setup type")}
                            </div>
                        )}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            className="mt-3 required__clz"
                            htmlFor="text"
                        >
                            {t("Terminal Type")}
                        </StyledLabel>

                        <StyledSelectInput
                            className={classnames(
                                "mnt_inp_speed afp_drp_typeTerminal",
                                {
                                    invalid__clz: inputTerminalType,
                                }
                            )}
                            value={typeTerminal !== "" ? typeTerminal : null}
                            id="typeTerminal"
                            name="typeTerminal"
                            options={typeOfTerminal}
                            onChange={(e: any) => {
                                if (e !== null) {
                                    setMoneticData(e, "typeTerminal");
                                    setMoneticData(false, "inputTerminalType");
                                } else {
                                    setMoneticData(true, "inputTerminalType");
                                }

                                setIsEdited(true);
                            }}
                            placeholder={t("Select")}
                            noOptionsMessage={() => t("No options")}
                            maxMenuHeight="80px"
                        />
                        {inputTerminalType && (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a terminal type")}
                            </div>
                        )}
                    </AvGroup>
                </React.Fragment>
            ) : mark === "Adyen" ? (
                <AdyenConfiguration setIsEdited={setIsEdited} />
            ) : mark === "Valina swiss" ? (
                <ValinaSwissConfig setIsEdited={setIsEdited} />
            ) : null}
            {mark !== "Valina swiss" ? (
                <AvGroup>
                    <StyledLabel className="mt-3" htmlFor="text">
                        {t("Remark")}
                    </StyledLabel>
                    <StyledTextInput
                        autocomplete="off"
                        name="remarque"
                        onChange={(e: any) => {
                            setMoneticData(e.target.value, "note");
                            setIsEdited(true);
                        }}
                        value={note}
                        placeholder={t("Write")}
                        type="text"
                        className="afp_inp_remark"
                    />
                </AvGroup>
            ) : null}
        </React.Fragment>
    );
}
