import React, { useState } from "react";
import classnames from "classnames";
import {
    CheckboxIcon,
    DownloadIcon,
    PencilIcon,
    SignInIcon,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";
import {
    LoadingSvgIcon,
    MessageSvgIcon,
    RefrechIcon as RefreshIcon,
    SignOutIcon,
} from "@components/Common/SvgIcons";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next/";

import store, {
    setActions,
    setIsActionsClicked,
    setIsAddAppClicked,
    setIsAddPeripheralClicked,
    setIsEdited,
} from "./store";

import TpeInformationsModal from "./TpeInformationsModal";

export function ActionColumn({
    e,
    setEditedData,
    setIsComingFromModify,
    displayMode,
    typeDisplay,
}: any) {
    const { t } = useTranslation();

    const [isAllowedMonetics] = useState<boolean>(
        e.selectedRow.original.mark !== "Nepting" &&
            e.selectedRow.original.Status === "in line"
    );
    const { isAddAppClicked, isEdited, isActionsClicked } = useSnapshot(store);
    const [
        isTpeInformationsModalOpened,
        setIsTpeInformationsModalOpened,
    ] = useState<boolean>(false);
    const [TpeInformationsModalTitle] = useState<string>(
        e.selectedRow.original[t("Device")] +
            " - " +
            e.selectedRow.original.appId
    );
    const { isAddPeripheralClicked } = useSnapshot(store);

    function RefreshHandleMoneticClick() {
        return toast.success(`${t("The initialisation was successful")}`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            theme: "colored",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            icon: <CheckboxIcon height={25} width={25} fill="white" />,
        });
    }

    function SignInHandleMoneticClick() {
        return toast.success(`${t("Login was successful")}`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            theme: "colored",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            icon: <CheckboxIcon height={25} width={25} fill="white" />,
        });
    }

    function SignOutHandleMoneticClick() {
        return toast.success(`${t("Logout was successful")}`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            theme: "colored",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            icon: <CheckboxIcon height={25} width={25} fill="white" />,
        });
    }

    function RestartHandleMoneticClick() {
        return toast.success(`${t("The restart was successful")}`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            theme: "colored",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            icon: <CheckboxIcon height={25} width={25} fill="white" />,
        });
    }

    return (
        <React.Fragment>
            {displayMode === t("View by app") ? (
                e.selectedRow.original["Application"] !== "" ? (
                    <div
                        className={`${classnames(
                            "w-100 d-flex justify-content-center",
                            {
                                "not-allowed-icon__clz": isAddAppClicked,
                            }
                        )}`}
                    >
                        <div className="dropdown">
                            <VerticalDotsIcon
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                style={{ cursor: "pointer" }}
                                height={25}
                                width={25}
                                fill="black"
                                className={`${classnames("cmn_drp_FRmenu", {
                                    "not-allowed-icon__clz":
                                        typeDisplay !== "notArchived",
                                })}`}
                            />
                            <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                            >
                                <div className="dropdown-item pl-1 not-allowed-icon__clz afa_drp_importSettings">
                                    <DownloadIcon height={10} width={10} />
                                    <span className="ml-2">
                                        {t("Import application settings")}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : e.selectedRow.original.peripheralType === "monetic" ? (
                    <div
                        className={`${classnames(
                            " dropdown w-100 d-flex justify-content-center",
                            {
                                "not-allowed-icon__clz": isAddAppClicked,
                            }
                        )}`}
                    >
                        <VerticalDotsIcon
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            className="cmn_drp_FRmenu"
                            style={{ cursor: "pointer" }}
                            height={25}
                            width={25}
                            fill="black"
                        />
                        <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                        >
                            <div
                                style={{ cursor: "pointer" }}
                                className={classnames("dropdown-item pl-1 ")}
                                onClick={() => RefreshHandleMoneticClick()}
                            >
                                <RefreshIcon
                                    height={15}
                                    width={15}
                                    className={classnames("m-1")}
                                />
                                <span className="ml-2">{t("Initialize")}</span>
                            </div>
                            <div
                                style={{ cursor: "pointer" }}
                                className={classnames("dropdown-item pl-1 ", {
                                    "not-allowed-icon__clz": isAllowedMonetics,
                                })}
                                onClick={() => SignInHandleMoneticClick()}
                            >
                                <SignInIcon
                                    height={15}
                                    width={15}
                                    className={classnames("m-1", {
                                        "not-allowed-icon__clz": isAllowedMonetics,
                                    })}
                                />
                                <span className="ml-2">{t("Login")}</span>
                            </div>
                            <div
                                style={{ cursor: "pointer" }}
                                className={classnames("dropdown-item pl-1 ", {
                                    "not-allowed-icon__clz": isAllowedMonetics,
                                })}
                                onClick={() => SignOutHandleMoneticClick()}
                            >
                                <SignOutIcon
                                    height={15}
                                    width={15}
                                    className={classnames("m-1", {
                                        "not-allowed-icon__clz": isAllowedMonetics,
                                    })}
                                />
                                <span className="ml-2">{t("Logout")}</span>
                            </div>
                            <div
                                style={{ cursor: "pointer" }}
                                className={classnames("dropdown-item pl-1 ", {
                                    "not-allowed-icon__clz":
                                        e.selectedRow.original.mark !==
                                        "Nepting",
                                })}
                                onClick={() => RestartHandleMoneticClick()}
                            >
                                <LoadingSvgIcon
                                    height={15}
                                    width={15}
                                    className={classnames("m-1", {
                                        "not-allowed-icon__clz": isAllowedMonetics,
                                    })}
                                />
                                <span className="ml-2">{t("Restart")}</span>
                            </div>
                            <div
                                style={{ cursor: "pointer" }}
                                className={classnames("dropdown-item pl-1 ", {
                                    "not-allowed-icon__clz":
                                        e.selectedRow.original.mark !==
                                        "Nepting",
                                })}
                                onClick={() =>
                                    setIsTpeInformationsModalOpened(
                                        !isTpeInformationsModalOpened
                                    )
                                }
                            >
                                <MessageSvgIcon
                                    height={15}
                                    width={15}
                                    className={classnames("m-1", {
                                        "not-allowed-icon__clz": isAllowedMonetics,
                                    })}
                                />
                                <span className="ml-2">
                                    {t("TPE informations")}
                                </span>
                            </div>
                        </div>
                    </div>
                ) : null
            ) : e.selectedRow.original[t("Peripheral")] !== "" &&
              e.selectedRow.original[t("Application")] === "" &&
              e.selectedRow.original.peripheralType !== "monetic" ? (
                <div
                    className={`${classnames(
                        "w-100 d-flex justify-content-center"
                    )}`}
                >
                    <div className="dropdown">
                        <VerticalDotsIcon
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            style={{ cursor: "pointer" }}
                            height={25}
                            width={25}
                            fill="black"
                            className="afp_drp_menuProject"
                        />
                        <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                        >
                            <div
                                className="dropdown-item pl-1 cmn_icn_FRedit"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(async () => {
                                            setIsEdited(false);
                                            if (!isAddAppClicked) {
                                                setIsAddAppClicked(
                                                    !isAddAppClicked
                                                );
                                            }
                                            setIsAddPeripheralClicked(
                                                !isAddPeripheralClicked
                                            );
                                            setEditedData(
                                                e.selectedRow.original
                                            );
                                            setIsComingFromModify(true);
                                        });
                                    } else {
                                        if (!isAddAppClicked) {
                                            setIsAddAppClicked(
                                                !isAddAppClicked
                                            );
                                        }
                                        setIsAddPeripheralClicked(
                                            !isAddPeripheralClicked
                                        );
                                        setEditedData(e.selectedRow.original);
                                        setIsComingFromModify(true);
                                    }
                                }}
                            >
                                <PencilIcon height={10} width={10} />
                                <span className="ml-2">{t("Edit")}</span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : e.selectedRow.original.peripheralType === "monetic" &&
              e.selectedRow.original[t("Application")] === "" ? (
                <div
                    className={`${classnames(
                        " dropdown w-100 d-flex justify-content-center"
                    )}`}
                >
                    <div className="dropdown">
                        <VerticalDotsIcon
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            style={{ cursor: "pointer" }}
                            height={25}
                            width={25}
                            fill="black"
                        />
                        <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                        >
                            <div
                                className="dropdown-item pl-1"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    if (isEdited === true) {
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(async () => {
                                            setIsEdited(false);

                                            if (!isAddAppClicked) {
                                                setIsAddAppClicked(
                                                    !isAddAppClicked
                                                );
                                            }
                                            setIsAddPeripheralClicked(
                                                !isAddPeripheralClicked
                                            );
                                            setEditedData(
                                                e.selectedRow.original
                                            );
                                            setIsComingFromModify(true);
                                        });
                                    } else {
                                        if (!isAddAppClicked) {
                                            setIsAddAppClicked(
                                                !isAddAppClicked
                                            );
                                        }
                                        setIsAddPeripheralClicked(
                                            !isAddPeripheralClicked
                                        );
                                        setEditedData(e.selectedRow.original);
                                        setIsComingFromModify(true);
                                    }
                                }}
                            >
                                <PencilIcon height={10} width={10} />
                                <span className="ml-2">{t("Edit")}</span>
                            </div>
                        </div>
                    </div>
                    {/* <VerticalDotsIcon
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        style={{ cursor: "pointer" }}
                        height={25}
                        width={25}
                        fill="black"
                    />
                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <div
                            style={{ cursor: "pointer" }}
                            className={classnames("dropdown-item pl-1 ")}
                            onClick={() => RefreshHandleMoneticClick()}
                        >
                            <RefreshIcon
                                height={15}
                                width={15}
                                className={classnames("m-1")}
                            />
                            <span className="ml-2">{t("Initialize")}</span>
                        </div>
                        <div
                            style={{ cursor: "pointer" }}
                            className={classnames("dropdown-item pl-1 ", {
                                "not-allowed-icon__clz": isAllowedMonetics,
                            })}
                            onClick={() => SignInHandleMoneticClick()}
                        >
                            <SignInIcon
                                height={15}
                                width={15}
                                className={classnames("m-1", {
                                    "not-allowed-icon__clz": isAllowedMonetics,
                                })}
                            />
                            <span className="ml-2">{t("Login")}</span>
                        </div>
                        <div
                            style={{ cursor: "pointer" }}
                            className={classnames("dropdown-item pl-1 ", {
                                "not-allowed-icon__clz": isAllowedMonetics,
                            })}
                            onClick={() => SignOutHandleMoneticClick()}
                        >
                            <SignOutIcon
                                height={15}
                                width={15}
                                className={classnames("m-1", {
                                    "not-allowed-icon__clz": isAllowedMonetics,
                                })}
                            />
                            <span className="ml-2">{t("Logout")}</span>
                        </div>
                        <div
                            style={{ cursor: "pointer" }}
                            className={classnames("dropdown-item pl-1 ", {
                                "not-allowed-icon__clz":
                                    e.selectedRow.original.mark !== "Nepting",
                            })}
                            onClick={() => RestartHandleMoneticClick()}
                        >
                            <LoadingSvgIcon
                                height={15}
                                width={15}
                                className={classnames("m-1", {
                                    "not-allowed-icon__clz": isAllowedMonetics,
                                })}
                            />
                            <span className="ml-2">{t("Restart")}</span>
                        </div>
                        <div
                            style={{ cursor: "pointer" }}
                            className={classnames("dropdown-item pl-1 ", {
                                "not-allowed-icon__clz":
                                    e.selectedRow.original.mark !== "Nepting",
                            })}
                            onClick={() =>
                                setIsTpeInformationsModalOpened(
                                    !isTpeInformationsModalOpened
                                )
                            }
                        >
                            <MessageSvgIcon
                                height={15}
                                width={15}
                                className={classnames("m-1", {
                                    "not-allowed-icon__clz": isAllowedMonetics,
                                })}
                            />
                            <span className="ml-2">
                                {t("TPE informations")}
                            </span>
                        </div>
                    </div> */}
                </div>
            ) : null}
            <TpeInformationsModal
                isTpeInformationsModalOpened={isTpeInformationsModalOpened}
                setIsTpeInformationsModalOpened={
                    setIsTpeInformationsModalOpened
                }
                title={TpeInformationsModalTitle}
            />
        </React.Fragment>
    );
}
