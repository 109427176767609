import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import { generalConfigStore } from "@store";

import { CustomErrorBoundary } from "@components/CustomErrorBoundary";
import { Template } from "@pages/GeneralConfig/TemplateSelection/Template";
import { FlexboxGrid } from "@components/Common/FlexboxGrid";

export function TemplateSelection(): JSX.Element {
    const { t } = useTranslation();

    const { templates } = useSnapshot(generalConfigStore);

    return (
        <CustomErrorBoundary>
            <div
                className="d-grid__clz cursor__clz"
                style={{
                    gridTemplateRows: "0.15fr auto",
                    gap: "20px",
                }}
            >
                <span style={{ fontSize: "45px" }}>
                    {t("What template do you want to use ?")}
                </span>
                <FlexboxGrid gap="20px">
                    {templates.map((template: NewTemplateType) => (
                        <Template key={template.id} template={template} />
                    ))}
                </FlexboxGrid>
            </div>
        </CustomErrorBoundary>
    );
}
