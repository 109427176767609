import styled from "styled-components";

export const StyledTextarea = styled.textarea`
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #bababa;
    border-radius: 8px;
    font: normal normal normal 15px/20px Segoe UI;
    color: #070707;
    resize: none;
    margin: 5px;
    width: 95%;
    padding: 10px;
`;
