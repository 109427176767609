import classNames from "classnames";
import React from "react";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSnapshot } from "valtio";

import { designStore, generalConfigStore } from "@store";

import { getImageContentById } from "@helpers/general";

import { data } from "@pages/GeneralConfig/Design/Content/OrderTaking/fastFoodCard";

import CloseCircleIcon from "../Icons/CloseCircleIcon";
import { InfoIcon } from "../Icons/InfoIcon";
import "./index.css";

import "swiper/swiper-bundle.min.css";

export function HeaderWrapper(): JSX.Element {
    const {
        project: { files: images },
    } = useSnapshot(generalConfigStore);

    const {
        progressColor,
        closingButton,
        productAccessIconCompositeProduct,
        isNextOrPreviousButtonsActive,
        isClosingButtonActive,
    } = useSnapshot(designStore);

    const workflow = data.workFlow;
    const width = "430px";
    document.documentElement.style.setProperty("--swiper-width", width);
    document.documentElement.style.setProperty(
        "--progress-color",
        progressColor.content
    );

    const closingButtonContent = getImageContentById(closingButton.id, images);
    const closingButtonImageSrc =
        closingButtonContent?.includes("http") ||
        closingButtonContent?.includes("https")
            ? closingButtonContent
            : `./images/${closingButtonContent}`;

    const productAccessIconContent = getImageContentById(
        productAccessIconCompositeProduct.id,
        images
    );

    const productAccessIconImageSrc =
        productAccessIconContent?.includes("http") ||
        productAccessIconContent?.includes("https")
            ? productAccessIconContent
            : `./images/${productAccessIconContent}`;

    return (
        <React.Fragment>
            <div
                style={{ display: "grid", gridTemplateColumns: "329px 100px" }}
            >
                <div
                    className="mx-2 d-flex justify-content-center align-items-center"
                    style={{
                        font: "normal normal 600 20px/30px Segoe UI",

                        color: "#000000",
                    }}
                >
                    {workflow.title}
                </div>

                <div className="d-flex justify-content-end align-items-center">
                    <div className="mb-2">
                        {productAccessIconCompositeProduct.active === true ? (
                            productAccessIconContent !== "" ? (
                                <img
                                    alt="productAccessIcon"
                                    src={productAccessIconImageSrc}
                                    style={{
                                        height: "20px",
                                        width: "23px",
                                    }}
                                />
                            ) : (
                                <InfoIcon />
                            )
                        ) : null}
                    </div>
                    {isClosingButtonActive === true ? (
                        closingButtonContent !== "" ? (
                            <img
                                alt="closingButton"
                                src={closingButtonImageSrc}
                                style={{
                                    height: "20px",
                                    width: "23px",
                                }}
                            />
                        ) : (
                            <CloseCircleIcon />
                        )
                    ) : null}
                </div>
            </div>
            <div
                className="d-flex flex-row"
                style={{ width: "430px", height: "110px" }}
            >
                {isNextOrPreviousButtonsActive === true ? (
                    <img
                        src="./images/prev.png"
                        alt="prev"
                        style={{
                            width: "45px",
                            height: "45px",
                            marginTop: "66px",
                        }}
                    />
                ) : null}
                <Swiper
                    slidesPerView="auto"
                    slidesPerGroup={1}
                    style={{
                        display: "flex",
                        gap: "20px",
                        marginLeft:
                            isNextOrPreviousButtonsActive === true
                                ? "24px"
                                : "69px",
                        width: "300px",
                    }}
                    modules={[Navigation]}
                >
                    {workflow.step?.map((items, index) => {
                        const item = {
                            ...items,
                            name: items.title,
                        };

                        return (
                            <SwiperSlide
                                key={`${item.name}|${index}`}
                                className="workflow-swiper-slide"
                                style={{
                                    width:
                                        workflow.step.length !== index + 1
                                            ? "30%"
                                            : "48%",
                                    height:
                                        item.isActive === true
                                            ? "89px"
                                            : "97px",
                                }}
                            >
                                <div
                                    className="d-flex flex-column"
                                    style={{
                                        justifyContent:
                                            index === 0 ? "start" : "center",
                                        alignItems:
                                            index === 0 ? "start" : "center",
                                    }}
                                >
                                    <img
                                        alt={item.name}
                                        src={item.image}
                                        style={{
                                            width: "30px",
                                            height: "30px",
                                        }}
                                    />
                                    <span>{item.name}</span>
                                </div>

                                {item.isActive === true &&
                                item.isCompleted === false ? (
                                    <div className="d-flex align-items-center">
                                        <div
                                            className={classNames({
                                                "active-line":
                                                    index + 1 !==
                                                    workflow.step?.length,
                                                none:
                                                    index + 1 ===
                                                    workflow.step?.length,
                                            })}
                                        />
                                        <div className="d-flex flex-column justify-content-center align-items-center outer circle">
                                            <div className="d-flex flex-column justify-content-center  align-items-center step-item">
                                                <span>{index + 1}</span>
                                            </div>
                                        </div>
                                    </div>
                                ) : item.isActive === true &&
                                  item.isCompleted === true ? (
                                    <div className="d-flex align-items-center">
                                        <div
                                            className={classNames({
                                                "active-line":
                                                    index + 1 !==
                                                    workflow.step?.length,
                                                none:
                                                    index + 1 ===
                                                    workflow.step?.length,
                                            })}
                                        />
                                        <div className="d-flex flex-column justify-content-center align-items-center outer circle">
                                            <div className="d-flex flex-column justify-content-center  align-items-center step-item">
                                                <span>{index + 1}</span>
                                            </div>
                                        </div>
                                    </div>
                                ) : item.isActive === false &&
                                  item.isCompleted === false ? (
                                    <div
                                        className="d-flex align-items-center h-110"
                                        style={{
                                            marginTop: "10px",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <div className="step-item non-active-step">
                                            <span>{index + 1}</span>
                                        </div>
                                    </div>
                                ) : item.isActive === false &&
                                  item.isCompleted === true ? (
                                    <div className="d-flex align-items-center">
                                        <div
                                            className={classNames({
                                                "active-line":
                                                    index + 1 !==
                                                    workflow.step?.length,
                                                none:
                                                    index + 1 ===
                                                    workflow.step?.length,
                                            })}
                                        />
                                        <div className="d-flex flex-column justify-content-center align-items-center outer circle">
                                            <div className="d-flex flex-column justify-content-center  align-items-center step-item">
                                                <span>{index + 1}</span>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
                {isNextOrPreviousButtonsActive === true ? (
                    <img
                        src="./images/nextIcon.png"
                        alt="nextIcon"
                        style={{
                            width: "45px",
                            height: "45px",
                            marginTop: "66px",
                        }}
                    />
                ) : null}
            </div>
        </React.Fragment>
    );
}
