import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";
import moment from "moment";

import { store } from "@components/VerticalLayout/store";
import {
    setIsAddExceptionalHoursClicked,
    setNumberActive,
    setNumberArchived,
    store as generalSettingStore,
    setDataArchive,
    setExceptionalHoursFranchise,
    setArchivedExceptionalHoursFranchise,
    setExceptionalHoursData,
    setIdsLocalShop,
    setLocalShop,
    setArchivedExceptionalHoursOfFranchise,
    setIsAssociatedExceptionalHours,
    setActiveExceptionalHours,
    setIsActionsClicked,
    setIsEdited,
    setActions,
    setValidationAction,
    setModify,
} from "../store";
import { getPathAuthorization } from "@components/VerticalLayout";
import { useLocation } from "react-router-dom";

import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import { ExceptionalHoursForm } from "./ExceptionalHoursForm";
import { CustomSideFilter } from "./CustomSideFilter";
import { CustomIcons } from "./CustomIcons";
import { NextException } from "../NextException";
import { ExceptionalHoursInfoModal } from "../ExceptionalHoursIfoModal";
import { ConfirmationActiveExceptionalHours } from "./ConfirmationActiveExceptionalHours";
import { ConfirmationAssociatedMessage } from "../ConfirmationAssociateMessage";
import { History } from "../History";
import { ConfirmationArchiveExceptionalHours } from "../ConfirmationArchiveExceptionalHours";

import { ConfirmationUpdatingDataMessageModal } from "@components/Common/ConfirmationUpdatingDataMessageModal";
import { useCallbackPrompt } from "@hooks/useCallbackPrompt";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

export function ExceptionalHours({
    setIsArchivedExceptionalHoursClicked,
    isArchivedExceptionalHoursClicked,
}: any) {
    const { t } = useTranslation();
    const location = useLocation();
    setModify(getPathAuthorization(location.pathname).Modifie);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const { oneShop, userID, franchiseID, shopID } = useSnapshot(store);
    const {
        isAddExceptionalHoursClicked,
        isAssociatedExceptionalHours,
        isValidateArchiveOpened,
        isActionsClicked,
        isEdited,
        actions,
        validationAction,
    } = useSnapshot(generalSettingStore);

    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        isEdited
    );
    const [
        isAddNewExceptionalHoursButtonClicked,
        setIsAddNewExceptionalHoursButtonClicked,
    ] = useState(false);
    const [isHistoryModalOpened, setIsHistoryModalOpened] = React.useState<
        boolean
    >(false);
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [isInfoModalOpened, setIsInfoModalOpened] = React.useState<boolean>(
        false
    );
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [shopId, setShopId] = React.useState<number | string>(
        oneShop ? shopID : 0
    );
    const [infoHours, setInfoHours] = React.useState<string>("");
    const [rowData, setRowData] = React.useState<any>([]);

    const urlArchive = `${process.env.REACT_APP_API_V2_URL}/settings/general/archived_exceptionalHours?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`;
    const urlActive = `${process.env.REACT_APP_API_V2_URL}/settings/general/exceptionalHours?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`;

    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [dataTable, setDataTable] = React.useState<any>({});
    const [isModification, setIsModification] = React.useState<boolean>(false);

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: t("State"),
            customJsx: (e: any) => <div>{t(e.selectedRow.state)}</div>,
        },
        {
            indexOFColumn: 3,
            columnName: t("Start date"),
            customJsx: (e: any) =>
                e.selectedRow.subRows !== undefined ? (
                    <div>
                        {moment(e.selectedRow.startDate, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                        )}
                    </div>
                ) : null,
        },
        {
            indexOFColumn: 4,
            columnName: t("End date"),
            customJsx: (e: any) =>
                e.selectedRow.subRows !== undefined ? (
                    <div>
                        {moment(e.selectedRow.endDate, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                        )}
                    </div>
                ) : null,
        },
        {
            indexOFColumn: 900,
            columnName: t("Next exception"),
            customJsx: (e: any) => (
                <NextException
                    e={e}
                    setRowData={setRowData}
                    setIsModalOpened={setIsInfoModalOpened}
                    setHours={setInfoHours}
                    setIsModification={setIsModification}
                />
            ),
        },
        {
            indexOFColumn: 999,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    e={e}
                    setShopId={setShopId}
                    setRowData={setRowData}
                    setDataIsUpdated={setDataIsUpdated}
                    isHistoryModalOpened={isHistoryModalOpened}
                    setIsHistoryModalOpened={setIsHistoryModalOpened}
                    setSelectedRows={setSelectedRows}
                    dataIsUpdated={dataIsUpdated}
                    isArchivedExceptionalHoursClicked={
                        isArchivedExceptionalHoursClicked
                    }
                    setIsModalOpened={setIsModalOpened}
                    isModalOpened={isModalOpened}
                    setIsModification={setIsModification}
                />
            ),
        },
    ];

    const ActiveArchiveCount = () => {
        if (dataTable.othersData !== undefined) {
            if (dataTable.othersData.numberActive !== undefined) {
                setNumberActive(dataTable.othersData.numberActive);
                setNumberArchived(dataTable.othersData.numberArchive);
            } else {
                setNumberActive(0);
                setNumberArchived(0);
            }
        }
    };

    React.useEffect(() => {
        setDataIsUpdated(!dataIsUpdated);
        setIsAddExceptionalHoursClicked(false);
        // eslint-disable-next-line
    }, [isArchivedExceptionalHoursClicked, urlArchive, urlActive]);

    React.useEffect(() => {
        ActiveArchiveCount();
        // eslint-disable-next-line
    }, [dataTable]);

    React.useEffect(() => {
        let localShop: string[] = [];
        let idsLocalShop: string[] = [];
        selectedRows.forEach((el: any) => {
            localShop.push(el.shopId);
            idsLocalShop.push(el.Id);
        });
        setIdsLocalShop(idsLocalShop);
        setLocalShop(localShop);
        setExceptionalHoursData(
            dataTable.data !== undefined ? dataTable.data : []
        );
        setExceptionalHoursFranchise(
            dataTable.othersData !== undefined &&
                dataTable.othersData.exceptionalHoursOfFranchise !== undefined
                ? dataTable.othersData.exceptionalHoursOfFranchise
                : []
        );
        setArchivedExceptionalHoursFranchise(
            dataTable.othersData !== undefined &&
                dataTable.othersData.archivedExceptionalHoursFranchise !==
                    undefined
                ? dataTable.othersData.archivedExceptionalHoursFranchise
                : []
        );
        setDataArchive(
            dataTable.othersData !== undefined &&
                dataTable.othersData.dataArchive !== undefined
                ? dataTable.othersData.dataArchive
                : []
        );
        setArchivedExceptionalHoursOfFranchise(
            dataTable.othersData !== undefined &&
                dataTable.othersData.dataOfFranchiseArchive !== undefined
                ? dataTable.othersData.dataOfFranchiseArchive
                : []
        );
        setActiveExceptionalHours(
            dataTable.othersData !== undefined &&
                dataTable.othersData.activeExceptionalHours !== undefined
                ? dataTable.othersData.activeExceptionalHours
                : []
        );
    }, [selectedRows, dataTable]);

    React.useEffect(() => {
        if (filterActive) setIsAddExceptionalHoursClicked(false);
    }, [filterActive]);

    React.useEffect(() => {
        setActions(confirmNavigation);
        setIsActionsClicked(showPrompt as boolean);
        setValidationAction(cancelNavigation);
    }, [showPrompt, confirmNavigation, cancelNavigation]);

    return (
        <React.Fragment>
            <div
                style={{
                    display: "grid",
                    minHeight: oneShop ? "650px" : "600px",
                    gridTemplateColumns: isAddExceptionalHoursClicked
                        ? "2fr 1fr "
                        : "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    canMovedCheckboxLeftOnExpand
                    url={
                        isArchivedExceptionalHoursClicked
                            ? urlArchive
                            : urlActive
                    }
                    setData={setDataTable}
                    canSelect
                    canExpand={true}
                    customSelect
                    setSelectedRows={setSelectedRows}
                    customJsxSideFilterButton={
                        <CustomSideFilter
                            setRowData={setRowData}
                            setShopId={setShopId}
                            selectedRows={selectedRows}
                            rowData={rowData}
                            isEdited={isEdited}
                            setIsInInitializationModalOpened={
                                setIsInInitializationModalOpened
                            }
                            setDataIsUpdated={setDataIsUpdated}
                            dataIsUpdated={dataIsUpdated}
                            isHistoryModalOpened={isHistoryModalOpened}
                            setIsHistoryModalOpened={setIsHistoryModalOpened}
                            setLocalFilterActive={setLocalFilterActive}
                            setIsModalOpened={setIsModalOpened}
                            isModalOpened={isModalOpened}
                            setIsArchivedExceptionalHoursClicked={
                                setIsArchivedExceptionalHoursClicked
                            }
                            isArchivedExceptionalHoursClicked={
                                isArchivedExceptionalHoursClicked
                            }
                        />
                    }
                    canResize
                    showGlobalFilter
                    showFilter
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    actionColumn={() => <></>}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    elevationTable={1}
                    minHeight={oneShop ? "500px" : "450px"}
                    maxHeight={oneShop ? "500px" : "450px"}
                    name="exceptionalHours"
                />
                {isAddExceptionalHoursClicked ? (
                    <ExceptionalHoursForm
                        setIsAddExceptionalHoursClicked={
                            setIsAddExceptionalHoursClicked
                        }
                        isAddExceptionalHoursClicked={
                            isAddExceptionalHoursClicked
                        }
                        setDataIsUpdated={setDataIsUpdated}
                        rowData={rowData}
                        isAddNewExceptionalHoursButtonClicked={
                            isAddNewExceptionalHoursButtonClicked
                        }
                        setRowData={setRowData}
                        setIsEdited={setIsEdited}
                        setLocalFilterActive={setLocalFilterActive}
                        dataIsUpdated={dataIsUpdated}
                        isModification={isModification}
                    />
                ) : null}
            </div>
            {isInInitializationModalOpened === true ? (
                <ConfirmationInitializationMessage
                    isModalOpened={isInInitializationModalOpened}
                    setIsModalOpened={setIsInInitializationModalOpened}
                    setIsClicked={setIsAddNewExceptionalHoursButtonClicked}
                    isClicked={isAddNewExceptionalHoursButtonClicked}
                    setIsEdited={setIsEdited}
                />
            ) : null}
            {isInfoModalOpened === true ? (
                <ExceptionalHoursInfoModal
                    isModalOpened={isInfoModalOpened}
                    setIsModalOpened={setIsInfoModalOpened}
                    rowData={rowData}
                    infoHours={infoHours}
                />
            ) : null}

            {isHistoryModalOpened === true ? (
                <History
                    ids={selectedRows}
                    isHistoryModalOpened={isHistoryModalOpened}
                    setIsHistoryModalOpened={setIsHistoryModalOpened}
                    rowData={rowData}
                    setDataShopIsUpdated={setDataIsUpdated}
                />
            ) : null}
            <ConfirmationActiveExceptionalHours
                setIsModalOpened={setIsModalOpened}
                isModalOpened={isModalOpened}
                setDataIsUpdated={setDataIsUpdated}
                dataIsUpdated={dataIsUpdated}
                selectedRows={selectedRows}
                isOneShop={true}
            />
            {isAssociatedExceptionalHours && oneShop ? (
                <ConfirmationAssociatedMessage
                    setRowData={setRowData}
                    setDataIsUpdated={setDataIsUpdated}
                    isModalOpened={isAssociatedExceptionalHours}
                    setIsModalOpened={setIsAssociatedExceptionalHours}
                />
            ) : null}
            {isValidateArchiveOpened ? (
                <ConfirmationArchiveExceptionalHours
                    selectedRows={selectedRows}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                />
            ) : null}
            {isActionsClicked === true ? (
                <ConfirmationUpdatingDataMessageModal
                    isModalOpened={isActionsClicked}
                    setIsModalOpened={setIsActionsClicked}
                    validationAction={validationAction}
                    actions={actions}
                    setIsEdited={setIsEdited}
                />
            ) : null}
        </React.Fragment>
    );
}
