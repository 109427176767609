import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledLabel } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import { capitalize } from "@helpers/general";

type OverLappedMessageType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    overLappedItem: any;
    week: any;
    setWeek: Function;
};

export function OverLappedMessage({
    isModalOpened,
    setIsModalOpened,
    overLappedItem,
    week,
    setWeek,
}: OverLappedMessageType) {
    const { t } = useTranslation();

    const handleValidate = () => {
        let newWeek = [...week];
        if (week[0].state === true && overLappedItem.keyDay === 0) {
            newWeek.forEach((element: any) => {
                if (overLappedItem.changedItem === "endTime") {
                    element.hours[overLappedItem.keyHour].endTime = "";
                    element.hours[overLappedItem.keyHour].overLappedEnd = false;
                } else {
                    element.hours[overLappedItem.keyHour].startTime = "";
                    element.hours[
                        overLappedItem.keyHour
                    ].overLappedStart = false;
                }
            });
        } else {
            if (overLappedItem.changedItem === "endTime") {
                newWeek[overLappedItem.keyDay].hours[
                    overLappedItem.keyHour
                ].endTime = "";
                newWeek[overLappedItem.keyDay].hours[
                    overLappedItem.keyHour
                ].overLappedEnd = false;
            } else {
                newWeek[overLappedItem.keyDay].hours[
                    overLappedItem.keyHour
                ].startTime = "";
                newWeek[overLappedItem.keyDay].hours[
                    overLappedItem.keyHour
                ].overLappedStart = false;
            }
        }
        setWeek(newWeek);
        setIsModalOpened(!isModalOpened);
    };
    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader className="text-capitalize" toggle={handleValidate}>
                <span>{capitalize(t("Alert"))}</span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {t("Attention! there is an overlap between the schedules.")}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={handleValidate}
                >
                    {t("Ok")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
