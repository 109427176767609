import React from "react";
import {StyledH2 } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import classnames from "classnames";
import { useSnapshot } from "valtio";

import { capitalize } from "../../../helpers/general";
import { resetStore } from "../../../pages/Applications/DeviceAssociateModal/store";

import { store } from "@components/VerticalLayout/store";
import { store as storeData } from "./store";
import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import CustomColumnDisplay from "@components/Common/CustomColumnDisplay";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

export default function HistoryModal({
    isModalOpened,
    setIsModalOpened,
    setGlobalDataUpdated,
}: any) {
    const { t } = useTranslation();

    const { selectedMonnyeur } = useSnapshot(storeData);
    const { franchiseID, userID } = useSnapshot(store);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const [data, setData] = React.useState<any>({});
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);

    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };
    const url = `${
        process.env.REACT_APP_API_V2_URL
    }/settings/general/cashManagement/history?userId=${userID}&language=${
        i18n.use(detector).language === "fr" ? "fr" : "en"
    }&franchiseId=${franchiseID}&monnyeur=${selectedMonnyeur
        .map((el: any) => JSON.stringify(el))
        .join()}`;

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: t("Actions"),
            customJsx: (e: any) => {
                let actionsArray: any = [];
                e.selectedRow.actions.forEach((el: any) => {
                    if (el.item !== "isActive") {
                        if (el.item === "designation") {
                            actionsArray.push(t("Designation"));
                        } else if (el.item === "reference") {
                            actionsArray.push(t("Reference"));
                        } else if (el.item === "note") {
                            actionsArray.push(t("Note"));
                        } else if (el.item === "mark") {
                            actionsArray.push(t("Marque"));
                        } else if (el.item === "ip") {
                            actionsArray.push(t("Adresse IP"));
                        } else if (el.item === "Log directory") {
                            actionsArray.push(t("Répertoire log"));
                        } else if (el.item === "port_number") {
                            actionsArray.push(t("Port number"));
                        } else if (el.item === "user") {
                            actionsArray.push(t("User"));
                        } else if (el.item === "password") {
                            actionsArray.push(t("Password"));
                        } else if (el.item === "pos_id") {
                            actionsArray.push(t("POS ID"));
                        }
                    }
                });

                if (e.selectedRow.operation === "creation") {
                    return <span>{t("Creation")}</span>;
                } else if (e.selectedRow.operation === "modify") {
                    return (
                        <span className="ml-2">
                            <CustomColumnDisplay
                                text={`${t("Modification")}:${actionsArray.join(
                                    ","
                                )}`}
                            />
                        </span>
                    );
                } else if (e.selectedRow.operation.includes("Archiving")) {
                    return <span>{t("Archiving")}</span>;
                } else if (e.selectedRow.operation.includes("Unarchiving")) {
                    return <span>{t("Unarchiving")}</span>;
                } else if (e.selectedRow.operation.includes("Association")) {
                    return <span>{e.selectedRow.operation}</span>;
                } else {
                    return <span>{e.selectedRow.operation}</span>;
                }
            },
        },
        {
            indexOFColumn: 3,
            columnName: t("Before modification"),
            customJsx: (e: any) => {
                let oldValueArray: any = [];
                e.selectedRow.actions.forEach((el: any) => {
                    if (el.item !== "edit_date" && el.item !== "add_date")
                        oldValueArray.push(el.oldValue);
                });

                if (e.selectedRow.operation === "modify") {
                    return (
                        <CustomColumnDisplay text={oldValueArray.join(",")} />
                    );
                } else if (e.selectedRow.operation === "creation") {
                    return "";
                } else if (e.selectedRow.operation === "Unarchiving") {
                    return (
                        <span>
                            {t("Status")}: {t("Archived")}
                        </span>
                    );
                } else if (e.selectedRow.operation === "Archiving") {
                    return (
                        <span>
                            {t("Status")}: {t("Unarchived")}
                        </span>
                    );
                } else {
                    return "";
                }
            },
        },
        {
            indexOFColumn: 4,
            columnName: t("After modification"),
            customJsx: (e: any) => {
                let NewValueArray: any = [];

                e.selectedRow.actions.forEach((el: any) => {
                    if (el.item !== "edit_date" && el.item !== "add_date")
                        NewValueArray.push(el.newValue);
                });
                if (e.selectedRow.operation === "modify") {
                    return (
                        <CustomColumnDisplay text={NewValueArray.join(",")} />
                    );
                } else if (e.selectedRow.operation === "creation") {
                    return e.selectedRow.Désignation;
                } else if (e.selectedRow.operation === "Unarchiving") {
                    return (
                        <span>
                            {t("Status")}: {t("Unarchived")}
                        </span>
                    );
                } else if (e.selectedRow.operation === "Archiving") {
                    return (
                        <span>
                            {t("Status")}: {t("Archived")}
                        </span>
                    );
                } else {
                    return "";
                }
            },
        },
        {
            indexOFColumn: 5,
            columnName: t("Operator"),
            customJsx: (e: any) => {
                return <CustomColumnDisplay text={e.selectedRow.Opérateur} />;
            },
        },
        {
            indexOFColumn: 6,
            columnName: t("Date and hour"),
            customJsx: (e: any) => {
                return (
                    <CustomColumnDisplay
                        text={e.selectedRow["Date and hour"]}
                    />
                );
            },
        },
    ];

    return (
        <React.Fragment>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                className="modal-wrapper__clz"
                isOpen={isModalOpened}
            >
                <ModalHeader
                    toggle={() => {
                        setIsModalOpened!(!isModalOpened);
                        setGlobalDataUpdated(true);
                        resetStore();
                    }}
                    className="dev_icn_HSclose"
                >
                    <StyledH2 className="dev_txt_HStitleHistory">
                        {capitalize(t("Historical"))}
                    </StyledH2>
                </ModalHeader>

                <ModalBody>
                    <div
                        className={`${classnames(
                            "title__clz d-flex justify-content-start",
                            {
                                "not-allowed-icon__clz":
                                    selectedMonnyeur.length > 1,
                            }
                        )}`}
                    >
                        <div
                            style={{
                                textAlign: "left",
                                font: "normal normal 600 16px/20px Segoe UI",
                                letterSpacing: "0px",
                                color: "#2B2828",
                                opacity: "0.42",
                            }}
                        >
                            {t("Designation")} :
                            {selectedMonnyeur.length > 1
                                ? null
                                : data?.data !== undefined
                                ? data?.data![0][t("Designation")]
                                : ""}
                        </div>
                    </div>

                    <DynamicTable
                        requestHeader={{ "Accept-Language": i18nextLng }}
                        url={url}
                        canResize
                        actionColumn={ActionColumn}
                        showGlobalFilter
                        showFilter
                        setLocalFilterActive={setLocalFilterActive}
                        filterActive={filterActive}
                        setDataIsUpdated={setDataIsUpdated}
                        dataIsUpdated={dataIsUpdated}
                        elevationTable={1}
                        minHeight="500px"
                        maxHeight="500px"
                        name="historyProject"
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        setData={setData}
                    />
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <CustomMainColorButton
                        variant="primary"
                        className="fed_btn_HSclose"
                        onClick={() => {
                            setIsModalOpened(!isModalOpened);
                            setGlobalDataUpdated(true);
                            resetStore();
                        }}
                        rounded
                    >
                        {t("Close")}
                    </CustomMainColorButton>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
