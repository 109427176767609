import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    CrossIcon,
    StyledLabel,
    CheckboxIcon,
    StyledSelectInput,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { mutate } from "swr";
import { toast } from "react-toastify";
import classnames from "classnames";
import { useSnapshot } from "valtio";
import { uuid } from "uuidv4";

import { store } from "@components/VerticalLayout/store";
import {
    setGlobalDataIsUpdated,
    setIsDataUpdated,
    setIsInlineErrorCleared,
    store as choiceStore,
    setIsActionsClicked,
    setActions,
} from "../store";
import { clearData } from "@components/Common/MultiCustomCreatableSelection/store";
import { clearMultiCustomSelectionData } from "@components/Common/MultiCustomSelect/store";

import { getUniqueId, searchPosInArrayObject } from "@helpers/general";

import MultiCustomSelect from "@components/Common/MultiCustomSelect";
import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import {
    CustomCardHeader,
    CustomCardBody,
    CustomCardFooter,
} from "@components/Common/CustomCardStyled";
import MultiCustomCreatableSelection from "@components/Common/MultiCustomCreatableSelection";
import ErrorToast from "@components/Common/ErrorTost";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import { ConfirmationModificationMessage } from "../ConfirmationModificationMessage";
import { ConfirmationMessage } from "../ShopsPaymentMethod/ConfirmationMessage";
import { ConfirmationModal } from "./ConfirmationModal";

import "@assets/swal.css";
import "@assets/theme.css";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";

type ChoiceFormType = {
    setIsAddChoiceClicked: Function;
    isAddChoiceClicked: boolean;
    paymentMethod: React.SetStateAction<any>;
    setIsAddNewChoiceButtonClicked: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    isAddNewChoiceButtonClicked: boolean;
    setDataChoice: Function;
    userData: {
        userId: string | number;
        shopId: string | number;
        franchiseId: string | number;
    };
    isEdited: boolean;
    setIsEdited: Function;
    allDataShops: any;
    setLocalFilterActive: Function;
    setToastLimit: Function;
};

export default function PaymentMethodForm({
    setIsAddChoiceClicked,
    isAddChoiceClicked,
    paymentMethod,
    isAddNewChoiceButtonClicked,
    setDataChoice,
    userData,
    isEdited,
    setIsEdited,
    allDataShops,
    setLocalFilterActive,
    setToastLimit,
}: ChoiceFormType) {
    const { t } = useTranslation();
    const { shopID, userID, franchiseID, operatorID } = useSnapshot(store);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

    const {
        shopsPaymentMethodsData,
        paymentMethodsData,
        isInlineErrorCleared,
        listFranchiseArchived,
        dataArchive,
        isInputUpdated,
        isActionsClicked,
    } = useSnapshot(choiceStore);
    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState<boolean>(false);
    const { oneShop, shopPays } = useSnapshot(store);
    const [choiceMode, setChoiceMode] = React.useState<string>("");
    const [display, setDisplay] = React.useState<string>("");
    const [title, setTitle] = React.useState<string>("");
    const [identifier, setIdentifier] = React.useState<string>("");
    const [inputChoiceMode, setInputChoiceMode] = React.useState<boolean>(
        false
    );
    const [inputSaleSupport, setInputSaleSupport] = React.useState<boolean>(
        false
    );
    const [tags, setTags] = React.useState<any[]>([]);
    const [paymentMethodList, setPaymentMethodList] = React.useState<any[]>([]);
    const [isSendingTag, setIsSendingTag] = React.useState<boolean>(false);
    const [inputDisplay, setInputDisplay] = React.useState<boolean>(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);

    const [countryList, setCountryList] = React.useState<any>([]);
    const [tagsData, setTagsData] = React.useState<any>([]);
    const [
        isMessageValidateClicked,
        setIsMessageValidateClicked,
    ] = React.useState<boolean>(false);
    const [isMessageModalOpened, setIsMessageModalOpened] = React.useState<
        boolean
    >(false);
    const [
        isArchivedFranchiseModalOpened,
        setIsArchivedFranchiseModalOpened,
    ] = React.useState<boolean>(false);

    const [idsAssociated, setIdsAssociated] = React.useState<any>([]);

    const [existingPaymentMethod, setExistingPaymentMethod] = React.useState<
        any
    >({});

    const [
        allAssociatedCountryShop,
        setAllAssociatedCountryShop,
    ] = React.useState<any>([]);

    const [
        isPaymentMethodAssociatedModified,
        setIsPaymentMethodAssociatedModified,
    ] = React.useState<boolean>(false);
    const [isNameExist, setIsNameExist] = React.useState(false);
    const [selectCountry, setSelectCountry] = React.useState<boolean>(false);
    const [countryData, setCountryData] = React.useState<any>([]);
    const [salesSupport, setSalesSupport] = React.useState<any>([]);
    const [salesSupportData, setSalesSupportData] = React.useState<any>([]);

    function fetchDataNameFranchiseArchived() {
        let localNamesFranchise: string[] = [];
        if (listFranchiseArchived !== undefined && dataArchive !== undefined) {
            listFranchiseArchived.forEach((el: any) => {
                localNamesFranchise.push(
                    `${el[t("Type of payment method")].toUpperCase()}`
                );
            });
        }
        return localNamesFranchise;
    }

    const addPaymentMethodList = React.useCallback(async () => {
        let data = {
            userId: userID,
            franchiseId: franchiseID,
            data: {
                id: uuid(),
                designation: choiceMode.trim(),
            },
        };
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod/list`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
        };
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }, [t, franchiseID, userID, choiceMode]);

    const addPaymentMethod = React.useCallback(
        async (
            id: string,
            choiceMode: string,
            display: string,
            salesSupport: string,
            country: string,
            tag: string,
            shopIds: any[],
            isActive: boolean,
            msg: string,
            isAffectation?: any
        ) => {
            const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod`;
            let data = {
                userId: userData.userId,
                shopId: shopIds,
                franchiseId: userData.franchiseId,
                affectation: isAffectation,
                operatorId: operatorID,
                adding: true,
                data: {
                    [id]: {
                        designation: choiceMode,
                        display: display,
                        shopId: userData.shopId,
                        country: country,
                        saleSupport: salesSupport,
                        tag: tag,
                        isActive: isActive,
                        isArchived: false,
                    },
                },
            };

            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
            };
            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            }
                            if (msg !== "false") {
                                toast.success(`${t(msg)!}`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                });
                                addPaymentMethodList();
                                setIsSendingTag(true);
                                setIsDataUpdated(true);
                                setGlobalDataIsUpdated(true);
                                setIsEdited(false);
                                setLocalFilterActive(false);
                                setTagsData([]);
                                setCountryData([]);
                                setSalesSupport([]);
                                setDataChoice([]);
                                clearData();
                                clearMultiCustomSelectionData();
                            }
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        },
        [
            t,
            userData,
            setIsEdited,
            setDataChoice,
            setLocalFilterActive,
            addPaymentMethodList,
            operatorID,
        ]
    );

    async function editPaymentMethod() {
        toast.dismiss();
        let idsAssociated: any = [];
        let idsShopsAssociated: any = [];
        shopsPaymentMethodsData.forEach((el: any) => {
            el.subRows.forEach((element: any) => {
                if (element.id === paymentMethod.id) {
                    idsAssociated.push(element.id);
                    idsShopsAssociated.push(element.idShop);
                }
            });
        });

        setIdsAssociated([...new Set(idsShopsAssociated)]);

        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod`;
        let dataConfig = {
            userId: userData.userId,
            shopId: [userData.shopId],
            franchiseId: userData.franchiseId,
            operatorId: operatorID,
            data: {
                [identifier]: {
                    designation: choiceMode,
                    display: display,
                    shopId: userData.shopId,
                    country: countryData.join(","),
                    saleSupport: salesSupport.join(","),
                    tag: tagsData.join(","),
                    isActive: paymentMethod.isActive,
                    isArchived: paymentMethod.isArchived,
                },
            },
        };
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataConfig),
        };
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }

                        toast.success(
                            userData.shopId === "0" &&
                                idsAssociated.includes(identifier)
                                ? `${t(
                                      "The Payment method of franchise was successfully changed"
                                  )!}`
                                : `${t(
                                      "Payment method is successfully changed"
                                  )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsSendingTag(true);
                        setGlobalDataIsUpdated!(true);
                        if (
                            userData.shopId === "0" &&
                            idsAssociated.includes(identifier)
                        ) {
                            setIsPaymentMethodAssociatedModified(true);
                        } else {
                            setDataChoice([]);
                            setIsAddChoiceClicked(!isAddChoiceClicked);
                            setIsEdited(false);
                            clearData();
                            clearMultiCustomSelectionData();
                            setTagsData([]);
                            setCountryData([]);
                            setSalesSupport([]);
                            setIsDataUpdated(true);
                        }
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    const formatCreateLabel = (inputValue: any) => (
        <span>
            {inputValue} ({t("New tag")})
        </span>
    );
    const formatCreateLabelPaymentMethod = (inputValue: any) => (
        <span>
            {inputValue} ({t("New payment method")})
        </span>
    );
    async function handleGetPaymentMethodsTagsButtonOnClickEvent() {
        const newList: any[] = [];
        const paymentMethods: any[] = [];
        const salesSupport: any[] = [];

        const headers = new Headers();
        headers.append("Accept-Language", i18nextLng);

        try {
            await Promise.all([
                fetch(
                    `${
                        process.env.REACT_APP_API_V2_URL
                    }/settings/general/tag?userId=${userID}&shopId=${"0"}&franchiseId=${franchiseID}`
                ).then((value) => value.json()),
                fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/paymentMethod/list?userId=${userID}&franchiseId=${franchiseID}`,
                    { headers: headers }
                ).then((value) => value.json()),
                fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport?userId=${userID}&shopId=${shopID}&franchiseId=${franchiseID}&type=activated`,
                    { headers: headers }
                ).then((value) => value.json()),
            ]).then((result) => {
                for (let element of result[0].data) {
                    newList.push(element.Designation);
                }
                for (let element of result[1].data) {
                    paymentMethods.push({
                        value: element.id,
                        label: element.designation,
                    });
                }
                for (let element of result[2].data) {
                    salesSupport.push(element[t("Sale support")]);
                }

                setTags(newList);
                setPaymentMethodList(paymentMethods);
                setSalesSupportData(salesSupport);
            });
        } catch (error) {
            console.log(error);
        }
    }

    const checkIfPaymentMethodFranchiseExist = () => {
        let isPaymentMethodExist: boolean = false;
        if (allDataShops !== undefined) {
            isPaymentMethodExist =
                allDataShops.find(
                    (el: any) =>
                        `${el[t("Type of payment method")].toUpperCase()}` ===
                        `${choiceMode.toUpperCase()}`
                ) !== undefined;
        }

        return isPaymentMethodExist;
    };

    function handleValidateButtonOnClickEvent() {
        if (choiceMode === "") {
            setInputChoiceMode(true);
        }
        if (display === "") {
            setInputDisplay(true);
        }

        if (countryData.length === 0) {
            setSelectCountry(true);
        }
        if (salesSupport.length === 0) {
            setInputSaleSupport(true);
        }

        if (paymentMethod.id) {
            let samePaymentMethod: any = allDataShops.find(
                (el: any) =>
                    `${el[t("Type of payment method")].toUpperCase()}` ===
                    `${choiceMode.toUpperCase()}`
            );

            let isTagNotModified: any =
                samePaymentMethod !== undefined &&
                samePaymentMethod?.Tag === tagsData.join(",");

            let isCountryNotModified: any =
                samePaymentMethod !== undefined &&
                samePaymentMethod?.Pays === countryData.join(",");

            if (
                salesSupport.length !== 0 &&
                choiceMode !== "" &&
                display !== "" &&
                countryData.length !== 0 &&
                !isNameExist
            ) {
                if (
                    isEdited === false &&
                    isTagNotModified &&
                    isCountryNotModified
                )
                    setIsModalOpened(!isModalOpened);
                else if (
                    allAssociatedCountryShop
                        .map((el: any) => countryData.includes(el))
                        .find((el: any) => el === false) !== undefined
                ) {
                    ErrorToast(
                        `${t(
                            "Please note that you cannot remove the country from a payment method used in a store"
                        )}.`
                    );
                } else editPaymentMethod();
            }
        } else if (
            salesSupport.length !== 0 &&
            choiceMode !== "" &&
            display !== "" &&
            countryData.length !== 0 &&
            !isNameExist
        ) {
            if (
                fetchDataNameFranchiseArchived().includes(
                    `${choiceMode.toUpperCase()}`
                )
            ) {
            } else if (
                allDataShops !== undefined &&
                checkIfPaymentMethodFranchiseExist()
            ) {
                setIsMessageModalOpened(!isMessageModalOpened);
            } else {
                addPaymentMethod(
                    getUniqueId(10),
                    choiceMode,
                    display,
                    salesSupport.join(","),
                    countryData.join(","),
                    tagsData.join(","),
                    userData.shopId === "0" ? ["0"] : ["0", userData.shopId],
                    false,
                    "The payment method has been added successfully",
                    false
                );
            }
        }
    }

    const dataFetch = async () => {
        const data = await (
            await fetch(`${process.env.REACT_APP_API_V2_URL}/settings/pays`)
        ).json();
        let listOfTag: any = [];

        data.data.forEach((element: any) => {
            listOfTag.push({
                label: element.Designation,
                value: element["Id"],
            });
        });

        if (oneShop) {
            let shopPaysItems =
                listOfTag[searchPosInArrayObject(listOfTag, "label", shopPays)];
            if (shopPaysItems !== undefined) {
                setCountryList([shopPaysItems.label]);
            }
        } else {
            setCountryList(data.data.map((el: any) => el.Designation));
        }
    };

    useEffect(() => {
        if (paymentMethod.id !== undefined) {
            setTitle(t("Modify a payment method"));
            setIdentifier(paymentMethod.id);
            setTagsData(paymentMethod.Tag.split(","));
            setChoiceMode(paymentMethod[t("Type of payment method")]);
            setDisplay(paymentMethod[t("Display name")]);
            setSalesSupport(paymentMethod[t("Sales support")].split(","));
            setCountryData(paymentMethod[t("Country")].split(","));
        } else {
            setChoiceMode("");
            setTitle(t("Add a payment method"));
            setDisplay("");
            setIdentifier("");
        }
    }, [paymentMethod, t, isAddNewChoiceButtonClicked]);

    useEffect(() => {
        if (paymentMethod.id === undefined) {
            setDisplay(choiceMode);
            setInputDisplay(false);
        }
        handleGetPaymentMethodsTagsButtonOnClickEvent();
        // eslint-disable-next-line
    }, [choiceMode]);

    useEffect(() => {
        if (isInlineErrorCleared === true) {
            setInputChoiceMode(false);
            setInputDisplay(false);
            setSelectCountry(false);
            setInputSaleSupport(false);
            setIsInlineErrorCleared(false);
            clearData();
            clearMultiCustomSelectionData();
            setTagsData([]);
            setCountryData([]);
            setSalesSupport([]);
        }
    }, [isInlineErrorCleared, isAddChoiceClicked]);

    useEffect(() => {
        setInputChoiceMode(false);
        setInputDisplay(false);
        setSelectCountry(false);
        setInputSaleSupport(false);
        setIsInlineErrorCleared(false);
    }, [isInputUpdated]);

    useEffect(() => {
        let allPaymentMethod: any = [];
        allPaymentMethod =
            paymentMethodsData !== undefined &&
            [...paymentMethodsData, ...dataArchive].map(
                (el: any) => `${el[t("Type of payment method")].toUpperCase()}`
            );

        if (paymentMethod.id !== undefined) {
            allPaymentMethod = allPaymentMethod.filter(
                (item: any) =>
                    item !==
                    `${paymentMethod[
                        t("Type of payment method")
                    ].toUpperCase()}`
            );
        }

        setIsNameExist(
            allPaymentMethod &&
                allPaymentMethod.includes(`${choiceMode.trim().toUpperCase()}`)
        );
    }, [paymentMethodsData, choiceMode, t, paymentMethod, dataArchive]);

    React.useEffect(() => {
        let allPaymentShop: any = [];
        let allAssociatedCountryShop: any = [];
        if (shopsPaymentMethodsData !== undefined) {
            shopsPaymentMethodsData.forEach((el: any) => {
                if (el.subRows !== undefined)
                    el.subRows.forEach((element: any) => {
                        allPaymentShop.push(element);
                        if (element.id === identifier) {
                            allAssociatedCountryShop.push(el.Pays);
                        }
                    });
            });
        }

        setAllAssociatedCountryShop([...new Set(allAssociatedCountryShop)]);
        setExistingPaymentMethod(
            allPaymentShop.find((el: any) => el.id === identifier)
        );
    }, [identifier, shopsPaymentMethodsData]);

    React.useEffect(() => {
        if (isMessageValidateClicked === true) {
            let paymentMethod: any = allDataShops.find(
                (el: any) =>
                    `${el[t("Type of payment method")].toUpperCase()}` ===
                    `${choiceMode.toUpperCase()}`
            );

            addPaymentMethod(
                paymentMethod.id,
                paymentMethod[t("Type of payment method")],
                display,
                salesSupport.join(","),
                countryData.some((substring: any) =>
                    paymentMethod[t("Country")].split(",").includes(substring)
                ) === true
                    ? paymentMethod[t("Country")]
                    : `${paymentMethod[t("Country")]},${countryData.join(",")}`,
                tagsData.join(","),
                [userData.shopId],
                false,
                "The Payment method assignment was successful",
                true
            );
            if (
                countryData.some((substring: any) =>
                    paymentMethod[t("Country")].split(",").includes(substring)
                ) === false
            ) {
                addPaymentMethod(
                    paymentMethod.id,
                    paymentMethod[t("Type of payment method")],
                    paymentMethod[t("Display name")],
                    salesSupport.some((substring: any) =>
                        paymentMethod[t("Support de vente")]
                            .split(",")
                            .includes(substring)
                    ) === true
                        ? paymentMethod[t("Support de vente")]
                        : `${
                              paymentMethod[t("Support de vente")]
                          },${salesSupport.join(",")}`,
                    `${paymentMethod[t("Country")]},${countryData.join(",")}`,
                    tagsData.join(","),
                    ["0"],
                    false,
                    "false",
                    false
                );
            }
        }
        setIsMessageValidateClicked(false);
        setIsMessageModalOpened(false);
    }, [
        allDataShops,
        userData,
        isMessageValidateClicked,
        addPaymentMethod,
        t,
        choiceMode,
        countryData,
        tagsData,
        salesSupport,
        display,
    ]);

    React.useEffect(() => {
        dataFetch();
        //eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader className="d-flex align-items-center justify-content-between">
                    <h5
                        className={`${classnames(
                            "pt-3 mdr_txt_FRtitleFormPaymentMethod"
                        )}`}
                    >
                        {title}
                    </h5>
                    <CrossIcon
                        className="mdr_icn_FRcloseForm"
                        style={{ cursor: "pointer" }}
                        height={13}
                        width={13}
                        onClick={() => {
                            if (
                                isEdited === true &&
                                isAddChoiceClicked === true
                            ) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setDataChoice([]);
                                    setIsActionsClicked(false);
                                    setIsAddChoiceClicked(false);
                                    clearData();
                                    setIsEdited(false);
                                    clearMultiCustomSelectionData();
                                });
                            } else {
                                setDataChoice([]);
                                setIsAddChoiceClicked!(!isAddChoiceClicked);
                                setIsEdited(false);
                                clearData();
                                clearMultiCustomSelectionData();
                            }
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className="pl-3 pr-5"
                    style={{
                        maxHeight: "470px",
                        overflowY: " scroll",
                    }}
                >
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Payment method")}
                        </StyledLabel>

                        <StyledSelectInput
                            isClearable
                            className={classnames("mdr_inp_FRpaymentMethod", {
                                invalid__clz: inputChoiceMode || isNameExist,
                                "not-allowed-input__clz":
                                    paymentMethod.id !== undefined,
                            })}
                            options={paymentMethodList}
                            placeholder={`${t("Select")}…`}
                            value={
                                choiceMode === ""
                                    ? null
                                    : {
                                          label: choiceMode,
                                          value: choiceMode,
                                      }
                            }
                            onChange={(e: any) => {
                                e !== null
                                    ? setChoiceMode(e.label)
                                    : setChoiceMode("");
                                setIsEdited(true);
                                setInputChoiceMode(false);
                            }}
                            onCreateOption={(e: any) => {
                                setChoiceMode(e);
                                setIsEdited(true);
                                setInputChoiceMode(false);
                            }}
                            noOptionsMessage={() => t("No options")}
                            formatCreateLabel={formatCreateLabelPaymentMethod}
                        />
                        {inputChoiceMode ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a payment method")}
                            </div>
                        ) : null}
                        {isNameExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("The payment method already exists")!}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Display name")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames("mdr_inp_FRdisplayName", {
                                input__clz: inputDisplay,
                            })}
                            autocomplete="off"
                            name="choiceMode"
                            onChange={(e: any) => {
                                setIsEdited(true);
                                setDisplay(e.target.value);
                                setInputDisplay(false);
                            }}
                            value={display}
                            placeholder={t("Write")}
                            type="text"
                            autoFocus
                        />

                        <div className="d-flex flex-wrap"></div>
                        {inputDisplay ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a display name")}
                            </div>
                        ) : null}
                    </AvGroup>

                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Country")}
                        </StyledLabel>

                        <MultiCustomSelect
                            onDelete={() => {
                                setIsEdited(true);
                            }}
                            result={countryData}
                            setResult={setCountryData}
                            data={countryList}
                            onChange={(e: any) => {
                                setIsEdited(true);
                                setSelectCountry(false);
                            }}
                            className={classnames("mdr_inp_FRcountry", {
                                invalid__clz: selectCountry,
                            })}
                            placeholder={`${t("Select")}…`}
                            value={t("Choose")}
                            name="country"
                            blockedList={
                                oneShop ? countryList : allAssociatedCountryShop
                            }
                        />
                        {selectCountry ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a country")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Sale support")}
                        </StyledLabel>

                        <MultiCustomSelect
                            onDelete={() => {
                                setIsEdited(true);
                            }}
                            result={salesSupport}
                            setResult={setSalesSupport}
                            data={salesSupportData}
                            onChange={(e: any) => {
                                setIsEdited(true);
                                setInputSaleSupport(false);
                            }}
                            className={classnames("mdr_inp_FRsalesSupport", {
                                invalid__clz: inputSaleSupport,
                            })}
                            placeholder={`${t("Select")}…`}
                            value={t("Choose")}
                            name="saleSupport"
                        />

                        {inputSaleSupport ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a sale support")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel htmlFor="text" className="mt-3">
                            {t("Tag")}
                        </StyledLabel>

                        <MultiCustomCreatableSelection
                            onDelete={() => {
                                setIsEdited(true);
                            }}
                            className="mdr_inp_FRtags"
                            isSendingTag={isSendingTag}
                            setResult={setTagsData}
                            result={tagsData}
                            data={tags}
                            onChange={(e: any) => {
                                setIsEdited(true);
                            }}
                            placeholder={`${t("Select")}…`}
                            value={t("Choose")}
                            name="tag"
                            noOptionsMessage={() => t("No options")}
                            formatCreateLabel={formatCreateLabel}
                        />
                        <div className="d-flex flex-wrap"></div>
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="mr-2 mdr_btn_FRcancel"
                        variant="light"
                        onClick={() => {
                            if (isEdited === true) {
                                setActions(() => {
                                    setDataChoice([]);
                                    setIsActionsClicked(false);
                                    setIsAddChoiceClicked(false);
                                    clearData();
                                    setIsEdited(false);
                                    clearMultiCustomSelectionData();
                                });
                                setIsActionsClicked(!isActionsClicked);
                            } else {
                                setDataChoice([]);
                                setIsAddChoiceClicked(!isAddChoiceClicked);
                                clearData();
                                clearMultiCustomSelectionData();
                            }
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        disabled={isValidateButtonDisabled}
                        rounded
                        className="mdr_btn_FRvalidate"
                        onClick={() => {
                            setIsValidateButtonDisabled(true);
                            handleValidateButtonOnClickEvent();
                            setTimeout(() => {
                                setIsValidateButtonDisabled(false);
                            }, 500);
                        }}
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
            <ConfirmationEditMessage
                setDataIsUpdated={setGlobalDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddChoiceClicked}
                setIsAddClicked={setIsAddChoiceClicked}
                setEditedData={setDataChoice}
            />
            {isPaymentMethodAssociatedModified && (
                <ConfirmationModificationMessage
                    setIsSendingTag={setIsSendingTag}
                    setToastLimit={setToastLimit}
                    setDataChoice={setDataChoice}
                    shopsPaymentMethodsData={shopsPaymentMethodsData}
                    data={{
                        [identifier]: {
                            designation: choiceMode,
                            display: display,
                            shopId: userData.shopId,
                            saleSupport: salesSupport.join(","),
                            country: countryData.join(","),
                            tag: tagsData.join(","),
                            isActive: existingPaymentMethod.isActive,
                            isArchived: false,
                        },
                    }}
                    resetData={() => {
                        clearData();
                        clearMultiCustomSelectionData();
                        setTagsData([]);
                        setCountryData([]);
                        setSalesSupport([]);
                        clearData();
                        clearMultiCustomSelectionData();
                        setTagsData([]);
                        setCountryData([]);
                        setSalesSupport([]);
                    }}
                    isAddChoiceClicked={isAddChoiceClicked}
                    userData={userData}
                    setIsAddChoiceClicked={setIsAddChoiceClicked}
                    setIsEdited={setIsEdited}
                    isModalOpened={isPaymentMethodAssociatedModified}
                    setIsModalOpened={setIsPaymentMethodAssociatedModified}
                    idsAssociated={idsAssociated}
                    salesSupport={salesSupport.join(",")}
                    paymentMethod={paymentMethod}
                />
            )}
            {isMessageModalOpened ? (
                <ConfirmationMessage
                    msg={t(
                        "The payment method already exists in the franchise. Do you want to associate it with this shop"
                    )}
                    setIsModalOpened={setIsMessageModalOpened}
                    isModalOpened={isMessageModalOpened}
                    setIsValidateClicked={setIsMessageValidateClicked}
                />
            ) : null}
            {isArchivedFranchiseModalOpened ? (
                <ConfirmationModal
                    isConfirmationModal={isArchivedFranchiseModalOpened}
                    setIsConfirmationModal={setIsArchivedFranchiseModalOpened}
                />
            ) : null}
        </React.Fragment>
    );
}
