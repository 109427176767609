import React from "react";

import "./index.css";

type PropsType = {
    globalColor?: ColorType;
    secondColor?: ColorType;
    opacity?: string;
    width?: number;
    height?: number;
};

export function AddIcon({
    opacity,
    globalColor,
    secondColor,
    width,
    height,
}: PropsType): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width !== undefined ? width : "55"}
            height={height !== undefined ? height : "46"}
            viewBox="0 0 68 68"
            style={{
                opacity,
            }}
        >
            <defs>
                <filter
                    id="Ellipse_1120"
                    width={width}
                    height={height}
                    x="0"
                    y="0"
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset dy="3"></feOffset>
                    <feGaussianBlur
                        result="blur"
                        stdDeviation="3"
                    ></feGaussianBlur>
                    <feFlood floodOpacity="0.243"></feFlood>
                    <feComposite in2="blur" operator="in"></feComposite>
                    <feComposite in="SourceGraphic"></feComposite>
                </filter>
            </defs>
            <g data-name="Groupe 30414" transform="translate(8.69 5.755)">
                <g data-name="Groupe 26897">
                    <g
                        filter="url(#Ellipse_1120)"
                        transform="translate(-8.69 -5.76)"
                    >
                        <circle
                            cx="26"
                            cy="26"
                            r="26"
                            fill="#FFFFFF"
                            data-name="Ellipse 1120"
                            transform="translate(9 6)"
                            stroke="none"
                        ></circle>
                    </g>
                    <g
                        data-name="Groupe 26895"
                        transform="translate(1.31 1.004)"
                    >
                        <path
                            fill={globalColor}
                            d="M25 0A25 25 0 110 25 25 25 0 0125 0z"
                            data-name="Tracé 21196"
                            transform="translate(0 .241)"
                            stroke="none"
                        ></path>
                        <g
                            fill="#FFFFFF"
                            stroke="#FFFFFF"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            data-name="Icon feather-plus"
                            transform="translate(19.09 18.291)"
                        >
                            <path
                                d="M6.449 0v13.689"
                                data-name="Tracé 2454"
                                fill="#FFFFFF"
                                stroke={secondColor}
                                strokeWidth="2"
                            ></path>
                            <path
                                d="M0 6.844h12.9"
                                data-name="Tracé 2455"
                                fill="#FFFFFF"
                                stroke={secondColor}
                                strokeWidth="2"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

type AddIconWrapperPropTypes = {
    onClick: () => void;
};

export function AddIconWrapper({
    onClick,
    ...props
}: AddIconWrapperPropTypes & PropsType): JSX.Element {
    return (
        <button className="icons-button__clz " onClick={onClick}>
            <AddIcon {...props} />
        </button>
    );
}
