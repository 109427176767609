import { StyledH2 } from "@aureskonnect/react-ui";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { ModalBody } from "reactstrap";
import { useSnapshot } from "valtio";

import { setLoyaltyAccount, store } from "../store";

export default function LoyaltyAccount() {
    const { t } = useTranslation();
    const { dataSetupApk } = useSnapshot(store);
    const [isActivation, setIsActivation] = React.useState<boolean>(
        dataSetupApk[0] !== undefined &&
            dataSetupApk[0].loyaltyAccount.isActivation !== undefined
            ? dataSetupApk[0].loyaltyAccount.isActivation
            : false
    );
    const [isAuthorized, setIsAuthorized] = React.useState<boolean>(
        dataSetupApk[0] !== undefined &&
            dataSetupApk[0].loyaltyAccount !== undefined
            ? dataSetupApk[0].loyaltyAccount.isAuthorized
            : false
    );

    useEffect(() => {
        setLoyaltyAccount({ isActivation, isAuthorized });
    }, [dataSetupApk, isActivation, isAuthorized]);

    return (
        <React.Fragment>
            <ModalBody style={{ height: "84.1%", fontSize: "17px" }}>
                <StyledH2 className="m-3">{t("Loyalty account")}</StyledH2>
                <div className="d-flex  align-items-center justify-content-between mt-2">
                    <div className="ml-3">
                        {t("Activation of the loyalty account")}
                    </div>
                    <div className="float-right mr-4">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isActivation}
                            onChange={(e: any) => {
                                setIsActivation(!isActivation);
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={55}
                            height={20}
                        />
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-3">
                    <div className="ml-3">
                        {t("Authorize payment by loyalty points")}
                    </div>
                    <div className="float-right mr-4">
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={isAuthorized}
                            onChange={(e: any) => {
                                setIsAuthorized(!isAuthorized);
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={55}
                            height={20}
                        />
                    </div>
                </div>
            </ModalBody>
        </React.Fragment>
    );
}
