import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { CheckboxIcon, StyledH2, StyledModal } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";

import { store } from "@components/VerticalLayout/store";
import {
    store as generalSettingStore,
    setIsAssociateModalOpened,
    setNumberMultiShopActive,
    setIds,
    setSelectedRow,
    setIsAssociatedLangues,
    setMessage,
    setGlobalDataIsUpdated,
} from "../store";

import { DisplayName } from "../DisplayName";
import { CustomIconsAssociateModal } from "./CustomIconsAssociateModal";
import ErrorToast from "@components/Common/ErrorTost";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "../index.css";

export function LanguageAssociateModal({ isArchivedLanguagesClicked }: any) {
    const { t } = useTranslation();
    const {
        isAssociateModalOpened,
        localShop,
        numberMultiShopActive,
        idsLocalShop,
        associatedLanguages,
        activeLanguages,
        favoredLanguages,
        shopRowData,
    } = useSnapshot(generalSettingStore);

    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const shopId = 0;
    const url =
        localShop.length === 1
            ? `${process.env.REACT_APP_API_V2_URL}/settings/general/filtered_archived_language?userId=${userID}&shopId=${localShop[0]}&franchiseId=${franchiseID}`
            : `${process.env.REACT_APP_API_V2_URL}/settings/general/language?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`;
    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 2,
            columnName: t("Langue"),
            customJsx: (e: any) => <NumberLangues e={e} />,
        },
        {
            indexOFColumn: 1,
            columnName: t("Display name"),
            customJsx: (e: any) => <DisplayName e={e} />,
        },
        {
            indexOFColumn: 3,
            columnName: t("Pays"),
            customJsx: (e: any) => <GetCountry e={e} />,
        },
        {
            indexOFColumn: 7,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIconsAssociateModal
                    e={e}
                    isArchivedLanguagesClicked={isArchivedLanguagesClicked}
                />
            ),
        },
    ];

    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [error, setError] = React.useState<boolean>(false);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );

    function checkIfLanguageAssociated(shopId: string) {
        let associated = shopRowData.map((elt: any) => {
            if (elt.shopId === shopId) {
                return elt.subRows;
            } else {
                return null;
            }
        });
        return associated.filter((el: any) => el);
    }

    function NumberLangues(e: any) {
        return (
            <React.Fragment>
                {e.e.selectedRow?.subRows !== undefined ? (
                    <h6 className="badge__clz">
                        {e.e.selectedRow?.Boutique !== ""
                            ? e.e.selectedRow.subRows?.length
                            : 0}
                    </h6>
                ) : (
                    e.e.selectedRow["Langue"]
                )}
            </React.Fragment>
        );
    }

    function GetCountry(e: any) {
        return (
            <React.Fragment>
                {e.e.selectedRow?.subRows !== undefined
                    ? e.e.selectedRow.pays
                    : e.e.selectedRow["Pays"]}
            </React.Fragment>
        );
    }

    async function AssociateLanguage() {
        toast.dismiss();
        let localData: any = [];
        for (let element of localShop) {
            let associated = checkIfLanguageAssociated(element);
            let objectData = {};
            if (selectedRows.length !== 0) {
                selectedRows.forEach(async (el: any) => {
                    if (
                        associated.findIndex((elt: any) => elt.Id === el.Id) ===
                        -1
                    ) {
                        const id: any = el.Id;
                        Object.assign(objectData, {
                            [id]: {
                                id: id,
                                language: el.Language,
                                display_name: el.display_name,
                                country: el.country,
                                urlImage: el.url_image,
                                isArchived: el.isArchived,
                                isFavorite: favoredLanguages.find(
                                    (element: any) => element.Id === el.Id
                                ).isFavored,
                                isActive: activeLanguages.find(
                                    (element: any) => element.Id === el.Id
                                ).state,
                                shopId: element,
                                abbreviation: el.abbreviation,
                            },
                        });
                    }
                });
                let dataLanguage = {
                    userId: userID,
                    shopId: [element],
                    franchiseId: franchiseID,
                    operatorId: operatorID,
                    data: objectData,
                    isShop: true,
                    isAssociate: true,
                };
                const data = new FormData();
                data.append("dataLanguage", JSON.stringify(dataLanguage));
                data.append("image", "");
                localData.push(
                    fetch(
                        `${process.env.REACT_APP_API_V2_URL}/settings/general/language`,
                        {
                            method: "POST",
                            body: data,
                        }
                    ).then((response) => response.json())
                );
            }
        }
        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/language`,
                await Promise.all(localData).then((result) => {
                    result.forEach((element: any) => {
                        if (element.error === true) setError(true);
                    });
                })
            );
        } catch (e) {
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
        if (error === false) {
            toast.success(`${t("The assignment was successful")}`, {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",
                icon: <CheckboxIcon height={25} width={25} fill="white" />,
            });
            setDataIsUpdated!(true);
            setNumberMultiShopActive(
                numberMultiShopActive + selectedRows.length * localShop.length
            );
            setGlobalDataIsUpdated(true);
        }
    }

    function CountIds() {
        let number = 0;
        idsLocalShop.forEach((el: any) => {
            selectedRows.forEach((v: any) => {
                if (v.Id === el) {
                    number = number + 1;
                }
            });
        });
        setIds(number);
    }

    function checkIfLanguageExistAndArchived() {
        let archived: any[] = [];
        if (shopRowData.length > 0) {
            shopRowData.forEach((element: any) => {
                if (element?.archivedLanguages !== undefined) {
                    element?.archivedLanguages.forEach((el: any) => {
                        archived.push(el.Id);
                    });
                }
            });
        }
        return archived;
    }

    const handleValidateButtonOnClickEvent = () => {
        let archived = checkIfLanguageExistAndArchived();
        if (selectedRows.length === 0) {
            ErrorToast(
                `${t("Attention ! You must select at least one language")!}`
            );
        } else {
            CountIds();
            try {
                setSelectedRow(selectedRows);
                selectedRows.forEach((el: any) => {
                    associatedLanguages.forEach((element: any) => {
                        if (
                            element.Id === el.Id &&
                            (element.isActive !==
                                activeLanguages.find(
                                    (elt: any) => elt.Id === el.Id
                                ).state ||
                                element.isFavorite !==
                                    favoredLanguages.find(
                                        (elt: any) => elt.Id === el.Id
                                    ).isFavored)
                        ) {
                            setMessage(
                                t(
                                    'Do you want to apply these changes for all stores? \n Attention ! If you click on "Validate", the specific settings of the shops will be modified.'
                                )
                            );
                            setIsAssociatedLangues(true);
                            // eslint-disable-next-line
                            throw "Break";
                        }
                    });
                });
                selectedRows.forEach((el: any) => {
                    if (archived.includes(el.Id)) {
                        setMessage(
                            t(
                                "Attention! One or more selected languages are already assigned and archived at the store level.Would you unarchive them?"
                            )
                        );
                        setIsAssociatedLangues(true);
                        // eslint-disable-next-line
                        throw "Break";
                    }
                });
                AssociateLanguage();
                setIsAssociateModalOpened(!isAssociateModalOpened);
            } catch (e) {
                if (e !== "Break") throw e;
            }
        }
    };

    return (
        <StyledModal
            toggle={true}
            isOpen={isAssociateModalOpened!}
            centered
            className="modal-wrapper__clz"
            backdrop="static"
            fade={false}
            scrollable
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsAssociateModalOpened(!isAssociateModalOpened);
                    setGlobalDataIsUpdated(true);
                }}
            >
                <StyledH2 className="text-uppercase lan_txt_AFlistOfLanguages">
                    {t("List of languages")}
                </StyledH2>
            </ModalHeader>
            <ModalBody>
                <DynamicTable
                    url={url}
                    canSelect
                    setSelectedRows={setSelectedRows}
                    customSelect
                    canResize
                    actionColumn={ActionColumn}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    showGlobalFilter
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    elevationTable={1}
                    minHeight="500px"
                    name="associateLanguage"
                />
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsAssociateModalOpened(!isAssociateModalOpened);
                        setGlobalDataIsUpdated(true);
                    }}
                    rounded
                    className="lan_btn_AFcancel"
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    variant="primary"
                    onClick={handleValidateButtonOnClickEvent}
                    className="lan_btn_AFvalidate"
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
