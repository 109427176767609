import React from "react";
import { StyledLabel } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Col, ModalFooter } from "reactstrap";
import { AvField } from "availity-reactstrap-validation";
import { ipMatchWord } from "@constants/index";
import classnames from "classnames";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "react-toastify/dist/ReactToastify.css";
import "react-dropzone-uploader/dist/styles.css";

type PeripheralsModalConfigurationsType = {
    setIsConfiguredCashManagementClicked: Function;
    isConfiguredCashManagementClicked: boolean;
    data: any;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<boolean | number>>;
    ipAddress: string;
    port: string;
    setIpAddress: Function;
    setPort: Function;
    setServerDirectory: Function;
    serverDirectory: string;
    addConfiguration: Function;
};

export default function CasdroConfigurations({
    ipAddress,
    port,
    setIpAddress,
    setPort,
    setIsConfiguredCashManagementClicked,
    isConfiguredCashManagementClicked,
    data,
    setDataIsUpdated,
    setServerDirectory,
    serverDirectory,
    addConfiguration,
}: PeripheralsModalConfigurationsType) {
    const { t } = useTranslation();

    const [InputPort, setInputPort] = React.useState<boolean>(false);
    const [InputIp, setInputIp] = React.useState<boolean>(false);

    const numberMatchWord = new RegExp(/^[1-9]\d*$/);

    return (
        <React.Fragment>
            <div className="d-flex">
                <Col xs="12" sm="6">
                    <StyledLabel
                        htmlFor="example-input"
                        className="required__clz"
                    >
                        {t("IP address")}
                    </StyledLabel>
                    <AvField
                        className={classnames({
                            input__clz: InputIp,
                        })}
                        autocomplete="off"
                        id="ipadress"
                        name="ipadress"
                        placeholder={t("Enter")}
                        type="text"
                        onChange={(e: any) => {
                            if (!e.target.value.match(ipMatchWord)) {
                                setInputIp(true);
                            } else {
                                setIpAddress(e.target.value);
                                setInputIp(false);
                            }
                        }}
                        value={ipAddress}
                    />
                    {InputIp && (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t(
                                ipAddress === ""
                                    ? "Please enter a IP address"
                                    : "IP address is invalid"
                            )}
                        </div>
                    )}
                </Col>

                <Col xs="12" sm="6">
                    <StyledLabel
                        htmlFor="example-input"
                        className="required__clz"
                    >
                        {t("Port number")}
                    </StyledLabel>
                    <AvField
                        className={classnames({
                            input__clz: InputPort,
                        })}
                        autocomplete="off"
                        id="port"
                        name="port"
                        placeholder={t("Enter")}
                        type="text"
                        onChange={(e: any) => {
                            setPort(e.target.value);
                            setInputPort(false);
                        }}
                        value={port}
                        validate={{
                            pattern: {
                                value: numberMatchWord,
                                errorMessage: t("Port number is invalid"),
                            },
                        }}
                    />
                    {InputPort && (
                        <div
                            style={{
                                width: "100%",
                                marginTop: "0.25rem",
                                fontSize: "80%",
                                color: "#f46a6a",
                            }}
                        >
                            {t("Please enter a port number")}
                        </div>
                    )}
                </Col>
            </div>

            <div className="d-flex mt-3">
                <Col xs="12" sm="6">
                    <StyledLabel htmlFor="example-input">
                        {t("Log directory")}
                    </StyledLabel>
                    <div>
                        <AvField
                            autocomplete="off"
                            id="serverDirectory"
                            name="serverDirectory"
                            type="text"
                            onChange={(e: any) =>
                                setServerDirectory(e.target.value)
                            }
                            value={serverDirectory}
                            defaultValue={`${".\\logMonnayeur"}`}
                        />
                    </div>
                </Col>
            </div>

            <div className="d-flex"></div>

            <ModalFooter>
                <CustomSecondaryColorButton
                    outline
                    rounded
                    type="button"
                    className="mr-2"
                    variant="light"
                    onClick={() => {
                        setIsConfiguredCashManagementClicked!(
                            !isConfiguredCashManagementClicked
                        );
                        setDataIsUpdated!(true);
                    }}
                >
                    <span> {t("Cancel")}</span>
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    variant="primary"
                    type="submit"
                    className="pull-right w-auto h-50"
                    onClick={() => {
                        if (!ipAddress.match(ipMatchWord)) {
                            setInputIp(true);
                        }
                        if (port === "") {
                            setInputPort(true);
                        }
                        if (
                            ipAddress?.length !== 0 &&
                            port?.length !== 0 &&
                            Number(port) &&
                            ipAddress.match(ipMatchWord)
                        )
                            addConfiguration();
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </React.Fragment>
    );
}
