import React from "react";
import classnames from "classnames";
import ReactSwitch from "react-switch";
import {
    CheckboxIcon,
    EyeIcon,
    HeartIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { mutate } from "swr";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";

import {
    store as generalSettingStore,
    setNumberMultiShopArchived,
    setNumberMultiShopActive,
    setIsValidateShopArchiveOpened,
} from "../store";
import { store } from "@components/VerticalLayout/store";

import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";

type CustomIconsType = {
    e: any;
    setShopId: Function;
    setRowData: Function;
    setDataIsUpdated: Function;
    setIsHistoryModalOpened: Function;
    isHistoryModalOpened: boolean;
    isArchivedLanguagesClicked: boolean;
    setSelectedRows: Function;
};

export function CustomIcons({
    e,
    setShopId,
    setRowData,
    setDataIsUpdated,
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    setSelectedRows,
    isArchivedLanguagesClicked,
}: CustomIconsType) {
    const { t } = useTranslation();
    const {
        numberMultiShopArchived,
        numberMultiShopActive,
        languagesData,modify
    } = useSnapshot(generalSettingStore);
    const { userID, franchiseID, operatorID } = useSnapshot(store);

    const [languageState, setLanguageState] = React.useState<boolean>(
        e.selectedRow.isActive
    );

    async function activeLanguage() {
        toast.dismiss();
        const id = e.selectedRow.Id;
        let dataLanguage = {
            userId: userID,
            shopId: [e.selectedRow.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            data: {
                [id]: {
                    id: id,
                    language: e.selectedRow.Language,
                    display_name: e.selectedRow.display_name,
                    country: e.selectedRow.country,
                    urlImage: e.selectedRow.url_image,
                    isArchived: false,
                    isFavorite: e.selectedRow.isFavorite,
                    isFavoriteShop: e.selectedRow.isFavorite,
                    isActive: e.selectedRow.isActive,
                    shopId: e.selectedRow.shopId,
                    isActiveShop: e.selectedRow.isActive,
                    abbreviation: e.selectedRow.abbreviation,
                },
            },
        };
        const data = new FormData();
        data.append("dataLanguage", JSON.stringify(dataLanguage));
        data.append("image", "");
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/language`;
        const requestOptions = {
            method: "POST",
            body: data,
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t("The language was successfully unarchived")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated!(true);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function favoriteLanguage(isFavorite: boolean) {
        toast.dismiss();
        const id = e.selectedRow.Id;
        let dataLanguage = {
            userId: userID,
            shopId: [e.selectedRow.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            data: {
                [id]: {
                    id: id,
                    language: e.selectedRow.Language,
                    display_name: e.selectedRow.display_name,
                    country: e.selectedRow.country,
                    urlImage: e.selectedRow.url_image,
                    isArchived: e.selectedRow.isArchived,
                    isFavorite: isFavorite,
                    isFavoriteShop: isFavorite,
                    isActive: e.selectedRow.isActive,
                    shopId: e.selectedRow.shopId,
                    isActiveShop: e.selectedRow.isActive,
                    abbreviation: e.selectedRow.abbreviation,
                },
            },
        };
        const data = new FormData();
        data.append("dataLanguage", JSON.stringify(dataLanguage));
        data.append("image", "");
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/language`;
        const requestOptions = {
            method: "POST",
            body: data,
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        setDataIsUpdated!(true);
                        toast.success(
                            isFavorite
                                ? `${t(
                                      "Favorite state has been enabled successfully"
                                  )}`
                                : `${t(
                                      "Favorite state has been disabled successfully"
                                  )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function enableLanguage() {
        toast.dismiss();
        const id = e.selectedRow.Id;
        let dataLanguage = {
            userId: userID,
            shopId: [e.selectedRow.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            data: {
                [id]: {
                    id: id,
                    language: e.selectedRow.Language,
                    display_name: e.selectedRow.display_name,
                    country: e.selectedRow.country,
                    urlImage: e.selectedRow.url_image,
                    isArchived: e.selectedRow.isArchived,
                    isFavorite: e.selectedRow.isFavorite,
                    isFavoriteShop: e.selectedRow.isFavorite,
                    isActive: !languageState,
                    shopId: e.selectedRow.shopId,
                    isActiveShop: !languageState,
                    abbreviation: e.selectedRow.abbreviation,
                },
            },
        };
        const data = new FormData();
        data.append("dataLanguage", JSON.stringify(dataLanguage));
        data.append("image", "");
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/language`;
        const requestOptions = {
            method: "POST",
            body: data,
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        setDataIsUpdated!(true);
                        toast.success(
                            languageState
                                ? `${t(
                                      "The language was successfully deactivated"
                                  )}`
                                : `${t(
                                      "The language was successfully activated"
                                  )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    function ArchivedLanguages() {
        let idsAssociated: any[] = [];
        if (languagesData !== undefined) {
            languagesData.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }
    let dataArchive = ArchivedLanguages();

    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    <Tooltip
                        title={
                            isArchivedLanguagesClicked
                                ? ""
                                : e.selectedRow.isFavorite === false
                                ? t("Enable favorite state")
                                : t("Disable favorite state")
                        }
                    >
                        <div>
                            <HeartIcon
                                onClick={() => {
                                    setRowData(e.selectedRow);
                                    setShopId(e.selectedRow.shopId);
                                    favoriteLanguage(!e.selectedRow.isFavorite);
                                }}
                                height={25}
                                width={25}
                                name="heart"
                                fill={
                                    e.selectedRow.isFavorite ? "red" : "black"
                                }
                                className={`${classnames(
                                    "pointer__clz lan_icn_BTlike",
                                    {
                                        "not-allowed-icon__clz": isArchivedLanguagesClicked,
                                    }
                                )}`}
                            />
                        </div>
                    </Tooltip>
                    {modify === true ? (
                        <>
                            <Tooltip
                                title={
                                    isArchivedLanguagesClicked
                                        ? ""
                                        : languageState === false
                                        ? t("Activate")
                                        : t("Unactivate")
                                }
                            >
                                <div>
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={languageState}
                                        onChange={async () => {
                                            setLanguageState(!languageState);
                                            enableLanguage();
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                        className={`${classnames(
                                            "pointer__clz cmn_icn_BTenableDisable ml-3",
                                            {
                                                "not-allowed-icon__clz": isArchivedLanguagesClicked,
                                            }
                                        )}`}
                                    />
                                </div>
                            </Tooltip>

                            {!isArchivedLanguagesClicked ? (
                                <Tooltip title={t("Archive")}>
                                    <div>
                                        <TrashIcon
                                            onClick={() => {
                                                setSelectedRows([
                                                    e.selectedRow,
                                                ]);
                                                setIsValidateShopArchiveOpened(
                                                    true
                                                );
                                            }}
                                            height={25}
                                            width={25}
                                            fill="red"
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            className="cmn_icn_BTarchived ml-3"
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={
                                        dataArchive.includes(e.selectedRow.Id)
                                            ? ""
                                            : t("Unarchive")
                                    }
                                >
                                    <div>
                                        <EyeIcon
                                            height={25}
                                            width={25}
                                            onClick={() => {
                                                activeLanguage();
                                                setNumberMultiShopArchived(
                                                    numberMultiShopArchived - 1
                                                );
                                                setNumberMultiShopActive(
                                                    numberMultiShopActive + 1
                                                );
                                            }}
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            className={`${classnames(
                                                "pointer__clz cmn_icn_BTunarchive ml-3",
                                                {
                                                    "not-allowed-icon__clz": dataArchive.includes(
                                                        e.selectedRow.Id
                                                    ),
                                                }
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : null}
                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                style={{ cursor: "pointer" }}
                                className="pointer__clz cmn_icn_BThistory ml-3"
                                onClick={() => {
                                    setSelectedRows([e.selectedRow]);
                                    setRowData(e.selectedRow);
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );
                                }}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
        </React.Fragment>
    );
}
