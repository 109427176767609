import { t } from "i18next";
import { proxy } from "valtio";

type StoreType = {
    applications: any[];
    applicationsToBeDeleted: any[];
    applicationListData: any
};

export const store = proxy<StoreType>({
    applications: [],
    applicationsToBeDeleted: [],
    applicationListData: []
});

export function setApplications(application: any) {
    var duplicatedData: any = store.applications.filter(
        (el: any) => el[t("appId")] === application[t("appId")]
    );

    if (duplicatedData.length !== 0) {
        store.applications = store.applications.filter(
            (el: any) => el[t("appId")] !== application[t("appId")]
        );
    } else store.applications.push(application);
}

export function setApplicationsToBeDeleted(appToBeDeleted: any) {
    var duplicatedData: any = store.applicationsToBeDeleted.filter(
        (el: any) => el[t("appId")] === appToBeDeleted[t("appId")]
    );

    if (duplicatedData.length !== 0) {
        store.applicationsToBeDeleted = store.applicationsToBeDeleted.filter(
            (el: any) => el[t("appId")] !== appToBeDeleted[t("appId")]
        );
    } else store.applicationsToBeDeleted.push(appToBeDeleted);
}

export function setApplicationListData(applicationListData: any): void {
    store.applicationListData = applicationListData;
}

export function resetStore() {
    store.applications = [];
    store.applicationsToBeDeleted = [];
}

export default store;
