import React, { useEffect, useState } from "react";
import {
    CheckboxIcon,
    CrossIcon,
    StyledDangerButton,
    StyledLabel,
    StyledSelectInput,
    StyledTextInput,
    StyledButton,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";

import {
    AvForm,
    AvRadioGroup,
    AvRadio,
    AvGroup,
} from "availity-reactstrap-validation";

import { mutate } from "swr";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";
import classnames from "classnames";

import { store } from "../../../components/VerticalLayout/store";
import { resetAllPeripherals } from "@pages/Applications/DeviceAssociateModal/store";
import {
    store as pathStore,
    setIsActionsClicked,
    setIsEdited,
    setActions,
    setValidationAction,
} from "./store";

import { verifyIfPeripheralAssociate } from "../../../helpers/peripheralsHelpers";
import { getUniqueId } from "@helpers/general";

import {
    CustomCardBody,
    CustomCardFooter,
    CustomCardHeader,
} from "@components/Common/CustomCardStyled";
import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import ErrorToast from "@components/Common/ErrorTost";
import { useCallbackPrompt } from "@hooks/useCallbackPrompt";
import { ConfirmationUpdatingDataMessageModal } from "@components/Common/ConfirmationUpdatingDataMessageModal";

import PrintersPathsModal from "./PrintersPathsModal";
import { ipMatchWord, numberMatchWord, SPEEDS } from "../../../constants";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "./index.css";

type PrintersType = {
    setIsAddPrinterClicked: Function;
    setDataIsUpdated: Function;
    isAddPrinterClicked: boolean;
    editedData: any;
    setEditedData: Function;
    isAddNewPrinterButtonClicked: boolean;
    shopIdPrinter: string;
    localShopId?: any;
    inputDesignation: boolean;
    setInputDesignation: Function;
    inputIp: boolean;
    setInputIp: Function;
    dataIsUpdated?: boolean | number;
    dataTable?: any;
    setIsUpdateOperation: Function;
    isConsult: boolean;
    setIsConsult: Function;
};

export default function PrintersConfig({
    setIsAddPrinterClicked,
    isAddPrinterClicked,
    setDataIsUpdated,
    editedData,
    setEditedData,
    isAddNewPrinterButtonClicked,
    shopIdPrinter,
    localShopId,
    inputDesignation,
    setInputDesignation,
    inputIp,
    setInputIp,
    dataIsUpdated,
    dataTable,
    setIsUpdateOperation,
    isConsult,
    setIsConsult,
}: PrintersType) {
    const { t } = useTranslation();
    const {
        userID,
        franchiseID,
        shopID,
        oneShop,
        resourceIp,
        operatorID,
    } = useSnapshot(store);
    const {
        selected,
        path,
        isActionsClicked,
        isEdited,
        actions,
        validationAction,
    } = useSnapshot(pathStore);

    const matchWordDesignation = new RegExp(
        /^[\w|é|è|û|É|È|Û|à|À|\s]+([\s][A-Za-z0-9]+)*$/i
    );
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        isEdited
    );
    let ips: string[] = [];
    const localNamesPrinters: string[] = [];
    const pathname = window.location.pathname.toString();

    const [printerType, setPrinterType] = useState<string>(
        editedData.type !== undefined ? editedData.type : "ip"
    );

    const [printerPath, setPrinterPath] = useState<string>("");
    const [designation, setDesignation] = useState<string>("");
    const [mark, setMark] = useState<string>("");
    const [remark, setRemark] = useState<string>("");
    const [speed, setSpeed] = useState<string | number>(19200);
    const [ip, setIp] = useState<string>("");
    const [, setPortNum] = useState<string>(
        printerType === "series" ? "COM 2" : "LPT 2"
    );
    const [fontSize, setFontSize] = useState<string | number>(10);
    const [ticketWidth, setTicketWidth] = useState<string | number>(40);
    const [ipPortNumber, setIpPortNumber] = useState<string>("7000");
    const [portSeries, setPortSeries] = useState<string>("COM 2");

    const [
        isPrintersPathsModalOpened,
        setIsPrintersPathsModalOpened,
    ] = useState<boolean>(false);
    const [exist, setExist] = useState({
        isNameExist: false,
        isIpExist: false,
    });
    const [emptyIp, setEmptyIp] = React.useState<boolean>(false);
    const [canValidate, setCanValidate] = React.useState<boolean>(true);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);

    const isUsbPrinter = printerType === "usb";
    const isIpPrinter = printerType === "ip";
    const appId = "KIOSK";
    let ifPeripheralAssociateObject: {
        isPeripheralAssociate: boolean;
        appId_children: any[];
    };
    React.useEffect(() => {
        fetchDataIp_Name();
        uniqueDesignationIp(designation, "designation");

        // eslint-disable-next-line
    }, [dataIsUpdated, localShopId, designation]);

    const fetchDataIp_Name = () => {
        let printerData: any = [];
        if (dataTable.data !== undefined) {
            if (dataTable !== undefined && dataTable.data.length > 0) {
                if (dataTable.data[0][t("Shop")] !== undefined) {
                    if (localShopId.length === 1) {
                        printerData = dataTable.data.find((x: any) => {
                            return x.shopId === localShopId[0];
                        })?.subRows;
                    }
                } else if (dataTable.data[0][t("Shop")] === undefined) {
                    printerData = dataTable.data;
                }
            }
        }

        printerData.forEach((datum: any) => {
            localNamesPrinters.push(datum[t("Printer name")]?.toUpperCase());
        });
        ips = resourceIp
            // eslint-disable-next-line array-callback-return
            .filter((x: any) => {
                if (x.shopId === localShopId[0]) {
                    return x.ip;
                }
            })
            .map((x: any) => {
                return x.ip;
            });
    };

    const fontSizeArray: {
        label: number;
        value: number;
    }[] = [...Array(16).keys()].map((e: any, index: number) => {
        return {
            label: index + 1,
            value: index + 1,
        };
    });

    const ticketWidthArray: {
        label: number;
        value: number;
    }[] = [...Array(7).keys()].map((e: any, index: number) => {
        return {
            label: index + 34,
            value: index + 34,
        };
    });

    const portNumArray: {
        label: string;
        value: string;
    }[] = [...Array(32).keys()].map((e: any, index: number) => {
        if (printerType === "series")
            return {
                label: `COM ${index + 1}`,
                value: `COM ${index + 1}`,
            };
        return { label: `LPT ${index + 1}`, value: `LPT ${index + 1}` };
    });

    const printerSpeedArray: {
        label: number;
        value: number;
    }[] = SPEEDS.map((element: number) => {
        return {
            label: element,
            value: element,
        };
    });

    function uniqueDesignationIp(data: any, type: string) {
        switch (type) {
            case "designation":
                if (
                    (localNamesPrinters.includes(data.toUpperCase()) &&
                        editedData.Id === undefined) ||
                    (localNamesPrinters.includes(data.toUpperCase()) &&
                        editedData.Id !== undefined &&
                        editedData[t("Printer name")].toUpperCase() !==
                            data.toUpperCase())
                ) {
                    setExist((prevState: any) => ({
                        ...prevState,
                        isNameExist: true,
                    }));
                } else {
                    setExist((prevState: any) => ({
                        ...prevState,
                        isNameExist: false,
                    }));
                }
                break;
            case "ip":
                if (
                    (ips.includes(data) && editedData.Id === undefined) ||
                    (ips.includes(data) &&
                        editedData.Id !== undefined &&
                        editedData[t(`IP/port`)] !== data)
                ) {
                    setExist((prevState: any) => ({
                        ...prevState,
                        isIpExist: true,
                    }));
                } else {
                    setExist((prevState: any) => ({
                        ...prevState,
                        isIpExist: false,
                    }));
                }
                break;
        }
    }

    async function handleEditButtonClickEvent() {
        toast.dismiss();
        resetAllPeripherals();
        if (canValidate) {
            setCanValidate(false);
            let userId: number;
            let shopId: string;
            let franchiseId: string;
            if (oneShop) {
                userId = userID;
                shopId = shopID.toString();
                franchiseId = franchiseID;
            } else {
                userId = userID;
                shopId = shopIdPrinter.toString();
                franchiseId = franchiseID;
            }
            ifPeripheralAssociateObject = await verifyIfPeripheralAssociate(
                editedData["Id"],
                shopId,
                franchiseID,
                userID,
                appId
            );
            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/printer?isPeripheralAssociate=${ifPeripheralAssociateObject.isPeripheralAssociate}`;

            let savedData = {
                userId: userId,
                shopId: shopId,
                franchiseId: franchiseId,
                operatorId: operatorID,
                appId: appId,
                appId_children: ifPeripheralAssociateObject.appId_children,
                peripheralId: editedData["Id"],
                data: [
                    {
                        id: editedData["Id"],
                        designation: designation,
                        mark: mark,
                        note: remark,
                        shopId: shopId,
                        type: printerType,
                        speed: speed,
                        path: printerPath,
                        ip: ip,
                        port: ipPortNumber,
                        font_size: fontSize,
                        edit_date: new Date(),
                        add_date: "",
                        ticket_width: ticketWidth,
                        port_series: portSeries,
                        isArchived: false,
                    },
                ],
            };

            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bareer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify(savedData),
                        method: "PUT",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                setCanValidate(true);
                                throw Error("Error!");
                            }
                            setCanValidate(true);
                            toast.success(
                                `${t("The device is successfully changed")!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setIsAddPrinterClicked(!isAddPrinterClicked);
                            setDataIsUpdated(true);
                            setEditedData([]);
                            setIsEdited(false);
                            activateCheckbox();
                        })
                );
            } catch (e: any) {
                setCanValidate(true);
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }
    function IsExistPeripheral(data: any, des: string) {
        let res: boolean = true;
        for (let index = 0; index < data.length; index++) {
            const element = data[index][t("Printer name")]?.toString();
            if (element.toUpperCase() === des.toUpperCase()) {
                res = false;
            }
        }
        return res;
    }
    async function handleValidateButtonClickEvent() {
        toast.dismiss();
        resetAllPeripherals();
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/printer`;
        let userId: number;
        let franchiseId: string;
        if (oneShop) {
            localShopId = [shopID.toString()];
        }

        if (localShopId.length === 0) {
            ErrorToast(`${t("Attention ! you must select a store.")!}`);
        } else if (localShopId.length >= 1) {
            if (canValidate) {
                setCanValidate(false);
                try {
                    let action: boolean = false;
                    for (let index = 0; index < localShopId.length; index++) {
                        const element = localShopId[index];
                        userId = userID;
                        franchiseId = franchiseID;
                        let savedData = {
                            userId: userId,
                            shopId: element,
                            franchiseId: franchiseId,
                            operatorId: operatorID,
                            data: [
                                {
                                    id: getUniqueId(10),
                                    designation: designation.trim(),
                                    mark: mark,
                                    note: remark,
                                    shopId: element,
                                    type: printerType,
                                    speed: printerType !== "ip" ? speed : "",
                                    path: printerPath,
                                    ip: ip,
                                    port: ipPortNumber,
                                    font_size: fontSize,
                                    edit_date: new Date(),
                                    add_date: new Date(),
                                    ticket_width: ticketWidth,
                                    port_series: portSeries,
                                    isArchived: false,
                                },
                            ],
                        };
                        let dataSearch: any = [];
                        if (dataTable.data.length > 0) {
                            dataSearch =
                                oneShop ||
                                dataTable.data[0][t("Shop")] === undefined
                                    ? dataTable.data
                                    : dataTable.data.find((x: any) => {
                                          return x.shopId === element;
                                      }).subRows;
                        }

                        if (IsExistPeripheral(dataSearch, designation.trim())) {
                            action = true;
                            mutate(
                                apiUrl,
                                await fetch(apiUrl, {
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: `Bareer ${localStorage.getItem(
                                            "jwt"
                                        )}`,
                                    },
                                    body: JSON.stringify(savedData),
                                    method: "POST",
                                })
                                    .then((response) => response.json())
                                    .then((data) => {
                                        if (data.error) {
                                            throw Error(
                                                "Error while saving selection!"
                                            );
                                        }
                                    })
                            );
                        }
                    }
                    if (action === true) {
                        toast.success(
                            `${t("The device is successfully added")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                    }
                    setDataIsUpdated(true);
                    setEditedData([]);
                    setIsEdited(false);
                    activateCheckbox();
                    setCanValidate(true);
                } catch (e: any) {
                    setCanValidate(true);
                    toast.error(`${t("There's an error")}!`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        }
    }

    const activateCheckbox = () => {
        let checks = document.getElementsByClassName("PrivateSwitchBase-input");

        for (let i = 0; i < checks.length; i++) {
            checks[i].classList.remove("not-allowed-icon-check__clz");
        }
    };

    fetchDataIp_Name();

    useEffect(() => {
        if (editedData.length !== 0) {
            console.log(editedData);

            setPrinterType(editedData.type);
            setDesignation(
                editedData[t(`Printer name`)]
                    ? editedData[t(`Printer name`)]
                    : ""
            );
            setPrinterPath(editedData.path);
            setIp(editedData.type === "ip" ? editedData[t(`IP/port`)] : "");
            setPortNum(
                editedData.type === "series"
                    ? editedData["port"]
                    : editedData.type !== "ip"
                    ? editedData[t(`IP/port`)]
                    : printerType === "series"
                    ? "COM 2"
                    : "LPT 2"
            );

            setSpeed(editedData["speed"] !== "" ? editedData["speed"] : 19200);
            setFontSize(editedData[t(`Font size`)]);
            setTicketWidth(editedData["ticket_width"]);
            setMark(editedData.Marque);
            setRemark(editedData.Remarque);
            setPortSeries(editedData.port_series);
            setIpPortNumber(editedData["port"]);
            setInputDesignation(false);
            setInputIp(false);
            setEmptyIp(false);
        } else {
            setPrinterType("ip");
            setDesignation("");
            setSpeed(19200);
            setMark("");
            setIp("");
            setRemark("");
            setFontSize(9);
            setPrinterPath("");
            setTicketWidth(40);
            setPortNum(printerType === "series" ? "COM 2" : "LPT 2");
            setIpPortNumber("7000");
            setPortSeries("COM 2");
            setInputDesignation(false);
            setInputIp(false);
            setEmptyIp(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedData, isAddNewPrinterButtonClicked, t, oneShop]);
    useEffect(() => {
        if (editedData.length !== 0 && editedData?.Id !== undefined) {
            setIsUpdateOperation(true);
        } else {
            setIsUpdateOperation(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedData]);
    useEffect(() => {
        setInputDesignation(false);
        setInputIp(false);

        if (editedData.length !== 0) {
            setPortNum(
                editedData.type === "series"
                    ? editedData["port"]
                    : editedData.type !== "ip"
                    ? editedData[t(`IP/port`)]
                    : printerType === "series"
                    ? "COM 2"
                    : "LPT 2"
            );
            setSpeed(editedData["speed"] !== "" ? editedData["speed"] : 19200);

            setIpPortNumber(editedData["port"]);
        } else {
            setSpeed(19200);
            setPortNum(printerType === "series" ? "COM 2" : "LPT 2");
            setIpPortNumber("7000");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [printerType]);

    useEffect(() => {
        if (path !== "" && selected !== "") {
            setPrinterPath(selected);
            setIsEdited(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path, selected]);

    React.useEffect(() => {
        setActions(confirmNavigation);
        setIsActionsClicked(showPrompt as boolean);
        setValidationAction(cancelNavigation);
    }, [showPrompt, confirmNavigation, cancelNavigation]);

    return (
        <React.Fragment>
            <AvForm
                className="card"
                model={{
                    locationType:
                        printerType !== undefined ? printerType : "ip",
                    checkItOut: true,
                }}
                style={{ marginBottom: "0px" }}
            >
                <CustomCardHeader className="d-flex align-items-center justify-content-between">
                    <h5 className="pt-3 imp_txt_addPrinterDevice">
                        {isConsult === true
                            ? t("View peripheral: printer")
                            : editedData.length === 0
                            ? t("Add device: printer")
                            : t("Modify a device: printer")}
                    </h5>
                    <CrossIcon
                        style={{ cursor: "pointer" }}
                        className="imp_icn_closeForm"
                        height={13}
                        width={13}
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    activateCheckbox();
                                    setEditedData([]);
                                    setIsAddPrinterClicked!(
                                        !isAddPrinterClicked
                                    );
                                    setIsConsult(false);
                                    resetAllPeripherals();
                                    setIsAddPrinterClicked!(
                                        !isAddPrinterClicked
                                    );
                                    setIsEdited(false);
                                });
                            } else {
                                activateCheckbox();
                                setEditedData([]);
                                setIsAddPrinterClicked!(!isAddPrinterClicked);
                                setIsConsult(false);
                                setIsEdited(false);
                                resetAllPeripherals();
                            }
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className="pl-3 pr-5"
                    style={{
                        maxHeight:
                            pathname === "/peripherals/printers"
                                ? "550px"
                                : pathname === "/kiosk-peripheral-setting"
                                ? "610px"
                                : "600px",

                        overflowY: " scroll",
                    }}
                >
                    <StyledLabel>{t("Type of configuration")}</StyledLabel>
                    <AvRadioGroup
                        name="locationType"
                        required
                        inline
                        className={classnames("form-check-label__clz", {
                            readOnly__clz: isConsult,
                        })}
                        value={printerType}
                    >
                        <AvRadio
                            onChange={() => {
                                setPrinterType("ip");
                                setIsEdited(true);
                            }}
                            className="ml-4 imp_icn_ip"
                            label={<StyledLabel>{t("IP")}</StyledLabel>}
                            name="type"
                            value="ip"
                        />
                        <AvRadio
                            onChange={() => {
                                setPrinterType("series");
                                setIsEdited(true);
                            }}
                            id="series"
                            className="ml-4 mt-0 imp_icn_series"
                            label={<StyledLabel>{t("Series")}</StyledLabel>}
                            name="type"
                            value="series"
                        />
                        {/* <AvRadio
                            onChange={() => {
                                setPrinterType("parallel");
                                setIsEdited(true);
                            }}
                            id="parallel"
                            className="ml-4 imp_icn_parallel"
                            label={<StyledLabel>{t("Parallel")}</StyledLabel>}
                            name="type"
                            value="parallel"
                        /> */}
                        <AvRadio
                            className="ml-4 imp_icn_usb"
                            onChange={() => {
                                setPrinterType("usb");
                                setIsEdited(true);
                            }}
                            label={<StyledLabel>{t("USB")}</StyledLabel>}
                            name="type"
                            value="usb"
                            id="usb"
                        />
                    </AvRadioGroup>
                    <AvGroup style={{ width: "100%" }}>
                        <StyledLabel
                            htmlFor="name"
                            className="required__clz mt-3"
                        >
                            {t("Designation")}
                        </StyledLabel>

                        <StyledTextInput
                            autocomplete="off"
                            className={classnames("imp_inp_printerName", {
                                input__clz:
                                    inputDesignation ||
                                    exist.isNameExist ||
                                    (designation?.trim() === "" &&
                                        designation !== ""),
                                readOnly__clz: isConsult,
                            })}
                            id="designation"
                            name="designation"
                            placeholder={t("Enter")}
                            type="text"
                            onChange={(e: any) => {
                                setDesignation(e.target.value);
                                setIsEdited(true);
                                setInputDesignation(false);
                                uniqueDesignationIp(
                                    e.target.value,
                                    "designation"
                                );
                            }}
                            value={designation}
                            validate={{
                                pattern: {
                                    value: matchWordDesignation,
                                    errorMessage: t("Prohibited characters"),
                                },
                            }}
                            autoFocus={isConsult === true ? false : true}
                        />
                        {inputDesignation ||
                        (designation.trim() === "" && designation !== "") ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a designation")}
                            </div>
                        ) : null}
                        {exist.isNameExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("The name is already exists")!}
                            </div>
                        ) : null}
                    </AvGroup>
                    {printerType === "usb" ? (
                        <div className="justify-content-between d-flex mt-3">
                            <AvGroup style={{ width: "100%" }}>
                                <StyledLabel htmlFor="path" className=" ">
                                    {t("Name of printer")}
                                </StyledLabel>
                                <StyledTextInput
                                    className={classnames(
                                        "imp_inp_selectPrinter",
                                        {
                                            readOnly__clz: isConsult,
                                        }
                                    )}
                                    style={{ width: "100%" }}
                                    id="path"
                                    name="path"
                                    placeholder={t("Enter")}
                                    type="text"
                                    value={printerPath}
                                    onChange={(e: any) => {
                                        setPrinterPath(e.target.value);
                                        setIsEdited(true);
                                    }}
                                />
                            </AvGroup>
                            {/* <Tooltip title={t("Select").toString()}>
                                <StyledIconButton
                                    icon="LinkIcon"
                                    style={{
                                        height: "23px",
                                        marginTop: "30px",
                                        marginLeft: "2px",
                                    }}
                                    onClick={() => {
                                        setIsPrintersPathsModalOpened(
                                            !isPrintersPathsModalOpened
                                        );
                                        setPath(printerPath);
                                    }}
                                    className="mx-1 imp_icn_select"
                                >
                                    <SelectPrinterSvgIcon
                                        height={20}
                                        width={20}
                                    />
                                </StyledIconButton>
                            </Tooltip> */}
                        </div>
                    ) : null}
                    {isIpPrinter ? (
                        <AvGroup>
                            <StyledLabel
                                htmlFor="example-input"
                                className="required__clz mt-3"
                            >
                                {t("IP address")}
                            </StyledLabel>
                            <StyledTextInput
                                autocomplete="off"
                                style={{ width: "100%" }}
                                className={classnames("imp_inp_ipAddress", {
                                    input__clz:
                                        inputIp || emptyIp || exist.isIpExist,
                                    readOnly__clz: isConsult,
                                })}
                                id="ip"
                                name="ip"
                                placeholder={t("Enter")}
                                type="text"
                                onChange={(e: any) => {
                                    setIp(e.target.value);
                                    setIsEdited(true);
                                    if (
                                        !e.target.value.match(ipMatchWord) &&
                                        e.target.value !== ""
                                    ) {
                                        setInputIp(true);
                                    } else {
                                        setInputIp(false);
                                    }
                                    if (e.target.value !== "") {
                                        setEmptyIp(false);
                                    }
                                    uniqueDesignationIp(e.target.value, "ip");
                                }}
                                value={ip}
                            />

                            {emptyIp || inputIp || exist.isIpExist ? (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "0.25rem",
                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t(
                                        emptyIp
                                            ? "Please enter a IP address"
                                            : inputIp
                                            ? "IP address is invalid"
                                            : exist.isIpExist
                                            ? "The ip address is already exists"
                                            : ""
                                    )}
                                </div>
                            ) : null}
                        </AvGroup>
                    ) : null}
                    {isIpPrinter || printerType === "series" ? (
                        <div
                            className="d-flex align-items-start"
                            style={{ gap: "10%" }}
                        >
                            {isIpPrinter ? (
                                <AvGroup
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <StyledLabel
                                        className="mt-3"
                                        htmlFor="example-input"
                                    >
                                        {t("Port number")}
                                    </StyledLabel>
                                    <StyledTextInput
                                        className={classnames(
                                            "imp_inp_portNumber",
                                            {
                                                readOnly__clz: isConsult,
                                            }
                                        )}
                                        autocomplete="off"
                                        id="port"
                                        name="port"
                                        placeholder={t("Enter")}
                                        onChange={(e: any) => {
                                            setIpPortNumber(e.target.value);
                                            setIsEdited(true);
                                        }}
                                        value={ipPortNumber}
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: `${t(
                                                    "Please enter a port number"
                                                )!}`,
                                            },
                                            pattern: {
                                                value: numberMatchWord,
                                                errorMessage: t(
                                                    "Port number is invalid"
                                                ),
                                            },
                                        }}
                                        maxMenuHeight="90px"
                                    />
                                </AvGroup>
                            ) : (
                                <AvGroup style={{ width: "50%" }}>
                                    <StyledLabel
                                        className="mt-3"
                                        htmlFor="example-input"
                                    >
                                        {t("Port number")}
                                    </StyledLabel>
                                    <StyledSelectInput
                                        style={{ width: "100%" }}
                                        name="port_num"
                                        id="port_num"
                                        options={portNumArray}
                                        onChange={(e: any) => {
                                            setPortSeries(e.label);
                                            setIsEdited(true);
                                        }}
                                        placeholder={t("Enter")}
                                        value={{
                                            value: portSeries,
                                            label: portSeries,
                                        }}
                                        noOptionsMessage={() => t("No options")}
                                        maxMenuHeight="90px"
                                        className={classnames(
                                            "imp_inp_portNumber",
                                            {
                                                readOnly__clz: isConsult,
                                            }
                                        )}
                                    />
                                </AvGroup>
                            )}

                            {printerType === "series" && (
                                <AvGroup style={{ width: "50%" }}>
                                    <StyledLabel
                                        className="mt-3"
                                        htmlFor="speed"
                                    >
                                        {t("Speed")}
                                    </StyledLabel>
                                    <StyledSelectInput
                                        value={{
                                            label: speed,
                                            value: speed,
                                        }}
                                        id="speed"
                                        name="speed"
                                        placeholder={t("Enter")}
                                        options={printerSpeedArray}
                                        onChange={(e: any) => {
                                            setSpeed(e.label);
                                            setIsEdited(true);
                                        }}
                                        noOptionsMessage={() => t("No options")}
                                        maxMenuHeight="90px"
                                        className={classnames("imp_inp_speed", {
                                            readOnly__clz: isConsult,
                                        })}
                                    />
                                </AvGroup>
                            )}
                        </div>
                    ) : null}

                    <div
                        className="d-flex align-items-start"
                        style={{ gap: "10%" }}
                    >
                        <AvGroup style={{ width: "50%" }}>
                            <StyledLabel className="mt-3" htmlFor="size">
                                {t("Font size")}
                            </StyledLabel>
                            <StyledSelectInput
                                value={{
                                    label: fontSize,
                                    value: fontSize,
                                }}
                                name="font_size"
                                id="label"
                                style={{ width: "50%" }}
                                className={classnames("imp_inp_fontSize", {
                                    readOnly__clz: isConsult,
                                })}
                                options={fontSizeArray}
                                placeholder={t("Enter")}
                                onChange={(e: any) => {
                                    setFontSize(e.label);
                                    setIsEdited(true);
                                }}
                                noOptionsMessage={() => t("No options")}
                                maxMenuHeight="90px"
                            />
                        </AvGroup>

                        <AvGroup style={{ width: "50%" }}>
                            <StyledLabel className="mt-3" htmlFor="width">
                                {t("Ticket width")}
                            </StyledLabel>
                            <StyledSelectInput
                                value={{
                                    label: ticketWidth,
                                    value: ticketWidth,
                                }}
                                name="ticket_width"
                                id="ticket_width"
                                options={ticketWidthArray}
                                placeholder={t("Enter")}
                                onChange={(e: any) => {
                                    setTicketWidth(e.label);
                                    setIsEdited(true);
                                }}
                                noOptionsMessage={() => t("No options")}
                                maxMenuHeight="90px"
                                className={classnames("imp_inp_ticketWidth", {
                                    readOnly__clz: isConsult,
                                })}
                            />
                        </AvGroup>
                    </div>

                    <AvGroup>
                        <StyledLabel className="mt-3" htmlFor="mark">
                            {t("Mark")}
                        </StyledLabel>
                        <StyledTextInput
                            style={{ width: "100%" }}
                            id="mark"
                            name="mark"
                            placeholder={t("Enter")}
                            type="text"
                            onChange={(e: any) => {
                                setMark(e.target.value);
                                setIsEdited(true);
                            }}
                            value={mark}
                            className={classnames("imp_inp_mark", {
                                readOnly__clz: isConsult,
                            })}
                        />
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel className="mt-3" htmlFor="remark">
                            {t("Remark")}
                        </StyledLabel>
                        <StyledTextInput
                            style={{ width: "100%" }}
                            id="remark"
                            name="remark"
                            placeholder={t("Enter")}
                            type="text"
                            onChange={(e: any) => {
                                setRemark(e.target.value);
                                setIsEdited(true);
                            }}
                            value={remark}
                            className={classnames("imp_inp_remark", {
                                readOnly__clz: isConsult,
                            })}
                        />
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        block={true}
                        variant="light"
                        className="m-1 imp_btn_cancel btn-footer__clz "
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setEditedData([]);
                                    setIsAddPrinterClicked!(
                                        !isAddPrinterClicked
                                    );
                                    setIsConsult(false);
                                    setIp("");
                                    activateCheckbox();
                                    setInputIp(false);
                                    setIsEdited(false);
                                });
                            } else {
                                setEditedData([]);
                                setIsAddPrinterClicked!(!isAddPrinterClicked);
                                setIsConsult(false);
                                setIp("");
                                activateCheckbox();
                                setInputIp(false);
                            }
                        }}
                    >
                        <span>{t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <StyledButton
                        block={true}
                        rounded
                        className={classnames(
                            "imp_btn_validate   btn-footer__clz  m-1",
                            {
                                "not-allowed-icon__clz": isConsult,
                            }
                        )}
                        variant="primary"
                        onClick={() => {
                            if (designation === "") {
                                setInputDesignation(true);
                            }
                            if (!ip.match(ipMatchWord)) {
                                setInputIp(true);
                            }
                            if (ip === "") {
                                setEmptyIp(true);
                            }
                            if (editedData.length === 0) {
                                if (
                                    (isIpPrinter &&
                                        ip.length !== 0 &&
                                        designation.length !== 0 &&
                                        ip.match(ipMatchWord) &&
                                        !exist.isNameExist &&
                                        !exist.isIpExist &&
                                        ipPortNumber.match(numberMatchWord)) ||
                                    (!isIpPrinter &&
                                        !isUsbPrinter &&
                                        designation.length !== 0 &&
                                        !exist.isNameExist &&
                                        !exist.isIpExist) ||
                                    (isUsbPrinter &&
                                        designation.length !== 0 &&
                                        !exist.isNameExist &&
                                        !exist.isIpExist)
                                ) {
                                    if (
                                        designation.match(
                                            matchWordDesignation
                                        ) !== null
                                    ) {
                                        handleValidateButtonClickEvent();
                                    }
                                }
                            } else {
                                if (isEdited === false) {
                                    setIsModalOpened(true);
                                } else if (
                                    (isIpPrinter &&
                                        ip.length !== 0 &&
                                        designation.length !== 0 &&
                                        ip.match(ipMatchWord) &&
                                        !exist.isNameExist &&
                                        !exist.isIpExist &&
                                        ipPortNumber.match(numberMatchWord)) ||
                                    (!isIpPrinter &&
                                        !isUsbPrinter &&
                                        designation.length !== 0 &&
                                        !exist.isNameExist &&
                                        !exist.isIpExist) ||
                                    (isUsbPrinter &&
                                        designation.length !== 0 &&
                                        !exist.isNameExist &&
                                        !exist.isIpExist)
                                )
                                    if (
                                        designation.match(
                                            matchWordDesignation
                                        ) !== null
                                    )
                                        handleEditButtonClickEvent();
                            }
                        }}
                    >
                        {t("Validate")}
                    </StyledButton>
                </CustomCardFooter>
                <PrintersPathsModal
                    setIsPrintersPathsModalOpened={
                        setIsPrintersPathsModalOpened
                    }
                    isPrintersPathsModalOpened={isPrintersPathsModalOpened}
                    setPrinterPath={setPrinterPath}
                    printerPath={printerPath}
                />
            </AvForm>
            <ConfirmationEditMessage
                setDataIsUpdated={setDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddPrinterClicked}
                setIsAddClicked={setIsAddPrinterClicked}
                setEditedData={setEditedData}
            />
            {isActionsClicked === true ? (
                <ConfirmationUpdatingDataMessageModal
                    isModalOpened={isActionsClicked}
                    setIsModalOpened={setIsActionsClicked}
                    validationAction={validationAction}
                    actions={actions}
                    setIsEdited={setIsEdited}
                />
            ) : null}
        </React.Fragment>
    );
}
