import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    CheckboxIcon,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";

import { store } from "../../../../components/VerticalLayout/store";
import {
    setShopDataIsUpdated,
    setFranchiseDataIsUpdated,
    store as logoStore,
} from "../store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationDeleteMessageType = {
    setIsModalConfirmationOpened: Function;
    isModalConfirmationOpened: boolean;
    setDataIsUpdated: Function;
    selectedRows: any;
};

export function ConfirmationActiveSaleSupport({
    setIsModalConfirmationOpened,
    isModalConfirmationOpened,
    setDataIsUpdated,
    selectedRows,
}: ConfirmationDeleteMessageType) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const { shopsSaleSupportData } = useSnapshot(logoStore);

    function ArchivedSaleSupport(e: any) {
        let idsAssociated = ["0"];
        if (shopsSaleSupportData !== undefined) {
            shopsSaleSupportData.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    if (el.Id === e.Id) idsAssociated.push(el.shopId);
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];

        return idsAssociated;
    }

    async function activeSaleSupport(isValidation: boolean) {
        toast.dismiss();
        let localData: any[] = [];

        Object.values(selectedRows).forEach(async (element: any) => {
            const id = element.Id;
            let shopsArchived = ArchivedSaleSupport(element);
            let objectData = {
                userId: userID,
                shopId: isValidation ? shopsArchived : ["0"],
                franchiseId: franchiseID,
                operatorId: operatorID,
                data: {
                    [id]: {
                        id: id,
                        displayName: element[t("Display name")],
                        shopId: element.shopId,
                        support_de_vente: element[t("Sale support")],
                        tag: element.Tag,
                        isActive: element.isActive,
                        isFavorite: element.isFavorite,
                        isArchived: false,
                    },
                },
            };

            localData.push(objectData);
            const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport`;
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(localData),
            };
            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, requestOptions)
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.error) {
                                throw Error(result.message);
                            }

                            toast.success(
                                `${t(
                                    "Sale support is successfully unarchived"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setDataIsUpdated!(true);
                            setShopDataIsUpdated(true);
                            setFranchiseDataIsUpdated(true);
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        });
    }

    return (
        <StyledModal toggle={true} isOpen={isModalConfirmationOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalConfirmationOpened(!isModalConfirmationOpened);
                    setDataIsUpdated!(true);
                }}
            >
                <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {t(
                        "Attention! the sale support is archived at store level. Do you want to activate it in shops too?"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        activeSaleSupport(false);
                        setIsModalConfirmationOpened(
                            !isModalConfirmationOpened
                        );
                        setDataIsUpdated!(true);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        activeSaleSupport(true);
                        setIsModalConfirmationOpened(
                            !isModalConfirmationOpened
                        );
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
