import styled from "styled-components";

type FinalMessageContainerWrapperPropsType = {
    isTopBannerActive: boolean;
    isHeaderActive: boolean;
    isLogoActive: boolean;
};

export const FinalMessageContainerWrapper = styled.div<FinalMessageContainerWrapperPropsType>`
    display: grid;
    background-color: transparent;
    position: relative;
    grid-template-rows: ${({
        isTopBannerActive,
        isHeaderActive,
    }: FinalMessageContainerWrapperPropsType) =>
        isTopBannerActive && isHeaderActive
            ? "110px 90px 200px 325px"
            : !isTopBannerActive && isHeaderActive
            ? "110px 285px 330px"
            : isTopBannerActive && !isHeaderActive
            ? "110px 290px 325px;"
            : !isTopBannerActive && !isHeaderActive
            ? "300px 425 ;"
            : ""};
    height: 100%;
`;
