import React from "react";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import {
    Dropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
} from "reactstrap";
import {
    VerticalDotsIcon,
    SettingsIcon,
    DuplicateIcon,
    TrashIcon,
    EyeIcon,
    ShieldExclamationIcon,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { store as userStore } from "../../../../components/VerticalLayout/store";
import store, {
    setIsModalAssociateStore,
    setIsModalDissociateOpened,
} from "./store";
import ErrorToast from "@components/Common/ErrorTost";

type DropDownWrapperType = {
    element: any;
    setSelectedRows: Function;
    isProjectArchived: boolean;
    setIsConfirmationModalArchived: Function;
    setIsConfirmationModalActive: Function;
    setIsConfirmationModalCopied: Function;
    setIsConfigurationModalOpened: Function;
    setIdStore: Function;
    dataFranchise: any;
};

export function DropDownWrapper({
    element,
    setSelectedRows,
    isProjectArchived,
    setIsConfirmationModalArchived,
    setIsConfirmationModalActive,
    setIsConfirmationModalCopied,
    setIsConfigurationModalOpened,
    setIdStore,
    dataFranchise,
}: DropDownWrapperType): JSX.Element {
    const { t } = useTranslation();

    const { oneShop } = useSnapshot(userStore);
    const { isModalAssociateStore } = useSnapshot(store);

    const [isDropdownOpened, setIsDropdownOpened] = React.useState(false);

    function toggleDropdown() {
        setIsDropdownOpened((prevState) => !prevState);
    }

    const isAssociateShops = React.useCallback(() => {
        let isAssociate: boolean = false;
        dataFranchise?.dataProjectFranchise?.data.forEach((el: any) => {
            if (el.projectId === element.selectedRow.original.projectId) {
                if (Object.keys(el.associates).length > 1) {
                    isAssociate = true;
                }
            }
        });

        return isAssociate;
    }, [element.selectedRow.original.projectId, dataFranchise]);

    let isAssociate = isAssociateShops();

    return (
        <React.Fragment>
            {element.selectedRow.original.key_project ? (
                <Dropdown isOpen={isDropdownOpened} toggle={toggleDropdown}>
                    <DropdownToggle
                        id="page-header-user-dropdown"
                        tag="button"
                        className={`${classnames(
                            "pointer__clz btn btn header-item waves-effect",
                            {
                                // "not-allowed-icon__clz":
                                //     isCreateProjectClicked === true,
                            }
                        )}`}
                    >
                        <VerticalDotsIcon
                            height={25}
                            width={25}
                            className={classnames("", {
                                kds_drp_menuProject: !isProjectArchived,
                                cmn_drp_menuProject: isProjectArchived,
                                // element.selectedRow.original
                                //     .Status === "Planifié",
                            })}
                        />
                    </DropdownToggle>
                    <DropdownMenu>
                        {element.selectedRow.original.Status === "Publié" ? (
                            <DropdownItem
                                style={{ cursor: "pointer" }}
                                className="cgt_icn_consult"
                                onClick={() => {
                                    setSelectedRows(
                                        element.selectedRow.original
                                    );
                                }}
                            >
                                <SettingsIcon height={15} width={15} />
                                <span>{t("To consult")}</span>
                            </DropdownItem>
                        ) : (
                            <DropdownItem
                                className={classnames("cmn_icn_configuration", {
                                    "not-allowed-icon__clz": isProjectArchived,
                                    // element.selectedRow.original
                                    //     .Status === "Planifié",
                                })}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setSelectedRows(
                                        element.selectedRow.original
                                    );

                                    if (
                                        element.selectedRow.original.shopId ===
                                        "casa_italiaboutique1"
                                    ) {
                                        setIdStore(2);
                                       
                                    } else if (
                                        element.selectedRow.original.shopId ===
                                        "casa_italiaboutique2"
                                    ) {
                                        setIdStore(3);
                                       
                                    } else {
                                        setIdStore(0);
                                    }
                                    setIsConfigurationModalOpened(true);
                                }}
                            >
                                <SettingsIcon height={15} width={15} />
                                <span>{t("Configure")}</span>
                            </DropdownItem>
                        )}

                        <DropdownItem
                            style={{ cursor: "pointer" }}
                            className={classnames("cmn_icn_publish", {
                                "not-allowed-icon__clz":
                                    isProjectArchived ||
                                    (isAssociate && oneShop),
                            })}
                            onClick={() => {
                                if (
                                    Object.keys(
                                        element.selectedRow.original.associates
                                    ).length === 0 &&
                                    !oneShop
                                ) {
                                    toast.error(
                                        `${t(
                                            "Unable to publish a template not associated with one or more store(s)"
                                        )!}`,
                                        {
                                            position: toast.POSITION.TOP_CENTER,
                                            autoClose: 2000,
                                            theme: "colored",
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            icon: (
                                                <ShieldExclamationIcon
                                                    width={25}
                                                    height={25}
                                                    fill="white"
                                                />
                                            ),
                                        }
                                    );
                                } else if (
                                    element.selectedRow.original.template.id ===
                                    ""
                                ) {
                                    toast.error(
                                        `${t(
                                            "Unable to publish an unconfigured Template"
                                        )!}`,
                                        {
                                            position: toast.POSITION.TOP_CENTER,
                                            autoClose: 2000,
                                            theme: "colored",
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            icon: (
                                                <ShieldExclamationIcon
                                                    width={25}
                                                    height={25}
                                                    fill="white"
                                                />
                                            ),
                                        }
                                    );
                                }
                                setSelectedRows(element.selectedRow.original);
                            }}
                        >
                            <DuplicateIcon height={15} width={15} />
                            <span>{t("Publish")}</span>
                        </DropdownItem>
                        <DropdownItem
                            style={{ cursor: "pointer" }}
                            className={classnames("cmn_icn_duplicate", {
                                "not-allowed-icon__clz":
                                    isProjectArchived ||
                                    (isAssociate && oneShop),
                            })}
                            onClick={() => {
                                setSelectedRows([element.selectedRow.original]);
                                setIsConfirmationModalCopied(true);
                            }}
                        >
                            <DuplicateIcon height={15} width={15} />
                            <span>{t("Duplicate")}</span>
                        </DropdownItem>
                        {!oneShop ? (
                            <DropdownItem
                                style={{ cursor: "pointer" }}
                                className={classnames(
                                    "cmn_icn_associateStore",
                                    {
                                        "not-allowed-icon__clz":
                                            isProjectArchived ||
                                            oneShop ||
                                            element.selectedRow.original
                                                .Status === "Publié",
                                    }
                                )}
                                onClick={() => {
                                    setSelectedRows(
                                        element.selectedRow.original
                                    );
                                    setIsModalAssociateStore(
                                        !isModalAssociateStore
                                    );
                                }}
                            >
                                <DuplicateIcon height={15} width={15} />
                                <span>{t("Associate to store(s)")}</span>
                            </DropdownItem>
                        ) : null}
                        <DropdownItem
                            className={classnames("", {
                                "not-allowed-icon__clz":
                                    element.selectedRow.original.Status ===
                                    "Publié",
                                cmn_btn_FRunarchive: !isProjectArchived,
                                cmn_icn_FRunarchive: isProjectArchived,
                            })}
                            onClick={() => {
                                setSelectedRows([element.selectedRow.original]);
                                if (oneShop) {
                                    setIsModalDissociateOpened(true);
                                } else if (!oneShop) {
                                    if (!isProjectArchived) {
                                        if (
                                            element.selectedRow.original.subRows
                                                .length > 0
                                        ) {
                                            return ErrorToast(
                                                `${t(
                                                    "Please note that you cannot archive a project associated with the store(s)."
                                                )!}`
                                            );
                                        } else {
                                            setIsConfirmationModalArchived(
                                                true
                                            );
                                        }
                                    } else {
                                        setIsConfirmationModalActive(true);
                                    }
                                }
                            }}
                        >
                            {!isProjectArchived ? (
                                <TrashIcon height={15} width={15} fill="red" />
                            ) : (
                                <EyeIcon height={15} width={15} />
                            )}

                            <span
                                className={classnames("", {
                                    "not-allowed-icon__clz":
                                        element.selectedRow.original.Status ===
                                        "Publié",
                                })}
                            >
                                {!isProjectArchived
                                    ? oneShop
                                        ? `${t("Delete")}`
                                        : `${t("Archive")}`
                                    : `${t("Enable")}`}
                            </span>
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            ) : null}
        </React.Fragment>
    );
}
