import React from "react";
import { SettingsIcon } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { useSnapshot } from "valtio";
import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";
import { Tooltip } from "@mui/material";

import {
    store as hoursStore,
    setIsHistoryShopsModalOpened,
    setSelectedHours,
} from "../../store";

export function CustomIcons({
    e,
    setSelectedRows,
    setIsConfigureHoursClicked,
    setDaysData,
    setShopsHoursData,
}: any) {
    const { t } = useTranslation();
    const { isHistoryShopsModalOpened, modify } = useSnapshot(hoursStore);

    return (
        <React.Fragment>
            {e?.selectedRow[t("Day of week")] !== "" ? (
                <div className="d-flex justify-content-around w-50">
                    {modify === true ? (
                        <>
                            <Tooltip title={t("Configure")}>
                                <div>
                                    {" "}
                                    <SettingsIcon
                                        onClick={() => {
                                            setShopsHoursData([e.selectedRow]);
                                            setDaysData(e.selectedRow);
                                            setSelectedRows([e.selectedRow]);
                                            setIsConfigureHoursClicked(true);
                                        }}
                                        height={25}
                                        width={25}
                                        className={classnames(
                                            "pointer__clz",
                                            {
                                                "not-allowed-icon__clz": false,
                                            },
                                            "hov_icn_BTsetting"
                                        )}
                                    />
                                </div>
                            </Tooltip>
                        </>
                    ) : null}
                    <Tooltip title={t("Consult history")}>
                        <div>
                            {" "}
                            <TimePastSvgIcon
                                className={classnames(
                                    "pointer__clz hov_icn_BThistory",
                                    {
                                        "not-allowed-icon__clz": false,
                                    }
                                )}
                                onClick={() => {
                                    setSelectedHours([
                                        {
                                            [e.selectedRow.shopId]:
                                                e.selectedRow.Id,
                                        },
                                    ]);
                                    setSelectedRows([e.selectedRow]);
                                    setIsHistoryShopsModalOpened(
                                        !isHistoryShopsModalOpened
                                    );
                                }}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
        </React.Fragment>
    );
}
