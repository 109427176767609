import { designStore } from "@store";
import React from "react";
import { useSnapshot } from "valtio";

import { MainContent } from "./MainContent";
import { Translation } from "./Translation";

export function Footer(): JSX.Element {
    const { isFooterTicketNumberMessageActive, isFooterFinalMessageActive } =
        useSnapshot(designStore);
    return (
        <React.Fragment>
            <MainContent />
            {isFooterTicketNumberMessageActive || isFooterFinalMessageActive ? (
                <Translation />
            ) : (
                ""
            )}
        </React.Fragment>
    );
}
