import React from "react";
import classNames from "classnames";
import { useSnapshot } from "valtio";

import { designStore, generalConfigStore } from "@store";

import { getImageContentById, getTopBannerImagePath } from "@helpers/general";

import { PreviewTopBannerBackgroundWrapper } from "./PreviewTopBannerBackgroundWrapper";

export function TopBannerWrapper(): JSX.Element {
    const { isLogoActive, activeTabIndex, activePageName } = useSnapshot(
        designStore
    );
    const {
        project: { template, files: images },
    } = useSnapshot(generalConfigStore);

    const logoImage = getImageContentById(
        (template.content.generalDesign as GeneralDesignType).logo.id as string,
        images
    );

    const logoImageSrc =
        logoImage?.includes("http") || logoImage?.includes("https")
            ? logoImage
            : `./images/${logoImage}`;

    const topBannerBackgroundImageSrc = getTopBannerImagePath(
        activePageName,
        images
    );
    const possibleExtensions = ["mp4", "mov", "avi", "flv", "wmv"];

    const extension = topBannerBackgroundImageSrc.split(".")[
        topBannerBackgroundImageSrc.split(".").length - 1
    ];

    return (
        <div
            className={classNames(
                "d-flex justify-content-center align-items-center",
                {
                    "border-wrapper__clz": activeTabIndex === 0,
                }
            )}
        >
            <PreviewTopBannerBackgroundWrapper>
                {isLogoActive ? (
                    <img
                        src={logoImageSrc}
                        style={{
                            height: "85px",
                            width: "85px",
                            position:
                                possibleExtensions.includes(extension) === true
                                    ? "fixed"
                                    : "unset",
                            zIndex: 1,
                        }}
                        alt="Logo"
                    />
                ) : null}
            </PreviewTopBannerBackgroundWrapper>
        </div>
    );
}
