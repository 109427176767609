import React from "react";
import { useTranslation } from "react-i18next";
import { Nav, NavItem, TabContent, TabPane, NavLink } from "reactstrap";
import classNames from "classnames";
import { useSnapshot } from "valtio";

import { designStore, setActiveTabIndex, setFinalMessage } from "@store";

import { MessagesTranslationWrapper } from "@pages/GeneralConfig/Design/Content/FinalMessage/Design/MessagesTranslationWrapper";
import { TopBanner } from "@pages/GeneralConfig/Design/Content/FinalMessage/Design/TopBanner";
import { Header } from "@pages/GeneralConfig/Design/Content/FinalMessage/Design/Header";
import { Footer } from "@pages/GeneralConfig/Design/Content/FinalMessage/Design/Footer";

import "@components/Common/Design/index.css";
import "@pages/GeneralConfig/Design/index.css";

export function Design(): JSX.Element {
    const { t } = useTranslation();

    const {
        selectedLanguage,
        selectedRegulationModeValue,
        finalMessageItems,
        selectedInformationModeValue,
    } = useSnapshot(designStore);

    const tabs: TabsType[] = [
        {
            title: t("General"),
            content: <TopBanner />,
        },
        {
            title: t("Header"),
            content: <Header />,
        },
        {
            title: t("Messages"),
            content: <MessagesTranslationWrapper />,
        },
        {
            title: t("Footer"),
            content: <Footer />,
        },
    ];

    const [activeTab, setActiveTab] = React.useState<number>(0);

    function toggleNav(tab: number) {
        if (activeTab !== tab) setActiveTab(tab);
    }

    React.useEffect(() => {
        if (finalMessageItems.length !== 0) {
            setFinalMessage(
                finalMessageItems.filter((item) => {
                    return item.title === selectedRegulationModeValue;
                })[0][selectedInformationModeValue].languages[selectedLanguage]
                    .defaultContent
            );
        }
        // eslint-disable-next-line
    }, [
        selectedLanguage,
        selectedInformationModeValue,
        selectedRegulationModeValue,
    ]);

    return (
        <div className="design-container-items__clz">
            <div
                style={{
                    width: "85%",
                    height: "98%",
                    backgroundColor: "#EDEDED",
                }}
                className="m-2 border-0 rounded"
            >
                <div className="p-2 mt-1 text-center font-weight-bold cursor__clz">
                    {t("Setup options")}
                </div>
                <Nav tabs className="mt-2">
                    {tabs.map((element, index) => {
                        return (
                            <NavItem key={index} style={{ width: "100%" }}>
                                <NavLink
                                    className={classNames("nav-link__clz", {
                                        "active design-tab-nav-tabs-active-link__clz":
                                            activeTab === index,
                                    })}
                                    onClick={() => {
                                        toggleNav(index);
                                        setActiveTabIndex(index);
                                    }}
                                >
                                    <span className="mx-2">
                                        {element.title}
                                    </span>
                                </NavLink>
                            </NavItem>
                        );
                    })}
                </Nav>
            </div>
            <div>
                <TabContent
                    activeTab={activeTab}
                    style={{ width: "100%", height: "95%" }}
                >
                    {tabs.map((element, index) => {
                        return (
                            <TabPane
                                tabId={index}
                                style={{ height: "100%", width: "100%" }}
                                key={index}
                            >
                                {element.content}
                            </TabPane>
                        );
                    })}
                </TabContent>
            </div>
        </div>
    );
}
