import React from "react";
import { useTranslation } from "react-i18next";
import { StyledH2, StyledLabel } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useSnapshot } from "valtio";

import CardFooterModalPublish from "./CardFooterModalPublish";

import {
    store,
    setIsModesModalPublishOpened,
} from "../../../store/project";

import { capitalize } from "@helpers/general";

type ModalPublishType = {
    rowData: any;
    setIsDataUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
};

export default function ModalPublish({
    setIsDataUpdated,
    rowData,
}: ModalPublishType) {
    const { t } = useTranslation();

    const { isModesModalPublishOpened } = useSnapshot(store);

    return (
        <React.Fragment>
            <Modal centered isOpen={isModesModalPublishOpened}>
                <ModalHeader
                    toggle={() => {
                        setIsModesModalPublishOpened!(
                            !{ isModesModalPublishOpened }
                        );
                    }}
                >
                    <StyledH2>
                        {capitalize(t("Release configuration"))}
                    </StyledH2>
                </ModalHeader>
                <ModalBody>
                    <StyledLabel className="pl-3 m-0">
                        {t("Please confirm the publication of this template")} !
                    </StyledLabel>
                </ModalBody>
                <CardFooterModalPublish
                    rowData={rowData}
                    setIsDataUpdated={setIsDataUpdated}
                />
            </Modal>
        </React.Fragment>
    );
}
