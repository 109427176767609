import { CheckboxIcon, ShieldExclamationIcon } from "@aureskonnect/react-ui";
import { toast } from "react-toastify";
import React from "react";
import { ModalFooter } from "reactstrap";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import moment from "moment";

import { store as userStore } from "../../../components/VerticalLayout/store";

import { setIsModalOpenedPlaning, store } from "../../../store/project";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";

type CardFooterModalPlaningType = {
    rowData: any;
    setIsDataUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    startDate: Date;
    time: Date;
    interval: any;
    hourlyInterval: boolean;
    definedTime: boolean;
    endDate: any;
    intervalDate: any;
    isArchivedTemplateClicked: boolean;
};

export default function CardFooterPlaning({
    rowData,
    setIsDataUpdated,
    startDate,
    time,
    interval,
    hourlyInterval,
    definedTime,
    endDate,
    intervalDate,
    isArchivedTemplateClicked,
}: CardFooterModalPlaningType) {
    const { isModalOpenedPlaning } = useSnapshot(store);
    const { userID, oneShop, shopID, franchiseID, shopName } = useSnapshot(
        userStore
    );
    const { t } = useTranslation();

    async function hourlyIntervalTime(): Promise<void> {
        let list: any;
        if (hourlyInterval === true) {
            if (intervalDate.length === 1) {
                interval.forEach((time: any) => {
                    if (endDate === null) {
                        endDate = startDate;
                    }
                    let date1 = `${moment(startDate)
                        .subtract(1, "month")
                        .format("YYYY,MM,D")},${moment(time.startDate).format(
                        "HH,mm"
                    )}`;
                    let date2 = `${moment(endDate)
                        .subtract(1, "month")
                        .format("YYYY,MM,D")},${moment(time.endDate).format(
                        "HH,mm"
                    )}`;
                    if (list) {
                        list += "||" + date1 + "-->" + date2;
                    } else {
                        list = date1 + "-->" + date2;
                    }
                });
                list += "||";
            } else {
                intervalDate.forEach((date: any) => {
                    let date1 = `${moment(date)
                        .subtract(1, "month")
                        .format("YYYY,MM,D")},${moment(
                        interval[0].startDate
                    ).format("HH,mm")}`;

                    let date2 = `${moment(date)
                        .subtract(1, "month")
                        .format("YYYY,MM,D")},${moment(
                        interval[0].endDate
                    ).format("HH,mm")}`;

                    if (list) {
                        list += "||" + date1 + "-->" + date2;
                    } else {
                        list = date1 + "-->" + date2;
                    }
                });
                list += "||";
                return list;
            }
        }
        if (definedTime === true) {
            list = `${moment(startDate)
                .subtract(1, "month")
                .format("YYYY,M,D")},${moment(time).format("HH,mm")}`;
        }
        return list;
    }
    function AssociateProject() {
        let idShops: any = [];
        if (rowData !== undefined) {
            Object.values(rowData.shopKey).forEach((el: any) => {
                idShops.push(el);
            });
        }
        idShops = [...new Set(idShops), "0"];
        return idShops;
    }

    async function handleSaveButtonChangeStatus() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project`;
        let dataAssociate: any = AssociateProject();
        let localData: any = [];
        let associates: any = {};
        let data: any = {};
        data[shopID] = {
            designation: shopName,
            id_boutique: shopID,
        };
        const days: any = [];
        let date: any = await hourlyIntervalTime();
        if (hourlyInterval) {
            let dataArray = date.split("||");
            for (let i = 0; i < dataArray.length; i++) {
                if (dataArray[i].trim()) {
                    let date = dataArray[i].split("-->");
                    let dataArray1 = date[0].split(",");
                    const date1 = new Date(
                        dataArray1[0],
                        dataArray1[1],
                        dataArray1[2],
                        dataArray1[3],
                        dataArray1[4]
                    );
                    let dataArray2 = date[1].split(",");
                    const date2 = new Date(
                        dataArray2[0],
                        dataArray2[1],
                        dataArray2[2],
                        dataArray2[3],
                        dataArray2[4]
                    );
                    days.push({
                        Start: date1,
                        End: date2,
                    });
                }
            }
        }

        oneShop ? (associates = data) : (associates = rowData.associates);
        let savedData = {
            userId: userID,
            franchiseId: franchiseID,
            shopId: oneShop ? [shopID, "0"] : dataAssociate,
            data: {
                PROJECT: {
                    project_id: "PROJECT",
                    data_project: {
                        [rowData.projectId]: {
                            projectId: rowData.projectId,
                            designation: rowData.Désignation,
                            associates: associates,
                            template: rowData.template,
                            files: rowData.files,
                            isArchived: rowData.isArchived,
                            note: rowData.Remarque,
                            nombre: rowData.Nombre,
                            status: "Planifié",
                            hourlyInterval: hourlyInterval,
                            definedTime: definedTime,
                            startDate: startDate,
                            isArchivedTemplateClicked: isArchivedTemplateClicked,
                            time: time,
                            days: days,
                            date: date,
                            endDate: endDate === null ? startDate : endDate,
                            interval: interval,
                            modified_at: `le ${moment().format(
                                "DD/MM/YY à HH:mm a"
                            )}`,
                            hour: `${moment().format("DD/MM/YY - HH:mm a")}`,
                        },
                    },
                },
            },
        };
        localData.push(
            fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(savedData),
            }).then((response) => response.json())
        );

        try {
            mutate(
                apiUrl,
                await Promise.all(localData).then((result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                        toast.success(
                            `${t("The project is successfully planned")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsDataUpdated(true);
                    } else {
                        throw Error(result.message);
                    }
                })
            );
        } catch (e: any) {
            console.log(`${t("There's an error")}!`);
        }
    }

    async function handleSaveButtonOnClickEvent() {
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/planing`;
        let dataAssociate: any = AssociateProject();
        let localData: any = [];
        let associates: any = {};
        let data: any = {};
        data[shopID] = {
            designation: shopName,
            id_boutique: shopID,
        };
        oneShop ? (associates = data) : (associates = rowData.associates);
        let savedData = {
            userId: userID,
            shopId: oneShop ? [shopID, "0"] : dataAssociate,
            franchiseId: franchiseID,
            date: await hourlyIntervalTime(),

            data: {
                PROJECT: {
                    project_id: "PROJECT",
                    data_project: {
                        [rowData.projectId]: {
                            projectId: rowData.projectId,
                            designation: rowData.Désignation,
                            associates: associates,
                            isArchived: rowData.isArchived,
                            template: rowData.template,
                            files: rowData.files,
                            note: rowData.Remarque,
                            nombre: rowData.Nombre,
                            status: "Publié",
                            modified_at: `le ${moment().format(
                                "DD/MM/YY à HH:mm a"
                            )}`,
                            hour: `${moment().format("DD/MM/YY - HH:mm a")}`,
                        },
                    },
                },
            },
        };
        localData.push(
            fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(savedData),
            }).then((response) => response.json())
        );

        try {
            mutate(
                apiUrl,
                await Promise.all(localData).then((result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                        setIsDataUpdated(true);
                    } else {
                        throw Error(result.message);
                    }
                })
            );
        } catch (e: any) {
            console.log(`${t("There's an error")}!`);
        }
    }

    return (
        <ModalFooter className="border-top-0">
            <CustomSecondaryColorButton
                outline
                rounded
                variant="light"
                className="mr-2 cmn_btn_cancelAlert"
                onClick={() => {
                    setIsModalOpenedPlaning(!isModalOpenedPlaning);
                    setIsDataUpdated(true);
                }}
            >
                {t("Cancel")}
            </CustomSecondaryColorButton>
            <CustomMainColorButton
                rounded
                variant="primary"
                className="cmn_btn_validateAlert"
                onClick={() => {
                    if (
                        Object.keys(rowData.associates).length === 0 &&
                        !oneShop
                    ) {
                        toast.error(
                            `${t(
                                "Unable to publish a template not associated with one or more store(s)"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <ShieldExclamationIcon
                                        width={25}
                                        height={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsModalOpenedPlaning(!isModalOpenedPlaning);
                    } else if (rowData.template.id === "") {
                        toast.error(
                            `${t(
                                "Unable to publish an unconfigured Template"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <ShieldExclamationIcon
                                        width={25}
                                        height={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsModalOpenedPlaning(!isModalOpenedPlaning);
                    } else {
                        handleSaveButtonChangeStatus();

                        handleSaveButtonOnClickEvent();
                    }
                    setIsModalOpenedPlaning(!isModalOpenedPlaning);
                }}
            >
                {t("Validate")}
            </CustomMainColorButton>
        </ModalFooter>
    );
}
