import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import { Dropdown, DropdownButton } from "react-bootstrap";
import classnames from "classnames";
import { useSnapshot } from "valtio";

import store from "./store";

import { capitalize } from "@helpers/general";

import "react-toastify/dist/ReactToastify.css";
import "./index.css";

type DropdownConfigureType = {
    state: string;
    typeDisplay: string;
};

export function DropdownConfigure({ state, typeDisplay }: DropdownConfigureType) {
    const { t } = useTranslation();
    const [lastState, setLastState] = useState<string>(state);
    const [configureValue, setConfigureValue] = React.useState<string>("");
    const { isAddAppClicked } = useSnapshot(store);

    useEffect(() => {
        setConfigureValue(
            state === "" ? t("Configured") : t(capitalize(state))
        );
    }, [state, t]);
    useEffect(() => {
        if (configureValue !== undefined && configureValue !== "")
            setLastState(configureValue);
    }, [configureValue, t, state]);
    return (
        <div
            className={`${classnames("d-flex justify-content-around w-50", {
                "not-allowed-icon__clz":
                    isAddAppClicked || typeDisplay === "archived",
            })}`}
        >
            <DropdownButton
                key="Success"
                id="dropdown-split-variants-Success"
                variant={lastState !== t("To configure") ? "success" : "danger"}
                title={t(lastState)}
                size="sm"
                onClick={(e: any) => setConfigureValue(e.target.text)}
            >
                <Dropdown.Item eventKey="1">{t("Configured")}</Dropdown.Item>
                <Dropdown.Item eventKey="2">{t("To configure")}</Dropdown.Item>
            </DropdownButton>
        </div>
    );
}
