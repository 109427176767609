import React from "react";
import { useSnapshot } from "valtio";
import classNames from "classnames";

import { designStore, generalConfigStore } from "@store";
import { getImageContentById } from "@helpers/general";

export function ActionButton(): JSX.Element {
    const {
        paymentTPAActionsButtonsItems,
        activeTabIndex,
        selectedLanguage,
    } = useSnapshot(designStore);
    const {
        project: { files: images },
    } = useSnapshot(generalConfigStore);

    const activeActionsButtons = paymentTPAActionsButtonsItems.filter(
        (item) => {
            return item.active === true;
        }
    );

    const mainActiveActionsButtons = activeActionsButtons.sort((a, b) => {
        return a.role > b.role ? -1 : 1;
    });

    const imageContent = getImageContentById(
        mainActiveActionsButtons[0].id,
        images,
        selectedLanguage
    );

    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    return (
        <React.Fragment>
            <div
                className={classNames(
                    "mx-3 d-flex justify-content-center align-items-center",
                    {
                        "border-wrapper__clz": activeTabIndex === 4,
                    }
                )}
                style={{ gap: "5px" }}
            >
                {mainActiveActionsButtons.map((item, index) => {
                    return (
                        <img
                            alt="img"
                            key={index}
                            src={imageSrc}
                            style={{
                                height: "30px",
                                width: "150px",
                                borderRadius: "5px",
                            }}
                        />
                    );
                })}
            </div>
        </React.Fragment>
    );
}
