export async function fetcherWithToken(url: string) {
    try {
        const token = localStorage.getItem("jwt");
        const response = await fetch(url, {
            headers: {
                authorization: `Bearer ${token}`,
            },
        });

        let text = await response.text();

        const data = text ? JSON.parse(text) : null;

        if (response.ok) {
            return data;
        } else {
            throw new Error("Error while fetching data");
        }
    } catch (error: any) {
        if (!error.data) {
            error.data = { message: error.message };
        }
        throw error;
    }
}
