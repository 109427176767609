import React from "react";
import { AvForm, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import ReactSwitch from "react-switch";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { ModalBody } from "reactstrap";

import store, {
    setIsConfigurationCashManagementClicked,
} from "@pages/Applications/Kiosk/store";
import { store as formStore } from "./store";
import { store as userStore } from "@components/VerticalLayout/store";

import { ByNumber } from "./ByNumber";
import { ByPercent } from "./ByPercent";
import { Footer } from "../Footer";

import "./index.css";

type CashFundAlert = {
    rowData: any;
    oneShop: boolean;
    setDataIsUpdated: Function;
    setRowData: Function;
};

export function CashFund({
    rowData,
    oneShop,
    setDataIsUpdated,
    setRowData,
}: CashFundAlert) {
    const { t } = useTranslation();
    const { userID, franchiseID, shopID } = useSnapshot(userStore);
    const { isConfigurationCashManagementClicked } = useSnapshot(store);
    const { isValidateCashFundForum } = useSnapshot(formStore);

    const [alertForm, setAlertForm] = React.useState<any>(
        Object.keys(rowData.cash_Fund).length > 0
            ? rowData.cash_Fund.alertForm
            : t("By number")
    );
    const [isControleActive, setIsContolActive] = React.useState<any>(
        Object.keys(rowData.cash_Fund).length > 0
            ? rowData.cash_Fund.state
            : false
    );
    const [totalBillets, setTotalBillets] = React.useState<number>(
        Object.keys(rowData.cash_Fund).length > 0
            ? rowData.cash_Fund.totalBillets
            : 0
    );
    const [totalPieces, setTotalPieces] = React.useState<number>(
        Object.keys(rowData.cash_Fund).length > 0
            ? rowData.cash_Fund.totalPieces
            : 0
    );
    const [total, setTotal] = React.useState<number>(
        Object.keys(rowData.cash_Fund).length > 0 ? rowData.cash_Fund.total : 0
    );
    const [billets, setBillets] = React.useState<any>([]);
    const [pieces, setPieces] = React.useState<any>([]);

    const handleSetRowData = () => {
        let newRowData = { ...rowData } as any;
        newRowData.cash_Fund = Object.assign(
            {
                total: total,
                alertForm: alertForm,
                state: isControleActive,
                totalBillets: totalBillets,
                totalPieces: totalPieces,
            },
            billets.concat(pieces)
        );
        setRowData(newRowData);
    };

    async function handleValidateButtonClickEvent() {
        let userId: number;
        let shopId: string;
        let franchiseId: string;
        if (oneShop) {
            franchiseId = franchiseID;
            shopId = shopID.toString();
            userId = userID;
        } else {
            userId = userID;
            shopId = rowData.shopId;
            franchiseId = franchiseID;
        }
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/application/cash_management/cash_fund`;
        let savedData = {
            userId: userId,
            shopId: shopId,
            franchiseId: franchiseId,
            appId: "KIOSK",
            appId_children: rowData.appId,
            peripheralId: rowData.peripheralId,
            data: Object.assign(
                {
                    total: total,
                    alertForm: alertForm,
                    state: isControleActive,
                    totalBillets: totalBillets,
                    totalPieces: totalPieces,
                },
                billets.concat(pieces)
            ),
        };
        try {
            if (isValidateCashFundForum && total > 0) {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bareer ${localStorage.getItem(
                                "jwt"
                            )}`,
                        },
                        body: JSON.stringify(savedData),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error(
                                    `${t("Error while saving selection!")}`
                                );
                            }

                            toast.success(
                                `${t("Registration successfully")}!`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                }
                            );
                            handleSetRowData();
                        })
                );
            }
        } catch (e: any) {
            toast.error(`${"There's an error"}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <React.Fragment>
            <ModalBody style={{ height: "89.7%" }}>
                <AvForm>
                    <div className="d-flex align-items-center ">
                        <div className="input-cash__clz">
                            {t("Cash fund control")}
                        </div>
                        <div className="float-right p-2 mt-2">
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={isControleActive}
                                onChange={(e: any) => {
                                    if (
                                        Object.keys(rowData.cash_Fund).length >
                                        0
                                    ) {
                                        rowData.cash_Fund.state = !rowData
                                            .cash_Fund.state;
                                        setIsContolActive(
                                            rowData.cash_Fund.state
                                        );
                                    } else {
                                        setIsContolActive(!isControleActive);
                                    }
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={55}
                                height={20}
                            />
                        </div>
                        <div className="d-flex align-items-center justify-content-between ml-5">
                            <AvForm
                                model={{
                                    form: alertForm,
                                    checkItOut: true,
                                }}
                            >
                                <AvRadioGroup
                                    name="form"
                                    required
                                    inline
                                    errorMessage="Pick one!"
                                >
                                    <AvRadio
                                        id={t("By number")}
                                        label={t("By number")}
                                        value={t("By number")}
                                        disabled={!isControleActive}
                                        onChange={() => {
                                            setAlertForm(t("By number"));
                                        }}
                                        style={{
                                            height: "20px",
                                            width: "20px",
                                            marginTop: "4px",
                                        }}
                                    />
                                    <AvRadio
                                        id={t("By percentage")}
                                        label={t("By percentage")}
                                        name="radio"
                                        value={t("By percentage")}
                                        disabled={!isControleActive}
                                        onChange={() => {
                                            setAlertForm(t("By percentage"));
                                        }}
                                        style={{
                                            height: "20px",
                                            width: "20px",
                                            marginTop: "4px",
                                        }}
                                    />
                                </AvRadioGroup>
                            </AvForm>
                        </div>
                    </div>
                    {alertForm === t("By number") ? (
                        <ByNumber
                            alertForm={alertForm}
                            isControleActive={isControleActive}
                            totalPieces={totalPieces}
                            totalBillets={totalBillets}
                            rowData={rowData}
                            setTotal={setTotal}
                            setTotalBillets={setTotalBillets}
                            setTotalPieces={setTotalPieces}
                            setPieces={setPieces}
                            setBillets={setBillets}
                        />
                    ) : (
                        <ByPercent
                            alertForm={alertForm}
                            isControleActive={isControleActive}
                            totalPieces={totalPieces}
                            totalBillets={totalBillets}
                            rowData={rowData}
                            setTotal={setTotal}
                            setTotalBillets={setTotalBillets}
                            setTotalPieces={setTotalPieces}
                            setPieces={setPieces}
                            setBillets={setBillets}
                        />
                    )}

                    <div className="border-footer__clz mt-2" />
                    <h5
                        style={{ color: "black", marginLeft: "26px" }}
                        className="mt-2 p-1"
                    >
                        {t("Total amount")}:{"  "}
                        {total >= 0 ? total.toFixed(2) : "0.00"} €
                    </h5>
                </AvForm>
            </ModalBody>
            <Footer
                isConfigurationCashManagementClicked={
                    isConfigurationCashManagementClicked
                }
                setIsConfigurationCashManagementClicked={
                    setIsConfigurationCashManagementClicked
                }
                handleValidateButtonClickEvent={handleValidateButtonClickEvent}
                setDataIsUpdated={setDataIsUpdated}
            />
        </React.Fragment>
    );
}
