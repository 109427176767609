import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import PageTitle from "@components/Common/PageTitle";
import MessageInfo from "@components/Common/MessageInfo";
import {
    customColumnProps,
    DynamicTable,
} from "@maherunlocker/custom-react-table";
import React from "react";
import { useTranslation } from "react-i18next/";
import { useSnapshot } from "valtio";
import { ToastContainer } from "react-toastify";

import { store as useStore } from "@components/VerticalLayout/store";
import {
    setIsEdited,
    setModify,
    setNumberActive,
    setNumberArchived,
    setSelectedApplicationSco,
    store,
} from "../store";

import { CustomSideFilter } from "./CustomSideFilter";
import { AddApplication } from "./AddApplication";
import { CustomIcons } from "./CustomIcons";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import HistoryModal from "../HistoryModal";
import { ConfirmationModalArchived } from "../ConfirmationModalArchived";
import { ConfirmationModalActivedApplication } from "../ConfirmationModalActivedApplication";
import { DeviceAssociateModal } from "./DeviceAssociateModal/index";
import DeviceDissociateModal from "./DeviceDissociateModal";
import PrinterSetting from "./PrinterSetting";
import OriginHistory from "@pages/Peripherals/Printers/OriginHistory";
import CashManagementSetting from "./CashManagementSetting";
import { MoneticValinaSetting } from "./MoneticSetting/MoneticValinaSetting";
import BadgeNumber from "@components/Common/BadgeNumber";
import { useLocation } from "react-router-dom";
import { getPathAuthorization } from "@components/VerticalLayout";

export default function ScoDeviceSetup() {
    const { t } = useTranslation();
    const location = useLocation();
    setModify(getPathAuthorization(location.pathname).Modifie);
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [isAddApplication, setIsAddApplication] = React.useState<boolean>(
        false
    );
    const [isHistoryModalOpened, setIsHistoryModalOpened] = React.useState<
        boolean
    >(false);
    const [
        isAddPrinterOriginHistoryClicked,
        setIsAddPrinterOriginHistoryClicked,
    ] = React.useState<boolean>(false);
    const [urlHistoryPrinter, setUrlHistoryPrinter] = React.useState<string>(
        ``
    );
    const [isApplicationArchived, setIsApplicationArchived] = React.useState<
        boolean
    >(false);
    const i18nextLng = localStorage.getItem("i18nextLng") || "";
    const [data, setData] = React.useState<any>({});
    const [typeDisplay, setTypeDisplay] = React.useState<string>("notArchived");
    const [selectedRows, setSelectedRows] = React.useState<any>({});
    const [isDataUpdated, setIsDataUpdated] = React.useState<boolean | number>(
        false
    );
    const [
        isAssociateModalOpened,
        setIsDeviceAssociateModalOpened,
    ] = React.useState<boolean>(false);
    const [
        isDeviceDissociateModalOpened,
        setIsDeviceDissociateModalOpened,
    ] = React.useState<boolean>(false);
    const [
        isConfirmationModalArchived,
        setIsConfirmationModalArchived,
    ] = React.useState<boolean>(false);

    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [
        isConfirmationModalActived,
        setIsConfirmationModalActived,
    ] = React.useState<boolean>(false);
    const [
        isAddNewApplicationButtonClicked,
        setIsAddNewApplicationButtonClicked,
    ] = React.useState(false);

    const appId = "SCO";
    const { oneShop, userID, franchiseID, shopID } = useSnapshot(useStore);
    const {
        isConfigurationPrinterClicked,
        isConfigurationCashManagementClicked,
        isConfigurationMoneticClicked,
    } = useSnapshot(store);
    const [shopId, setShopId] = React.useState<string>("");
    const url = oneShop
        ? `${process.env.REACT_APP_API_V2_URL}/settings/applications/sco/oneShop?userId=${userID}&shopId=${shopID}&franchiseId=${franchiseID}&appId=${appId}&typeDisplay=${typeDisplay}`
        : `${process.env.REACT_APP_API_V2_URL}/settings/applications/sco?userId=${userID}&franchiseId=${franchiseID}&appId=${appId}&typeDisplay=${typeDisplay}`;

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: oneShop ? 1 : 2,
            columnName: t("Application"),
            customJsx: (e: any) => {
                return oneShop ? (
                    e.selectedRow[t("Application")]
                ) : e.selectedRow[t("Shop")] !== "" ? (
                    <BadgeNumber value={e.selectedRow.subRows?.length} />
                ) : (
                    e.selectedRow[t("Application")]
                );
            },
        },
        {
            indexOFColumn: 999,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    e={e}
                    setDataIsUpdated={setIsDataUpdated}
                    selectedRows={selectedRows}
                    isAddApplication={isAddApplication}
                    setIsAddApplication={setIsAddApplication}
                    isApplicationArchived={isApplicationArchived}
                    setShopId={setShopId}
                    setLocalFilterActive={setLocalFilterActive}
                    setSelectedRows={setSelectedRows}
                    setIsHistoryModalOpened={setIsHistoryModalOpened}
                    isHistoryModalOpened={isHistoryModalOpened}
                    setIsConfirmationModalArchived={
                        setIsConfirmationModalArchived
                    }
                    setIsDeviceAssociateModalOpened={
                        setIsDeviceAssociateModalOpened
                    }
                    setIsConfirmationModalActived={
                        setIsConfirmationModalActived
                    }
                    setIsDeviceDissociateModalOpened={
                        setIsDeviceDissociateModalOpened
                    }
                    setUrlHistoryPrinter={setUrlHistoryPrinter}
                    setIsAddPrinterOriginHistoryClicked={
                        setIsAddPrinterOriginHistoryClicked
                    }
                />
            ),
            disableFilter: false,
        },
    ];
    React.useEffect(() => {
        if (filterActive) setIsAddApplication(false);
    }, [filterActive]);

    React.useEffect(() => {
        if (isApplicationArchived) {
            setTypeDisplay("Archived");
        } else {
            setTypeDisplay("notArchived");
        }
        let array: any = [];

        Object.values(selectedRows).forEach((element: any) => {
            if (element.appId !== undefined) {
                array.push({ [element.shopId]: element.appId });
            }
        });
        setSelectedApplicationSco(array);
    }, [selectedRows, shopID, oneShop, data, isApplicationArchived]);
    React.useEffect(() => {
        setNumberActive(
            data.othersData !== undefined
                ? data.othersData.activedApplications
                : 0
        );
        setNumberArchived(
            data.othersData !== undefined
                ? data.othersData.archivedApplications
                : 0
        );
    }, [data]);
    React.useEffect(() => {
        if (i18nextLng === "fr" || i18nextLng === "en") {
            setIsDataUpdated(true);
        }
    }, [i18nextLng]);

    return (
        <React.Fragment>
            <PageTitle title={t("Applications")} />

            <div className="page-content__clz">
                <StyledBreadcrumb
                    items={[
                        {
                            item: "ETK Settings".toUpperCase(),
                            link: "https://dev-portail.aureskonnect.com",
                        },
                        {
                            item:
                                localStorage
                                    .getItem("selectedAccount")
                                    ?.toUpperCase() || "",
                            link: "/compte",
                        },
                        {
                            item: t("Application").toUpperCase(),
                            link: "applications",
                        },
                        {
                            item: t("SCO").toUpperCase(),
                            link: "sco",
                        },
                        {
                            item: t(
                                typeDisplay === "notArchived"
                                    ? "Peripheral setting"
                                    : "Archived peripheral setting"
                            ).toUpperCase(),
                            link: "sco-peripheral-setting",
                        },
                    ]}
                />
                <MessageInfo message={t("Please set your application")} />

                <div
                    style={{
                        display: "grid",
                        minHeight: oneShop ? "650px" : "600px",
                        gridTemplateColumns: isAddApplication
                            ? "2fr 1fr "
                            : "auto",
                        gridColumnGap: "4px",
                        border: 0,
                    }}
                >
                    <DynamicTable
                        url={url}
                        name="Item_table"
                        requestHeader={{ "Accept-Language": i18nextLng }}
                        setData={setData}
                        canExpand
                        canSort
                        customSelect
                        customJsxSideFilterButton={
                            <CustomSideFilter
                                isAddApplication={isAddApplication}
                                setIsAddApplication={setIsAddApplication}
                                setLocalFilterActive={setLocalFilterActive}
                                setDataIsUpdated={setIsDataUpdated}
                                selectedRows={selectedRows}
                                isApplicationArchived={isApplicationArchived}
                                setIsApplicationArchived={
                                    setIsApplicationArchived
                                }
                                setIsConfirmationModalActived={
                                    setIsConfirmationModalActived
                                }
                                shopId={shopId}
                                setIsInInitializationModalOpened={
                                    setIsInInitializationModalOpened
                                }
                                setIsHistoryModalOpened={
                                    setIsHistoryModalOpened
                                }
                                setIsConfirmationModalArchived={
                                    setIsConfirmationModalArchived
                                }
                                isHistoryModalOpened={isHistoryModalOpened}
                                setSelectedRows={setSelectedRows}
                            />
                        }
                        canResize
                        canSelect
                        elevationTable={1}
                        setSelectedRows={setSelectedRows}
                        showGlobalFilter
                        showFilter
                        canMovedCheckboxLeftOnExpand
                        actionColumn={() => <></>}
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        filterActive={filterActive}
                        setLocalFilterActive={setLocalFilterActive}
                        setDataIsUpdated={setIsDataUpdated}
                        dataIsUpdated={isDataUpdated}
                        minHeight="500px"
                        maxHeight="500px"
                    />
                    {isAddApplication ? (
                        <AddApplication
                            isAddApplication={isAddApplication}
                            setIsAddApplication={setIsAddApplication}
                            setDataIsUpdated={setIsDataUpdated}
                            isAddNewApplicationButtonClicked={
                                isAddNewApplicationButtonClicked
                            }
                            data={data}
                            selectedRows={selectedRows}
                        />
                    ) : null}
                    {isInInitializationModalOpened === true ? (
                        <ConfirmationInitializationMessage
                            isModalOpened={isInInitializationModalOpened}
                            setIsModalOpened={setIsInInitializationModalOpened}
                            setIsClicked={setIsAddNewApplicationButtonClicked}
                            isClicked={isAddNewApplicationButtonClicked}
                            setIsEdited={setIsEdited}
                        />
                    ) : null}
                    {isHistoryModalOpened ? (
                        <HistoryModal
                            ids={selectedRows}
                            setIsModalOpened={setIsHistoryModalOpened}
                            isModalOpened={isHistoryModalOpened}
                            setIsDataUpdated={setIsDataUpdated}
                        />
                    ) : null}
                    {isConfirmationModalArchived ? (
                        <ConfirmationModalArchived
                            setIsConfirmationModalArchived={
                                setIsConfirmationModalArchived
                            }
                            isConfirmationModalArchived={
                                isConfirmationModalArchived
                            }
                            setIsDataUpdated={setIsDataUpdated}
                            selectedRows={selectedRows}
                        />
                    ) : null}
                    {isConfirmationModalActived ? (
                        <ConfirmationModalActivedApplication
                            setIsConfirmationModalActived={
                                setIsConfirmationModalActived
                            }
                            isConfirmationModalActived={
                                isConfirmationModalActived
                            }
                            setIsDataUpdated={setIsDataUpdated}
                            selectedRows={selectedRows}
                        />
                    ) : null}
                    {isAssociateModalOpened ? (
                        <DeviceAssociateModal
                            setIsDeviceAssociateModalOpened={
                                setIsDeviceAssociateModalOpened
                            }
                            isDeviceAssociateModalOpened={
                                isAssociateModalOpened
                            }
                            selectedRows={selectedRows}
                            isDataUpdated={isDataUpdated}
                            setDataIsUpdated={setIsDataUpdated}
                            oneShop={oneShop}
                        />
                    ) : null}
                    <DeviceDissociateModal
                        selectedRows={selectedRows}
                        isDataUpdated={isDataUpdated}
                        setDataIsUpdated={setIsDataUpdated}
                        setIsDeviceDissociateModalOpened={
                            setIsDeviceDissociateModalOpened
                        }
                        isDeviceDissociateModalOpened={
                            isDeviceDissociateModalOpened
                        }
                    />
                    {isConfigurationPrinterClicked ? (
                        <PrinterSetting
                            rowData={selectedRows}
                            isDataUpdated={isDataUpdated}
                            setDataIsUpdated={setIsDataUpdated}
                            oneShop={oneShop}
                        />
                    ) : null}
                    {isConfigurationCashManagementClicked ? (
                        <CashManagementSetting
                            rowData={selectedRows}
                            setRowData={setSelectedRows}
                            setDataIsUpdated={setIsDataUpdated}
                            oneShop={oneShop}
                        />
                    ) : null}
                    {isConfigurationMoneticClicked ? (
                        <MoneticValinaSetting
                            infoData={{
                                userId: userID,
                                shopId: shopId,
                                franchiseId: franchiseID,
                                appId: appId,
                                appId_children: selectedRows.appChildrenId,
                            }}
                            data={selectedRows}
                            setDataIsUpdated={setIsDataUpdated}
                        />
                    ) : null}

                    {isAddPrinterOriginHistoryClicked && (
                        <OriginHistory
                            url={urlHistoryPrinter}
                            setIsModalOpened={
                                setIsAddPrinterOriginHistoryClicked
                            }
                            isModalOpened={isAddPrinterOriginHistoryClicked}
                        />
                    )}

                    <ToastContainer limit={1} />
                </div>
            </div>
        </React.Fragment>
    );
}
