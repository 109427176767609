import { proxy } from "valtio";




type StoreType = {
    numberActive: number,
    numberArchived: number,
    isEdited: boolean,
    isPlusIconClicked: boolean
    selectedApplicationKds: any[],
    isAddPeripheralClicked: boolean,
    allPrinters: any[];
    printerSelected: any[];
    selectedPeripherals: any[];
    isPrinterModified: boolean;
    checkModificationEffect: boolean;
    printers: any[],
    isConfigurationPrinterClicked: boolean
    isModalAssociateStore: boolean,
    isModalDissociateOpened: boolean,
    template: any
    activeStateScreenItems: any[],
    view: any,
    advertisingAnimation: any,
    language: any,
    model: any,
    options: any,
    categoryAssignment: any,
    projectFiles:any[],
    uploadedFiles:any[]





};

export const store = proxy<StoreType>({
    numberActive: 0,
    numberArchived: 0,
    isEdited: false,
    isPlusIconClicked: false,
    selectedApplicationKds: [],
    isAddPeripheralClicked: false,
    allPrinters: [],
    printerSelected: [],
    selectedPeripherals: [],
    isPrinterModified: false,
    checkModificationEffect: false,
    printers: [],
    isConfigurationPrinterClicked: false,
    isModalAssociateStore: false,
    isModalDissociateOpened: false,
    template: {
        "View": {

        }, "AdvertisingAnimation": {}, "Language": {}, "Model": {

        }, "Options": {}, "CategoryAssignment": {}
    },
    activeStateScreenItems: [],
    view: {},
    advertisingAnimation: {},
    language: {},
    model: {},
    options: {},
    categoryAssignment: {},
    projectFiles:[],
    uploadedFiles:[]




});

export function setNumberActive(
    numberActive: number
): void {
    store.numberActive = numberActive;
}
export function setIsModalAssociateStore(
    isModalAssociateStore: boolean
): void {
    store.isModalAssociateStore = isModalAssociateStore;
}
export function setIsModalDissociateOpened(
    isModalDissociateOpened: boolean
): void {
    store.isModalDissociateOpened = isModalDissociateOpened;
}
export function setNumberArchived(
    numberArchived: number
): void {
    store.numberArchived = numberArchived;
}
export function setIsEdited(isEdited: boolean): void {
    store.isEdited = isEdited;
}
export function setIsPlusIconClicked(isPlusIconClicked: boolean): void {
    store.isPlusIconClicked = isPlusIconClicked;
}
export function setSelectedApplicationKds(selectedApplicationKds: any[]): any {
    store.selectedApplicationKds = selectedApplicationKds;
}
export function setIsAddPeripheralClicked(isAddPeripheralClicked: boolean) {
    store.isAddPeripheralClicked = isAddPeripheralClicked;
}
export function setAllPrinters(printer: any) {
    store.allPrinters.push(printer);
}
export function setInAllPrinters(printer: any) {
    if (
        store.printerSelected.filter((x: any) => {
            return x.Id === printer.Id;
        }).length === 0
    ) {
        store.printerSelected.push(printer);
    } else {
        store.printerSelected = store.printerSelected.filter((x: any) => {
            return x.Id !== printer.Id;
        });
    }
}

export function setIsPrinterModified(isPrinterModified: boolean) {
    store.isPrinterModified = isPrinterModified;
}
export function setCheckModificationEffect(x: boolean) {
    store.checkModificationEffect = x;
}
export function resetStore() {
    store.printers = [];
    store.printerSelected = [];
}
export function setIsConfigurationPrinterClicked(isConfigurationPrinterClicked: boolean) {
    store.isConfigurationPrinterClicked = isConfigurationPrinterClicked;
}
export function setTemplate(
    template: any
): void {
    store.template = template;
}
export function setActiveStateScreenItems(
    activeStateScreenItems: any
): void {
    store.activeStateScreenItems = activeStateScreenItems;
}
export function setView(
    view: any
): void {
    store.view = view;
}
export function setAdvertisingAnimation(
    advertisingAnimation: any
): void {
    store.advertisingAnimation = advertisingAnimation;
}
export function setModel(
    model: any
): void {
    store.model = model;
}
export function setLanguage(
    language: any
): void {
    store.language = language;
}
export function setOptions(
    options: any
): void {
    store.options = options;
}
export function setCategoryAssignment(
    categoryAssignment: any
): void {
    store.categoryAssignment = categoryAssignment;
}
export function setProjectFiles(images: ImageItemType[]): void {
    store.projectFiles = images;
}
export function setUploadedFiles(images: ImageItemType[]): void {
    store.uploadedFiles = images;
}

export default store;
