import React from "react";
import { useSnapshot } from "valtio";

import { designStore, generalConfigStore } from "@store";

import { getImageContentById, truncateString } from "@helpers/general";

export function HeaderWrapper(): JSX.Element {
    const {
        selectedLanguage,

        selectedSubStep,
        isIconActive,
        isSubStepInformationMessageActive,
        subStepInformationMessageItems,
    } = useSnapshot(designStore);
    const {
        project: { template, files: images },
    } = useSnapshot(generalConfigStore);

    const iconImageContent = getImageContentById(
        ((template.pages.subSteps[
            (selectedSubStep as ProjectMainContentItemType).shortName as string
        ] as PageType).header.icon as IconType).id,
        images,
        selectedLanguage
    );

    const iconImageSrc =
        iconImageContent?.includes("http") ||
        iconImageContent?.includes("https")
            ? iconImageContent
            : `./images/${iconImageContent}`;

    return (
        <React.Fragment>
            {isIconActive === true ? (
                <div className="d-flex justify-content-center align-items-center">
                    <img
                        alt="img"
                        src={iconImageSrc}
                        style={{ height: "80px", width: "80px" }}
                    />
                </div>
            ) : null}

            {isSubStepInformationMessageActive ? (
                <div className="d-flex justify-content-center align-items-center cursor__clz">
                    <span>
                        {truncateString(
                            subStepInformationMessageItems[selectedLanguage]
                                .content,
                            40
                        )}
                    </span>
                </div>
            ) : null}
        </React.Fragment>
    );
}
