import React from "react";
import "./index.css";
export default function Spinner() {
    return (
        <div
            style={{
                position: "fixed",
                zIndex: 9999,
                top: "510px",
                right: "950px",
                width: "12px",
            }}
        >
            <div className="loader" />
        </div>
    );
}
