import React from "react";
import { Droppable, DroppableProvided } from "react-beautiful-dnd";
import classNames from "classnames";
import styled from "styled-components";

import ListItem from "./ListItem";

type DraggableElementPropsType = {
    prefix: string;
    elements: (
        | PageType
        | ConsommationPageType
        | StandbyScreenPageType
        | PaymentPageType
        | OrderTakingPageType
    )[];
};

type DroppableStylesPropsType = Pick<DraggableElementPropsType, "prefix">;

const DroppableStyles = styled.div<DroppableStylesPropsType>`
    display: grid;
    background-color: ${({ prefix }: DroppableStylesPropsType) =>
        prefix === "ways" ? "#EBEBEB" : "#F8F8F8"};
`;

export function DraggableElement({
    prefix,
    elements,
}: DraggableElementPropsType): JSX.Element {
    return (
        <DroppableStyles prefix={prefix}>
            <Droppable
                droppableId={prefix}
                direction={prefix === "ways" ? "horizontal" : "vertical"}
            >
                {(provided: DroppableProvided, snapshot): JSX.Element => {
                    return (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className={classNames({
                                "px-4 d-flex flex-row align-items-center":
                                    provided.droppableProps[
                                        "data-rbd-droppable-id"
                                    ] === "ways",
                            })}
                            style={{
                                background: snapshot.isDraggingOver
                                    ? "#A6B0CF"
                                    : "",
                                overflowX:
                                    provided.droppableProps[
                                        "data-rbd-droppable-id"
                                    ] === "ways"
                                        ? "auto"
                                        : "hidden",
                                overflowY:
                                    provided.droppableProps[
                                        "data-rbd-droppable-id"
                                    ] === "elements"
                                        ? "auto"
                                        : "hidden",
                                gap:
                                    provided.droppableProps[
                                        "data-rbd-droppable-id"
                                    ] === "ways"
                                        ? "13px"
                                        : "unset",
                                maxHeight: "697px",
                            }}
                        >
                            {elements.map(
                                (
                                    item:
                                        | PageType
                                        | ConsommationPageType
                                        | StandbyScreenPageType
                                        | PaymentPageType
                                        | OrderTakingPageType,
                                    index: number
                                ): JSX.Element => {
                                    return (
                                        <ListItem
                                            key={item.id}
                                            item={item}
                                            index={index}
                                        />
                                    );
                                }
                            )}
                            {provided.placeholder}
                        </div>
                    );
                }}
            </Droppable>
        </DroppableStyles>
    );
}
