import React from "react";
import classnames from "classnames";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next/";
import { StyledButton } from "@aureskonnect/react-ui";

import {
    store,
    setCashManagements,
    setSelectedPeripherals,
    setCheckModificationEffect,
} from "../../../store";
import { store as useStore } from "../../../../PeripheralConfigure/MoneticPeripheralConfigure/store";

export default function CashManagementActionColumn({
    rowData,
    element,
    isDataCashManagementUpdated,
}: any) {
    const { t } = useTranslation();
    const { cashManagements } = useSnapshot(store);
    const { dataTable } = useSnapshot(useStore);

    const [isSelected, setIsSelected] = React.useState<boolean>(false);

    function isSelectedCashManagement() {
        let isSelectedCashManagement: any[] = [];
        dataTable.forEach((element: any) => {
            if (rowData[0].shopId === element.shopId) {
                for (let el of element.subRows) {
                    if (el?.subRows !== undefined) {
                        for (let row of el.subRows) {
                            if (row.peripheralType === "cash_management") {
                                if (
                                    cashManagements
                                        .map((item) => item.Id)
                                        .indexOf(row.peripheralId) === -1
                                ) {
                                    isSelectedCashManagement.push(row);
                                }
                            }
                        }
                    }
                }
            }
        });
        return isSelectedCashManagement;
    }

    let cashManagementList: any[] = JSON.parse(
        JSON.stringify(isSelectedCashManagement())
    );

    React.useEffect(() => {
        if (rowData !== undefined && rowData[0].subRows !== undefined) {
            rowData[0].subRows.forEach((el: any) => {
                if (
                    element.Id === el.peripheralId ||
                    cashManagements
                        .map((item) => item.Id)
                        .indexOf(element.Id) !== -1
                ) {
                    setCashManagements(element);
                    setIsSelected(true);
                }
            });
        }
        // eslint-disable-next-line
    }, [rowData, isDataCashManagementUpdated]);

    return (
        <StyledButton
            rounded
            outline
            variant={
                cashManagements.map((item) => item.Id).indexOf(element.Id) !==
                -1
                    ? "danger"
                    : "light"
            }
            className={`${classnames("button-style-select___clz", {
                "not-allowed-icon__clz":
                    (cashManagements.length === 1 &&
                        cashManagements
                            .map((item) => item.Id)
                            .indexOf(element.Id) === -1) ||
                    cashManagementList
                        .map((item) => item.peripheralId)
                        .indexOf(element.Id) !== -1,
            })}`}
            onClick={() => {
                setCashManagements(element);
                setSelectedPeripherals(element);
                setIsSelected(!isSelected);
                setCheckModificationEffect(true);
            }}
        >
            <span>
                {cashManagements.map((item) => item.Id).indexOf(element.Id) !==
                -1
                    ? t("Deselect")
                    : t("Select")}
            </span>
        </StyledButton>
    );
}
