import React from "react";
import { CheckboxIcon } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { getUniqueId } from "@helpers/general";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";
import { mutate } from "swr";

import { store } from "@components/VerticalLayout/store";
import {
    resetPrinterData,
    setPrinterData,
    store as printerDataStore,
} from "./store";
import {
    store as generalStore,
    setIsActionsClicked,
    setActions,
} from "../../store";
import { resetAllPeripherals } from "../../DeviceAssociateModal/store";
import { store as userStore } from "@components/VerticalLayout/store";
import { resetCashManagementData } from "../CashManagementPeripheralConfigure/store";
import { resetMoneticData } from "../MoneticPeripheralConfigure/store";

import { verifyIfPeripheralAssociate } from "../../../../helpers/peripheralsHelpers";
import ErrorToast from "@components/Common/ErrorTost";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type FooterType = {
    setDataIsUpdated: Function;
    editedData: any;
    selectedRows: any;
    isAddPeripheralClicked: boolean;
    isEdited: boolean;
    setIsAddPeripheralClicked: Function;
    setEditedData: Function;
    setIsEdited: Function;
    setIsConfirmationEditModalOpened: Function;
};

export function CardFooter({
    setEditedData,
    setDataIsUpdated,
    editedData,
    selectedRows,
    isAddPeripheralClicked,
    isEdited,
    setIsAddPeripheralClicked,
    setIsEdited,
    setIsConfirmationEditModalOpened,
}: FooterType) {
    const { t } = useTranslation();
    const matchWord = new RegExp(
        /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/i
    );
    const notStartWithSpaceRegExp = new RegExp(/^\S/);

    const { isActionsClicked } = useSnapshot(generalStore);
    const { franchiseID, userID } = useSnapshot(store);
    const { oneShop } = useSnapshot(userStore);
    const { shopID } = useSnapshot(userStore);
    const shopId = oneShop ? shopID : selectedRows?.shopId;
    const appId = "KIOSK";
    let ifPeripheralAssociateObject: {
        isPeripheralAssociate: boolean;
        appId_children: any[];
    };
    const {
        printerType,
        printerPath,
        designation,
        mark,
        note,
        speed,
        ip,
        port_series,
        fontSize,
        ticketWidth,
        ipPortNumber,
        isNameExist,
        isIpPrinter,
        isUsbPrinter,
        isModalOpened,
    } = useSnapshot(printerDataStore);

    async function handleEditButtonClickEventPrinter() {
        toast.dismiss();
        resetAllPeripherals();
        ifPeripheralAssociateObject = await verifyIfPeripheralAssociate(
            editedData.peripheralId,
            shopId,
            franchiseID,
            userID,
            appId
        );

        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/printer?isPeripheralAssociate=${ifPeripheralAssociateObject.isPeripheralAssociate}`;

        let savedData = {
            userId: userID,
            shopId: shopId,
            franchiseId: franchiseID,
            appId: appId,
            appId_children: ifPeripheralAssociateObject.appId_children,
            peripheralId: editedData.peripheralId,
            data: [
                {
                    id: editedData.peripheralId,
                    designation: designation.trim(),
                    mark: mark,
                    note: note,
                    shopId: shopId,
                    type: printerType,
                    speed: speed,
                    path: printerPath,
                    ip: ip,
                    port: ipPortNumber,
                    font_size: fontSize,
                    edit_date: new Date(),
                    add_date: "",
                    ticket_width: ticketWidth,
                    port_series: port_series,
                    isArchived: false,
                },
            ],
        };
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(savedData),
                    method: "PUT",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error!");
                        }
                        toast.success(
                            `${t("The device was successfully changed")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated(true);

                        resetCashManagementData();

                        setIsAddPeripheralClicked(!isAddPeripheralClicked);

                        resetAllPeripherals();
                        resetPrinterData();
                        resetCashManagementData();
                        resetMoneticData();
                        setEditedData([]);
                        setIsEdited(false);
                        activateCheckbox();
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function handleValidateButtonClickEventPrinter() {
        toast.dismiss();
        resetAllPeripherals();
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/printer`;

        let savedData = {
            userId: userID,
            shopId: shopId,
            franchiseId: franchiseID,
            data: [
                {
                    id: getUniqueId(10),
                    designation: designation.trim(),
                    mark: mark,
                    note: note,
                    shopId: shopId,
                    type: printerType,
                    speed: printerType !== "ip" ? speed : "",
                    path: printerPath,
                    ip: ip,
                    port: ipPortNumber,
                    font_size: fontSize,
                    edit_date: new Date(),
                    add_date: new Date(),
                    ticket_width: ticketWidth,
                    port_series: port_series,
                    isArchived: false,
                },
            ],
        };
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(savedData),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                        toast.success(
                            `${t("The device is successfully added")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated(true);

                        setEditedData([]);
                        resetAllPeripherals();
                        resetPrinterData();
                        resetCashManagementData();
                        resetMoneticData();
                        setEditedData([]);
                        setIsEdited(false);
                        activateCheckbox();
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    function handleValidate() {
        if (designation === "") {
            setPrinterData(true, "inputDesignation");
        }
        if (ip === "") {
            setPrinterData(true, "inputIp");
        }
        if (printerPath === "") {
            setPrinterData(true, "inputPrinterPath");
        }
        if (editedData.length === 0) {
            if (
                (isIpPrinter &&
                    ip.length !== 0 &&
                    designation.length !== 0 &&
                    ip.match(matchWord) &&
                    !isNameExist &&
                    designation.trim() !== "" &&
                    designation.match(notStartWithSpaceRegExp)) ||
                (!isIpPrinter &&
                    !isUsbPrinter &&
                    designation.length !== 0 &&
                    !isNameExist &&
                    designation.trim() !== "" &&
                    designation.match(notStartWithSpaceRegExp)) ||
                (isUsbPrinter &&
                    designation.length !== 0 &&
                    !isNameExist &&
                    designation.trim() !== "" &&
                    designation.match(notStartWithSpaceRegExp))
            ) {
                if (shopId === undefined) {
                    ErrorToast(`${t("Attention ! you must select a store.")!}`);
                } else {
                    handleValidateButtonClickEventPrinter();
                }
            }
        } else {
            if (isEdited === false) {
                setPrinterData(!isModalOpened, "isModalOpened");
                setIsConfirmationEditModalOpened(true);
            } else if (
                (isIpPrinter &&
                    ip.length !== 0 &&
                    designation.length !== 0 &&
                    ip.match(matchWord) &&
                    !isNameExist &&
                    designation.trim() !== "" &&
                    designation.match(notStartWithSpaceRegExp)) ||
                (!isIpPrinter &&
                    !isUsbPrinter &&
                    designation.length !== 0 &&
                    !isNameExist &&
                    designation.trim() !== "" &&
                    designation.match(notStartWithSpaceRegExp)) ||
                (isUsbPrinter &&
                    designation.length !== 0 &&
                    !isNameExist &&
                    designation.trim() !== "" &&
                    designation.match(notStartWithSpaceRegExp))
            )
                handleEditButtonClickEventPrinter();
        }
    }
    function activateCheckbox() {
        let checks = document.getElementsByClassName("PrivateSwitchBase-input");
        for (let i = 0; i < checks.length; i++) {
            checks[i].classList.remove("not-allowed-icon-check__clz");
        }
    }

    return (
        <React.Fragment>
            <CustomSecondaryColorButton
                outline
                rounded
                variant="light"
                className="mr-2 afp_btn_FRcancel"
                onClick={() => {
                    if (isEdited === true) {
                        setIsActionsClicked(!isActionsClicked);
                        setActions(() => {
                            activateCheckbox();
                            setIsAddPeripheralClicked(!isAddPeripheralClicked);
                            resetPrinterData();
                            resetCashManagementData();
                            resetAllPeripherals();
                            resetMoneticData();
                            setEditedData([]);
                            setIsEdited(false);
                        });
                    } else {
                        activateCheckbox();
                        setIsAddPeripheralClicked(!isAddPeripheralClicked);
                        resetPrinterData();
                        resetCashManagementData();
                        resetAllPeripherals();
                        resetMoneticData();
                        setEditedData([]);
                        setIsEdited(false);
                    }
                }}
            >
                <span> {t("Cancel")}</span>
            </CustomSecondaryColorButton>
            <CustomMainColorButton
                rounded
                variant="primary"
                className="afp_btn_FRvalidate"
                onClick={handleValidate}
            >
                {t("Validate")}
            </CustomMainColorButton>
        </React.Fragment>
    );
}
