import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import {
    RefreshIcon,
    StyledIconButton,
    EyeCrossedIcon,
    EyeIcon,
} from "@aureskonnect/react-ui";
import { AvForm } from "availity-reactstrap-validation";
import { Table } from "reactstrap";

import {
    designStore,
    setInformationMessageItem,
    setAmountToBePaidItem,
    setTotalAmountItem,
    setPaymentMainContent,
    setPaymentConfig,
} from "@store";

import { SwiperLanguagesWrapper } from "@components/Common/Design/SwiperLanguagesWrapper";
import { CustomInput as Input } from "@components/Common/CustomInput";

import "@components/Common/Design/index.css";
import "@assets/css/global.css";

export function MainContentCreditCard(): JSX.Element {
    const { t } = useTranslation();

    const {
        paymentMainContent,
        paymentSelectedRegulationModeValue,
        informationMessageItem,
        amountToBePaidItem,
        totalAmountItem,
        selectedLanguage,
    } = useSnapshot(designStore);

    const selectedPaymentMethod = paymentMainContent.filter((item) => {
        return item.shortName === paymentSelectedRegulationModeValue;
    });

    function handleInformationMessageItemInputOnChangeEvent(content: string) {
        const informationMessage = { ...informationMessageItem };

        const localItems = {
            ...informationMessage,
            name: content,
        };
        setInformationMessageItem(localItems);
        const mainContentItem = [...paymentMainContent];

        const itemPayment = mainContentItem.find(
            (item) => item.shortName === paymentSelectedRegulationModeValue
        );

        if (itemPayment !== undefined) {
            const itemIndex = mainContentItem.indexOf(itemPayment);

            mainContentItem[itemIndex] = {
                ...itemPayment,
                languages: {
                    ...itemPayment.languages,
                    [selectedLanguage]: {
                        ...itemPayment.languages[selectedLanguage],
                        amount: {
                            ...itemPayment.languages[selectedLanguage].amount,
                            informationMessage: localItems,
                        },
                    },
                },
            };

            setPaymentMainContent(mainContentItem);
            setPaymentConfig(mainContentItem);
        }
    }

    function handleAmountToBePaidItemInputOnChangeEvent(content: string) {
        const amountToBePaid = { ...amountToBePaidItem };

        const localItems = {
            ...amountToBePaid,
            name: content,
        };

        setAmountToBePaidItem(localItems);
        const mainContentItem = [...paymentMainContent];

        const itemPayment = mainContentItem.find(
            (item) => item.shortName === paymentSelectedRegulationModeValue
        );

        if (itemPayment !== undefined) {
            const itemIndex = mainContentItem.indexOf(itemPayment);

            mainContentItem[itemIndex] = {
                ...itemPayment,
                languages: {
                    ...itemPayment.languages,
                    [selectedLanguage]: {
                        ...itemPayment.languages[selectedLanguage],
                        amount: {
                            ...itemPayment.languages[selectedLanguage].amount,
                            amountToBePaid: localItems,
                        },
                    },
                },
            };

            setPaymentMainContent(mainContentItem);
            setPaymentConfig(mainContentItem);
        }
    }

    function handleTotalAmountItemInputOnChangeEvent(content: string) {
        const totalAmount = { ...totalAmountItem };

        const localItems = {
            ...totalAmount,
            name: content,
        };

        setTotalAmountItem(localItems);

        const mainContentItem = [...paymentMainContent];

        const itemPayment = mainContentItem.find(
            (item) => item.shortName === paymentSelectedRegulationModeValue
        );

        if (itemPayment !== undefined) {
            const itemIndex = mainContentItem.indexOf(itemPayment);

            mainContentItem[itemIndex] = {
                ...itemPayment,
                languages: {
                    ...itemPayment.languages,
                    [selectedLanguage]: {
                        ...itemPayment.languages[selectedLanguage],
                        amount: {
                            ...itemPayment.languages[selectedLanguage].amount,
                            totalAmount: localItems,
                        },
                    },
                },
            };

            setPaymentMainContent(mainContentItem);
            setPaymentConfig(mainContentItem);
        }
    }

    function handleResetTranslationsOnClickEvent() {
        const informationMessage = { ...informationMessageItem };
        const amountToBePaid = { ...amountToBePaidItem };
        const totalAmount = { ...totalAmountItem };

        const localInformationMessage = {
            ...informationMessage,
            name: informationMessage.defaultName,
            isTextVisible: informationMessage.isDefaultTextVisible,
        };
        const localAmountToBePaid = {
            ...amountToBePaid,
            name: amountToBePaid.defaultName,
        };
        const localTotalAmount = {
            ...totalAmount,
            name: totalAmount.defaultName,
        };

        setInformationMessageItem(localInformationMessage);
        setAmountToBePaidItem(localAmountToBePaid);
        setTotalAmountItem(localTotalAmount);

        const mainContentItem = [...paymentMainContent];

        const itemPayment = mainContentItem.find(
            (item) => item.shortName === paymentSelectedRegulationModeValue
        );

        if (itemPayment !== undefined) {
            const itemIndex = mainContentItem.indexOf(itemPayment);

            mainContentItem[itemIndex] = {
                ...itemPayment,
                languages: {
                    ...itemPayment.languages,
                    [selectedLanguage]: {
                        ...itemPayment.languages[selectedLanguage],
                        amount: {
                            ...itemPayment.languages[selectedLanguage].amount,
                            informationMessage: localInformationMessage,
                            amountToBePaid: localAmountToBePaid,
                            totalAmount: localTotalAmount,
                        },
                    },
                },
            };

            setPaymentMainContent(mainContentItem);
            setPaymentConfig(mainContentItem);
        }
    }

    function handleResetLanguagesTranslationsOnClickEvent() {
        const amountToBePaid = { ...amountToBePaidItem };
        const totalAmount = { ...totalAmountItem };

        const localAmountToBePaid = {
            ...amountToBePaid,
            name: amountToBePaid.defaultName,
        };
        const localTotalAmount = {
            ...totalAmount,
            name: totalAmount.defaultName,
        };

        setAmountToBePaidItem(localAmountToBePaid);
        setTotalAmountItem(localTotalAmount);

        const mainContentItem = [...paymentMainContent];

        const itemPayment = mainContentItem.find(
            (item) => item.shortName === paymentSelectedRegulationModeValue
        );

        if (itemPayment !== undefined) {
            const itemIndex = mainContentItem.indexOf(itemPayment);

            const languages = Object.fromEntries(
                Object.entries(itemPayment.languages).map(([key, value]) => [
                    key,
                    {
                        ...value,
                        amount: {
                            ...value.amount,
                            informationMessage: {
                                ...value.amount.informationMessage,
                                name: value.amount.informationMessage
                                    .defaultName,
                                isTextVisible:
                                    value.amount.informationMessage
                                        .isDefaultTextVisible,
                            },
                            amountToBePaid: {
                                ...value.amount.amountToBePaid,
                                name: value.amount.amountToBePaid.defaultName,
                            },
                            totalAmount: {
                                ...value.amount.totalAmount,
                                name: value.amount.totalAmount.defaultName,
                            },
                        },
                    },
                ])
            );
            mainContentItem[itemIndex] = {
                ...itemPayment,
                languages,
            };

            setPaymentMainContent(mainContentItem);
            setPaymentConfig(mainContentItem);
        }
    }
    function handleCheckIconOnClickEvent() {
        const informationMessage = { ...informationMessageItem };
        const localItems = {
            ...informationMessage,
            isTextVisible: !informationMessage.isTextVisible,
        };

        setInformationMessageItem(localItems);

        const mainContentItem = [...paymentMainContent];

        const itemPayment = mainContentItem.find(
            (item) => item.shortName === paymentSelectedRegulationModeValue
        );

        if (itemPayment !== undefined) {
            const itemIndex = mainContentItem.indexOf(itemPayment);

            mainContentItem[itemIndex] = {
                ...itemPayment,
                languages: {
                    ...itemPayment.languages,
                    [selectedLanguage]: {
                        ...itemPayment.languages[selectedLanguage],
                        amount: {
                            ...itemPayment.languages[selectedLanguage].amount,
                            informationMessage: localItems,
                        },
                    },
                },
            };

            setPaymentMainContent(mainContentItem);
            setPaymentConfig(mainContentItem);
        }
    }

    return (
        <div
            className="mt-3 rounded border"
            style={{
                maxWidth: "800px",
                height: "96%",
                borderColor: "#CECECE",
            }}
        >
            <div
                className="d-flex justify-content-between align-items-center"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                <div className="text-left px-4 d-flex align-items-center">
                    {t("Translation")}
                </div>
                <StyledIconButton
                    title={t("Reset")}
                    icon="RefreshIcon m-0"
                    onClick={() => {
                        handleResetLanguagesTranslationsOnClickEvent();
                    }}
                >
                    <RefreshIcon height={15} width={15} />
                </StyledIconButton>
            </div>

            <div className="p-2" style={{ width: "798px" }}>
                <SwiperLanguagesWrapper />
                {selectedPaymentMethod.length > 0 ? (
                    <div className="mt-3 mx-4">
                        <AvForm>
                            <Table
                                bordered
                                className="payment-amount-table__clz"
                            >
                                <thead>
                                    <tr>
                                        <th
                                            className="p-0"
                                            style={{
                                                width: "35%",
                                                verticalAlign: "middle",
                                            }}
                                        >
                                            <div className="ml-2 d-flex justify-content-center align-items-center">
                                                {t("Default text")}
                                            </div>
                                        </th>
                                        <th className="p-0">
                                            <div
                                                className="d-grid__clz"
                                                style={{
                                                    gridTemplateColumns:
                                                        "1fr 0.1fr",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    {t("Custom")}
                                                </div>

                                                <StyledIconButton
                                                    title={t("Reset")}
                                                    icon="RefreshIcon m-0"
                                                    onClick={() => {
                                                        handleResetTranslationsOnClickEvent();
                                                    }}
                                                >
                                                    <RefreshIcon
                                                        height={15}
                                                        width={15}
                                                    />
                                                </StyledIconButton>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td
                                            style={{
                                                width: "35%",
                                            }}
                                        >
                                            {informationMessageItem.defaultName}
                                        </td>
                                        <td>
                                            <div
                                                className="d-flex justify-content-between align-items-center"
                                                style={{
                                                    gap: "7px",
                                                }}
                                            >
                                                <Input
                                                    value={
                                                        informationMessageItem.name
                                                    }
                                                    onChange={(
                                                        event: React.ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        handleInformationMessageItemInputOnChangeEvent(
                                                            event.target.value
                                                        );
                                                    }}
                                                    disabled={
                                                        informationMessageItem.isTextVisible ===
                                                        true
                                                            ? false
                                                            : true
                                                    }
                                                />
                                                {informationMessageItem.name !==
                                                "" ? (
                                                    informationMessageItem.isTextVisible ===
                                                    true ? (
                                                        <EyeIcon
                                                            height={25}
                                                            width={20}
                                                            onClick={() => {
                                                                handleCheckIconOnClickEvent();
                                                            }}
                                                        />
                                                    ) : (
                                                        <EyeCrossedIcon
                                                            height={25}
                                                            width={20}
                                                            onClick={() => {
                                                                handleCheckIconOnClickEvent();
                                                            }}
                                                            style={{
                                                                fill: "red",
                                                            }}
                                                        />
                                                    )
                                                ) : null}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            style={{
                                                width: "35%",
                                            }}
                                        >
                                            {amountToBePaidItem.defaultName}
                                        </td>
                                        <td>
                                            <div
                                                className="d-flex justify-content-between align-items-center"
                                                style={{
                                                    gap: "7px",
                                                }}
                                            >
                                                <Input
                                                    value={
                                                        amountToBePaidItem.name
                                                    }
                                                    onChange={(
                                                        event: React.ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        handleAmountToBePaidItemInputOnChangeEvent(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            style={{
                                                width: "35%",
                                            }}
                                        >
                                            {totalAmountItem.defaultName}
                                        </td>
                                        <td>
                                            <div
                                                className="d-flex justify-content-between align-items-center"
                                                style={{
                                                    gap: "7px",
                                                }}
                                            >
                                                <Input
                                                    value={totalAmountItem.name}
                                                    onChange={(
                                                        event: React.ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        handleTotalAmountItemInputOnChangeEvent(
                                                            event.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </AvForm>
                    </div>
                ) : null}
            </div>
        </div>
    );
}
