import styled from "styled-components";

type ContainerWrapperPropsType = {
    isTopBannerActive: boolean;
    isHeaderActive: boolean;
    isLanguagesActive: boolean;
    isConfigOptionsActive: boolean;
    isLogoActive: boolean;
    isActionButtonsActive: boolean;
};

export const ContainerWrapper = styled.div<ContainerWrapperPropsType>`
    display: grid;
    background-color: transparent;
    position: relative;
    grid-template-rows: ${({
        isTopBannerActive,
        isHeaderActive,
        isLanguagesActive,
        isConfigOptionsActive,
        isActionButtonsActive,
    }: ContainerWrapperPropsType) =>
        isTopBannerActive &&
        isHeaderActive &&
        isActionButtonsActive &&
        isLanguagesActive &&
        isConfigOptionsActive
            ? "110px 80px 320px 75px 70px 70px"
            : isLanguagesActive &&
              isHeaderActive &&
              isConfigOptionsActive &&
              isTopBannerActive &&
              !isActionButtonsActive
            ? "110px 80px 395px 70px 70px"
            : !isLanguagesActive &&
              !isHeaderActive &&
              !isConfigOptionsActive &&
              !isTopBannerActive &&
              isActionButtonsActive
            ? "650px 75px;"
            : isLanguagesActive &&
              isHeaderActive &&
              isConfigOptionsActive &&
              !isTopBannerActive &&
              isActionButtonsActive
            ? "135px 375px 75px 70px 70px;"
            : !isLanguagesActive &&
              !isHeaderActive &&
              !isConfigOptionsActive &&
              isTopBannerActive &&
              !isActionButtonsActive
            ? "110px 615px;"
            : isLanguagesActive &&
              isHeaderActive &&
              isConfigOptionsActive &&
              !isTopBannerActive &&
              !isActionButtonsActive
            ? "135px 450px 70px 70px;"
            : !isLanguagesActive &&
              !isHeaderActive &&
              !isConfigOptionsActive &&
              isTopBannerActive &&
              isActionButtonsActive
            ? "110px 540px 75px;"
            : isLanguagesActive &&
              isHeaderActive &&
              !isConfigOptionsActive &&
              isTopBannerActive &&
              isActionButtonsActive
            ? "110px 80px 395px 75px 70px;"
            : !isLanguagesActive &&
              !isHeaderActive &&
              isConfigOptionsActive &&
              !isTopBannerActive &&
              !isActionButtonsActive
            ? "655px 70px;"
            : isLanguagesActive &&
              isHeaderActive &&
              !isConfigOptionsActive &&
              isTopBannerActive &&
              !isActionButtonsActive
            ? "110px 80px 465px 70px;"
            : !isLanguagesActive &&
              !isHeaderActive &&
              isConfigOptionsActive &&
              !isTopBannerActive &&
              isActionButtonsActive
            ? "580px 75px 70px;"
            : isLanguagesActive &&
              isHeaderActive &&
              !isConfigOptionsActive &&
              !isTopBannerActive &&
              isActionButtonsActive
            ? "135px 445px 75px 70px;"
            : !isLanguagesActive &&
              !isHeaderActive &&
              isConfigOptionsActive &&
              isTopBannerActive &&
              !isActionButtonsActive
            ? "110px 545px 70px;"
            : isLanguagesActive &&
              isHeaderActive &&
              !isConfigOptionsActive &&
              !isTopBannerActive &&
              !isActionButtonsActive
            ? "135px 520px 70px;"
            : !isLanguagesActive &&
              !isHeaderActive &&
              isConfigOptionsActive &&
              isTopBannerActive &&
              isActionButtonsActive
            ? "110px 470px 75px 70px;"
            : isLanguagesActive &&
              !isHeaderActive &&
              isConfigOptionsActive &&
              isTopBannerActive &&
              isActionButtonsActive
            ? "110px 400px 75px 70px 70px;"
            : !isLanguagesActive &&
              isHeaderActive &&
              !isConfigOptionsActive &&
              !isTopBannerActive &&
              !isActionButtonsActive
            ? "135px 590px;"
            : isLanguagesActive &&
              !isHeaderActive &&
              isConfigOptionsActive &&
              isTopBannerActive &&
              !isActionButtonsActive
            ? "110px 475px 70px 70px;"
            : !isLanguagesActive &&
              isHeaderActive &&
              !isConfigOptionsActive &&
              !isTopBannerActive &&
              isActionButtonsActive
            ? "135px 515px 75px;"
            : isLanguagesActive &&
              !isHeaderActive &&
              isConfigOptionsActive &&
              !isTopBannerActive &&
              isActionButtonsActive
            ? "510px 75px 70px 70px"
            : !isLanguagesActive &&
              isHeaderActive &&
              !isConfigOptionsActive &&
              isTopBannerActive &&
              !isActionButtonsActive
            ? "110px 80px 535px;"
            : isLanguagesActive &&
              !isHeaderActive &&
              isConfigOptionsActive &&
              !isTopBannerActive &&
              !isActionButtonsActive
            ? "585px 70px 70px;"
            : !isLanguagesActive &&
              isHeaderActive &&
              !isConfigOptionsActive &&
              isTopBannerActive &&
              isActionButtonsActive
            ? "110px 80px 460px 75px;"
            : isLanguagesActive &&
              !isHeaderActive &&
              !isConfigOptionsActive &&
              isTopBannerActive &&
              isActionButtonsActive
            ? "110px 470px 75px 70px;"
            : !isLanguagesActive &&
              isHeaderActive &&
              isConfigOptionsActive &&
              !isTopBannerActive &&
              !isActionButtonsActive
            ? "135px 520px 70px;"
            : isLanguagesActive &&
              !isHeaderActive &&
              !isConfigOptionsActive &&
              isTopBannerActive &&
              !isActionButtonsActive
            ? "110px 545px 70px;"
            : !isLanguagesActive &&
              isHeaderActive &&
              isConfigOptionsActive &&
              !isTopBannerActive &&
              isActionButtonsActive
            ? "135px 445px 75px 70px;"
            : isLanguagesActive &&
              !isHeaderActive &&
              !isConfigOptionsActive &&
              !isTopBannerActive &&
              isActionButtonsActive
            ? "580px 75px 70px;"
            : !isLanguagesActive &&
              isHeaderActive &&
              isConfigOptionsActive &&
              isTopBannerActive &&
              !isActionButtonsActive
            ? "110px 80px 465px 70px;"
            : isLanguagesActive &&
              !isHeaderActive &&
              !isConfigOptionsActive &&
              !isTopBannerActive &&
              !isActionButtonsActive
            ? "655px 70px;"
            : !isLanguagesActive &&
              isHeaderActive &&
              isConfigOptionsActive &&
              isTopBannerActive &&
              isActionButtonsActive
            ? "110px 80px 390px 75px 70px;"
            : !isLanguagesActive &&
              !isHeaderActive &&
              !isConfigOptionsActive &&
              !isTopBannerActive &&
              !isActionButtonsActive
            ? "725px;"
            : ""};
    height: 100%;
`;
