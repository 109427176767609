import React from "react";
import { useSnapshot } from "valtio";
import classNames from "classnames";
import { t } from "i18next";

import { designStore, generalConfigStore } from "@store";

import { FinalMessageContainerWrapper } from "@pages/GeneralConfig/Design/Preview/FinalMessage/FinalMessageContainerWrapper";
import { HeaderWrapper } from "@components/Common/Design/Preview/HeaderWrapper";
import { TopBannerWrapper } from "@components/Common/Design/Preview/TopBannerWrapper";
import { PreviewBackgroundWrapper } from "@components/Common/Design/Preview/PreviewBackgroundWrapper";

export function PreviewFinalMessage(): JSX.Element {
    const {
        isTopBannerActive,
        isHeaderActive,
        isLogoActive,
        activeTabIndex,
        finalMessage,
        isFooterFinalMessageActive,
        isFooterTicketNumberMessageActive,
        footerTicketNumberMessage,
        selectedLanguage,
        footerFinalMessage,
    } = useSnapshot(designStore);

    const {
        project: {
            template: {
                content: { generalDesign },
            },
        },
    } = useSnapshot(generalConfigStore);

    return (
        <PreviewBackgroundWrapper>
            <FinalMessageContainerWrapper
                isTopBannerActive={isTopBannerActive}
                isHeaderActive={isHeaderActive}
                isLogoActive={isLogoActive}
            >
                {isTopBannerActive ? <TopBannerWrapper /> : null}

                {isHeaderActive ? <HeaderWrapper /> : null}

                <div
                    className={classNames(
                        "d-flex justify-content-center align-items-center",
                        {
                            "border-wrapper__clz": activeTabIndex === 2,
                        }
                    )}
                    style={{
                        width: "429px",
                        marginLeft: "1px",
                    }}
                >
                    <span
                        className="d-flex justify-content-center align-items-center text-center mx-3 cursor__clz"
                        style={{
                            font: "normal 24px/30px Segoe UI",
                        }}
                    >
                        {finalMessage}
                    </span>
                </div>
                <div
                    className={classNames(
                        "d-flex justify-content-center align-items-center",
                        {
                            "border-wrapper__clz": activeTabIndex === 3,
                        }
                    )}
                >
                    <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{ height: "335px" }}
                    >
                        <div
                            className="d-flex flex-column justify-content-center align-items-center cursor__clz"
                            style={{
                                height: "80px",
                                font: "normal 15px/20px Segoe UI",
                            }}
                        >
                            {isFooterTicketNumberMessageActive
                                ? footerTicketNumberMessage[selectedLanguage]
                                      ?.defaultContent !== undefined
                                    ? t(
                                          footerTicketNumberMessage[
                                              selectedLanguage
                                          ].content
                                      )
                                    : null
                                : null}
                        </div>

                        <div
                            className="d-flex flex-column justify-content-center align-items-center cursor__clz"
                            style={{
                                height: "175px",
                                font: "normal 91px/121px Segoe UI",
                                color: `${
                                    (generalDesign as GeneralDesignType)
                                        .colors[0].content
                                }`,
                            }}
                        >
                            510
                        </div>
                        <div
                            className="d-flex flex-column justify-content-center align-items-center cursor__clz"
                            style={{
                                height: "80px",
                                font: "normal 24px/20px Segoe UI",
                            }}
                        >
                            {isFooterFinalMessageActive
                                ? footerFinalMessage[selectedLanguage]
                                      .content !== undefined
                                    ? footerFinalMessage[selectedLanguage]
                                          .content
                                    : null
                                : null}
                        </div>
                    </div>
                </div>
            </FinalMessageContainerWrapper>
        </PreviewBackgroundWrapper>
    );
}
