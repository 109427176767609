import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    CheckboxIcon,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";

import { store } from "../../../../components/VerticalLayout/store";
import {
    store as service,
    setNumberActive,
    setNumberArchived,
    setIsValidateClicked,
} from "../store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationActiveType = {
    setIsModalConfirmationOpened: Function;
    isModalConfirmationOpened: boolean;
    setDataIsUpdated: Function;
    selectedRows: any;
    isOneShop: boolean;
};

export function ConfirmationActiveService({
    setIsModalConfirmationOpened,
    isModalConfirmationOpened,
    setDataIsUpdated,
    selectedRows,
}: ConfirmationActiveType) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const { numberActive, numberArchived, shopsServiceData } = useSnapshot(
        service
    );
    const [error, setError] = React.useState(false);
    function ArchivedService(e: any) {
        let idsAssociated = ["0"];
        if (shopsServiceData !== undefined) {
            shopsServiceData.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    if (el.Id === e.Id) idsAssociated.push(el.shopId);
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];

        return idsAssociated;
    }

    async function activeService(isValidation: boolean) {
        setIsValidateClicked(false);
        let localData: any = [];
        toast.dismiss();
        Object.values(selectedRows).forEach(async (element: any) => {
            const id = element.Id;
            let shopsArchived = ArchivedService(element);
            let dataService = {
                userId: userID,
                shopId: isValidation ? shopsArchived : ["0"],
                franchiseId: franchiseID,
                operatorId: operatorID,
                data: {
                    [id]: {
                        designation: element[t("Designation")],
                        heure_début: element[t("Start time")],
                        heure_fin: element[t("End time")],
                        tag: element.tag,
                        activeDays: element?.activeDays,
                        jour_de_la_semaine: element[t("Jour de la semaine")],
                        isActive: element.isActive,
                        isArchived: false,
                    },
                },
            };

            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/services`;

            localData.push(
                fetch(apiUrlAdd, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(dataService),
                }).then((response) => response.json())
            );
        });
        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/services`,
                await Promise.all(localData).then((result) => {})
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setError(true);
        }

        if (error === false) {
            toast.success(`${t("The service was successfully unarchived")!}`, {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored",
                icon: <CheckboxIcon height={25} width={25} fill="white" />,
            });
            setDataIsUpdated!(true);
            setIsValidateClicked(true);
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalConfirmationOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsModalConfirmationOpened(!isModalConfirmationOpened);
                    setDataIsUpdated!(true);
                }}
            >
                <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {t(
                        "Attention! the service is archived at store level. Do you want to activate it in shops too?"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        activeService(false);
                        setIsModalConfirmationOpened(
                            !isModalConfirmationOpened
                        );
                        setDataIsUpdated!(true);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        activeService(true);
                        setDataIsUpdated!(true);
                        setIsModalConfirmationOpened(
                            !isModalConfirmationOpened
                        );
                        setNumberActive(
                            selectedRows.length !== 0
                                ? numberActive + selectedRows.length
                                : numberActive + 1
                        );
                        setNumberArchived(
                            selectedRows.length !== 0
                                ? numberArchived - selectedRows.length
                                : numberArchived - 1
                        );
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
