import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledLabel, CheckboxIcon } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { capitalize } from "@helpers/general";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { setIsValidateClicked } from "../store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

type ConfirmationModificationMessageType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    setIsEdited: Function;
    setDataIsUpdated: Function;
    dataModeSale: any;
    shopId: any;
    setIsClickedBouttonValider: Function;
    setIsAddDeviceClicked: Function;
};

export function ConfirmationModificationMessage({
    isModalOpened,
    setIsModalOpened,
    setIsEdited,
    setDataIsUpdated,
    dataModeSale,
    shopId,
    setIsClickedBouttonValider,
    setIsAddDeviceClicked,
}: ConfirmationModificationMessageType) {
    const { t } = useTranslation();
    async function editDevise() {
        setIsValidateClicked(false);
        dataModeSale.shopId = shopId;
        const apiUrlUpdate = `${process.env.REACT_APP_API_V2_URL}/settings/general/devise`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataModeSale),
        };

        try {
            mutate(
                apiUrlUpdate,
                await fetch(apiUrlUpdate, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                "The currency of the store(s) has been changed successfully"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated!(true);
                        setIsEdited(false);
                        setIsAddDeviceClicked(false);
                        setIsValidateClicked(true);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <StyledModal toggle={true} isOpen={isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => setIsModalOpened(!isModalOpened)}
            >
                <span
                    style={{
                        fontFamily: "Segoe UI Semibold",
                        color: "#2b2828",
                        fontSize: "20px",
                    }}
                >
                    {capitalize(t("Alert"))}
                </span>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        'Do you want to apply these changes for all stores? \n Attention ! If you click on "Validate", the specific settings of the shops will be modified.'
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0 ">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                        setIsClickedBouttonValider(true);
                        setDataIsUpdated!(true);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        setIsModalOpened(!isModalOpened);
                        editDevise();
                        setIsClickedBouttonValider(true);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
