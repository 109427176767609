import React from "react";
import { useTranslation } from "react-i18next";
import { Tabs } from "@aureskonnect/react-ui";

import { LoadingAlert } from "../LoadingAlert";
import { EmptyAlert } from "../EmptyAlert";
import { CashManagementState } from "../CashManagementState";
import { CollectionAlert } from "../CollectionAlert";

import "@assets/swal.css";
import "@assets/theme.css";
import "../../index.css";

type ConfigurationCashManagementType = {
    rowData: any;
    oneShop: boolean;
    setDataIsUpdated: Function;
};

export default function Alerts({
    rowData,
    oneShop,
    setDataIsUpdated,
}: ConfigurationCashManagementType) {
    const { t } = useTranslation();

    return (
        <Tabs
            tabs={[
                {
                    content: (
                        <LoadingAlert
                            rowData={rowData}
                            oneShop={oneShop}
                            setDataIsUpdated={setDataIsUpdated}
                        />
                    ),
                    disabled: false,
                    title: (
                        <div
                            className="d-flex justify-content-between"
                            style={{
                                gap: "10px",
                                fontSize: "16px",
                                color: "black",
                            }}
                        >
                            {t("Change machine loading alert")}
                        </div>
                    ),
                },
                {
                    content: (
                        <EmptyAlert
                            rowData={rowData}
                            oneShop={oneShop}
                            setDataIsUpdated={setDataIsUpdated}
                        />
                    ),
                    title: (
                        <div
                            className="d-flex justify-content-between"
                            style={{
                                gap: "10px",
                                fontSize: "16px",
                                color: "black",
                            }}
                        >
                            {t("Change machine empty alert")}
                        </div>
                    ),
                },
                {
                    content: (
                        <CashManagementState
                            rowData={rowData}
                            oneShop={oneShop}
                            setDataIsUpdated={setDataIsUpdated}
                        />
                    ),
                    title: (
                        <div
                            className="d-flex justify-content-between"
                            style={{
                                gap: "10px",
                                fontSize: "16px",
                                color: "black",
                            }}
                        >
                            {t("Change machine status")}
                        </div>
                    ),
                },
                {
                    content: (
                        <CollectionAlert
                            rowData={rowData}
                            oneShop={oneShop}
                            setDataIsUpdated={setDataIsUpdated}
                        />
                    ),
                    title: (
                        <div
                            className="d-flex justify-content-between"
                            style={{
                                gap: "10px",
                                fontSize: "16px",
                                color: "black",
                            }}
                        >
                            {t("Collection alert")}
                        </div>
                    ),
                },
            ]}
        />
    );
}
