import React from "react";
import { useSnapshot } from "valtio";

import { designStore, setActiveMainContentItems } from "@store";

import { MainContentWrapper } from "@components/Common/Design/MainContentWrapper";
import { TranslationWrapper } from "@components/Common/Design/MainContentWrapper/TranslationWrapper";

import "@components/Common/Design/index.css";
import "simplebar-react/dist/simplebar.min.css";
import { SubStepTranslationTableWrapper } from "@components/Common/Design/SubStepContent/SubStepTranslationTableWrapper";

export function Modes(): JSX.Element {
    const {
        mainContentItems,
        isSubStepActivated,
        isSubStepInformationMessageActive,
    } = useSnapshot(designStore);

    React.useEffect(() => {
        setActiveMainContentItems(
            mainContentItems.filter((item) => {
                return item.active === true;
            })
        );
    }, [mainContentItems]);

    return (
        <div>
            <MainContentWrapper title="Authentication modes" />
            {isSubStepActivated === false ? (
                <TranslationWrapper />
            ) : isSubStepInformationMessageActive === true ? (
                <SubStepTranslationTableWrapper />
            ) : null}
        </div>
    );
}
