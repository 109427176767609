import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    CrossIcon,
    StyledLabel,
    StyledSelectInput,
    CheckboxIcon,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import classnames from "classnames";
import { uuid } from "uuidv4";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";
import { mutate } from "swr";

import {
    setCountryKeyValue,
    setNumberActive,
    store as generalSettingStore,
    setMessage,
    setIsAssociatedLangues,
    setLocalShop,
    setSelectedRow,
    setIsArchived,
    setIsPlusIconClicked,
    setEditedDataLanguage,
    setIsActionsClicked,
    setActions,
    setIsEdited,
} from "../store";
import { store } from "@components/VerticalLayout/store";

import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import {
    CustomCardHeader,
    CustomCardBody,
    CustomCardFooter,
} from "@components/Common/CustomCardStyled";
import { ImageUploader } from "./ImageUploader";
import { ConfirmationModificationModal } from "./ConfirmationModificationModal";
import { languages } from "@constants/index";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "../index.css";
import "react-toastify/dist/ReactToastify.css";
import "react-languages-select/css/react-languages-select.css";

type LanguageFormType = {
    setIsAddLanguageClicked: Function;
    isAddLanguageClicked: boolean;
    setDataIsUpdated: React.Dispatch<React.SetStateAction<boolean | number>>;
    rowData: React.SetStateAction<any>;
    setIsAddNewLanguageButtonClicked: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    isAddNewLanguageButtonClicked: boolean;
    setDataLanguage: Function;
    setLocalFilterActive: Function;
    dataIsUpdated: boolean | number;
};

export function LanguageForm({
    setIsAddLanguageClicked,
    isAddLanguageClicked,
    setDataIsUpdated,
    rowData,
    isAddNewLanguageButtonClicked,
    setDataLanguage,
    setLocalFilterActive,
    dataIsUpdated,
}: LanguageFormType) {
    const { t } = useTranslation();
    const { userID, franchiseID, shopID, oneShop, operatorID } = useSnapshot(
        store
    );
    const {
        numberActive,
        shopsLanguagesData,
        languagesData,
        languagesFranchise,
        dataArchive,
        archivedLanguageOfFranchise,
        countryKeyValue,
        isPlusIconClicked,
        isActionsClicked,
        isEdited,
    } = useSnapshot(generalSettingStore);
    const shopId = oneShop ? shopID : 0;

    const [uploadedImage, setUploadedImage] = React.useState<any>("");
    const [langue, setLangue] = React.useState<string>("");
    const [selectLangue, setSelectLangue] = React.useState<boolean>(false);
    const [displayName, setDisplayName] = React.useState<string>("");
    const [abbreviation, setAbbreviation] = React.useState<string>("");
    const [selectName, setSelectName] = React.useState<boolean>(false);
    const [country, setCountry] = React.useState<any>("");
    const [selectCountry, setSelectCountry] = React.useState<boolean>(false);
    const [title, setTitle] = React.useState<string>("");
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isOpened, setIsOpened] = React.useState<boolean>(false);
    const [associated, setAssociated] = React.useState<any[]>([]);
    const [countryOptions, setCountryOptions] = React.useState<any[]>([]);
    const [isNotImg, setIsNotImg] = React.useState<boolean>(false);
    const [selectImage, setSelectImage] = React.useState<boolean>(false);
    const [isLanguageExist, setIsLanguageExist] = React.useState<boolean>(
        false
    );
    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState<boolean>(false);
    const [countryKey, setCountryKey] = React.useState<string>("");
    const [borderColor, setBorderColor] = React.useState("none");

    const changeHandler = (value: any) => {
        setCountry(value !== null ? value : "");
        setUploadedImage("");
        setCountryKeyValue(value !== null ? value.value : "");
        setCountryKey(value !== null ? value.value : "");
        setIsEdited(true);
        setSelectCountry(false);
        setSelectImage(false);
        setIsNotImg(false);
    };

    const changeLanguage = (value: any) => {
        setLangue(value !== null ? value.label : "");
        setDisplayName(value !== null ? value.label : "");
        setAbbreviation(value !== null ? value.value : "");
        setIsEdited(true);
        setSelectLangue(false);
        setSelectName(false);
    };

    const fetchCountry = async () => {
        const data = await (
            await fetch(`${process.env.REACT_APP_API_V2_URL}/settings/pays`)
        ).json();
        let listOfCountry: any = [];
        data.data.forEach((element: any) => {
            if (
                listOfCountry.findIndex(
                    (elt: any) => elt.value === element[t("code")]
                ) === -1
            ) {
                listOfCountry.push({
                    label: element.Designation,
                    value: element[t("code")],
                });
            }
        });
        setCountryOptions(listOfCountry);
    };

    const checkIfLanguageExist = () => {
        let isLAnguageExist = false;
        let allLanguages = [...languagesData, ...dataArchive];
        allLanguages.forEach((element) => {
            if (element.Language === langue) {
                setLocalShop([shopID]);
                setSelectedRow([element]);
                isLAnguageExist = true;
                return;
            }
        });
        return isLAnguageExist;
    };

    const checkIfLanguageFranchiseExist = () => {
        let isLAnguageExist = false;
        languagesFranchise.forEach((element) => {
            if (element.Language === langue) {
                setLocalShop([shopID]);
                setSelectedRow([
                    {
                        Id: element.Id,
                        Language: langue,
                        display_name: displayName,
                        country: country,
                        url_image:
                            uploadedImage !== ""
                                ? uploadedImage
                                : `https://flagpedia.net/data/flags/h80/${countryKeyValue.toLowerCase()}.webp`,
                        isArchived: false,
                        isFavorite: rowData.isFavorite,
                        isActive: rowData.isActive,
                        shopId: shopId,
                        abbreviation: abbreviation,
                    },
                ]);
                isLAnguageExist = true;
                return;
            }
        });
        return isLAnguageExist;
    };

    const checkIfLanguageFranchiseExistAndArchived = () => {
        let isLAnguageExist = false;
        archivedLanguageOfFranchise.forEach((element) => {
            if (element.Language === langue) {
                setLocalShop([shopID]);
                setSelectedRow([element]);
                isLAnguageExist = true;
                return;
            }
        });
        return isLAnguageExist;
    };

    async function addLanguage() {
        toast.dismiss();
        const id = uuid();
        let dataLanguage = {
            userId: userID,
            shopId: oneShop ? [shopId, 0] : [shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            adding: true,
            data: {
                [id]: {
                    id: id,
                    language: langue,
                    abbreviation: abbreviation,
                    display_name: displayName,
                    country: country,
                    urlImage:
                        uploadedImage !== ""
                            ? uploadedImage
                            : `https://flagpedia.net/data/flags/h80/${countryKeyValue.toLowerCase()}.webp`,
                    isArchived: false,
                    isFavorite: false,
                    isActive: false,
                    shopId: shopId,
                },
            },
        };
        const data = new FormData();
        data.append("dataLanguage", JSON.stringify(dataLanguage));
        data.append("image", uploadedImage);
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/language`;
        const requestOptions = {
            method: "POST",
            body: data,
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t("The language was successfully added")}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated!(true);
                        setIsEdited(false);
                        setLangue("");
                        setDisplayName("");
                        setCountry("");
                        setCountryKeyValue("");
                        setCountryKey("");
                        setUploadedImage("");
                        setNumberActive(numberActive + 1);
                        setLocalFilterActive(false);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    async function editLanguage() {
        toast.dismiss();
        const id = rowData.Id;
        let dataAssociate: any[] = [];
        let dataLanguage = {
            userId: userID,
            shopId: [shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            data: {
                [id]: {
                    id: id,
                    language: langue,
                    display_name: displayName,
                    country: country,
                    abbreviation: abbreviation,
                    urlImage:
                        uploadedImage !== ""
                            ? uploadedImage
                            : `https://flagpedia.net/data/flags/h80/${countryKeyValue.toLowerCase()}.webp`,
                    isArchived: rowData.isArchived,
                    isFavorite: rowData.isFavorite,
                    isActive: rowData.isActive,
                    shopId: shopId,
                },
            },
        };
        setEditedDataLanguage(dataLanguage);
        const data = new FormData();
        data.append("dataLanguage", JSON.stringify(dataLanguage));
        data.append("image", uploadedImage);
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/language`;
        const requestOptions = {
            method: "POST",
            body: data,
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        if (!oneShop) {
                            dataAssociate = AssociateLangues();
                        }
                        toast.success(
                            `${t(
                                dataAssociate.length > 0
                                    ? "The language of franchise was successfully changed"
                                    : "The language was successfully edited"
                            )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        if (dataAssociate.length > 0) {
                            setIsOpened(true);
                        } else {
                            setIsAddLanguageClicked(!isAddLanguageClicked);
                            setIsEdited(false);
                        }
                        setDataIsUpdated(!dataIsUpdated);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    function AssociateLangues() {
        let idsAssociated: any[] = [];
        let associated: any[] = [];
        if (shopsLanguagesData !== undefined) {
            shopsLanguagesData.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    if (el.Id === rowData.Id) {
                        idsAssociated.push(el.shopId);
                        associated.push(el);
                    }
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return associated;
    }

    function handleValidateButtonOnClickEvent() {
        let urlImage =
            uploadedImage !== ""
                ? uploadedImage
                : `https://flagpedia.net/data/flags/h80/${countryKeyValue.toLowerCase()}.webp`;
        let isLanguageFranchiseExist = checkIfLanguageFranchiseExist();
        let isLanguageFranchiseExistAndArchived = checkIfLanguageFranchiseExistAndArchived();
        if (langue === "") setSelectLangue(true);
        if (country === "") setSelectCountry(true);
        if (displayName === "") setSelectName(true);

        if (uploadedImage === "" && countryKeyValue === "") {
            setSelectImage(true);
        } else if (
            oneShop &&
            isLanguageFranchiseExist &&
            langue !== "" &&
            country !== "" &&
            displayName !== "" &&
            isNotImg === false &&
            isLanguageExist === false &&
            rowData.Id === undefined
        ) {
            setMessage(
                t(
                    "The language already exists in the franchise. do you want to associate it with this shop?"
                )
            );
            setIsAssociatedLangues(true);
        } else if (
            oneShop &&
            isLanguageFranchiseExistAndArchived &&
            langue !== "" &&
            country !== "" &&
            displayName !== "" &&
            isNotImg === false &&
            isLanguageExist === false
        ) {
            setMessage(
                t(
                    "Attention! the language is already archived at the franchise level"
                )
            );
            setIsArchived(true);
            setIsAssociatedLangues(true);
        } else if (
            langue === rowData.Language &&
            displayName === rowData.display_name &&
            country === rowData.country &&
            (urlImage === rowData.url_image ||
                rowData.url_image.endsWith(uploadedImage?.name)) &&
            countryKeyValue === rowData.country.value
        ) {
            setIsModalOpened(true);
        } else if (
            langue !== "" &&
            country !== "" &&
            displayName !== "" &&
            isNotImg === false &&
            isLanguageExist === false
        ) {
            if (rowData.Id !== undefined) editLanguage();
            else addLanguage();
        }
    }

    useEffect(() => {
        setSelectCountry(false);
        setSelectLangue(false);
        setSelectName(false);
        setIsNotImg(false);
        setSelectImage(false);
        if (rowData.Id !== undefined) {
            setTitle(t("Modify a language"));
            setLangue(rowData.Language);
            setAbbreviation(rowData.abbreviation);
            setDisplayName(rowData.display_name);
            setCountry(rowData.country);
            setUploadedImage(
                rowData.url_image.startsWith(
                    "https://flagpedia.net/data/flags/h80"
                )
                    ? ""
                    : rowData.url_image
            );
            setCountryKeyValue(rowData.country.value);
            setCountryKey(rowData.country.value);
        } else {
            setTitle(t("Add a language"));
            setIsEdited(false);
            setLangue("");
            setDisplayName("");
            setCountry("");
            setCountryKeyValue("");
            setCountryKey("");
            setUploadedImage("");
        }
        fetchCountry();
        setAssociated(AssociateLangues);
        // eslint-disable-next-line
    }, [rowData, t, isAddNewLanguageButtonClicked]);

    useEffect(() => {
        let isLanguageExist = checkIfLanguageExist();
        if (
            isLanguageExist &&
            (rowData.Id === undefined ||
                (rowData.Id !== undefined && rowData.Language !== langue))
        ) {
            setIsLanguageExist(true);
        } else {
            setIsLanguageExist(false);
        }
        // eslint-disable-next-line
    }, [country, rowData, langue]);

    React.useEffect(() => {
        setBorderColor(selectImage || isNotImg ? "red" : "#c3bfbe");
        if (isNotImg) setSelectImage(false);
    }, [isNotImg, selectImage]);

    React.useEffect(() => {
        if (isPlusIconClicked) {
            setSelectCountry(false);
            setSelectLangue(false);
            setSelectName(false);
            setIsNotImg(false);
            setSelectImage(false);
            setIsPlusIconClicked(false);
        }
    }, [isPlusIconClicked]);

    return (
        <React.Fragment>
            <AvForm className="card">
                <CustomCardHeader className="d-flex align-items-center justify-content-between">
                    <h5 className="pt-3 lan_txt_FRtitleFormLanguage">
                        {title}
                    </h5>
                    <CrossIcon
                        className="lan_icn_FRcloseForm"
                        height={13}
                        width={13}
                        onClick={() => {
                            if (isEdited === true) {
                                setIsActionsClicked(!isActionsClicked);
                                setActions(() => {
                                    setIsEdited(false);
                                    setCountryKeyValue("");
                                    setIsAddLanguageClicked!(
                                        !isAddLanguageClicked
                                    );
                                });
                            } else {
                                setCountryKeyValue("");
                                setIsAddLanguageClicked!(!isAddLanguageClicked);
                                setIsEdited(false);
                            }
                        }}
                    />
                </CustomCardHeader>
                <CustomCardBody
                    className="pl-3 pr-5"
                    style={{
                        maxHeight: "500px",
                        overflowY: " scroll",
                    }}
                >
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Language")}
                        </StyledLabel>
                        <StyledSelectInput
                            isClearable
                            value={
                                langue === ""
                                    ? null
                                    : {
                                          label: langue,
                                          value: langue,
                                      }
                            }
                            className={classnames(
                                "lan_inp_FRlanguage",
                                {
                                    invalid__clz:
                                        selectLangue || isLanguageExist,
                                },
                                {
                                    "not-allowed-input__clz":
                                        (oneShop ||
                                            (!oneShop &&
                                                AssociateLangues().length >
                                                    0)) &&
                                        rowData.Id !== undefined,
                                }
                            )}
                            onChange={(e: any) => {
                                changeLanguage(e);
                            }}
                            placeholder={t("Select")}
                            options={languages}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: `${t(
                                        "Please select a language"
                                    )!}`,
                                },
                            }}
                            name="mark"
                            noOptionsMessage={() => t("No options")}
                            autoFocus={
                                (!oneShop && AssociateLangues().length === 0) ||
                                rowData.Id === undefined
                            }
                        />
                        {selectLangue ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a language")}
                            </div>
                        ) : null}
                        {isLanguageExist ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("the language already exists")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Display name")}
                        </StyledLabel>
                        <StyledTextInput
                            className={classnames("lan_inp_FRdisplayName", {
                                input__clz: selectName,
                            })}
                            autocomplete="off"
                            name="designation"
                            onChange={(e: any) => {
                                setDisplayName(e.target.value);
                                setIsEdited(true);
                                setSelectName(false);
                            }}
                            value={displayName}
                            placeholder={t("Write")}
                            type="text"
                        />
                        <div className="d-flex flex-wrap "></div>
                        {selectName ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please enter a display name")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Country")}
                        </StyledLabel>

                        <StyledSelectInput
                            isClearable
                            value={country === "" ? null : country}
                            className={classnames("lan_inp_FRcountry", {
                                invalid__clz: selectCountry,
                            })}
                            placeholder={t("Select")}
                            onChange={(e: any) => {
                                changeHandler(e);
                            }}
                            options={countryOptions}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: `${t(
                                        "Please select a country"
                                    )!}`,
                                },
                            }}
                            name="country"
                            noOptionsMessage={() => t("No options")}
                        />
                        {selectCountry ? (
                            <div
                                style={{
                                    width: "100%",
                                    marginTop: "0.25rem",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please select a country")}
                            </div>
                        ) : null}
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel
                            htmlFor="text"
                            className="required__clz mt-3"
                        >
                            {t("Flag")}
                        </StyledLabel>
                        <span style={{ fontSize: "15px", marginLeft: "3px" }}>
                            ({t("Insert an image such as PNG, JPG or JPEG")})
                        </span>
                        <ImageUploader
                            setIsNotImg={setIsNotImg}
                            uploadedImage={uploadedImage}
                            setUploadedImage={setUploadedImage}
                            setIsEdited={setIsEdited}
                            setSelectImage={setSelectImage}
                            borderColor={borderColor}
                            countryKey={countryKey}
                        />
                        {isNotImg && (
                            <div
                                style={{
                                    width: "100%",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please insert a PNG, JPG or JPEG image.")}
                            </div>
                        )}
                        {selectImage && (
                            <div
                                style={{
                                    width: "100%",
                                    fontSize: "80%",
                                    color: "#f46a6a",
                                }}
                            >
                                {t("Please insert a flag.")}
                            </div>
                        )}
                    </AvGroup>
                </CustomCardBody>
                <CustomCardFooter className="pt-1 pb-2">
                    <CustomSecondaryColorButton
                        outline
                        rounded
                        className="lan_btn_FRcancel mr-2"
                        variant="light"
                        onClick={() => {
                            if (isEdited === true) {
                                setActions(() => {
                                    setIsEdited(false);
                                    setIsAddLanguageClicked(
                                        !isAddLanguageClicked
                                    );
                                    setCountryKeyValue("");
                                });
                                setIsActionsClicked(!isActionsClicked);
                            } else {
                                setIsAddLanguageClicked(!isAddLanguageClicked);
                                setCountryKeyValue("");
                            }
                        }}
                    >
                        <span> {t("Cancel")}</span>
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        disabled={isValidateButtonDisabled}
                        onClick={() => {
                            setIsValidateButtonDisabled(true);
                            handleValidateButtonOnClickEvent();
                            setTimeout(() => {
                                setIsValidateButtonDisabled(false);
                            }, 500);
                        }}
                        className="lan_btn_FRvalidate"
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </CustomCardFooter>
            </AvForm>
            {isOpened ? (
                <ConfirmationModificationModal
                    isModalOpened={isOpened}
                    setIsModalOpened={setIsOpened}
                    setIsAddLanguageClicked={setIsAddLanguageClicked}
                    associated={associated}
                    isAddLanguageClicked={isAddLanguageClicked}
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                />
            ) : null}
            <ConfirmationEditMessage
                setDataIsUpdated={setDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={isAddLanguageClicked}
                setIsAddClicked={setIsAddLanguageClicked}
                setEditedData={setDataLanguage}
            />
        </React.Fragment>
    );
}
