import React, { useEffect } from "react";
import ReactSwitch from "react-switch";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import { store as userStore } from "@components/VerticalLayout/store";

import {
    setCustomerInformationModeSaleCode,
    storeKitchenTicketType,
    setCustomerInformationModeSaleName,
    setCustomerInformationModeSaleNumTel,
    setCustomerInformationModeSaleAdress,
    setCustomerInformationModeSaleCity,
    setCustomerInformationModeSaleCompany,
} from "./Hook/store";

import "../index.css";

export default function PrintCustomerInformation({ rowData }: any) {
    const { t } = useTranslation();
    const {
        customerInformationModeSaleName,
        customerInformationModeSaleNumTel,
        customerInformationModeSaleAdress,
        customerInformationModeSaleCity,
        customerInformationModeSaleCode,
        customerInformationModeSaleCompany,
    } = useSnapshot(storeKitchenTicketType);

    const { userID, franchiseID, shopID, oneShop } = useSnapshot(userStore);
    const [salesModesNames, setSalesModesNames] = React.useState<any>([]);
    const [salesModesNumTel, setSalesModesNumTel] = React.useState<any>([]);
    const [salesModesCode, setSalesModesCode] = React.useState<any>([]);
    const [salesModesCompany, setSalesModesCompany] = React.useState<any>([]);
    const [salesModesAdress, setSalesModesAdress] = React.useState<any>([]);
    const [salesModesCity, setSalesModesCity] = React.useState<any>([]);
    const shopId = oneShop ? shopID : rowData.shopId;
    const fetchModeOfSale = React.useCallback(() => {
        fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/mode_of_sale?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`
        )
            .then((res) => res.json())
            .then((response) => {
                const saleModesNames: any[] = response.data.map(
                    (saleMode: any) => {
                        return {
                            saleSupports: saleMode.support_vente.map(
                                // eslint-disable-next-line array-callback-return
                                (el: any) => {
                                    if (el === "KIOSK")
                                        return {
                                            designation:
                                                saleMode["Mode of sale"],
                                            state: false,
                                        };
                                }
                            ),
                        };
                    }
                );

                let salesNames: any[] = [];
                let salesModes: any[] = [];
                let rowDataSales: any[] = [];

                let salesSupportMode: any[] = [];
                if (
                    rowData["Kitchen_ticket"]?.customer_information_mode_sale
                        ?.customerInformationModeSaleName !== undefined
                ) {
                    for (let element of rowData["Kitchen_ticket"]
                        ?.customer_information_mode_sale
                        ?.customerInformationModeSaleName) {
                        rowDataSales.push(element);
                    }
                }
                saleModesNames !== undefined &&
                    saleModesNames.forEach((el: any) => {
                        el.saleSupports.forEach((elt: any) => {
                            if (elt !== undefined) {
                                salesSupportMode.push(elt.designation);
                                rowDataSales = rowDataSales.filter(
                                    (o) => o.designation !== elt.designation
                                );
                            }
                        });
                    });
                saleModesNames !== undefined &&
                    saleModesNames.forEach((el: any) => {
                        if (
                            rowData["Kitchen_ticket"]
                                ?.customer_information_mode_sale
                                ?.customerInformationModeSaleName !== undefined
                        ) {
                            for (let element of rowData["Kitchen_ticket"]
                                ?.customer_information_mode_sale
                                ?.customerInformationModeSaleName) {
                                salesModes.push(element.designation);
                                salesNames.push(element);
                            }
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    elt !== undefined &&
                                    !salesModes.includes(elt.designation)
                                ) {
                                    salesNames.push(elt);
                                }
                            });
                        } else {
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    rowData["Kitchen_ticket"]
                                        ?.customer_information_mode_sale
                                        ?.customerInformationModeSaleName ===
                                        undefined ||
                                    rowData["Kitchen_ticket"]
                                        ?.customer_information_mode_sale
                                        ?.customerInformationModeSaleName === 0
                                ) {
                                    elt !== undefined && salesNames.push(elt);
                                }
                            });
                        }
                    });
                salesNames = [...new Set(salesNames)];
                for (let sale of rowDataSales) {
                    salesNames = salesNames.filter(
                        (o) => o.designation !== sale.designation
                    );
                }
                setSalesModesNames(salesNames);
                const saleModesNumTel: any[] = response.data.map(
                    (saleMode: any) => {
                        return {
                            saleSupports: saleMode.support_vente.map(
                                // eslint-disable-next-line array-callback-return
                                (el: any) => {
                                    if (el === "KIOSK")
                                        return {
                                            designation:
                                                saleMode["Mode of sale"],
                                            state: false,
                                        };
                                }
                            ),
                        };
                    }
                );
                let salesNamesNumTel: any[] = [];
                let salesNumTel: any[] = [];
                let rowDataSalesNumTel: any[] = [];

                let salesSupportModeNumTel: any[] = [];
                if (
                    rowData["Kitchen_ticket"]?.customer_information_mode_sale
                        ?.customerInformationModeSaleNumTel !== undefined
                ) {
                    for (let element of rowData["Kitchen_ticket"]
                        ?.customer_information_mode_sale
                        ?.customerInformationModeSaleNumTel) {
                        rowDataSalesNumTel.push(element);
                    }
                }
                saleModesNumTel !== undefined &&
                    saleModesNumTel.forEach((el: any) => {
                        el.saleSupports.forEach((elt: any) => {
                            if (elt !== undefined) {
                                salesSupportModeNumTel.push(elt.designation);
                                rowDataSalesNumTel = rowDataSalesNumTel.filter(
                                    (o) => o.designation !== elt.designation
                                );
                            }
                        });
                    });
                saleModesNumTel !== undefined &&
                    saleModesNumTel.forEach((el: any) => {
                        if (
                            rowData["Kitchen_ticket"]
                                ?.customer_information_mode_sale
                                ?.customerInformationModeSaleNumTel !==
                            undefined
                        ) {
                            for (let element of rowData["Kitchen_ticket"]
                                ?.customer_information_mode_sale
                                ?.customerInformationModeSaleNumTel) {
                                salesNamesNumTel.push(element.designation);
                                salesNumTel.push(element);
                            }
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    elt !== undefined &&
                                    !salesNamesNumTel.includes(elt.designation)
                                ) {
                                    salesNumTel.push(elt);
                                }
                            });
                        } else {
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    rowData["Kitchen_ticket"]
                                        ?.customer_information_mode_sale
                                        ?.customerInformationModeSaleNumTel ===
                                        undefined ||
                                    rowData["Kitchen_ticket"]
                                        ?.customer_information_mode_sale
                                        ?.customerInformationModeSaleNumTel ===
                                        0
                                ) {
                                    elt !== undefined && salesNumTel.push(elt);
                                }
                            });
                        }
                    });
                salesNumTel = [...new Set(salesNumTel)];

                for (let sale of rowDataSalesNumTel) {
                    salesNumTel = salesNumTel.filter(
                        (o) => o.designation !== sale.designation
                    );
                }
                setSalesModesNumTel(salesNumTel);
                const saleModesAdress: any[] = response.data.map(
                    (saleMode: any) => {
                        return {
                            saleSupports: saleMode.support_vente.map(
                                // eslint-disable-next-line array-callback-return
                                (el: any) => {
                                    if (el === "KIOSK")
                                        return {
                                            designation:
                                                saleMode["Mode of sale"],
                                            state: false,
                                        };
                                }
                            ),
                        };
                    }
                );
                let salesNamesAdress: any[] = [];
                let salesAdress: any[] = [];
                let rowDataSalesAdress: any[] = [];

                let salesSupportModeAdress: any[] = [];
                if (
                    rowData["Kitchen_ticket"]?.customer_information_mode_sale
                        ?.customerInformationModeSaleAdress !== undefined
                ) {
                    for (let element of rowData["Kitchen_ticket"]
                        ?.customer_information_mode_sale
                        ?.customerInformationModeSaleAdress) {
                        rowDataSalesAdress.push(element);
                    }
                }
                saleModesAdress !== undefined &&
                    saleModesAdress.forEach((el: any) => {
                        el.saleSupports.forEach((elt: any) => {
                            if (elt !== undefined) {
                                salesSupportModeAdress.push(elt.designation);
                                rowDataSalesAdress = rowDataSalesAdress.filter(
                                    (o) => o.designation !== elt.designation
                                );
                            }
                        });
                    });
                saleModesAdress !== undefined &&
                    saleModesAdress.forEach((el: any) => {
                        if (
                            rowData["Kitchen_ticket"]
                                ?.customer_information_mode_sale
                                ?.customerInformationModeSaleAdress !==
                            undefined
                        ) {
                            for (let element of rowData["Kitchen_ticket"]
                                ?.customer_information_mode_sale
                                ?.customerInformationModeSaleAdress) {
                                salesNamesAdress.push(element.designation);
                                salesAdress.push(element);
                            }
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    elt !== undefined &&
                                    !salesNamesAdress.includes(elt.designation)
                                ) {
                                    salesAdress.push(elt);
                                }
                            });
                        } else {
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    rowData["Kitchen_ticket"]
                                        ?.customer_information_mode_sale
                                        ?.customerInformationModeSaleAdress ===
                                        undefined ||
                                    rowData["Kitchen_ticket"]
                                        ?.customer_information_mode_sale
                                        ?.customerInformationModeSaleAdress ===
                                        0
                                ) {
                                    elt !== undefined && salesAdress.push(elt);
                                }
                            });
                        }
                    });
                salesAdress = [...new Set(salesAdress)];
                for (let sale of rowDataSalesAdress) {
                    salesAdress = salesAdress.filter(
                        (o) => o.designation !== sale.designation
                    );
                }
                setSalesModesAdress(salesAdress);
                const saleModesCity: any[] = response.data.map(
                    (saleMode: any) => {
                        return {
                            saleSupports: saleMode.support_vente.map(
                                // eslint-disable-next-line array-callback-return
                                (el: any) => {
                                    if (el === "KIOSK")
                                        return {
                                            designation:
                                                saleMode["Mode of sale"],
                                            state: false,
                                        };
                                }
                            ),
                        };
                    }
                );
                let salesNamesCity: any[] = [];
                let salesCity: any[] = [];
                let rowDataSalesCity: any[] = [];

                let salesSupportModeCity: any[] = [];
                if (
                    rowData["Kitchen_ticket"]?.customer_information_mode_sale
                        ?.customerInformationModeSaleCity !== undefined
                ) {
                    for (let element of rowData["Kitchen_ticket"]
                        ?.customer_information_mode_sale
                        ?.customerInformationModeSaleCity) {
                        rowDataSalesCity.push(element);
                    }
                }
                saleModesCity !== undefined &&
                    saleModesCity.forEach((el: any) => {
                        el.saleSupports.forEach((elt: any) => {
                            if (elt !== undefined) {
                                salesSupportModeCity.push(elt.designation);
                                rowDataSalesCity = rowDataSalesCity.filter(
                                    (o) => o.designation !== elt.designation
                                );
                            }
                        });
                    });
                saleModesCity !== undefined &&
                    saleModesCity.forEach((el: any) => {
                        if (
                            rowData["Kitchen_ticket"]
                                ?.customer_information_mode_sale
                                ?.customerInformationModeSaleCity !== undefined
                        ) {
                            for (let element of rowData["Kitchen_ticket"]
                                ?.customer_information_mode_sale
                                ?.customerInformationModeSaleCity) {
                                salesNamesCity.push(element.designation);
                                salesCity.push(element);
                            }
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    elt !== undefined &&
                                    !salesNamesCity.includes(elt.designation)
                                ) {
                                    salesCity.push(elt);
                                }
                            });
                        } else {
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    rowData["Kitchen_ticket"]
                                        ?.customer_information_mode_sale
                                        ?.customerInformationModeSaleCity ===
                                        undefined ||
                                    rowData["Kitchen_ticket"]
                                        ?.customer_information_mode_sale
                                        ?.customerInformationModeSaleCity === 0
                                ) {
                                    elt !== undefined && salesCity.push(elt);
                                }
                            });
                        }
                    });
                salesCity = [...new Set(salesCity)];
                for (let sale of rowDataSalesCity) {
                    salesCity = salesCity.filter(
                        (o) => o.designation !== sale.designation
                    );
                }
                setSalesModesCity(salesCity);
                const saleModesCompany: any[] = response.data.map(
                    (saleMode: any) => {
                        return {
                            saleSupports: saleMode.support_vente.map(
                                // eslint-disable-next-line array-callback-return
                                (el: any) => {
                                    if (el === "KIOSK")
                                        return {
                                            designation:
                                                saleMode["Mode of sale"],
                                            state: false,
                                        };
                                }
                            ),
                        };
                    }
                );
                let salesNamesCompany: any[] = [];
                let salesCompany: any[] = [];
                let rowDataSalesCompany: any[] = [];

                let salesSupportModeCompany: any[] = [];
                if (
                    rowData["Kitchen_ticket"]?.customer_information_mode_sale
                        ?.customerInformationModeSaleCompany !== undefined
                ) {
                    for (let element of rowData["Kitchen_ticket"]
                        ?.customer_information_mode_sale
                        ?.customerInformationModeSaleCompany) {
                        rowDataSalesCompany.push(element);
                    }
                }
                saleModesCompany !== undefined &&
                    saleModesCompany.forEach((el: any) => {
                        el.saleSupports.forEach((elt: any) => {
                            if (elt !== undefined) {
                                salesSupportModeCompany.push(elt.designation);
                                rowDataSalesCompany = rowDataSalesCompany.filter(
                                    (o) => o.designation !== elt.designation
                                );
                            }
                        });
                    });
                saleModesCompany !== undefined &&
                    saleModesCompany.forEach((el: any) => {
                        if (
                            rowData["Kitchen_ticket"]
                                ?.customer_information_mode_sale
                                ?.customerInformationModeSaleCompany !==
                            undefined
                        ) {
                            for (let element of rowData["Kitchen_ticket"]
                                ?.customer_information_mode_sale
                                ?.customerInformationModeSaleCompany) {
                                salesNamesCompany.push(element.designation);
                                salesCompany.push(element);
                            }
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    elt !== undefined &&
                                    !salesNamesCompany.includes(elt.designation)
                                ) {
                                    salesCompany.push(elt);
                                }
                            });
                        } else {
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    rowData["Kitchen_ticket"]
                                        ?.customer_information_mode_sale
                                        ?.customerInformationModeSaleCompany ===
                                        undefined ||
                                    rowData["Kitchen_ticket"]
                                        ?.customer_information_mode_sale
                                        ?.customerInformationModeSaleCompany ===
                                        0
                                ) {
                                    elt !== undefined && salesCompany.push(elt);
                                }
                            });
                        }
                    });
                salesCompany = [...new Set(salesCompany)];
                for (let sale of rowDataSalesCompany) {
                    salesCompany = salesCompany.filter(
                        (o) => o.designation !== sale.designation
                    );
                }
                setSalesModesCompany(salesCompany);
                const saleModesCode: any[] = response.data.map(
                    (saleMode: any) => {
                        return {
                            saleSupports: saleMode.support_vente.map(
                                // eslint-disable-next-line array-callback-return
                                (el: any) => {
                                    if (el === "KIOSK")
                                        return {
                                            designation:
                                                saleMode["Mode of sale"],
                                            state: false,
                                        };
                                }
                            ),
                        };
                    }
                );
                let salesNamesCode: any[] = [];
                let salesCode: any[] = [];
                let rowDataSalesCode: any[] = [];

                let salesSupportModeCode: any[] = [];
                if (
                    rowData["Kitchen_ticket"]?.customer_information_mode_sale
                        ?.customerInformationModeSaleCode !== undefined
                ) {
                    for (let element of rowData["Kitchen_ticket"]
                        ?.customer_information_mode_sale
                        ?.customerInformationModeSaleCode) {
                        rowDataSalesCode.push(element);
                    }
                }
                saleModesCompany !== undefined &&
                    saleModesCompany.forEach((el: any) => {
                        el.saleSupports.forEach((elt: any) => {
                            if (elt !== undefined) {
                                salesSupportModeCode.push(elt.designation);
                                rowDataSalesCode = rowDataSalesCode.filter(
                                    (o) => o.designation !== elt.designation
                                );
                            }
                        });
                    });
                saleModesCode !== undefined &&
                    saleModesCode.forEach((el: any) => {
                        if (
                            rowData["Kitchen_ticket"]
                                ?.customer_information_mode_sale
                                ?.customerInformationModeSaleCode !== undefined
                        ) {
                            for (let element of rowData["Kitchen_ticket"]
                                ?.customer_information_mode_sale
                                ?.customerInformationModeSaleCode) {
                                salesNamesCode.push(element.designation);
                                salesCode.push(element);
                            }
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    elt !== undefined &&
                                    !salesNamesCode.includes(elt.designation)
                                ) {
                                    salesCode.push(elt);
                                }
                            });
                        } else {
                            el.saleSupports.forEach((elt: any) => {
                                if (
                                    rowData["Kitchen_ticket"]
                                        ?.customer_information_mode_sale
                                        ?.customerInformationModeSaleCode ===
                                        undefined ||
                                    rowData["Kitchen_ticket"]
                                        ?.customer_information_mode_sale
                                        ?.customerInformationModeSaleCode === 0
                                ) {
                                    elt !== undefined && salesCode.push(elt);
                                }
                            });
                        }
                    });
                salesCode = [...new Set(salesCode)];
                for (let sale of rowDataSalesCode) {
                    salesCode = salesCode.filter(
                        (o) => o.designation !== sale.designation
                    );
                }
                setSalesModesCode(salesCode);
            });
    }, [franchiseID, shopId, userID, rowData]);
    useEffect(() => {
        fetchModeOfSale();
    }, [fetchModeOfSale]);
    useEffect(() => {
        setCustomerInformationModeSaleAdress(salesModesAdress);
        setCustomerInformationModeSaleCompany(salesModesCompany);
        setCustomerInformationModeSaleCode(salesModesCode);
        setCustomerInformationModeSaleCity(salesModesCity);
        setCustomerInformationModeSaleNumTel(salesModesNumTel);
        setCustomerInformationModeSaleName(salesModesNames);
    }, [
        salesModesAdress,
        salesModesCompany,
        salesModesCode,
        salesModesNames,
        salesModesCity,
        salesModesNumTel,
    ]);
    const handleStateSortedVisibilityChange = (item: any, key: number) => {
        let items = { ...item };
        items.state = !items.state;
        let newVisibility = [...customerInformationModeSaleName] as any;
        newVisibility = JSON.parse(JSON.stringify(newVisibility));
        newVisibility[key].state = items.state;
        setCustomerInformationModeSaleName(newVisibility);
    };
    const handleStateSortedVisibilityChangeNumTel = (
        item: any,
        key: number
    ) => {
        let items = { ...item };
        items.state = !items.state;
        let newVisibility = [...customerInformationModeSaleNumTel] as any;
        newVisibility = JSON.parse(JSON.stringify(newVisibility));
        newVisibility[key].state = items.state;
        setCustomerInformationModeSaleNumTel(newVisibility);
    };
    const handleStateSortedVisibilityChangeCity = (item: any, key: number) => {
        let items = { ...item };
        items.state = !items.state;
        let newVisibility = [...customerInformationModeSaleCity] as any;
        newVisibility = JSON.parse(JSON.stringify(newVisibility));
        newVisibility[key].state = items.state;
        setCustomerInformationModeSaleCity(newVisibility);
    };
    const handleStateSortedVisibilityChangeAdress = (
        item: any,
        key: number
    ) => {
        let items = { ...item };
        items.state = !items.state;
        let newVisibility = [...customerInformationModeSaleAdress] as any;
        newVisibility = JSON.parse(JSON.stringify(newVisibility));
        newVisibility[key].state = items.state;
        setCustomerInformationModeSaleAdress(newVisibility);
    };
    const handleStateSortedVisibilityChangeCompany = (
        item: any,
        key: number
    ) => {
        let items = { ...item };
        items.state = !items.state;
        let newVisibility = [...customerInformationModeSaleCompany] as any;
        newVisibility = JSON.parse(JSON.stringify(newVisibility));
        newVisibility[key].state = items.state;
        setCustomerInformationModeSaleCompany(newVisibility);
    };
    const handleStateSortedVisibilityChangeCode = (item: any, key: number) => {
        let items = { ...item };
        items.state = !items.state;
        let newVisibility = [...customerInformationModeSaleCode] as any;
        newVisibility = JSON.parse(JSON.stringify(newVisibility));
        newVisibility[key].state = items.state;
        setCustomerInformationModeSaleCode(newVisibility);
    };

    return (
        <div>
            <div className="d-flex  align-items-center d-flex flex-wrap p-2">
                <div>
                    <b>{t("Print customer information by mode of sale")}</b>
                </div>
            </div>
            <div className="d-flex  align-items-center flex-wrap ">
                <label className="p-2" style={{ width: "15%" }}>
                    {t("Name")}
                </label>
                {customerInformationModeSaleName.map(
                    (modeOfSale: any, key: any): JSX.Element => (
                        <div className="text-danger row b-2 align-items-center">
                            <div
                                className="d-flex col-4"
                                style={{ color: "#212529 " }}
                            >
                                <div className="px-1">
                                    <h6 style={{ width: "87px" }}>
                                        {modeOfSale.designation}
                                    </h6>
                                </div>
                                <div className="mr-2">
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={modeOfSale.state}
                                        onChange={() => {
                                            handleStateSortedVisibilityChange(
                                                modeOfSale,
                                                key
                                            );
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={55}
                                        height={20}
                                        className="fed_icn_FRmodeOfSales"
                                    />
                                </div>
                            </div>
                        </div>
                    )
                )}
            </div>

            <div className="d-flex  align-items-center flex-wrap ">
                <label className="p-2" style={{ width: "15%" }}>
                    {t("Phone number")}
                </label>
                {customerInformationModeSaleNumTel.map(
                    (modeOfSale: any, key: any): JSX.Element => (
                        <div className="text-danger row b-2 align-items-center">
                            <div
                                className="d-flex col-4"
                                style={{ color: "#212529 " }}
                            >
                                <div className="px-1">
                                    <h6 style={{ width: "87px" }}>
                                        {modeOfSale.designation}
                                    </h6>
                                </div>
                                <div className="mr-2">
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={modeOfSale.state}
                                        onChange={() => {
                                            handleStateSortedVisibilityChangeNumTel(
                                                modeOfSale,
                                                key
                                            );
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={55}
                                        height={20}
                                        className="fed_icn_FRmodeOfSales"
                                    />
                                </div>
                            </div>
                        </div>
                    )
                )}
            </div>
            <div className="d-flex  align-items-center flex-wrap ">
                <label className="p-2" style={{ width: "15%" }}>
                    {t("Company")}
                </label>
                {customerInformationModeSaleCity.map(
                    (modeOfSale: any, key: any): JSX.Element => (
                        <div className="text-danger row b-2 align-items-center">
                            <div
                                className="d-flex col-4"
                                style={{ color: "#212529 " }}
                            >
                                <div className="px-1">
                                    <h6 style={{ width: "87px" }}>
                                        {modeOfSale.designation}
                                    </h6>
                                </div>
                                <div className="mr-2">
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={modeOfSale.state}
                                        onChange={() => {
                                            handleStateSortedVisibilityChangeCity(
                                                modeOfSale,
                                                key
                                            );
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={55}
                                        height={20}
                                        className="fed_icn_FRmodeOfSales"
                                    />
                                </div>
                            </div>
                        </div>
                    )
                )}
            </div>
            <div className="d-flex  align-items-center flex-wrap ">
                <label className="p-2" style={{ width: "15%" }}>
                    {t("Address")}
                </label>
                {customerInformationModeSaleAdress.map(
                    (modeOfSale: any, key: any): JSX.Element => (
                        <div className="text-danger row b-2 align-items-center">
                            <div
                                className="d-flex col-4"
                                style={{ color: "#212529 " }}
                            >
                                <div className="px-1">
                                    <h6 style={{ width: "87px" }}>
                                        {modeOfSale.designation}
                                    </h6>
                                </div>
                                <div className="mr-2">
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={modeOfSale.state}
                                        onChange={() => {
                                            handleStateSortedVisibilityChangeAdress(
                                                modeOfSale,
                                                key
                                            );
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={55}
                                        height={20}
                                        className="fed_icn_FRmodeOfSales"
                                    />
                                </div>
                            </div>
                        </div>
                    )
                )}
            </div>
            <div className="d-flex  align-items-center flex-wrap ">
                <label className="p-2" style={{ width: "15%" }}>
                    {t("Postal code")}
                </label>
                {customerInformationModeSaleCode.map(
                    (modeOfSale: any, key: any): JSX.Element => (
                        <div className="text-danger row b-2 align-items-center">
                            <div
                                className="d-flex col-4"
                                style={{ color: "#212529 " }}
                            >
                                <div className="px-1">
                                    <h6 style={{ width: "87px" }}>
                                        {modeOfSale.designation}
                                    </h6>
                                </div>
                                <div className="mr-2">
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={modeOfSale.state}
                                        onChange={() => {
                                            handleStateSortedVisibilityChangeCode(
                                                modeOfSale,
                                                key
                                            );
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={55}
                                        height={20}
                                        className="fed_icn_FRmodeOfSales"
                                    />
                                </div>
                            </div>
                        </div>
                    )
                )}
            </div>
            <div className="d-flex  align-items-center flex-wrap ">
                <label className="p-2" style={{ width: "15%" }}>
                    {t("City")}
                </label>
                {customerInformationModeSaleCompany.map(
                    (modeOfSale: any, key: any): JSX.Element => (
                        <div className="text-danger row b-2 align-items-center">
                            <div
                                className="d-flex col-4"
                                style={{ color: "#212529 " }}
                            >
                                <div className="px-1">
                                    <h6 style={{ width: "87px" }}>
                                        {modeOfSale.designation}
                                    </h6>
                                </div>
                                <div className="mr-2">
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        checked={modeOfSale.state}
                                        onChange={() => {
                                            handleStateSortedVisibilityChangeCompany(
                                                modeOfSale,
                                                key
                                            );
                                        }}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={55}
                                        height={20}
                                        className="fed_icn_FRmodeOfSales"
                                    />
                                </div>
                            </div>
                        </div>
                    )
                )}
            </div>
        </div>
    );
}
