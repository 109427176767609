import { proxy } from "valtio";

type StoreType = {
    isAddExceptionalHoursClicked: boolean;
    numberActive: number;
    numberArchived: number;
    isPlusIconClicked: boolean;
    dataArchive: any[];
    exceptionalHoursFranchise: any[];
    archivedExceptionalHoursFranchise: any[];
    exceptionalHoursData: any[];
    shopsExceptionalHoursData: any[];
    numberMultiShopActive: number;
    numberMultiShopArchived: number;
    idsLocalShop: any[];
    shopRowData: any[];
    associatedExceptionalHours: any[];
    localShop: any[];
    archivedExceptionalHoursOfFranchise: any[];
    globalDataIsUpdated: boolean;
    isAssociateModalOpened: boolean;
    ids: number;
    selectedRows: any[];
    isAssociatedLangues: boolean;
    message: string;
    isAssociatedExceptionalHours: boolean;
    isArchived: boolean;
    activeExceptionalHours: any;
    isValidateArchiveOpened: boolean;
    isValidateShopArchiveOpened: boolean;
    isActionsClicked: boolean;
    isEdited: boolean;
    actions: Function;
    validationAction: any;
    modify: boolean;
};

export const store = proxy<StoreType>({
    isAddExceptionalHoursClicked: false,
    numberActive: 0,
    numberArchived: 0,
    isPlusIconClicked: false,
    dataArchive: [],
    exceptionalHoursFranchise: [],
    archivedExceptionalHoursFranchise: [],
    exceptionalHoursData: [],
    shopsExceptionalHoursData: [],
    numberMultiShopActive: 0,
    numberMultiShopArchived: 0,
    idsLocalShop: [],
    shopRowData: [],
    associatedExceptionalHours: [],
    localShop: [],
    archivedExceptionalHoursOfFranchise: [],
    globalDataIsUpdated: false,
    isAssociateModalOpened: false,
    ids: 0,
    selectedRows: [],
    isAssociatedLangues: false,
    message: "",
    isAssociatedExceptionalHours: false,
    isArchived: false,
    activeExceptionalHours: [],
    isValidateArchiveOpened: false,
    isValidateShopArchiveOpened: false,
    isActionsClicked: false,
    isEdited: false,
    actions: () => {},
    validationAction: {},
    modify: false,
});

export function setIsAddExceptionalHoursClicked(
    isAddExceptionalHoursClicked: boolean
): void {
    store.isAddExceptionalHoursClicked = isAddExceptionalHoursClicked;
}

export function setActions(actions: any): any {
    store.actions = actions;
}
export function setModify(value: boolean): any {
    store.modify = value;
}
export function setValidationAction(validationAction: any): any {
    store.validationAction = validationAction;
}

export function setNumberActive(numberActive: number): void {
    store.numberActive = numberActive;
}
export function setNumberArchived(numberArchived: number): void {
    store.numberArchived = numberArchived;
}
export function setIsPlusIconClicked(isPlusIconClicked: boolean): void {
    store.isPlusIconClicked = isPlusIconClicked;
}
export function setDataArchive(dataArchive: any[]): void {
    store.dataArchive = dataArchive;
}
export function setExceptionalHoursFranchise(
    exceptionalHoursFranchise: any[]
): void {
    store.exceptionalHoursFranchise = exceptionalHoursFranchise;
}
export function setArchivedExceptionalHoursFranchise(
    archivedExceptionalHoursFranchise: any[]
): void {
    store.archivedExceptionalHoursFranchise = archivedExceptionalHoursFranchise;
}
export function setExceptionalHoursData(exceptionalHoursData: any[]): void {
    store.exceptionalHoursData = exceptionalHoursData;
}
export function setShopsExceptionalHoursData(
    shopsExceptionalHoursData: any[]
): void {
    store.shopsExceptionalHoursData = shopsExceptionalHoursData;
}
export function setNumberMultiShopActive(number: number): void {
    store.numberMultiShopActive = number;
}
export function setNumberMultiShopArchived(number: number): void {
    store.numberMultiShopArchived = number;
}
export function setIdsLocalShop(ids: any[]): void {
    store.idsLocalShop = ids;
}
export function setLocalShop(shops: any[]): void {
    store.localShop = shops;
}
export function setShopRowData(shopRowData: any[]): void {
    store.shopRowData = shopRowData;
}
export function setAssociatedExceptionalHours(
    associatedExceptionalHours: any[]
): void {
    store.associatedExceptionalHours = associatedExceptionalHours;
}
export function setArchivedExceptionalHoursOfFranchise(
    archivedExceptionalHoursOfFranchise: any[]
): void {
    store.archivedExceptionalHoursOfFranchise = archivedExceptionalHoursOfFranchise;
}
export function setGlobalDataIsUpdated(globalDataIsUpdated: boolean): void {
    store.globalDataIsUpdated = globalDataIsUpdated;
}
export function setIsAssociateModalOpened(
    isAssociateModalOpened: boolean
): void {
    store.isAssociateModalOpened = isAssociateModalOpened;
}
export function setIds(id: number): void {
    store.ids = id;
}
export function setSelectedRow(selectedRows: any[]): void {
    store.selectedRows = selectedRows;
}
export function setIsAssociatedLangues(isAssociatedLangues: boolean): void {
    store.isAssociatedLangues = isAssociatedLangues;
}
export function setMessage(message: string): void {
    store.message = message;
}
export function setIsAssociatedExceptionalHours(
    isAssociatedExceptionalHours: boolean
): void {
    store.isAssociatedExceptionalHours = isAssociatedExceptionalHours;
}
export function setIsArchived(isArchived: boolean): void {
    store.isArchived = isArchived;
}
export function setActiveExceptionalHours(
    activeExceptionalHours: boolean
): void {
    store.activeExceptionalHours = activeExceptionalHours;
}
export function setIsValidateArchiveOpened(isValidateArchiveOpened: any): void {
    store.isValidateArchiveOpened = isValidateArchiveOpened;
}
export function setIsValidateShopArchiveOpened(
    isValidateShopArchiveOpened: any
): void {
    store.isValidateShopArchiveOpened = isValidateShopArchiveOpened;
}

export function setIsEdited(isEdited: boolean): any {
    store.isEdited = isEdited;
}

export function setIsActionsClicked(isActionsClicked: boolean): any {
    store.isActionsClicked = isActionsClicked;
}
export default store;
