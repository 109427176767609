import React from "react";
import { Input } from "reactstrap";
import { useSnapshot } from "valtio";

import {
    computedStore,
    designStore,
    setFooterFinalMessage,
    setFooterFinalMessageItems,
} from "@store";
export function FinalMessageRow(): JSX.Element {
    const { selectedLanguage, footerFinalMessage, activeSlideIndex } =
        useSnapshot(designStore);

    const { elements } = useSnapshot(computedStore);

    function handleFooterFinalMessageItemInputOnChangeEvent(content: string) {
        const localItems: HeaderLanguageItemsType = {
            ...footerFinalMessage,
            [selectedLanguage]: {
                defaultContent:
                    footerFinalMessage[selectedLanguage].defaultContent,
                content: content,
            },
        };
        setFooterFinalMessage(localItems);
        setFooterFinalMessageItems(localItems, elements[activeSlideIndex].name);
    }

    return (
        <tr>
            <td className="cursor__clz" style={{ width: "35%" }}>
                {footerFinalMessage[selectedLanguage].defaultContent !==
                undefined
                    ? footerFinalMessage[selectedLanguage].defaultContent
                    : null}
            </td>
            <td>
                <Input
                    id="card-title"
                    name="card-title"
                    type="text"
                    value={
                        footerFinalMessage[selectedLanguage].content !==
                        undefined
                            ? footerFinalMessage[selectedLanguage].content
                            : "null"
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        return handleFooterFinalMessageItemInputOnChangeEvent(
                            event.target.value
                        );
                    }}
                />
            </td>
        </tr>
    );
}
