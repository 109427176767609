import React from "react";
import { useSnapshot } from "valtio";
import { Swiper, SwiperSlide } from "swiper/react";
import classNames from "classnames";
import { Navigation } from "swiper";
import { designStore } from "@store";
import {
    AngleSmallLeftIcon,
    AngleSmallRightIcon,
    StyledIconButton,
} from "@aureskonnect/react-ui";

import "./index.css";
import "swiper/swiper-bundle.min.css";

export function LanguagesWrapper(): JSX.Element {
    const { activeLanguages } = useSnapshot(designStore);

    const [languagesWithPairIndex, setLanguagesWithPairIndex] = React.useState<
        LanguagesItemType[]
    >([]);
    const [
        languagesWithImpairIndex,
        setLanguagesWithImpairIndex,
    ] = React.useState<LanguagesItemType[]>([]);

    const navigationPrevRef = React.useRef(null);
    const navigationNextRef = React.useRef(null);

    React.useEffect(() => {
        if (activeLanguages.length > 6) {
            const localLanguagesWithPairIndex: LanguagesItemType[] = [];
            const localLanguagesWithImpairIndex: LanguagesItemType[] = [];

            activeLanguages.forEach(
                (language: LanguagesItemType, index: number) => {
                    if (index % 2 === 0) {
                        localLanguagesWithPairIndex.push(language);
                    } else {
                        localLanguagesWithImpairIndex.push(language);
                    }
                }
            );

            setLanguagesWithPairIndex(localLanguagesWithPairIndex);
            setLanguagesWithImpairIndex(localLanguagesWithImpairIndex);
        }
    }, [activeLanguages]);

    return (
        <React.Fragment>
            {activeLanguages.length <= 2 ? (
                <div
                    className="d-flex justify-content-center align-items-center mx-3"
                    style={{
                        gap: "20px",
                    }}
                >
                    {activeLanguages.map(
                        (item: LanguagesItemType, index: number) => (
                            <div
                                key={index}
                                className="d-flex cursor__clz"
                                style={{
                                    placeSelf: "center",
                                    placeItems: "center",
                                    flexDirection: "row",
                                    gap: "10px",
                                }}
                            >
                                <img
                                    src={item.flag}
                                    alt={item.title}
                                    style={{ width: "44px" }}
                                />
                                <span
                                    className={classNames({
                                        "title-language__clz": index === 0,
                                    })}
                                >
                                    {item.title}
                                </span>
                            </div>
                        )
                    )}
                </div>
            ) : activeLanguages.length === 3 ? (
                <div
                    className="mx-3"
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                        gap: "10px",
                    }}
                >
                    {activeLanguages.map(
                        (item: LanguagesItemType, index: number) => (
                            <div
                                key={index}
                                className="d-flex"
                                style={{
                                    placeSelf: "center",
                                    placeItems: "center",
                                    flexDirection: "row",
                                    gap: "10px",
                                }}
                            >
                                <img
                                    src={item.flag}
                                    alt={item.title}
                                    style={{ width: "44px" }}
                                />
                                <span
                                    className={classNames({
                                        "title-language__clz": index === 0,
                                    })}
                                >
                                    {item.title}
                                </span>
                            </div>
                        )
                    )}
                </div>
            ) : activeLanguages.length > 3 && activeLanguages.length <= 6 ? (
                <div
                    className="mx-3"
                    style={{
                        display: "grid",
                        gridTemplateRows: "1fr 1fr",
                        gap: "10px",
                    }}
                >
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr 1fr",
                            gap: "10px",
                        }}
                    >
                        {activeLanguages
                            .slice(0, 3)
                            .map((item: LanguagesItemType, index: number) => (
                                <div
                                    key={index}
                                    className="d-flex justify-content-start align-items-center"
                                    style={{ gap: "10px" }}
                                >
                                    <img
                                        src={item.flag}
                                        alt={item.title}
                                        style={{ width: "33px" }}
                                    />
                                    <span
                                        className={classNames({
                                            "title-language__clz": index === 0,
                                        })}
                                    >
                                        {item.title}
                                    </span>
                                </div>
                            ))}
                    </div>
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: `repeat(${
                                activeLanguages.length - 3
                            }, 1fr)`,
                            placeSelf:
                                activeLanguages.length < 6 ? "center" : "unset",
                            gap: "10px",
                        }}
                    >
                        {activeLanguages
                            .slice(3)
                            .map((item: LanguagesItemType, index: number) => (
                                <div
                                    key={index}
                                    className="d-flex justify-content-start align-items-center"
                                    style={{ gap: "10px" }}
                                >
                                    <img
                                        src={item.flag}
                                        alt={item.title}
                                        style={{ width: "33px" }}
                                    />
                                    <span>{item.title}</span>
                                </div>
                            ))}
                    </div>
                </div>
            ) : (
                <React.Fragment>
                    <StyledIconButton
                        ref={navigationPrevRef}
                        className="m-0 p-0 swiper-button__clz"
                    >
                        <AngleSmallLeftIcon height={70} width={40} />
                    </StyledIconButton>
                    <Swiper
                        modules={[Navigation]}
                        navigation={{
                            prevEl: navigationPrevRef.current,
                            nextEl: navigationNextRef.current,
                        }}
                        spaceBetween={50}
                        slidesPerView={3}
                        className="kiosk-languages-swiper-container__clz"
                        style={{ zIndex: 0 }}
                    >
                        {languagesWithPairIndex.map((item, index) => {
                            return (
                                <SwiperSlide
                                    key={index}
                                    className="d-flex flex-column justify-content-center language-swiper-slide__clz language-swiper-slide-img__clz"
                                    style={{ gap: "10px" }}
                                >
                                    <div
                                        className="d-flex align-items-center"
                                        style={{
                                            gap: "10px",
                                        }}
                                    >
                                        <img
                                            alt="img"
                                            src={item.flag}
                                            style={{ width: "44px" }}
                                        />
                                        <span
                                            className={classNames({
                                                "title-language__clz":
                                                    index === 0,
                                            })}
                                        >
                                            {item.title}
                                        </span>
                                    </div>

                                    {languagesWithImpairIndex[index] !==
                                    undefined ? (
                                        <div
                                            className="d-flex align-items-center"
                                            style={{
                                                gap: "10px",
                                            }}
                                        >
                                            <img
                                                src={
                                                    languagesWithImpairIndex[
                                                        index
                                                    ].flag
                                                }
                                                alt={
                                                    languagesWithImpairIndex[
                                                        index
                                                    ] !== undefined
                                                        ? `./images/${languagesWithImpairIndex[index].title}`
                                                        : ""
                                                }
                                                style={{ width: "44px" }}
                                            />
                                            <span>
                                                {
                                                    languagesWithImpairIndex[
                                                        index
                                                    ].title
                                                }
                                            </span>
                                        </div>
                                    ) : null}
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                    <StyledIconButton
                        ref={navigationNextRef}
                        className="m-0 p-0 swiper-button__clz"
                    >
                        <AngleSmallRightIcon height={70} width={40} />
                    </StyledIconButton>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
