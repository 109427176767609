import { proxy } from "valtio";

type StoreType = {
    isAddChoiceClicked: boolean;
    numberArchived: number;
    archivedData: any;
    numberActive: number;
    shopsNumberActive: number;
    shopsNumberArchived: number;
    isAssociateModalOpened: boolean;
    isDataUpdated: any;
    dataArchive: any[];
    globalDataIsUpdated: boolean | number;
    isArchiveModalOpened: boolean;
    isHistoryModalOpened: boolean;
    isHistoryShopsModalOpened: boolean;
    activePaymentMethods: any[];
    selectedPaymentMethods: any[];
    selectedHistoryPaymentMethods: any;
    shopsPaymentMethodsData: any[];
    paymentMethodsData: any[];
    shopsSelectedRow: any;
    isInlineErrorCleared: boolean;
    isInputUpdated: boolean;
    localShop: any;
    ids: number;
    idsLocalShop: string[];
    selectedRows: any[];
    associatedPaymentMethods: any[];
    listFranchiseArchived: any[];
    salesSupports: any;
    isActionsClicked: boolean;
    isEdited: boolean;
    actions: Function;
    validationAction: any;
    modify: boolean;
};

export const store = proxy<StoreType>({
    isAddChoiceClicked: false,
    associatedPaymentMethods: [],
    numberArchived: 0,
    numberActive: 0,
    ids: 0,
    selectedRows: [],
    idsLocalShop: [],
    shopsNumberArchived: 0,
    shopsNumberActive: 0,
    isAssociateModalOpened: false,
    globalDataIsUpdated: false,
    archivedData: {},
    isArchiveModalOpened: false,
    isInputUpdated: false,
    isHistoryModalOpened: false,
    isHistoryShopsModalOpened: false,
    activePaymentMethods: [],
    selectedPaymentMethods: [],
    selectedHistoryPaymentMethods: {},
    shopsPaymentMethodsData: [],
    paymentMethodsData: [],
    shopsSelectedRow: [],
    isDataUpdated: false,
    isInlineErrorCleared: false,
    localShop: [],
    dataArchive: [],
    listFranchiseArchived: [],
    salesSupports: [],
    isActionsClicked: false,
    isEdited: false,
    actions: () => {},
    validationAction: {},
    modify: false,
});
export function setModify(value: boolean): void {
    store.modify = value;
}

export function setActions(actions: any): any {
    store.actions = actions;
}

export function setValidationAction(validationAction: any): any {
    store.validationAction = validationAction;
}

export function setPaymentMethodsData(paymentMethodsData: any): any {
    store.paymentMethodsData = paymentMethodsData;
}

export function setSalesSupports(salesSupports: any): any {
    store.salesSupports = salesSupports;
}
export function setIdsLocalShop(ids: any[]): void {
    store.idsLocalShop = ids;
}

export function setListFranchiseArchived(listFranchiseArchived: any[]): void {
    store.listFranchiseArchived = listFranchiseArchived;
}

export function setAssociatedPaymentMethods(
    associatedPaymentMethods: any
): void {
    store.associatedPaymentMethods = associatedPaymentMethods;
}
export function setLocalShop(shops: any[]): void {
    store.localShop = shops;
}

export function setIsInlineErrorCleared(isInlineErrorCleared: any): any {
    store.isInlineErrorCleared = isInlineErrorCleared;
}

export function setIsDataUpdated(isDataUpdated: boolean): any {
    store.isDataUpdated = isDataUpdated;
}

export function setIsInputUpdated(isInputUpdated: boolean): any {
    store.isInputUpdated = isInputUpdated;
}

export function setIds(id: number): void {
    store.ids = id;
}

export function setSelectedRow(selectedRows: any[]): void {
    store.selectedRows = selectedRows;
}

export function setShopsSelectedRow(shopSelectedRow: any): any {
    store.shopsSelectedRow = shopSelectedRow;
}
export function setShopsPaymentMethodsData(shopsPaymentMethodsData: any): any {
    store.shopsPaymentMethodsData = shopsPaymentMethodsData;
}

export function setIsHistoryModalOpened(isHistoryModalOpened: boolean): any {
    store.isHistoryModalOpened = isHistoryModalOpened;
}

export function setSelectedHistoryPaymentMethods(
    selectedHistoryPaymentMethods: any[]
): any {
    store.selectedHistoryPaymentMethods = selectedHistoryPaymentMethods;
}
export function setSelectedPaymentMethods(selectedPaymentMethod: any[]): any {
    store.selectedPaymentMethods = selectedPaymentMethod;
}

export function setActivePaymentMethods(activePaymentMethod: any): any {
    let localIndex: number = store.activePaymentMethods.findIndex((e: any) => {
        return e.id === activePaymentMethod.id;
    });
    if (localIndex === -1 || localIndex === undefined) {
        store.activePaymentMethods.push(activePaymentMethod);
    } else {
        store.activePaymentMethods[localIndex] = activePaymentMethod;
    }
}

export function setIsHistoryShopsModalOpened(
    isHistoryShopsModalOpened: boolean
): any {
    store.isHistoryShopsModalOpened = isHistoryShopsModalOpened;
}

export function setIsAddChoiceClicked(isAddChoiceClicked: boolean): any {
    store.isAddChoiceClicked = isAddChoiceClicked;
}

export function setArchivedData(archivedData: boolean): any {
    store.archivedData = archivedData;
}

export function setIsArchiveModalOpened(isArchiveModalOpened: boolean): any {
    store.isArchiveModalOpened = isArchiveModalOpened;
}

export function setGlobalDataIsUpdated(globalDataIsUpdated: boolean): void {
    store.globalDataIsUpdated = globalDataIsUpdated;
}

export function setNumberArchive(number: number): void {
    store.numberArchived = number;
}

export function setNumberActive(number: number): void {
    store.numberActive = number;
}

export function setShopsNumberArchived(number: number): void {
    store.shopsNumberArchived = number;
}

export function setShopsNumberActive(number: number): void {
    store.shopsNumberActive = number;
}

export function setIsAssociateModalOpened(isModalOpened: boolean): void {
    store.isAssociateModalOpened = isModalOpened;
}

export function setDataArchive(dataArchive: any[]): void {
    store.dataArchive = dataArchive;
}
export function setIsEdited(isEdited: boolean): any {
    store.isEdited = isEdited;
}

export function setIsActionsClicked(isActionsClicked: boolean): any {
    store.isActionsClicked = isActionsClicked;
}
export default store;
