import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { SettingsIcon } from "@aureskonnect/react-ui";
import { Tooltip } from "@mui/material";
import TimePastSvgIcon from "@components/Common/SvgIcons/TimePastSvgIcon";

import {
    store as hoursStore,
    setIsHistoryModalOpened,
    setSelectedHours,
} from "../../store";

export function CustomIcons({
    selectedRowData,
    setIsConfigureHoursClicked,
    setDay,
    setSelectedRows,
}: any) {
    const { t } = useTranslation();
    const { isHistoryModalOpened, modify } = useSnapshot(hoursStore);
    return (
        <React.Fragment>
            {selectedRowData?.selectedRow[t("Day of week")] !== "" ? (
                <div className="d-flex justify-content-around w-50">
                    {modify === true ? (
                        <>
                            <Tooltip title={t("Configure")}>
                                <div>
                                    <SettingsIcon
                                        className="pointer__clz	hov_icn_FRsetting "
                                        onClick={() => {
                                            setSelectedRows([]);
                                            setIsConfigureHoursClicked(true);
                                            setDay({
                                                day:
                                                    selectedRowData.selectedRow[
                                                        "Id"
                                                    ],
                                                designation:
                                                    selectedRowData.selectedRow[
                                                        t("Day of week")
                                                    ],
                                                stateDay:
                                                    selectedRowData.selectedRow[
                                                        t("State")
                                                    ],
                                                hoursNumber:
                                                    selectedRowData.selectedRow[
                                                        t("Total hours")
                                                    ],
                                                settingDay:
                                                    selectedRowData.selectedRow
                                                        .subRows.length > 0
                                                        ? selectedRowData.selectedRow.subRows.map(
                                                              (elt: any) => {
                                                                  return {
                                                                      openingHour:
                                                                          elt.openingHour,
                                                                      closingHour:
                                                                          elt.closingHour,
                                                                      stateHour:
                                                                          elt.stateHour,
                                                                      isArchived:
                                                                          elt.isArchived,
                                                                      overLappedStart:
                                                                          elt.overLappedStart,
                                                                      overLappedEnd:
                                                                          elt.overLappedEnd,
                                                                      inputStartTime:
                                                                          elt.inputStartTime,
                                                                      inputEndTime:
                                                                          elt.inputEndTime,
                                                                      hoursNumber:
                                                                          elt.hoursNumber,
                                                                  };
                                                              }
                                                          )
                                                        : [
                                                              {
                                                                  openingHour:
                                                                      "",
                                                                  closingHour:
                                                                      "",
                                                                  stateHour: true,
                                                                  isArchived: false,
                                                                  overLappedStart: false,
                                                                  overLappedEnd: false,
                                                                  inputStartTime: false,
                                                                  inputEndTime: false,
                                                                  hoursNumber: 0,
                                                              },
                                                          ],
                                            });
                                        }}
                                        height={25}
                                        width={25}
                                    />
                                </div>
                            </Tooltip>
                        </>
                    ) : null}
                    <Tooltip title={t("Consult history")}>
                        <div>
                            <TimePastSvgIcon
                                className="pointer__clz  hov_icn_FRhistory"
                                onClick={() => {
                                    setSelectedHours([
                                        {
                                            [selectedRowData.selectedRow
                                                .shopId]:
                                                selectedRowData.selectedRow.Id,
                                        },
                                    ]);
                                    setSelectedRows([
                                        selectedRowData.selectedRow,
                                    ]);
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );
                                }}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
        </React.Fragment>
    );
}
