import React from "react";
import { useSnapshot } from "valtio";
import classNames from "classnames";

import { designStore } from "@store";

import { ContainerWrapper } from "@components/Common/Design/Preview/LoyaltyWrapper/ContainerWrapper";
import { TopBannerWrapper } from "@components/Common/Design/Preview/TopBannerWrapper";
import { ActionsButtonsWrapper } from "@components/Common/Design/Preview/ActionsButtonsWrapper";
import { HeaderWrapper } from "@components/Common/Design/Preview/LoyaltyWrapper/HeaderWrapper";
import { MainContentWrapper } from "@components/Common/Design/Preview/MainContentWrapper";

import { PreviewBackgroundWrapper } from "../PreviewBackgroundWrapper";

export function LoyaltyWrapper(): JSX.Element {
    const {
        isTopBannerActive,
        isHeaderActive,
        isActionButtonsActive,
        activeTabIndex,
    } = useSnapshot(designStore);

    return (
        <PreviewBackgroundWrapper>
            <ContainerWrapper
                isHeaderActive={isHeaderActive}
                isTopBannerActive={isTopBannerActive}
                isActionButtonsActive={isActionButtonsActive}
            >
                {isTopBannerActive ? <TopBannerWrapper /> : null}
                {isHeaderActive ? <HeaderWrapper /> : null}
                <div
                    className={classNames(
                        "d-flex justify-content-center align-items-center",
                        {
                            "border-wrapper__clz": activeTabIndex === 2,
                        }
                    )}
                    style={{
                        width: "429px",
                        marginLeft: "1px",
                    }}
                >
                    <MainContentWrapper />
                </div>
                <ActionsButtonsWrapper />
            </ContainerWrapper>
        </PreviewBackgroundWrapper>
    );
}
