import React from "react";
export function CategoriesIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={107}
            height={107}
            {...props}
        >
            {" "}
            <defs>
                {" "}
                <linearGradient
                    id="a"
                    x1={0.5}
                    x2={0.5}
                    y2={1}
                    gradientUnits="objectBoundingBox"
                >
                    {" "}
                    <stop offset={0} stopColor="#3a3a3a" />{" "}
                    <stop offset={1} stopColor="#5b5b5b" />{" "}
                </linearGradient>{" "}
            </defs>{" "}
            <g transform="translate(0 -.373)">
                {" "}
                <circle
                    cx={53.5}
                    cy={53.5}
                    r={53.5}
                    transform="translate(0 .373)"
                    fill={props.stopColor}
                />{" "}
                <path
                    d="M89.503 32.148 53.829 15.364a1.179 1.179 0 0 0-1 0L17.154 32.148a1.119 1.119 0 0 0-.651 1.01v40.76a1.12 1.12 0 0 0 .651 1.01l35.674 16.784a1.178 1.178 0 0 0 1 0l35.675-16.784a1.12 1.12 0 0 0 .65-1.01V33.153a1.12 1.12 0 0 0-.65-1.005ZM53.328 17.62l33.027 15.533-9.574 4.5a1.146 1.146 0 0 0-.195-.12L43.784 22.11Zm-12.144 5.758 32.979 15.516-6.754 3.178-32.965-15.51Zm33.75 17.643v11.747l-6.309 2.968V43.99Zm12.917 32.193-33.372 15.7V50.645l7.96-3.745a1.109 1.109 0 0 0 .536-1.5 1.164 1.164 0 0 0-1.537-.522l-8.11 3.816-3.191-1.5a1.164 1.164 0 0 0-1.537.522 1.109 1.109 0 0 0 .536 1.5l3.041 1.431v38.267l-33.372-15.7V34.945l25.413 11.956a1.17 1.17 0 0 0 .5.112 1.154 1.154 0 0 0 1.037-.634 1.109 1.109 0 0 0-.536-1.5L20.301 33.153l11.433-5.379 34.574 16.27.016.02v13.46a1.116 1.116 0 0 0 .539.95 1.177 1.177 0 0 0 1.112.06l8.611-4.051a1.119 1.119 0 0 0 .651-1.01V39.939l10.614-4.994v38.269Z"
                    fill={props.fill}
                />{" "}
                <path
                    d="m28.303 68.84-5.235-2.524a1.151 1.151 0 1 0-1 2.073l5.235 2.527a1.151 1.151 0 0 0 1-2.073ZM33.121 65.814l-10.046-4.85a1.151 1.151 0 1 0-1 2.073l10.046 4.85a1.151 1.151 0 0 0 1-2.073Z"
                    fill={props.fill}
                />{" "}
            </g>{" "}
        </svg>
    );
}
