import React from "react";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { useTranslation } from "react-i18next/";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { CheckboxIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";

import ErrorToast from "@components/Common/ErrorTost";

import { store as FiscalYear } from "../../../../components/VerticalLayout/store";
import store, { setIsActionsClicked, setIsEdited, setActions } from "../store";

import { ConfirmationArchive } from "../ConfirmationArchive";

export function ActionColumn({
    setIsHistoryModalOpened,
    isHistoryModalOpened,
    selectedRows,
    setDataIsUpdated,
    setIsModalOpened,
    isModalOpened,
    setIsFiscalYearArchived,
    isFiscalYearArchived,
    setIsAddFiscalYearClicked,
}: any) {
    const { t } = useTranslation();
    const {
        numberArchived,
        numberActive,
        shopsFiscalYearData,
        archivedFiscalYearFranchise,
        isActionsClicked,
        isEdited,
        modify,
    } = useSnapshot(store);
    const { franchiseID, oneShop, userID, operatorID } = useSnapshot(
        FiscalYear
    );
    const [error, setError] = React.useState(false);
    const [isArchiveModalOpened, setIsArchiveModalOpened] = React.useState<
        boolean
    >(false);
    let dataAssociate: any[] = [];
    let dataArchive: any[] = [];

    if (!oneShop) {
        dataAssociate = associateFiscalYear();
        dataArchive = archiveFiscalYear();
    } else {
        dataArchive = archiveFiscalYearFranchise();
    }

    function archiveFiscalYear() {
        let idsAssociated: any = [];
        if (shopsFiscalYearData.data !== undefined) {
            shopsFiscalYearData.data.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((el: any) => {
                        idsAssociated.push(el.Id);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function archiveFiscalYearFranchise() {
        let idsAssociated: any = [];
        if (
            archivedFiscalYearFranchise.othersData
                .archivedFiscalYearFranchise !== undefined
        ) {
            archivedFiscalYearFranchise.othersData.archivedFiscalYearFranchise.forEach(
                (element: any) => {
                    idsAssociated.push(element.Id);
                }
            );
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function associateFiscalYear() {
        let idsAssociated: any[] = [];
        if (shopsFiscalYearData.data !== undefined) {
            shopsFiscalYearData.data.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((el: any) => {
                        idsAssociated.push(el.Id);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function isFiscalYearExist() {
        let isExist = false;
        selectedRows.forEach((el: any) => {
            if (dataAssociate.includes(el.Id)) {
                isExist = true;
            }
        });
        if (isExist) {
            ErrorToast(
                `${t(
                    "Please note that you cannot archive an item assigned to stores."
                )!}`
            );
        }
        !isExist && setIsArchiveModalOpened(true);
    }

    async function activeFiscalYear() {
        toast.dismiss();
        let localData: any = [];
        selectedRows.forEach(async (el: any) => {
            let dataFiscalYear = {
                userId: userID,
                shopId: [el.shopId],
                franchiseId: franchiseID,
                operatorId: operatorID,
                isShop: false,
                data: {
                    [el.Id]: {
                        first_day: el.first_day,
                        first_month: el.first_month,
                        end_day: el.end_day,
                        end_month: el.end_month,
                        isArchived: false,
                        shopId: el.shopId,
                    },
                },
            };

            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/fiscal_year`;
            localData.push(
                fetch(apiUrlAdd, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(dataFiscalYear),
                }).then((response) => response.json())
            );
        });
        try {
            mutate(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/fiscal_year`,
                await Promise.all(localData).then((result) => {})
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setError(true);
        }

        if (error === false) {
            toast.success(
                `${t("The fiscal year has been activated successfully")!}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
            setDataIsUpdated!(true);
        }
    }

    return (
        <React.Fragment>
            <div>
                <div className="dropdown">
                    <VerticalDotsIcon
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        style={{ cursor: "pointer" }}
                        height={25}
                        width={25}
                        fill="black"
                        className="cmn_drp_FRmenu"
                    />
                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <div
                            className="dropdown-item pl-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setIsFiscalYearArchived(false);
                            }}
                        >
                            <span className="ml-2 cmn_btn_FRlistActivated">
                                {t("Fiscal Year Enabled")} ({numberActive})
                            </span>
                        </div>
                        <div
                            className="dropdown-item pl-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setIsFiscalYearArchived(true);
                                setIsAddFiscalYearClicked(false);
                            }}
                        >
                            <span className="ml-2 cmn_btn_FRlistArchived">
                                {t("Archived fiscal year")}({numberArchived})
                            </span>
                        </div>
                        <div
                            style={{ cursor: "pointer" }}
                            className={`${classnames(
                                " dropdown-item pl-1 pointer__clz",
                                {
                                    "not-allowed-icon__clz":
                                        selectedRows.length === 0,
                                }
                            )}`}
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);
                                        if (selectedRows.length === 1) {
                                            ErrorToast(
                                                `${t(
                                                    "Attention, it is a multiple action. Please select at least two items"
                                                )!}`
                                            );
                                        } else {
                                            setIsHistoryModalOpened(
                                                !isHistoryModalOpened
                                            );
                                        }
                                    });
                                } else {
                                    if (selectedRows.length === 1) {
                                        ErrorToast(
                                            `${t(
                                                "Attention, it is a multiple action. Please select at least two items"
                                            )!}`
                                        );
                                    } else {
                                        setIsHistoryModalOpened(
                                            !isHistoryModalOpened
                                        );
                                    }
                                }
                            }}
                        >
                            <span className="ml-2 cmn_btn_FRhistory">
                                {t("Historical")}
                            </span>
                        </div>
                        {modify === true ? (
                            <>
                                <div
                                    style={{ cursor: "pointer" }}
                                    className={`${classnames(
                                        " dropdown-item pl-1 pointer__clz",
                                        {
                                            "not-allowed-icon__clz":
                                                selectedRows.length === 0 ||
                                                (isFiscalYearArchived &&
                                                    oneShop &&
                                                    selectedRows.some(
                                                        (element: any) =>
                                                            dataArchive.includes(
                                                                element.Id
                                                            )
                                                    )),
                                        }
                                    )}`}
                                    onClick={() => {
                                        if (isEdited === true) {
                                            setIsActionsClicked(
                                                !isActionsClicked
                                            );
                                            setActions(() => {
                                                setIsAddFiscalYearClicked(
                                                    false
                                                );
                                                setIsEdited(false);
                                                if (selectedRows.length === 1) {
                                                    ErrorToast(
                                                        `${t(
                                                            "Attention, it is a multiple action. Please select at least two items"
                                                        )!}`
                                                    );
                                                } else if (
                                                    selectedRows.length > 1 &&
                                                    oneShop &&
                                                    isFiscalYearArchived
                                                ) {
                                                    ErrorToast(
                                                        t(
                                                            "Attention, a shop cannot have more than one fiscal year"
                                                        )
                                                    );
                                                } else {
                                                    if (isFiscalYearArchived) {
                                                        if (
                                                            selectedRows.some(
                                                                (
                                                                    element: any
                                                                ) =>
                                                                    dataArchive.includes(
                                                                        element.Id
                                                                    )
                                                            ) &&
                                                            !oneShop
                                                        ) {
                                                            setIsModalOpened(
                                                                !isModalOpened
                                                            );
                                                            activeFiscalYear();
                                                        } else {
                                                            activeFiscalYear();
                                                        }
                                                    } else {
                                                        isFiscalYearExist();
                                                    }
                                                }
                                            });
                                        } else {
                                            if (selectedRows.length === 1) {
                                                ErrorToast(
                                                    `${t(
                                                        "Attention, it is a multiple action. Please select at least two items"
                                                    )!}`
                                                );
                                            } else if (
                                                selectedRows.length > 1 &&
                                                oneShop &&
                                                isFiscalYearArchived
                                            ) {
                                                ErrorToast(
                                                    t(
                                                        "Attention, a shop cannot have more than one fiscal year"
                                                    )
                                                );
                                            } else {
                                                if (isFiscalYearArchived) {
                                                    if (
                                                        selectedRows.some(
                                                            (element: any) =>
                                                                dataArchive.includes(
                                                                    element.Id
                                                                )
                                                        ) &&
                                                        !oneShop
                                                    ) {
                                                        setIsModalOpened(
                                                            !isModalOpened
                                                        );
                                                        activeFiscalYear();
                                                    } else {
                                                        activeFiscalYear();
                                                    }
                                                } else {
                                                    isFiscalYearExist();
                                                }
                                            }
                                        }
                                    }}
                                >
                                    <span className="ml-2 cmn_btn_FRarchived cmn_btn_FRunarchive">
                                        {isFiscalYearArchived
                                            ? t("unarchive")
                                            : t("archive")}
                                    </span>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
            {isArchiveModalOpened ? (
                <ConfirmationArchive
                    isModalOpened={isArchiveModalOpened}
                    setIsModalOpened={setIsArchiveModalOpened}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                />
            ) : null}
        </React.Fragment>
    );
}
