import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { CheckboxIcon, StyledModal } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import ErrorToast from "@components/Common/ErrorTost";

import { store } from "@components/VerticalLayout/store";
import {
    store as generalSettingStore,
    setIsAssociateModalOpened,
    setIsAssociateModalClosed,
    setNumberActiveMultiShop,
    setIsAssociatedFiscalYear,
    setSelectedRow,
    setIds,
    setMessage,
    setFiscalYear,
} from "../store";
import UniqueRowSelection from "./UniqueRowSelection";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

export function FiscalYearAssociateModal({ setData, setLimit }: any) {
    const { t } = useTranslation();
    const {
        isAssociateModalOpened,
        localShop,
        isAssociateModalClosed,
        numberActiveMultiShop,
        idsLocalShop,
        rowsShop,
        selectedRowsFiscalYear,
        fiscalYear,
    } = useSnapshot(generalSettingStore);
    const { userID, franchiseID, operatorID } = useSnapshot(store);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [error, setError] = React.useState<boolean>(false);
    const [dataIsUpdated, setDataIsUpdated] = React.useState<boolean | number>(
        false
    );
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const shopId = 0;

    const url =
        localShop.length === 1
            ? `${process.env.REACT_APP_API_V2_URL}/settings/general/filteredFiscalYearArchived?userId=${userID}&shopId=${localShop[0]}&franchiseId=${franchiseID}`
            : `${process.env.REACT_APP_API_V2_URL}/settings/general/fiscal_year?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`;
    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 0,
            columnName: " ",
            customJsx: UniqueRowSelection,
        },
        {
            indexOFColumn: 1,
            columnName: t("Start exercise"),
            customJsx: (e: any) => (
                <span>{e.selectedRow[t("Start exercise")]}</span>
            ),
            filterName: t("Start exercise"),
            canFilter: true,
        },
    ];

    function checkIfFiscalYearAssociated(shopId: string) {
        let associated = rowsShop.map((elt: any) => {
            if (elt.shopId === shopId) {
                return elt.subRows;
            } else {
                return null;
            }
        });
        return associated.filter((el: any) => el)[0];
    }
    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };
    async function associateFiscalYear() {
        toast.dismiss();

        let localData: any = [];
        let isError: boolean = false;

        for (let element of localShop) {
            let associated = checkIfFiscalYearAssociated(element);
            let objectData: any = {};
            if (fiscalYear !== "") {
                if (associated.length === 1) {
                    isError = true;
                } else if (
                    associated.findIndex(
                        (elt: any) => elt.Id === selectedRowsFiscalYear.Id
                    ) === -1 &&
                    associated.length === 0
                ) {
                    Object.assign(objectData, {
                        [selectedRowsFiscalYear.Id]: {
                            first_day: selectedRowsFiscalYear.first_day,
                            first_month: selectedRowsFiscalYear.first_month,
                            end_day: selectedRowsFiscalYear.end_day,
                            end_month: selectedRowsFiscalYear.end_month,
                            isArchived: selectedRowsFiscalYear.isArchived,
                            shopId: element,
                        },
                    });

                    let dataFiscalYear = {
                        userId: userID,
                        shopId: [element],
                        franchiseId: franchiseID,
                        operatorId: operatorID,
                        data: objectData,
                        isAssociate: true,
                        isShop: true,
                    };
                    localData.push(
                        fetch(
                            `${process.env.REACT_APP_API_V2_URL}/settings/general/fiscal_year`,
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify(dataFiscalYear),
                            }
                        ).then((response) => response.json())
                    );
                }
            }
        }
        if (isError === true) {
            ErrorToast(
                t("Attention, a shop cannot have more than one fiscal year")
            );
            setData(true);
        }
        if (localData.length > 0) {
            try {
                mutate(
                    `${process.env.REACT_APP_API_V2_URL}/settings/general/fiscal_year`,
                    await Promise.all(localData).then((result) => {
                        result.forEach((element: any) => {
                            if (element.error === true) setError(true);
                        });
                    })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }

            if (error === false) {
                toast.success(
                    `${t("Fiscal year assignment was successfully assigned")!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );

                setLimit(2);
                setData(true);
                setNumberActiveMultiShop(
                    numberActiveMultiShop +
                        selectedRows.length * localShop.length
                );
            }
        }
    }

    function CountIds() {
        let number = 0;
        idsLocalShop.forEach((el: any) => {
            selectedRows.forEach((v: any) => {
                if (v.Id === el) {
                    number = number + 1;
                }
            });
        });
        setIds(number);
    }
    function checkIfFiscalYearExistAndArchived() {
        let archived: any[] = [];
        if (rowsShop.length > 0) {
            rowsShop.forEach((element: any) => {
                if (element.archivedFiscalYear !== undefined) {
                    element.archivedFiscalYear.forEach((el: any) => {
                        archived.push(el.Id);
                    });
                }
            });
        }
        return archived;
    }
    React.useEffect(() => {
        if (rowsShop[0] !== undefined) {
            if (rowsShop[0]?.subRows !== undefined) {
                localShop.length > 1
                    ? setFiscalYear("")
                    : setFiscalYear(
                          rowsShop[0]?.subRows[0] !== undefined
                              ? rowsShop[0]?.subRows[0]?.Id
                              : rowsShop[0].Id
                      );
            } else if (rowsShop[1]?.subRows !== undefined) {
                localShop.length > 1
                    ? setFiscalYear("")
                    : setFiscalYear(
                          rowsShop[1]?.subRows[1] !== undefined
                              ? rowsShop[1]?.subRows[1]?.Id
                              : rowsShop[0].Id
                      );
            }
        }
    }, [rowsShop, localShop]);
    return (
        <StyledModal
            toggle={true}
            isOpen={isAssociateModalOpened!}
            centered
            className="modal-wrapper__clz"
            backdrop="static"
            fade={false}
            scrollable
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    setIsAssociateModalOpened(!isAssociateModalOpened);
                    setIsAssociateModalClosed(!isAssociateModalClosed);
                }}
            >
                <span
                    className="text-uppercase exf_txt_AFlistOfExercises"
                    style={{
                        fontFamily: "Segoe UI Semibold",
                        color: "#2b2828",
                        fontSize: "20px",
                    }}
                >
                    {t("List of fiscal year")}
                </span>
            </ModalHeader>
            <ModalBody>
                <DynamicTable
                    url={url}
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    customSelect
                    setSelectedRows={setSelectedRows}
                    canResize
                    canMovedCheckboxLeftOnExpand
                    actionColumn={ActionColumn}
                    showGlobalFilter
                    setDataIsUpdated={setDataIsUpdated}
                    dataIsUpdated={dataIsUpdated}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    elevationTable={1}
                    minHeight="500px"
                    maxHeight="500px"
                    name="associateFiscalYear"
                />
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    className="exf_btn_AFcancel"
                    onClick={() => {
                        setIsAssociateModalOpened(!isAssociateModalOpened);
                        setIsAssociateModalClosed(!isAssociateModalClosed);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    variant="primary"
                    className="exf_btn_AFvalidate"
                    onClick={() => {
                        let archived = checkIfFiscalYearExistAndArchived();
                        CountIds();
                        if (fiscalYear === "" || fiscalYear === undefined) {
                            ErrorToast(
                                t(
                                    "Attention ! You must select least one fiscal year"
                                )
                            );
                        } else {
                            try {
                                setSelectedRow(selectedRows);
                                if (
                                    archived.includes(selectedRowsFiscalYear.Id)
                                ) {
                                    setIsAssociatedFiscalYear(true);
                                    setMessage(
                                        t(
                                            "Attention! One or more selected fiscal years are already assigned and archived at the store level.Would you unarchive them?"
                                        )
                                    );
                                    // eslint-disable-next-line
                                    throw "Break";
                                }

                                associateFiscalYear();
                                setIsAssociateModalOpened(
                                    !isAssociateModalOpened
                                );
                            } catch (e) {
                                if (e !== "Break") throw e;
                            }
                        }
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
