import React from "react";
import { Card, Input } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { useSnapshot } from "valtio";
import { CheckIcon } from "@aureskonnect/react-ui";

import {
    generalConfigStore,
    setConfigOptionsCustomerAccount,
    setIsCustomerAccountActive,
    setIsInformationModesEmpty,
    setIsStateChanged,
} from "@store";

import { getImageContentById } from "@helpers/general";
import classnames from "classnames";

type PropsType = {
    item: ProjectMainContentItemType;
    index: number;
    setItems: any;
    setConfig: any;
};

export function CardWrapperSpecific({
    item,
    index,
    setItems,
    setConfig,
}: PropsType): JSX.Element {
    const {
        activeSubTab,
        defaultLanguage,
        project: { template, files: images },
    } = useSnapshot(generalConfigStore);

    const imageContent = getImageContentById(
        item.id,
        images,
        defaultLanguage.name
    );

    const imageSrc =
        imageContent?.includes("http") || imageContent?.includes("https")
            ? imageContent
            : `./images/${imageContent}`;

    function handleCheckIconOnClickEvent(index: number) {
        setItems((prevState: ProjectMainContentItemType[]) => {
            let newState = [...prevState];

            const activeItemsCount = newState.filter(
                (item): boolean => item.active === true
            ).length;

            newState = newState.map(
                (item: ProjectMainContentItemType, itemIndex: number) => {
                    if (itemIndex === index) {
                        const INFORMATION_MODE_TAB_NUMBER = 4;
                        return {
                            ...item,
                            active:
                                activeSubTab === INFORMATION_MODE_TAB_NUMBER
                                    ? !item.active
                                    : activeItemsCount !== 1
                                    ? !item.active
                                    : true,
                        };
                    }
                    if (item.name === "Delivery" && item.active === true) {
                        setIsCustomerAccountActive(true);
                        setIsStateChanged(true);
                        setConfigOptionsCustomerAccount(true);
                    }

                    return { ...item };
                }
            );

            setConfig(newState);
            return newState;
        });
    }

    React.useEffect(() => {
        const filteredInformationMode = (template.content
            .salesMethods as ProjectContentItemType).items.filter(
            (item1: any) =>
                item1.name !== "Retrait C&C" &&
                (item1.informationModes as InformationModesContentType).items
                    .length !== 0 &&
                item1.active === true
        );

        if (item.active === true && filteredInformationMode.length > 0) {
            setIsInformationModesEmpty(false);
        }
        if (item.active === false && filteredInformationMode.length === 0) {
            setIsInformationModesEmpty(true);
        }
    }, [
        item.active,
        item.name,
        template.content.informationModes,
        template.content.salesMethods,
    ]);
    return (
        <div
            className="d-flex flex-column justify-content-center cursor__clz"
            style={{
                gap: "10px",
                width: "min-content",
                borderBottom: "1px solid #707070",
                opacity: item.active === false ? "40%" : "",
            }}
        >
            <Card
                className={classnames({
                    "disable-account-creation-items__clz":
                        item.name === "Retrait C&C",
                })}
                style={{
                    borderBottom: item.active ? "blue 10px solid" : "unset",
                    width: "250px",
                    height: "220px",
                    borderRadius: "20px",
                }}
            >
                <div
                    style={{
                        display: "grid",
                        gridTemplateRows: "0.5fr auto",
                        height: "100%",
                    }}
                >
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "0.2fr 0.6fr 0.2fr",
                            height: "100%",
                        }}
                    >
                        <CheckIcon
                            className="mt-1 ml-1 cmn_chk_selectButton"
                            height={45}
                            width={45}
                            style={{
                                fill: item.active ? "blue" : "#BCBCBC",
                                cursor: "pointer",
                            }}
                            onClick={() => handleCheckIconOnClickEvent(index)}
                        />
                    </div>
                    <div
                        className="d-flex justify-content-center "
                        onClick={() => handleCheckIconOnClickEvent(index)}
                    >
                        <img
                            alt="img"
                            key={index}
                            src={imageSrc}
                            style={{
                                width: "120px",
                                height: "120px",
                            }}
                        />
                    </div>
                </div>
            </Card>
            <AvForm
                className="d-flex justify-content-center align-items-center"
                style={{ gap: "5px" }}
            >
                <Input
                    className="border-0"
                    id="card-title"
                    name="card-title"
                    type="text"
                    disabled={true}
                    value={item.languages[defaultLanguage.name].name}
                    style={{
                        backgroundColor: "transparent",
                        fontSize: "20px",
                        color: "#000000",
                    }}
                />
            </AvForm>
        </div>
    );
}
