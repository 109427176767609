import React from "react";
import classnames from "classnames";
import { VerticalDotsIcon } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";

import ErrorToast from "../../../../../components/Common/ErrorTost";
import { store as hoursStore, setIsHistoryModalOpened } from "../../store";

export function CustomSideFilter({
    selectedRows,
    isModalOpened,
    setIsModalOpened,
    setDay,
    setDaysDesignations,
    setIsAllHoursEqual,
}: any) {
    const { t } = useTranslation();
    const { isHistoryModalOpened ,modify} = useSnapshot(hoursStore);
    return (
        <div className="d-flex justify-content-start align-items-center ml-2">
            <VerticalDotsIcon
                className="hov_drp_FRmenu"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={25}
                fill="black"
            />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames(
                        "dropdown-item pl-1 hov_btn_FRhistory",
                        {
                            "not-allowed-icon__clz":
                                selectedRows.length === 0 ||
                                selectedRows.every(
                                    (elt: any) => elt[t("Day of week")] === ""
                                ),
                        }
                    )}
                    onClick={() => {
                        let hoursOfSelectedRow = 0;
                        selectedRows.forEach((element: any) => {
                            if (element[t("Day of week")] !== "") {
                                hoursOfSelectedRow += 1;
                            }
                        });
                        if (hoursOfSelectedRow === 1) {
                            ErrorToast(
                                `${t(
                                    "Attention, it is a multiple action. Please select at least two items"
                                )!}`
                            );
                        } else {
                            setIsHistoryModalOpened(!isHistoryModalOpened);
                        }
                    }}
                >
                    {t("Historical")}
                </div>
                {modify === true ? (
                    <>
                        <div
                            style={{ cursor: "pointer" }}
                            className={classnames(
                                "dropdown-item pl-1",
                                {
                                    "not-allowed-icon__clz":
                                        selectedRows.length === 0 ||
                                        selectedRows.every(
                                            (elt: any) =>
                                                elt[t("Day of week")] === ""
                                        ),
                                },
                                "hov_btn_FRmultipleTimeSetting"
                            )}
                            onClick={() => {
                                let hoursOfSelectedRow = 0;
                                selectedRows.forEach((element: any) => {
                                    if (element[t("Day of week")] !== "") {
                                        hoursOfSelectedRow += 1;
                                    }
                                });
                                if (hoursOfSelectedRow === 1) {
                                    ErrorToast(
                                        `${t(
                                            "Attention, it is a multiple action. Please select at least two items"
                                        )!}`
                                    );
                                } else {
                                    setIsModalOpened(!isModalOpened);
                                    if (selectedRows.length > 1) {
                                        let days = "";
                                        selectedRows.forEach((elt: any) => {
                                            if (elt[t("Day of week")] !== "") {
                                                days =
                                                    days +
                                                    ", " +
                                                    t(
                                                        elt[t("Day of week")]
                                                    ).slice(0, 3);
                                            }
                                        });
                                        setDaysDesignations(
                                            days.slice(1, days.length)
                                        );

                                        let isSimilar = [];
                                        let rows: any = [];
                                        selectedRows.forEach((element: any) => {
                                            if (
                                                element[t("Day of week")] !== ""
                                            ) {
                                                rows.push(element);
                                            }
                                        });

                                        let firstElement = rows[0].subRows;
                                        let firstStateDay = rows[0][t("State")];
                                        for (let i = 1; i < rows.length; i++) {
                                            if (rows[i].subRows.length > 0) {
                                                isSimilar.push(
                                                    rows[i].subRows.length ===
                                                        firstElement.length &&
                                                        firstStateDay ===
                                                            rows[i][
                                                                t("State")
                                                            ] &&
                                                        rows[i].subRows.every(
                                                            (
                                                                value: any,
                                                                key: number
                                                            ) =>
                                                                value[
                                                                    t(
                                                                        "Opening time"
                                                                    )
                                                                ] ===
                                                                    firstElement[
                                                                        key
                                                                    ][
                                                                        t(
                                                                            "Opening time"
                                                                        )
                                                                    ] &&
                                                                value[
                                                                    t(
                                                                        "Closing time"
                                                                    )
                                                                ] ===
                                                                    firstElement[
                                                                        key
                                                                    ][
                                                                        t(
                                                                            "Closing time"
                                                                        )
                                                                    ] &&
                                                                value[
                                                                    t("Status")
                                                                ] ===
                                                                    firstElement[
                                                                        key
                                                                    ][
                                                                        t(
                                                                            "Status"
                                                                        )
                                                                    ]
                                                        )
                                                );
                                            } else {
                                                isSimilar.push(false);
                                            }
                                        }

                                        const isSameElts = isSimilar.every(
                                            (elt: any) => elt === true
                                        );
                                        setIsAllHoursEqual(
                                            isSimilar.every(
                                                (elt: any) => elt === true
                                            )
                                        );

                                        let settingDayRows: any = [];
                                        if (isSameElts) {
                                            rows[0].subRows.forEach(
                                                (elt: any) => {
                                                    settingDayRows.push({
                                                        openingHour:
                                                            elt[
                                                                t(
                                                                    "Opening time"
                                                                )
                                                            ],
                                                        closingHour:
                                                            elt[
                                                                t(
                                                                    "Closing time"
                                                                )
                                                            ],
                                                        stateHour:
                                                            elt[t("Status")],
                                                        isArchived: false,
                                                        overLappedStart: false,
                                                        overLappedEnd: false,
                                                        inputStartTime: false,
                                                        inputEndTime: false,
                                                        hoursNumber:
                                                            elt[
                                                                t("Total hours")
                                                            ],
                                                    });
                                                }
                                            );
                                        }

                                        setDay({
                                            day: rows[0]["Id"],
                                            designation:
                                                rows[0][t("Day of week")],
                                            stateDay: isSameElts
                                                ? rows[0][t("State")]
                                                : false,
                                            hoursNumber:
                                                rows[0][t("Total hours")],
                                            settingDay: isSameElts
                                                ? settingDayRows.map(
                                                      (elt: any) => {
                                                          return {
                                                              openingHour:
                                                                  elt.openingHour,
                                                              closingHour:
                                                                  elt.closingHour,
                                                              stateHour:
                                                                  elt.stateHour,
                                                              isArchived:
                                                                  elt.isArchived,
                                                              overLappedStart:
                                                                  elt.overLappedStart,
                                                              overLappedEnd:
                                                                  elt.overLappedEnd,
                                                              inputStartTime:
                                                                  elt.inputStartTime,
                                                              inputEndTime:
                                                                  elt.inputEndTime,
                                                              hoursNumber:
                                                                  elt.hoursNumber,
                                                          };
                                                      }
                                                  )
                                                : [
                                                      {
                                                          openingHour: "",
                                                          closingHour: "",
                                                          stateHour: true,
                                                          isArchived: false,
                                                          overLappedStart: false,
                                                          overLappedEnd: false,
                                                          inputStartTime: false,
                                                          inputEndTime: false,
                                                          hoursNumber: 0,
                                                      },
                                                  ],
                                        });
                                    }
                                }
                            }}
                        >
                            {t("Multiple time configuration")}
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    );
}
