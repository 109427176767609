import React from "react";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";
import { useSnapshot } from "valtio";

import {
    generalConfigStore,
    setIsOrderTakingCategoryTitleActive,
    setPageOrderTaking,
} from "@store";

export function Menu(): JSX.Element {
    const { t } = useTranslation();

    const {
        project: { template },
        isOrderTakingCategoryTitleActive,
    } = useSnapshot(generalConfigStore);

    return (
        <div
            className="mx-2 m-2 rounded border"
            style={{ borderColor: "#CECECE", height: "93%" }}
        >
            <div
                className="text-left px-4 d-flex align-items-center cursor__clz"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                {t("Menu")}
            </div>
            <div className="p-4">
                <div className="py-2 d-flex flex-rows justify-content-between align-items-center">
                    <div>
                        {t("Category titles")}
                        <div style={{ color: "#B7B7B7", fontSize: "13px" }}>
                            {
                                (template.pages.ways[
                                    "orderTaking"
                                ] as OrderTakingPageType).orderTakingProduct
                                    .categoryTitle
                            }
                        </div>
                    </div>
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#f7b4b8"
                        offHandleColor="#E30613"
                        checked={isOrderTakingCategoryTitleActive}
                        onChange={() => {
                            setIsOrderTakingCategoryTitleActive(
                                !isOrderTakingCategoryTitleActive
                            );
                            setPageOrderTaking({
                                ...(template.pages.ways[
                                    "orderTaking"
                                ] as OrderTakingPageType).orderTakingProduct,
                                categoryTitle: !isOrderTakingCategoryTitleActive,
                            });
                        }}
                        onColor="#c2eddd"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
            </div>
        </div>
    );
}
