export const ITEMS_PER_PAGE = 5;
export const EMAIL_VALIDATION_REGEX = /\S+@\S+\.\S+/;
export const FORGOT_PASSWORD_CODE_LENGTH = 6;
export const FORGOT_PASSWORD_CODE_LENGHT = 6;
export const DAYS_IN_ONE_YEAR = 365;
export const ADVANCED_ITEMS_PER_PAGE = 7;
export const SPEEDS = [9600, 19200, 38400, 57600, 115200];
export const PORT_NUMBER_REG_EXP = new RegExp(/-?\d*\.?\d{1,2/);
export const PORTS = [
    "COM1",
    "COM2",
    "COM3",
    "COM4",
    "COM5",
    "COM6",
    "COM7",
    "COM8",
    "COM9",
];
export const ipMatchWord = new RegExp(
    /^(?!0|\s)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/i
);
export const httpRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
export const numberMatchWord = new RegExp(/^[1-9]\d*$/);
export const DEFAULT_TIMEOUT_IN_SECONDES = 3;
export const MIN_TIMEOUT_IN_SECONDES = 0;
export const languages = [
    { value: "fr", label: "Français" },
    { value: "en", label: "Anglais" },
    { value: "nl", label: "Allemand" },
    { value: "es", label: "Espagnol" },
    { value: "it", label: "Italien" },
    { value: "pt", label: "Portugais" },
];
export const allLanguages = [
    "Albanais",
    "Allemand",
    "Afrikaans",
    "Araona",
    "Azéri",
    "Amazigh",
    "Arménien",
    "Aymara",
    "Anglais",
    "Arabe",
    "Bengali",
    "Bulgare",
    "Bésiro",
    "Bichelamar",
    "Biélorusse",
    "Birman",
    "Catalan",
    "Coréen",
    "Croate",
    "Cambodgien",
    "Chinois",
    "Danois",
    "Espagnol",
    "Finnois",
    "Français",
    "Japonais",
    "Portugais",
    "Russe",
    "Guarani",
    "Grec",
    "Hongrois",
    "Italien",
    "Kikongo",
    "Kiswahili",
    "Lingala",
    "Malais",
    "Mongol",
    "Néerlandais",
    "Occitan",
    "Ourdou",
    "Persan",
    "Quechua",
    "Roumain",
    "Samoan",
    "Serbe",
    "Sesotho",
    "Slovaque",
    "Slovène",
    "Suédois",
    "Tamoul",
    "Turc",
    "Canichana",
    "Cavineña",
    "Cayubaba",
    "Chácobo",
    "Chichewa",
    "Chimane",
    "Créole de Guinée-Bissau",
    "Créole haïtien",
    "Créole seychellois",
    "Divehi",
    "Dzongkha",
    "Ese 'ejja",
    "Estonien",
    "Éwé",
    "Fidjien",
    "Filipino",
    "Géorgien",
    "Gilbertin",
    "Guarasu'we",
    "Guarayu",
    "Hébreu",
    "Hindi",
    "Hindi des Fidji",
    "Hiri motu",
    "Indonésien",
    "Irlandais",
    "Islandais",
    "Itonama",
    "Kallawaya",
    "Kabiyè",
    "Kazakh",
    "Khmer",
    "Kirghiz",
    "Kirundi",
    "Lao",
    "Néo-zélandaise",
    "Papouasienne",
    "Latin",
    "Leko",
    "Letton",
    "Lituanien",
    "Luxembourgeois",
    "Macédonien",
    "Machineri",
    "Malgache",
    "Maltais",
    "Maori",
    "Maori des Îles Cook",
    "Maropa",
    "Marshallais",
    "Mirandais",
    "Mojeño-Trinitario",
    "Mojeño-Ignaciano",
    "Monténégrin",
    "Moré",
    "Mosetén",
    "Movima",
    "Nauruan",
    "Ndébélé du Transvaal",
    "Népalais",
    "Norvégien",
    "Ouzbek",
    "Pacahuara",
    "Pachto",
    "Paluan",
    "Polonais",
    "Puquina",
    "Sango",
    "Shikomor",
    "Shona",
    "Shuar",
    "Sindebele",
    "Singhalais",
    "Sirionó",
    "Somali",
    "Sotho du Nord",
    "Sotho du Sud",
    "Tacana",
    "Tadjik",
    "Tamazight",
    "Tapiete",
    "Tchèque",
    "Tétoum",
    "Tigrinya",
    "Thaï",
    "Tok pisin",
    "Tongien",
    "Toromona",
    "Turkmène",
    "Tuvaluan",
    "Ukrainien",
    "Uru-Chipaya",
    "Vietnamien",
    "Wichi",
    "Yaminahua",
    "Yuki",
    "Yaracaré",
    "Zamuco",
    "Zoulou",
    "Adyguéen",
    "Agul",
    "Altaï",
    "Assamais",
    "Avar",
    "Bachkir",
    "Bas saxon",
    "Basque",
    "Bouriate",
    "Cantonais",
    "Carolinien",
    "Chamorro",
    "Darguine",
    "Erzya",
    "Galicien",
    "Gallois",
    "Gwich'in",
    "Hawaïen",
    "Iakoute",
    "Ingouche",
    "Inuinnaqtun",
    "Inuktitut",
    "Kabarde",
    "Kalmouk",
    "Karatchaï-balkar",
    "Khakasse",
    "Komi",
    "Koumyk",
    "Lak",
    "Lezghien",
    "Mannois",
    "Mari",
    "Moksa",
    "Nogaï",
    "Occitan (aranais)",
    "Ossète",
    "Oudmourte",
    "Ouïghour",
    "Romanche",
    "Routoul",
    "Ruthène",
    "Same",
    "Sorabe",
    "Swati",
    "Tabassaran",
    "Tat",
    "Tatare",
    "Tchétchène",
    "Tchouvache",
    "Tibétain",
    "Tobi",
    "Touvain",
    "Tsakhur",
    "Tsonga",
    "Tswana",
    "Venda",
    "Xhosa",
];
