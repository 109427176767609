import React from "react";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";

import { setIsModalOpened } from "@store";

import { ModalWrapper } from "@components/Common/ModalWrapper";
import { ModalContentWrapper } from "@components/ModalContentWrapper";

import "./index.css";
import "./transitions.css";

export function GeneralConfig(): JSX.Element {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <div>
                <Button color="danger" onClick={() => setIsModalOpened(true)}>
                    Click Me
                </Button>
            </div>
            <ModalWrapper title={t("Expert mode settings")}>
                <ModalContentWrapper />
            </ModalWrapper>
        </React.Fragment>
    );
}
