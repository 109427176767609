import React from "react";
import Keyboard from "react-simple-keyboard";

export function UsualPseudonymInputKeyboard(): JSX.Element {
    const [input, setInput] = React.useState<string>("");

    function handleKeyboardOnChangeEvent(input: string) {
        setInput(input);
    }

    return (
        <div
            className="p-1 d-grid__clz justify-content-center align-items-end"
            style={{
                border: "1px solid rgb(221, 214, 214)",
                borderRadius: "10px",
                boxShadow: "rgba(5, 4, 4, 0.15) 0px 2px 4px",
                gap: "5px",
            }}
        >
            <div className="d-flex justify-content-center">
                <input
                    value={input}
                    placeholder={"Nom ou Pseudo"}
                    className="w-50 text-center"
                    style={{
                        font: "normal normal 300 34px/46px Segoe UI;",
                        border: "none",
                        borderBottom: "1px solid rgb(221, 214, 214)",
                    }}
                />
            </div>
            <Keyboard
                layoutName="default"
                onChange={handleKeyboardOnChangeEvent}
            />
        </div>
    );
}
