import React from "react";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { StyledBreadcrumb, Tabs } from "@aureskonnect/react-ui";
import { getPathAuthorization } from "@components/VerticalLayout";
import { useLocation } from "react-router-dom";

import PageTitle from "@components/Common/PageTitle";
import MessageInfo from "@components/Common/MessageInfo";
import { store } from "@components/VerticalLayout/store";
import { setModify } from "./store";

import ServicesFranchise from "./ServiceFranchise";
import ServicesShops from "./ServicesShops";

import { MainColorTabsWrapper } from "@components/Common/MainColorTabsWrapper";

import "./index.css";

export function Services() {
    const location = useLocation();
    setModify(getPathAuthorization(location.pathname).Modifie);
    const { t } = useTranslation();
    const [isServicesArchived, setIsServicesArchived] = React.useState<boolean>(
        false
    );
    const { oneShop } = useSnapshot(store);

    const tabs1 = (
        <ServicesFranchise
            isServicesArchived={isServicesArchived}
            setIsServicesArchived={setIsServicesArchived}
        />
    );

    const tabs2 = (
        <Tabs
            tabs={[
                {
                    content: (
                        <ServicesFranchise
                            isServicesArchived={isServicesArchived}
                            setIsServicesArchived={setIsServicesArchived}
                        />
                    ),
                    disabled: false,
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark cmn_btn_franchise"
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Service")}
                        </div>
                    ),
                },
                {
                    content: (
                        <ServicesShops
                            isServicesArchived={isServicesArchived}
                            setIsServicesArchived={setIsServicesArchived}
                        />
                    ),
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark border-0 cmn_btn_boutique"
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Store service")}
                        </div>
                    ),
                },
            ]}
        />
    );
    return (
        <React.Fragment>
            <ToastContainer limit={1} />
            <PageTitle title={t("General settings")} />
            <div className="page-content__clz">
                <div className="srv_txt_service">
                    <StyledBreadcrumb
                        items={[
                            {
                                item: "ETK Settings".toUpperCase(),
                                link: "https://dev-portail.aureskonnect.com",
                            },
                            {
                                item:
                                    localStorage
                                        .getItem("selectedAccount")
                                        ?.toUpperCase() || "",
                                link: "/compte",
                            },
                            {
                                item: t("General settings").toUpperCase(),
                                link: "#",
                            },
                            {
                                item: !isServicesArchived
                                    ? t("Service").toUpperCase()
                                    : t("Archived Service").toUpperCase(),
                                link: "#",
                            },
                        ]}
                    />
                </div>

                <MessageInfo message={t("Please configure your service")} />
                <MainColorTabsWrapper>
                    {oneShop ? tabs1 : tabs2}
                </MainColorTabsWrapper>
            </div>
        </React.Fragment>
    );
}
