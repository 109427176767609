import React from "react";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import ReactSwitch from "react-switch";

import {
    computedStore,
    designStore,
    setIsPaymentPageActionsButtonsActive,
    setPaymentPageActionsButtonsItems,
    setIsActionButtonsActive,
    setActionsButtonsItems,
} from "@store";

export function MainContentChangeMachine(): JSX.Element {
    const { t } = useTranslation();

    const {
        isActionButtonsActive,
        activeSlideIndex,
        paymentSelectedRegulationModeValue,
    } = useSnapshot(designStore);
    const { elements } = useSnapshot(computedStore);

    const [filteredItems, setFilteredItems] = React.useState<
        ActionsButtonsDesignItemType[]
    >(
        (elements[activeSlideIndex] as PaymentPageType).regulationMode[
            paymentSelectedRegulationModeValue
        ].actionsButtons.items.filter((item: ActionsButtonsDesignItemType) => {
            const PAYMENT_INSTRUCTION_BUTTON_ROLE = 0;
            const BACK_BUTTON_ROLE = 3;

            const ROLES = [PAYMENT_INSTRUCTION_BUTTON_ROLE, BACK_BUTTON_ROLE];

            return ROLES.includes(item.role);
        })
    );

    function handleActivateSwitchOnClickEvent(name: string) {
        const array = [...filteredItems];
        if (
            array.filter((actionButtonItem) => {
                return actionButtonItem.active === true;
            }).length === 0 &&
            isActionButtonsActive === false
        ) {
            setIsActionButtonsActive(true);
            setIsPaymentPageActionsButtonsActive(
                true,
                paymentSelectedRegulationModeValue
            );
        }

        const item = array.find((element) => element.name === name);

        if (item !== undefined) {
            const actionButtonItem = array.indexOf(item);

            array[actionButtonItem] = {
                ...item,
                active: !array[actionButtonItem].active,
            };
            setFilteredItems(array);
            setActionsButtonsItems(array);
            setPaymentPageActionsButtonsItems(
                array,
                paymentSelectedRegulationModeValue
            );
        }

        if (
            array.filter((actionButtonItem) => {
                return actionButtonItem.active === true;
            }).length === 0
        ) {
            setIsActionButtonsActive(false);
            setIsPaymentPageActionsButtonsActive(
                false,
                paymentSelectedRegulationModeValue
            );
        }
    }

    return (
        <div
            className="rounded border"
            style={{
                top: "357px",
                left: "555px",
                width: "800px",
                height: "507px",
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                borderRadius: "9px",
                opacity: 1,
            }}
        >
            <div
                className="text-left px-4 d-flex align-items-center"
                style={{
                    backgroundColor: "#EDEDED",
                    height: "40px",
                    fontSize: "20px",
                }}
            >
                {t("Actions Buttons")}
            </div>
            <div className="p-4 d-flex flex-column align-items-center">
                {filteredItems.map(
                    (item: ActionsButtonsDesignItemType, index: number) => {
                        return (
                            <div
                                className="py-3 align-items-center"
                                style={{
                                    width: "100%",
                                    gap: "20px",
                                    display: "grid",
                                    gridTemplateColumns: "1fr 1fr",
                                }}
                                key={index}
                            >
                                <span>{t(item?.name)}</span>

                                <div className="d-flex justify-content-end">
                                    <ReactSwitch
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        handleDiameter={26}
                                        offColor="#f7b4b8"
                                        offHandleColor="#E30613"
                                        onChange={() => {
                                            handleActivateSwitchOnClickEvent(
                                                filteredItems[index].name
                                            );
                                        }}
                                        checked={filteredItems[index].active}
                                        onColor="#c2eddd"
                                        onHandleColor="#34C38F"
                                        width={50}
                                        height={20}
                                    />
                                </div>
                            </div>
                        );
                    }
                )}
            </div>
        </div>
    );
}
