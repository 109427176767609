import { proxy } from "valtio";

type StoreType = {
    FN: Function;
    message: string;
};

export const store = proxy<StoreType>({
    FN: () => {},
    message: "",
});

export function setFN(f: Function): void {
    store.FN = f;
}
export function setMessage(message: string): void {
    store.message = message;
}

export default store;
