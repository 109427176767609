import React from "react";
import { useSnapshot } from "valtio";
import { t } from "i18next";

import { designStore, setActiveMainContentItems } from "@store";

import { TranslationWrapper } from "@components/Common/Design/MainContentWrapper/TranslationWrapper";

import "@components/Common/Design/index.css";
import "simplebar-react/dist/simplebar.min.css";

export function Modes(): JSX.Element {
    const { mainContentItems } = useSnapshot(designStore);

    React.useEffect(() => {
        setActiveMainContentItems(
            mainContentItems.filter((item) => {
                return item.active === true;
            })
        );
    }, [mainContentItems]);

    return (
        <div>
            <div
                className="mt-4 rounded border"
                style={{
                    width: "93%",
                    height: "96%",
                    borderColor: "#CECECE",
                }}
            >
                <div
                    className="text-left pl-4 d-flex align-items-center justify-content-between cursor__clz"
                    style={{
                        backgroundColor: "#EDEDED",
                        height: "40px",
                        fontSize: "20px",
                    }}
                >
                    <div>{t("Connection")}</div>
                </div>
            </div>
            <TranslationWrapper />
        </div>
    );
}
