import classNames from "classnames";
import React from "react";
import { useSnapshot } from "valtio";

import { designStore, generalConfigStore } from "@store";

import { ActionsButtonsWrapper } from "./ActionsButtonsWrapper";
import { BasketWrapper } from "./BasketWrapper";
import "./ConfigOptionsWrapper.css";
import { ContainerWrapper } from "./ContainerWrapper";
import { HeaderWrapper } from "./HeaderWrapper";
import { MainContentWrapper } from "./MainContentWrapper";
import { TopBannerWrapper } from "./TopBannerWrapper";

export function GenericInterfacePreview(): JSX.Element {
    const { activeTabIndex, isHeaderOrderTakingActive } = useSnapshot(
        designStore
    );
    const { isOrderTakingTopBannerActive } = useSnapshot(generalConfigStore);

    return (
        <ContainerWrapper
            isTopBannerActive={isOrderTakingTopBannerActive}
            isHeaderActive={isHeaderOrderTakingActive}
        >
            {isOrderTakingTopBannerActive === true ? (
                <div
                    className={classNames(
                        "d-flex justify-content-center align-items-center",
                        {
                            "border-wrapper__clz": activeTabIndex === 0,
                        }
                    )}
                >
                    <TopBannerWrapper />
                </div>
            ) : null}
            {isHeaderOrderTakingActive === true ? (
                <div>
                    <div
                        className={classNames({
                            "border-wrapper__clz": activeTabIndex === 1,
                            "d-contents": isOrderTakingTopBannerActive === true,
                            "mt-3": isOrderTakingTopBannerActive === false,
                        })}
                        style={{
                            width: "429px",
                            height: "36px",
                        }}
                    >
                        <HeaderWrapper />
                    </div>

                    <hr
                        style={{
                            height: "1px",
                            width: "96%",
                            backgroundColor: "#cccccc",
                            margin: "unset 2px unset 2px",
                            marginTop: "unset",
                            marginBottom: "unset",
                            border: " 0px none",
                        }}
                    />
                </div>
            ) : null}

            <MainContentWrapper />

            <div
                className={classNames({
                    "border-wrapper__clz": activeTabIndex === 4,
                })}
                style={{ width: "429px", height: "113px" }}
            >
                <BasketWrapper />
            </div>

            <div
                className={classNames({
                    "border-wrapper__clz": activeTabIndex === 5,
                })}
                style={{ width: "429px", height: "30px", marginLeft: "-3px" }}
            >
                <div
                    className="ml-2 d-flex flex-row justify-content-center align-items-center"
                    style={{ gap: "10px" }}
                >
                    <ActionsButtonsWrapper />
                </div>
            </div>
        </ContainerWrapper>
    );
}
