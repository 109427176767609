import React from "react";
import { useTable } from "react-table";
import {
    DragDropContext,
    Droppable,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    DropResult,
} from "react-beautiful-dnd";
import styled from "styled-components";
import { useSnapshot } from "valtio";
import SimpleBar from "simplebar-react";

import {
    generalConfigStore,
    setStandbyScreenFile,
    setStandbyStateScreenItems,
} from "@store";

const Tr = styled.tr`
    background-color: white;
    display: ${({ isDragging }: { isDragging: boolean }) =>
        isDragging ? "table" : ""};
`;

// eslint-disable-next-line
export function Table({ columns }: any) {
    const { standbyStateScreenItems } = useSnapshot(generalConfigStore);

    const table = useTable({
        columns,
        data: standbyStateScreenItems,
    });

    const { getTableProps, headerGroups, prepareRow, rows } = table;

    function handleDragEnd(result: DropResult) {
        if (!result.destination) return;

        const newData = [...standbyStateScreenItems];
        const [movedRow] = newData.splice(result.source.index, 1);
        newData.splice(result.destination.index, 0, movedRow);

        setStandbyStateScreenItems(newData);
        setStandbyScreenFile(newData);
    }

    return (
        <div style={{ border: "1px solid #C6C6C6", height: "100%" }}>
            <table {...getTableProps()}>
                <thead>
                    {
                        // eslint-disable-next-line
                        headerGroups.map((headerGroup: any, index: number) => (
                            <tr
                                key={index}
                                {...headerGroup.getHeaderGroupProps()}
                            >
                                {headerGroup.headers.map(
                                    // eslint-disable-next-line
                                    (column: any, index: number) => (
                                        <th
                                            key={index}
                                            {...column.getHeaderProps()}
                                        >
                                            {column.render("Header")}
                                        </th>
                                    )
                                )}
                            </tr>
                        ))
                    }
                </thead>
                <SimpleBar autoHide={true} style={{ maxHeight: 350 }}>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="table-body">
                            {(provided) => (
                                <tbody
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {
                                        // eslint-disable-next-line
                                        rows.map((row: any) => {
                                            prepareRow(row);
                                            return (
                                                <Draggable
                                                    draggableId={
                                                        row.original.id
                                                    }
                                                    key={row.original.id}
                                                    index={row.index}
                                                >
                                                    {(
                                                        provided: DraggableProvided,
                                                        snapshot: DraggableStateSnapshot
                                                    ) => {
                                                        return (
                                                            <Tr
                                                                {...row.getRowProps()}
                                                                {...provided.draggableProps}
                                                                ref={
                                                                    provided.innerRef
                                                                }
                                                                isDragging={
                                                                    snapshot.isDragging
                                                                }
                                                            >
                                                                {row.cells.map((
                                                                    // eslint-disable-next-line
                                                                    cell: any,
                                                                    index: number
                                                                ) => (
                                                                    <td
                                                                        key={
                                                                            index
                                                                        }
                                                                        {...cell.getCellProps()}
                                                                    >
                                                                        {cell.render(
                                                                            "Cell",
                                                                            {
                                                                                dragHandleProps:
                                                                                    provided.dragHandleProps,
                                                                                isSomethingDragging:
                                                                                    snapshot.draggingOver,
                                                                            }
                                                                        )}
                                                                    </td>
                                                                ))}
                                                            </Tr>
                                                        );
                                                    }}
                                                </Draggable>
                                            );
                                        })
                                    }
                                    {provided.placeholder}
                                </tbody>
                            )}
                        </Droppable>
                    </DragDropContext>
                </SimpleBar>
            </table>
        </div>
    );
}
