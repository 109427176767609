import React from "react";
import { Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useSnapshot } from "valtio";
import classNames from "classnames";

import { store } from "./store";

import "./SidebarLogo.css";
import "./layout.css";

export default function Footer() {
    const { collapsed, isMobileBurgerButtonClicked } = useSnapshot(store);
    const { t } = useTranslation();
    const location = useLocation();

    let matchHomeRoute = "/home" === location.pathname;
    let HomeRoute = "/compte" === location.pathname;

    return (
        <footer
            className={`${classNames({
                "my-selection-footer": matchHomeRoute,
                footer__clz: collapsed,
                footer: !collapsed && !HomeRoute,
                mobile__clz: isMobileBurgerButtonClicked,
                footerSettings__clz: !collapsed,
            })}
            `}
            style={{ marginTop: "auto", backgroundColor: "#FFFFFF" }}
        >
            <Row className="d-flex col-sm justify-content-center justify-content-sm-start pb-md-row">
                <div className="mt-4 mx-1 mb-md-4 px-4">
                    © {new Date().getFullYear()} AuresKonnect
                </div>

                <Link to="#" className="mt-4 mx-1 mb-md-4 link-div__clz">
                    <u>{t("Terms of use")}</u>
                </Link>

                <Link to="#" className="mt-4 mx-1 mb-md-4 link-div__clz">
                    <u>{t("Confidentiality rules")}</u>
                </Link>
            </Row>
        </footer>
    );
}
