import { proxy } from "valtio";

type StoreType = {
    numberArchived: number;
    numberActive: number;
    isAssociateModalOpened: boolean;
    localShop: any[];
    isAssociateModalClosed: boolean;
    numberActiveMultiShop: number;
    numberArchiveMultiShop: number;
    isModeOfSaleAssociatedModifiedClosed: boolean;
    idsLocalShop: string[];
    isAssociatedModeOfSale: boolean;
    selectedRows: any[];
    isAssociatedClosed: boolean;
    ids: number;
    isModeOfSaleAssociatedArchivedClosed: boolean;
    isModified: boolean;
    isActivation: boolean;
    isModeOfSaleAssociatedModified: boolean;
    shopsModeSaleData: any;
    modeSaleData: any;
    rowsShop: any[];
    archivedModeOfSaleFranchise: any;
    associatedModeOfSale: any[];
    modeOfSaleFranchise: any;
    activeModeSale: any[];
    globalDataIsUpdated: boolean | number;
    dataArchive: any;
    dataFranchiseArchive: any;
    isArchived: boolean;
    message: string;
    salesSupports: any;
    modeOfSaleFavored: any;
    editedDataModeOfSale: any;
    isActionsClicked: boolean;
    isEdited: boolean;
    actions: Function;
    validationAction: any;
    modify: boolean;
};

export const store = proxy<StoreType>({
    numberArchived: 0,
    numberActive: 0,
    isAssociateModalOpened: false,
    localShop: [],
    isAssociateModalClosed: false,
    numberActiveMultiShop: 0,
    numberArchiveMultiShop: 0,
    isModeOfSaleAssociatedModifiedClosed: false,
    idsLocalShop: [],
    isAssociatedModeOfSale: false,
    selectedRows: [],
    isAssociatedClosed: false,
    ids: 0,
    isModeOfSaleAssociatedArchivedClosed: false,
    isModified: false,
    isActivation: false,
    isModeOfSaleAssociatedModified: false,
    shopsModeSaleData: [],
    modeSaleData: [],
    rowsShop: [],
    archivedModeOfSaleFranchise: [],
    associatedModeOfSale: [],
    modeOfSaleFranchise: [],
    activeModeSale: [],
    globalDataIsUpdated: false,
    dataArchive: [],
    dataFranchiseArchive: [],
    isArchived: false,
    message: "",
    salesSupports: [],
    modeOfSaleFavored: [],
    editedDataModeOfSale: {},
    isActionsClicked: false,
    isEdited: false,
    actions: () => {},
    validationAction: {},
    modify: false,
});
export function setModify(value: boolean): void {
    store.modify = value;
}

export function setNumberArchived(number: number): void {
    store.numberArchived = number;
}

export function setNumberActive(number: number): void {
    store.numberActive = number;
}

export function setModeOfSaleFavored(modeOfSaleFavored: any): any {
    let localIndex: number = store.modeOfSaleFavored.findIndex((e: any) => {
        return e.id === modeOfSaleFavored.id;
    });
    if (localIndex === -1 || localIndex === undefined) {
        store.modeOfSaleFavored.push(modeOfSaleFavored);
    } else {
        store.modeOfSaleFavored[localIndex] = modeOfSaleFavored;
    }

    if (modeOfSaleFavored.status === true) {
        store.modeOfSaleFavored = store.modeOfSaleFavored.map((el: any) =>
            el.id !== modeOfSaleFavored.id ? { id: el.id, status: false } : el
        );
    }
}
export function setIsAssociateModalOpened(isModalOpened: boolean): void {
    store.isAssociateModalOpened = isModalOpened;
}
export function setLocalShop(shops: any[]): void {
    store.localShop = shops;
}
export function setIsAssociateModalClosed(
    isAssociateModalClosed: boolean
): void {
    store.isAssociateModalClosed = isAssociateModalClosed;
}

export function setNumberActiveMultiShop(number: number): void {
    store.numberActiveMultiShop = number;
}
export function setNumberArchiveMultiShop(number: number): void {
    store.numberArchiveMultiShop = number;
}

export function setIdsLocalShop(ids: any[]): void {
    store.idsLocalShop = ids;
}

export function setIsAssociatedModeOfSale(
    isAssociatedModeOfSale: boolean
): void {
    store.isAssociatedModeOfSale = isAssociatedModeOfSale;
}
export function setSelectedRow(selectedRows: any[]): void {
    store.selectedRows = selectedRows;
}

export function setIsAssociatedClosed(isAssociatedClosed: boolean): void {
    store.isAssociatedClosed = isAssociatedClosed;
}

export function setIds(id: number): void {
    store.ids = id;
}

export function setIsModified(isModified: boolean): void {
    store.isModified = isModified;
}
export function setIsActivation(isActivation: boolean): void {
    store.isActivation = isActivation;
}
export function setIsModeOfSaleAssociatedModified(
    isModeOfSaleAssociatedModified: boolean
): void {
    store.isModeOfSaleAssociatedModified = isModeOfSaleAssociatedModified;
}

export function setShopsModeSaleData(shopsModeSaleData: any): void {
    store.shopsModeSaleData = shopsModeSaleData;
}

export function setModeSaleData(modeSaleData: any): void {
    store.modeSaleData = modeSaleData;
}

export function setRowsShop(rowsShop: any[]): void {
    store.rowsShop = rowsShop;
}
export function setArchivedModeOfSaleFranchise(
    archivedModeOfSaleFranchise: any[]
): void {
    store.archivedModeOfSaleFranchise = archivedModeOfSaleFranchise;
}
export function setAssociatedModeOfSale(associatedModeOfSale: any[]): void {
    store.associatedModeOfSale = associatedModeOfSale;
}

export function setModeOfSaleFranchise(modeOfSaleFranchise: any[]): void {
    store.modeOfSaleFranchise = modeOfSaleFranchise;
}

export function setActiveModeSale(activeModeSale: any): void {
    let index = store.activeModeSale.findIndex(
        (element: any) => element.Id === activeModeSale.Id
    );
    index !== -1
        ? (store.activeModeSale[index] = activeModeSale)
        : store.activeModeSale.push(activeModeSale);
}

export function setGlobalDataIsUpdated(
    globalDataIsUpdated: boolean | number
): void {
    store.globalDataIsUpdated = globalDataIsUpdated;
}

export function setDataArchive(dataArchive: any[]): void {
    store.dataArchive = dataArchive;
}
export function setDataFranchiseArchive(dataFranchiseArchive: any[]): void {
    store.dataFranchiseArchive = dataFranchiseArchive;
}

export function setIsArchived(isArchived: boolean): void {
    store.isArchived = isArchived;
}

export function setMessage(message: string): void {
    store.message = message;
}

export function setSalesSupports(salesSupports: any): any {
    store.salesSupports = salesSupports;
}

export function setEditedDataModeOfSale(editedDataModeOfSale: any): void {
    store.editedDataModeOfSale = editedDataModeOfSale;
}

export function setIsEdited(isEdited: boolean): any {
    store.isEdited = isEdited;
}

export function setIsActionsClicked(isActionsClicked: boolean): any {
    store.isActionsClicked = isActionsClicked;
}

export function setValidationAction(validationAction: any): any {
    store.validationAction = validationAction;
}

export function setActions(actions: any): any {
    store.actions = actions;
}
export default store;
