import React from "react";
import { useCaretPosition } from "react-use-caret-position";

type PropsType = {
    value: string | number | readonly string[] | undefined;
    disabled?: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    style?: React.CSSProperties;
};

export function CustomInput({
    value,
    disabled,
    onChange,
    style,
}: PropsType): JSX.Element {
    const { ref: inputRef, updateCaret } = useCaretPosition();

    function handleOnKeyPressEvent(
        event: React.KeyboardEvent<HTMLInputElement>
    ) {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    }

    return (
        <input
            ref={inputRef}
            className="form-control"
            type="text"
            value={value}
            disabled={disabled}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChange(event);
                updateCaret();
            }}
            onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                handleOnKeyPressEvent(event);
            }}
            style={style}
        />
    );
}
