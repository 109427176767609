import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { CheckboxIcon, CrossIcon } from "@aureskonnect/react-ui";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { diff } from "deep-diff";

import {
    computedStore,
    generalConfigStore,
    setChosenTemplate,
    setInitItemsFromGeneralSetting,
    setIsInformationModesEmpty,
    setIsModalOpened,
    setIsProjectModesModalOpened,
    setProject,
} from "@store";
import { store as useStore } from "@components/VerticalLayout/store";
import {
    setIsLoading,
    setIsModalConfirmationConfigurationKiosk,
    setKioskProject,
    store,
} from "../../../store/project";

import { SwiperWrapper } from "../../FullscreenPreview/SwiperWrapper";
import { Modal as Modala } from "./Modal";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";

import "./index.css";

type PropsType = {
    children: React.ReactNode;
    title: string;
    rowData?: any;
    setIsDataUpdated: Function;
    isDataUpdated: boolean | number;
    setIsCreatedProject: Function;
};

export function ModalWrapper({
    children,
    title = "",
    rowData,
    setIsDataUpdated,
    isDataUpdated,
    setIsCreatedProject,
}: PropsType): JSX.Element {
    const { t } = useTranslation();
    const {
        userID,
        franchiseID,
        projectId,
        shopID,
        oneShop,
        operatorID,
    } = useSnapshot(useStore);

    const { isModalOpened, isWaysTabActive, project } = useSnapshot(
        generalConfigStore
    );
    const { isConsult } = useSnapshot(store);
    const { items } = useSnapshot(computedStore);
    const [isModalShown, setModalVisibility] = React.useState(false);

    const toggle = () => {
        setIsModalOpened(!isModalOpened);
        setIsDataUpdated(!isDataUpdated);
        setIsCreatedProject(false);
    };

    async function handleUpdateProjectButtonOnClickEvent() {
        let apiUrl: any;
        const kioskProject = JSON.parse(localStorage.getItem("project")!);

        setKioskProject(kioskProject);
        apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/template`;
        setIsLoading(true);
        mutate(
            apiUrl,
            await fetch(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    userId: userID,
                    franchiseId: franchiseID,
                    projectId: projectId,
                    project_id: "PROJECT",
                    shopId: "0",
                    operatorId: operatorID,

                    data: {
                        template: kioskProject.template,
                        files: kioskProject.files,
                    },
                }),
                method: "POST",
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }
                    setIsModalOpened(!isModalOpened);
                    if (!oneShop) {
                        toast.success(`${t("Template saved successfully")}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        });

                        if (
                            rowData !== undefined &&
                            rowData.template.id !== "" &&
                            rowData?.subRows.length > 0 &&
                            (diff(rowData.template, kioskProject.template) !==
                                undefined ||
                                diff(rowData.files, kioskProject.files) !==
                                    undefined)
                        ) {
                            setIsModalConfirmationConfigurationKiosk(true);
                        } else {
                            setIsDataUpdated(!isDataUpdated);
                        }
                    } else {
                        if (project.associates !== undefined) {
                            if (Object.keys(project.associates).length > 0) {
                                handleSaveTemplateOnClickEvent();
                            }
                        } else {
                            setIsDataUpdated(!isDataUpdated);
                        }
                    }
                    setIsLoading(false);
                })

                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }
    async function handleUpdateProjectShopsButtonOnClickEvent() {
        setIsLoading(true);
        let apiUrl: any;
        const kioskProject = JSON.parse(localStorage.getItem("project")!);
        apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/template`;
        mutate(
            apiUrl,
            await fetch(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    userId: userID,
                    franchiseId: franchiseID,
                    project_id: "PROJECT",
                    projectId: projectId,
                    shopId: shopID,
                    operatorId: operatorID,
                    data: {
                        template: kioskProject.template,
                        files: kioskProject.files,
                    },
                }),
                method: "POST",
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }

                    toast.success(`${t("Template saved successfully")}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    });

                    if (
                        rowData !== undefined &&
                        Object.keys(rowData).length > 0 &&
                        rowData?.template.id !== "" &&
                        (diff(rowData.template, kioskProject.template) !==
                            undefined ||
                            diff(rowData.files, kioskProject.files) !==
                                undefined)
                    ) {
                        rowData?.subRows.forEach((elt: any) => {
                            if (elt.template !== "") {
                                setIsModalConfirmationConfigurationKiosk(true);
                            }
                        });
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }

    const closeModal = () => {
        setModalVisibility(false);
    };
    const handleSaveTemplateOnClickEvent = async () => {
        for (let row of Object.keys(project.associates)) {
            let template: any;
            const getLanguagesUrl: Response = await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/consomation/langue?franchiseId=${franchiseID}&shopId=${row}`
            );
            const languages = await getLanguagesUrl.json();
            const getReglementUrl: Response = await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/consomation/reglement?franchiseId=${franchiseID}&shopId=${row}`
            );
            const reglements = await getReglementUrl.json();

            const getInformationMethodUrl: Response = await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/consomation/information?franchiseId=${franchiseID}&shopId=${row}`
            );

            const getSalesMethodUrl: Response = await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/consomation/sale?franchiseId=${franchiseID}&shopId=${row}`
            );

            const getLogoUrl: Response = await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/consomation/logo?franchiseId=${franchiseID}&shopId=${row}`
            );

            const informationModes = await getInformationMethodUrl.json();
            const saleMethods = await getSalesMethodUrl.json();
            const logo = await getLogoUrl.json();

            if (saleMethods.length === 0) {
                toast.warning(
                    `${t(
                        `Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings.`
                    )!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
                return;
            } else {
                if (
                    informationModes.length === 0 ||
                    informationModes.filter(
                        // eslint-disable-next-line no-loop-func
                        (item: ProjectMainContentItemType) => {
                            return saleMethods.filter((saleMethod: any) => {
                                return (
                                    Object.keys(item.active).includes(
                                        saleMethod.name
                                    ) && saleMethod.active === true
                                );
                            });
                        }
                    ).length === 0
                ) {
                    setIsInformationModesEmpty(true);
                } else {
                    setIsInformationModesEmpty(false);
                }
                template = {
                    ...project.template,
                    content: {
                        ...project.template.content,
                        languages: {
                            ...project.template.content.languages,
                            items: languages,
                        },
                        salesMethods: {
                            items: saleMethods,
                        },
                        meansOfPayment: {
                            items: reglements,
                        },
                        informationModes: {
                            items: informationModes,
                        },
                        generalDesign: {
                            ...project.template.content.generalDesign,
                            logo,
                        },
                    },
                };

                let files = project.files;
                setProject({
                    ...project,
                    template,
                    files,
                });
                setInitItemsFromGeneralSetting({
                    informationModes,
                    saleMethods,
                    reglements,
                    languages,
                    logo,
                });

                setChosenTemplate(template, files);
                let project1 = {
                    ...project,
                    template,
                };

                let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/template`;
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            userId: userID,
                            franchiseId: franchiseID,
                            projectId: projectId,
                            project_id: "PROJECT",
                            shopId: row,
                            data: project1,
                            operatorId: operatorID,
                        }),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.error) {
                                throw Error(result.message);
                            }
                            setIsModalOpened(!isModalOpened);
                        })

                        .catch((error) => {
                            toast.error(`${t("There's an error")!}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                            });
                        })
                );
            }
        }
    };
    const handleSaveTemplateFromShopsToFranchiseOnClickEvent = async () => {
        let shopId = "0";
        let template: any;
        const getLanguagesUrl: Response = await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/consomation/langue?franchiseId=${franchiseID}&shopId=${shopId}`
        );
        const languages = await getLanguagesUrl.json();
        const getReglementUrl: Response = await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/consomation/reglement?franchiseId=${franchiseID}&shopId=${shopId}`
        );
        const reglements = await getReglementUrl.json();

        const getInformationMethodUrl: Response = await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/consomation/information?franchiseId=${franchiseID}&shopId=${shopId}`
        );

        const getSalesMethodUrl: Response = await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/consomation/sale?franchiseId=${franchiseID}&shopId=${shopId}`
        );

        const getLogoUrl: Response = await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/consomation/logo?franchiseId=${franchiseID}&shopId=${shopId}`
        );

        const informationModes = await getInformationMethodUrl.json();
        const saleMethods = await getSalesMethodUrl.json();
        const logo = await getLogoUrl.json();

        if (saleMethods.length === 0) {
            toast.warning(
                `${t(
                    `Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings.`
                )!}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
            return;
        } else {
            if (
                informationModes.length === 0 ||
                informationModes.filter((item: ProjectMainContentItemType) => {
                    return saleMethods.filter((saleMethod: any) => {
                        return (
                            Object.keys(item.active).includes(
                                saleMethod.name
                            ) && saleMethod.active === true
                        );
                    });
                }).length === 0
            ) {
                setIsInformationModesEmpty(true);
            } else {
                setIsInformationModesEmpty(false);
            }
            template = {
                ...project.template,
                content: {
                    ...project.template.content,
                    languages: {
                        ...project.template.content.languages,
                        items: languages,
                    },
                    salesMethods: {
                        items: saleMethods,
                    },
                    meansOfPayment: {
                        items: reglements,
                    },
                    informationModes: {
                        items: informationModes,
                    },
                    generalDesign: {
                        ...project.template.content.generalDesign,
                        logo,
                    },
                },
            };

            let files = project.files;
            setProject({
                ...project,
                template,
                files,
            });
            setInitItemsFromGeneralSetting({
                informationModes,
                saleMethods,
                reglements,
                languages,
                logo,
            });

            setChosenTemplate(template, files);
            let project1 = {
                ...project,
                template,
            };

            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/template`;
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        userId: userID,
                        franchiseId: franchiseID,
                        projectId: projectId,
                        project_id: "PROJECT",
                        shopId: "0",
                        operatorId: operatorID,
                        data: {
                            template: project1.template,
                            files: project1.files,
                        },
                    }),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.error) {
                            throw Error(result.message);
                        }

                        setIsDataUpdated(!isDataUpdated);
                    })

                    .catch((error) => {
                        toast.error(`${t("There's an error")!}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    })
            );
        }
    };

    return (
        <React.Fragment>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                toggle={toggle}
                isOpen={isModalOpened}
                className="modal-wrapper__clz"
            >
                <ModalHeader
                    toggle={toggle}
                    className="text-uppercase cursor__clz"
                >
                    <span className="pme_txt_popUpTitle"> {title}</span>
                </ModalHeader>
                <ModalBody className="p-0">{children}</ModalBody>
                <ModalFooter>
                    {isWaysTabActive ? (
                        <CustomMainColorButton
                            outline={true}
                            rounded={true}
                            className="m-2"
                            variant="primary"
                            disabled={Object.values(items.ways).length === 0}
                            onClick={() => setModalVisibility(true)}
                        >
                            {t("See")}
                        </CustomMainColorButton>
                    ) : null}
                    {isConsult ? (
                        <CustomMainColorButton
                            className="w-20"
                            rounded
                            variant="primary"
                            onClick={() => {
                                setIsModalOpened(false);
                                setIsProjectModesModalOpened(false);
                                setIsDataUpdated(!isDataUpdated);
                            }}
                        >
                            {t("Close")}
                        </CustomMainColorButton>
                    ) : (
                        <CustomMainColorButton
                            disabled={project.template.id === "" ? true : false}
                            className="w-20 cmn_btn_SaveButton"
                            rounded
                            variant="primary"
                            onClick={() => {
                                if (
                                    (oneShop && rowData === undefined) ||
                                    (oneShop &&
                                        rowData !== undefined &&
                                        rowData?.template.id === "")
                                ) {
                                    handleUpdateProjectShopsButtonOnClickEvent();
                                    handleSaveTemplateFromShopsToFranchiseOnClickEvent();
                                } else if (
                                    oneShop &&
                                    rowData !== undefined &&
                                    rowData?.template.id !== ""
                                ) {
                                    handleUpdateProjectShopsButtonOnClickEvent();
                                    setIsDataUpdated(!isDataUpdated);
                                } else {
                                    handleUpdateProjectButtonOnClickEvent();
                                }
                                setIsProjectModesModalOpened(false);
                                setIsModalOpened(false);
                                setIsCreatedProject(false);
                            }}
                        >
                            {t("Save")}
                        </CustomMainColorButton>
                    )}
                </ModalFooter>
            </Modal>
            {isModalShown && (
                <Modala close={closeModal}>
                    <div
                        style={{
                            height: "940px",
                            display: "grid",
                            gridTemplateRows: "0.001fr 0.999fr",
                            marginTop: "-185px",
                            marginLeft: "-280px",
                            borderRadius: "9px",
                            background:
                                "linear-gradient(227.9deg, #1F1726 0%, #120E16 100%)",
                            boxShadow:
                                "-5px -5px 11px #221A2B, 5px 5px 11px #16101C",
                            position: "fixed",
                            zIndex: 500000,
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "start",
                                justifyContent: "end",
                                padding: "10px",
                            }}
                        >
                            <CrossIcon
                                fill="white"
                                height={10}
                                width={10}
                                onClick={() => {
                                    closeModal();
                                }}
                            />
                        </div>

                        <SwiperWrapper />
                    </div>
                </Modala>
            )}
        </React.Fragment>
    );
}
