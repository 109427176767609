import React from "react";

export function VeganIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            width="10"
            height="14.5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            style={{
                background: "new 0 0 512 512",
            }}
            xmlSpace="preserve"
            {...props}
        >
            <path d="M170.469 244.963 318.416 392.91c.682-13.128 1.271-42.1-3.707-76.814-8.971-62.564-32.044-113.967-66.726-148.647-34.681-34.681-86.083-57.755-148.647-66.726-46.552-6.676-82.801-3.341-84.321-3.194A15.61 15.61 0 0 0 .961 111.583c-.146 1.52-3.481 37.768 3.194 84.321 8.971 62.564 32.044 113.967 66.726 148.647 34.681 34.681 86.083 57.755 148.647 66.726 23.042 3.304 43.553 4.155 58.583 4.155 7.612 0 13.815-.219 18.229-.449L148.394 267.038c-6.096-6.095-6.096-15.979 0-22.076 6.095-6.094 15.979-6.094 22.075.001zM511.306 205.805a15.61 15.61 0 0 0-14.054-14.054c-4.172-.398-102.848-9.104-160.309 48.358a132.488 132.488 0 0 0-6.413 6.895c6.727 20.116 11.796 41.738 15.082 64.661a439.79 439.79 0 0 1 2.508 21.195l40.787-40.787c6.095-6.095 15.979-6.095 22.076 0s6.096 15.979 0 22.076L350.1 375.032c-.015 15.912-.921 26.286-1.119 28.357a46.63 46.63 0 0 1-2.273 10.568c34.675-3.206 82.542-14.144 116.24-47.843 57.461-57.46 48.757-156.135 48.358-160.309z" />
        </svg>
    );
}
